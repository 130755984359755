<template>
    <v-container style="max-width: 100%">
    <!-- {{ $store.state.loadingApp }} -->
        <div class="d-flex flex-row flex-wrap justify-center">
            <div class='d-flex flex-row elevation-1 px-2 pt-4 mb-2'>
                <v-radio-group
                    row
                    :value="scopeLanguage"
                    @change="$store.dispatch('changescopeLanguage', $event), showAllTeachers(true), loadDataAll()"
                    class="mt-0"
                    :disabled="freeze"
                >
                    <v-radio
                        label="Spanish"
                        value="Spanish"
                        class="my-0 py-0"
                    ></v-radio>
                    <v-radio
                        label="Quechua"
                        value="Quechua"
                        class="my-0 py-0"
                    ></v-radio>
                    <v-radio
                        label="English"
                        value="English"
                        class="my-0 py-0"
                    ></v-radio>
                    <v-radio
                        label="All"
                        value="allLanguages"
                        class="my-0 py-0"
                    ></v-radio>
                </v-radio-group>
            </div>
        </div>
        <v-tabs
            :value="activeTabTeaching"
            background-color="transparent"
            color="basil"
            grow
            @change="$store.dispatch('activeTabTeaching', $event)"
        >
            <v-tab
                v-for="itemTab in itemsTab"
                :key="itemTab"
            >
                <span class="ppc_darkgreen--text">
                    {{ itemTab }}
                </span>
            </v-tab>
        </v-tabs>
    
        <v-tabs-items v-model="activeTabTeaching">
            <!-- Resources -->
            <v-tab-item>
                <div class="d-flex flex-row flex-wrap justify-center">
                    <v-card flat outlined class='d-flex flex-row px-2 pt-8 mb-2'>
                        <v-radio-group
                            row
                            :value="scopeOnline"
                            @change="$store.dispatch('changeScopeOnline', $event), loadDataTabResources()"
                            class="mt-0"
                            :disabled="freeze"
                        >
                            <v-radio
                                label="Online"
                                value="online"
                                class="my-0 py-0"
                            ></v-radio>
                            
                            <v-radio
                                label="In Person"
                                value="inPerson"
                                class="my-0 py-0"
                            ></v-radio>
                            <v-radio
                                label="Both"
                                value="all"
                                class="my-0 py-0"
                            ></v-radio>
                        </v-radio-group>
                    </v-card>
                    <v-card flat outlined class='d-flex flex-row px-2 pt-2 mb-2'>
                        <v-radio-group
                            row
                            :value="scopeResources"
                            @change="changingScopeResources($event)"
                            class="mt-0"
                            :disabled="freeze"
                        >
                            <div class="d-flex flex-column">
                                <div class="d-flex justify-center">
                                    <v-radio
                                        label="Ongoing"
                                        value="ongoing"
                                        class="my-0 py-0"
                                    ></v-radio>
                                </div>
                                <v-divider class="my-2"></v-divider>
                                <div class="d-flex flex-row flex-wrap">
                                    <div class="d-flex flex-row flex-wrap">
                                        <v-radio
                                            label="No Classes Planned"
                                            value="notPlanned"
                                            class="my-0 py-0"
                                        ></v-radio>
                                        <v-radio
                                            label="Planning Not Finished"
                                            value="courseNotFinished"
                                            class="my-0 py-0"
                                        ></v-radio>
                                        <v-radio
                                            label="Extending"
                                            value="extending"
                                            class="my-0 py-0"
                                        ></v-radio>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-space-between mx-2">
                                <v-radio
                                    label="Completed"
                                    value="completed"
                                    class="my-0 py-0"
                                ></v-radio>
                            </div>
                        </v-radio-group>
                    </v-card>
                    <v-card flat outlined class='d-flex flex-row flex-wrap px-2 pt-0 mb-2'>
                        <v-card-title>Courses starting</v-card-title>
                        <span class="mx-2 my-0 mt-4">
                            <v-menu
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="100%"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        label="between"
                                        v-bind="attrs"
                                        prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                        :value="formattedDate(scopeDates.start)"
                                        v-on="on"
                                        @click:clear="scopeDates.start = null, resourcesScope = filterResources(resourcesLoaded)"
                                        clearable
                                        readonly
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="scopeDates.start"
                                    no-title
                                    scrollable
                                    @change="resourcesScope = filterResources(resourcesLoaded)"
                                ></v-date-picker>
                            </v-menu>
                        </span>
                        <span class="mx-2 my-0 mt-4">
                            <v-menu
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="100%"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        label="and"
                                        v-bind="attrs"
                                        prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                        :value="formattedDate(scopeDates.end)"
                                        v-on="on"
                                        @click:clear="scopeDates.end = null, resourcesScope = filterResources(resourcesLoaded)"
                                        clearable
                                        readonly
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="scopeDates.end"
                                    no-title
                                    scrollable
                                    @change="resourcesScope = filterResources(resourcesLoaded)"
                                ></v-date-picker>
                            </v-menu>
                        </span>
                        <v-card 
                            flat
                            class="d-flex flex-row flex-wrap my-0 mx-6 align-center jusify-space-between" 
                            height="65px" 
                            width="220px"
                        >
                            <v-card
                                flat
                                class="ma-0 pa-0 mt-4"
                                v-for="(item, id) in legendColorsCourses"
                                :key="id"
                                color="grey lighten-4"
                            >
                                <v-icon
                                    :style="{'background-color': item.color, color: '#FFF'}"
                                    class="pa-1 ma-1"
                                    x-small
                                    v-html="item.name"
                                ></v-icon>
                            </v-card>
                        </v-card>
                    </v-card>
                </div>
                <v-data-table
                    :headers="headersResourcesAll"
                    :items="resourcesScope"
                    sort-by="start"
                    style="color: white"
                    class="my-5 elevation-1 invert-colors"
                    :item-class="itemRowBackgroundResources"
                    :items-per-page="-1"
                    hide-default-footer
                    :search="searchResourceTableTeaching"
                >
                <template v-slot:top>
                    <v-toolbar flat color="white">
                        <div class="d-flex flex-row flex-wrap justify-center">
                            <v-card flat>
                                <v-text-field
                                    v-model="searchResourceTableTeaching"
                                    append-icon="$vuetify.icons.icon_mdi_magnify"
                                    :label="'Search ' + `${ resourcesScope.length }` + ' Course(s)'"
                                    single-line
                                    hide-details
                                    style="width: 300px"
                                    clearable
                                ></v-text-field>
                            </v-card>
                        </div>
                    </v-toolbar>
                </template>
                    <template v-slot:[`item.full_name`]="{ item }">
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn 
                                    small 
                                    rounded 
                                    v-bind="attrs"
                                    v-on="on" 
                                    @click="goClient(item.client_id, item.resource_id)"
                                >
                                    {{ item.full_name }}
                                </v-btn>
                            </template>
                            <span>go resource in client</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.class_language`]="{ item }">
                        <span class="d-flex flex-row">
                            <span>
                                <v-icon small>{{getIconResource(item)}}</v-icon>
                            </span>
                            <v-icon small class="mx-2 mt-1" v-if="item.class_location === 'Online'">{{ "$vuetify.icons.icon_mdi_headphones" }}</v-icon>
                        </span>
                    </template>
                    <template v-slot:[`item.class_group`]="{ item }">
                        <v-icon small color="white" v-if="item.class_group === 'Individual'">$vuetify.icons.icon_mdi_account</v-icon>
                        <v-icon small color="white" v-if="item.class_group === 'Group'">$vuetify.icons.icon_mdi_account_group</v-icon>
                    </template>
                    <template v-slot:[`item.class_location`]="{ item }">
                        <v-icon color="white" small v-if="item.class_location === 'School'">mdi-home</v-icon>
                        <v-icon color="white" small v-if="item.class_location === 'Online'">mdi-desktop-classic</v-icon>
                    </template>
                    <template v-slot:[`item.class_type`]="{ item }">
                        <v-icon color="white" small v-if="item.class_type === 'Normal'"></v-icon>
                        <v-icon color="white" small v-if="item.class_type === 'Special'">mdi-star</v-icon>
                    </template>
                    <template v-slot:[`item.start`]="{ item }">
                        <span small v-if="item.start !== null" v-html="formattedDate(item.start, 'date')"></span>
                    </template>
                    <template v-slot:[`item.end`]="{ item }">
                        <span small v-if="item.end !== null" v-html="formattedDate(item.end, 'date')"></span>
                    </template>
                    <template v-slot:[`item.minutes_planned`]="{ item }">
                        <span>{{ item.minutes_planned / 60 }}</span>
                    </template>
                    <template v-slot:[`item.minutes_completed`]="{ item }">
                        <span>{{ item.minutes_completed / 60 }}</span>
                    </template>
                    <template v-slot:[`item.minutes_available`]="{ item }">
                        <!-- <span>{{ calcAvailableHoursResource(item, false) }} ({{ calcAvailableHoursResource(item, true) }})</span> -->
                        <!-- <span>{{ calcAvailableHoursResource(item, false) }}</span> -->
                        <span>{{ (Math.floor((item.minutes_available) / 60 * 2) / 2 ) }}</span>
                    </template>
                    <template v-slot:[`item.graduationClassStart`]="{ item }">
                        <span small v-if="item.graduationClassStart !== null && item.minutes_available === 0" v-html="formattedDate(item.graduationClassStart, 'date')"></span>
                    </template>
                    <template v-slot:[`item.statusResourceId`]="{ item }">
                        <v-chip small color="white" text-color="negro" v-html="getStatusResource(item).name"></v-chip>
                    </template>
                    <template v-slot:[`item.sendScheduleStudent`]="{ item }">
                        <v-chip 
                            @click="goClassesScheduleStudent(item), dialogCourse = false"
                            small 
                            color="#00cc00"
                            text-color="white"
                            v-if="item.sendScheduleStudent === 0"
                            :disabled="$store.state.loadingApp > 0"
                        >
                            ok
                        </v-chip>
                        <v-chip 
                            @click="goClassesScheduleStudent(item)"
                            small
                            color="#ff6600"
                            text-color="white"
                            v-if="item.sendScheduleStudent === 1"
                            :disabled="$store.state.loadingApp > 0"
                        >
                            send
                        </v-chip>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    class="mx-1"
                                    color="white"
                                    @click="openDialogCourse(item.resource_id)"
                                    v-on="on"
                                    :disabled="$store.state.loadingApp > 0"
                                >
                                    $vuetify.icons.icon_mdi_pencil
                                </v-icon>
                            </template>
                            <span>Edit Course</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-tab-item>
            <!-- Calendar -->
            <v-tab-item>
                <v-card flat outlined class="d-flex flex-column ma-4 pa-4 justify-center" style="border-color: grey;">
                    <v-card flat class="d-flex flex-row flex-wrap">
                        <v-checkbox
                            :input-value="selectAllTeachers"
                            ripple
                            label="All"
                            class="py-0 my-0 mx-2"
                            color="blue"
                            @change="changeSelectAllTeachers($event)"
                            :disabled="freeze"
                         ></v-checkbox>
                        <v-card flat v-for="(teacher, index) in teachersTable" :key="index">
                            <div class="ma-0 pa-0">
                                <v-checkbox
                                    ripple
                                    :input-value="teacher.visible"
                                    @change="payload = {index: index, visibility: !teacher.visible }, $store.dispatch('changeTeacherVisibility', payload), checkAllTeachersSelected(), loadDataTabCalendar()"
                                    :label="`${teacher.contactFullName}`"
                                    class="py-0 my-0 mx-2"
                                    color="blue"
                                    :disabled="freeze"
                                    v-if="teacher.obsolete === null && (scopeLanguage === 'allLanguages' || scopeLanguage === 'Spanish' && teacher.spanish === 1 || scopeLanguage === 'Quechua' && teacher.quechua === 1 || scopeLanguage === 'English' && teacher.english === 1)"
                                ></v-checkbox>
                            </div>
                        </v-card>
                    </v-card>
                </v-card>
                <!-- <v-card flat outlined class="d-flex flex-row flex-wap ma-4 pa-4" style="border-color: grey;">
                    <div class="d-flex flex-row flex-wrap">
                        <v-checkbox
                            :input-value="selectAllRooms"
                            ripple
                            label="All"
                            class="py-0 my-0 mx-2"
                            color="green"
                            @change="$store.dispatch('changeSelectAllRooms', !selectAllRooms), hideAllRooms(!selectAllRooms), loadDataTabCalendar()"
                            :disabled="freeze || scopeCalendar !== 'category'"
                         ></v-checkbox>
                        <v-card flat v-for="(class_room_id, index) in classRooms" :key="index">
                            <div class="ma-0 pa-0">
                                <v-checkbox
                                    ripple
                                    v-model="classRooms[index].hide"
                                    :false-value=true
                                    :true-value=false
                                    @change="changeClassRooms()"
                                    :label="`${classRooms[index].name}`"
                                    class="py-0 my-0 mx-2"
                                    :disabled="freeze || scopeCalendar !== 'category'"
                                ></v-checkbox>
                            </div>
                        </v-card>
                    </div>
                </v-card> -->
                <div class="d-flex flex-row flex-wrap justify-space-between">
                    <v-sheet height="64">
                        <v-toolbar
                        flat
                        >
                            <v-btn
                                outlined
                                class="mr-4 py-8"
                                color="grey darken-2"
                                @click="setToday"
                                :disabled="$store.state.loadingApp > 0"
                            >
                                Today
                            </v-btn>
                            <v-btn
                                fab
                                text
                                small
                                color="grey darken-2"
                                @click="prev"
                                :disabled="$store.state.loadingApp > 0"
                            >
                                <v-icon>
                                mdi-chevron-left
                                </v-icon>
                            </v-btn>
                            <v-btn
                                fab
                                text
                                small
                                color="grey darken-2"
                                @click="next"
                                :disabled="$store.state.loadingApp > 0"
                            >
                                <v-icon>
                                mdi-chevron-right
                                </v-icon>
                            </v-btn>
                            <v-toolbar-title v-if="$refs.calendar">
                                {{ $refs.calendar.title }}
                            </v-toolbar-title>
                        </v-toolbar>
                    </v-sheet>
                    <v-card 
                        flat
                        class="d-flex flex-row flex-wrap my-0 mx-6 align-center jusify-space-between" 
                        height="65px" 
                        width="410px"
                        outlined
                    >
                        <v-card
                            flat
                            class="ma-0 pa-0"
                            v-for="(item, id) in legendColorsCalendar"
                            :key="id"
                            color="grey lighten-4"
                        >
                            <v-icon
                                :style="{'background-color': item.color, color: '#FFF'}"
                                class="pa-1 ma-1"
                                x-small
                                v-html="item.name"
                            ></v-icon>
                        </v-card>
                        <v-card flat>
                            <v-icon
                                style="background-color: transparent"
                                color="black"
                                class="pa-1 ma-1"
                                x-small
                            >
                                edit class = RMB
                            </v-icon>
                        </v-card>
                    </v-card>
                    <v-card flat outlined class='d-flex flex-row px-2 pt-3 pb-0 mb-1 mr-4' style="border-color: grey;">
                        <v-radio-group
                            row
                            :value="scopeCalendar"
                            @change="changingScopeCalendar"
                            class="pt-2 pb-0 px-4 ma-0"
                            :disabled="freeze"
                        >
                            <v-radio
                                label="Day Rooms"
                                value="category"
                                class="my-0 py-0"
                            ></v-radio>
                            <!-- <v-radio
                                label="Day"
                                value="day"
                                class="my-0 py-0"
                            ></v-radio> -->
                            <v-radio
                                label="Week"
                                value="week"
                                class="my-0 py-0"
                            ></v-radio>
                            <!-- <v-radio
                                label="Day Teacher"
                                value="dayTeacher"
                                class="my-0 py-0"
                            ></v-radio> -->
                            <v-radio
                                label="Month"
                                value="month"
                                class="my-0 py-0"
                            ></v-radio>
                        </v-radio-group>
                    </v-card>
                </div>
                <!-- @click:event="checkClickCalender($event.event)" -->
                <!-- @contextmenu:event="openDialogClass($event.event)" -->
                <v-sheet height="100vh">
                    <v-calendar
                        ref="calendar"
                        v-model="focusCalendar"
                        color="primary"
                        :type="scopeCalendar"
                        category-show-all
                        category-days = 1
                        :categories="categoriesCalendar"
                        :events="events"
                        event-overlap-mode="column"
                        show-week
                        :first-interval= 7
                        @change="loadDataTabCalendar"
                        @mousedown:event="startDrag"
                        @mousedown:time="startTimer"
                        @mouseenter:time-category="getCategory"
                        @mousemove:time="mouseMove"
                        @mouseup:time="endDrag"
                        @mouseleave.native="cancelDrag"
                        @contextmenu:event="prepOpenDialogClass($event.event)"
                        @click:date="clickDate()"
                    >
                        <template v-slot:event="{ event, timed, eventSummary }">
                            <v-icon small>{{ event.icon }}</v-icon>
                            <v-icon small>{{ event.iconOnline }}</v-icon>
                            <v-icon small>{{ event.iconGrad }}</v-icon>
                            {{ event.title }}
                            <div class="v-event-draggable">
                                <component :is="{ render: eventSummary }"></component>
                            </div>
                            <div
                                v-if="timed"
                                class="v-event-drag-bottom"
                                @mousedown.stop="extendBottom(event)"
                            ></div>
                        </template>
                    </v-calendar>
                </v-sheet>
            </v-tab-item>
            <v-tab-item>
                <!-- Teachers -->
                <div class='d-flex flex-row elevation-1 px-2 pt-4 mb-2 justify-center'>
                    <v-radio-group
                        row
                        :value="scopeClasses"
                        @change="changingScopeClasses($event)"
                        class="mt-0"
                        :disabled="freeze"
                        label="Classes"
                    >
                        <v-radio
                            label="Planification"
                            value="ongoing"
                            class="my-0 py-0"
                        ></v-radio>
                        <v-radio
                            label="Planned Not Paid"
                            value="notPaid"
                            class="my-0 py-0"
                        ></v-radio>
                        <v-radio
                            label="Finished Not Paid"
                            value="finishedNotPaid"
                            class="my-0 py-0"
                        ></v-radio>
                        <v-radio
                            label="Finished Paid"
                            value="finishedPaid"
                            class="my-0 py-0"
                        ></v-radio>
                        <!-- <v-radio
                            label="All"
                            value="all"
                            class="my-0 py-0"
                        ></v-radio> -->
                    </v-radio-group>
                    <v-spacer></v-spacer>
                    <v-btn color="ppc_darkgreen darken-1" class="white--text" @click="$router.push({ name: 'Teachers'})">Teachers Pool</v-btn>
                </div>
                <v-tabs
                    :value="activeTabTeachers"
                    background-color="transparent"
                    color="basil"
                    grow
                    @change="$store.dispatch('activeTabTeachers', $event)"
                >
                    <v-tab
                        v-for="itemTabTeachers in itemsTabTeachers"
                        :key="itemTabTeachers"
                    >
                        <span class="ppc_darkgreen--text">
                            {{ itemTabTeachers }}
                        </span>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="activeTabTeachers">
                    <v-tab-item
                        v-for="teacher in indexTeachers"
                        :key="teacher.id"
                    >
                        <v-card flat outlined class="d-flex flex-row flex-wrap ma-4 justify-space-around" v-if="(scopeClasses === 'notPaid' || scopeClasses === 'finishedNotPaid') && selectedClassesTeachers">
                            <v-card flat class="d-flex flex-row flex-wrap ma-4">
                                <v-card flat width="400px">
                                    <v-text-field 
                                        v-model="teacher.comments_correction"
                                        label="Comments"
                                        :disabled="selectedClassesTeachers.length === 0"
                                        class="ma-2"
                                    ></v-text-field>
                                </v-card>
                                <v-card flat width="150px">
                                    <v-text-field
                                        v-model="teacher.correction"
                                        label="Correction PEN"
                                        :disabled="selectedClassesTeachers.length === 0"
                                        class="ma-2"
                                    ></v-text-field>
                                </v-card>
                                <v-btn
                                    small
                                    @click="goPaymentTeacherForm(teacher)"
                                    :disabled="selectedClassesTeachers.length === 0"
                                    class="mx-4 mt-5"
                                >
                                    Payment Teacher Form
                                </v-btn>
                                <v-btn
                                    small
                                    @click="payClasses(teacher)"
                                    :disabled="selectedClassesTeachers.length === 0"
                                    class="mx-4 mt-5"
                                >
                                    Pay Selected Classes
                                </v-btn>
                            </v-card>
                        </v-card>
                        <v-card flat v-if="teacher.classes && scopeClasses === 'ongoing'" height="131px" class="d-flex flex-row justify-space-around">
                            <v-btn
                                small
                                @click="goClassesScheduleTeacher(teacher)"
                                :disabled="teacher.classes.length === 0"
                                style="width: 7cm; height: 1cm;"
                                v-bind:color="teacher.lastSchedule !== null ? '#00cc00' : '#ff6600'"
                                class="ma-4 mt-8"
                            >
                                <span v-if="teacher.classes.length === 0">No Classes Planned</span>
                                <span v-else-if="teacher.lastSchedule === null">Send Planification</span>
                                <span v-else-if="teacher.lastSchedule !== null">Last Planification Sent</span>
                            </v-btn>
                        </v-card>
                        <v-data-table
                            :headers="headersClassesTeachers"
                            :items="teacher.classes"
                            class="my-5 elevation-1"
                            dense
                            :items-per-page="-1"
                            disable-pagination
                            hide-default-footer
                            show-select
                            :single-select=false
                            v-model="selectedClassesTeachers"
                            :item-class="getBackgroundColorTeachers"
                            sort-by="start"
                            item-key="class_id"
                        >
                            <template v-slot:[`item.start`]="{ item }">
                                <span small v-if="item.start !== null" v-html="formattedDate(item.start, 'dateTimeShort')"></span>
                                <v-icon small v-if="item.minutes_completed >= item.duration" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                            </template>
                            <template v-slot:[`item.duration`]="{ item }">
                                <span small v-html="item.duration/60 + 'h'"></span>
                            </template>
                            <template v-slot:[`item.students`]="{ item }">
                                <span small v-html="('(' + item.amount_students + ') ' + item.students)"></span>
                            </template>
                            <template v-slot:[`item.individual_instead`]="{ item }">
                                <v-icon small v-if="item.individual_instead === 1" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                            </template>
                            <template v-slot:[`item.outgoing_id`]="{ item }">
                                S/. {{ item.paymentClass }} <span v-if="item.outgoing_id !== null"><v-icon small  color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon></span>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                    small
                                    @click="openDialogClass(item)"
                                    class="mr-2"
                                >
                                    $vuetify.icons.icon_mdi_pencil
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </v-tab-item>
        </v-tabs-items>

        <v-dialog
            v-model="dialogCourse"
            max-width="1500px"
            persistent
        >
            <v-form>
                <v-card>
                    <v-card-title 
                        :style="{'background-color': editedResource.color}"
                        class="d-flex justify-space-between"
                    >
                        <span class="headline white--text">
                            <div>
                                <v-icon class="mr-2">{{ getIconResource(editedResource) }}</v-icon>
                                <v-icon class="mr-2" v-if="editedResource.class_location === 'Online'">{{ "$vuetify.icons.icon_mdi_headphones" }}</v-icon>
                                {{ editedResource.full_name }} 
                                <span v-if="editedResource.initial_spanish_level"> ({{ editedResource.initial_spanish_level }})</span> - {{ editedResource.class_group }}, {{ editedResource.class_type }}
                            </div>
                        </span>
                        <div class="caption white--text">#{{ editedResource.resource_id }}</div>
                    </v-card-title>
                    <v-card-text>
                        <div class="d-flex flex-column mt-4">
                            <div class="d-flex flex-row flex-wrap">
                                <v-card flat outlined class="mx-2 mb-0 mt-0 pt-0">
                                    <v-card-title class="ml-4 mb-2 pa-0">
                                        Hours
                                    </v-card-title>
                                    <v-card flat class="d-flex flex-column mx-4" style="width: 200px">
                                        <span>
                                            Sold: {{ editedResource.hours }} hrs
                                        </span>
                                        <span>
                                            Per class: {{ editedResource.hours_per_class }} hrs
                                        </span>
                                        <span>
                                            From: {{ this.formattedDate(editedResource.start) }}
                                        </span>
                                        <span>
                                            To: {{ this.formattedDate(editedResource.end) }}
                                        </span>
                                        <span>
                                            Location: {{ editedResource.class_location }}
                                        </span>
                                        <span>
                                            <v-btn 
                                                small 
                                                rounded
                                                class="my-6 mx-2"
                                                @click="goClient(editedResource.client_id, editedResource.resource_id)">Edit Resource
                                            </v-btn>
                                        </span>
                                    </v-card>
                                </v-card>
                                <v-container style="max-width: 700px" class="d-flex flex-column">
                                    <v-card flat style="max-width: 700px">
                                        <v-textarea
                                            class="mt-0 mx-3 mb-0 pb-0"
                                            v-model="editedResource.comments"
                                            clearable
                                            counter
                                            outlined
                                            height="100px"
                                            no-resize
                                            label="Comments (255 max)"
                                            :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                                        >
                                        </v-textarea>
                                    </v-card>
                                    <v-card outlined flat class="d-flex flex-row flex-wrap">
                                        <v-card flat>
                                            <v-tooltip left>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        :color="(selectedClassesResources.length > 0 && !oneOfClassesPaid(selectedClassesResources)) ? 'blue lighten-2' : 'ppc_grey'"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        dark
                                                        x-small
                                                        fab
                                                        class="ma-4"
                                                        @click="(selectedClassesResources.length > 0 && !oneOfClassesPaid(selectedClassesResources)) ? changeEditedClassesInResource('addStudent') : $store.dispatch('warningMessage', 'select class(es) please')"
                                                    >
                                                        <v-icon 
                                                            v-on="on"
                                                        >
                                                            $vuetify.icons.icon_mdi_plus
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add a student</span>
                                            </v-tooltip>
                                        </v-card>
                                        <v-card flat class="d-flex justify-center">
                                            <v-select
                                                v-model="resourceTeacher_id"
                                                :items="selectTeachers(editedResource)"
                                                item-text="contact"
                                                item-value="id"
                                                :menu-props="{ auto: true }"
                                                label="Teacher"
                                                single-line
                                                prepend-icon="mdi-school ppc_darkgreen--text"
                                                clearable
                                                style="max-width: 250px"
                                                class="mx-4"
                                                :disabled="selectedClassesResources.length === 0 || oneOfClassesPaid(selectedClassesResources)"
                                                @change="changeEditedClassesInResource('teacher')"
                                            ></v-select>
                                        </v-card>
                                        <v-card flat class="d-flex flex-row">
                                            <v-text-field
                                                v-model="resourceClassStartTime"
                                                label="From"
                                                style="max-width: 50px"
                                                type="time"
                                                format="24hr"
                                                class="mx-8"
                                                :disabled="selectedClassesResources.length === 0 || oneOfClassesPaid(selectedClassesResources)"
                                                @change="changeEditedClassesInResource('startTime')"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="resourceClassEndTime"
                                                label="Until"
                                                style="max-width: 50px"
                                                type="time"
                                                format="24hr"
                                                class="mx-8"
                                                :disabled="selectedClassesResources.length === 0 || oneOfClassesPaid(selectedClassesResources)"
                                                @change="changeEditedClassesInResource('endTime')"
                                            ></v-text-field>
                                        </v-card>
                                        <v-select
                                            v-model="resourceClass_room_id"
                                            :items="classRooms"
                                            item-text="name"
                                            item-value="id"
                                            :menu-props="{ auto: true }"
                                            label="Change Classroom"
                                            single-line
                                            prepend-icon="mdi-map-marker ppc_darkgreen--text"
                                            clearable
                                            style="max-width: 200px"
                                            :disabled="selectedClassesResources.length === 0"
                                            class="mx-6"
                                            @change="changeEditedClassesInResource('Room')"
                                        ></v-select>
                                        <v-select
                                            v-model="studentSelectedForRemovalId"
                                            :items="studentsSelectedForRemoval"
                                            item-text="full_name"
                                            item-value="resource_id"
                                            :menu-props="{ auto: true }"
                                            label="Remove Student"
                                            single-line
                                            prepend-icon="mdi-account-off ppc_darkgreen--text"
                                            clearable
                                            style="max-width: 200px"
                                            :disabled="selectedClassesResources.length === 0 || oneOfClassesPaid(selectedClassesResources)"
                                            class="mx-6"
                                            @change="changeEditedClassesInResource('removeStudent', $event)"
                                        ></v-select>
                                    </v-card>
                                </v-container>
                                <v-card outlined class="mx-2 mb-0 mt-0 pt-0" style="max-width: 500px">
                                    <v-data-table
                                        :headers="headersResourcesInCourse"
                                        :items="resourcesInCourseDisplay"
                                        class="mb-6 mx-4"
                                        v-if="resourcesInCourse.length > 0"
                                        dense
                                        hide-default-footer
                                        disable-pagination
                                        sort-by="full_name"
                                    >
                                        <template v-slot:[`item.full_name`]="{ item }">
                                            <span v-if="editedResource.resource_id === item.resource_id"><strong>{{ item.full_name }}</strong></span> 
                                            <span v-else>{{ item.full_name }}</span> 
                                        </template>
                                        <template v-slot:[`item.minutes_available`]="{ item }">
                                            <span v-if="(Math.floor((item.minutes_available) / 60 * 2) / 2 ) < 0" style="color: red">{{ (Math.floor((item.minutes_available) / 60 * 2) / 2 ) }}</span>
                                            <span v-else>{{ (Math.floor((item.minutes_available) / 60 * 2) / 2 ) }}</span>
                                        </template>
                                        <template v-slot:[`item.goCourse`]="{ item }">
                                            <v-btn 
                                                x-small 
                                                rounded 
                                                @click="dialogCourse = false, resourcesInCourse = [], resourcesInCourseOriginal = [], resourcesInCourseDisplay = [], openDialogCourse(item.resource_id), closeDialogClass()" 
                                                v-if="editedResource.resource_id !== item.resource_id"
                                            >
                                                Go Course
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </div>    
                            <v-card flat class="mt-8 d-flex justify-end">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="ppc_pink"
                                            v-bind="attrs"
                                            v-on="on"
                                            dark
                                            x-small
                                            fab
                                            @click="addNewClass(editedResource)"
                                        >
                                            <v-icon>
                                                $vuetify.icons.icon_mdi_plus
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Add a class</span>
                                </v-tooltip>
                            </v-card>
                            <v-data-table
                                :headers="headersClassesInResource"
                                :items="editedClassesInResource"
                                class="mb-6 mx-4 cursorPointer"
                                v-if="editedClassesInResource.length > 0"
                                dense
                                hide-default-footer
                                disable-pagination
                                show-select
                                :single-select=false
                                v-model="selectedClassesResources"
                                sort-by="start"
                                item-key="key"
                                @input="filterSelectionClassesResources(editedResource.resource_id)"
                            >
                                <template v-slot:[`item.start`]="{ item }">
                                    <span small v-if="item.start !== null" v-html="formattedDate(item.start, 'date') + ' '"></span>
                                    <!-- <v-icon small v-if="item.minutes_completed >= item.duration" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon> -->
                                </template>
                                <template v-slot:[`item.students`]="{ item }">
                                    <span small v-html="( '#' + item.amount_students + ' - ' + item.students)"></span>
                                </template>
                                <template v-slot:[`item.duration`]="{ item }">
                                    <span small v-html="item.duration/60 + 'h'"></span>
                                </template>
                                <template v-slot:[`item.individual_instead`]="{ item }">
                                    <span v-if="item.individual_instead === 1" v-html="'Yes'"></span>
                                    <span v-else v-html="'No'"></span>
                                </template>
                                <template v-slot:[`item.outgoing_id`]="{ item }">
                                    S/. {{ item.paymentClass.toFixed(2) }} <span v-if="item.outgoing_id !== null"><v-icon small  color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon></span>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                        small
                                        @click="item.newClass = false, openDialogClass(item)"
                                        class="mr-2"
                                    >
                                        $vuetify.icons.icon_mdi_pencil
                                    </v-icon>
                                </template>
                            </v-data-table>
                            <span class="ml-3">Total {{ editedClassesInResource.length }}</span>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn 
                            small 
                            class="mx-4" 
                            @click="removeClasses()" 
                            :disabled="selectedClassesResources.length === 0 || oneOfClassesPaid(selectedClassesResources)"
                        >
                            Remove Selected Classes
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="blue lighten-3" small @click="openTransactionOutgoings(editedResource.transaction_id)" class="ma-4" v-if="editedResource.transaction_id">
                            <v-icon color="white">$vuetify.icons.icon_mdi_credit_card</v-icon>
                            <span class="white--text">Transaction</span>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <div>
                            <v-btn
                                color="ppc_darkgreen darken-1"
                                text
                                @click="closeDialogCourse(), dialogCourse = false"
                            >
                                Cancel
                            </v-btn>
                            <!-- <v-btn
                                color="ppc_darkgreen darken-1"
                                text
                                @click="applyCourse(true)"
                                :disabled="allHoursUsedCourse"
                            >
                                Apply
                            </v-btn> -->
                            <v-btn
                                color="ppc_darkgreen darken-1"
                                text
                                @click="applyCourse(), dialogCourse = false"
                                :disabled="allHoursUsedCourse"
                            >
                                Apply
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-dialog v-model="dialogClass" width="700px" persistent v-if="resourcesInClass.length > 0">
            <v-form ref="dialogFormClass">
                <v-card>
                    <v-card-title 
                        :style="{'background-color': editedClass.color}"
                        class="d-flex justify-space-between"
                    >
                        <span class="headline white--text">
                            <div>
                                <v-icon class="mx-1">{{ editedClass.icon }}</v-icon>
                                <v-icon class="mx-1">{{ editedClass.iconOnline }}</v-icon>
                                <span class="ml-2" v-if="!editedClass.class_id">New Class</span>
                                <span class="ml-2" v-else>Edit Class</span>
                                <span v-if="editedClass.initial_spanish_level"> ({{ editedClass.initial_spanish_level }})</span> - {{ editedClass.class_group }}, {{ editedClass.class_type }}
                            </div>
                        </span>
                        <div class="caption white--text">#{{ editedClass.class_id }}</div>
                    </v-card-title>
                    <v-card-text class="d-flex flex-column pb-0" width="100%">
                        <v-card v-if="editedClass.outgoing_id === null" flat class="d-flex justify-center">
                            <v-select
                                v-model="editedClass.teacher_id"
                                :items="selectTeachers(editedClass)"
                                item-text="contact"
                                item-value="id"
                                :menu-props="{ auto: true }"
                                label="Select teacher"
                                single-line
                                prepend-icon="mdi-school ppc_darkgreen--text"
                                clearable
                                style="max-width: 250px"
                                :rules="inputRulesReq"
                                class="mb-0 pb-0"
                                @change='editedClass.paymentClass = calcPaymentClass(editedClass).paymentClass, editedClass.rate = calcPaymentClass(editedClass).rate'
                            ></v-select>
                        </v-card>
                        <v-card v-else flat class="d-flex justify-center ma-2">
                            <span v-if="this.editedClass.teacher_id">Teacher: {{ getTeacher(this.editedClass.teacher_id).contact }}</span>
                        </v-card>
                        <div class="d-flex flex-row flex-wrap mt-4 justify-center">
                            <v-menu 
                                transition="scale-transition"
                                offset-y
                                min-width="250px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                        :value="formattedDate(editedClass.day)"
                                        label="Day" 
                                        v-on='on' 
                                        v-bind="attrs"
                                        readonly
                                        style="max-width: 150px"
                                        :rules="inputRulesReq"
                                        :disabled="editedClass.outgoing_id !== null"
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model='editedClass.day'
                                    color='ppc_darkgreen'
                                    scrollable
                                    no-title
                                >
                                </v-date-picker>
                            </v-menu>
                            <v-text-field
                                v-model="editedClass.startTime"
                                label="From"
                                style="max-width: 50px"
                                type="time"
                                format="24hr"
                                class="mx-6"
                                :rules="inputRulesReq"
                                :disabled="editedClass.outgoing_id !== null"
                                @change="adjustDurationsClass()"
                            ></v-text-field>
                            <v-text-field
                                v-model="editedClass.endTime"
                                label="Until"
                                style="max-width: 50px"
                                type="time"
                                format="24hr"
                                class="mx-6"
                                :rules="inputRulesReq"
                                :disabled="editedClass.outgoing_id !== null"
                                @change="adjustDurationsClass()"
                            ></v-text-field>
                            <div class="d-flex flex-column ma-4 mt-2">
                                <div>
                                    <span>
                                        Duration: {{ (editedClass.duration / 60).toFixed(1) }} 
                                    </span>
                                    <span> hrs</span>
                                </div>
                                <div>
                                    <span>
                                        Spending: {{ (editedClass.minutes_used / 60).toFixed(1) }} 
                                    </span>
                                    <span> hrs</span>
                                </div>
                            </div>
                        </div>
                        <v-card flat class="d-flex justify-center">
                            <v-select
                                v-model="editedClass.class_room_id"
                                :items="classRooms"
                                item-text="name"
                                item-value="id"
                                :menu-props="{ auto: true }"
                                label="Select Classroom"
                                single-line
                                prepend-icon="mdi-map-marker ppc_darkgreen--text"
                                clearable
                                style="max-width: 200px"
                                :rules="inputRulesReq"
                                :disabled="editedClass.outgoing_id !== null"
                                class="mb-0 pb-0"
                            ></v-select>
                        </v-card>
                        <v-card flat class="d-flex flex-row flex-wrap mt-4 justify-center">
                            <v-text-field
                                v-model="editedClass.paymentClass"
                                label="Payment (PEN)"
                                style="max-width: 130px"
                                @change="editedClass.autoPayment = false"
                                :rules="inputRulesReq"
                                :disabled="editedClass.outgoing_id !== null || editedClass.teacher_id === null || !editedClass.teacher_id"
                            ></v-text-field>
                            <v-switch
                                v-model="editedClass.autoPayment"
                                label="auto"
                                class="mr-5"
                                @change='editedClass.paymentClass = calcPaymentClass(editedClass).paymentClass, editedClass.rate = calcPaymentClass(editedClass).rate'
                                hide-details
                                :disabled="editedClass.outgoing_id !== null || editedClass.teacher_id === null || !editedClass.teacher_id"
                            ></v-switch>
                            <span class="pa-5" v-if="editedClass.rate">
                                Rate: {{ editedClass.rate }} PEN/hr
                            </span>
                            <v-card flat class='mt-4' v-if="editedClass.individual_instead && (resourcesInClass.individual_instead === 1 || editedClass.individual_instead)">
                                <v-chip small rounded class="ma-1 white--text" color="brown lighten-2">Individual Instead</v-chip>
                            </v-card>
                            <v-card flat class="d-flex flex-row flex-wrap">
                                <v-card-subtitle class="ma-4 mt-5" flat v-if="editedClass.outgoing_id !== null">Paid: {{ editedClass.currency }} {{ editedClass.paid }} on {{ formattedDate(editedClass.datePaid, 'date') }}</v-card-subtitle>
                            </v-card>
                        </v-card>
                        <v-card flat class="d-flex flex-column">
                            <v-card flat v-if="editedClass.class_group === 'Group' && (dialogCourse || (!dialogCourse && editedClass.class_id))" class="d-flex justify-end">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="blue lighten-2"
                                            v-bind="attrs"
                                            v-on="on"
                                            dark
                                            x-small
                                            fab
                                            class="mr-6 my-0"
                                            @click="openSelectStudent(editedClass)"
                                            :disabled="editedClass.outgoing_id !== null"
                                        >
                                            <v-icon 
                                                v-on="on"
                                            >
                                                $vuetify.icons.icon_mdi_plus
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Add a student</span>
                                </v-tooltip>
                            </v-card>
                            <v-data-table
                                :headers="headersResourcesInClass"
                                :items="resourcesInClass"
                                class="mb-6 mx-4 cursorPointer"
                                v-if="resourcesInClass.length > 0"
                                dense
                                hide-default-footer
                                disable-pagination
                                sort-by="start"
                            >
                                <template v-slot:[`item.full_name`]="{ item }">
                                    <span class="d-flex flex-row">
                                        <v-btn x-small rounded @click="goClient(item.client_id, item.resource_id)">{{ item.full_name }}</v-btn>
                                        <v-icon small class="ml-2">{{ item.iconGrad }}</v-icon>
                                    </span>
                                </template>
                                <template v-slot:[`item.minutes_available`]="{ item }">
                                    <span v-if="(Math.floor((item.minutes_available) / 60 * 2) / 2 ) < 0" style="color: red">{{ (Math.floor((item.minutes_available) / 60 * 2) / 2 ) }}</span>
                                    <span v-else>{{ (Math.floor((item.minutes_available) / 60 * 2) / 2 ) }}</span>
                                </template>
                                <template v-slot:[`item.goCourse`]="{ item }">
                                    <v-btn 
                                        x-small 
                                        rounded 
                                        @click="openDialogCourse(item.resource_id), closeDialogClass()" 
                                        v-if="!dialogCourse && editedClass.class_id"
                                    >
                                        Go Course
                                    </v-btn>
                                </template>
                                <template v-slot:[`item.removeStudent`]="{ item }">
                                    <v-icon
                                        small
                                        @click="removeStudentFromClass(item)"
                                        class="mr-2"
                                        v-if="resourcesInClass.length > 1 && editedClass.outgoing_id === null"
                                        :disabled="editedResource.client_id === item.client_id"
                                    >
                                        $vuetify.icons.icon_mdi_delete
                                    </v-icon>
                                    <span v-else>NA</span>
                                </template>
                            </v-data-table>
                            <span class="ml-3 mb-4">Total {{ resourcesInClass.length }} student(s)</span>
                        </v-card>
                    </v-card-text>
                    <v-card-actions class="d-flex flex-column">
                        <v-card outlined rounded-xl flat class="d-flex flex-column pa-0" width="90%" v-if="dialogCourse && !editedClass.class_id">
                            <v-card flat>
                                <v-checkbox
                                    class="ml-6"
                                    v-model="copyClasses"
                                    label="copy classes with all hours available"
                                    :disabled="editedClass.teacher_id === null || !editedClass.teacher_id"
                                ></v-checkbox>
                            </v-card>
                            <v-card-text class="d-flex flex-row flex-wrap ma-0 pb-0">
                                <v-card :disabled="!copyClasses" flat v-for="(copyDay, i) in week" :key="i" style="width: 80px">
                                    <v-checkbox 
                                        class="ma-2 pa-0"
                                        v-model="weekSelect.day[copyDay]"
                                        :label="`${copyDay}`"
                                        @change="createCopyClasses()"
                                    ></v-checkbox>
                                </v-card>
                            </v-card-text>
                        </v-card>
                        <v-card flat class="mt-2" width="95%">
                            <v-textarea
                                class="rounded-xl mx-3"
                                v-model="editedClass.comments"
                                clearable
                                counter
                                outlined
                                no-resize
                                label="Comments (100 max)"
                                :rules="[v => (v || '' ).length <= 100 || 'Comments must be 100 characters or less']"
                            ></v-textarea>
                        </v-card>
                        <v-card flat width="100%" class="d-flex flex-row flex-wrap">
                            <v-btn 
                                class="px-4 my-0 mx-5" 
                                @click="prepDeleteClass(originalClass)"
                                :disabled="editedClass.outgoing_id !== null"
                                v-if="editedClass.class_id"
                            >
                                <span class="mr-2">Remove Class</span>
                                <v-icon>
                                    $vuetify.icons.icon_mdi_delete
                                </v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn 
                                color="ppc_darkgreen darken-1" 
                                text 
                                @click="dialogClass = false, 
                                cancelDialogClass()"
                                :disabled="$store.state.loadingApp > 0"
                            >
                                Cancel
                            </v-btn>
                            <v-btn 
                                v-if="dialogCourse" 
                                color="ppc_darkgreen darken-1" 
                                text 
                                @click="saveApplyClass(editedClass)"
                            >
                                <span v-if="!editedClass.class_id">Add</span>
                                <span v-else>Edit</span>
                            </v-btn>
                            <v-btn 
                                v-else 
                                color="ppc_darkgreen darken-1" 
                                text 
                                @click="saveApplyClass(editedClass)"
                                :disabled="allHoursUsedClass(resourcesInClass)"
                            >
                                Apply
                            </v-btn>
                        </v-card>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-dialog v-model="dialogSelectResources" width="1200px" persistent>
            <v-card class="py-4">
                <v-card-title>
                    Select Student(s)
                </v-card-title>
                <v-data-table
                    :headers="headersResourcesSelect"
                    :items="resourcesSelectList"
                    class="mb-6 mx-4 cursorPointer"
                    v-if="resourcesSelectList.length > 0"
                    dense
                    hide-default-footer
                    :items-per-page="-1"
                    disable-pagination
                    show-select
                    :single-select=selectedResourcesSingleSelect
                    item-key="resource_id"
                    v-model="selectedResources"
                    sort-by="full_name"
                    @input="filterSelectionResources($event)"
                    @toggle-select-all="filterSelectionResources($event)"
                >
                <template v-slot:[`item.start`]="{ item }">
                    {{ formattedDate(item.start, 'date') }}
                </template>
                <template v-slot:[`item.end`]="{ item }">
                    {{ formattedDate(item.end, 'date') }}
                </template>
                <template v-slot:[`item.minutes_available`]="{ item }">
                    {{ (item.minutes_available / 60).toFixed(1) }}
                </template>
                </v-data-table>
                <v-card-actions class="d-flex flex-column">
                    <v-card flat class="d-flex flex-row flex-wrap" width="100%">
                        <v-spacer></v-spacer>
                        <v-btn 
                            color="ppc_darkgreen darken-1" 
                            text 
                            @click="cancelSelectStudent"
                        >
                            Cancel
                        </v-btn>
                        <v-btn 
                            color="ppc_darkgreen darken-1" 
                            text 
                            @click="includeStudentClass(selectedResources)"
                            :disabled="selectedResources.length===0"
                        >
                            Add
                        </v-btn>
                    </v-card>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>

import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import dayjs from 'dayjs';
import compare from '../mixins/compare';
// import zlib from 'zlib';

export default {
    data: () => ({
        itemsTab: [
            'Courses', 'Calendar', 'Teachers'
        ],
        itemsTabTeachers: [],
        indexTeachers: [],
        freeze: false,
        dialogCourse: false,
        dialogClass: false,
        dialogSelectResources: false,
        weekSelect:{
            day: [],
                },
        resourcesScope: [],
        resourcesLoaded: [],
        editedResource: {},
        editedClass: {},
        originalClass: {},
        headersResourcesAll: [
            { 
                text: '',
                align: 'start',
                value: 'class_language',
                width: '0px'
            },
            { text: 'Service', value: 'servicesName', width: '150px' },
            { text: 'Student', value: 'full_name'},
            { text: 'Agency', value: 'agency', width: '50px' },
            { text: 'Start on', value: 'start', width: '110px' },
            { text: 'End before', value: 'end', width: '110px' },
            { text: 'Hours sold', value: 'hours'},
            { text: 'Per class', value: 'hours_per_class'},
            // { text: 'Classes', value: 'amountOfClasses'},
            { text: 'Graduation', value: 'graduationClassStart', width: '110px' },
            { text: 'Planned', value: 'minutes_planned'},
            { text: 'Finished', value: 'minutes_completed'},
            { text: 'Available', value: 'minutes_available'},
            { text: 'Status', value: 'statusResourceId'},
            { text: 'Schedule', value: 'sendScheduleStudent'},
            { text: 'Comments', value: 'comments'},
            { text: 'Actions', value: 'actions', width: '80px'},
        ],
        headersClassesInResource: [
            // { text: 'Key', value: 'key' },
            { text: 'Date (completed)', value: 'start', width: '150px' },
            { text: 'From', value: 'startTime', width: '80px' },
            { text: 'Until', value: 'endTime', width: '80px' },
            { text: 'Duration', value: 'duration', width: '80px' },
            { text: 'Individual', value: 'individual_instead', width: '10px' },
            { text: 'Teacher', value: 'contact', width: '100px' },
            { text: '# - Students (initial level)', value: 'students' },
            { text: 'Payment (paid)', value: 'outgoing_id', width: '120px' },
            { text: 'Room', value: 'class_room', width: '80px' },
            { text: 'Edit', value: 'actions', width: '80px'},
        ],
        headersClassesTeachers: [
            { text: 'Class', value: 'start', width: '140px' },
            { text: 'Duration', value: 'duration' },
            { text: 'Teacher', value: 'contact' },
            { text: 'Students (initial level)', value: 'students' },
            { text: 'Ind', value: 'individual_instead' },
            { text: 'Payment', value: 'outgoing_id' },
            { text: 'Room', value: 'class_room' },
            { text: 'Actions', value: 'actions'},
        ],
        headersResourcesInClass: [
            { text: 'Student', value: 'full_name' },
            { text: 'Level', value: 'initial_spanish_level' },
            { text: 'Available (hrs)', value: 'minutes_available'},
            { text: '', value: 'goCourse', width: '80px'},
            { text: 'Remove Student', value: 'removeStudent'},
        ],
        headersResourcesInCourse: [
            { text: 'Classmate(s)', value: 'full_name', width: '140px' },
            { text: 'Available (hrs)', value: 'minutes_available', width: '120px'},
            { text: '', value: 'goCourse', width: '80px'},
            // { text: 'status', value: 'status', width: '80px'},
        ],
        headersResourcesSelect: [
            // { text: 'id', value: 'resource_id' },
            { text: 'Student', value: 'full_name' },
            { text: 'Language', value: 'class_language' },
            { text: 'Type', value: 'class_type' },
            { text: 'Group', value: 'class_group' },
            { text: 'Location', value: 'class_location' },
            { text: 'Start', value: 'start' },
            { text: 'End', value: 'end' },
            { text: 'Level', value: 'initial_spanish_level' },
            { text: 'Hours left', value: 'minutes_available'},
        ],
        // headersClassesCopy: [
        //     { text: 'Copy', value: 'nr' },
        //     { text: 'Class', value: 'start' },
        //     { text: 'Day', value: 'dayName' },
        // ],
        classesInResource: [],
        editedClassesInResource: [],
        resourcesInClass: [],
        editedResourcesInClass: [],
        selectedClassesResources: [],
        selectedClassesTeachers: [],
        selectedClassesCopy: [],
        resourcesSelectList: [],
        selectedResources: [],
        selectedResourcesSingleSelect: true,
        availableHoursClass: null,
        events: [],
        focusCalendar: '',
        categoriesCalendar: [],
        createEvent: null,
        createStart: null,
        extendOriginal: null,
        categoryStart: null,
        dragEvent: null,
        dragEventOriginal: null,
        types: ['month', 'week', 'day', '4day'],
        week: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        // renderTabTeachers: Math.random(),
        teacherOldId: null,
        searchResourceTableTeaching: '',
        scopeDates: [],
        resourceTeacher_id: null,
        resourceClassStartTime: null,
        resourceClassEndTime: null,
        resourceClass_room_id: null,
        studentSelectedForRemovalId: null,
        studentsSelectedForRemoval: [],
        extendingBottom: false,
        claimDraggingTime: false,
        resourcesInCourse: [],
        resourcesInCourseOriginal: [],
        resourcesInCourseDisplay: [],
        allHoursUsedCourse: false,
        copyClasses: false,
        legendColorsCourses: [],
        legendColorsCalendar: [],
        typeColors: [ 
            { id: 'GroupNormal', name: 'Group Normal', color: '#4CAF50'},
            { id: 'GroupSpecial', name: 'Group Special', color: '#3F51B5'},
            { id: 'IndividualNormal', name: 'Individual Normal', color: '#00BCD4'},
            { id: 'IndividualSpecial', name: 'Individual Special', color: '#FF9800'},
            { id: 'DoubleBookingAula', name: 'Classroom Double Booked', color: 'red'},
            { id: 'DoubleBookingProfe', name: 'Profe Double Booked', color: 'purple'},
            // { id: 'OutsideCourseDates', name: 'Outside Course Dates', color: 'grey'},
        ],

    }),

    mixins: [inputRules, formattedDate, compare],

    computed: {
        classRooms () {
            let classrooms = this.$store.getters.classRooms;
            classrooms = classrooms.sort(this.dynamicSort('name'));
            return classrooms;
        },
        scopeLanguage () {
            return this.$store.getters.scopeLanguage
        },
        scopeOnline () {
            return this.$store.getters.scopeOnline
        },
        scopeResources () {
            return this.$store.getters.scopeResources
        },
        scopeClasses () {
            return this.$store.getters.scopeClasses
        },
        // scopeType () {
        //     return this.$store.getters.scopeType
        // },
        scopeCalendar () {
            return this.$store.getters.scopeCalendar
        },
        activeTabTeaching () {
            return this.$store.getters.activeTabTeaching
        },
        activeTabTeachers () {
            return this.$store.getters.activeTabTeachers
        },
        teachersTable () {
            return this.$store.getters.teachers
        },
        selectAllTeachers () {
            return this.$store.getters.selectAllTeachers
        },
        // selectAllRooms () {
        //     return this.$store.getters.selectAllRooms
        // },

    },

    beforeCreate () {
        if (this.$store.state.accessTeaching === 0) {
            this.$router.push({ name: 'Illegal' })
        }
    },

    created () {
        dayjs.locale("en");
        this.initialize()
    },

    methods: {

        async initialize () {
            var date = new Date();
            this.focusCalendar = this.formattedDate(date,'date');
            this.$store.dispatch('setLoadingApp', 1);
            await this.$store.dispatch('getTeachers');
            this.freeze = true;
            await this.$store.dispatch('getContacts');
            await this.$store.dispatch('breadcrumbs', ['Teaching']);
            await this.formCategoriesCalendar();
            await this.loadDataAll();
            this.freeze = false;
            if (this.$route.params.resourceId) {
                if(this.activeTabTeaching !== 0) {
                    this.$store.dispatch('activeTabTeaching', 'Resources');
                }
                this.openDialogCourse(this.$route.params.resourceId);
            }
            this.checkAllTeachersSelected();
            // this.checkAllRoomsSelected();
            this.createLegendColors();
            this.$store.dispatch('setLoadingApp', 'dis');
        },

        async loadDataAll () {
            console.log('loadDataAll')
            this.$store.dispatch('setLoadingApp', 'add');
            var date = new Date();
            let start = date.getTime();
            console.log('***1 loadDataAll');
            await Promise.all([this.loadDataTabResources(), this.loadDataTabTeachers()]).then(() => {
                this.loadDataTabCalendar()
                date = new Date();
                let end = date.getTime();
                const totalTime = ((end-start)/1000);
                console.log('***2', totalTime);
                this.$store.dispatch('setLoadingApp', 'dis');
            });
        },

        async loadDataTabResources () {
            console.log('loadDataTabResources')
            this.$store.dispatch('setLoadingApp', 'add');
            if (this.scopeResources === 'completed') {
                this.resourcesLoaded = await this.$store.dispatch('getResourcesClassesAll');
            } else {
                this.resourcesLoaded = await this.$store.dispatch('getResourcesClassesIncompleted');
            }
            for (let i = 0; i < this.resourcesLoaded.length; i++) {
                this.resourcesLoaded[i].start = this.formattedDate(this.resourcesLoaded[i].start, 'date')
                this.resourcesLoaded[i].end = this.formattedDate(this.resourcesLoaded[i].end, 'date')
            }
            this.resourcesScope = this.filterResources(this.resourcesLoaded);
            if (this.dialogCourse) {
                let pos = this.resourcesScope.map(function(e) { return e.resource_id; }).indexOf(this.editedResource.resource_id);
                this.editedResource = Object.assign({}, this.resourcesScope[pos]);
                this.openDialogCourse(this.editedResource.resource_id);
            }
            this.selectedClassesResources = [];
            this.$store.dispatch('setLoadingApp', 'dis');
        },

        filterResources(resources) {
            if (this.scopeResources === 'notPlanned') {
                resources = resources.filter(function(select_item) {
                    return select_item.statusResourceId === 9 || select_item.statusResourceId === 10 || select_item.statusResourceId === 11 || select_item.statusResourceId === 12 || select_item.minutes_planned === 0;
                });
            }
            if (this.scopeResources === 'courseNotFinished') {
                resources = resources.filter(function(select_item) {
                    return select_item.statusResourceId !== 1 && select_item.statusResourceId !== 15 && select_item.minutes_available > 0 && select_item.minutes_completed === select_item.minutes_planned &&  select_item.minutes_planned > 0;
                });
            }
            if (this.scopeResources === 'ongoing') {
                resources = resources.filter(function(select_item) {
                    return select_item.statusResourceId !== 1 && select_item.statusResourceId !== 15;
                });
            }
            // if (this.scopeResources === 'startingAlmost') {
            //     resources = resources.filter(function(select_item) {
            //         return select_item.statusResourceId === 11;
            //     });
            // }
            // if (this.scopeResources === 'graduations') {
            //     resources = resources.filter(function(select_item) {
            //         return select_item.minutes_available < 15 && select_item.hours > 0;
            //     });
            // }
            if (this.scopeResources === 'extending') {
                resources = resources.filter(function(select_item) {
                    return select_item.minutes_planned > 0 && select_item.hours > 0 && select_item.lastClassDate > select_item.end;
                });
            }
            if (this.scopeResources === 'completed') {
                resources = resources.filter(function(select_item) {
                    return select_item.statusResourceId === 1 || select_item.statusResourceId === 15;
                });
            }
            if (this.scopeLanguage === 'Spanish') {
                resources = resources.filter(function(select_item) {
                    return select_item.class_language === 'Spanish';
                });
            } else if (this.scopeLanguage === 'English') {
                resources = resources.filter(function(select_item) {
                    return select_item.class_language === 'English';
                });
            } else if (this.scopeLanguage === 'Quechua') {
                resources = resources.filter(function(select_item) {
                    return select_item.class_language === 'Quechua';
                });
            }
            if (this.scopeOnline === 'online') {
                resources = resources.filter(function(select_item) {
                    return select_item.class_location === 'Online';
                });
            } else if (this.scopeOnline === 'inPerson') {
                resources = resources.filter(function(select_item) {
                    return select_item.class_location === 'School';
                });
            }
            if (this.scopeDates.start || this.scopeDates.end) {
                let start = this.scopeDates.start;
                let end = this.scopeDates.end;
                if (this.scopeDates.start && this.scopeDates.end && this.scopeDates.start > this.scopeDates.end) {
                    this.$store.dispatch('errorMessage', 'between earlier please');
                    return resources;
                }
                if (this.scopeDates.start) {
                    resources = resources.filter(function(select_item) {
                        return select_item.start >= start;
                    });
                }
                if (this.scopeDates.end) {
                    resources = resources.filter(function(select_item) {
                        return select_item.start <= end;
                    });
                }
            } 
            return resources;
        },

        async loadDataTabTeachers () {
            console.log('loadDataTabTeachers')
            this.$store.dispatch('setLoadingApp', 'add');
            let sqlTxt = '(';
            for (let index = 0; index < this.teachersTable.length; index++) {
                if(this.teachersTable[index].obsolete === null) {
                    sqlTxt = sqlTxt.concat('teacher_id = ', this.teachersTable[index].id, ' OR ');
                }
            }
            sqlTxt = sqlTxt.substring(0 , sqlTxt.length - 4);
            if (this.scopeClasses === 'ongoing') {
                sqlTxt = sqlTxt.concat(') AND (minutes_completed = 0 OR outgoing_id IS NULL)')
            } else if (this.scopeClasses === 'notPaid') {
                sqlTxt = sqlTxt.concat(') AND outgoing_id IS NULL')
            } else if (this.scopeClasses === 'finishedNotPaid') {
                sqlTxt = sqlTxt.concat(') AND minutes_completed > 0 AND outgoing_id IS NULL')
            } else if (this.scopeClasses === 'finishedPaid') {
                sqlTxt = sqlTxt.concat(') AND minutes_completed > 0 AND outgoing_id IS NOT NULL')
            } else if (this.scopeClasses === 'all') {
                sqlTxt = sqlTxt.concat(')')
            }
            let classesTeachers = JSON.parse(JSON.stringify(await this.$store.dispatch('getClassesSafe', sqlTxt)));
            // console.log('loadDataTabTeachers', sqlTxt, classesTeachers[125].class_id, classesTeachers[125].studentsObject)
            await this.configureClassesFromDB(classesTeachers);
            this.itemsTabTeachers = [];
            this.indexTeachers = [];
            for (let index = 0; index < this.teachersTable.length; index++) {
                let teacherId = this.teachersTable[index].id;
                this.teachersTable[index].classes = classesTeachers.filter(function(select_item) {
                    return select_item.teacher_id === teacherId;
                })
                // await this.teachersTable[index].classes.map(this.correctAutoPaymentClasses);
                this.teachersTable[index].classes = this.teachersTable[index].classes.sort(this.dynamicSort('start'));
                let colorNr = 0;
                if (this.teachersTable[index].classes.length) {
                    let month = dayjs(this.teachersTable[index].classes[0].start).month();
                    for (let index2 = 0; index2 < this.teachersTable[index].classes.length; index2++) {
                        if (dayjs(this.teachersTable[index].classes[index2].start).month() !== month) {
                            month = dayjs(this.teachersTable[index].classes[index2].start).month();
                            colorNr = colorNr + 1;
                            if (colorNr === 4) {
                                colorNr = 0
                            }
                        }
                        // console.log(this.teachersTable[index], month, colorNr,('backgroundColor' + (colorNr + 1)).toString())
                        this.teachersTable[index].classes[index2].teacherRowClass = ('backgroundColor' + (colorNr + 1)).toString();
                    }
                }
                this.teachersTable[index].totalPayment = this.calcTotalPaymentClasses(this.teachersTable[index].classes);
                if (((this.scopeLanguage === 'Spanish' && this.teachersTable[index].spanish === 1) || (this.scopeLanguage === 'Quechua' && this.teachersTable[index].quechua === 1) || (this.scopeLanguage === 'English' && this.teachersTable[index].english === 1) || this.scopeLanguage === 'allLanguages') && this.teachersTable[index].obsolete === null) {
                    if (this.teachersTable[index].lastSchedule) {
                        this.itemsTabTeachers.push(this.teachersTable[index].contact + ' (' + this.teachersTable[index].classes.length + ')');
                    } else {
                        this.itemsTabTeachers.push(this.teachersTable[index].contact + ' (' + this.teachersTable[index].classes.length + ') !'); 
                    }
                    this.indexTeachers.push(this.teachersTable[index]);
                }
            }
            this.$store.dispatch('setLoadingApp', 'dis');
        },

        correctAutoPaymentClasses(classes) {
            if (classes.autoPayment === 0) {
                classes.autoPayment = false
            } else if (classes.autoPayment === 1) {
                classes.autoPayment = true
            }
        },

        calcTotalPaymentClasses(classes) {
            let totalPayment = 0;
            for (let index = 0; index < classes.length; index++) {
                totalPayment = totalPayment + classes[index].paymentClass;
            }
            return totalPayment;
        },

        // Calendar ##############################################

        async loadDataTabCalendar () {
            console.log('loadDataTabCalendar');
            this.$store.dispatch('setLoadingApp', 'add');
            let sqlTxt = '(teacher_id = ';
            for (let index = 0; index < this.teachersTable.length; index++) {
                if ( this.teachersTable[index].obsolete === null && ((this.teachersTable[index].visible && this.scopeLanguage === 'allLanguages') || (this.teachersTable[index].visible && this.teachersTable[index].spanish === 1 && this.scopeLanguage === 'Spanish') || (this.teachersTable[index].visible && this.teachersTable[index].english === 1 && this.scopeLanguage === 'English') || (this.teachersTable[index].visible && this.teachersTable[index].quechua === 1 && this.scopeLanguage === 'Quechua'))) {
                    sqlTxt = sqlTxt.concat(this.teachersTable[index].id, ' OR teacher_id = ')
                }
            }
            sqlTxt = sqlTxt.substring(0, sqlTxt.length - 17);
            sqlTxt = sqlTxt.concat(')');
            if (this.scopeCalendar === 'category') {
                sqlTxt = sqlTxt.concat(" AND DATE(startTime) = DATE('", this.formattedDate(this.focusCalendar,'dateTime'), "')");
            } else if (this.scopeCalendar === 'week') {
                let startWeek = dayjs(this.focusCalendar).day(0);
                let endWeek = dayjs(this.focusCalendar).day(6);
                sqlTxt = sqlTxt.concat(" AND DATE(startTime) >= DATE('", this.formattedDate(startWeek,'dateTime'), "')");
                sqlTxt = sqlTxt.concat(" AND DATE(startTime) <= DATE('", this.formattedDate(endWeek,'dateTime'), "')");
            } else if (this.scopeCalendar === 'month') {
                let startMonth = dayjs(this.focusCalendar).startOf("month");
                let endMonth = dayjs(this.focusCalendar).endOf("month");
                sqlTxt = sqlTxt.concat(" AND DATE(startTime) >= DATE('", this.formattedDate(startMonth,'dateTime'), "')");
                sqlTxt = sqlTxt.concat(" AND DATE(startTime) <= DATE('", this.formattedDate(endMonth,'dateTime'), "')");
            }
            let classesCalendarLoaded = JSON.parse(JSON.stringify(await this.$store.dispatch('getClassesSafe', sqlTxt)));
            classesCalendarLoaded = await this.configureClassesFromDB(classesCalendarLoaded);
            classesCalendarLoaded = await this.putColorsToClasses(classesCalendarLoaded);
            for (let i = 0; i < classesCalendarLoaded.length; i++) {
                await this.checkGraduation(classesCalendarLoaded[i]);
            }
            this.events = JSON.parse(JSON.stringify(classesCalendarLoaded));
            this.$store.dispatch('setLoadingApp', 'dis');
        },

        async checkGraduation(itemClass) {
            let classId = itemClass.class_id;
            let graduationClass = this.resourcesLoaded.filter(function(select_item){
                return select_item.graduationClassId === classId
            });
            if (graduationClass.length > 0) {
                itemClass.iconGrad = "$vuetify.icons.icon_mdi_school"
            } else {
                itemClass.iconGrad = null
            }
        },

        configureClassesFromDB(classes) {
            // console.log('configureClassesFromDB', classes)
            for (let i = 0; i < classes.length; i++) {
                if (classes[i].studentsObject !== null) {
                    classes[i].studentsObject = JSON.parse(classes[i].studentsObject);
                }
                classes[i].category = classes[i].class_room;
                classes[i].timed = true;
                classes[i].day = this.formattedDate(classes[i].start, 'date');
                classes[i].startTime = this.formattedDate(classes[i].startTime, 'time');
                classes[i].endTime = this.formattedDate(classes[i].endTime, 'time');
                // console.log('configureClassesFromDB test1', classes[i].studentsObject);
                classes[i].students = this.createStudentsStringfromObject(classes[i].studentsObject);
                classes[i] = this.styleEvent(classes[i]);
                classes[i].key = this.createUUID();
            }
            // console.log('configureClassesFromDB test3', classes);
            classes.map(this.correctAutoPaymentClasses);
            return classes;
        },

        putColorsToClasses(classes) {
            // console.log('putColorsToClasses');
            for (let i = 0; i < classes.length; i++) {
                if (classes[i].studentsObject === null) {
                    classes[i].color = 'black';
                } else {
                    // console.log('configureClassesFromDB test2',classes[i])
                    classes[i].color = this.sortColor(classes[i].class_group + classes[i].class_type);
                }
                // check double aula bookings
                let classRoomId = classes[i].class_room_id;
                let classStart = classes[i].start;
                let classEnd = classes[i].end;
                if (classRoomId !== 1 && classes[i].color !== 'black') {
                    let doubleAula = classes.filter(function(select_item) {
                        return select_item.class_room_id === classRoomId && !(select_item.start >= classEnd || select_item.end <= classStart);
                    });
                    if (doubleAula.length > 1) {
                        for (let j = 0; j < doubleAula.length; j++) {
                            doubleAula[j].color = 'red';
                        }
                    }
                }
                // check double profe bookings
                let teacherId = classes[i].teacher_id;
                let doubleProfe = classes.filter(function(select_item) {
                    return select_item.teacher_id === teacherId && !(select_item.start >= classEnd || select_item.end <= classStart);
                });
                if (doubleProfe.length > 1 && classes[i].color !== 'black') {
                    for (let j = 0; j < doubleProfe.length; j++) {
                        doubleProfe[j].color = 'purple';
                    }
                }
            }
            return classes;
        },

        createStudentsStringfromObject(studentsObject) {
            // console.log('createStudentsStringfromObject', studentsObject)
            if (studentsObject !== null) {
                let students = studentsObject;
                let result = '';
                for (let i = 0; i < students.length; i++) {
                    (i > 0) ? result = result.concat(', ') : result = result.concat('')
                    if (students[i].initial_spanish_level && students[i].initial_spanish_level !== '?') {
                        result = result.concat(students[i].full_name, ' (' + students[i].initial_spanish_level, ')')
                    } else {
                        result = result.concat(students[i].full_name)
                    }
                }
                return result;
            } else {
                return 'ERROR';
            }
        },

        styleEvent(itemClass) {
            // console.log('styleEvent', itemClass)
            if (itemClass !== null && itemClass.studentsObject !== null) {
                if (itemClass.class_language === "Spanish") {
                    itemClass.icon = "$vuetify.icons.icon_spanish"
                } else if (itemClass.class_language === "English") {
                    itemClass.icon = "$vuetify.icons.icon_english"
                } else {
                    itemClass.icon = "$vuetify.icons.icon_quechua"
                }
                if (itemClass.class_location === 'Online') {
                    itemClass.iconOnline = "$vuetify.icons.icon_mdi_headphones"
                } else {
                    itemClass.iconOnline = null;
                }
                itemClass.name = this.createStudentsFromObject(itemClass.studentsObject);
                itemClass.title = ' '.concat(itemClass.class_group, ' ', itemClass.class_room, ' ', itemClass.contact, ' #', itemClass.studentsObject.length);
                return itemClass
            } else {
                itemClass.icon = null;
                itemClass.iconOnline = null;
                itemClass.name = 'Open Class to remove';
                itemClass.title = 'Error No Students';
                return itemClass;
            }
        },

        createStudentsFromObject(studentsObject) {
            // console.log('createStudentsFromObject', studentsObject);
            if (studentsObject === null) {
                return 'NO STUDENTS';
            } else {
                let students = '';
                for (let i = 0; i < studentsObject.length; i++) {
                    if (i > 0) { students += ', ' }
                    students += studentsObject[i].full_name
                }
                return students;
            }
        },

        createStudentsObject(resources) {
            // console.log('createStudentsObject', resources)
            let students = [];
            let student = {};
            for (let i = 0; i < resources.length; i++) {
                if (!resources[i].resources_classes_id) {
                    resources[i].resources_classes_id = null;
                }
                student = {
                    full_name: resources[i].full_name,
                    client_id: resources[i].client_id,
                    initial_spanish_level: resources[i].initial_spanish_level,
                    resource_id: resources[i].resource_id,
                    resources_classes_id: resources[i].resources_classes_id,
                }
                students.push(student);
            }
            return students;
        },

        toTimestamp(strDate){
            var datum = Date.parse(strDate);
            return datum;
        },

        calcDurationClass(event) {
            // console.log('calcDurationClass',event )
            if (event && event !== null) {
                let start = this.timeToTimeStamp(event.day, event.startTime);
                let end = this.timeToTimeStamp(event.day, event.endTime);
                // console.log('calcDurationClass',event ,start ,end ,Math.floor((end - start) / (1000 * 60)))
                return Math.floor((end - start) / (1000 * 60));
            }
        },

        sortColor(typeCourse) {
            if (typeCourse) {
                let color = this.typeColors.filter(function(select_item) {
                    return select_item.id === typeCourse;
                });
                if (color) {
                    return color[0].color;
                }
            }
        },

        createLegendColors() {
            let legendColors = [];
            for (let i = 0; i < this.typeColors.length; i++) {
                this.typeColors[i].color = this.sortColor(this.typeColors[i].id);
                legendColors.push(this.typeColors[i]);
                if (i === this.typeColors.length - 3) {
                    this.legendColorsCourses = JSON.parse(JSON.stringify(legendColors))
                }
            }
            this.legendColorsCalendar = JSON.parse(JSON.stringify(legendColors))
        },

        startDrag ({ event, timed }) {
            console.log('startDrag', timed)
            if (event && timed) {
                console.log('startDrag continued')
                this.dragEvent = event
                this.dragEventOriginal = JSON.parse(JSON.stringify(event))
                this.dragTime = null
                this.extendOriginal = null
            }
        },

        getCategory ({ category }) {
            // console.log('getCategory test', this.categoryStart)
            if (this.scopeCalendar === 'category'){
                this.categoryStart = category;
            } else {
                this.categoryStart = null;
            }
            
        },

        changeSelectAllTeachers(event) {
            console.log('changeSelectAllTeachers', event)
            this.$store.dispatch('changeSelectAllTeachers', !this.selectAllTeachers); 
            this.showAllTeachers(this.selectAllTeachers); 
            if (event) {
                this.loadDataTabCalendar();
            }
        },

        startTimer (tms) {
            const mouse = this.toTime(tms)
            console.log('startTimer', tms);
            if (this.dragEvent && this.dragTime === null) {
                console.log('this.dragEvent.start')
                const start = this.dragEvent.start
                this.dragTime = mouse - start
            } else {
                console.log('createStart', this.events)
                this.createStart = this.roundTime(mouse)
                this.createEvent = {
                    name: `New Class`,
                    color: 'grey',
                    start: this.createStart,
                    end: this.createStart,
                    timed: true,
                    category: this.categoryStart,
                }
                this.events.push(this.createEvent)
            }
        },

        extendBottom (event) {
            console.log('extendBottom')
            this.extendingBottom = true;
            this.createEvent = event
            this.createStart = event.start
            this.extendOriginal = event.end
            this.dragEventOriginal = JSON.parse(JSON.stringify(event))
        },

        mouseMove (tms) {
            const mouse = this.toTime(tms)
            // console.log('mouseMove')
            if (this.dragEvent && this.dragTime !== null) {
                // console.log('mouseMove dragEvent', this.dragEvent)
                const start = this.dragEvent.start
                const end = this.dragEvent.end
                const duration = end - start
                const newStartTimer = mouse - this.dragTime
                const newStart = this.roundTime(newStartTimer)
                const newEnd = newStart + duration
                this.dragEvent.start = newStart
                this.dragEvent.end = newEnd
                this.dragEvent.category = this.categoryStart
            } else if (this.createEvent && this.createStart !== null) {
                // console.log('mouseMove createEvent')
                const mouseRounded = this.roundTime(mouse, false)
                const min = Math.min(mouseRounded, this.createStart)
                const max = Math.max(mouseRounded, this.createStart)

                this.createEvent.start = min
                this.createEvent.end = max
            }
        },

        async endDrag () {
            console.log('endDrag', this.createEvent)
            // this.$store.dispatch('setLoadingApp', 'add');
            this.claimDraggingTime = true;
            if (this.createEvent !== null && this.createStart !== null) {
                if (this.createEvent.class_id) {
                    let studentsObject = this.dragEventOriginal.studentsObject;
                    this.originalClass.duration = Math.floor((this.extendOriginal - this.createEvent.start) / (1000 * 60));
                    this.createEvent.duration = Math.floor((this.createEvent.end - this.createEvent.start) / (1000 * 60));
                    var minAvailability = null;
                    for (let i = 0; i < studentsObject.length; i++) {
                        let resource = await this.getResourceIdCourse(studentsObject[i].resource_id);
                        if (minAvailability === null || minAvailability > resource.minutes_available) {
                            minAvailability = resource.minutes_available;
                        }
                    }
                    // console.log('endDrag test1', minAvailability, (minAvailability - (this.createEvent.duration - this.originalClass.duration)));
                    if ((this.formattedDate(this.createEvent.start, 'date') === this.formattedDate(this.createEvent.end, 'date')) && !this.createEvent.outgoing_id && (minAvailability - (this.createEvent.duration - this.originalClass.duration) >= 0 )) {
                        this.resourcesInClass = await this.createResourcesFromStudentsObject(this.createEvent.studentsObject);
                        // this.$store.dispatch('setLoadingApp', 'add');
                        this.writeClassToDB(this.dragEventOriginal, this.createEvent);
                        this.events = this.putColorsToClasses(this.events)
                        // this.loadDataAll();
                        // this.$store.dispatch('setLoadingApp', 'dis');
                    } else {
                        console.log('drag bottom *****%%***** WRONG', this.createEvent);
                        this.createEvent.end = this.extendOriginal;
                        this.extendingBottom = false;
                        if (this.createEvent.outgoing_id) {
                            this.$store.dispatch('errorMessage', 'class already paid')
                        }
                        if (minAvailability - (this.createEvent.duration - this.originalClass.duration) < 0) {
                            this.$store.dispatch('errorMessage', 'all time used in course')
                        }
                    }
                } else {
                    let duration = (this.createEvent.end - this.createEvent.start)/60000;
                    console.log('open new class *****%%*****', duration);
                    this.originalClass.duration = 0;
                    if (duration >= 60 && this.formattedDate(this.createEvent.start, 'date') === this.formattedDate(this.createEvent.end, 'date') && this.formattedDate(startTimeEight, 'dateTime') <= this.formattedDate(this.createEvent.start, 'dateTime')) {
                        this.createEvent.duration = duration;
                        this.createEvent.day = this.formattedDate(this.createEvent.start, 'date');
                        if (this.scopeCalendar === 'category') {
                            let classRoomLabel = this.createEvent.category;
                            let classRoom =  this.getClassRoomLabel(classRoomLabel)
                            if (classRoom) {
                                this.createEvent.class_room_id = classRoom.id;
                            }
                        } else {
                            this.createEvent.class_room_id = null;
                        }
                        this.openSelectStudent(this.createEvent);
                    } else {
                        console.log('open new class *****%%***** WRONG POP');
                        this.events.pop();
                    }
                }
            } else if (this.dragEvent !== null && this.$store.state.loadingApp === 0) {
                console.log('drag event *****%%*****', this.dragEvent);
                if (!this.dialogClass) {
                    var startTimeEight = new Date(this.dragEvent.start);
                    startTimeEight.setHours(8,0,0,0);
                    if ((this.formattedDate(this.dragEvent.start, 'date') === this.formattedDate(this.dragEvent.end, 'date') && this.formattedDate(startTimeEight, 'dateTime') <= this.formattedDate(this.dragEvent.start, 'dateTime')) && !this.dragEvent.outgoing_id) {
                        if (this.scopeCalendar === 'category') {
                            let classRoomLabel = this.dragEvent.category;
                            let classRoom =  this.getClassRoomLabel(classRoomLabel);
                            console.log('drag event *****%%***** test1', this.dragEvent, this.dragEvent.category, this.categoryStart);
                            this.dragEvent.class_room_id = classRoom.id;
                            this.dragEvent.class_room = classRoomLabel;
                            this.dragEvent = this.styleEvent(this.dragEvent)
                        }
                        // this.$store.dispatch('setLoadingApp', 'add');
                        this.writeClassToDB(this.dragEventOriginal, this.dragEvent);
                        this.events = this.putColorsToClasses(this.events)
                        // this.loadDataAll();
                        // this.$store.dispatch('setLoadingApp', 'dis');
                    } else {
                        console.log('drag *****%%***** WRONG', this.dragEventOriginal);
                        let pos = this.events.map(function(e) { return e.class_id; }).indexOf(this.dragEvent.class_id);
                        this.changeObjectInArray(this.events, this.dragEventOriginal, pos);
                        // this.events.splice(pos, 1);
                        // this.events.push(this.dragEventOriginal);
                        if (this.dragEvent.outgoing_id) {
                            this.$store.dispatch('errorMessage', 'class already paid')
                        }
                    }
                }
            }
            this.dragEvent = null;
            this.dragTime = null
            this.createEvent = null
            this.createStart = null
            this.extendOriginal = null
            // this.categoryStart = null
            this.claimDraggingTime = false;
            // this.$store.dispatch('setLoadingApp', 'dis');
        },

        cancelDrag () {
            console.log('cancelDrag')
            if (this.createEvent) {
                if (this.extendOriginal) {
                this.createEvent.end = this.extendOriginal
                this.extendOriginal = null
                } else {
                    const i = this.events.indexOf(this.createEvent)
                    if (i !== -1) {
                        console.log('cancelDrag splice')
                        this.events.splice(i, 1)
                    }
                }
            }
            this.createEvent = null
            this.createStart = null
            this.dragTime = null
            this.dragEvent = null
            this.categoryStart = null
        },

        roundTime (time, down = true) {
            const roundTo = 15 // minutes
            const roundDownTime = roundTo * 60 * 1000

            return down
                ? time - time % roundDownTime
                : time + (roundDownTime - (time % roundDownTime))
        },

        toTime (tms) {
            return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
        },
        
        formCategoriesCalendar() {
            this.categoriesCalendar = [];
            for (let index = 0; index < this.classRooms.length; index++) {
                if(!this.classRooms[index].hide) {
                    this.categoriesCalendar.push(this.classRooms[index].label)
                }
            }
        },

        // hideAllRooms (visibility) {
        //     for (let index = 0; index < this.classRooms.length; index++) {
        //         this.classRooms[index].hide = visibility;
        //     }
        //     this.$store.dispatch('changeClassRooms', this.classRooms) 
        //     this.formCategoriesCalendar();
        // },

        // checkAllRoomsSelected() {
        //     var allSelected = true;
        //     for (let index = 0; index < this.classRooms.length; index++) {
        //         if (this.classRooms[index].hide) {
        //             allSelected = false
        //         }
        //     }
        //     this.$store.dispatch('changeSelectAllRooms', allSelected);
        // },

        // changeClassRooms() {
        //     this.$store.dispatch('changeClassRooms', this.classRooms);
        //     // this.checkAllRoomsSelected();
        //     this.formCategoriesCalendar();
        // },

        async getResourceIdCourse(resourceId) {
            // console.log('getResourceIdCourse', resourceId)
            let resource = [];
            if (this.dialogCourse) {
                resource = this.resourcesInCourse.filter(function(select_item) {
                    return select_item.resource_id === resourceId;
                });
                // console.log('getResourceIdCourse resourcesInCourse', resource);
            } 
            if (resource.length === 0) {
                resource = this.resourcesLoaded.filter(function(select_item) {
                    return select_item.resource_id === resourceId;
                });
                // console.log('getResourceIdCourse resourcesLoaded', resource);
            }
            if (resource.length === 0) {
                resource = await this.$store.dispatch('getCourseResourceId', resourceId);
                if (resource) {
                    console.log('getResourceIdCourse from DB', resource);
                    this.resourcesLoaded.push(resource);
                } else {
                    console.log('NO resource from DB', resourceId);
                }
            } else {
                resource = resource[0];
            }
            // console.log('getResourceIdCourse result', resource);
            return resource;
        },

        async createResourcesFromStudentsObject(studentsObject) {
            // console.log('createResourcesFromStudentsObject', studentsObject);
            let resources = [];
            if (studentsObject && studentsObject.length) {
                for (let i = 0; i < studentsObject.length; i++) {
                    var resourceId = studentsObject[i].resource_id;
                    var resource = await this.getResourceIdCourse(resourceId);
                    resources.push(resource);
                }
                return JSON.parse(JSON.stringify(resources));
            }
            return [];
        },

        prepOpenDialogClass(event) {
            // console.log('prepOpenDialogClass', this.extendingBottom , this.claimDraggingTime , this.$store.state.loadingApp)
            if  (!this.extendingBottom && !this.claimDraggingTime && this.$store.state.loadingApp === 0) {
                this.dialogClass = true;
                this.openDialogClass(event)
            }
        },

        async openDialogClass (itemClass) {
            console.log('openDialogClass', itemClass);
            this.copyClasses = false;
            if (itemClass.color === 'black') {
                // WRONG black without classes resources CLEAN
                this.deleteClass(itemClass);
                let pos = this.events.map(function(e) { return e.class_id; }).indexOf(itemClass.class_id);
                if (pos > -1) {
                    this.events.splice(pos,1);
                }
                this.$store.dispatch('successMessage', 'class ' + itemClass.class_id + ' removed')
                return;
            }
            this.editedClass = JSON.parse(JSON.stringify(itemClass));
            this.$store.dispatch('setLoadingApp', 'add');
            this.weekSelect = {
                day: {Sun: false, Mon: true, Tue: true, Wed: true, Thu: true, Fri: true, Sat: false}
            };
            if (!this.editedClass.individual_instead) {
                this.editedClass.individual_instead = false;
            }
            this.editedClass.color = this.sortColor(this.editedClass.class_group + this.editedClass.class_type).toString();
            this.editedClass.day = this.formattedDate(this.editedClass.start, 'date');
            this.editedClass.startTime = this.formattedDate(this.editedClass.start, 'time');
            this.editedClass.endTime = this.formattedDate(this.editedClass.end, 'time');
            // this.editedClass.duration = this.calcDurationClass(this.editedClass);
            if (this.editedClass.teacher_id && this.getTeacher(this.editedClass.teacher_id).obsolete !== null && this.editedClass.outgoing_id === null) {
                this.editedClass.teacher_id = null;
            }
            if (this.resourcesInClass.length === 0) {
                this.resourcesInClass = await this.createResourcesFromStudentsObject(this.editedClass.studentsObject);
                // console.log('openDialogClass test5', this.resourcesInClass);
            }
            // console.log('openDialogClass test1', this.resourcesInClass);
            if (this.editedClass.class_id) {
                for (let i = 0; i < this.resourcesInClass.length; i++) {
                    // console.log('openDialogClass test6', this.resourcesInClass[i].newStudent, this.resourcesInClass[i].full_name);
                    if (this.resourcesInClass[i].graduationClassId === itemClass.class_id) {
                        this.resourcesInClass[i].iconGrad = "$vuetify.icons.icon_mdi_school"
                    } else {
                        this.resourcesInClass[i].iconGrad = null
                    }
                }
                this.editedClass.paymentClass = this.calcPaymentClass(this.editedClass).paymentClass;
                // console.log('openDialogClass test2',this.resourcesInClass.length, this.resourcesInClass.class_group)
                this.editedClass.individual_instead = false;
                if (this.resourcesInClass.length === 1 && this.resourcesInClass[0].class_group === 'Group') {
                    this.editedClass.individual_instead = true;
                }
            } else {
                this.editedClass.paymentClass = 0;
                this.editedClass.outgoing_id = null;
                this.editedClass.autoPayment = 1;
                if (this.editedClass.class_location ==='Online' && this.scopeCalendar !== 'category') {
                    this.editedClass.class_room_id = 12
                }
                this.editedClass = this.styleEvent(this.editedClass);
            }
            this.originalClass = JSON.parse(JSON.stringify(this.editedClass));
            this.teacherOldId = this.editedClass.teacher_id;
            this.dialogClass = true;
            this.adjustDurationsClass();
            this.$store.dispatch('setLoadingApp', 'dis');
        },

        allHoursUsedClass(resources) {
            if (resources) {
                for (let i = 0; i < resources.length; i++) {
                    if (resources[i].minutes_available < 0) {
                        return true
                    }
                }
            }
            return false;
        },

        cancelDialogClass() {
            // console.log('cancelDialogClass')
            if (!this.editedClass.class_id && !this.dialogCourse) {
                // console.log('cancelDialogClass pop')
                this.events.pop();
            }
            this.closeDialogClass();
        },

        closeDialogClass() {
            this.editedClass = {};
            this.originalClass = [];
            this.resourcesInClass = [];
            this.selectedClassesResources = [];
            if (this.dialogCourse) {
                this.createResourcesInCourse();
            }
        },

        async openSelectStudent(itemClass) {
            // console.log('openSelectStudent itemClass = ', itemClass, this.resourcesLoaded.length)
            this.$store.dispatch('setLoadingApp', 'add');
            let dayTime = this.formattedDate(itemClass.day, 'date');
            this.selectedResources = [];
            this.resourcesSelectList = [];
            let resourcesSelectListNew = [];
            if (this.dialogClass || this.dialogCourse) {
                resourcesSelectListNew = JSON.parse(JSON.stringify(this.resourcesLoaded.filter(function(select_item) {
                    return select_item.hours > 0 && select_item.statusResourceId !== 1 && select_item.statusResourceId !== 8 && select_item.statusResourceId !== 9 && select_item.application_id !== null && select_item.class_location === itemClass.class_location && select_item.class_type === itemClass.class_type && select_item.class_group === itemClass.class_group && select_item.class_language === itemClass.class_language && select_item.start <= dayTime && select_item.end >= dayTime;
                })))
                console.log('openSelectStudent test1', resourcesSelectListNew, this.resourcesSelectList, dayTime)
                for (let i = 0; i < resourcesSelectListNew.length; i++) {
                    if (this.resourcesInClass.map(function(e) { return e.resource_id; }).indexOf(resourcesSelectListNew[i].resource_id) === -1) {
                        let pos = this.resourcesInCourse.map(function(e) { return e.resource_id; }).indexOf(resourcesSelectListNew[i].resource_id);
                        if (pos > -1) {
                            resourcesSelectListNew[i].minutes_available = JSON.parse(JSON.stringify(this.resourcesInCourse[pos])).minutes_available;
                        }
                        this.resourcesSelectList.push(resourcesSelectListNew[i])
                    }
                }
                // console.log('openSelectStudent test2', resourcesSelectListNew, this.resourcesSelectList)
            } else {
                if (this.scopeLanguage === 'allLanguages') {
                    resourcesSelectListNew = JSON.parse(JSON.stringify(this.resourcesLoaded.filter(function(select_item) {
                        return select_item.hours > 0 && select_item.statusResourceId !== 1 && select_item.statusResourceId !== 8 && select_item.statusResourceId !== 9 && select_item.application_id !== null && select_item.start <= dayTime && select_item.end >= dayTime;
                    })))
                } else {
                    let language = this.scopeLanguage
                    resourcesSelectListNew = JSON.parse(JSON.stringify(this.resourcesLoaded.filter(function(select_item) {
                        return select_item.hours > 0 && select_item.statusResourceId !== 1 && select_item.statusResourceId !== 8 && select_item.statusResourceId !== 9 && select_item.application_id !== null && select_item.start <= dayTime && select_item.end >= dayTime && select_item.class_language === language;
                    })))
                }
                console.log('openSelectStudent test3', dayTime,resourcesSelectListNew)
                this.resourcesInClass = [];
                this.resourcesSelectList = JSON.parse(JSON.stringify(resourcesSelectListNew));
            }
            if (this.resourcesSelectList.length === 0) {
                this.$store.dispatch('warningMessage', "No courses found around this date");
                if (!itemClass.class_id && !this.dialogClass && !this.dialogCourse) {
                    this.editedClass = {};
                    console.log('openSelectStudent pop')
                    this.events.pop();
                }
            } else {
                this.editedClass = itemClass;
                this.dialogSelectResources = true;
            }
            this.$store.dispatch('setLoadingApp', 'dis');
        },

        cancelSelectStudent() {
            console.log('cancelSelectStudent')
            if (!this.dialogClass && !this.dialogCourse) {
                console.log('openSelectStudent pop')
                this.events.pop();
            }
            this.dialogSelectResources = false;
            this.selectedResourcesSingleSelect = true;
        },

        openSelectStudentResource(resource, dateMin, dateMax) {
            this.$store.dispatch('setLoadingApp', 'add');
            let dayMin = this.formattedDate(dateMin, 'date');
            let dayMax = this.formattedDate(dateMax, 'date');
            this.selectedResources = [];
            this.resourcesSelectList = [];
            let resourcesSelectListNew = [];
            resourcesSelectListNew = JSON.parse(JSON.stringify(this.resourcesLoaded.filter(function(select_item) {
                return select_item.hours > 0 && select_item.statusResourceId !== 1 && select_item.statusResourceId !== 8 && select_item.statusResourceId !== 9 && select_item.application_id !== null && select_item.class_location === resource.class_location && select_item.class_type === resource.class_type && select_item.class_group === resource.class_group && select_item.class_language === resource.class_language && select_item.start <= dayMin && select_item.end >= dayMax;
            })));
            for (let i = 0; i < resourcesSelectListNew.length; i++) {
                if (resource.resource_id !== resourcesSelectListNew[i].resource_id) {
                    let pos = this.resourcesInCourse.map(function(e) { return e.resource_id; }).indexOf(resourcesSelectListNew[i].resource_id);
                    if (pos > -1) {
                        resourcesSelectListNew[i].minutes_available = JSON.parse(JSON.stringify(this.resourcesInCourse[pos])).minutes_available;
                    }
                    this.resourcesSelectList.push(resourcesSelectListNew[i])
                }
            }
            if (this.resourcesSelectList.length === 0) {
                this.$store.dispatch('warningMessage', "No courses found around this date");
            } else {
                this.dialogSelectResources = true;
            }
            this.$store.dispatch('setLoadingApp', 'dis');
        },

        async includeStudentClass() {
            console.log('includeStudent start', this.selectedClassesResources)
            let resource = {};
            if (this.selectedClassesResources.length > 0) {
                // add students to multiple classes in course
                for (let i = 0; i < this.selectedClassesResources.length; i++) {
                    // console.log('includeStudent test', this.selectedClassesResources[i].studentsObject)
                    for (let j = 0; j < this.selectedResources.length; j++) {
                        resource = JSON.parse(JSON.stringify(this.selectedResources[j]));
                        console.log('includeStudent test2', this.selectedClassesResources[i], resource.resource_id, this.selectedClassesResources[i].studentsObject.map(function(e) { return e.resource_id; }).indexOf(resource.resource_id));
                        if (this.selectedClassesResources[i].studentsObject.map(function(e) { return e.resource_id; }).indexOf(resource.resource_id) === -1) {
                            let resources = [];
                            resources.push(resource);
                            let studentsObject = this.createStudentsObject(resources)[0];
                            let pos = this.editedClassesInResource.map(function(e) { return e.class_id; }).indexOf(this.selectedClassesResources[i].class_id);
                            if (pos > -1) {
                                this.editedClassesInResource[pos].studentsObject.push(studentsObject);
                                this.editedClassesInResource[pos].students = this.createStudentsFromObject(this.editedClassesInResource[pos].studentsObject);
                                this.editedClassesInResource[pos].amount_students = this.editedClassesInResource[pos].studentsObject.length;
                                this.editedClassesInResource[pos].individual_instead = false;
                                // console.log('includeStudent test2', pos, this.editedClassesInResource[pos].studentsObject)
                            }
                        }
                    }
                }
                await this.createResourcesInCourse();
                this.dialogSelectResources = false;
                this.selectedResources = [];
            } else {
                // new class or new student
                if ((this.selectedResources.length + this.resourcesInClass.length) === 1 && this.selectedResources[0].class_group === 'Group') {
                    this.editedClass.individual_instead = true;
                } else {
                    this.editedClass.individual_instead = false;
                }
                for (let i = 0; i < this.selectedResources.length; i++) {
                    resource = JSON.parse(JSON.stringify(this.selectedResources[i]));
                    resource.newStudent = true;
                    // console.log('includeStudent test1', resource);
                    this.resourcesInClass.push(resource);
                }
                this.selectedResources = [];
                this.editedClass.studentsObject = this.createStudentsObject(this.resourcesInClass);
                // console.log('includeStudent test3', this.resourcesInClass, this.editedClass.studentsObject)
                this.dialogSelectResources = false;
                if (!this.editedClass.class_id && !this.dialogClass) {
                    this.transferCoursePropsToClass(resource);
                    this.openDialogClass(this.editedClass);
                } else {
                    console.log('includeStudent test2');
                    this.adjustDurationsClass()
                }
            }
        },

        async removeStudentFromClass(itemResource) {
            let pos = this.resourcesInClass.map(function(e) { return e.resource_id; }).indexOf(itemResource.resource_id);
            if (pos > -1) {
                this.resourcesInClass.splice(pos, 1);
            }
            this.editedClass.studentsObject = this.createStudentsObject(this.resourcesInClass);
            if (this.resourcesInClass.length < 2) {
                this.editedClass.individual_instead = true;
            }
            this.adjustDurationsClass();
        },

        async adjustDurationsClass() {
            this.$store.dispatch('setLoadingApp', 'add');
            console.log('adjustDurationsClass start', this.calcPaymentClass(this.editedClass).paymentClass);
            this.editedClass.duration = this.calcDurationClass(this.editedClass);
            this.editedClass.paymentClass = this.calcPaymentClass(this.editedClass).paymentClass;
            this.editedClass.rate = this.calcPaymentClass(this.editedClass).rate;
            if (this.originalClass.individual_instead) {
                this.originalClass.minutes_used =  this.originalClass.duration / 3 * 4
            } else {
                this.originalClass.minutes_used =  this.originalClass.duration
            }
            if (this.editedClass.individual_instead) {
                this.editedClass.minutes_used =  this.editedClass.duration / 3 * 4
            } else {
                this.editedClass.minutes_used =  this.editedClass.duration
            }
            let change_minutes_used = this.originalClass.minutes_used - this.editedClass.minutes_used;
            let compensationNewStudent = 0;
            var minutes_available_original = 0;
            for (let i = 0; i < this.resourcesInClass.length; i++) {
                if (this.resourcesInClass[i].newStudent) {
                    compensationNewStudent = this.originalClass.minutes_used
                } else {
                    compensationNewStudent = 0
                }
                // this.resourcesInClass[i].newStudent = false;
                let obj = await this.getResourceIdCourse(this.resourcesInClass[i].resource_id);
                minutes_available_original = obj['minutes_available'];
                this.resourcesInClass[i].minutes_available = minutes_available_original + change_minutes_used - compensationNewStudent;
                // // console.log('adjustDurationsClass test2', minutes_available_original/60, change_minutes_used/60, compensationNewStudent/60);
                // console.log('adjustDurationsClass test1', this.resourcesInClass[i].newStudent, this.resourcesInClass[i].full_name);
            }
            this.$store.dispatch('setLoadingApp', 'dis');
        },

        async saveApplyClass (itemClass) {
            console.log('saveApplyClass itemClass =', itemClass)
            if(this.$refs.dialogFormClass.validate()) {
                itemClass.timed = true;
                itemClass = this.createClassFromDayTime(itemClass);
                if (this.dialogCourse) {
                    itemClass.students = this.createStudentsStringfromObject(itemClass.studentsObject);
                    itemClass.amount_students = itemClass.studentsObject.length;
                    if (itemClass.newClass) {
                        itemClass.key = this.createUUID();
                        this.editedClassesInResource.push(itemClass);
                        if (this.copyClasses) {
                            // check if any day is selected
                            var check = false;
                            for (var j in this.weekSelect.day) {
                                if(this.weekSelect.day[j]) {
                                    check = true;
                                }
                            }
                            if (check) {
                                // get available hours for copying
                                let minutesAvailable = this.resourcesInClass.reduce((prev, curr) => prev.minutes_available < curr.minutes_available ? prev : curr).minutes_available;
                                let amountOfClasses = Math.floor(minutesAvailable / itemClass.minutes_used);
                                console.log('saveApplyClass minutesAvailable =', minutesAvailable / 60, amountOfClasses)
                                let dayDate = dayjs(itemClass.day)
                                let i = 0;
                                do {
                                    dayDate = dayjs(dayDate).add(1, 'day')
                                    let dayName = dayjs(dayDate).$d.toString().substring(0, 3)
                                    // console.log('saveApplyClass dayDate =', dayDate)
                                    for (j in this.weekSelect.day) {
                                        if(this.weekSelect.day[j] && j === dayName) {
                                            let nextClass = JSON.parse(JSON.stringify(itemClass))
                                            dayDate = this.formattedDate(dayDate, 'date')
                                            nextClass.start = this.timeToTimeStamp(dayDate, nextClass.startTime);
                                            nextClass.end = this.timeToTimeStamp(dayDate, nextClass.endTime);
                                            nextClass.key = this.createUUID();
                                            nextClass.day = dayDate;
                                            this.editedClassesInResource.push(nextClass);
                                            i += 1;
                                        }
                                    }
                                } while (i < amountOfClasses);
                            } 
                        }
                        // console.log('saveApplyClass test1', itemClass, this.editedClassesInResource.length);
                    } else {
                        // console.log('saveApplyClass test2', itemClass);
                        let pos = this.editedClassesInResource.map(function(e) { return e.class_id; }).indexOf(itemClass.class_id);
                        if ( pos > -1 ) {
                            this.changeObjectInArray(this.editedClassesInResource, itemClass, pos);
                            // console.log('editedClassesInResource = ', itemClass, this.editedClassesInResource[pos]);
                        }
                    }
                    await this.createResourcesInCourse();
                    for (let i = 0; i < this.resourcesInCourse.length; i++) {
                        this.resourcesInCourse[i].newStudent = false;
                        // console.log('saveApplyClass test1', this.resourcesInCourse[i].newStudent);
                    }
                } else {
                    this.$store.dispatch('setLoadingApp', 'add');
                    if (itemClass.class_id) {
                        await this.writeClassToDB(this.originalClass, itemClass);
                    } else {
                        await this.writeClassToDB([], itemClass);
                    }
                    await this.loadDataAll();
                    this.$store.dispatch('setLoadingApp', 'dis');
                }
                this.dialogClass = false;
                this.closeDialogClass();
            }
        },

        createClassFromDayTime(itemClass) {
            // console.log('createClassFromDayTime itemClass=', itemClass);
            itemClass.class_room = this.getClassRoomId(itemClass.class_room_id).name;
            itemClass.category = itemClass.class_room;
            if (itemClass.teacher_id) {
                itemClass.contact = this.getTeacher(itemClass.teacher_id).contact;
            }
            itemClass.color = this.sortColor(itemClass.class_group + itemClass.class_type).toString();
            itemClass.start = this.timeToTimeStamp(itemClass.day, itemClass.startTime);
            itemClass.end = this.timeToTimeStamp(itemClass.day, itemClass.endTime);
            itemClass = this.styleEvent(itemClass);
            return itemClass;
        },

        async openDialogCourse (resourceId) {
            console.log('openDialog', resourceId)
            this.$store.dispatch('setLoadingApp', 'add');
            this.dialogClass = false;
            this.selectedClassesResources = [];
            let resource = this.resourcesLoaded.filter(function(select_item) {
                return select_item.resource_id === resourceId;
            });
                var date = new Date();
                let start = date.getTime();
                console.log('@@@1', resourceId, resource);
            let message = ' only classes';
            if (resource.length === 0) {
                message = ' both queries classes and resource';
                await Promise.all([this.classesInResource = JSON.parse(JSON.stringify(await this.$store.dispatch('getClassesResourceId', resourceId))), this.editedResource = await this.$store.dispatch('getCourseResourceId', resourceId)]);
                // console.log('openDialogCourse test1', this.editedResource);
            }
            else {
                resource = resource[0];
                this.editedResource = JSON.parse(JSON.stringify(resource));
                this.classesInResource = JSON.parse(JSON.stringify(await this.$store.dispatch('getClassesResourceId', resourceId)));
                console.log('openDialogCourse test2', this.classesInResource);
                // console.log('openDialogCourse test2', this.editedResource);
            }
            // console.log('openDialogCourse test3', resource);
            this.editedResource.color = this.sortColor(this.editedResource.class_group + this.editedResource.class_type).toString();
                date = new Date();
                let end = date.getTime();
                const totalTime = ((end-start)/1000);
                console.log('@@@2', resourceId, totalTime, message);
            this.classesInResource = this.configureClassesFromDB(this.classesInResource);
            this.editedClassesInResource = JSON.parse(JSON.stringify(this.classesInResource));
            // create resourcesInCourse
            await this.createResourcesInCourse();
            this.dialogCourse = true;
            this.$store.dispatch('setLoadingApp', 'dis');
        },

        async createResourcesInCourse() {
            // console.log('createResourcesInCourse', this.editedClassesInResource)
            var studentsObjectsMerged = [];
            let resourcesTemp = [];
            this.allHoursUsedCourse = false;
            for (let i = 0; i < this.editedClassesInResource.length; i++) {
                let nextStudentObject = this.editedClassesInResource[i].studentsObject;
                for (let j = 0; j < nextStudentObject.length; j++) {
                    let pos = studentsObjectsMerged.map(function(e) { return e.resource_id; }).indexOf(nextStudentObject[j].resource_id);
                    if (pos === -1) {
                        studentsObjectsMerged = studentsObjectsMerged.concat(nextStudentObject[j]);
                    }
                }
                // console.log('createResourcesInCourse', studentsObjectsMerged)
            }
            resourcesTemp = await this.createResourcesFromStudentsObject(studentsObjectsMerged);
            for (let i = 0; i < resourcesTemp.length; i++) {
                resourcesTemp[i].status = 'ok';
            }
            for (let i = 0; i < this.resourcesInCourseOriginal.length; i++) {
                let pos = resourcesTemp.map(function(e) { return e.resource_id; }).indexOf(this.resourcesInCourseOriginal[i].resource_id);
                if (pos === -1) {
                    this.resourcesInCourseOriginal[i].status = 'removed';
                    resourcesTemp.push(this.resourcesInCourseOriginal[i]);
                }
            }
            // console.log('createResourcesInCourse test4', resourcesTemp)
            for (let i = 0; i < resourcesTemp.length; i++) {
                let pos = this.resourcesInCourse.map(function(e) { return e.resource_id; }).indexOf(resourcesTemp[i].resource_id);
                if (pos === -1) {
                    // console.log('createResourcesInCourse test1', resourcesTemp[i], resourcesTemp[i].minutes_available/60)
                    resourcesTemp[i].minutesAvailableInCourse = resourcesTemp[i].minutes_available
                } else {
                    // console.log('createResourcesInCourse test2')
                    resourcesTemp[i].minutesAvailableInCourse = this.resourcesInCourse[pos].minutesAvailableInCourse
                }
                let minutes_used_total = 0;
                for (let j = 0; j < this.editedClassesInResource.length; j++) {
                    let minutes_used_class = 0;
                    if (this.editedClassesInResource[j].studentsObject.map(function(e) { return e.resource_id; }).indexOf(resourcesTemp[i].resource_id) > -1) {
                        if (this.editedClassesInResource[j].individual_instead) {
                            minutes_used_class = this.editedClassesInResource[j].duration / 3 * 4
                        } else {
                            minutes_used_class = this.editedClassesInResource[j].duration
                        }
                        minutes_used_total += minutes_used_class;
                        // console.log('hours:',resourcesTemp[i].hours, 'used resource:',resourcesTemp[i].minutes_used/60, 'used class:', minutes_used_class/60, 'available', resourcesTemp[i].minutes_available/60, 'AvailableInCourse', resourcesTemp[i].minutesAvailableInCourse/60, resourcesTemp[i].full_name)
                    }
                    
                }
                if (pos === -1 && !this.dialogCourse) {
                    resourcesTemp[i].minutesAvailableInCourse += minutes_used_total;
                }
                resourcesTemp[i].minutes_available = resourcesTemp[i].minutesAvailableInCourse - minutes_used_total;
                if (resourcesTemp[i].minutes_available < 0) {
                    this.allHoursUsedCourse = true;
                }
                resourcesTemp[i].newStudent = false;
                // console.log('createResourcesInCourse test2', resourcesTemp[i].newStudent)
            }
            if (this.resourcesInCourse.length === 0) {
                this.resourcesInCourseOriginal = JSON.parse(JSON.stringify(resourcesTemp));
                // console.log('createResourcesInCourse resourcesInCourseOriginal created', this.resourcesInCourseOriginal)
            }
            this.resourcesInCourse = JSON.parse(JSON.stringify(resourcesTemp));
            this.resourcesInCourseDisplay = this.resourcesInCourse.filter(function(select_item) {
                return select_item.status !== 'removed';
            });
        },

        async writeClassToDB (originalClass, editedClass) {
            console.log('writeClassToDB start', originalClass, editedClass);
            // compare originalClass with editedClass and write to classes
            delete editedClass.day;
            delete originalClass.day;
            delete editedClass.rate;
            delete originalClass.rate;
            // delete editedClass.studentsObject;
            // delete originalClass.studentsObject;
            var class_id = null;
            if (this.sameArrays(originalClass, editedClass)) {
                console.log('writeClassToDB class did not change')
            } else {
                if (editedClass.class_id) {
                    class_id = editedClass.class_id;
                    console.log('writeClassToDB insertClass update', editedClass);
                    await this.$store.dispatch('insertClass', editedClass);
                } else {
                    editedClass.class_id = null;
                    class_id = await this.$store.dispatch('insertClass', editedClass);
                    console.log('writeClassToDB insertClass new insert');
                }
            }
            // calc change in minutes spent for correcting resources in class
            var minutes_used = 0;
            var minutes_used_original = 0;
            if (editedClass.individual_instead === 1 || editedClass.individual_instead) {
                minutes_used_original = originalClass.duration / 3 * 4;
            } else {
                minutes_used_original = originalClass.duration;
            }
            if (editedClass.individual_instead === 1 || editedClass.individual_instead) {
                minutes_used = editedClass.duration / 3 * 4;
            } else {
                minutes_used = editedClass.duration;
            }
            let change_minutes_used = minutes_used_original - minutes_used;
            // compare studentObjects in originalClass with editedClass
            var studentsInOriginalClass = [];
            console.log('writeClassToDB test ', studentsInOriginalClass);
            if (originalClass.studentsObject) {
                studentsInOriginalClass = await this.createResourcesFromStudentsObject(originalClass.studentsObject);
            }
            let studentsInEditedClass = await this.createResourcesFromStudentsObject(editedClass.studentsObject);
            // correction minutes_available in resourcesLoaded in all students in class
            for (let i = 0; i < studentsInEditedClass.length; i++) {
                let pos = this.resourcesLoaded.map(function(e) { return e.resource_id; }).indexOf(studentsInEditedClass[i].resource_id);
                if (pos > -1) {
                    this.resourcesLoaded[pos].minutes_available += change_minutes_used;
                }
            }
            // console.log('writeClassToDB test2', studentsInOriginalClass, studentsInEditedClass)
            if (!this.sameArrays(studentsInOriginalClass, studentsInEditedClass)) {
                var onlyInStudentsInOriginalClass = this.onlyInFirstArray(studentsInOriginalClass, studentsInEditedClass);
                // console.log('Only in onlyInStudentsInOriginalClass', onlyInStudentsInOriginalClass);
                for (let i = 0; i < onlyInStudentsInOriginalClass.length; i++) {
                    console.log('deleteStudentFromClassIds', onlyInStudentsInOriginalClass[i].resource_id, editedClass.class_id);
                    let payload = {
                        resourceId: onlyInStudentsInOriginalClass[i].resource_id,
                        classId: editedClass.class_id,
                    }
                    await this.$store.dispatch('deleteStudentFromClassIds', payload);
                    this.setSendToStudent(onlyInStudentsInOriginalClass[i].resource_id);
                    // correction minutes_available in removed resourcesLoaded
                    let pos = this.resourcesLoaded.map(function(e) { return e.resource_id; }).indexOf(onlyInStudentsInOriginalClass[i].resource_id);
                    let resource = this.resourcesLoaded[pos];
                    resource.minutes_used += change_minutes_used;
                    if (pos > -1) {
                        this.changeObjectInArray(this.resourcesLoaded, resource, pos);
                    }
                }
                var onlyInStudentsInEditedClass = this.onlyInFirstArray(studentsInEditedClass, studentsInOriginalClass);
                // console.log('Only in studentsInEditedClass ', onlyInStudentsInEditedClass, studentsInEditedClass, studentsInOriginalClass);
                for (let i = 0; i < onlyInStudentsInEditedClass.length; i++) {
                    let payload = {
                        resources_classes_id: null,
                        resource_id: onlyInStudentsInEditedClass[i].resource_id,
                        class_id: class_id,
                    };
                    let result = await this.$store.dispatch('insertResourcesClasses', payload);
                    // console.log('writeClassToDB test4', payload, result);
                    let resources_classes_id = result.data.insertId;
                    let pos = null;
                    if (this.dialogCourse) {
                        pos = this.events.map(function(e) { return e.resource_id; }).indexOf(onlyInStudentsInEditedClass[i].resource_id);
                        // console.log('writeClassToDB test1 ', this.events[pos]);
                        this.events[pos].studentsObject.push({resources_classes_id : resources_classes_id});
                        // console.log('writeClassToDB test3 CHECK THIS!!! ', this.events[pos]);
                    }
                    pos = this.resourcesLoaded.map(function(e) { return e.resource_id; }).indexOf(onlyInStudentsInEditedClass[i].resource_id);
                    let resource = this.resourcesLoaded[pos];
                    resource.minutes_used += change_minutes_used;
                    if (pos > -1) {
                        this.changeObjectInArray(this.resourcesLoaded, resource, pos);
                    }
                    // console.log('insertResourcesClasses test', resource, change_minutes_used);
                    this.setSendToStudent(onlyInStudentsInEditedClass[i].resource_id);
                }
            } else {
                console.log('resources arrays the same')
            }
            this.closeDialogClass();
            if (this.teacherOldId !== editedClass.teacher_id) {
                this.setSendToTeacher(this.teacherOldId);
            }
            this.setSendToTeacher(editedClass.teacher_id);
            this.extendingBottom = false;
            console.log('ok')
        },

        async removeClasses () {
            let pos = 0;
            for (let i = 0; i < this.selectedClassesResources.length; i++) {
                let studentsObject = this.selectedClassesResources[i].studentsObject;
                for (let j = 0; j < studentsObject.length; j++) {
                    pos = this.resourcesInCourse.map(function(e) { return e.resource_id; }).indexOf(studentsObject[j].resource_id);
                    if ( pos > -1 ) {
                        this.recalcResourceMinutes(this.resourcesInCourse[pos], this.selectedClassesResources[i], 'remove');
                        // this.resourcesInCourse[pos].minutes_available += this.selectedClassesResources[i].duration;
                    }
                }
                pos = this.editedClassesInResource.map(function(e) { return e.key; }).indexOf(this.selectedClassesResources[i].key);
                if (pos > -1) {
                    this.editedClassesInResource.splice(pos,1);
                }
            }
            await this.createResourcesInCourse();
            this.selectedClassesResources = [];
        },

        recalcResourceMinutes(resource, itemClass, action) {
            // console.log('recalcResourceMinutes', resource, itemClass, action)
            if (action === 'remove') {
                action = 1
            } else {
                action = -1
            }
            resource.minutes_available += action * itemClass.minutes_used
            resource.minutes_planned += action * itemClass.duration
            var date = new Date();
            let now = date.getTime();
            console.log('recalcResourceMinutes test', itemClass.end, now)
            if (itemClass.end < now) {
                resource.minutes_completed += action * itemClass.duration
            }
        },

        prepDeleteClass(itemClass) {
            console.log('prepDeleteClass ', itemClass);
            let durationOriginal = Math.floor((itemClass.end - itemClass.start) / (1000 * 60));
            let spentOriginal = 0;
            if (itemClass.individual_instead) {
                spentOriginal =  durationOriginal / 3 * 4
            } else {
                spentOriginal =  durationOriginal
            }
            let pos = 0;
            for ( let i = 0; i < itemClass.studentsObject.length; i++ ) {
                pos = this.resourcesLoaded.map(function(e) { return e.resource_id; }).indexOf(itemClass.studentsObject[i].resource_id);
                if (pos > -1) {
                    // this.resourcesLoaded[pos].minutes_available += spentOriginal;
                    let resource = this.resourcesLoaded[pos];
                    resource.minutes_available += spentOriginal;
                    this.changeObjectInArray(this.resourcesLoaded, resource, pos);
                }
            }
            pos = this.editedClassesInResource.map(function(e) { return e.class_id; }).indexOf(itemClass.class_id);
            if (pos > -1) {
                this.editedClassesInResource.splice(pos,1);
            }
            if (!this.dialogCourse) {
                pos = this.events.map(function(e) { return e.class_id; }).indexOf(itemClass.class_id);
                if (pos > -1) {
                    this.events.splice(pos,1);
                }
                this.deleteClass(itemClass);
            } else {
                this.closeDialogClass();
            }
        },

        async deleteClass (itemClass) {
            this.$store.dispatch('setLoadingApp', 'add')
            await this.$store.dispatch('deleteClass', itemClass.class_id);
            this.dialogClass = false;
            this.closeDialogClass();
            this.$store.dispatch('setLoadingApp', 'dis')
        },

        async addNewClass(resource) {
            // console.log('addNewClass', resource);
            this.$store.dispatch('setLoadingApp', 'add');
            this.resourcesInClass = [];
            this.transferCoursePropsToClass(resource);
            if (this.classesInResource.length > 0) {
                // console.log('addNewClass copy from last class');
                let classes = this.classesInResource.sort(this.dynamicSort('start'));
                let lastClass = classes[classes.length - 1];
                let dayDate = dayjs(lastClass.day)
                dayDate = dayjs(dayDate).add(1, 'day')
                dayDate = this.formattedDate(dayDate, 'date')
                lastClass.start = this.timeToTimeStamp(dayDate, lastClass.startTime);
                lastClass.end = this.timeToTimeStamp(dayDate, lastClass.endTime);
                console.log('addNewClass copy from last class', lastClass.startTime, lastClass.endTime);
                this.editedClass = JSON.parse(JSON.stringify(lastClass));
                for ( let i = 0; i < this.editedClass.studentsObject.length; i++ ) {
                    let resource = await this.getResourceIdCourse(this.editedClass.studentsObject[i].resource_id)
                    resource.newStudent = true;
                    // console.log('addNewClass test1', resource);
                }
            } else {
                // console.log('addNewClass first class of course', this.editedClass);
                if (!this.editedClass.hours_per_class || this.editedClass.hours_per_class === null) {
                    this.editedClass.hours_per_class = 1;
                }
                let n = new Date();
                this.editedClass.day = this.formattedDate(n,'date');
                this.editedClass.startTime = "08:00";
                var minutes = ':00'
                if (this.editedClass.hours_per_class !== Math.floor(this.editedClass.hours_per_class)) {
                    minutes = ':30';
                }
                let endTime = (8 + Math.floor(this.editedClass.hours_per_class)).toString()
                if (endTime.length === 1) {
                    this.editedClass.endTime = '0' + endTime + minutes;
                } else {
                    this.editedClass.endTime = this.formattedDate(endTime + minutes, 'time');
                }
                this.editedClass.duration = this.editedClass.hours_per_class * 60;
                this.editedClass.class_room_id = 1;
                if (resource.class_group === 'Group') {
                    this.editedClass.individual_instead = true;
                }
                let array = [];
                array.push(resource);
                this.editedClass.studentsObject = this.createStudentsObject(array);
                resource.newStudent = true;
                this.resourcesInClass.push(resource);
                this.editedClass = this.createClassFromDayTime(this.editedClass);
            }
            this.editedClass.newClass = true;
            this.editedClass.class_id = null;
            this.editedClass.outgoing_id = null;
            this.editedClass.autoPayment = true;
            this.editedClass.comments = '';
            // console.log('addNewClass test', this.editedClass)
            this.openDialogClass(this.editedClass);
            this.$store.dispatch('setLoadingApp', 'dis');
        },

        async applyCourse(reopen) {
            this.$store.dispatch('setLoadingApp', 'add');
            let resourceId = this.editedResource.resource_id;
            let pos
            let resourceLoaded = this.resourcesLoaded.filter(function(select_item) {
                return select_item.resource_id === resourceId;
            })[0];
            delete resourceLoaded.color;
            delete this.editedResource.color;
            delete resourceLoaded.minutes_available;
            delete this.editedResource.minutes_available;
            delete resourceLoaded.minutes_completed;
            delete this.editedResource.minutes_completed;
            delete resourceLoaded.minutes_used;
            delete this.editedResource.minutes_used;
            delete resourceLoaded.minutes_planned;
            delete this.editedResource.minutes_planned;
            if (!this.sameArrays(this.editedResource, resourceLoaded)) {
                // update resourceLoaded
                console.log('applyCourse update resource', this.editedResource, resourceLoaded)
                pos = this.resourcesLoaded.map(function(e) { return e.resource_id; }).indexOf(this.editedResource.resource_id);
                if (pos > -1) {
                    this.changeObjectInArray(this.resourcesLoaded, this.editedResource, pos)
                    await this.updateResource(this.editedResource)
                } else {
                    this.$store.dispatch('errorMessage', 'something went wrong when saving resource (' + this.editedResource.resource_id + '), please check')
                }
            } else {
                console.log('applyCourse resource not changed')
            }
            var onlyInClassesInResource = this.onlyInFirstArray(this.classesInResource, this.editedClassesInResource);
            console.log('Only in classesInResource', onlyInClassesInResource);
            var onlyInEditedClassesInResource = this.onlyInFirstArray(this.editedClassesInResource, this.classesInResource);
            console.log('Only in editedClassesInResource', onlyInEditedClassesInResource);
            for ( let i = 0; i < onlyInClassesInResource.length; i++ ) {
                if (onlyInEditedClassesInResource.find(x => x.class_id === onlyInClassesInResource[i].class_id) === undefined) {
                    console.log('applyCourse delete class')
                    this.deleteClass(onlyInClassesInResource[i])
                    let pos = this.events.map(function(e) { return e.class_id; }).indexOf(onlyInClassesInResource[i].class_id);
                    if (pos > -1) {
                        this.events.splice(pos,1);
                    }
                }
            }
            for ( let i = 0; i < onlyInEditedClassesInResource.length; i++ ) {
                if (onlyInEditedClassesInResource[i].class_id) {
                    let pos;
                    console.log('applyCourse update class', onlyInEditedClassesInResource[i])
                    pos = this.classesInResource.map(function(e) { return e.class_id; }).indexOf(onlyInEditedClassesInResource[i].class_id);
                    await this.writeClassToDB(this.classesInResource[pos], onlyInEditedClassesInResource[i]);
                } else {
                    console.log('applyCourse new class')
                    await this.writeClassToDB([], onlyInEditedClassesInResource[i])
                }
            }
            await this.loadDataAll();
            this.$store.dispatch('setLoadingApp', 'dis');
            this.closeDialogCourse();
            if (reopen) {
                this.openDialogCourse(resourceId)
            }
        },

        oneOfClassesPaid(arrayOfClasses) {
            if (arrayOfClasses.find(obj => {
                return obj.outgoing_id !== null
            }) !== undefined) {
                return true
            } else {
                return false
            }
        },

        async changeEditedClassesInResource(action, event) {
            console.log('changeEditedClassesInResource ', action);
            let dateMin = null;
            let dateMax = null;
            for (let i = 0; i < this.selectedClassesResources.length; i++) {
                if (this.resourceTeacher_id !== null) {
                    this.selectedClassesResources[i].teacher_id = this.resourceTeacher_id;
                }
                if (this.resourceClassStartTime !== null) {
                    this.selectedClassesResources[i].startTime = this.resourceClassStartTime;
                    this.selectedClassesResources[i].duration = this.calcDurationClass(this.selectedClassesResources[i]);
                    this.selectedClassesResources[i].minutes_used = this.calcMinutesUsed(this.selectedClassesResources[i]);
                    this.editedClass.paymentClass = this.calcPaymentClass(this.selectedClassesResources[i]).paymentClass;
                }
                if (this.resourceClassEndTime !== null) {
                    this.selectedClassesResources[i].endTime = this.resourceClassEndTime;
                    this.selectedClassesResources[i].duration = this.calcDurationClass(this.selectedClassesResources[i]);
                    this.selectedClassesResources[i].minutes_used = this.calcMinutesUsed(this.selectedClassesResources[i]);
                    this.editedClass.paymentClass = this.calcPaymentClass(this.selectedClassesResources[i]).paymentClass;
                    // console.log('changeEditedClassesInResource test1', this.editedClass.paymentClass, this.calcPaymentClass(this.selectedClassesResources[i].paymentClass));
                }
                if (this.resourceClass_room_id !== null) {
                    // console.log(this.resourceClass_room_id)
                    this.selectedClassesResources[i].class_room_id = this.resourceClass_room_id;
                    this.selectedClassesResources[i].class_room = this.getClassRoomId(this.resourceClass_room_id).name;
                }
                if (action === 'addStudent') {
                    if (dateMin > this.selectedClassesResources[i].day || dateMin === null) {
                        dateMin = this.selectedClassesResources[i].day
                    }
                    if (dateMax < this.selectedClassesResources[i].day || dateMax === null) {
                        dateMax = this.selectedClassesResources[i].day
                    }
                }
                if (action === 'removeStudent') {
                    // console.log('changeEditedClassesInResource test3', this.selectedClassesResources[i].studentsObject, event);
                    let pos = this.selectedClassesResources[i].studentsObject.map(function(e) { return e.resource_id; }).indexOf(event);
                    if (pos > -1) {
                        this.selectedClassesResources[i].studentsObject.splice(pos,1)
                        this.selectedClassesResources[i].students = this.createStudentsFromObject(this.selectedClassesResources[i].studentsObject);
                        this.selectedClassesResources[i].amount_students = this.selectedClassesResources[i].studentsObject.length;
                        if (this.selectedClassesResources[i].amount_students === 1) {
                            this.selectedClassesResources[i].individual_instead = true;
                        }
                    }
                }
                console.log('changeEditedClassesInResource test4', this.selectedClassesResources[i].day);
                this.selectedClassesResources[i] = this.createClassFromDayTime(this.selectedClassesResources[i]);
            }
            if (action === 'addStudent') {
                this.openSelectStudentResource(this.selectedClassesResources[0], dateMin, dateMax);
            } else {
                await this.createResourcesInCourse();
            }

        },

        calcMinutesUsed(itemClass) {
            console.log('calcMinutesUsed', itemClass)
            if (itemClass.individual_instead) {
                return itemClass.duration / 3 * 4;
            } else {
                return itemClass.duration;
            }
        },

        getClassRoomId(classRoomId) {
            let classRoom =  this.classRooms.filter(function(select_item) {
                return select_item.id === classRoomId;
            })[0];
            return classRoom;
        },

        getClassRoomLabel(classRoomLabel) {
            let classRoom =  this.classRooms.filter(function(select_item) {
                return select_item.label === classRoomLabel;
            })[0];
            return classRoom;
        },

        async filterSelectionClassesResources(resourceId) {
            this.resourceTeacher_id = null;
            this.resourceClassStartTime = null;
            this.resourceClassEndTime = null;
            this.resourceClass_room_id = null;
            this.studentSelectedForRemovalId = null;
            this.studentsSelectedForRemoval = [];
            let resourcesTemp = [];
            let studentsObjectsMerged = [];
            for (let i = 0; i < this.selectedClassesResources.length; i++) {
                let nextStudentObject = this.selectedClassesResources[i].studentsObject;
                for (let j = 0; j < nextStudentObject.length; j++) {
                    if (studentsObjectsMerged.map(function(e) { return e.resource_id; }).indexOf(nextStudentObject[j].resource_id) === -1 && nextStudentObject[j].resource_id !== resourceId) {
                        studentsObjectsMerged = studentsObjectsMerged.concat(nextStudentObject[j]);
                    }
                }
            }
            resourcesTemp = await this.createResourcesFromStudentsObject(studentsObjectsMerged);
            // console.log('filterSelectionClassesResources', resourcesTemp);
            this.studentsSelectedForRemoval = resourcesTemp;
        },

        createUUID() {
            return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
            );
        },

        setToday () {
            var date = new Date();
            this.focusCalendar = this.formattedDate(date,'date');
            console.log('setToday')
            this.loadDataTabCalendar();
        },

        prev () {
            this.loadDataTabCalendar();
            this.$refs.calendar.prev();
        },

        next () {
            this.loadDataTabCalendar();
            this.$refs.calendar.next();
        },

        clickDate() {
            if (this.scopeCalendar !== 'category') {
                this.changingScopeCalendar('category')
            }
            this.loadDataTabCalendar();
        },

        transferCoursePropsToClass(resource) {
            console.log('transferCoursePropsToClass')
            this.editedClass.class_language = resource.class_language;
            this.editedClass.class_type = resource.class_type;
            this.editedClass.class_location = resource.class_location;
            this.editedClass.class_group = resource.class_group;
            this.editedClass.level = resource.initial_spanish_level;
            this.editedClass.icon = this.getIconResource(resource);
        },

        getIconResource(resource) {
            if (resource.class_language === 'English') {
                return "$vuetify.icons.icon_english";
            } else if (resource.class_language === 'Quechua') {
                return "$vuetify.icons.icon_quechua";
            } else {
                return "$vuetify.icons.icon_spanish";
            }
        },
        
        checkAllTeachersSelected() {
            var allSelected = true;
            for (let index = 0; index < this.teachersTable.length; index++) {
                if ((this.scopeLanguage === 'Spanish' && this.teachersTable[index].spanish === 1) || (this.scopeLanguage === 'English' && this.teachersTable[index].english === 1) || (this.scopeLanguage === 'Quechua' && this.teachersTable[index].quechua === 1)) {
                    if (!this.teachersTable[index].visible) {
                        allSelected = false
                    }
                }
            }
            this.$store.dispatch('changeSelectAllTeachers', allSelected);
        },

        changingScopeResources(event) {
            // console.log('changingScopeResources', event, this.resourcesLoaded.length, this.resourcesScope.length)
            this.$store.dispatch('changeScopeResources', event);
            if (this.scopeResources === 'completed') {
                this.loadDataTabResources();
            } else {
                this.resourcesScope = this.filterResources(this.resourcesLoaded);
            }
            
        },

        

        // getClassRoomName(classRoomName) {
        //     let classRoom =  this.classRooms.filter(function(select_item) {
        //         return select_item.name === classRoomName;
        //     })[0];
        //     return classRoom;
        // },

        

        selectTeachers(item) { 
            if (item.class_language === 'Spanish') {
                return this.teachersTable.filter(function(select_item) {
                    return select_item.obsolete === null && select_item.spanish;
                })
            }
            if (item.class_language === 'Quechua') {
                return this.teachersTable.filter(function(select_item) {
                    return select_item.obsolete === null && select_item.quechua;
                })
            }
            if (item.class_language === 'English') {
                return this.teachersTable.filter(function(select_item) {
                    return select_item.obsolete === null && select_item.english;
                })
            }
        },

        getTeacher(teacherId) {
            let teacher =  this.teachersTable.filter(function(select_item) {
                return select_item.id === teacherId;
            })[0];
            return teacher;
        },

        changingScopeCalendar(event) {
             this.$store.dispatch('changeScopeCalendar', event);
             this.loadDataTabCalendar();
        },

        filterSelectionResources(event) {
            console.log('filterSelectionResources', event.value);
            let countIsSelectable = this.resourcesSelectList.filter(function(select_item) {
                return select_item.isSelectable;
            });
            if (this.selectedResources.length === 0 || (event.value && this.selectedResources.length === countIsSelectable.length)) {
                this.selectedResourcesSingleSelect = true;
                this.resourcesSelectList.map((x) => x.isSelectable = true);
                this.selectedResources = [];
            } else {
                if (this.selectedResources[0].class_group === 'Group') {
                    this.selectedResourcesSingleSelect = false;
                    for (let i = 0; i < this.resourcesSelectList.length; i++) {
                        if (this.selectedResources[0].class_language === this.resourcesSelectList[i].class_language && this.selectedResources[0].class_type === this.resourcesSelectList[i].class_type && this.resourcesSelectList[i].class_group === 'Group') {
                            this.resourcesSelectList[i].isSelectable = true;
                        } else {
                            this.resourcesSelectList[i].isSelectable = false;
                        }
                    }
                } else {
                    this.selectedResourcesSingleSelect = true;
                }
                
            }
        },

        // calcAvailableHoursResource (resource, individualInstead) {
        //     if (individualInstead || individualInstead === 1) {
        //         return Math.floor((resource.minutes_available) / 60 * 2 * 3 / 4) / 2;
        //     } else {
        //         return Math.floor((resource.minutes_available) / 60 * 2) / 2;
        //     }
        // },

        calcPaymentClass (itemClass) {
            // console.log('calcPaymentClass', itemClass)
            if (itemClass.teacher_id && (itemClass.autoPayment || itemClass.autoPayment === 1)) {
                let teacher_id = itemClass.teacher_id;
                let teacher = this.teachersTable.filter(function(select_item) {
                    return select_item.id === teacher_id;
                })[0];
                // console.log('calcPaymentClass test1', teacher)
                if (itemClass.class_group === 'Individual' && itemClass.class_type === 'Normal') {
                    itemClass.paymentClass = teacher.rate_individual * itemClass.duration / 60;
                    itemClass.rate = teacher.rate_individual;
                }
                if (itemClass.class_group === 'Group' && itemClass.class_type === 'Normal' && (itemClass.individual_instead === 0 || !itemClass.individual_instead)) {
                    itemClass.paymentClass = teacher.rate_group * itemClass.duration / 60;
                    itemClass.rate = teacher.rate_group;
                }
                if (itemClass.class_group === 'Group' && itemClass.class_type === 'Normal' && (itemClass.individual_instead === 1 || itemClass.individual_instead)) {
                    itemClass.paymentClass = teacher.rate_individual * itemClass.duration / 60;
                    itemClass.rate = teacher.rate_individual;
                    // console.log('calcPaymentClass test2', teacher.rate_individual, itemClass.duration)
                }
                if (itemClass.class_group === 'Individual' && itemClass.class_type === 'Special') {
                    itemClass.paymentClass = teacher.rate_special * itemClass.duration / 60;
                    itemClass.rate = teacher.rate_special;
                }
                if (itemClass.class_group === 'Group' && itemClass.class_type === 'Special') {
                    itemClass.paymentClass = teacher.rate_special_group * itemClass.duration / 60;
                    itemClass.rate = teacher.rate_special_group;
                }
            }
            return itemClass;
        },

        timeToTimeStamp(day, time) {
            // console.log("timeToTimeStamp", day, time)
            let minStart = time.substring(time.length - 2, time.length);
            let hourStart = time.substring(0, time.length - 3);
            // console.log('timeToTimeStamp', this.toTimestamp(this.formattedDate(dayjs(day).hour(hourStart).minute(minStart), "dateTime")))
            return this.toTimestamp(this.formattedDate(dayjs(day).hour(hourStart).minute(minStart), "dateTime"));
        },

        changeObjectInArray(array, object, pos) {
            if (pos > -1) {
                let objectPropNames = Object.keys(object)
                for ( let i = 0; i < objectPropNames.length; i++ ) {
                    array[pos][objectPropNames[i]] = object[objectPropNames[i]]
                }
            }
        },

        async updateResource (resource) {
            (resource.start) ? resource.start = this.formattedDate(resource.start, 'date') : resource.start = null;
            (resource.end) ? resource.end = this.formattedDate(resource.end, 'date') : resource.end = null;
            (resource.info_to_provider) ? resource.info_to_provider = this.formattedDate(resource.info_to_provider, 'date') : resource.info_to_provider = null;
            (resource.info_to_client) ? resource.info_to_client = this.formattedDate(resource.info_to_client, 'date') : resource.info_to_client = null;
            (resource.reserved) ? resource.reserved = this.formattedDate(resource.reserved, 'date') : resource.reserved = null;
            resource.id = resource.resource_id; // table resources prim key is ID
            // console.log('updateResource insertResource', resource);
            await this.$store.dispatch('insertResource', resource);
        },

        closeDialogCourse() {
            this.editedResource = {};
            this.classesInResource = [];
            this.editedClassesInResource = [];
            this.allHoursUsedCourse = false;
            this.selectedClassesResources = [];
            this.resourcesInCourse = [];
            this.resourcesInCourseOriginal = [];
            this.resourcesInCourseDisplay = []
        },

        changingScopeClasses(event) {
            this.$store.dispatch('changeScopeClasses', event);
            this.loadDataTabTeachers();
        },

        itemRowBackgroundResources: function (item) {
            if(item.class_group === "Group" && item.class_type === "Normal") {
                return 'background_normal_group'
            }
            if(item.class_group === "Group" && item.class_type === "Special") {
                return 'background_special_group'
            }
            if(item.class_group === "Individual" && item.class_type === "Normal") {
                return 'background_normal_individual'
            }
            if(item.class_group === "Individual" && item.class_type === "Special") {
                return 'background_special_individual'
            }
            return 'white-background';
        },

        goClient(id, resourceId) {
            this.$store.dispatch('setLoadingApp', 1);
            this.$router.push({ name: 'Client', params: { id: id, resourceId: resourceId } })
        },

        showAllTeachers(visibility) {
            let payload = {};
            for (let index = 0; index < this.teachersTable.length; index++) {
                if ((this.scopeLanguage === 'Spanish' && this.teachersTable[index].spanish === 1) || (this.scopeLanguage === 'English' && this.teachersTable[index].english === 1) || (this.scopeLanguage === 'Quechua' && this.teachersTable[index].quechua === 1) || (this.scopeLanguage === 'allLanguages')) {
                    payload = {
                        index: index,
                        visibility: visibility,
                    }
                } else {
                    payload = {
                        index: index,
                        visibility: false,
                    }
                }
                this.$store.dispatch('changeTeacherVisibility', payload);
            }
        },

        // async getResourcesClass (itemClass) {
        //     let resourcesInClass = await this.$store.dispatch('getResourcesClassId', itemClass.class_id);
        //     return resourcesInClass;
        // },

        async payClasses(teacher) {
            if (this.selectedClassesTeachers.length > 0) {
                console.log(teacher)
                let correction = 0;
                let comments_correction = '';
                (teacher.correction) ? correction = teacher.correction : correction = 0;
                correction = parseFloat(correction);
                (teacher.comments_correction) ? comments_correction = teacher.comments_correction : comments_correction = '';
                let paymentProfe = {
                    teacher: teacher.id,
                    contactId: teacher.contact_id,
                    language: this.selectedClassesTeachers[0].class_language,
                    discountPayment: teacher.discount_payment,
                    classes: this.selectedClassesTeachers,
                    correction: correction,
                    comments_correction: comments_correction,
                }
                teacher.correction = null;
                teacher.comments_correction = null;
                this.$router.push({ name: 'Financial', params: { paymentProfe: paymentProfe, appId: null, transId: null } })
            }
        },

        openTransactionOutgoings(transIdOutgoings) {
            this.$store.dispatch('setLoadingApp', 1)
            this.$router.push({ name: 'Financial', params: { transIdOutgoings: transIdOutgoings } })
        },

        async goClassesScheduleStudent (resource) {
            let payload = [];
            payload.classes = await this.$store.dispatch('getClassesClientId', resource.client_id);
            payload.classes.map(this.correctAutoPaymentClasses);
            if (payload.classes.length > 0) {
                if (!resource.info_to_client) {
                    let n = new Date();
                    resource.info_to_client = this.formattedDate(n,'date');
                    this.updateResource(resource);
                }
                payload.student = resource.full_name;
                payload.email = resource.email;
                payload.resourceId = resource.resource_id;
                await this.$store.dispatch('saveDataForReport', payload);
                this.$router.push({ name: 'ClassScheduleStudent'});
            } else {
                this.$store.dispatch('warningMessage', "No classes planned for this client");
            }
        },

        async goClassesScheduleTeacher (teacher) {
            let report = JSON.parse(JSON.stringify(teacher));
            if (this.selectedClassesTeachers.length > 0) {
                report.classes = []; 
                for (let index = 0; index < this.selectedClassesTeachers.length; index++) {
                    report.classes.push(this.selectedClassesTeachers[index]);
                }
            }
            console.log('saveDataForReport', report);
            await this.$store.dispatch('saveDataForReport', report);
            this.$router.push({ name: 'ClassScheduleTeacher'});
        },

        async goPaymentTeacherForm(teacher) {
            let payload = [];
            payload.classes = this.selectedClassesTeachers;
            payload.totalPayment = 0;
            for (let index = 0; index < this.selectedClassesTeachers.length; index++) {
                payload.totalPayment = payload.totalPayment + this.selectedClassesTeachers[index].paymentClass;
            }
            payload.teacher = teacher.contact;
            (teacher.correction) ? payload.correction = teacher.correction : payload.correction = 0;
            payload.correction = parseFloat(payload.correction);
            (teacher.comments_correction) ? payload.comments_correction = teacher.comments_correction : payload.comments_correction = '';
            payload.discountPayment = teacher.discount_payment,
            await this.$store.dispatch('saveDataForReport', payload);
            this.$router.push({ name: 'PaymentTeacherForm'});
        },

        getBackgroundColorTeachers: function (item) {
            return item.teacherRowClass;
        },

        getStatusResource(resource) {
            let statusResourceId = resource.statusResourceId
            return this.$store.state.statusResources.filter((select_item) => {
                return select_item.id === statusResourceId;
            })[0];
        },

        async setSendToStudent(resourceId) {
            let resource = await this.$store.dispatch('getCourseResourceId', resourceId);
            resource.lastSchedule = null;
            resource.id = resource.resource_id;
            this.updateResource(resource);
        },

        async setSendToTeacher(teacherId) {
            let payload = {
                id: teacherId,
                lastSchedule: null
            };
            await this.$store.dispatch('updateTeacherSchedule', payload);
        },

        sameArrays(arrayOne, arrayTwo) {
            let arrayOneJSON = JSON.stringify(arrayOne);
            let arrayTwoJSON = JSON.stringify(arrayTwo);
            let result = (arrayOneJSON === arrayTwoJSON);
            return result;
        },

        onlyInFirstArray(firstArray, secondArray) { 
            // console.log('onlyInFirstArray', firstArray, secondArray)
            let arrayOne = JSON.parse(JSON.stringify(firstArray))
            let arrayTwo = JSON.parse(JSON.stringify(secondArray))
            return arrayOne.filter(arrayOneValue =>
                !arrayTwo.some(arrayTwoValue => 
                    this.sameArrays(arrayOneValue, arrayTwoValue)));
        },
    },
}

</script>

<style>
    /* .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .alertRed {color: red;}
    .okGreen {color: green;} */

    .cursorPointer {
        cursor: pointer;
    }

    .background_normal_group {
        background-color: #4CAF50;
    }
    .background_special_group {
        background-color: #3F51B5;
    }
    .background_normal_individual {
        background-color: #00BCD4;
    }
    .background_special_individual {
        background-color: #FF9800;
    }
    .white-background {
        background-color: white;
    }

    .backgroundColor1 {
        background-color: #D5F5E3;
    }

    .backgroundColor2 {
        background-color: #EBDEF0;
    }

    .backgroundColor3 {
        background-color: #FDEBD0;
    }

    .backgroundColor4 {
        background-color: #AED6F1;
    }

    /* .theme--light.v-data-table.invert-colors > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
        background: #888;
    } */

    .v-event-draggable {
        padding-left: 6px;
    }

    .v-event-timed {
        user-select: none;
        -webkit-user-select: none;
    }

    .v-event-drag-bottom {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 4px;
            height: 4px;
            cursor: ns-resize;

            &::after {
            display: none;
            position: absolute;
            left: 50%;
            height: 4px;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
            width: 16px;
            margin-left: -8px;
            opacity: 0.8;
            content: '';
        }

        &:hover::after {
            display: block;
        }
    }


</style>