<template>
    <v-container v-if="$store.getters.loadingApp !== 1">
        <v-data-table
            :headers="headers"
            :items="updatedOrganisations"
            class="elevation-1"
            :items-per-page="-1"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-toolbar-title>Organisations of {{ contactName }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="ppc_pink"
                        dark
                        small
                        absolute
                        right
                        fab
                        @click="dialog=true"
                    >
                        <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                    </v-btn>
                    <v-dialog
                        v-model="dialog"
                        max-width="500px"
                        persistent
                    >
                        <v-form ref="dialogForm">
                            <v-card>
                                <v-card-title class="ppc_darkgreen darken-1">
                                    <span class="headline white--text">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            md="8"
                                        >
                                            <v-text-field
                                                v-model.trim="editedItem.name"
                                                label="Name"
                                                counter
                                                :rules="inputRulesName"
                                            ></v-text-field>
                                        </v-col>
                                        <v-checkbox
                                            :input-value="editedItem.obsolete !== null"
                                            label="Obsolete"
                                            @change="setObsolete($event)"
                                        ></v-checkbox>
                                        <v-text-field
                                            :value="formattedDate(editedItem.obsolete)"
                                            disabled
                                            label="From"
                                            v-if="editedItem.obsolete !== null"
                                            class="ml-2"
                                        ></v-text-field>
                                    </v-row>
                                </v-card-text>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="ppc_darkgreen darken-1"
                                    text
                                    @click="close"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="ppc_darkgreen darken-1"
                                    text
                                    @click="save"
                                >
                                    Save
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title>Are you sure you want to delete {{ editedItem.option_name }}?</v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="ppc_darkgreen darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-btn color="ppc_darkgreen darken-1" text @click="deleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:[`item.obsolete`]="{ item }">
                <v-icon small v-if="item.obsolete !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    $vuetify.icons.icon_mdi_pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    $vuetify.icons.icon_mdi_delete
                </v-icon>
            </template>
        </v-data-table>
        <v-divider></v-divider>
        <div class="d-flex flex-row mb-7">
            <v-spacer></v-spacer>
            <v-btn color="ppc_darkgreen darken-1" text @click="$router.go(-1)">Ok</v-btn>
        </div>
        
    </v-container>
</template>

<script>

import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import dayjs from 'dayjs';

export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Obsolete', value: 'obsolete' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      updatedOrganisations: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        obsolete: null,
      },
      defaultItem: {
        name: '',
        obsolete: null,
      },
      contactId: null,
      contactName: null,
    }),

    mixins: [inputRules, formattedDate],

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Organisation' : 'Edit Organisation'
        },
    },

    watch: {
    },

    beforeCreate () {
        this.$store.dispatch('setLoadingApp', 1)
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.contactId = this.$route.params.contactId;
        let tmp = this.contactId;
        this.contactName = this.$store.state.contacts.filter(function(select_item) {
            return select_item.id === tmp;
        })[0].full_name;
        this.updatedOrganisations = await this.$store.dispatch('getOrganisations', this.contactId);
        this.$store.dispatch('setLoadingApp', 0)
      },

      editItem (item) {
        this.editedIndex = this.updatedOrganisations.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.updatedOrganisations.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        let response = await this.$store.dispatch('deleteOrganisation', this.editedItem.id);
        if (response.data.affectedRows === 1) {
            this.$store.dispatch('successMessage', 'Organisation deleted');
        } else {
            this.$store.dispatch('errorMessage', 'Something went wrong');
        }
        this.updatedOrganisations = await this.$store.dispatch('getOrganisations', this.contactId);
        // this.updatedOrganisations = JSON.parse(JSON.stringify(this.available_options));
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      },

      closeDelete () {
        this.dialogDelete = false
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      },
      async save () {
          if(this.$refs.dialogForm.validate()){
            let tmp = this.editedItem.name;
            let doubleName = this.updatedOrganisations.filter(function(select_item) {
                    return select_item.name === tmp;
                }
            )
            if (doubleName.length > 0 && this.editedIndex !== this.updatedOrganisations.indexOf(doubleName[0])) {
                this.$store.dispatch('errorMessage', 'The name "' + this.editedItem.name + '" is already in use!');
                return;
            } else {
                this.editedItem.contact_id = this.contactId;
                await this.$store.dispatch('insertOrganisation', this.editedItem);
                this.updatedOrganisations = await this.$store.dispatch('getOrganisations', this.contactId);
            }
            this.close()
          }
      },
      setObsolete(event) {
            if (event) {
                this.editedItem.obsolete = dayjs().format("YYYY-MM-DD")
            } else {
                this.editedItem.obsolete = null
            }
        },
    },
  }

</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
</style>