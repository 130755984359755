import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

//init
store.dispatch('signUserOut');
store.dispatch('setLoadingApp', 1);
router.push({ name: 'root' });

store.dispatch('testConnection').then(() => {

  const getGeneralSettings = new Promise((resolve) => {store.dispatch('getGeneralSettings').then((result) => {
      resolve(result);
    })
  });
  const getUsers = new Promise((resolve) => {store.dispatch('getUsers').then((result) => {
      resolve(result);
    })
  });
  const getContacts = new Promise((resolve) => {store.dispatch('getContacts').then((result) => {
      resolve(result);
    })
  });
  const getNationalities = new Promise((resolve) => {store.dispatch('getNationalities').then((result) => {
      resolve(result);
    })
  });
  const getSources = new Promise((resolve) => {store.dispatch('getSources').then((result) => {
      resolve(result);
    })
  });
  const getTypes = new Promise((resolve) => {store.dispatch('getTypes').then((result) => {
      resolve(result);
    })
  });
  const getLanguageLevels = new Promise((resolve) => {store.dispatch('getLanguageLevels').then((result) => {
      resolve(result);
    })
  });
  const getTeachers = new Promise((resolve) => {store.dispatch('getTeachers').then((result) => {
      resolve(result);  
    })
  });
  const getClassRooms = new Promise((resolve) => {store.dispatch('getClassRooms').then((result) => {
      resolve(result);
    })
  });
  const getProjects = new Promise((resolve) => {store.dispatch('getProjects').then((result) => {
      resolve(result);
    })
  });
  const getAvailableImages = new Promise((resolve) => {store.dispatch('getAvailableImages').then((result) => {
      resolve(result);
    })
  });
  const getProjectCategories = new Promise((resolve) => {store.dispatch('getProjectCategories').then((result) => {
      resolve(result);
    })
  });
  const getProjectPlacements = new Promise((resolve) => {store.dispatch('getProjectPlacements').then((result) => {
      resolve(result);
    })
  });
  const getAvailableAgencies = new Promise((resolve) => {store.dispatch('getAvailableAgencies').then((result) => {
      resolve(result);
    })
  });
  const getAvailableServices = new Promise((resolve) => {store.dispatch('getAvailableServices').then((result) => {
      resolve(result);
    })
  });
  const getPaymentMethods = new Promise((resolve) => {store.dispatch('getPaymentMethods').then((result) => {
      resolve(result);  
    })
  });

  Promise.all([getGeneralSettings, getUsers, getContacts, getNationalities, getSources, getTypes, getLanguageLevels, getTeachers, getClassRooms, getProjects, getAvailableImages, getProjectCategories, getProjectPlacements, getAvailableAgencies, getAvailableServices, getPaymentMethods]).then((value) => {
    store.dispatch('setLoadingApp', 0);
    store.dispatch('getExchangeRates');
    if ((value.filter(result => result === 'ok')).length !== 16) {
      console.log('something went wrong', value);
      store.dispatch('errorMessage', 'Something went wrong, contact the administrator');
    } else {
      console.log('loading Cusco ready', value);
    }
    
  });

});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
