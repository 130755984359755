<template>
  <v-container style="max-width: 100%">
    
    <v-data-table
        :headers="headersapplications"
        :items="applications"
        class="elevation-1 ma-2"
        :items-per-page="-1"
        sort-by="dateTime"
        dense
        :single-select=false
        :show-select=true
        v-model="selectedApplications"
        @input="reviewSelects($event)"
        :search="searchApplicationTable"
        fixed-header
        height="60vh"
        :disabled="$store.getters.loadingApp === 1"
    >
        <template v-slot:top>
            <div class="d-flex flex-column my-8">
                <div class="d-flex justify-center mt-8">
                    <v-radio-group
                        row
                        v-model="directOrAgencies"
                        class="mt-0 mx-4"
                        @change="getApplications()"
                    >
                        <v-radio
                            label="Direct Clients"
                            value="directClients"
                            class="my-0 py-0"
                        ></v-radio>
                        <v-radio
                            label="Agencies"
                            value="Agencies"
                            class="my-0 py-0"
                        ></v-radio>
                        <div class="mx-4">
                            <v-select
                                style="width: 250px"
                                v-model="selectedAgency"
                                :items="$store.state.available_agencies"
                                item-text="name"
                                item-value="id"
                                :menu-props="{ auto: true }"
                                label="All Agencies"
                                single-line
                                validate-on-blur
                                clearable
                                @change="getApplications()"
                                class="mt-0 pt-0"
                                :disabled="directOrAgencies !== 'Agencies'"
                            >
                            </v-select>
                        </div>
                        <v-radio
                            label="All Applications"
                            value="all"
                            class="my-0 py-0"
                        ></v-radio>
                    </v-radio-group>
                </div>
                <div class="d-flex flex-row flex-wrap justify-center mb-2">
                    <div>
                        <v-radio-group
                            row
                            v-model="scopeApplications"
                            class="mt-0 mx-4"
                            @change="getApplications()"
                            :disabled="directOrAgencies === 'all'"
                        >
                            <v-radio
                                label="Ongoing"
                                value="ongoing"
                                class="my-0 py-0"
                            ></v-radio>
                            <v-radio
                                label="Not Paid"
                                value="notPaid"
                                class="my-0 py-0"
                            ></v-radio>
                            <v-radio
                                label="Completed"
                                value="completed"
                                class="my-0 py-0"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                </div>
                <div class="d-flex flex-row flex-wrap justify-center">
                    <v-card flat>
                        <v-text-field
                            v-model="searchApplicationTable"
                            append-icon="$vuetify.icons.icon_mdi_magnify"
                            :label="'Search ' + `${ applications.length }` + ' application(s)'"
                            single-line
                            hide-details
                            style="width: 400px"
                            clearable
                        ></v-text-field>
                    </v-card>
                    <!-- {{ selectedApplications.length }} -->
                    <v-btn
                        :disabled="selectedApplications.length < 2"
                        class="mx-4 mt-2"
                        @click="mergeApplications()"
                    >
                        Merge Selected    
                    </v-btn>
                </div>
            </div>
        </template>
        <template v-slot:[`item.applied`]="{ item }">
            <span v-html="formattedDate(item.applied, 'dateYearShort')"></span>
        </template>
        <template v-slot:[`item.start_first_service`]="{ item }">
            <span v-html="formattedDate(item.start_first_service, 'dateYearShort')"></span>
        </template>
        <template v-slot:[`item.end_last_service`]="{ item }">
            <span v-html="formattedDate(item.end_last_service, 'dateYearShort')"></span>
        </template>
        <template v-slot:[`item.totalPrice`]="{ item }">
            <span v-if='item.totalPrice' v-html="'$' + parseFloat(item.totalPrice).toFixed(2)"></span>
        </template>
        <template v-slot:[`item.open`]="{ item }">
            <span v-if='item.open && (item.statusAppId ===16)' v-html="'<FONT COLOR=grey><strong>$' + item.open.toFixed(2) + '</strong>'"></span>
            <span v-else-if='item.open' v-html="'<FONT COLOR=red><strong>$' + item.open.toFixed(2) + '</strong>'"></span>
            <span v-else v-html="'<FONT COLOR=green><strong>PAID</strong>'"></span>
        </template>
        <template v-slot:[`item.statusAppId`]="{ item }">
            <v-chip :color="getStatusApp(item.statusAppId).color" small text-color="white" v-html="getStatusApp(item.statusAppId).name"></v-chip>
        </template>
        <template v-slot:[`item.frozen`]="{ item }">
            <v-icon small v-if="item.frozen === 1" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="goApplication(item.id)"
            >
                $vuetify.icons.icon_mdi_pencil
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="removeApplication(item.id)"
                v-if="item.amountOfServices === 0"
                :disabled="parseFloat(item.amountPaidApplication) > 0"
            >
                $vuetify.icons.icon_mdi_delete
            </v-icon>
        </template>
    </v-data-table>
  </v-container>
</template>

<script>
import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import getStatusApp from '../mixins/getStatusApp';
import createAppTitle from '../mixins/createAppTitle';
import dayjs from 'dayjs';
export default {
  data() {
    return {
      applications: [],
      headersapplications: [
          { text: 'Application', align: 'start', value: 'appTitle', width: '200px' },
          { text: 'Payer Name', value: 'payerName', width: '150px' },
        //   { text: '#Services', value: 'amountOfServices' },
          { text: 'Applied on', value: 'applied', width: '100px' },
        //   { text: 'Application nr', value: 'id' },
          { text: 'Start', value: 'start_first_service', width: '100px' },
          { text: 'End', value: 'end_last_service', width: '100px' },
          { text: 'Price', value: 'totalPrice' },
        //   { text: 'Deposit', value: 'depositPaid' },
        //   { text: 'Paid', value: 'amountPaidApplication' },
          { text: 'Open', value: 'open' },
          { text: 'Comments', value: 'comments', width: '200px' },
          { text: 'Status', value: 'statusAppId', width: '100px' },
          { text: 'Frozen', value: 'frozen', width: '10px' },
          { text: 'Actions', value: 'actions', sortable: false, width: '80px' },
      ],
        loadingButtonAllApplications: false,
        loadingButtonNotCompleted : false,
        loadingButtonNotPaid: false,
        scopeApplications: 'ongoing',
        directOrAgencies: 'directClients',
        selectedAgency: null,
        selectedApplications: [],
        searchApplicationTable: '',
    }
  },

  mixins: [inputRules, formattedDate, getStatusApp, createAppTitle],
  
  watch: {},
  computed: {},

  created () {
        dayjs.locale("en");
        this.initialize();
  },

  methods: {
    async initialize () {
      this.$store.dispatch('setLoadingApp', 1)
      this.$store.dispatch('breadcrumbs', ['Applications']);
      if (this.$store.state.directOrAgencies) {
        this.directOrAgencies = this.$store.state.directOrAgencies;
      }
      if (this.$store.state.scopeApplications) {
        this.scopeApplications = this.$store.state.scopeApplications;
      }
      if (this.$store.state.selectedAgency) {
        this.selectedAgency = this.$store.state.selectedAgency;
      }
      await this.getApplications();
    },

    async getApplications() {
        this.$store.dispatch('setLoadingApp', 1)
        // console.log(this.directOrAgencies, this.scopeApplications, this.selectedAgency);
        if (this.directOrAgencies === 'all') {
            this.applications = await this.$store.dispatch('getAllApplications');
        } else if (this.directOrAgencies === 'directClients') {
            if (this.scopeApplications === 'ongoing') {
                this.applications = await this.$store.dispatch('getApplicationsOngoingDirectServices');
            } else if (this.scopeApplications === 'notPaid') {
                this.applications = await this.$store.dispatch('getApplicationsNotPaidDirectServices');
            } else if (this.scopeApplications === 'completed') {
                this.applications = await this.$store.dispatch('getApplicationsCompletedDirectServices');
            }
        } else {
            var payload = this.selectedAgency;
            if (this.scopeApplications === 'ongoing') {
                this.applications = await this.$store.dispatch('getApplicationsOngoingAgencies', payload);
            } else if (this.scopeApplications === 'notPaid') {
                this.applications = await this.$store.dispatch('getApplicationsNotPaidAgencies', payload);
            } else if (this.scopeApplications === 'completed') {
                this.applications = await this.$store.dispatch('getApplicationsCompletedAgencies', payload);
            }
        }
        this.storeScope();
        this.checkApplications();
        this.$store.dispatch('setLoadingApp', 0)

        // if (event === null) {
        //     this.getApplications()
        // } else {
        //     await this.$store.dispatch('changeScopeApplications', event);
        //     await this.$store.dispatch('changeSelectedAgency', event);
        //     let sqlTxt = 'SELECT * FROM view_applications WHERE payerAgency = '
        //     sqlTxt = sqlTxt.concat(event)
        //     this.applications = await this.$store.dispatch('openQuery', sqlTxt);
        //     this.checkApplications();
        // }
    },

    storeScope() {
        this.$store.dispatch('changeDirectOrAgencies', this.directOrAgencies);
        this.$store.dispatch('changeScopeApplications', this.scopeApplications);
        this.$store.dispatch('changeSelectedAgency', this.selectedAgency);
    },

    checkApplications () {
        if (this.applications) {
            this.selectedApplications = [];
            for (let index = 0; index < this.applications.length; index++) {
                if (this.applications[index].payerClient !== null) {
                    this.applications[index].payerName = this.applications[index].payerClientName;
                } else if (this.applications[index].payerAgency !== null) {
                    this.applications[index].payerName = this.applications[index].payerAgencyName;
                }
                if (parseFloat(this.applications[index].amountPaidApplication) === null) {
                    this.applications[index].amountPaidApplication = 0
                }
                if (this.applications[index].comments === null) {
                    this.applications[index].comments = '';
                }
                if (this.applications[index].refund > 0 || this.applications[index].fixedPrice > 0  || this.applications[index].discount > 0 || this.applications[index].extraChargeUSD > 0 || this.applications[index].comments.trim().length > 0 || parseFloat(this.applications[index].amountPaidApplication) > 0 || this.applications[index].statusFixedClient === 8 || this.applications[index].statusFixedClient === 9) {
                    this.applications[index].isSelectable = false;
                } else {
                    this.applications[index].isSelectable = true;
                }
                this.applications[index].open = parseFloat(this.applications[index].totalPrice).toFixed(2) - parseFloat(this.applications[index].amountPaidApplication).toFixed(2);
            }
        }
    },

    async goApplication(id) {
        await this.$store.dispatch('saveDataForReport', id);
        this.$router.push({ name: 'Application' })
    },
    
    async reviewSelects(event) {
        console.log('reviewSelects', event);
        let payer = null;
        // console.log('reviewSelects', event.item.payerClient);
        for (let index = 0; index < this.selectedApplications.length; index++) {
            if (this.selectedApplications[index].payerClient && this.selectedApplications[index].payerClient !== null) {
                payer = 'client'
            } else {
                payer = this.selectedApplications[index].payerAgency
            }
        }
        // if(event.value) {
        //     this.amountSelected = this.amountSelected + 1;
        // } else {
        //     this.amountSelected = this.amountSelected - 1;
        //     if (this.amountSelected === 0) {
        //         payer = null;
        //     }
        // }
        if(this.selectedApplications.length === 0) {
            payer = null;
        }
        for (let index = 0; index < this.applications.length; index++) {
            if (payer === 'client') {
                if (this.applications[index].payerClient && this.applications[index].payerClient !== null) {
                    this.applications[index].isSelectable = true;
                } else {
                    this.applications[index].isSelectable = false;
                }
            } else if (payer !== null) {
                if (this.applications[index].payerAgency === payer) {
                    this.applications[index].isSelectable = true;
                } else {
                    this.applications[index].isSelectable = false;
                }
            } else {
                this.applications[index].isSelectable = true;
            }
            if (this.applications[index].comments === null) {
                this.applications[index].comments = '';
            }
            if (this.applications[index].refund > 0 || this.applications[index].fixedPrice > 0  || this.applications[index].discount > 0 || this.applications[index].extraChargeUSD > 0 || this.applications[index].comments.trim().length > 0 || parseFloat(this.applications[index].amountPaidApplication) > 0 || this.applications[index].statusFixedClient === 8 || this.applications[index].statusFixedClient === 9) {
                this.applications[index].isSelectable = false;
            }
        }
    },

    async mergeApplications() {
        this.$store.dispatch('progressAdvance', 0);
        this.$store.dispatch('showProgressBar', true);
        let progress = 1;
        let application = JSON.parse(JSON.stringify(this.selectedApplications[0]));
        let appIdNew = application.id;
        let step = (100 - progress)/(
            this.selectedApplications.length
        );
        for (let index = 1; index < this.selectedApplications.length; index++) {
            console.log(this.selectedApplications[index])
            // get services
            let services = await this.$store.dispatch('getServicesAppId', this.selectedApplications[index].id);
            console.log(services)
            for (let index2 = 0; index2 < services.length; index2++) {
                services[index2].application_id = appIdNew;
                (services[index2].start) ? services[index2].start = this.formattedDate(services[index2].start, 'date') : services[index2].start = null;
                (services[index2].end) ? services[index2].end = this.formattedDate(services[index2].end, 'date') : services[index2].end = null;
                await this.$store.dispatch('serviceUpdate', services[index2]);
            }
            // delete app
            this.$store.dispatch('deleteApplication', this.selectedApplications[index].id);
            progress = progress + step;
            this.$store.dispatch('progressAdvance', progress);
        }
        let servicesInApp = await this.$store.dispatch('getServicesAppId', appIdNew);
        application = await this.$store.dispatch('getApplicationId', appIdNew);
        application.appTitle = await this.createAppTitle(application, servicesInApp);
        if (application.fixedPrice === null) {
            application.fixedPrice = 0
        }
        if (application.discount === null) {
            application.discount = 0
        }
        if (application.discountPercentage === null) {
            application.discountPercentage = 0
        }
        if (application.refund === null) {
            application.refund = 0
        }
        if (application.extraChargeUSD === null) {
            application.extraChargeUSD = 0
        }
        (application.applied) ? application.applied = this.formattedDate(application.applied, 'date') : application.applied = null;
        (application.invoice_date) ? application.invoice_date = this.formattedDate(application.invoice_date, 'date') : application.invoice_date = null;
        await this.$store.dispatch('insertApplication', application);
        this.$store.dispatch('showProgressBar', false);
        this.$store.dispatch('progressAdvance', 0);
        this.$store.dispatch('successMessage', 'Applications merged into ' + application.appTitle);
        this.goApplication(appIdNew);
    },
    async removeApplication(id) {
        console.log(id)
        await this.$store.dispatch('deleteApplication', id);
        this.$store.dispatch('successMessage', 'Agency application removed');
        this.getApplications();
    },
  }
}
</script>

<style>

/* thead .v-simple-checkbox {
    display: none;
} */

</style>