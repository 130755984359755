<template>
    <div class="d-flex justify-space-around" style="max-width: 100%">
        <v-card flat class="d-flex flex-column pa-5 mt-3 rounded-xl" v-if="application.id !== null" color="brown lighten-5" style="width: 100%">
            <v-card flat class="d-flex flex-row flex-wrap" color="brown lighten-5">
                <div class="d-flex flex-column justify-center" style="width: 100%">
                    <v-text-field
                        full-width
                        class="text-input-green center-text"
                        outlined
                        :value="application.appTitle"
                        counter
                        color="ppc_darkgreen"
                        :rules="inputRules100maxReq"
                        @change="changeAppTitle($event)"
                        :disabled="application.frozen===1"
                    ></v-text-field>
                </div>
                <v-card flat class="d-flex flex-column" color="brown lighten-5">
                    <div class="caption black--text">
                        Applied: {{ formattedDate(application.applied) }}
                    </div>
                    <div class="caption black--text">
                        Nr: {{ application.id }}
                    </div>
                </v-card>
                <v-spacer></v-spacer>
                <v-switch
                    v-model="application.autoAppTitle"
                    label="auto title"
                    class="mr-5"
                    @change="updateAutoAppTitle($event)"
                    :disabled="application.frozen===1"
                ></v-switch>
                <v-switch
                    v-model="application.frozen"
                    label="Freeze Application"
                    class="mr-5"
                    @change="updateFreezeApplication($event)"
                    :disabled="($store.state.unfreezeApplication===0 && application.frozen===1)"
                ></v-switch>
                <v-spacer></v-spacer>
                <div style="width: 320px" class="d-flex flex-column">
                    <div v-if="application.statusAppId !== null" class="align-self-end">
                        <v-chip :color="getStatusApp(application.statusAppId).color" class="mt-2" text-color="white" v-html="getStatusApp(application.statusAppId).name"></v-chip>
                    </div>
                </div>
            </v-card>
            <v-card flat class="d-flex flex-row flex-wrap justify-center">
                <v-card flat color="brown lighten-4" class="d-flex flex-column pa-5 my-5 mr-2 rounded-xl">
                    <v-card-title class="pa-0 py-2">Clients<span> ({{ application.amountOfClients }})</span></v-card-title>
                    <div class="d-flex flex-column">
                        <span flat v-for="(appClient, index) in this.appClients" :key="index">
                            <v-btn x-small rounded @click="goClient(appClient.id, 'applications')">{{ appClient.full_name }}</v-btn>
                            <span class="text-caption" v-if="appClient.autoStatus === 4"> (check resources)</span>
                        </span>
                    </div>
                    <v-card-title class="mt-8 pa-0">Payer</v-card-title>
                    <v-card flat class="d-flex flex-column" color="brown lighten-4"  :disabled="application.frozen===1">
                        <v-select
                            style="width: 250px"
                            v-model="application.payerClient"
                            :items="appClients"
                            item-text="full_name"
                            item-value="id"
                            :menu-props="{ auto: true }"
                            label="select"
                            single-line
                            prepend-icon="$vuetify.icons.icon_mdi_credit_card_outline ppc_darkgreen--text"
                            validate-on-blur
                            clearable
                            @change="updatePayer('Client', $event)"
                            class="mr-4"
                            persistent-hint
                            hint="client"
                            :disabled="application.amountPaidTransaction > 0"
                        ></v-select>
                        or
                        <v-select
                            style="width: 250px"
                            v-model="application.payerAgency"
                            :items="selectAvailableAgencies()"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ auto: true }"
                            label="select"
                            single-line
                            prepend-icon="$vuetify.icons.icon_mdi_credit_card_outline ppc_darkgreen--text"
                            validate-on-blur
                            clearable
                            @change="updatePayer('Agency', $event)"
                            class="mr-4"
                            persistent-hint
                            hint="agency"
                            :disabled="application.amountPaidTransaction > 0"
                        ></v-select>
                    </v-card>
                </v-card>
                <v-card flat color="brown lighten-4" class="d-flex flex-column pa-5 my-5 mr-2 rounded-xl" style="width: 500px" :disabled="application.frozen===1">
                    <v-card-title class="pa-0 py-2">Price</v-card-title>
                    <div v-if="!application.fixedPrice" class="mb-2">
                        Calculated: ${{ calcSumServicesPrice() }} ({{ (calcSumServicesPrice()*application.exchangeRate).toFixed(2) }} PEN)
                    </div>
                    <div v-else class="text-decoration-line-through mb-2">
                        Calculated: ${{ calcSumServicesPrice() }} ({{ (calcSumServicesPrice()*application.exchangeRate).toFixed(2) }} PEN)
                    </div>
                    <v-card flat class="d-flex flex-row flex-wrap ma-2 pa-4 rounded-xl" color="brown lighten-5">
                        <v-text-field
                            class="pa-0 mr-2"
                            v-model="application.fixedPrice"
                            label="fixed price USD"
                            background-color="brown lighten-5"
                            clearable
                            @click:clear="application.fixedPrice = null, application.fixedSoles = null"
                            @change="setTotalPrice('fixedPrice' ,application.fixedPrice ,application.discount ,application.discountPercentage ,application.refund, application.extraCharge, application.extraChargeUSD )"
                        ></v-text-field>
                        <v-text-field
                            class="pa-0 mr-2"
                            v-model="application.fixedSoles"
                            label="fixed price PEN"
                            background-color="brown lighten-5"
                            @change="setTotalPrice('fixedPrice' ,application.fixedSoles/application.exchangeRate ,application.discount ,application.discountPercentage ,application.refund, application.extraCharge, application.extraChargeUSD )"
                        ></v-text-field>
                    </v-card>
                    <v-card flat class="d-flex flex-column ma-2 pa-4 rounded-xl" color="brown lighten-5">
                        <div class="d-flex flex-row flex-wrap">
                            <v-text-field
                                class="pa-0 mr-2"
                                v-model="application.discount"
                                label="discount USD"
                                background-color="brown lighten-5"
                                clearable
                                @click:clear="application.discountSoles = null, application.discountPercentage = null"
                                @change="setTotalPrice('discount' ,application.fixedPrice ,application.discount ,application.discountPercentage ,application.refund, application.extraCharge, application.extraChargeUSD )"
                            ></v-text-field>
                            <v-text-field
                                class="pa-0 mr-2"
                                v-model="application.discountSoles"
                                label="discount PEN"
                                background-color="brown lighten-5"
                                @change="setTotalPrice('discount', application.fixedPrice, (application.discountSoles/application.exchangeRate), application.discountPercentage, application.refund, application.extraCharge, application.extraChargeUSD)"
                            ></v-text-field>
                        </div>
                        <div class="d-flex flex-row flex-wrap">
                            <div style="width: 70px">
                                <v-text-field
                                    class="pa-0 mr-2"
                                    v-model="application.discountPercentage"
                                    label="%"
                                    background-color="brown lighten-5"
                                    clearable
                                    @click:clear="application.discountPercentage = null"
                                    @change="setTotalPrice('discountPercentage' ,application.fixedPrice ,application.discount ,application.discountPercentage ,application.refund, application.extraCharge, application.extraChargeUSD)"
                                ></v-text-field>
                            </div>
                            <div class="caption mt-4">if being used, discount will be recalculated</div>
                        </div>
                    </v-card>
                    <v-card flat class="d-flex flex-row flex-wrap ma-2 pa-4 rounded-xl" color="brown lighten-5">
                        <v-text-field
                            class="pa-0 mr-2"
                            v-model="application.refund"
                            label="refund USD"
                            background-color="brown lighten-5"
                            clearable
                            @click:clear="application.refundSoles = null"
                            @change="setTotalPrice('refund' ,application.fixedPrice ,application.discount ,application.discountPercentage ,application.refund, application.extraCharge, application.extraChargeUSD )"
                        ></v-text-field>
                        <v-text-field
                            class="pa-0 mr-2"
                            v-model="application.refundSoles"
                            label="refund PEN"
                            background-color="brown lighten-5"
                            @change="setTotalPrice('refund' ,application.fixedPrice ,application.discount ,application.discountPercentage ,(application.refundSoles/application.exchangeRate), application.extraCharge, application.extraChargeUSD )"
                        ></v-text-field>
                    </v-card>
                    <!-- <div>Extra Charge</div> -->
                    <v-card flat class="d-flex flex-column ma-2 pa-4 rounded-xl" color="brown lighten-5">
                        <v-text-field
                            class="pa-0 mr-2"
                            v-model="application.extraCharge"
                            label="Extra Charge"
                            background-color="brown lighten-5"
                            clearable
                            @click:clear="application.extraChargeUSD = null, application.extraChargeSoles = null"
                            @change="setTotalPrice('extraCharge', application.fixedPrice, application.discount, application.discountPercentage, application.refund, application.extraCharge, application.extraChargeUSD )"
                        ></v-text-field>
                    <div flat class="d-flex flex-row flex-wrap">
                        <v-text-field
                            class="pa-0 mr-2"
                            v-model="application.extraChargeUSD"
                            label="USD"
                            background-color="brown lighten-5"
                            clearable
                            @click:clear="application.extraChargeSoles = null"
                            @change="setTotalPrice('extraCharge', application.fixedPrice, application.discount, application.discountPercentage, application.refund, application.extraCharge, application.extraChargeUSD )"
                        ></v-text-field>
                        <v-text-field
                            class="pa-0 mr-2"
                            v-model="application.extraChargeSoles"
                            label="PEN"
                            background-color="brown lighten-5"
                            @change="setTotalPrice('extraCharge', application.fixedPrice, application.discount, application.discountPercentage, (application.refundSoles/application.exchangeRate), application.extraCharge, (application.extraChargeSoles/application.exchangeRate) )"
                        ></v-text-field>
                    </div>
                    </v-card>
                    <div class="d-flex flex-column ml-8">
                        <div class="d-flex flex-row flex-wrap">
                            <div v-if="application.totalPrice && application.totalPriceSoles">
                                <h2 class="ppc_pink--text my-2 mr-4">${{ parseFloat(application.totalPrice).toFixed(2) }} </h2><h3 class="mt-3 mr-4" > ({{ parseFloat(application.totalPriceSoles).toFixed(2) }} PEN) </h3>
                            </div>
                            <v-text-field
                                class="pa-0 mt-2 mr-2"
                                label="exchange rate"
                                style="width: 50px"
                                v-model="application.exchangeRate"
                                disabled
                                @change="setTotalPrice('exchangeRate' ,application.fixedPrice ,application.discount ,application.discountPercentage ,(application.refundSoles/application.exchangeRate), application.extraCharge, application.extraChargeUSD )"
                            ></v-text-field>
                        </div>
                        <div class="d-flex flex-column mt-0">
                            <span class="mr-2">Paid: ${{ distributionsTotal.toFixed(2) }} </span>
                            <hr>
                            <span class="mr-2">Open: ${{ (parseFloat(application.totalPrice) - distributionsTotal).toFixed(2) }} </span>
                        </div>
                        <div class="mt-4">
                            <span v-if="(application.depositTotal && application.depositTotal !== null && application.depositPaid === 0 || (application.depositPaid === 1 && application.ignore_deposit === 1)) || application.payerAgency !== null" class="d-flex flex-row">
                                <span class="mr-2" v-if="application && application.depositTotal">Deposit: {{ application.depositTotal.toFixed(2) }} USD</span>
                                <v-checkbox 
                                    v-model="application.ignore_deposit"
                                    label="pass to planning"
                                    @change="updateIgnoreDeposit($event)"
                                    class="mt-0 pt-0"
                                ></v-checkbox>
                            </span>
                            <p v-if="application.depositPaid === 1 && application.payerAgency === null && application.ignore_deposit === 0">
                                Passed to planning (deposit paid)
                            </p>
                            <p v-if="application.payerAgency !== null && application.ignore_deposit === 1">
                                Passed to planning (agency)
                            </p>
                            <p v-if="application.statusAppId === 4">
                                Planning blocked: check resources!
                            </p>
                            <v-checkbox
                                v-model="application.transferFeeIncluded"
                                label="Transfer Fee Included"
                                class="mt-0 pt-0"
                                disabled
                            ></v-checkbox>
                        </div>
                    </div>
                </v-card>
                <v-card flat color="brown lighten-4 rounded-xl outlined" class="d-flex flex-column pa-5 my-5 mr-2">
                    <v-card-title class="pa-0 py-2">Invoice</v-card-title>
                    <v-card-subtitle class="pa-0 py-2 mt-2">Date</v-card-subtitle>
                    <v-btn
                        @click="changeInvoiceDate(today)"
                    >
                        today
                    </v-btn>
                    <v-date-picker 
                        v-model='application.invoice_date'
                        color='ppc_darkgreen'
                        scrollable
                        @change="changeInvoiceDate($event)">
                    </v-date-picker>
                    <v-card-subtitle class="pa-0 py-2 mt-2">Comments</v-card-subtitle>
                    <v-textarea
                        style="min-width: 300px"
                        class="rounded-xl py-5"
                        v-model="application.comments"
                        auto-grow
                        clearable
                        filled
                        rounded
                        shaped
                        counter
                        rows="2"
                        background-color="brown lighten-5"
                        label="(255 max)"
                        :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                        @change="changeComments($event)"
                    ></v-textarea>
                    <v-btn color="brown lighten-1" @click="showInvoice(application.id)" class="ma-4" :disabled="$store.state.loadingApp === 1">
                        <v-icon color="white">$vuetify.icons.icon_mdi_cogs</v-icon>
                        <span class="white--text">Create Invoice</span>
                    </v-btn>
                    <v-btn color="brown lighten-1" @click="goView('Invoices', application.id)" class="ma-4" :disabled="$store.state.loadingApp === 1">
                        <v-icon color="white">$vuetify.icons.icon_mdi_archive_outline</v-icon>
                        <span class="white--text">Show Saved Invoices</span>
                    </v-btn>
                </v-card>
                <div class="d-flex flex-column">
                    <v-data-table
                        :headers="headersApplicationIncome"
                        :items="applicationIncome"
                        class="my-4 pa-4 rounded-xl"
                        disable-pagination
                        hide-default-footer
                        dense
                        sort-by="paidOn"
                    >
                        <template v-slot:top>
                            <v-toolbar
                                flat
                            >
                                <v-toolbar-title>Payments: ${{ distributionsTotal.toFixed(2) }}</v-toolbar-title>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.amountIncome`]="{ item }">
                            <span v-if="item.currency">{{ item.currency }} {{ item.amountIncome.toFixed(2) }}</span>
                            <span v-else>$ {{ item.amountIncome.toFixed(2) }}</span>
                        </template>
                        <template v-slot:[`item.amount`]="{ item }">
                            <span v-if="item.currency">{{ (item.currency) }} {{ (item.amount).toFixed(2) }}</span>
                            <span v-else>$ {{ (item.amount).toFixed(2) }}</span>
                        </template>
                        <template v-slot:[`item.paidOn`]="{ item }">
                            <span v-html="formattedDate(item.paidOn)"></span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                small
                                @click="goFinancial(item)"
                            >
                                $vuetify.icons.icon_mdi_magnify
                            </v-icon>
                        </template>
                    </v-data-table>
                    <div class="d-flex flex-column" v-if="application.totalPrice">
                        <v-btn :disabled="$store.state.loadingApp === 1" color="ppc_darkgreen lighten-1" @click="addPayment(application.id, 'dollars')" class="ma-4" v-if="(this.$store.state.accessFinancial || this.$store.state.accessCajaGrande) && !(parseFloat(application.totalPrice).toFixed(2) - application.amountPaidApplication === 0)">
                            <v-icon color="white">$vuetify.icons.icon_mdi_credit_card_outline</v-icon>
                            <span class="white--text">Add Payment</span>
                        </v-btn>
                        <v-btn :disabled="$store.state.loadingApp === 1" color="ppc_darkgreen lighten-1" @click="addRefund(application.id, '$')" class="ma-4" v-if="(this.$store.state.accessFinancial || this.$store.state.accessCajaGrande)">
                            <v-icon color="white">$vuetify.icons.icon_mdi_credit_card_outline</v-icon>
                            <span class="white--text">Add Refund</span>
                        </v-btn>
                        <v-btn :disabled="$store.state.loadingApp === 1" color="ppc_darkgreen lighten-1" @click="openTransactionIncome(application.transaction_id)" class="ma-4" v-if="application.transaction_id">
                            <v-icon color="white">$vuetify.icons.icon_mdi_credit_card</v-icon>
                            <span class="white--text">Open Transaction</span>
                        </v-btn>
                    </div>
                </div>
            </v-card>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                    text
                    class="ppc_darkgreen--text"
                    @click="goBack"
                >
                    Back
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialogIncomeDelete" max-width="500px">
            <v-card>
                <v-card-title>Are you sure you want to delete the payment?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="ppc_darkgreen darken-1" text @click="dialogIncomeDelete = !dialogIncomeDelete">Cancel</v-btn>
                    <v-btn color="ppc_darkgreen darken-1" text @click="deleteIncomeConfirm" :loading="loadingButton">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import inputRules from '../mixins/inputRules';
import createAppTitle from '../mixins/createAppTitle';
import getStatusApp from '../mixins/getStatusApp';
import getDateFromTimestamp from '../mixins/getDateFromTimestamp';
import getServicesApplied from '../mixins/getServicesApplied';
import formattedDate from '../mixins/formattedDate';
import radioGroups_filtered from '../mixins/radioGroups_filtered';
import options_filtered_selected from '../mixins/options_filtered_selected';

export default {
    data () {
        return {
            dialog_application: false,
            application_id: null,
            servicesInApp: [],
            application: [],
            groupByButton: true,
            groupBy: null,
            optionsTable: {},
            headersServices: [
                {value: 'full_name', text: 'Client', width:'20%', align: 'left'},
                {value: 'name', text: 'Service', width:'30%'},
                {value: 'type', text: 'Type', width:'20%'},
                {value: 'start', text: 'Start', width:'10%', groupable: false},
                {value: 'end', text: 'End', width:'10%', groupable: false},
                {value: 'duration', text: 'duration', groupable: false},
                // {value: 'autoStatus', text: 'autoStatus', groupable: false},
                {value: 'servicePrice', text: 'Price', width:'10%', groupable: false},
            ],
            appClients: [],
            income: [],
            applicationIncome: [],
            headersApplicationIncome: [
                { text: 'Payment On', value: 'paidOn' },
                { text: 'Method', value: 'paymentMethodName' },
                { text: 'Payment', value: 'amountIncome' },
                { text: 'Distributed', value: 'amount' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            appWithOnlyEnglish: true,
            dialogIncomeDelete: false,
            itemToBeDeleted: null,
            loadingButton: false,
            today: null,
        }
    },

    computed: {
        distributionsTotal () {
            var distributionsTotal = 0;
            if (this.applicationIncome.length > 0) {
                for (let index = 0; index < this.applicationIncome.length; index++) {
                    if (this.applicationIncome[index].currency === 'S/.') {
                        distributionsTotal = distributionsTotal + this.applicationIncome[index].amountDollars;
                    } else {
                        distributionsTotal = distributionsTotal + this.applicationIncome[index].amount;
                    }
                }
            }
            return distributionsTotal;
        },
    },

    beforeCreate () {
        this.$store.dispatch('setLoadingApp', 1)
    },
    async created() {
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        let previousView =  this.$store.state.breadcrumbs[this.$store.state.breadcrumbs.length - 1];
        if (previousView === 'Invoice') {
            this.tab = 1;
            breadcrumb.pop();
        } else if (previousView !== 'Application') {
            breadcrumb.push('Application')
        }
        this.$store.dispatch('breadcrumbs', breadcrumb);
        await this.initialize();
        this.$store.dispatch('setLoadingApp', 0)
    },
    mounted() {
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[0] === 'Application') {
            this.$router.push({ name: 'Leads' })
        }
    },
    mixins: [inputRules, getDateFromTimestamp, getServicesApplied, formattedDate, radioGroups_filtered, options_filtered_selected, createAppTitle, getStatusApp],
    methods: {
        async initialize () {
            let n = new Date();
            this.today = this.formattedDate(n, 'date');
            this.application_id = JSON.parse(JSON.stringify(this.$store.state.dataForReport));
            this.$store.dispatch('saveDataForReport', );
            // get payments
            this.applicationIncome = await this.$store.dispatch('getDistributionsApplicationId', this.application_id);
            let outgoingsApp = await this.$store.dispatch('getOutgoingsApp', this.application_id);
            for (let index = 0; index < outgoingsApp.length; index++) {
                outgoingsApp[index].amount = -1 * outgoingsApp[index].amount;
                outgoingsApp[index].amountDollars = -1 * outgoingsApp[index].amountDollars;
                outgoingsApp[index].amountIncome = outgoingsApp[index].amount;
                // outgoingsApp[index].currency = outgoingsApp[index].currency;
                outgoingsApp[index].paidOn = this.formattedDate(outgoingsApp[index].dateTime, 'date');
                this.applicationIncome.push(outgoingsApp[index]);
            }
            // get application + services
            await this.getApplication();
            // if (this.applicationIncome.length > 0) {
            //     this.application.frozen = 1;
            // }
            if (this.application.amountOfClients === 1) {
                this.groupBy = "type";
            } else {
                this.groupBy = "full_name";
            }
            this.changeGroupBy();
            this.servicesInApp = await this.$store.dispatch('getServicesAppId', this.application_id);
            var client = {};
            for (let index = 0; index < this.servicesInApp.length; index++) {
                client = {
                    id: this.servicesInApp[index].client_id,
                    full_name: this.servicesInApp[index].full_name,
                    autoStatus: this.servicesInApp[index].autoStatus,
                };
                let objIndex = this.appClients.findIndex(item => item.id === client.id);
                if (objIndex === -1) {
                    this.appClients.push(client);
                } else {
                    if (client.autoStatus === 4) {
                        this.appClients[objIndex].autoStatus = 4;
                    }
                }
                console.log('initialize', this.servicesInApp[index].type_id)
                if (this.servicesInApp[index].type_id !== 8) {
                    this.appWithOnlyEnglish = false;
                }
            }
            this.appClients.sort((a, b) => (a.full_name > b.full_name) ? 1 : -1);
            if (this.servicesInApp.length === 0) {
                this.appWithOnlyEnglish = false;
            }
        },
        async getApplication() {
            this.application = await this.$store.dispatch('getApplicationId', this.application_id);
            if (this.application && this.application.fixedPrice) {
                this.application.fixedPrice = this.application.fixedPrice.toFixed(2);
            }
            if (this.application.discount) {
                this.application.discount = this.application.discount.toFixed(2);
            }
            if (this.application.refund) {
                this.application.refund = this.application.refund.toFixed(2);
            }
            if (this.application && this.application.extraChargeUSD) {
                this.application.extraChargeUSD = this.application.extraChargeUSD.toFixed(2);
            }
            if (this.application.amountPaidApplication) {
                this.application.amountPaidApplication = parseFloat(this.application.amountPaidApplication).toFixed(2);
            } else {
                this.application.amountPaidApplication = 0;
            }
            (this.application.applied) ? this.application.applied = this.formattedDate(this.application.applied, 'date') : this.application.applied = null;
            if (this.application.invoice_date) {
                this.application.invoice_date = this.formattedDate(this.application.invoice_date, 'date')
            } else { 
                this.application.invoice_date = this.today;
                await this.updateApplication();
            }
        },
        changeGroupBy() {
            if (this.groupBy === "full_name") {
                this.optionsTable = {"groupBy": ["full_name"]};
            } else if (this.groupBy === "name") {
                this.optionsTable = {"groupBy": ["name"]};
            } else if (this.groupBy === "type") {
                this.optionsTable = {"groupBy": ["type"]};
            }
        },
        async updateAutoAppTitle (switchAutoAppTitle) {
            this.application.autoAppTitle = switchAutoAppTitle;
            if (this.application.autoAppTitle) {
                let servicesInApp = await this.$store.dispatch('getServicesAppId', this.application.id);
                this.application.appTitle = await this.createAppTitle(this.application, servicesInApp)
            }
            this.updateApplication();
        },
        changeAppTitle (title) {
            this.application.autoAppTitle = false;
            this.application.appTitle = title;
            this.updateApplication();
        },
        updateIgnoreDeposit (event) {
            this.application.ignore_deposit = event;
            this.updateApplication();
        },
        updateFreezeApplication (event) {
            console.log('*****', event)
            this.application.frozen = event;
            this.updateApplication();
        },
        // updateTransferFeeIncluded (event) {
        //     this.application.transferFeeIncluded = event;
        //     this.updateApplication();
        // },
        updatePayer (payer, event) {
            if (payer ==='Client') {
                this.application.payerClient = event;
                this.application.payerAgency = null;
                this.application.transferFeeIncluded = false;
            } else {
                this.application.payerClient = null;
                this.application.payerAgency = event;
                if (event) {
                    let tmp = this.$store.state.available_agencies.filter(function(select_item) {
                        return select_item.id === event;
                    });
                    this.application.transferFeeIncluded = tmp[0].transferFeeIncluded;
                } else {
                    this.application.transferFeeIncluded = false;
                }
            }
            if (!this.application.payerClient && !this.application.payerAgency) {
                this.$store.dispatch('warningMessage', 'No payer assigned!')
            }
            this.updateApplication();
        },
        changeComments (event) {
            this.application.comments = event;
            this.updateApplication();
        },
        changeInvoiceDate (event) {
            console.log(event)
            this.application.invoice_date = event;
            this.updateApplication();
        },
        goClient(id, tab) {
            console.log(id, tab)
            this.$store.dispatch('setLoadingApp', 1);
            this.$router.push({ name: 'Client', params: { id: id, tab: tab } })
        },
        calcSumServicesPrice() {
            let i = 0;
            let sumServicesPrice = 0;
            if (this.servicesInApp.length > 0) {
                do {
                if (this.servicesInApp[i].servicePrice) {
                    if (this.servicesInApp[i].currency ==='$') {
                        sumServicesPrice += +this.servicesInApp[i].servicePrice;
                    } else {
                        sumServicesPrice += +(this.servicesInApp[i].servicePrice / this.$store.state.exchangeRate).toFixed(2);
                    }
                }
                i++;
                }
                while (this.servicesInApp[i]);
            }
            return sumServicesPrice.toFixed(2);
        },
        async setTotalPrice (action, fixedPrice, discount, discountPercentage, refund, extraCharge, extraChargeUSD) {
            console.log('set price', action, fixedPrice, discount, discountPercentage, refund, extraCharge, extraChargeUSD)
            switch (action) {
                case 'fixedPrice':
                    this.application.fixedPrice = fixedPrice;
                break;
                case 'discount':
                    this.application.discount = discount;
                    this.application.discountPercentage = null;
                break;
                case 'discountPercentage':
                    this.application.discountPercentage = discountPercentage;
                break;
                case 'refund':
                    this.application.refund = refund;
                break;
                case 'extraCharge':
                    this.application.extraCharge = extraCharge;
                    this.application.extraChargeUSD = extraChargeUSD;
            }
            let totalPrice;
            if (fixedPrice === 0) {
                totalPrice = this.calcSumServicesPrice();
            } else {
                totalPrice = fixedPrice;
            }
            if (discountPercentage !== 0) {
                discount = totalPrice*discountPercentage/100;
                this.application.discount = discount;
            }
            console.log(totalPrice, discount, refund, extraChargeUSD, '^^^^^^^')
            // this.application.totalPrice = totalPrice - discount - refund + extraChargeUSD;
            // console.log(this.application.totalPrice, action)
            await this.updateApplication()
            return;
        },
        async updateApplication () {
            if (this.application.fixedPrice === null) {
                this.application.fixedPrice = 0
            }
            if (this.application.discount === null) {
                this.application.discount = 0
            }
            if (this.application.discountPercentage === null) {
                this.application.discountPercentage = 0
            }
            if (this.application.refund === null) {
                this.application.refund = 0
            }
            if (this.application.extraChargeUSD === null) {
                this.application.extraChargeUSD = 0
            }
            await this.$store.dispatch('insertApplication', this.application)
            this.getApplication();
        },
        async goView(view, id) {
            if (view === 'Invoice') {
                await this.$store.dispatch('saveDataForReport', id);
                this.$router.push({ name: 'Invoice' });
            }
            if (view === 'Invoices') {
                await this.$store.dispatch('saveDataForReport', id);
                this.$router.push({ name: 'Invoices' });
            }
        },
        goBack () {
            if (this.$store.state.breadcrumbs.length > 1) {
                this.$router.push({ name: this.$store.state.breadcrumbs[this.$store.state.breadcrumbs.length - 2], params: { id: this.$store.state.client.id } })
            } else {
                this.$router.push({ name: 'Leads' })
            }
        },
        selectAvailableAgencies () {
            return this.$store.state.available_agencies.filter(function(select_item) {
                return select_item.obsolete === null;
            })
        },
        addPayment(appId, currency) {
            let incomeType = 2;
            if (this.appWithOnlyEnglish) {
                if (this.application.payerClient !== null) {
                    incomeType = 9;
                } else {
                    incomeType = 19;
                }
            } else {
                if (this.application.payerClient !== null) {
                    incomeType = 2;
                } else {
                    incomeType = 16;
                }
            }
            this.$store.dispatch('setLoadingApp', 1)
            this.$router.push({ name: 'Financial', params: { appId: appId, currency: currency, incomeType: incomeType, clientId: null } })
        },
        addRefund(appId, currency) {
            this.$store.dispatch('setLoadingApp', 1)
            this.$router.push({ name: 'Financial', params: { refund: true, appId: appId, currency: currency, outgoingType: 78 } })  
        },
        async deleteIncomeConfirm () {
            this.loadingButton = true;
            console.log(this.itemToBeDeleted);
            await this.$store.dispatch('deleteIncome', this.itemToBeDeleted);
            this.$store.dispatch('successMessage', 'Payment removed');
            this.initialize();
            this.loadingButton = false;
            this.dialogIncomeDelete = false;
        },
        // openTransactionIncome(transIdIncome) {
        //     this.$store.dispatch('setLoadingApp', 1)
        //     this.$router.push({ name: 'Financial', params: { transIdIncome: transIdIncome } })
        // },
        async showInvoice(appId) {
            let payload = {
                id: appId,
                archive: false,
            }
            console.log('showInvoice', payload);
            await this.$store.dispatch('saveDataForReport', payload);
            this.$store.dispatch('showInvoice', true);
        },
        async goFinancial(item) {
            console.log(item);
            if (item.income_id) {
                console.log('transaction');
                this.$router.push({ name: 'Financial', params: { income_id: item.income_id, paidOn: item.paidOn } })
            } else {
                console.log('outgoing');
                this.$router.push({ name: 'Financial', params: { outgoing_id: item.id, paidOn: item.paidOn } })
            }
        }
    }
}
</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { 
        padding-top: 0; 
        padding-bottom: 0;
    }
    .pos_absolute {
        position: absolute;
    }
    .center-text input {
      text-align: center;
    }
</style>