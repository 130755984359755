export default {
    methods: {
        options_filtered(serviceId) {
            return this.$store.state.options.filter(function(select_item) {
              return select_item.services_id === serviceId;
              }
            )
        },
    }
    
}