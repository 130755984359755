<template>
    <v-container style="max-width: 100%">
        <v-tabs
            v-model="tabGeneral"
            background-color="transparent"
            color="basil"
            grow
            @change="renderTabCategoriesTypes = Math.random(), changeGeneralTab($event)"
        >
            <v-tab
                v-for="itemTabGeneral in itemsTabGeneral"
                :key="itemTabGeneral"
            >
                <span class="ppc_darkgreen--text">
                    {{ itemTabGeneral }}
                </span>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabGeneral">
            <v-tab-item>
                <v-card flat class="d-flex flex-row flex-wrap justify-center">
                    <div>
                        <v-btn
                            @click="changeDate(nowDate)"
                            class="mx-5 mt-4"
                            :disabled = "$store.getters.loadingApp === 1"
                        >
                            today
                        </v-btn>
                        <v-btn
                            @click="changeDate(addDays(cajaChicaDay, -1))"
                            class="mx-5 mt-4"
                            :disabled = "$store.getters.loadingApp === 1"
                        >
                            prev day
                        </v-btn>
                    </div>
                    <v-card flat>
                        <v-menu
                            transition="scale-transition"
                            offset-y
                            :close-on-content-click="false"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="computedDateFormatted"
                                    v-bind="attrs"
                                    prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                    v-on="on"
                                    readonly
                                    :disabled = "$store.getters.loadingApp === 1"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="cajaChicaDay"
                                color="ppc_darkgreen"
                                no-title
                                scrollable
                                @change="changeDate($event)"
                            ></v-date-picker>
                        </v-menu>
                    </v-card>
                    <div>
                        <v-btn
                            @click="changeDate(addDays(cajaChicaDay, 1))"
                            class="mx-5 mt-4"
                            :disabled = "$store.getters.loadingApp === 1"
                        >
                            next day
                        </v-btn>
                        <v-btn
                            @click="squareCaja"
                            class="mx-5 mt-4 white--text"
                            color="ppc_darkgreen"
                            :loading="loadingButton"
                            :disabled = "$store.getters.loadingApp === 1 || loadingCajas"
                        >
                            square
                        </v-btn>
                    </div>
                </v-card>
                <v-card flat class="d-sm-flex flex-row justify-center">
                    <v-card flat class="d-flex flex-column">
                        <v-data-table
                            :headers="headersIncome"
                            :items="incomeDay"
                            class="elevation-1 ma-2"
                            disable-pagination
                            hide-default-footer
                            sort-by="dateTime"
                            dense
                            style="min-width: 40vw;"
                            :item-class="itemRowBackgroundIncome"
                        >
                            <template v-slot:[`item.payerName`]="{ item }">
                                <span v-if="item.client_id !== null"><v-btn x-small rounded @click="goClient(item.client_id)">{{ item.payerName }}</v-btn></span>
                                <span v-else>{{ item.payerName }}</span>
                            </template>
                            <template v-slot:top>
                                <v-toolbar
                                    flat
                                >
                                    <v-btn
                                        color="ppc_darkgreen"
                                        dark
                                        x-small
                                        class="mr-4"
                                        fab
                                        @click="newEditIncome(null, null, null, null)"
                                    >
                                        <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Income Caja</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <h3 class="mr-4" v-if="dayIncomeDollars">${{ dayIncomeDollars.toFixed(2) }}</h3>
                                    <h3 v-if="dayIncomeSoles">S/.{{ dayIncomeSoles.toFixed(2) }}</h3>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.payment`]="{ item }">
                                <span v-if="item.payment">{{ item.currency }} {{ item.amount.toFixed(2) }}</span>
                            </template>
                            <template v-slot:[`item.allDistributed`]="{ item }">
                                <v-icon small v-if="item.allDistributed === 'ok'" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                                <span v-else v-html="item.allDistributed"></span>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <div v-if="!item.idSquare" class='d-flex flex-row'>
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editIncome(item)"
                                        :disabled="$store.getters.loadingApp === 1"
                                    >
                                        $vuetify.icons.icon_mdi_pencil
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="deleteIncome(item)"
                                        :disabled="item.frozen || $store.getters.loadingApp === 1"
                                    >
                                        $vuetify.icons.icon_mdi_delete
                                    </v-icon>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                    <v-card flat class="d-flex flex-column">
                        <v-data-table
                            :headers="headersOutgoings"
                            :items="outgoingsDay"
                            class="elevation-1 ma-2"
                            disable-pagination
                            hide-default-footer
                            sort-by="dateTime"
                            dense
                            style="min-width: 40vw;"
                            :item-class="itemRowBackgroundOutgoings"
                        >
                            <template v-slot:top>
                                <v-toolbar
                                    flat
                                >
                                    <v-btn
                                        color="blue"
                                        dark
                                        class="mr-4"
                                        x-small
                                        fab
                                        @click="newEditOutgoing(null)"
                                    >
                                        <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Outgoings Caja</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <h3 class="mr-4" v-if="dayOutgoingsDollars">${{ dayOutgoingsDollars.toFixed(2) }}</h3>
                                    <h3 v-if="dayOutgoingsSoles">S/.{{ dayOutgoingsSoles.toFixed(2) }}</h3>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.payment`]="{ item }">
                                <span v-if="item.payment">{{ item.currency }} {{ item.amount.toFixed(2) }}</span>
                            </template>
                            <template v-slot:[`item.receiverName`]="{ item }">
                                <span v-if="item.payerClient !== null"><v-btn x-small rounded @click="goClient(item.payerClient)">{{ item.receiverName }}</v-btn></span>
                                <span v-else>{{ item.receiverName }}</span>
                            </template>
                            <template v-slot:[`item.allDistributed`]="{ item }">
                                <v-icon small v-if="item.allDistributed === 'ok'" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                                <span v-else v-html="item.allDistributed"></span>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <div v-if="!item.idSquare" class='d-flex flex-row'>
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editOutgoing(item)"
                                        :disabled="$store.getters.loadingApp === 1"
                                    >
                                        $vuetify.icons.icon_mdi_pencil
                                    </v-icon>
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="deleteOutgoing(item)"
                                        :disabled="item.frozen || $store.getters.loadingApp === 1"
                                    >
                                        $vuetify.icons.icon_mdi_delete
                                    </v-icon>
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="goPaymentTeacherForm(item)"
                                        v-if="!item.idSquare && (item.type_id === 12 || item.type_id === 13 || item.type_id === 32)"
                                    >
                                        mdi-file-chart
                                    </v-icon>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-card>
                <v-row justify="center" class="d-flex flex-row flex-wrap"><h6>Total end of the Day</h6></v-row>
                <v-divider></v-divider>
                <div class="d-flex flex-row flex-wrap mt-8">
                    <v-card-title>
                        End of Day
                    </v-card-title>
                    <v-card flat>
                        <v-card-subtitle>
                            Caja Chica
                        </v-card-subtitle>
                        <v-card-text>
                            <div v-if="currentBalances[1]" class="mr-4">S/.{{ currentBalances[1].toFixed(2) }}</div>
                            <div v-if="currentBalances[0]">${{ currentBalances[0].toFixed(2) }}</div>
                        </v-card-text>
                    </v-card>
                    <v-card flat>
                        <v-card-subtitle>
                            Caja Fuerte
                        </v-card-subtitle>
                        <v-card-text>
                            <div v-if="currentBalances[3]">S/.{{ currentBalances[3].toFixed(2) }}</div>
                            <div v-if="currentBalances[2]">${{ currentBalances[2].toFixed(2) }}</div>
                        </v-card-text>
                    </v-card>
                    <v-card flat>
                        <v-card-subtitle>
                            Outstanding Deposits
                        </v-card-subtitle>
                        <v-btn
                            @click='dialogSecurityDeposits = true'
                            small
                            class="mt-2 ml-6"
                            :loading="loadingCajas"
                            width="75px"
                        >
                            <div v-if="outstandingDepositsTotal">${{ outstandingDepositsTotal.toFixed(2) }}</div>
                        </v-btn>
                    </v-card>
                </div>
            </v-tab-item>
            <v-tab-item>
                <v-tabs
                    v-model="tabTransactions"
                    background-color="transparent"
                    color="basil"
                    grow
                >
                    <v-tab>
                        <span class="ppc_darkgreen--text">
                            Income
                        </span>
                    </v-tab>
                    <v-tab>
                        <span class="blue--text">
                            Outgoings
                        </span>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabTransactions">
                    <v-tab-item>
                        <v-row justify="center" class="pa-4">
                            <v-btn
                                color="grey"
                                small
                                @click="getTransactionsIncome('open')"
                                class="ma-2"
                                :loading = "loadingbuttonLoadTransactionsOpen"
                                :disabled = "loadingbuttonLoadTransactionsClosed"
                            >
                                <span class="white--text">Load Open Transactions Income</span>
                            </v-btn>
                            <v-btn
                                color="grey"
                                small
                                @click="getTransactionsIncome('all')"
                                class="ma-2"
                                :loading = "loadingbuttonLoadTransactionsClosed"
                                :disabled = "loadingbuttonLoadTransactionsOpen"
                            >
                                <span class="white--text">Load All Transactions Income</span>
                            </v-btn>
                        </v-row>
                        <v-data-table
                            :headers="headersTransactionsIncome"
                            :items="transactionsIncome"
                            class="elevation-1 ma-2"
                            sort-by="dateTime"
                            dense
                            :items-per-page="-1"
                            v-if="transactionsIncome.length > 0"
                        >
                            <template v-slot:[`item.totalPriceTransaction`]="{ item }">
                                <span v-if="item.totalPriceTransaction">{{ parseFloat(item.totalPriceTransaction).toFixed(2) }}</span>
                            </template>
                            <template v-slot:[`item.amountPaidTransaction`]="{ item }">
                                <span v-if="item.amountPaidTransaction">{{ item.amountPaidTransaction.toFixed(2) }}</span>
                            </template>
                            <template v-slot:[`item.transactionClosed`]="{ item }">
                                <v-icon v-if="item.transactionClosed === 1" small color="ppc_darkgreen" class="mr-2">$vuetify.icons.icon_mdi_check</v-icon>
                            </template>
                            <template v-slot:[`item.id`]="{ item }">
                                <span v-html="formattedDate(item.id, 'dateAndTime')"></span>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    @click="openDialogTransactionIncome(item.id)"
                                    :disabled="item.amountPaidTransaction===0"
                                >
                                    $vuetify.icons.icon_mdi_pencil
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row justify="center" class="pa-4">
                            <v-btn
                                color="grey"
                                small
                                @click="loadTransactionsOutgoings('open')"
                                class="ma-2"
                                :loading = "loadingbuttonLoadTransactionsOpen"
                                :disabled = "loadingbuttonLoadTransactionsClosed"
                            >
                                <span class="white--text">Load Open Transactions Outgoings</span>
                            </v-btn>
                            <v-btn
                                color="grey"
                                small
                                @click="loadTransactionsOutgoings('all')"
                                class="ma-2"
                                :loading = "loadingbuttonLoadTransactionsClosed"
                                :disabled = "loadingbuttonLoadTransactionsOpen"
                            >
                                <span class="white--text">Load All Transactions Outgoings</span>
                            </v-btn>
                        </v-row>
                        <v-data-table
                            :headers="headersTransactionsOutgoings"
                            :items="transactionsOutgoings"
                            class="elevation-1 ma-2"
                            sort-by="dateTime"
                            dense
                            :items-per-page="-1"
                            v-if="transactionsOutgoings.length > 0"
                        >
                            <template v-slot:[`item.payerName`]="{ item }">
                                <v-btn
                                    v-if="item.payerName===''"
                                    @click="goApplication(item.application_id)"
                                >{{item.payerName}}</v-btn>
                                <span v-else>{{ item.payerName }}</span>
                            </template>
                            <template v-slot:[`item.totalPaymentTransactionSoles`]="{ item }">
                                <span v-if="item.totalPaymentTransactionDollars > 0">${{ item.totalPaymentTransactionDollars.toFixed(2) }}</span> <span v-if="item.totalPaymentTransactionSoles.toFixed(2) > 0">S/.{{ item.totalPaymentTransactionSoles.toFixed(2) }}</span>
                            </template>
                            <template v-slot:[`item.amountSoles`]="{ item }">
                                <span v-if="item.amountDollars">{{ item.amountDollars.toFixed(2) }}</span> <span v-if="item.amountSoles">({{ item.amountSoles.toFixed(2) }})</span>
                            </template>
                            <template v-slot:[`item.transactionClosed`]="{ item }">
                                <v-icon v-if="item.transactionClosed === 1"  small color="ppc_darkgreen" class="mr-2">$vuetify.icons.icon_mdi_check</v-icon>
                            </template>
                            <template v-slot:[`item.id`]="{ item }">
                                <span v-html="formattedDate(item.id, 'dateAndTime')"></span>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    @click="openDialogTransactionOutgoings(item.id)"
                                >
                                    $vuetify.icons.icon_mdi_pencil
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </v-tab-item>
            <v-tab-item>
                <v-card flat class="d-flex flex-row flex-wrap justify-space-around mb-0">
                    <v-card flat class="d-flex flex-row flex-wrap mb-0">
                        <v-text-field
                            v-model="balanceGeneralYear"
                            prepend-icon="$vuetify.icons.icon_mdi_calendar"
                            single-line
                            type="number"
                            style="width: 100px"
                            :rules="[v => v >= 2020 || 'The value must be a bigger than 2020']"
                            @change="getDataMonth(tabMonth)"
                            @click="getDataMonth(tabMonth)"
                        ></v-text-field>
                        <v-btn 
                            small
                            v-if="this.$store.state.accessCajaGrande === 1"
                            @click="openAnnualReport()"
                            class="my-5 mx-8"
                        >
                            Annual Report
                        </v-btn>
                        <v-menu
                            ref="menu"
                            :close-on-content-click="false"
                            :return-value.sync="lockedFinancialMonth"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="lockedFinancialMonth"
                                label="Closed until"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                color="red"
                                style="width: 100px"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="lockedFinancialMonth"
                                type="month"
                                no-title
                                scrollable
                                color="red"
                            >
                            <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="red"
                                    @click="$refs.menu.save(lockedFinancialMonth), saveFinancialLocked()"
                                >
                                    Save
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-card>
                </v-card>
                <v-tabs
                    v-model="tabMonth"
                    background-color="blue-grey lighten-5"
                    color="basil"
                    dark
                    grow
                    @change="getDataMonth($event)"
                >
                    <v-tab
                        v-for="itemTabMonth in itemsTabMonths"
                        :key="itemTabMonth"
                    >
                        <span class="grey--text darken-2">
                            {{ itemTabMonth }}
                        </span>
                    </v-tab>
                </v-tabs>
                <!-- Months -->
                <v-tabs-items v-model="tabMonth">
                    <v-tab-item
                        v-for="itemTabMonth in itemsTabMonths"
                        :key="itemTabMonth"
                    >
                    <v-card flat class="ma-3 rounded-xl">
                        <v-card-text>
                            <v-card flat class="d-flex flex-row flex-wrap">
                                <v-card-title class="mt-3" v-if="$store.state.showBank === 1 || $store.state.showWise === 1">
                                    End of Month Totals
                                </v-card-title>
                                <v-card flat v-if="$store.state.showBank === 1">
                                    <v-card-subtitle>
                                        Bank
                                    </v-card-subtitle>
                                    <v-card-text
                                        class="d-flex flex-row"
                                    >
                                        <v-btn
                                            @click='openReportValutaYear("solesBank")'
                                            small
                                            class="mt-2"
                                            :loading="loadingCajas"
                                            width="150px"
                                        >
                                            <div v-if="currentBalances[1]">S/.{{ currentBalances[1].toFixed(2) }}</div>
                                        </v-btn>
                                        <v-btn
                                            @click='openReportValutaYear("dollarsBank")'
                                            small
                                            class="mt-2"
                                            :loading="loadingCajas"
                                            width="150px"
                                        >
                                            <div v-if="currentBalances[0]">${{ currentBalances[0].toFixed(2) }}</div>
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                                <v-card flat v-if="$store.state.showWise === 1">
                                    <v-card-subtitle>
                                        Wise
                                    </v-card-subtitle>
                                    <v-card-text
                                        class="d-flex flex-row"
                                    >
                                        <v-btn
                                            @click='openReportValutaYear("dollarsWise")'
                                            small
                                            class="mt-2"
                                            :loading="loadingCajas"
                                            width="150px"
                                        >
                                            <div v-if="currentBalances[2]">${{ currentBalances[2].toFixed(2) }}</div>
                                        </v-btn>
                                        <v-btn
                                            @click='openReportValutaYear("eurosWise")'
                                            small
                                            class="mt-2"
                                            :loading="loadingCajas"
                                            width="150px"
                                        >
                                            <div v-if="currentBalances[3]">€{{ currentBalances[3].toFixed(2) }}</div>
                                        </v-btn>
                                        <v-btn
                                            @click='openReportValutaYear("gbpWise")'
                                            small
                                            class="mt-2"
                                            :loading="loadingCajas"
                                            width="150px"
                                        >
                                            <div v-if="currentBalances[4]">£{{ currentBalances[4].toFixed(2) }}</div>
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-card>
                        </v-card-text>
                    </v-card>
                        <v-tabs
                            v-model="tabIncomeOutgoing"
                            background-color="transparent"
                            color="basil"
                            grow
                        >
                            <v-tab>
                                <v-btn
                                    color="ppc_darkgreen"
                                    dark
                                    x-small
                                    absolute
                                    left
                                    fab
                                    @click="newEditIncome(null, null, null, null)"
                                >
                                    <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                                </v-btn>
                                <span class="ppc_darkgreen--text">
                                    Income
                                </span>
                            </v-tab>
                            <v-tab>
                                <v-btn
                                    color="blue"
                                    dark
                                    x-small
                                    absolute
                                    right
                                    fab
                                    @click="newEditOutgoing(null)"
                                >
                                    <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                                </v-btn>
                                <span class="blue--text">
                                    Outgoings
                                </span>
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tabIncomeOutgoing">
                            <v-tab-item>
                                <v-data-table
                                    :headers="headersIncomeMonth"
                                    :items="incomeMonth"
                                    class="elevation-1 ma-2"
                                    disable-pagination
                                    hide-default-footer
                                    sort-by="dateTime"
                                    dense
                                    :item-class="itemRowBackgroundIncome"
                                >
                                    <template v-slot:[`item.payment`]="{ item }">
                                        <span v-if="item.payment">{{ item.currency }} {{ item.amount.toFixed(2) }}</span>
                                    </template>
                                    <template v-slot:[`item.payerName`]="{ item }">
                                        <span v-if="item.client_id !== null"><v-btn x-small rounded @click="goClient(item.client_id)">{{ item.payerName }}</v-btn></span>
                                        <span v-else>{{ item.payerName }}</span>
                                    </template>
                                    <template v-slot:[`item.allDistributed`]="{ item }">
                                        <v-icon small v-if="item.allDistributed === 'ok'" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                                        <span v-else v-html="item.allDistributed"></span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <span class='d-flex flex-row'>
                                            <v-icon
                                                small
                                                class="mr-2"
                                                @click="editIncome(item)"
                                                v-if="!item.idSquare"
                                            >
                                                $vuetify.icons.icon_mdi_pencil
                                            </v-icon>
                                            <v-icon
                                                small
                                                @click="deleteIncome(item)"
                                                :disabled="item.frozen"
                                            >
                                                $vuetify.icons.icon_mdi_delete
                                            </v-icon>
                                        </span>
                                    </template>
                                </v-data-table>
                            </v-tab-item>
                            <v-tab-item>
                                <v-data-table
                                    :headers="headersOutgoingsMonth"
                                    :items="outgoingsMonth"
                                    class="elevation-1 ma-2"
                                    disable-pagination
                                    hide-default-footer
                                    sort-by="dateTime"
                                    dense
                                    :item-class="itemRowBackgroundOutgoings"
                                >
                                    <template v-slot:[`item.payment`]="{ item }">
                                        <span v-if="item.payment">{{ item.currency }} {{ (item.amount + item.bankTransferFee).toFixed(2) }}</span>
                                    </template>
                                    <template v-slot:[`item.receiverName`]="{ item }">
                                        <span v-if="item.payerClient !== null"><v-btn x-small rounded @click="goClient(item.payerClient)">{{ item.receiverName }}</v-btn></span>
                                        <span v-else>{{ item.receiverName }}</span>
                                    </template>
                                    <template v-slot:[`item.allDistributed`]="{ item }">
                                        <v-icon small v-if="item.allDistributed === 'ok'" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                                        <span v-else v-html="item.allDistributed"></span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <span class='d-flex flex-row'>
                                            <v-icon
                                                small
                                                class="mr-2"
                                                @click="editOutgoing(item)"
                                                v-if="!item.idSquare"
                                            >
                                                $vuetify.icons.icon_mdi_pencil
                                            </v-icon>
                                            <v-icon
                                                small
                                                class="mr-2"
                                                @click="deleteOutgoing(item)"
                                                :disabled="item.frozen"
                                            >
                                                $vuetify.icons.icon_mdi_delete
                                            </v-icon>
                                            <v-icon
                                                small
                                                class="mr-2"
                                                @click="goPaymentTeacherForm(item)"
                                                v-if="!item.idSquare && (item.type_id === 12 || item.type_id === 13 || item.type_id === 32)"
                                            >
                                                mdi-file-chart
                                            </v-icon>
                                        </span>
                                    </template>
                                </v-data-table>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tab-item>
                </v-tabs-items>         
            </v-tab-item>
            <v-tab-item>
                <v-tabs
                    v-model="tabCategoriesTypes"
                    background-color="transparent"
                    color="basil"
                    grow
                >
                    <v-tab>
                        <span class="ppc_darkgreen--text">
                            Income
                        </span>
                    </v-tab>
                    <v-tab>
                        <span class="blue--text">
                            Outgoings
                        </span>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabCategoriesTypes"  :key="renderTabCategoriesTypes">
                    <v-tab-item>
                        <div class="d-flex flex-row mt-4">
                            <v-data-table
                                :headers="headersCategoriesIncome"
                                :items="categoriesIncome"
                                class="elevation-1 ma-2"
                                disable-pagination
                                hide-default-footer
                                sort-by="name"
                                dense
                            >
                                <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <v-toolbar-title>Categories</v-toolbar-title>
                                        <v-divider
                                            class="mx-4"
                                            inset
                                            vertical
                                        ></v-divider>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.obsolete`]="{ item }">
                                    <v-icon small v-if="item.obsolete !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editCategoryIncome(item)"
                                    >
                                        $vuetify.icons.icon_mdi_pencil
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="deleteCategoryIncome(item)"
                                    >
                                        $vuetify.icons.icon_mdi_delete
                                    </v-icon>
                                </template>
                            </v-data-table>
                            <v-data-table
                                :headers="headersTypesIncome"
                                :items="typesIncome"
                                class="elevation-1 ma-2"
                                disable-pagination
                                hide-default-footer
                                sort-by="name"
                                dense
                            >
                                <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <v-toolbar-title>Types</v-toolbar-title>
                                        <v-divider
                                            class="mx-4"
                                            inset
                                            vertical
                                        ></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="ppc_darkgreen"
                                            dark
                                            small
                                            absolute
                                            right
                                            fab
                                            @click="dialogTypeIncome=true"
                                        >
                                            <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                                        </v-btn>
                                        <v-dialog
                                            v-model="dialogTypeIncome"
                                            max-width="500px"
                                            persistent
                                        >
                                            <v-form ref="dialogTypeIncomeForm">
                                                <v-card class="d-flex flex-column">
                                                    <v-card-title class="ppc_darkgreen darken-1">
                                                        <span class="headline white--text">{{ formTitleTypeIncome }}</span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-text-field
                                                            v-model.trim="editedTypeIncomeItem.name"
                                                            label="Name"
                                                            counter
                                                            :rules="inputRulesName"
                                                            :disabled="editedTypeIncomeItem.fixed === 1"
                                                        ></v-text-field>
                                                        <v-select
                                                            v-model="editedTypeIncomeItem.category_id"
                                                            :items="selectCategoriesIncome"
                                                            item-text="name"
                                                            item-value="id"
                                                            :menu-props="{ auto: true }"
                                                            label="Select category"
                                                            single-line
                                                            validate-on-blur
                                                            :rules="inputRulesReq"
                                                            :disabled="editedTypeIncomeItem.fixed === 1"
                                                        ></v-select>
                                                        <v-select
                                                            v-model="editedTypeIncomeItem.payer"
                                                            :items="selectPayer"
                                                            item-text="name"
                                                            item-value="value"
                                                            :menu-props="{ auto: true }"
                                                            label="Select Payer"
                                                            single-line
                                                            validate-on-blur
                                                            :rules="inputRulesReq"
                                                            :disabled="editedTypeIncomeItem.fixed === 1"
                                                        ></v-select>
                                                        <v-select
                                                            v-model="editedTypeIncomeItem.paymentMethod"
                                                            :items="selectPaymentMethods()"
                                                            item-text="method"
                                                            item-value="id"
                                                            :menu-props="{ auto: true }"
                                                            label="Select Payment Method"
                                                            single-line
                                                            validate-on-blur
                                                            :disabled="editedTypeIncomeItem.fixed === 1"
                                                        ></v-select>
                                                        <v-checkbox
                                                            v-model="editedTypeIncomeItem.accountable"
                                                            :input-value="editedTypeIncomeItem.accountable === 1"
                                                            label="Accounted for with Applications"
                                                            :disabled="editedTypeIncomeItem.fixed === 1"
                                                        ></v-checkbox>
                                                        <v-checkbox
                                                            v-model="editedTypeIncomeItem.showCajaChica"
                                                            :input-value="editedTypeIncomeItem.showCajaChica === 1"
                                                            label="Show in Caja Chica"
                                                        ></v-checkbox>
                                                        <v-checkbox
                                                            v-model="editedTypeIncomeItem.fixed"
                                                            label="Fix (nobody can change it later)"
                                                            :disabled="editedTypeIncomeItem.fixed === 1"
                                                        ></v-checkbox>
                                                        <v-textarea
                                                            class="rounded-xl ma-3"
                                                            v-model="editedTypeIncomeItem.description"
                                                            auto-grow
                                                            clearable
                                                            counter
                                                            outlined
                                                            label="Description (255 max)"
                                                            :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                                                        ></v-textarea>
                                                        <v-checkbox
                                                            :input-value="editedTypeIncomeItem.obsolete !== null"
                                                            label="Obsolete"
                                                            @change="setObsoleteTypeIncome($event)"
                                                        ></v-checkbox>
                                                        <v-text-field
                                                            :value="formattedDate(editedTypeIncomeItem.obsolete)"
                                                            disabled
                                                            label="From"
                                                            v-if="editedTypeIncomeItem.obsolete !== null"
                                                            class="ml-2"
                                                        ></v-text-field>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="ppc_darkgreen darken-1"
                                                            text
                                                            @click="closeTypeIncome"
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            color="ppc_darkgreen darken-1"
                                                            text
                                                            @click="saveTypeIncome"
                                                        >
                                                            Save
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-form>
                                        </v-dialog>
                                        <v-dialog v-model="dialogTypeIncomeDelete" max-width="500px">
                                            <v-card>
                                                <v-card-title>Are you sure you want to delete {{ editedTypeIncomeItem.name }}?</v-card-title>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="ppc_darkgreen darken-1" text @click="closeDeleteTypeIncome">Cancel</v-btn>
                                                    <v-btn color="ppc_darkgreen darken-1" text @click="deleteTypeIncomeConfirm">OK</v-btn>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.accountable`]="{ item }">
                                    <v-icon small v-if="item.accountable === 1" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                                </template>
                                <template v-slot:[`item.paymentMethod`]="{ item }">
                                    <v-icon small v-if="!item.paymentMethod" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                                </template>
                                <template v-slot:[`item.showCajaChica`]="{ item }">
                                    <v-icon small v-if="item.showCajaChica === 1" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                                </template>
                                <template v-slot:[`item.fixed`]="{ item }">
                                    <v-icon small v-if="item.fixed === 1" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                                </template>
                                <template v-slot:[`item.obsolete`]="{ item }">
                                    <v-icon small v-if="item.obsolete !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editTypeIncome(item)"
                                    >
                                        $vuetify.icons.icon_mdi_pencil
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="deleteTypeIncome(item)"
                                        :disabled="item.used !== 0"
                                    >
                                        $vuetify.icons.icon_mdi_delete
                                    </v-icon>
                                </template>
                            </v-data-table>
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="d-flex flex-row mt-4">
                            <v-data-table
                                :headers="headersCategoriesOutgoings"
                                :items="categoriesOutgoings"
                                class="elevation-1 ma-2"
                                disable-pagination
                                hide-default-footer
                                sort-by="name"
                                dense
                            >
                                <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <v-toolbar-title>Categories</v-toolbar-title>
                                        <v-divider
                                            class="mx-4"
                                            inset
                                            vertical
                                        ></v-divider>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.obsolete`]="{ item }">
                                    <v-icon small v-if="item.obsolete !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editCategoryOutgoings(item)"
                                    >
                                        $vuetify.icons.icon_mdi_pencil
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="deleteCategoryOutgoings(item)"
                                    >
                                        $vuetify.icons.icon_mdi_delete
                                    </v-icon>
                                </template>
                            </v-data-table>
                            <v-data-table
                                :headers="headersTypesOutgoings"
                                :items="typesOutgoings"
                                class="elevation-1 ma-2"
                                disable-pagination
                                hide-default-footer
                                sort-by="name"
                                dense
                            >
                                <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <v-toolbar-title>Types</v-toolbar-title>
                                        <v-divider
                                            class="mx-4"
                                            inset
                                            vertical
                                        ></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="blue"
                                            dark
                                            small
                                            absolute
                                            right
                                            fab
                                            @click="dialogTypeOutgoings=true"
                                        >
                                            <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                                        </v-btn>
                                        <v-dialog
                                            v-model="dialogTypeOutgoings"
                                            max-width="500px"
                                            persistent
                                        >
                                            <v-form ref="dialogTypeOutgoingsForm">
                                                <v-card class="d-flex flex-column">
                                                    <v-card-title class="blue darken-1">
                                                        <span class="headline white--text">{{ formTitleTypeOutgoings }}</span>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-text-field
                                                            v-model.trim="editedTypeOutgoingsItem.name"
                                                            label="Name"
                                                            counter
                                                            :rules="inputRulesName"
                                                            :disabled="editedTypeOutgoingsItem.fixed === 1"
                                                        ></v-text-field>
                                                        <v-select
                                                            v-model="editedTypeOutgoingsItem.category_id"
                                                            :items="selectCategoriesOutgoings"
                                                            item-text="name"
                                                            item-value="id"
                                                            :menu-props="{ auto: true }"
                                                            label="Select category"
                                                            single-line
                                                            validate-on-blur
                                                            :rules="inputRulesReq"
                                                            :disabled="editedTypeOutgoingsItem.fixed === 1"
                                                        ></v-select>
                                                        <v-select
                                                            v-model="editedTypeOutgoingsItem.receiver"
                                                            :items="selectReceiver"
                                                            item-text="name"
                                                            item-value="value"
                                                            :menu-props="{ auto: true }"
                                                            label="Select Receiver"
                                                            single-line
                                                            validate-on-blur
                                                            :rules="inputRulesReq"
                                                            :disabled="editedTypeOutgoingsItem.fixed === 1"
                                                        ></v-select>
                                                        <v-select
                                                            v-model="editedTypeOutgoingsItem.contact_id"
                                                            :items="$store.state.contacts"
                                                            item-text="full_name"
                                                            item-value="id"
                                                            :menu-props="{ auto: true }"
                                                            label="Select Fixed Contact"
                                                            single-line
                                                            prepend-icon="$vuetify.icons.icon_mdi_account_group ppc_darkgreen--text"
                                                            validate-on-blur
                                                            :disabled="editedTypeOutgoingsItem.fixed === 1"
                                                            v-if="editedTypeOutgoingsItem.receiver === 'Fixed Contact'"
                                                        ></v-select>
                                                        <v-select
                                                            v-model="editedTypeOutgoingsItem.paymentMethod"
                                                            :items="selectPaymentMethods()"
                                                            item-text="method"
                                                            item-value="id"
                                                            :menu-props="{ auto: true }"
                                                            label="Select Payment Method"
                                                            single-line
                                                            validate-on-blur
                                                            :disabled="editedTypeOutgoingsItem.fixed === 1"
                                                        ></v-select>
                                                        <v-checkbox
                                                            v-model="editedTypeOutgoingsItem.accountable"
                                                            :input-value="editedTypeOutgoingsItem.accountable === 1"
                                                            label="Accounted for with Resources"
                                                            :disabled="editedTypeOutgoingsItem.fixed === 1"
                                                        ></v-checkbox>
                                                        <v-checkbox
                                                            v-model="editedTypeOutgoingsItem.showCajaChica"
                                                            :input-value="editedTypeOutgoingsItem.showCajaChica === 1"
                                                            label="Show in Caja Chica"
                                                        ></v-checkbox>
                                                        <v-checkbox
                                                            v-model="editedTypeOutgoingsItem.fixed"
                                                            label="Fix (nobody can change it later)"
                                                            :disabled="editedTypeOutgoingsItem.fixed === 1"
                                                        ></v-checkbox>
                                                        <v-textarea
                                                            class="rounded-xl ma-3"
                                                            v-model="editedTypeOutgoingsItem.description"
                                                            auto-grow
                                                            clearable
                                                            counter
                                                            outlined
                                                            label="Description (255 max)"
                                                            :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                                                        ></v-textarea>
                                                        <v-checkbox
                                                            :input-value="editedTypeOutgoingsItem.obsolete !== null"
                                                            label="Obsolete"
                                                            @change="setObsoleteTypeOutgoings($event)"
                                                        ></v-checkbox>
                                                        <v-text-field
                                                            :value="formattedDate(editedTypeOutgoingsItem.obsolete)"
                                                            disabled
                                                            label="From"
                                                            v-if="editedTypeOutgoingsItem.obsolete !== null"
                                                            class="ml-2"
                                                        ></v-text-field>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="ppc_darkgreen darken-1"
                                                            text
                                                            @click="closeTypeOutgoings"
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            color="ppc_darkgreen darken-1"
                                                            text
                                                            @click="saveTypeOutgoings"
                                                        >
                                                            Save
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-form>
                                        </v-dialog>
                                        <v-dialog v-model="dialogTypeOutgoingsDelete" max-width="500px">
                                            <v-card>
                                                <v-card-title>Are you sure you want to delete {{ editedTypeOutgoingsItem.name }}?</v-card-title>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="ppc_darkgreen darken-1" text @click="closeDeleteTypeOutgoings">Cancel</v-btn>
                                                    <v-btn color="ppc_darkgreen darken-1" text @click="deleteTypeOutgoingsConfirm">OK</v-btn>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.accountable`]="{ item }">
                                    <v-icon small v-if="item.accountable === 1" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                                </template>
                                <template v-slot:[`item.paymentMethod`]="{ item }">
                                    <v-icon small v-if="!item.paymentMethod" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                                </template>
                                <template v-slot:[`item.showCajaChica`]="{ item }">
                                    <v-icon small v-if="item.showCajaChica === 1" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                                </template>
                                <template v-slot:[`item.fixed`]="{ item }">
                                    <v-icon small v-if="item.fixed === 1" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                                </template>
                                <template v-slot:[`item.obsolete`]="{ item }">
                                    <v-icon small v-if="item.obsolete !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editTypeOutgoings(item)"
                                    >
                                        $vuetify.icons.icon_mdi_pencil
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="deleteTypeOutgoings(item)"
                                        :disabled="item.used !== 0"
                                    >
                                        $vuetify.icons.icon_mdi_delete
                                    </v-icon>
                                </template>
                            </v-data-table>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog
            v-model="dialogEditIncome"
            max-width="900px"
            persistent
        >
            <v-card flat class="d-flex flex-column" :key='renderDialog'>
                <v-card-title class="ppc_darkgreen darken-1 white--text">Edit Income</v-card-title>
                <v-form flat class="d-flex flex-column pa-5" ref="formIncome">
                    <v-card-actions class="d-flex flex-column" style="width: 100%">
                        <v-card flat>
                            <v-menu
                                transition="scale-transition"
                                offset-y
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formattedDate(editedIncomeItem.dateTime)"
                                        v-bind="attrs"
                                        prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                        v-on="on"
                                        readonly
                                        :disabled="editedIncomeItem.frozen"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="editedIncomeItem.dateTime"
                                    color="ppc_darkgreen"
                                    no-title
                                    scrollable
                                    :max="nowDate"
                                    :min="financialFirstOpenDay"
                                    :disabled="editedIncomeItem.frozen"
                                ></v-date-picker>
                            </v-menu>
                        </v-card>
                        <v-card flat>
                            <v-select
                                style="width: 400px"
                                v-model="editedIncomeItem.type_id"
                                :items="selectTypesIncome()"
                                item-text="name"
                                item-value="id"
                                :menu-props="{ auto: true }"
                                label="type of income"
                                single-line
                                prepend-icon="mdi-arrow-right-thick ppc_darkgreen--text"
                                validate-on-blur
                                clearable
                                class="mr-4"
                                :rules="inputRulesReq"
                                @change="changeIncomeType()"
                                :disabled="distributionsIncomeNew.length > 0 || tabGeneral === 1 || editedIncomeItem.frozen"
                            ></v-select>
                        </v-card>
                        <v-card-subtitle style="width: 100%">
                            <v-divider></v-divider>
                        </v-card-subtitle>
                        <v-card flat class="d-flex flex-row flex-wrap mb-4" :disabled="distributionsIncomeNew.length > 0 || tabGeneral === 1">
                            <v-select
                                style="width: 250px"
                                v-model="editedIncomeItem.client_id"
                                :items="selectClients"
                                item-text="full_name"
                                item-value="id"
                                :menu-props="{ auto: true }"
                                label="client"
                                single-line
                                prepend-icon="$vuetify.icons.icon_mdi_account ppc_darkgreen--text"
                                validate-on-blur
                                clearable
                                class="mr-4"
                                @change="choosePayer('client')"
                                v-if="editedIncomeItem.type_id  && editedIncomeItem.payer === 'Client'"
                                :disabled="editedIncomeItem.frozen"
                            ></v-select>
                            <v-select
                                style="width: 250px"
                                v-model="editedIncomeItem.agency_id"
                                :items="selectAvailableAgencies()"
                                item-text="name"
                                item-value="id"
                                :menu-props="{ auto: true }"
                                label="agency"
                                single-line
                                prepend-icon="$vuetify.icons.icon_mdi_domain ppc_darkgreen--text"
                                validate-on-blur
                                clearable
                                class="mr-4"
                                @change="choosePayer('agency')"
                                v-if="editedIncomeItem.type_id && editedIncomeItem.payer === 'Client Agency'"
                                :disabled="editedIncomeItem.frozen"
                            ></v-select>
                            <v-select
                                style="width: 250px"
                                v-model="editedIncomeItem.contact_id"
                                :items="selectContactsIncome()"
                                item-text="full_name"
                                item-value="id"
                                :menu-props="{ auto: true }"
                                label="contact"
                                single-line
                                prepend-icon="$vuetify.icons.icon_mdi_account_group ppc_darkgreen--text"
                                validate-on-blur
                                clearable
                                class="mr-4"
                                @change="choosePayer('contact')"
                                v-if="editedIncomeItem.type_id && editedIncomeItem.payer === 'Contact'"
                                :disabled="editedIncomeItem.frozen"
                            ></v-select>
                        </v-card>
                        <v-select
                            style="width: 250px"
                            v-model="editedIncomeItem.paymentMethod"
                            :items="selectPaymentMethods()"
                            item-text="method"
                            item-value="id"
                            :menu-props="{ auto: true }"
                            label="payment method"
                            single-line
                            prepend-icon="$vuetify.icons.icon_mdi_credit_card_outline ppc_darkgreen--text"
                            validate-on-blur
                            clearable
                            class="mr-4"
                            :rules="inputRulesReq"
                            v-if="tabGeneral === 1 || tabGeneral === 2"
                            :disabled="editedIncomeItem.fixedPaymentMethod === 1 || !editedIncomeItem.type_id || distributionsIncomeNew.length > 0 || editedIncomeItem.frozen"
                        ></v-select>
                        <v-radio-group
                            v-model="editedIncomeItem.currency"
                            row
                            label="Currency"
                            :rules="inputRulesReq"
                            :disabled="distributionsIncomeNew.length > 0 || editedIncomeItem.frozen"
                            v-if="editedIncomeItem.type_id !== 29 && editedIncomeItem.type_id !== 30"
                        >
                            <v-radio
                                label = "$"
                                value="$"
                                v-if="editedIncomeItem.type_id !== 22"
                            ></v-radio>
                            <v-radio
                                label = "PEN"
                                value="S/."
                                v-if="editedIncomeItem.paymentMethod !== 2"
                            ></v-radio>
                            <v-radio
                                label = "€"
                                value="€"
                                v-if="editedIncomeItem.paymentMethod === 2"
                            ></v-radio>
                            <v-radio
                                label = "£"
                                value="£"
                                v-if="editedIncomeItem.paymentMethod === 2"
                            ></v-radio>
                        </v-radio-group>
                        <v-card flat class="d-flex flex-row flex-wrap">
                            <v-card flat class="ma-2 pa-4 rounded-xl">
                                <v-text-field
                                    v-model="editedIncomeItem.amount"
                                    class="pa-0 mr-2"
                                    label="amount received"
                                    clearable
                                    :rules="numberRule"
                                    :disabled="distributionsIncomeNew.length > 0 || editedIncomeItem.frozen"
                                ></v-text-field>
                            </v-card>
                            <div v-if="editedIncomeItem.type_id !== 13">
                                <v-card flat class="ma-2 pa-4 rounded-xl">
                                    <v-text-field
                                        v-model="editedIncomeItem.bankTransferFee"
                                        class="pa-0 mr-2"
                                        label="bank transfer fee"
                                        clearable
                                        :disabled="editedIncomeItem.frozen"
                                    ></v-text-field>
                                </v-card>
                                <v-card flat v-if="transferFeeIncluded === 1" class="mt-8">
                                    Included!
                                </v-card>
                            </div>
                        </v-card>
                        <v-card flat class="d-flex flex-row flex wrap" :disabled="editedIncomeItem.accountable === 0 || (!editedIncomeItem.client_id && !editedIncomeItem.agency_id)  || !editedIncomeItem.amount">
                            <v-btn
                                color="ppc_pink"
                                dark
                                x-small
                                bottom
                                fab
                                @click="openAddDistributionsIncome"
                                :disabled="editedIncomeItem.frozen"
                                v-if="checkShow()"
                            >
                                <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                            </v-btn>
                            <v-card flat class="d-flex flex-column">
                                <v-data-table
                                    :headers="headersDistributionsIncome"
                                    :items="distributionsIncomeNew"
                                    class="ma-4"
                                    disable-pagination
                                    hide-default-footer
                                    dense
                                >
                                    <template v-slot:[`item.totalPrice`]="{ item }">
                                        <span v-if="item.totalPrice">${{ parseFloat(item.totalPrice).toFixed(2) }}</span>
                                    </template>
                                    <template v-slot:[`item.amount`]="{ item }">
                                        <span v-if="item.amount">${{ item.amount.toFixed(2) }}</span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-icon
                                            small
                                            @click="deleteDistributionIncome(item)"
                                            :disabled="item.transactionStatus === 'closed' || editedIncomeItem.frozen"
                                        >
                                            $vuetify.icons.icon_mdi_delete
                                        </v-icon>
                                    </template>
                                </v-data-table>
                                <v-divider></v-divider>
                                <v-card-subtitle v-if="distributionsTotalIncome">Total:  ${{ distributionsTotalIncome.toFixed(2) }}</v-card-subtitle>
                            </v-card>
                        </v-card>
                        <v-card flat>
                            <v-textarea
                                class="rounded-xl ma-2"
                                v-model="editedIncomeItem.comments"
                                auto-grow
                                clearable
                                counter
                                rows="2"
                                outlined
                                label="Comments (255 max)"
                                :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                                :disabled="editedIncomeItem.frozen"
                            ></v-textarea>
                            <v-checkbox
                                v-model="editedIncomeItem.setAllDistributed"
                                v-if="editedIncomeItem.currency !== '$' && editedIncomeItem.currency !== 'S/.'"
                                label="All distributed"
                                :disabled="editedIncomeItem.frozen"
                            ></v-checkbox>
                        </v-card>
                        <div class="d-flex flex-row flex-wrap justify-space-around" style="width: 100%">
                            <div>
                                <v-btn 
                                    class="mt-4"
                                    @click="closeEditIncome()"
                                >
                                    Cancel
                                </v-btn>
                            </div>
                            <div>
                                <v-btn 
                                    class="mt-4"
                                    @click="saveIncome()"
                                    :loading="loadingButton"
                                    :disabled="editedIncomeItem.frozen"
                                >
                                    Save
                                </v-btn>
                            </div>
                        </div>
                    </v-card-actions>
                </v-form>
                <v-card-subtitle>{{editedIncomeItem.id}}</v-card-subtitle>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_add_distributionIncome" :retain-focus="false" max-width="320px">
            <v-card>
                <v-card-title class="d-flex flex-row flex-wrap py-0">
                    <span>Distribute $</span>
                    <span>
                        <v-text-field
                            v-model="amountDistributedIncome"
                            class="pa-0 mx-2 mt-6"
                            style="width: 80px"
                            @change="calcDistributed()"
                        ></v-text-field>
                    </span>
                    <!-- <span>of {{ editedIncomeItem.currency }} {{ editedIncomeItem.amount }} to:</span> -->
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                    <v-list rounded>
                        <v-list-item-group color="primary">
                            <v-list-item
                                v-for="(app, i) in selectApplicationsPayerItems"
                                :key="i"
                                @click="addDistributionIncome(app)"
                            >
                                <v-list-item-content>
                                    <v-list-item-title v-text="app.appTitle"></v-list-item-title>
                                    <v-list-item-subtitle v-text="app.id"></v-list-item-subtitle>
                                    <span v-if="app.totalPrice && app.amountPaidApplication"><v-list-item-content v-text="'Price $' + parseFloat(app.totalPrice).toFixed(2) + ' - Open $' + (parseFloat(app.totalPrice) - parseFloat(app.amountPaidApplication)).toFixed(2)"></v-list-item-content></span>
                                    <span v-else-if="app.totalPrice"><v-list-item-content v-text="'Open $' + parseFloat(app.totalPrice).toFixed(2)"></v-list-item-content></span>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="ppc_darkgreen darken-1" text @click="dialog_add_distributionIncome = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogIncomeDelete" max-width="500px">
            <v-card>
                <v-card-title v-if="!editedIncomeItem.idSquare">Are you sure you want to delete the income payment of {{ editedIncomeItem.payerName }}?</v-card-title>
                <v-card-title v-else>Are you sure you want to remove the Quadrado?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="ppc_darkgreen darken-1" text @click="closeDeleteIncome">Cancel</v-btn>
                    <v-btn color="ppc_darkgreen darken-1" text @click="deleteIncomeConfirm" :loading="loadingButton">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogEditOutgoing"
            max-width="900px"
            persistent
        >
            <v-card flat class="d-flex flex-column" :key="renderDialog">
                <v-card-title class="blue darken-1 white--text">Edit Outgoing</v-card-title>
                <v-form flat class="d-flex flex-column pa-5" ref="formOutgoing">
                    <v-card-actions class="d-flex flex-column" style="width: 100%">
                        <v-card flat>
                            <v-menu
                                transition="scale-transition"
                                offset-y
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formattedDate(editedOutgoingsItem.dateTime)"
                                        v-bind="attrs"
                                        color="blue"
                                        prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                        v-on="on"
                                        readonly
                                        :disabled="editedOutgoingsItem.frozen"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="editedOutgoingsItem.dateTime"
                                    color="blue"
                                    no-title
                                    scrollable
                                    :max="nowDate"
                                    :min="financialFirstOpenDay"
                                    :disabled="editedOutgoingsItem.frozen"
                                ></v-date-picker>
                            </v-menu>
                        </v-card>
                        <v-card flat>
                            <v-select
                                style="width: 400px"
                                v-model="editedOutgoingsItem.type_id"
                                :items="selectTypesOutgoings()"
                                item-text="name"
                                item-value="id"
                                :menu-props="{ auto: true }"
                                label="type of outgoing"
                                single-line
                                prepend-icon="mdi-arrow-right-thick blue--text"
                                validate-on-blur
                                clearable
                                class="mr-4"
                                color="blue"
                                item-color="blue"
                                :rules="inputRulesReq"
                                @change="changeOutgoingType()"
                                :disabled="distributionsOutgoingsNew.length > 0 || tabGeneral === 1 || editedOutgoingsItem.frozen"
                            ></v-select>
                        </v-card>
                        <v-card-subtitle style="width: 100%">
                            <v-divider></v-divider>
                        </v-card-subtitle>
                        <v-card flat class="d-flex flex-row flex-wrap mb-4" :disabled="distributionsOutgoingsNew.length > 0 || tabGeneral === 1 || editedOutgoingsItem.frozen">
                            <v-select
                                style="width: 250px"
                                v-model="editedOutgoingsItem.client_id"
                                :items="selectClients"
                                item-text="full_name"
                                item-value="id"
                                :menu-props="{ auto: true }"
                                label="client"
                                single-line
                                prepend-icon="$vuetify.icons.icon_mdi_account blue--text"
                                validate-on-blur
                                clearable
                                class="mr-4"
                                color="blue"
                                item-color="blue"
                                @change="chooseReceiver('client')"
                                :disabled="!editedOutgoingsItem.type_id || editedOutgoingsItem.receiver !== 'Client' || editedOutgoingsItem.frozen"
                            ></v-select>
                            <v-select
                                style="width: 250px"
                                v-model="editedOutgoingsItem.contact_id"
                                :items="selectContactsOutgoings()"
                                item-text="full_name"
                                item-value="id"
                                :menu-props="{ auto: true }"
                                label="contact/organisation"
                                single-line
                                prepend-icon="$vuetify.icons.icon_mdi_account_group blue--text"
                                validate-on-blur
                                clearable
                                class="mr-4"
                                color="blue"
                                item-color="blue"
                                @change="chooseReceiver('contact')"
                                :disabled="!editedOutgoingsItem.type_id || !(editedOutgoingsItem.receiver === 'Contact' || editedOutgoingsItem.receiver === 'Contact Tour Agency' || editedOutgoingsItem.receiver === 'Contact' || editedOutgoingsItem.receiver === 'Contact Teacher' || editedOutgoingsItem.receiver === 'Contact Project' || editedOutgoingsItem.receiver === 'Contact Staff' || editedOutgoingsItem.receiver === 'Contact Accommodation') || editedOutgoingsItem.frozen"
                            ></v-select>
                            <div style="width: 250px">
                                <v-text-field
                                    style="width: 250px"
                                    v-model="editedOutgoingsItem.application_id"
                                    label="application nr"
                                    single-line
                                    validate-on-blur
                                    clearable
                                    class="mr-4"
                                    color="blue"
                                    item-color="blue"
                                    @change="checkAppNr()"
                                    :disabled="!editedOutgoingsItem.type_id || editedOutgoingsItem.receiver !== 'Application' || editedOutgoingsItem.frozen"
                                ></v-text-field>
                                <span class="smallText">{{ editedOutgoingsItem.application_appTitle }}</span>
                            </div>
                        </v-card>
                        <v-card flat class="d-flex flex-row flex wrap mb-8" :disabled="editedOutgoingsItem.accountable === 0 || !editedOutgoingsItem.contact_id">
                            <v-btn
                                color="ppc_pink"
                                dark
                                x-small
                                bottom
                                fab
                                v-if="editedOutgoingsItem.type_id !== 12 && editedOutgoingsItem.type_id !== 13 && editedOutgoingsItem.type_id !== 32"
                                @click="openAddDistributionsOutgoings"
                                :disabled="editedOutgoingsItem.frozen"
                            >
                                <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                            </v-btn>
                            <v-card flat class="d-flex flex-column">
                                <v-data-table
                                    :headers="headersDistributionsOutgoings"
                                    :items="distributionsOutgoingsNew"
                                    class="ma-4"
                                    disable-pagination
                                    hide-default-footer
                                    dense
                                    v-if="editedOutgoingsItem.type_id !== 12 && editedOutgoingsItem.type_id !== 13 && editedOutgoingsItem.type_id !== 32"
                                >
                                    <template v-slot:[`item.payment`]="{ item }">
                                        <span v-if="item.payment">{{ item.currency }} {{ item.payment.toFixed(2) }}</span>
                                    </template>
                                    <template v-slot:[`item.amount`]="{ item }">
                                        <span v-if="item.amount">{{ item.currency }} {{ item.amount.toFixed(2) }}</span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-icon
                                            small
                                            @click="deleteDistributionOutgoing(item)"
                                            :disabled="editedOutgoingsItem.type_id === 12 || editedOutgoingsItem.type_id === 13 || editedOutgoingsItem.type_id === 32 || editedOutgoingsItem.frozen"
                                        >
                                            $vuetify.icons.icon_mdi_delete
                                        </v-icon>
                                    </template>
                                </v-data-table>
                                <v-data-table
                                    :headers="headersClassesPayment"
                                    :items="classesPayment"
                                    class="ma-4"
                                    disable-pagination
                                    hide-default-footer
                                    dense
                                    v-if="editedOutgoingsItem.type_id === 12 || editedOutgoingsItem.type_id === 13 || editedOutgoingsItem.type_id === 32"
                                >
                                    <template v-slot:[`item.start`]="{ item }">
                                        {{ formattedDate(item.start, 'date') }}
                                    </template>
                                    <template v-slot:[`item.payment`]="{ item }">
                                        <span v-if="item.paymentClass">S/. {{ item.paymentClass.toFixed(2) }}</span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-icon
                                            small
                                            @click="deleteClassesPayment(item)"
                                            :disabled="loadingApp === 1"
                                        >
                                            $vuetify.icons.icon_mdi_delete
                                        </v-icon>
                                    </template>
                                </v-data-table>
                                <span v-if="(editedOutgoingsItem.type_id === 12 || editedOutgoingsItem.type_id === 13 || editedOutgoingsItem.type_id === 32)">
                                    Total: {{ distributionsTotalOutgoingsSoles }} <span v-if="discountPaymentTeachers">- {{ roundTwoDecimals(discountPaymentTeachers) }} (AFP {{ editedOutgoingsItem.discount_payment_teacher }}%)</span> <span v-if="editedOutgoingsItem.correction"> <span v-if="editedOutgoingsItem.correction.toFixed(2) > 0">+ </span>{{ editedOutgoingsItem.correction.toFixed(2) }} ({{ editedOutgoingsItem.comments_correction }})</span>
                                </span>
                            </v-card>
                        </v-card>
                        <v-select
                            style="width: 250px"
                            v-model="editedOutgoingsItem.paymentMethod"
                            :items="selectPaymentMethods()"
                            item-text="method"
                            item-value="id"
                            :menu-props="{ auto: true }"
                            label="payment method"
                            single-line
                            prepend-icon="$vuetify.icons.icon_mdi_credit_card_outline blue--text"
                            validate-on-blur
                            clearable
                            color="blue"
                            item-color="blue"
                            class="mr-4"
                            :rules="inputRulesReq"
                            v-if="tabGeneral === 1 || tabGeneral === 2"
                            :disabled="editedOutgoingsItem.fixedPaymentMethod === 1 || editedOutgoingsItem.frozen"
                        ></v-select>
                        <v-card flat class="d-flex flex-row flex-wrap">
                            <v-radio-group
                                v-model="editedOutgoingsItem.currency"
                                :rules="inputRulesReq"
                                class="mt-4"
                                :disabled="editedOutgoingsItem.type_id === 12 || editedOutgoingsItem.type_id === 13 || editedOutgoingsItem.type_id === 32 || editedOutgoingsItem.frozen"
                            >
                                <v-radio
                                    label = "USD"
                                    value="$"
                                    color="blue"
                                    v-if="editedOutgoingsItem.type_id !== 73"
                                ></v-radio>
                                <v-radio
                                    label = "PEN"
                                    value="S/."
                                    color="blue"
                                    v-if="editedOutgoingsItem.type_id !== 72 && editedOutgoingsItem.type_id !== 73 && editedOutgoingsItem.type_id !== 74 && editedOutgoingsItem.type_id !== 63"
                                ></v-radio>
                                <v-radio
                                    label = "EUR"
                                    value="€"
                                    color="blue"
                                    v-if="editedOutgoingsItem.type_id === 73 || editedOutgoingsItem.type_id === 57 || editedOutgoingsItem.type_id === 50"
                                ></v-radio>
                                <v-radio
                                    label = "GBP"
                                    value="£"
                                    color="blue"
                                    v-if="editedOutgoingsItem.type_id === 73 || editedOutgoingsItem.type_id === 57"
                                ></v-radio>
                            </v-radio-group>
                            <v-card flat class="ma-2 mt-4 pa-4 rounded-xl" width="120px">
                                <v-text-field
                                    v-model="editedOutgoingsItem.amount"
                                    class="pa-0 mr-2"
                                    label="amount paid"
                                    :rules="numberRule"
                                    color="blue"
                                    v-if="editedOutgoingsItem.accountable === 0"
                                    :disabled="editedOutgoingsItem.frozen"
                                ></v-text-field>
                                <span v-if="(editedOutgoingsItem.type_id === 12 || editedOutgoingsItem.type_id === 13 || editedOutgoingsItem.type_id === 32) && distributionsTotalOutgoingsSoles && (discountPaymentTeachers || editedOutgoingsItem.correction)">
                                    <span>S/.{{ roundTwoDecimals(distributionsTotalOutgoingsSoles - roundTwoDecimals(discountPaymentTeachers) + editedOutgoingsItem.correction) }}</span>
                                </span>
                                <span v-else-if='editedOutgoingsItem.currency === "$" && editedOutgoingsItem.accountable === 1'>${{ distributionsTotalOutgoingsDollar }}</span>
                                <span v-else-if='editedOutgoingsItem.currency === "S/." && editedOutgoingsItem.accountable === 1'>S/.{{ distributionsTotalOutgoingsSoles }}</span>
                            </v-card>
                            <v-card flat class="ma-2 pa-4 rounded-xl" v-if="tabGeneral === 1 || tabGeneral === 2">
                                <v-text-field
                                    v-model="editedOutgoingsItem.bankTransferFee"
                                    class="pa-0 mr-2"
                                    label="bank transfer fee"
                                    clearable
                                    color="blue"
                                    :disabled="editedOutgoingsItem.frozen"
                                ></v-text-field>
                            </v-card>
                        </v-card>
                        <v-card flat>
                            <v-textarea
                                class="rounded-xl ma-2"
                                v-model="editedOutgoingsItem.comments"
                                auto-grow
                                clearable
                                counter
                                rows="2"
                                outlined
                                color="blue"
                                label="Comments (255 max)"
                                :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                                :disabled="editedOutgoingsItem.frozen"
                            ></v-textarea>
                        </v-card>
                        <div class="d-flex flex-row flex-wrap justify-space-around" style="width: 100%">
                            <div>
                                <v-btn 
                                    class="mt-4"
                                    @click="closeEditOutgoing()"
                                >
                                    Cancel
                                </v-btn>
                            </div>
                            <div>
                                <v-btn 
                                    class="mt-4"
                                    @click="saveOutgoing()"
                                    :loading="loadingButton"
                                    :disabled="editedOutgoingsItem.receiver === 'Application' && editedOutgoingsItem.application_appTitle === '' || editedOutgoingsItem.frozen"
                                >
                                    Save
                                </v-btn>
                            </div>
                        </div>
                    </v-card-actions>
                </v-form>
                <v-card-subtitle>{{editedOutgoingsItem.id}}</v-card-subtitle>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogOutgoingDelete" max-width="500px">
            <v-card>
                <v-card-title class="blue--text" v-if="!editedOutgoingsItem.idSquare">Are you sure you want to delete the Outgoing payment of {{ editedOutgoingsItem.receiverName }}?</v-card-title>
                <v-card-title class="blue--text" v-else>Are you sure you want to remove the Quadrado?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDeleteOutgoing">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteOutgoingConfirm" :loading="loadingButton">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_add_distributionOutgoings" :retain-focus="false" max-width="320px">
            <v-card>
                <v-card-title class="d-flex flex-row flex-wrap py-0">
                    <span>Distribute</span>
                    <span>
                        <v-text-field
                            v-model="amountDistributedOutgoings"
                            class="pa-0 mx-2 mt-6"
                            style="width: 40px"
                        ></v-text-field>
                    </span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                    <v-list rounded>
                        <v-list-item-group color="primary">
                            <v-list-item
                                v-for="(resource, i) in selectResourceReceiverItems"
                                :key="i"
                                @click="addDistributionOutgoings(resource)"
                            >
                                <v-list-item-content>
                                    <v-list-item-title v-text="resource.resourceName"></v-list-item-title>
                                    <v-list-item-subtitle v-text="resource.full_name"></v-list-item-subtitle>
                                    <v-list-item-subtitle v-if='resource.first_class' v-text="'First class: ' + formattedDate(resource.first_class, 'dateAndTime')"></v-list-item-subtitle>
                                    <span v-if="resource.payment && resource.amountPaidReceiver">
                                        <v-list-item-content v-text="'Payment ' + resource.currency + resource.payment.toFixed(2) + ' - Paid ' + resource.currency + resource.amountPaidReceiver.toFixed(2)"></v-list-item-content>
                                    </span>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="ppc_darkgreen darken-1" text @click="dialog_add_distributionOutgoings = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Transaction Dialogs -->
        
        <v-dialog
            v-model="dialogTransactionIncome"
            max-width="900px"
            persistent
        >
            <v-card flat class="d-flex flex-column" :key='renderDialog'>
                <v-card-title class="py-6 ppc_darkgreen darken-1 white--text">Transaction Income</v-card-title>
                <v-card-subtitle class="ppc_darkgreen flex-row flex-wrap darken-1 white--text" v-if="transactionIncome.length > 0">
                    Payer: {{ transactionIncome[0].payerName }} - Price: ${{ transactionIncome[0].totalPriceTransaction.toFixed(2) }} - Paid ${{ transactionIncome[0].amountPaidTransaction.toFixed(2) }}
                     <span v-if="transactionIncome[0].transferFeeIncluded === 1"> - Transfer Fees Included</span>
                     <v-spacer></v-spacer>
                    TransNr: {{ transactionIncome[0].id }}
                    <span v-if="transactionIncome[0].transactionClosed === 0">- Open</span>
                    <span v-if="transactionIncome[0].transactionClosed === 1">- Closed</span>
                </v-card-subtitle>
                <v-card-text>
                    <v-data-table
                        :headers="headersDistributionsTransactionIncome"
                        :items="distributionsIncomeTable"
                        class="ma-2"
                        disable-pagination
                        hide-default-footer
                        dense
                        @click:row="openEditIncome($event)"
                    >
                        <template v-slot:[`item.amountIncome`]="{ item }">
                            <span>{{ item.currencyIncome }} {{ item.amountIncome }}</span>
                        </template>
                        <template v-slot:[`item.amount`]="{ item }">
                            <span>$ {{ item.amount }}</span>
                        </template>
                        <template v-slot:[`item.paidOn`]="{ item }">
                            <span v-html="formattedDate(item.paidOn)"></span>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="d-flex flex-row" style="width: 100%">
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn 
                            class="mt-4"
                            @click="dialogTransactionIncome = false;"
                        >
                            Ok
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogTransactionOutgoings"
            max-width="900px"
            persistent
        >
            <v-card flat class="d-flex flex-column" :key='renderDialog'>
                <v-card-title class="py-6 blue darken-1 white--text">Transaction Outgoings</v-card-title>
                <v-card-subtitle class="d-flex flex-row flex-wrap blue darken-1 white--text" v-if="transactionOutgoing.length > 0">
                    Receiver: {{ transactionOutgoing[0].receiverName }} - Costs: ${{ transactionOutgoing[0].totalPaymentTransactionDollars.toFixed(2) }} + S/.{{ transactionOutgoing[0].totalPaymentTransactionSoles.toFixed(2) }} - Paid ${{ transactionOutgoing[0].amountDollars.toFixed(2) }} (S/.{{ transactionOutgoing[0].amountSoles.toFixed(2) }})
                    <v-spacer></v-spacer>
                    TransNr: {{ transactionOutgoing[0].id }}
                    <span v-if="transactionOutgoing[0].transactionClosed === 0">- Open</span>
                    <span v-if="transactionOutgoing[0].transactionClosed === 1">- Closed</span>
                </v-card-subtitle>
                <v-card-text>
                    <v-data-table
                        :headers="headersDistributionsTransactionOutgoings"
                        :items="distributionsOutgoingsTable"
                        class="ma-2"
                        disable-pagination
                        hide-default-footer
                        dense
                        sort-by="paidOn"
                        @click:row="openEditOutgoings($event)"
                    >
                        <template v-slot:[`item.amount`]="{ item }">
                            <span>{{ item.currency }} {{ item.amount }}</span>
                        </template>
                        <template v-slot:[`item.paidOn`]="{ item }">
                            <span v-html="formattedDate(item.paidOn)"></span>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="d-flex flex-row" style="width: 100%">
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn 
                            class="mt-4"
                            @click="dialogTransactionOutgoings = false;"
                        >
                            Ok
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogValutaYear"
            max-width="800px"
            scrollable
        >
            <v-card
                height="80vh"
            >
                <div class="d-flex flex-row justify-space-between">
                    <v-btn 
                        @click="switchMonthDialogValutaYear('down')"
                        :disabled="tabMonth === 0"
                        class="ma-4"
                    >
                        <v-icon
                            start
                            small
                        >mdi-arrow-left</v-icon>
                        {{ itemsTabMonths[tabMonth-1] }}
                    </v-btn>
                    <v-card-title>{{ dialogValutaYearTitle }} {{ itemsTabMonths[tabMonth] }}</v-card-title>
                    <v-btn 
                        @click="switchMonthDialogValutaYear('up')"
                        :disabled="tabMonth === 11"
                        class="ma-4"
                    >
                        {{ itemsTabMonths[tabMonth+1] }}
                        <v-icon
                            end
                            small
                        >mdi-arrow-right</v-icon>
                    </v-btn>
                </div>
                <v-card-text>
                    <v-data-table
                        :headers="headersValutaMonths"
                        :items="valutaMonth"
                        class="elevation-1 ma-2"
                        disable-pagination
                        hide-default-footer
                        dense
                    >
                        <template v-slot:[`item.amount`]="{ item }">
                            <span v-if="item.amount">{{ item.amount.toFixed(2) }}</span>
                        </template>
                        <template v-slot:[`item.bankTransferFee`]="{ item }">
                            <span v-if="item.bankTransferFee">{{ item.bankTransferFee.toFixed(2) }}</span>
                        </template>
                        <template v-slot:[`item.balance`]="{ item }">
                            <span v-if="item.balance">{{ item.balance.toFixed(2) }}</span>
                        </template>
                        <template v-slot:[`item.total`]="{ item }">
                            <span v-if="item.move==='IN' && item.amount">{{ parseFloat(item.amount).toFixed(2) }}</span>
                            <span v-else-if="item.amount && item.bankTransferFee">{{ (parseFloat(item.amount.toFixed(2)) + parseFloat(item.bankTransferFee.toFixed(2))).toFixed(2) }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogSecurityDeposits"
            max-width="800px"
            scrollable
        >
            <v-card
                height="80vh"
            >
                <v-card-title>Oustanding Security Deposits</v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="headersSecurityDeposits"
                        :items="outstandingDeposits"
                        class="elevation-1 ma-2"
                        disable-pagination
                        hide-default-footer
                        dense
                    >
                        <template v-slot:[`item.clientName`]="{ item }">
                            <v-btn small rounded @click="goClient(item.client_id)">{{ item.clientName }}</v-btn>
                        </template>
                        <template v-slot:[`item.totals`]="{ item }">
                            {{ item.totals.toFixed(2) }}
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>

    import dayjs from 'dayjs';
    import compare from '../mixins/compare';
    import inputRules from '../mixins/inputRules';
    import formattedDate from '../mixins/formattedDate';
    import roundDownToFixed from '../mixins/roundTwoDecimals';

    export default {
        data: () => ({
            nowDate: null,
            nowYear: null,
            cajaChicaDay: null,
            balanceGeneralYear: null,
            tabGeneral: 0,
            itemsTabGeneral: [
                'Caja Chica', 'Transactions', 
            ],
            tabCategoriesTypes: 0,
            tabTransactions: 0,
            tabMonth: 0,
            itemsTabMonths: [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ],
            tabIncomeOutgoing: 0,
            renderDialog: Math.random(),
            // renderTabMonth: Math.random(),
            renderTabCategoriesTypes: Math.random(),
            // Income
            dialogIncomeDelete: false,
            dialogEditIncome: false,
            loadingButton: false,
            selectClients: [],
            headersIncome: [
                { text: 'Payment', value: 'payment', width: "150px", align: 'right' },
                { text: 'Type', value: 'typeName' },
                { text: 'Payer Name', value: 'payerName' },
                { text: 'Comments', value: 'comments' },
                { text: 'Dist', value: 'allDistributed' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            headersIncomeMonth: [
                {
                    text: 'Date',
                    align: 'start',
                    sortable: false,
                    value: 'date',
                    width:  '120px',
                },
                { text: 'Payment', value: 'payment', width: "150px", align: 'right' },
                { text: 'Category', value: 'categoryName' },
                { text: 'Type', value: 'typeName' },
                { text: 'Payer Name', value: 'payerName' },
                { text: 'Methods', value: 'paymentMethodName' },
                { text: 'Comments', value: 'comments' },
                { text: 'Dist', value: 'allDistributed' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            incomeDay: [],
            editedIncomeIndex: -1,
            editedIncomeItem: {
                dateTime: null,
                date: null,
                application_id: null,
                type_id: null,
                client_id: null,
                agency_id: null,
                contact_id: null,
                paymentMethod: null,
                currency: '$',
                amount: null,
                bankTransferFee: null,
                exchangeRate: null,
                obsolete: null,
            },
            defaultIncomeItem: {
                dateTime: null,
                date: null,
                application_id: null,
                type_id: null,
                client_id: null,
                agency_id: null,
                contact_id: null,
                paymentMethod: null,
                currency: '$',
                amount: null,
                bankTransferFee: null,
                exchangeRate: null,
                obsolete: null,
            },
            // Outgoings
            dialogEditOutgoing: false,
            dialogOutgoingDelete: false,
            headersOutgoings: [
                { text: 'Payment', value: 'payment', width: "150px", align: 'right' },
                { text: 'Type', value: 'typeName' },
                { text: 'Receiver Name', value: 'receiverName' },
                { text: 'Comments', value: 'comments' },
                { text: 'Dist', value: 'allDistributed' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            headersOutgoingsMonth: [
                {
                    text: 'Date',
                    align: 'start',
                    sortable: false,
                    value: 'date',
                    width:  '120px',
                },
                { text: 'Payment', value: 'payment', width: "150px", align: 'right' },
                { text: 'Category', value: 'categoryName' },
                { text: 'Type', value: 'typeName' },
                { text: 'Receiver Name', value: 'receiverName' },
                { text: 'Methods', value: 'paymentMethodName' },
                { text: 'Comments', value: 'comments' },
                { text: 'Dist', value: 'allDistributed' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            outgoingsDay: [],
            editedOutgoingIndex: -1,
            editedOutgoingsItem: {
                dateTime: null,
                date: null,
                type_id: null,
                client_id: null,
                contact_id: null,
                paymentMethod: null,
                currency: 'S/.',
                amount: null,
                bankTransferFee: null,
                exchangeRate: null,
                obsolete: null,
                application_appTitle: '',
            },
            defaultOutgoingItem: {
                dateTime: null,
                date: null,
                type_id: null,
                client_id: null,
                contact_id: null,
                paymentMethod: null,
                currency: 'S/.',
                amount: null,
                bankTransferFee: null,
                exchangeRate: null,
                obsolete: null,
            },
            // Gategories Income
            headersCategoriesIncome: [
                {
                    text: 'Name',
                    align: 'start',
                    value: 'name',
                },
            ],
            categoriesIncome: [],
            editedCategoryIncomeIndex: -1,
            editedCategoryIncomeItem: {
                name: '',
                obsolete: null,
            },
            defaultCategoryIncome: {
                name: '',
                obsolete: null,
            },
            // Gategories Outgoings
            headersCategoriesOutgoings: [
                {
                    text: 'Name',
                    align: 'start',
                    value: 'name',
                },
            ],
            categoriesOutgoings: [],
            editedCategoryOutgoingsIndex: -1,
            editedCategoryOutgoingsItem: {
                name: '',
                obsolete: null,
            },
            defaultCategoryOutgoings: {
                name: '',
                obsolete: null,
            },
            // Types Income
            dialogTypeIncome: false,
            dialogTypeIncomeDelete: false,
            headersTypesIncome: [
                {
                    text: 'Name',
                    align: 'start',
                    value: 'name',
                },
                { text: 'Category', value: 'categoryName' },
                { text: 'Payer', value: 'payer' },
                { text: 'Accountable', value: 'accountable' },
                { text: 'Payment Method Variable', value: 'paymentMethod' },
                { text: 'Caja Chica', value: 'showCajaChica' },
                { text: 'Fixed', value: 'fixed' },
                { text: 'Description', value: 'description' },
                { text: 'Used', value: 'used' },
                { text: 'Obsolete', value: 'obsolete' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            typesIncome: [],
            editedTypeIncomeIndex: -1,
            editedTypeIncomeItem: {
                name: '',
                category_id: null,
                paymentMethod: null,
                payer: 'No payer',
                accountable: 1,
                showCajaChica: 1,
                fixed: 0,
                decription: '',
                obsolete: null,
            },
            defaultTypeIncome: {
                name: '',
                category_id: null,
                paymentMethod: null,
                payer: 'No payer',
                accountable: 1,
                showCajaChica: 1,
                fixed: 0,
                decription: '',
                obsolete: null,
            },
            selectPayer: [
                {
                    name: 'No payer',
                    value: 'No payer',
                },
                {
                    name: 'PPC',
                    value: 'PPC',
                },
                {
                    name: 'Contact',
                    value: 'Contact',
                },
                {
                    name: 'Client',
                    value: 'Client',
                },
                {
                    name: 'Client Agency',
                    value: 'Client Agency',
                },
            ],
            // Types Outgoings
            dialogTypeOutgoings: false,
            dialogTypeOutgoingsDelete: false,
            headersTypesOutgoings: [
                {
                    text: 'Name',
                    align: 'start',
                    value: 'name',
                },
                { text: 'Category', value: 'categoryName' },
                { text: 'Receiver', value: 'receiver' },
                { text: 'Accountable', value: 'accountable' },
                { text: 'Payment Method Variable', value: 'paymentMethod' },
                { text: 'Caja Chica', value: 'showCajaChica' },
                { text: 'Fixed', value: 'fixed' },
                { text: 'Description', value: 'description' },
                { text: 'Used', value: 'used' },
                { text: 'Obsolete', value: 'obsolete' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            typesOutgoings: [],
            editedTypeOutgoingsIndex: -1,
            editedTypeOutgoingsItem: {
                name: '',
                category_id: null,
                receiver: 'No receiver',
                contact_id: null,
                paymentMethod: 0,
                accountable: 1,
                showCajaChica: 1,
                fixed: 0,
                decription: '',
                obsolete: null,
            },
            defaultTypeOutgoings: {
                name: '',
                category_id: null,
                receiver: 'No receiver',
                contact_id: null,
                paymentMethod: 0,
                accountable: 1,
                showCajaChica: 1,
                fixed: 0,
                decription: '',
                obsolete: null,
            },
            selectReceiver: [
                {
                    name: 'No receiver',
                    value: 'No receiver',
                },
                {
                    name: 'PPC',
                    value: 'PPC',
                },
                {
                    name: 'Contact',
                    value: 'Contact',
                },
                {
                    name: 'Client',
                    value: 'Client',
                },
                {
                    name: 'Contact Staff',
                    value: 'Contact Staff',
                },
                {
                    name: 'Contact Client Agency',
                    value: 'Contact Client Agency',
                },
                {
                    name: 'Contact Tour Agency',
                    value: 'Contact Tour Agency',
                },
                {
                    name: 'Contact Project',
                    value: 'Contact Project',
                },
                {
                    name: 'Contact Accommodation',
                    value: 'Contact Accommodation',
                },
                {
                    name: 'Contact Teacher',
                    value: 'Contact Teacher',
                },
                {
                    name: 'Fixed Contact',
                    value: 'Fixed Contact',
                },
                {
                    name: 'Application',
                    value: 'Application',
                },
            ],
            incomeMonth: [],
            outgoingsMonth: [],
            currentBalances: [],
            dayIncomeSoles: 0,
            dayIncomeDollars: 0,
            dayOutgoingsSoles: 0,
            dayOutgoingsDollars: 0,
            lastSquareDateTime: 0,
            // filterBalanceGeneral: 'All',
            selectApplicationsPayer: [],
            selectApplicationsPayerItems: [],
            selectResourceReceiver: [],
            selectResourceReceiverItems: [],
            dialogTransactionIncome: false,
            dialogTransactionOutgoings: false,
            transactionsIncome: [],
            transactionsOutgoings: [],
            headersTransactionsIncome: [
                { text: 'Payer Name', align: 'start', value: 'payerName' },
                { text: 'Price USD', value: 'totalPriceTransaction' },
                { text: 'Paid USD', value: 'amountPaidTransaction' },
                { text: 'Closed', value: 'transactionClosed' },
                { text: 'Last Change', value: 'id' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            headersTransactionsOutgoings: [
                { text: 'Receiver name', align: 'start', value: 'receiverName' },
                { text: 'Payment', value: 'totalPaymentTransactionSoles' },
                { text: 'Paid USD (PEN)', value: 'amountSoles' },
                { text: 'Closed', value: 'transactionClosed' },
                { text: 'Last Change', value: 'id' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            openDistributionsIncome: [],
            closedDistributionsIncome: [],
            distributionsTransactionsOutgoings: [],
            allDistributionsOutgoings: [],
            editedApplicationsDistributionIncome: [],
            transactionIncome: [],
            headersDistributionsTransactionIncome: [
                { text: 'Payment', value: 'paidOn' },
                { text: 'Method', value: 'paymentMethodName' },
                { text: 'Income', value: 'amountIncome' },
                { text: 'Transfer Fee', value: 'bankTransferFee' },
                { text: 'Distributed', value: 'amount' },
            ],
            transactionOutgoing: [],
            headersDistributionsTransactionOutgoings: [
                { text: 'Payment', value: 'paidOn' },
                { text: 'Resource', value: 'resourceName' },
                { text: 'Client', value: 'full_name' },
                { text: 'Receiver', value: 'receiverName' },
                { text: 'Method', value: 'paymentMethodName' },
                { text: 'Distributed', value: 'amount' }
            ],
            distributionsIncomeTable: [],
            distributionsIncomeOld: [],
            distributionsIncomeNew: [],
            headersDistributionsIncome: [
                { text: 'Application', value: 'appTitle' },
                { text: 'Price', value: 'totalPrice' },
                { text: 'Distributed', value: 'amount' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            distributionsOutgoingsTable: [],
            distributionsOutgoingsOld: [],
            distributionsOutgoingsNew: [],
            headersDistributionsOutgoings: [
                // { text: 'resId', value: 'resource_id' },
                { text: 'Client', value: 'full_name' },
                { text: 'Payment', value: 'payment' },
                { text: 'Distributed', value: 'amount' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            headersClassesPayment: [
                { text: 'Class', value: 'start' },
                { text: 'Students', value: 'students_short' },
                { text: 'Language', value: 'class_language' },
                { text: 'Group', value: 'class_group' },
                { text: 'Type', value: 'class_type' },
                { text: 'Payment', value: 'payment' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            classesPayment: [],
            classesPaymentOld: [],
            dialog_add_distributionIncome: false,
            dialog_add_distributionOutgoings: false,
            amountDistributedIncome: null,
            amountDistributedOutgoings: null,
            transferFeeIncluded: 0,
            loadingbuttonLoadTransactionsOpen: false,
            loadingbuttonLoadTransactionsClosed: false,
            // totalSoles: null,
            // totalDollars: null,
            selectContactsOrganisations: [],
            loadingCajas: true,
            financialLocked: null,
            lockedFinancialMonth: null,
            financialFirstOpenDay: null,
            valutaYear: [],
            valutaMonth: [],
            dialogValutaYear: false,
            headersValutaMonths: [
                { text: 'Date', value: 'dateTime' },
                { text: 'Move', value: 'move' },
                { text: 'Amount', value: 'amount' },
                { text: 'Bank Transfer Fee', value: 'bankTransferFee', width: '50px' },
                { text: 'Total', value: 'total' },
                { text: 'Balance', value: 'balance' },
            ],
            dialogValutaYearTitle: '',
            outstandingDeposits: [],
            outstandingDepositsTotal: 0,
            dialogSecurityDeposits: false,
            headersSecurityDeposits: [
                { text: 'Client', value: 'clientName' },
                { text: 'Outstanding', value: 'totals' },
            ],
        }),

        mixins: [inputRules, formattedDate, compare, roundDownToFixed],

        computed: {
            loadingApp () {
                return this.$store.getters.loadingApp
            },
            formTitleTypeIncome () {
                return this.editedCategoryIncomeIndex === -1 ? 'New Income Type' : 'Edit Income Type'
            },
            formTitleTypeOutgoings () {
                return this.editedCategoryOutgoingsIndex === -1 ? 'New Outgoings Type' : 'Edit Outgoings Type'
            },
            selectCategoriesIncome () {
                return this.categoriesIncome.filter(function(select_item) {
                    return select_item.obsolete === null;
                })
            },
            selectCategoriesOutgoings () {
                return this.categoriesOutgoings.filter(function(select_item) {
                    return select_item.obsolete === null;
                })
            },
            computedDateFormatted () {
                return this.formattedDate(this.cajaChicaDay)
            },
            distributionsTotalOutgoingsDollar () {
                var distributionsTotalOutgoingsDollar = 0;
                if (this.distributionsOutgoingsNew.length > 0) {
                    for (let index = 0; index < this.distributionsOutgoingsNew.length; index++) {
                        if (this.distributionsOutgoingsNew[index].currency === '$') {
                            console.log(this.distributionsOutgoingsNew[index].amount)
                            distributionsTotalOutgoingsDollar = distributionsTotalOutgoingsDollar + this.distributionsOutgoingsNew[index].amount;
                        } else {
                            distributionsTotalOutgoingsDollar = distributionsTotalOutgoingsDollar + (this.distributionsOutgoingsNew[index].amount / this.$store.state.exchangeRate);
                        }
                    }
                }
                return distributionsTotalOutgoingsDollar.toFixed(2);
            },
            distributionsTotalOutgoingsSoles () {
                var distributionsTotalOutgoingsSoles = 0;
                if (this.distributionsOutgoingsNew.length > 0) {
                    for (let index = 0; index < this.distributionsOutgoingsNew.length; index++) {
                        if (this.distributionsOutgoingsNew[index].currency === '$') {
                            distributionsTotalOutgoingsSoles = distributionsTotalOutgoingsSoles + (this.distributionsOutgoingsNew[index].amount * this.$store.state.exchangeRate);
                        } else {
                            distributionsTotalOutgoingsSoles = distributionsTotalOutgoingsSoles + this.distributionsOutgoingsNew[index].amount;
                        }
                    }
                }
                return distributionsTotalOutgoingsSoles.toFixed(2);
            },
            discountPaymentTeachers () {
                return this.editedOutgoingsItem.discount_payment_teacher * this.distributionsTotalOutgoingsSoles / 100;
            },
        },

        mounted() {
            let breadcrumb = [];
            breadcrumb.push('Financial')
            this.$store.dispatch('breadcrumbs', breadcrumb);
        },

        beforeCreate () {
            if (this.$store.state.accessFinancial === 0) {
                this.$router.push({ name: 'Illegal' })
            }
        },

        created () {
            dayjs.locale("en");
            this.$store.dispatch('setLoadingApp', 1)
            this.initialize();
        },

        methods: {
            async initialize () {
                this.loadingCajas = true;
                if (this.$store.state.accessCajaGrande === 1) {
                    this.itemsTabGeneral = [
                        'Caja Chica', 'Transactions', 'Balance General', 'Categories & Types',
                    ]
                }
                // this.applications = await this.$store.dispatch('getAllApplications');
                this.initFinanciallocked();
                this.reloadLastSquare();
                this.$store.dispatch('getContacts');
                let n = new Date();
                this.nowDate = this.formattedDate(n, 'date');
                this.nowYear =  n.getFullYear();
                this.balanceGeneralYear = this.nowYear;
                this.tabMonth = n.getMonth();
                let organisations = await this.$store.dispatch('getOrganisationsAll');
                for (let index = 0; index < organisations.length; index++) {
                    organisations[index].full_name = organisations[index].name + ' - ' + organisations[index].contactName;
                    organisations[index].id = organisations[index].contact_id;
                }
                this.selectContactsOrganisations = organisations.sort(this.dynamicSort('full_name'));
                this.selectClients = await this.$store.dispatch('getClientsIdName');
                this.selectClients.sort(this.dynamicSort('full_name'));
                await this.$store.dispatch('getLeads');
                this.cajaChicaDay = this.nowDate;
                await this.getData();
                if (this.$route.params.appId && this.$route.params.incomeType) {
                    this.newEditIncome(this.$route.params.appId, this.$route.params.currency, this.$route.params.incomeType, this.$route.params.clientId);
                } else if (this.$route.params.refund) {
                    this.newEditOutgoing(this.$route.params);
                } else if (this.$route.params.securityDeposit && this.$route.params.incomeType) {
                    this.newEditIncome(null, null, this.$route.params.incomeType, this.$route.params.clientId);
                } else if (this.$route.params.securityDeposit && this.$route.params.outgoingType) {
                    this.newEditOutgoing(this.$route.params);
                } else if (this.$route.params.transIdIncome) {
                    this.tabGeneral = 1;
                    await this.openDialogTransactionIncome(this.$route.params.transIdIncome);
                } else if (this.$route.params.transIdOutgoings) {
                    this.tabGeneral = 1;
                    await this.openDialogTransactionOutgoings(this.$route.params.transIdOutgoings);
                } else if (this.$route.params.paymentProfe) {
                    this.tabGeneral = 1;
                    this.tabTransactions = 1;
                    this.$store.dispatch('setLoadingApp', 0);
                    this.newEditOutgoing(this.$route.params.paymentProfe);
                } else if (this.$route.params.paymentFamily) {
                    this.tabGeneral = 1;
                    this.tabTransactions = 1;
                    this.$store.dispatch('setLoadingApp', 0);
                    this.newEditOutgoing(this.$route.params.paymentFamily);
                } else if (this.$route.params.paymentProject) {
                    this.tabGeneral = 1;
                    this.tabTransactions = 1;
                    this.$store.dispatch('setLoadingApp', 0);
                    this.newEditOutgoing(this.$route.params);
                } else if (this.$route.params.paymentAgency) {
                    this.tabGeneral = 1;
                    this.tabTransactions = 1;
                    this.$store.dispatch('setLoadingApp', 0);
                    this.newEditOutgoing(this.$route.params);
                } else if (this.$route.params.income_id) {
                    this.tabGeneral = 1;
                    this.tabTransactions = 1;
                    this.$store.dispatch('setLoadingApp', 0);
                    this.openEditIncome(this.$route.params);
                } else if (this.$route.params.outgoing_id) {
                    this.tabGeneral = 1;
                    this.tabTransactions = 1;
                    this.$store.dispatch('setLoadingApp', 0);
                    this.openEditOutgoings(this.$route.params);
                } else {
                    this.$store.dispatch('setLoadingApp', 0);
                }
            },

            async reloadLastSquare () {
                let tmpLastSquare = await this.$store.dispatch('getLastSquare');
                if(tmpLastSquare.length > 0) {
                    this.lastSquareDateTime = this.formattedDate(tmpLastSquare[0].dateTime, 'dateTime');
                }
            },

            async getData() {
                await this.changeDate(this.cajaChicaDay);
                this.typesIncome = await this.$store.dispatch('getTypesIncome');
                for (let index = 0; index < this.typesIncome.length; index++) {
                    (this.typesIncome[index].obsolete) ? this.typesIncome[index].obsolete = this.formattedDate(this.typesIncome[index].obsolete, 'date') : this.typesIncome[index].obsolete = null;
                }
                this.typesOutgoings = await this.$store.dispatch('getTypesOutgoings');
                for (let index = 0; index < this.typesOutgoings.length; index++) {
                    (this.typesOutgoings[index].obsolete) ? this.typesOutgoings[index].obsolete = this.formattedDate(this.typesOutgoings[index].obsolete, 'date') : this.typesOutgoings[index].obsolete = null;
                }
                this.categoriesIncome = await this.$store.dispatch('getCategoriesIncome');
                for (let index = 0; index < this.categoriesIncome.length; index++) {
                    (this.categoriesIncome[index].obsolete) ? this.categoriesIncome[index].obsolete = this.formattedDate(this.categoriesIncome[index].obsolete, 'date') : this.categoriesIncome[index].obsolete = null;
                }
                this.categoriesOutgoings = await this.$store.dispatch('getCategoriesOutgoings');
                for (let index = 0; index < this.categoriesOutgoings.length; index++) {
                    (this.categoriesOutgoings[index].obsolete) ? this.categoriesOutgoings[index].obsolete = this.formattedDate(this.categoriesOutgoings[index].obsolete, 'date') : this.categoriesOutgoings[index].obsolete = null;
                }
            },

            async getCurrentCajas() {
                // console.log("getCurrentCajas test1")
                this.loadingCajas = true;
                this.currentBalances = [];
                var results = [];
                if (this.tabGeneral === 0) {
                    results = await this.$store.dispatch('getTotalBalancesDay', this.formattedDate(dayjs(this.cajaChicaDay).add(1, 'day'), 'date'));
                } else {
                    // console.log('+++++++++++++++++++++++',this.formattedDate(dayjs(this.cajaChicaDay).add(1, 'day'), 'date'));
                    results = await this.$store.dispatch('getTotalBalancesMonth', this.formattedDate(dayjs(this.cajaChicaDay).add(1, 'day'), 'date'));
                }
                // console.log("getCurrentCajas test2", results)
                for (let index = 0; index < results.length; index++) {
                    let tmp = (Object.values(results[index]))[0];
                    this.currentBalances[index] = tmp;
                }
                // console.log("getCurrentCajas test3")
                // this.renderTabMonth = Math.random();
                results = await this.$store.dispatch('getSecurityDepositsTotals');
                this.outstandingDepositsTotal = 0;
                for (let index = 0; index < results.length; index++) {
                    this.outstandingDepositsTotal += results[index].totals;
                }
                // console.log("getCurrentCajas test4")
                this.outstandingDeposits = results;
                this.loadingCajas = false;
            },

            async getDataDay(day) {
                day = this.formattedDate(day, 'date');
                let month = parseInt(this.formattedDate(day, 'month')) - 1;
                await this.getDataMonth(month);
                this.dayIncomeSoles = 0;
                this.dayIncomeDollars = 0;
                for (let index = 0; index < this.incomeDay.length; index++) {
                    if (!this.incomeDay[index].idSquare) {
                        if (this.incomeDay[index].currency === '$') {
                            this.dayIncomeDollars = this.dayIncomeDollars + this.incomeDay[index].amount;
                        } else {
                            this.dayIncomeSoles = this.dayIncomeSoles + this.incomeDay[index].amount;
                        }
                    }
                }
                this.dayOutgoingsSoles = 0;
                this.dayOutgoingsDollars = 0;
                for (let index = 0; index < this.outgoingsDay.length; index++) {
                    if (!this.outgoingsDay[index].idSquare) {
                        if (this.outgoingsDay[index].currency === '$') {
                            this.dayOutgoingsDollars = this.dayOutgoingsDollars + this.outgoingsDay[index].amount;
                        } else {
                            this.dayOutgoingsSoles = this.dayOutgoingsSoles + this.outgoingsDay[index].amount;
                        }
                    }
                }
            },

            async getDataMonth(month) {
                this.$store.dispatch('setLoadingApp', 1)
                month = month + 1;
                let payload = {
                    month: month,
                    year: this.balanceGeneralYear,
                }
                let incomeScope = [];
                let outgoingsScope = [];
                if (this.tabGeneral === 0) {
                    incomeScope = await this.$store.dispatch('getIncomeDay', this.cajaChicaDay);
                } else {
                    incomeScope = await this.$store.dispatch('getIncomeMonth', payload);
                }
                // console.log("getDataMonth test1")
                for (let index = 0; index < incomeScope.length; index++) {
                    if (incomeScope[index]) {
                        if (this.formattedDate(incomeScope[index].dateTime, 'date') <= this.financialLocked) {
                            incomeScope[index].frozen = true;
                        }
                    }
                    if (!incomeScope[index].amount || incomeScope[index].amount === null) {
                        incomeScope[index].amount = 0;
                    }
                    incomeScope[index].payment = incomeScope[index].currency + incomeScope[index].amount;
                    incomeScope[index].date = this.formattedDate(incomeScope[index].dateTime, 'dayAndDate');
                    incomeScope[index].day = this.formattedDate(incomeScope[index].dateTime, 'date');
                    if (incomeScope[index].client_id !== null) {
                        incomeScope[index].payerName = incomeScope[index].clientName;
                    } else if (incomeScope[index].agency_id !== null) {
                        incomeScope[index].payerName = incomeScope[index].agencyName;
                    } else if (incomeScope[index].contact_id !== null) {
                        incomeScope[index].payerName = incomeScope[index].contactName;
                    } else {
                        incomeScope[index].payerName = ''
                    }
                    if (incomeScope[index].incomeDistributed === null) {
                        incomeScope[index].incomeDistributed = 0;
                    } else {
                        incomeScope[index].incomeDistributed = parseFloat(incomeScope[index].incomeDistributed.toFixed(2));
                    }
                }
                // console.log("getDataMonth test2")
                if (this.tabGeneral === 0) {
                    outgoingsScope = await this.$store.dispatch('getOutgoingsDay', this.cajaChicaDay);
                } else {
                    outgoingsScope = await this.$store.dispatch('getOutgoingsMonth', payload);
                }
                for (let index = 0; index < outgoingsScope.length; index++) {
                    if (outgoingsScope[index]) {
                        if (this.formattedDate(outgoingsScope[index].dateTime, 'date') <= this.financialLocked) {
                            outgoingsScope[index].frozen = true;
                        }
                    }
                    if (outgoingsScope[index].client_id !== null) {
                        outgoingsScope[index].receiverName = outgoingsScope[index].clientName;
                    }
                    if (outgoingsScope[index].contact_id !== null) {
                        outgoingsScope[index].receiverName = outgoingsScope[index].contactName;
                    }
                    if (outgoingsScope[index].application_id !== null) {
                        if (outgoingsScope[index].payerClientName !== null) {
                            outgoingsScope[index].receiverName = outgoingsScope[index].payerClientName;
                        } else {
                            outgoingsScope[index].receiverName = outgoingsScope[index].payerAgencyName;
                        }
                    }
                    if (outgoingsScope[index].type_id === 79 && outgoingsScope[index].client_id !== null) {
                        outgoingsScope[index].payerClient = outgoingsScope[index].client_id;
                    }
                    if (!outgoingsScope[index].amount || outgoingsScope[index].amount === null) {
                        outgoingsScope[index].amount = 0;
                    }
                    outgoingsScope[index].payment = outgoingsScope[index].currency + parseFloat(outgoingsScope[index].amount + outgoingsScope[index].bankTransferFee);
                    outgoingsScope[index].date = this.formattedDate(outgoingsScope[index].dateTime, 'dayAndDate');
                    outgoingsScope[index].day = this.formattedDate(outgoingsScope[index].dateTime, 'date');
                }
                // console.log("getDataMonth test3")
                if (this.tabGeneral === 0) {
                    payload.month = dayjs(this.cajaChicaDay).month() + 1;
                    payload.year = dayjs(this.cajaChicaDay).year();
                }
                this.squares = await this.$store.dispatch('getSquaresMonth', payload);
                for (let index = 0; index < this.squares.length; index++) {
                    if (this.tabGeneral !== 0 || (this.tabGeneral === 0 && this.formattedDate(this.squares[index].dateTime, 'date') === this.cajaChicaDay)) {
                        let square = {
                            idSquare: this.squares[index].id,
                            comments: this.squares[index].comments,
                            dateTime: this.squares[index].dateTime,
                            day: this.formattedDate(this.squares[index].dateTime, 'date'),
                            date: this.formattedDate(this.squares[index].dateTime, 'dayAndDate'),
                            exchangeRate: this.squares[index].exchangeRate,
                            typeName: 'CajaChica Quadrada',
                            receiverName: this.formattedDate(this.squares[index].dateTime, 'time'),
                            payerName: this.formattedDate(this.squares[index].dateTime, 'time'),
                            client_id: null,
                            payerClient: null,
                            paymentMethod: 1,
                        }
                        incomeScope.push(square);
                        outgoingsScope.push(square);
                    }
                    
                }
                // console.log("getDataMonth test4")
                incomeScope.sort(this.dynamicSort('dateTime'));
                if (this.squares.length > 0) {
                    var counterSquares = 0;
                    for (let index = 0; index < incomeScope.length; index++) {
                        if (incomeScope[index].idSquare) {
                            counterSquares = counterSquares + 1;
                            if (this.squares.length === counterSquares) {
                                break;
                            }
                        }
                        if (incomeScope[index]) {
                            if ((incomeScope[index].paymentMethod === 1 || incomeScope[index].paymentMethod === 5) && this.formattedDate(incomeScope[index].dateTime, 'dateTime') < this.lastSquareDateTime) {
                                incomeScope[index].frozen = true;
                            }
                        }
                    }
                }
                // console.log("getDataMonth test5")
                outgoingsScope.sort(this.dynamicSort('dateTime'));
                if (this.squares.length > 0) {
                    counterSquares = 0;
                    for (let index = 0; index < outgoingsScope.length; index++) {
                        if (outgoingsScope[index].idSquare) {
                            counterSquares = counterSquares + 1;
                            if (this.squares.length === counterSquares) {
                                break;
                            }
                        }
                        if (outgoingsScope[index]) {
                            if ((outgoingsScope[index].paymentMethod === 1 || outgoingsScope[index].paymentMethod === 5) && this.formattedDate(outgoingsScope[index].dateTime, 'dateTime') < this.lastSquareDateTime) {
                                outgoingsScope[index].frozen = true;
                                
                            }
                        }
                    }
                }
                if (this.tabGeneral === 0) {
                    this.incomeDay = incomeScope
                } else {
                    this.incomeMonth = incomeScope;
                }
                if (this.tabGeneral === 0) {
                    this.outgoingsDay = outgoingsScope
                } else {
                    this.outgoingsMonth = outgoingsScope;
                }
                // console.log("getDataMonth test6")
                // this.renderTabMonth = Math.random();
                this.getCurrentCajas();
                this.$store.dispatch('setLoadingApp', 0)
            },

            itemRowBackgroundIncome: function (item) {
                if (item.idSquare) {
                    return 'green-background'
                } else if (item.categoryId === 15) {
                    return 'orange-background'
                } else if (item.categoryId === 19) {
                    return 'blue-background'
                } else {
                    return 'white-background'
                }
            },

            itemRowBackgroundOutgoings: function (item) {
                if (item.idSquare) {
                    return 'green-background'
                } else if (item.categoryId === 25) {
                    return 'orange-background'
                } else if (item.categoryId === 15) {
                    return 'blue-background'
                } else {
                    return 'white-background'
                }
            },

            changeGeneralTab(event) {
                if (event === 0) {
                    this.getDataDay(this.cajaChicaDay)
                }
                if (event === 2) {
                    this.getDataMonth(this.tabMonth)
                    // this.filterBalanceGeneral = 'All';
                }
            },

            // Types Income

            selectTypesIncome () {
                if (this.tabGeneral === 0) {
                        return this.typesIncome.filter(function(select_item) {
                        return select_item.obsolete === null && select_item.showCajaChica === 1;
                    })
                } else {
                        return this.typesIncome.filter(function(select_item) {
                        return select_item.obsolete === null;
                    })
                }
            },

            editTypeIncome (item) {
                this.editedTypeIncomeIndex = this.typesIncome.indexOf(item)
                this.editedTypeIncomeItem = Object.assign({}, item)
                this.dialogTypeIncome = true
            },

            async deleteTypeIncome (item) {
                this.editedTypeIncomeIndex = this.typesIncome.indexOf(item)
                this.editedTypeIncomeItem = Object.assign({}, item)
                this.dialogTypeIncomeDelete = true
            },

            async deleteTypeIncomeConfirm () {
                let response = await this.$store.dispatch('deleteTypeIncome', this.editedTypeIncomeItem.id);
                if (response.data.affectedRows === 1) {
                    this.$store.dispatch('successMessage', 'Type deleted');
                } else {
                    this.$store.dispatch('errorMessage', 'Something went wrong');
                }
                await this.getData();
                this.closeDeleteTypeIncome()
            },

            setObsoleteTypeIncome(event) {
                if (event) {
                    this.editedTypeIncomeItem.obsolete = dayjs().format("YYYY-MM-DD")
                } else {
                    this.editedTypeIncomeItem.obsolete = null
                }
            },

            closeTypeIncome () {
                this.dialogTypeIncome = false
                this.$nextTick(() => {
                    this.editedTypeIncomeItem = Object.assign({}, this.defaultTypeIncome)
                    this.editedTypeIncomeIndex = -1
                })
            },

            closeDeleteTypeIncome () {
                this.dialogTypeIncomeDelete = false
                this.$nextTick(() => {
                this.editedTypeIncomeItem = Object.assign({}, this.defaultTypeIncome)
                    this.editedTypeIncomeIndex = -1
                })
            },

            async saveTypeIncome () {
                if(this.$refs.dialogTypeIncomeForm.validate()){
                    let tmp = this.editedTypeIncomeItem.name;
                    let doubleName = this.typesIncome.filter(function(select_item) {
                            return select_item.name === tmp;
                        }
                    )
                    if (doubleName.length > 0 && this.editedTypeIncomeIndex !== this.typesIncome.indexOf(doubleName[0])) {
                        this.$store.dispatch('errorMessage', 'The name "' + this.editedTypeIncomeItem.name + '" is already in use!');
                        return;
                    } else {
                        await this.$store.dispatch('insertTypeIncome', this.editedTypeIncomeItem);
                        await this.getData();
                    }
                    this.closeTypeIncome()
                }
            },

            // Types Outgoings

            selectTypesOutgoings () {
                if (this.tabGeneral === 0) {
                        return this.typesOutgoings.filter(function(select_item) {
                        return select_item.obsolete === null && select_item.showCajaChica === 1;
                    })
                } else {
                        return this.typesOutgoings.filter(function(select_item) {
                        return select_item.obsolete === null;
                    })
                }
            },

            editTypeOutgoings (item) {
                this.editedTypeOutgoingsIndex = this.typesOutgoings.indexOf(item)
                this.editedTypeOutgoingsItem = Object.assign({}, item)
                this.dialogTypeOutgoings = true
            },

            async deleteTypeOutgoings (item) {
                this.editedTypeOutgoingsIndex = this.typesOutgoings.indexOf(item)
                this.editedTypeOutgoingsItem = Object.assign({}, item)
                this.dialogTypeOutgoingsDelete = true
            },

            async deleteTypeOutgoingsConfirm () {
                let response = await this.$store.dispatch('deleteTypeOutgoings', this.editedTypeOutgoingsItem.id);
                if (response.data.affectedRows === 1) {
                    this.$store.dispatch('successMessage', 'Type deleted');
                } else {
                    this.$store.dispatch('errorMessage', 'Something went wrong');
                }
                await this.getData();
                this.closeDeleteTypeOutgoings()
            },

            setObsoleteTypeOutgoings(event) {
                if (event) {
                    this.editedTypeOutgoingsItem.obsolete = dayjs().format("YYYY-MM-DD")
                } else {
                    this.editedTypeOutgoingsItem.obsolete = null
                }
                
            },

            closeTypeOutgoings () {
                this.dialogTypeOutgoings = false
                this.editedTypeOutgoingsItem = Object.assign({}, this.defaultTypeOutgoings)
                this.editedTypeOutgoingsIndex = -1
            },

            closeDeleteTypeOutgoings () {
                this.dialogTypeOutgoingsDelete = false;
                this.editedTypeOutgoingsItem = Object.assign({}, this.defaultTypeOutgoings)
                this.editedTypeOutgoingsIndex = -1
            },

            async saveTypeOutgoings () {
                if(this.$refs.dialogTypeOutgoingsForm.validate()){
                    let tmp = this.editedTypeOutgoingsItem.name;
                    let doubleName = this.typesOutgoings.filter(function(select_item) {
                        return select_item.name === tmp;
                    })
                    if (doubleName.length > 0 && this.editedTypeOutgoingsIndex !== this.typesOutgoings.indexOf(doubleName[0])) {
                        this.$store.dispatch('errorMessage', 'The name "' + this.editedTypeOutgoingsItem.name + '" is already in use!');
                        return;
                    } else {
                        await this.$store.dispatch('insertTypeOutgoings', this.editedTypeOutgoingsItem);
                        await this.getData();
                    }
                    this.closeTypeOutgoings()
                }
            },

            // Income Edit

            async openDialogTransactionIncome (transIdIncome) {
                this.transactionIncome = await this.$store.dispatch('getTransactionIncomeId', transIdIncome)
                this.distributionsIncomeTable = await this.$store.dispatch('getDistributionsIncomeTransactionId', transIdIncome)
                if (this.transactionIncome.length === 0 || this.distributionsIncomeTable === 0) {
                    this.dialogTransactionIncome = false;
                } else {
                    this.dialogTransactionIncome = true;
                }
            },

            async deleteEditedApplicationsDistributionIncome (id, transIdIncome) {
                await this.$store.dispatch('deleteDistributionIncomeId', id);
                console.log(id, transIdIncome)
                this.$store.dispatch('successMessage', 'Distribution removed');
                await this.getTransactionsIncome();
                await this.openDialogTransactionIncome(transIdIncome);
            },

            async editIncome (item) {
                this.$store.dispatch('setLoadingApp', 1)
                if (this.tabGeneral === 0) {
                    this.editedIncomeIndex = this.incomeDay.indexOf(item)
                } else {
                    this.editedIncomeIndex = this.incomeMonth.indexOf(item)
                }
                this.editedIncomeItem = Object.assign({}, item);
                this.distributionsIncomeNew = await this.$store.dispatch('getDistributionsIncomeId', this.editedIncomeItem.id);
                this.distributionsIncomeOld = JSON.parse(JSON.stringify(this.distributionsIncomeNew));
                if (this.editedIncomeItem.client_id) {
                    this.transferFeeIncluded = 0;
                    if (this.editedIncomeItem.accountable === 1) {
                        this.selectApplicationsPayer = await this.$store.dispatch('getApplicationsPayerClientId', this.editedIncomeItem.client_id);
                        console.log(this.editedIncomeItem.client_id)
                    }
                }
                if (this.editedIncomeItem.agency_id) {
                    this.transferFeeIncluded = this.getTransferFeeIncluded(this.editedIncomeItem.agency_id);
                    if (this.editedIncomeItem.accountable === 1) {
                        this.selectApplicationsPayer = await this.$store.dispatch('getApplicationsPayerAgencyId', this.editedIncomeItem.agency_id);
                    }
                }
                this.createApplicationsPayerItems();
                this.dialogEditIncome = true;
                this.$store.dispatch('setLoadingApp', 0);
            },

            async newEditIncome (appId, currency, incomeType, clientId) {
                console.log('newEditIncome', appId, currency, incomeType, clientId);
                if (this.formattedDate(new Date(), 'date') <= this.financialLocked) {
                    this.$store.dispatch('errorMessage', 'Caja closed');
                    return;
                }
                if (appId !== null) {
                    this.tabGeneral = 1;
                    this.distributionsIncomeOld = [];
                    this.distributionsIncomeNew = [];
                    let app = await this.$store.dispatch('getApplicationId', appId);
                    if (app.payerClient !== null) {
                        this.editedIncomeItem.client_id = app.payerClient;
                        this.editedIncomeItem.paymentMethod = 2;
                        this.transferFeeIncluded = 0;
                        this.selectApplicationsPayer = await this.$store.dispatch('getApplicationsPayerClientId', this.editedIncomeItem.client_id);
                    } else if (app.payerAgency !== null) {
                        this.editedIncomeItem.agency_id = app.payerAgency;
                        this.editedIncomeItem.paymentMethod = 4;
                        this.transferFeeIncluded = this.getTransferFeeIncluded(this.editedIncomeItem.agency_id);
                        this.selectApplicationsPayer = await this.$store.dispatch('getApplicationsPayerAgencyId', this.editedIncomeItem.agency_id);
                    }
                    this.createApplicationsPayerItems();
                    if (currency === 'dollars') {
                        this.editedIncomeItem.currency = '$';
                        this.editedIncomeItem.amount = (parseFloat(app.totalPrice) - parseFloat(app.amountPaidApplication)).toFixed(2);
                    } else {
                        this.editedIncomeItem.currency = 'S/.';
                        this.editedIncomeItem.amount = ((parseFloat(app.totalPrice) - parseFloat(app.amountPaidApplication))*parseFloat(app.exchangeRate)).toFixed(2);
                    }
                    this.editedIncomeItem.type_id = incomeType;
                    this.amountDistributedIncome = this.editedIncomeItem.amount;
                    await this.addDistributionIncome(app);
                } else if (incomeType !== null) {
                    this.editedIncomeItem.type_id = incomeType;
                    this.editedIncomeItem.client_id = clientId;
                    this.editedIncomeItem.accountable = 0;
                    this.tabGeneral = 1;
                }
                this.editedIncomeItem.setAllDistributed = 0;
                this.editedIncomeItem.transaction_id = null;
                this.editedIncomeItem.dateTime = this.formattedDate(new Date(), 'dateTime');
                this.dialogEditIncome = true;
            },

            closeEditIncome () {
                this.dialogEditIncome = false;
                this.editedIncomeItem = Object.assign({}, this.defaultIncomeItem);
                this.editedIncomeIndex = -1;
                this.$refs.formIncome.reset();
                this.distributionsIncomeOld = [];
                this.distributionsIncomeNew = [];
                this.loadingButton = false;
            },

            deleteIncome (item) {
                this.editedIncomeIndex = this.incomeDay.indexOf(item)
                this.editedIncomeItem = Object.assign({}, item)
                this.dialogIncomeDelete = true
            },

            closeDeleteIncome () {
                this.editedIncomeItem = Object.assign({}, this.defaultIncomeItem);
                this.editedIncomeIndex = -1;
                this.dialogIncomeDelete = false;
                this.loadingButton = false;
            },

            async deleteIncomeConfirm () {
                this.loadingButton = true;
                if (this.editedIncomeItem.idSquare) {
                    await this.$store.dispatch('deleteSquare', this.editedIncomeItem.idSquare);
                    this.reloadLastSquare();
                } else {
                    await this.$store.dispatch('deleteIncome', this.editedIncomeItem.id);
                }
                this.cajaChicaDay = this.formattedDate(this.editedIncomeItem.dateTime, 'date');
                this.closeDeleteIncome();
                await this.getDataDay(this.cajaChicaDay);
                this.transactionsIncome = [];
            },

            async choosePayer(payer) {
                if (payer === 'client') {
                    this.editedIncomeItem.agency_id = null;
                    this.editedIncomeItem.contact_id = null;
                    this.transferFeeIncluded = 0;
                }
                if (payer === 'agency') {
                    this.editedIncomeItem.contact_id = null;
                    this.editedIncomeItem.client_id = null;
                    this.transferFeeIncluded = this.getTransferFeeIncluded(this.editedIncomeItem.agency_id);
                }
                if (payer === 'contact') {
                    this.editedIncomeItem.agency_id = null;
                    this.editedIncomeItem.client_id = null;
                    this.transferFeeIncluded = 0;
                }
                if (this.editedIncomeItem.client_id && this.editedIncomeItem.accountable === 1) {
                    this.selectApplicationsPayer = await this.$store.dispatch('getApplicationsPayerClientId', this.editedIncomeItem.client_id);
                    this.createApplicationsPayerItems();
                }
                if (this.editedIncomeItem.agency_id && this.editedIncomeItem.accountable === 1) {
                    this.selectApplicationsPayer = await this.$store.dispatch('getApplicationsPayerAgencyId', this.editedIncomeItem.agency_id);
                    this.createApplicationsPayerItems();
                }
                // this.renderDialog = Math.random();
            },

            async getTransactionsIncome(scope) {
                if (scope ===  'all') {
                    this.loadingbuttonLoadTransactionsClosed = true;
                    this.transactionsIncome = await this.$store.dispatch('getAllTransactionsIncome');
                    this.loadingbuttonLoadTransactionsClosed = false;
                } else {
                    this.loadingbuttonLoadTransactionsOpen = true;
                    this.transactionsIncome = await this.$store.dispatch('getOpenTransactionsIncome');
                    this.loadingbuttonLoadTransactionsOpen = false;
                }
            },

            async openAddDistributionsIncome () {
                this.createApplicationsPayerItems();
                if (!this.editedIncomeItem.bankTransferFee) {
                    this.editedIncomeItem.bankTransferFee = 0;
                }
                this.amountDistributedIncome = 0;
                if (!this.distributionsTotalIncome) {
                    this.distributionsTotalIncome = 0;
                }
                if (this.transferFeeIncluded || this.transferFeeIncluded === 1) {
                    this.amountDistributedIncome = parseFloat(this.editedIncomeItem.amount) + parseFloat(this.editedIncomeItem.bankTransferFee) - parseFloat(this.distributionsTotalIncome);
                } else {
                    this.amountDistributedIncome = parseFloat(this.editedIncomeItem.amount) - parseFloat(this.distributionsTotalIncome);
                }
                this.dialog_add_distributionIncome = true;
            },

            createApplicationsPayerItems() {
                this.selectApplicationsPayerItems = [];
                for (let index = 0; index < this.selectApplicationsPayer.length; index++) {
                    if (this.selectApplicationsPayer[index].amountPaidApplication === null) {
                        this.selectApplicationsPayer[index].amountPaidApplication = 0;
                    }
                    if (this.distributionsIncomeNew.map(function(e) { return e.application_id; }).indexOf(this.selectApplicationsPayer[index].id) === -1 && (Math.abs(parseFloat(this.selectApplicationsPayer[index].totalPrice) - parseFloat(this.selectApplicationsPayer[index].amountPaidApplication)).toFixed(2) > 0.01)) {
                        this.selectApplicationsPayerItems.push(this.selectApplicationsPayer[index]);
                    }
                }
            },

            addDistributionIncome(distribution) {
                if (!this.amountDistributedIncome > 0 || !this.amountDistributedIncome) {
                    this.$store.dispatch('errorMessage', 'Please insert a amount to ditribute');
                    return;
                }
                distribution.application_id = distribution.id;
                distribution.income_id = this.editedIncomeItem.id;
                if (this.editedIncomeItem.currency === 'S/.') {
                    this.amountDistributedIncome = this.amountDistributedIncome / this.$store.state.exchangeRate;
                }
                if (this.dialog_add_distributionIncome) {
                    distribution.amount = this.amountDistributedIncome;
                } else {
                    distribution.amount = parseFloat(distribution.totalPrice) - parseFloat(distribution.amountPaidApplication);
                }
                distribution.amount = Number(distribution.amount);
                let posPayer = this.selectApplicationsPayer.map(function(e) { return e.id; }).indexOf(distribution.application_id);
                this.selectApplicationsPayer[posPayer].amountPaidApplication = this.selectApplicationsPayer[posPayer].amountPaidApplication + distribution.amount;
                this.distributionsIncomeNew.push(distribution);
                console.log(this.distributionsIncomeNew);
                this.dialog_add_distributionIncome = false;
            },

            async deleteDistributionIncome (event) {
                let pos = this.distributionsIncomeNew.map(function(e) { return e.id; }).indexOf(event.id);
                let posPayer = this.selectApplicationsPayer.map(function(e) { return e.id; }).indexOf(event.application_id);
                this.selectApplicationsPayer[posPayer].amountPaidApplication = this.selectApplicationsPayer[posPayer].amountPaidApplication - event.amount;
                if (pos > -1) {
                    this.distributionsIncomeNew.splice(pos, 1);
                }
            },

            async saveIncome() {
                this.nowDate = this.formattedDate(new Date(), 'date');
                if(this.$refs.formIncome.validate()) {
                    if ((this.editedIncomeItem.paymentMethod === 1 || this.editedIncomeItem.paymentMethod === 5) && this.lastSquareDateTime > this.formattedDate(this.editedIncomeItem.dateTime, 'dateTime')) {
                        this.$store.dispatch('errorMessage', 'Caja Chica already closed for that date');
                    } else {
                        this.loadingButton = true;
                        if (this.editedIncomeItem.currency === '$' || this.editedIncomeItem.currency === 'S/.') {
                            this.editedIncomeItem.setAllDistributed = 0;
                        }
                        if (this.tabGeneral === 0) {
                            this.editedIncomeItem.paymentMethod = 1;
                        }
                        if (this.editedIncomeItem.type_id === 13 || this.editedIncomeItem.type_id === 5) {
                            this.editedIncomeItem.paymentMethod = 5;
                        }
                        if (this.editedIncomeItem.bankTransferFee === null) {
                            this.editedIncomeItem.bankTransferFee = 0;
                        }
                        if (this.editedIncomeItem.dateTime) {
                            if (this.formattedDate(this.editedIncomeItem.dateTime, 'date') === this.nowDate) {
                                this.editedIncomeItem.dateTime = this.formattedDate(new Date(), 'dateTime');
                            } else {
                                this.editedIncomeItem.dateTime = this.formattedDate(this.editedIncomeItem.dateTime, 'dateTime');
                            }
                        } else {
                            this.editedIncomeItem.dateTime = this.formattedDate(new Date(), 'dateTime');
                        }
                        this.cajaChicaDay = this.formattedDate(this.editedIncomeItem.dateTime, 'date');
                        this.editedIncomeItem.exchangeRate = this.$store.state.exchangeRate;
                        let newIncomeId = await this.$store.dispatch('insertIncome', this.editedIncomeItem);
                        for (let index = 0; index < this.distributionsIncomeOld.length; index++) {
                            if (this.distributionsIncomeNew.map(function(e) { return e.id; }).indexOf(this.distributionsIncomeOld[index].id) === -1) {
                                await this.$store.dispatch('deleteDistributionIncomeId', this.distributionsIncomeOld[index].id);
                            }
                        }
                        for (let index = 0; index < this.distributionsIncomeNew.length; index++) {
                            if (newIncomeId === 0) {
                                this.distributionsIncomeNew[index].income_id = this.editedIncomeItem.id;
                            } else {
                                this.distributionsIncomeNew[index].income_id = newIncomeId;
                            }
                            let transaction_id = new Date();
                            this.distributionsIncomeNew[index].transaction_id = transaction_id.getTime();
                            this.distributionsIncomeNew[index].id = null;
                            await this.$store.dispatch('insertDistributionIncome', this.distributionsIncomeNew[index]);
                        }
                        this.balanceGeneralYear = this.formattedDate(this.editedIncomeItem.dateTime, 'year');
                        this.tabMonth = parseInt(this.formattedDate(this.editedIncomeItem.dateTime, 'month')) - 1;
                        this.closeEditIncome();
                        await this.getDataDay(this.cajaChicaDay);
                        this.transactionsIncome = [];
                    }
                }
            },
            
            changeIncomeType() {
                let tmp = this.editedIncomeItem.type_id;
                this.editedIncomeItem.accountable = 0;
                this.editedIncomeItem.fixedPaymentMethod = 0;
                if (tmp !== null && tmp) {
                    let typesIncome = this.typesIncome.filter(function(select_item) {
                        return select_item.id === tmp;
                    })[0];
                    this.editedIncomeItem.payer = typesIncome.payer;
                    this.editedIncomeItem.accountable = typesIncome.accountable;
                    if (typesIncome.paymentMethod) {
                        this.editedIncomeItem.paymentMethod = typesIncome.paymentMethod;
                        this.editedIncomeItem.fixedPaymentMethod = 1;
                    }
                    if (tmp === 22) {
                        this.editedIncomeItem.currency = 'S/.'
                    }
                    console.log(tmp)
                    if (tmp === 29 || tmp === 30) {
                        this.editedIncomeItem.currency = '$'
                    }
                }
            },

            async openEditIncome (event) {
                this.balanceGeneralYear = this.formattedDate(event.paidOn, 'year');
                // this.tabMonth = parseInt(this.formattedDate(event.paidOn, 'month')) - 1;
                await this.getDataDay(event.paidOn)
                let tmp = event.income_id;
                let item = this.incomeMonth.filter(function(select_item) {
                    return select_item.id === tmp;
                })[0]
                this.editIncome(item);
                this.dialogTransactionIncome = false;
                this.$store.dispatch('saveDataForReport', );
            },

            // Outgoings Edit

            async openDialogTransactionOutgoings (transIdOutgoings) {
                Promise.all([this.transactionOutgoing = await this.$store.dispatch('getTransactionOutgoingsId', transIdOutgoings), this.distributionsOutgoingsTable = await this.$store.dispatch('getDistributionsOutgoingsTransactionId', transIdOutgoings)]).then(() => {
                    if (this.transactionOutgoing.length === 0 || this.distributionsOutgoingsTable === 0) {
                        this.dialogTransactionOutgoings = false;
                    } else {
                        this.dialogTransactionOutgoings = true;
                    }
                })
            },

            async editOutgoing (item) {
                this.$store.dispatch('setLoadingApp', 1)
                if (this.tabGeneral === 0) {
                    this.editedOutgoingIndex = this.outgoingsDay.indexOf(item)
                } else {
                    this.editedOutgoingIndex = this.outgoingsMonth.indexOf(item)
                }
                this.editedOutgoingsItem = Object.assign({}, item)
                this.distributionsOutgoingsNew = await this.$store.dispatch('getDistributionsOutgoingId', this.editedOutgoingsItem.id);
                this.distributionsOutgoingsOld = JSON.parse(JSON.stringify(this.distributionsOutgoingsNew));
                // console.log('editOutgoing test1', this.editedOutgoingsItem.contact_id);
                if (this.editedOutgoingsItem.contact_id) {
                    console.log('editOutgoing test2');
                    this.selectResourceReceiver = await this.$store.dispatch('getResourcesReceiverContactId', this.editedOutgoingsItem.contact_id);
                    console.log('editOutgoing test3');
                }
                this.classesPayment = [];
                this.classesPaymentOld = [];
                if (this.editedOutgoingsItem.type_id === 12 || this.editedOutgoingsItem.type_id === 13 || this.editedOutgoingsItem.type_id === 32) {
                    this.classesPayment = await this.$store.dispatch('getClassesOutgoingId', this.editedOutgoingsItem.id);
                    this.classesPaymentOld = JSON.parse(JSON.stringify(this.classesPayment));
                    if (this.classesPayment.length === 0) {
                        this.$store.dispatch('warningMessage', 'No classes to pay');
                    } 
                }
                this.dialogEditOutgoing = true;
                this.$store.dispatch('setLoadingApp', 0);
            },

            async newEditOutgoing(input) {
                if (this.formattedDate(new Date(), 'date') <= this.financialLocked) {
                    this.$store.dispatch('errorMessage', 'Caja closed');
                    return;
                }
                this.editedOutgoingsItem.dateTime = this.formattedDate(new Date(), 'dateTime');
                this.distributionsOutgoingsNew = [];
                this.distributionsOutgoingsOld = [];
                var distribution = {};
                this.editedOutgoingsItem.currency = '$';
                if (input) {
                    if (input.securityDeposit) {
                        this.editedOutgoingsItem.type_id = input.outgoingType;
                        this.editedOutgoingsItem.accountable = 0;
                        this.editedOutgoingsItem.paymentMethod = 2;
                        this.editedOutgoingsItem.currency = '$';
                        this.editedOutgoingsItem.client_id = input.clientId;
                    }
                    else if (input.teacher) {
                        this.editedOutgoingsItem.teacher_id = input.teacher;
                        this.editedOutgoingsItem.currency = 'S/.';
                        this.editedOutgoingsItem.accountable = 1;
                        this.editedOutgoingsItem.paymentMethod = 4;
                        this.editedOutgoingsItem.discount_payment_teacher = input.discountPayment;
                        this.editedOutgoingsItem.correction = input.correction;
                        this.editedOutgoingsItem.comments_correction = input.comments_correction;
                        if (input.classes.length > 0) {
                            this.editedOutgoingsItem.contact_id = input.contactId;
                            if (input.language === 'English') {
                                this.editedOutgoingsItem.type_id = 13;
                            } else if (input.language === 'Spanish') {
                                this.editedOutgoingsItem.type_id = 12;
                            } else {
                                this.editedOutgoingsItem.type_id = 32;
                            }
                            this.classesPayment = JSON.parse(JSON.stringify(input.classes));
                            this.classesPaymentOld = [];
                            this.prepareClassesNotPaid(null);
                        }
                        this.editedOutgoingsItem.receiver = 'Contact Teacher'
                    }
                    else if (input.family) {
                        this.editedOutgoingsItem.accountable = 1;
                        distribution = {}
                        this.editedOutgoingsItem.currency = 'S/.';
                        this.editedOutgoingsItem.type_id = 4;
                        this.editedOutgoingsItem.contact_id = input.contact_id;
                        this.selectResourceReceiver = await this.$store.dispatch('getResourcesReceiverContactId', this.editedOutgoingsItem.contact_id);
                        let array = input.resources;
                        for (let index = 0; index < array.length; index++) {
                            distribution.outgoing_id = null;
                            distribution.resource_id = array[index].id;
                            distribution.resourceName = array[index].resourceName;
                            distribution.full_name = array[index].full_name;
                            distribution.payment = array[index].toPayNow;
                            distribution.amount = distribution.payment;
                            distribution.currency = array[index].currency;
                            distribution.exchangeRate = array[index].exchangeRate;
                            this.distributionsOutgoingsNew.push(distribution);
                            let posReceiver = this.selectResourceReceiver.map(function(e) { return e.id; }).indexOf(distribution.resource_id);
                            this.selectResourceReceiver[posReceiver].amountPaidReceiver = this.selectResourceReceiver[posReceiver].amountPaidReceiver + distribution.amount
                            distribution = {};
                        }
                        this.editedOutgoingsItem.receiver = 'Contact Accommodation'
                        this.editedOutgoingsItem.amount = input.totalPayment;
                    }
                    else if (input.paymentProject) {
                        this.editedOutgoingsItem.accountable = 1;
                        this.editedOutgoingsItem.currency = '$';
                        distribution = {};
                        distribution.outgoing_id = null;
                        this.editedOutgoingsItem.type_id = 14;
                        this.editedOutgoingsItem.contact_id = input.resources[0].contact_id;
                        this.selectResourceReceiver = await this.$store.dispatch('getResourcesReceiverContactId', this.editedOutgoingsItem.contact_id);
                        let array = input.resources;
                        for (let index = 0; index < array.length; index++) {
                            distribution.resource_id = array[index].id;
                            distribution.resourceName = array[index].resourceName;
                            distribution.full_name = array[index].full_name;
                            distribution.payment = array[index].payment;
                            distribution.amount = distribution.payment;
                            distribution.currency = array[index].currency;
                            distribution.exchangeRate = array[index].exchangeRate;
                            this.distributionsOutgoingsNew.push(distribution);
                            let posReceiver = this.selectResourceReceiver.map(function(e) { return e.id; }).indexOf(distribution.resource_id);
                            this.selectResourceReceiver[posReceiver].amountPaidReceiver = this.selectResourceReceiver[posReceiver].amountPaidReceiver + distribution.amount
                            distribution = {};
                        }
                        this.editedOutgoingsItem.receiver = 'Contact Project'
                        this.editedOutgoingsItem.amount = input.paymentProject;
                    }
                    else if (input.paymentAgency) {
                        this.editedOutgoingsItem.accountable = 1;
                        if (input.payInDollars) {
                            this.editedOutgoingsItem.currency = '$';
                        } else {
                            this.editedOutgoingsItem.currency = 'S/.';
                        }
                        distribution = {};
                        distribution.outgoing_id = null;
                        this.editedOutgoingsItem.type_id = 7;
                        this.editedOutgoingsItem.contact_id = input.resources[0].contact_id;
                        this.selectResourceReceiver = await this.$store.dispatch('getResourcesReceiverContactId', this.editedOutgoingsItem.contact_id);
                        let array = input.resources;
                        for (let index = 0; index < array.length; index++) {
                            distribution.resource_id = array[index].id;
                            distribution.resourceName = array[index].resourceName;
                            distribution.full_name = array[index].full_name;
                            distribution.payment = array[index].payment;
                            distribution.amount = array[index].paymentOpen;
                            distribution.currency = array[index].currency;
                            distribution.exchangeRate = array[index].exchangeRate;
                            this.distributionsOutgoingsNew.push(distribution);
                            let posReceiver = this.selectResourceReceiver.map(function(e) { return e.id; }).indexOf(distribution.resource_id);
                            this.selectResourceReceiver[posReceiver].amountPaidReceiver = this.selectResourceReceiver[posReceiver].amountPaidReceiver + distribution.amount
                            distribution = {};
                        }
                        this.editedOutgoingsItem.receiver = 'Contact Tour Agency'
                        this.editedOutgoingsItem.amount = input.paymentAgency;
                        console.log('newEditOutgoing', this.editedOutgoingsItem.contact_id, this.selectResourceReceiver)
                    }
                    else if (input.refund) {
                        this.editedOutgoingsItem.currency = input.currency;
                        this.editedOutgoingsItem.accountable = 0;
                        this.editedOutgoingsItem.paymentMethod = 2;
                        this.editedOutgoingsItem.type_id = input.outgoingType;
                        this.editedOutgoingsItem.application_id = input.appId;
                        this.editedOutgoingsItem.receiver = 'Application';
                        this.tabGeneral = 1;
                        this.checkAppNr();
                    }
                }
                this.editedOutgoingsItem.setAllDistributed = 0;
                this.editedOutgoingsItem.transaction_id = null;
                this.editedOutgoingsItem.dateTime = this.formattedDate(new Date(), 'dateTime');
                this.dialogEditOutgoing = true;
            },

            closeEditOutgoing () {
                this.dialogEditOutgoing = false;
                this.editedOutgoingsItem = Object.assign({}, this.defaultOutgoingItem);
                this.editedOutgoingIndex = -1;
                this.distributionsOutgoingsNew = [];
                this.distributionsOutgoingsOld = [];
                this.classesPayment = [];
                this.$refs.formOutgoing.reset();
                this.loadingButton = false;
            },

            deleteOutgoing (item) {
                this.editedOutgoingIndex = this.outgoingsDay.indexOf(item)
                this.editedOutgoingsItem = Object.assign({}, item)
                this.dialogOutgoingDelete = true;
            },

            closeDeleteOutgoing () {
                this.editedOutgoingsItem = Object.assign({}, this.defaultOutgoingItem);
                this.editedOutgoingIndex = -1;
                this.dialogOutgoingDelete = false;
                this.loadingButton = false;
            },

            async deleteOutgoingConfirm () {
                this.loadingButton = true;
                if (this.editedOutgoingsItem.idSquare) {
                    await this.$store.dispatch('deleteSquare', this.editedOutgoingsItem.idSquare);
                    this.reloadLastSquare();
                } else {
                    await this.$store.dispatch('deleteOutgoing', this.editedOutgoingsItem.id);
                }
                this.cajaChicaDay = this.formattedDate(this.editedOutgoingsItem.dateTime, 'date');
                this.closeDeleteOutgoing();
                await this.getDataDay(this.cajaChicaDay);
                this.transactionsOutgoings = [];
            },

            async chooseReceiver(receiver) {
                if (receiver === 'client') {
                    this.editedOutgoingsItem.contact_id = null;
                }
                if (receiver === 'contact') {
                    this.editedOutgoingsItem.client_id = null;
                    if (this.editedOutgoingsItem.type_id === 12 || this.editedOutgoingsItem.type_id === 13 || this.editedOutgoingsItem.type_id === 32) {
                        let tmp = this.editedOutgoingsItem.contact_id;
                        let teacherId = this.$store.state.teachers.filter(function(select_item) {
                            return select_item.contact_id === tmp;
                        })[0].id;
                        this.selectClassesFinishedNotPaid(teacherId);
                    }
                }
                if (this.editedOutgoingsItem.contact_id) {
                    if (this.editedOutgoingsItem.type_id === 12 || this.editedOutgoingsItem.type_id === 13 || this.editedOutgoingsItem.type_id === 32) {
                        let contactId = this.editedOutgoingsItem.contact_id;
                        let teacher = this.$store.state.teachers.filter(function(select_item) {
                            return select_item.contact_id === contactId;
                        })[0];
                        this.editedOutgoingsItem.discount_payment_teacher = teacher.discount_payment;
                    } else {
                        this.selectResourceReceiver = await this.$store.dispatch('getResourcesReceiverContactId', this.editedOutgoingsItem.contact_id);
                    }
                }
            },

            async loadTransactionsOutgoings(scope) {
                if (scope ===  'all') {
                    this.loadingbuttonLoadTransactionsClosed = true;
                    this.transactionsOutgoings = await this.$store.dispatch('getAllTransactionsOutgoings');
                    this.loadingbuttonLoadTransactionsClosed = false;
                } else {
                    this.loadingbuttonLoadTransactionsOpen = true;
                    this.transactionsOutgoings = await this.$store.dispatch('getOpenTransactionsOutgoings');
                    this.loadingbuttonLoadTransactionsOpen = false;
                }
            },

            async openAddDistributionsOutgoings () {
                this.selectResourceReceiverItems = [];
                for (let index = 0; index < this.selectResourceReceiver.length; index++) {
                    if (this.distributionsOutgoingsNew.map(function(e) { return e.resource_id; }).indexOf(this.selectResourceReceiver[index].id) === -1) {
                        console.log('openAddDistributionsOutgoings test1')
                        if (this.selectResourceReceiver[index].amountPaidReceiver !== this.selectResourceReceiver[index].payment) {
                            console.log('openAddDistributionsOutgoings test2')
                            if (this.selectResourceReceiver[index].amountPaidReceiver === null) {
                                this.selectResourceReceiver[index].amountPaidReceiver = 0;
                            }
                            if (this.selectResourceReceiver[index].payment !== null && this.selectResourceReceiver[index].payment !== this.selectResourceReceiver[index].amountPaidReceiver && this.selectResourceReceiver[index].statusResourceId < 5) {
                                console.log('openAddDistributionsOutgoings test3')  
                                this.selectResourceReceiverItems.push(this.selectResourceReceiver[index]);
                            }
                        }
                    }
                }
                this.amountDistributedOutgoings = 0;
                console.log('openAddDistributionsOutgoings test4', this.selectResourceReceiver, this.selectResourceReceiverItems)
                this.dialog_add_distributionOutgoings = true;
            },

            addDistributionOutgoings(distribution) {
                if (this.amountDistributedOutgoings === 0) {
                    distribution.amount = distribution.payment - distribution.amountPaidReceiver;
                } else {
                    distribution.amount = Math.min(distribution.payment - distribution.amountPaidReceiver, this.amountDistributedOutgoings)
                }
                distribution.resource_id = distribution.id;
                distribution.outgoings_id = this.editedOutgoingsItem.id;
                this.distributionsOutgoingsNew.push(distribution);
                let posReceiver = this.selectResourceReceiver.map(function(e) { return e.id; }).indexOf(distribution.resource_id);
                this.selectResourceReceiver[posReceiver].amountPaidReceiver = this.selectResourceReceiver[posReceiver].amountPaidReceiver + distribution.amount;
                this.dialog_add_distributionOutgoings = false;
            },

            async deleteDistributionOutgoing (event) {
                let pos = this.distributionsOutgoingsNew.map(function(e) { return e.resource_id; }).indexOf(event.resource_id);
                let posReceiver = this.selectResourceReceiver.map(function(e) { return e.id; }).indexOf(event.resource_id);
                this.selectResourceReceiver[posReceiver].amountPaidReceiver = this.selectResourceReceiver[posReceiver].amountPaidReceiver - event.amount
                if (pos > -1) {
                    this.distributionsOutgoingsNew.splice(pos, 1);
                }
                // this.calcAmountOutgoings();
            },

            async saveOutgoing() {
                this.nowDate = this.formattedDate(new Date(), 'date');
                if(this.$refs.formOutgoing.validate()) {
                    if ((this.editedIncomeItem.paymentMethod === 1 || this.editedIncomeItem.paymentMethod === 5) && this.lastSquareDateTime > this.formattedDate(this.editedOutgoingsItem.dateTime, 'dateTime')) {
                        this.$store.dispatch('errorMessage', 'Caja Chica already closed for that date')
                    } else {
                        var correctionTeacher = 0;
                        if (this.editedOutgoingsItem.type_id === 12 || this.editedOutgoingsItem.type_id === 13 || this.editedOutgoingsItem.type_id === 32) {
                            if (this.classesPayment.length === 0) {
                                this.$store.dispatch('errorMessage', 'No classes to pay');
                                return;
                            }
                            correctionTeacher = this.roundTwoDecimals(- this.roundTwoDecimals(this.discountPaymentTeachers) + parseFloat(this.editedOutgoingsItem.correction));
                        }
                        this.loadingButton = true;
                        if (this.tabGeneral === 0) {
                            this.editedOutgoingsItem.paymentMethod = 1;
                        }
                        if (this.editedOutgoingsItem.type_id === 27 || this.editedOutgoingsItem.type_id === 28 || this.editedOutgoingsItem.type_id === 41) {
                            this.editedOutgoingsItem.paymentMethod = 5;
                        }
                        if (this.editedOutgoingsItem.bankTransferFee === null) {
                            this.editedOutgoingsItem.bankTransferFee = 0;
                        }
                        this.editedOutgoingsItem.transaction_id = null;
                        if (this.editedOutgoingsItem.dateTime) {
                            if (this.formattedDate(this.editedOutgoingsItem.dateTime, 'date') === this.nowDate) {
                                this.editedOutgoingsItem.dateTime = this.formattedDate(new Date(), 'dateTime');
                            } else {
                                this.editedOutgoingsItem.dateTime = this.formattedDate(this.editedOutgoingsItem.dateTime, 'dateTime');
                            }
                        } else {
                            this.editedOutgoingsItem.dateTime = this.formattedDate(new Date(), 'dateTime');
                        }
                        this.cajaChicaDay = this.formattedDate(this.editedOutgoingsItem.dateTime, 'date');
                        if (this.editedOutgoingsItem.accountable === 1) {
                            if (this.editedOutgoingsItem.currency === '$') {
                                this.editedOutgoingsItem.amount = this.distributionsTotalOutgoingsDollar;
                            } else {
                                this.editedOutgoingsItem.amount = parseFloat(this.distributionsTotalOutgoingsSoles) + parseFloat(correctionTeacher);
                            }
                        }
                        this.editedOutgoingsItem.exchangeRate = this.$store.state.exchangeRate;
                        let newOutgoingId = await this.$store.dispatch('insertOutgoing', this.editedOutgoingsItem);
                        console.log(this.distributionsOutgoingsOld, this.distributionsOutgoingsNew)
                        for (let index = 0; index < this.distributionsOutgoingsOld.length; index++) {
                            if (this.distributionsOutgoingsNew.map(function(e) { return e.id; }).indexOf(this.distributionsOutgoingsOld[index].id) === -1) {
                                await this.$store.dispatch('deleteDistributionOutgoingId', this.distributionsOutgoingsOld[index].id);
                            }
                        }
                        for (let index = 0; index < this.distributionsOutgoingsNew.length; index++) {
                            if (newOutgoingId === 0) {
                                this.distributionsOutgoingsNew[index].outgoing_id = this.editedOutgoingsItem.id;
                            } else {
                                this.distributionsOutgoingsNew[index].outgoing_id = newOutgoingId;
                            }
                            let transaction_id = new Date();
                            this.distributionsOutgoingsNew[index].transaction_id = transaction_id.getTime();
                            this.distributionsOutgoingsNew[index].id = null;
                            await this.$store.dispatch('insertDistributionOutgoings', this.distributionsOutgoingsNew[index]);
                        }
                        if (this.editedOutgoingsItem.type_id === 12 || this.editedOutgoingsItem.type_id === 13 || this.editedOutgoingsItem.type_id === 32) {
                            for (let index = 0; index < this.classesPaymentOld.length; index++) {
                                if (this.classesPayment.map(function(e) { return e.id; }).indexOf(this.classesPaymentOld[index].id) === -1) {
                                    this.classesPaymentOld[index].outgoing_id = null;
                                    this.classesPaymentOld[index].start = this.timeToTimeStamp(this.formattedDate(this.classesPaymentOld[index].start, "date"), this.formattedDate(this.classesPaymentOld[index].start, "time"));
                                    this.classesPaymentOld[index].end = this.timeToTimeStamp(this.formattedDate(this.classesPaymentOld[index].end, "date"), this.formattedDate(this.classesPaymentOld[index].end, "time"));
                                    // this.classesPaymentOld[index].start = this.formattedDate(this.classesPaymentOld[index].start, "dateTime");
                                    // this.classesPaymentOld[index].end = this.formattedDate(this.classesPaymentOld[index].end, "dateTime");
                                    await this.$store.dispatch('insertClass', this.classesPaymentOld[index])
                                }
                            }
                            for (let index = 0; index < this.classesPayment.length; index++) {
                                if (this.classesPaymentOld.map(function(e) { return e.id; }).indexOf(this.classesPayment[index].id) === -1) {
                                    this.classesPayment[index].outgoing_id = newOutgoingId;
                                    this.classesPayment[index].start = this.timeToTimeStamp(this.formattedDate(this.classesPayment[index].start, "date"), this.formattedDate(this.classesPayment[index].start, "time"));
                                    this.classesPayment[index].end = this.timeToTimeStamp(this.formattedDate(this.classesPayment[index].end, "date"), this.formattedDate(this.classesPayment[index].end, "time"));
                                    // console.log('Financial test2', this.classesPayment[index].start, this.classesPayment[index].end);
                                    // this.classesPayment[index].start = this.formattedDate(this.classesPayment[index].start, "dateTime");
                                    // this.classesPayment[index].end = this.formattedDate(this.classesPayment[index].end, "dateTime");
                                    await this.$store.dispatch('insertClass', this.classesPayment[index])
                                }
                            }
                        }
                        this.balanceGeneralYear = this.formattedDate(this.editedOutgoingsItem.dateTime, 'year');
                        this.tabMonth = parseInt(this.formattedDate(this.editedOutgoingsItem.dateTime, 'month')) - 1;
                        this.closeEditOutgoing();
                        await this.getDataDay(this.cajaChicaDay);
                        this.transactionsOutgoings = [];
                        this.classesPayment = [];
                    }
                }
            },
            
            changeOutgoingType() {
                let tmp = this.editedOutgoingsItem.type_id;
                this.editedOutgoingsItem.fixedPaymentMethod = 0;
                if (tmp !== null && tmp) {
                    var outgoingItem = this.typesOutgoings.filter(function(select_item) {
                        return select_item.id === tmp;
                    })[0];
                }
                this.editedOutgoingsItem.accountable = outgoingItem.accountable;
                this.editedOutgoingsItem.receiver = outgoingItem.receiver;
                this.editedOutgoingsItem.contact_id = outgoingItem.contact_id;
                if (outgoingItem.paymentMethod) {
                    this.editedOutgoingsItem.paymentMethod = outgoingItem.paymentMethod;
                    this.editedOutgoingsItem.fixedPaymentMethod = 1;
                }
                // if (tmp === 27 || tmp === 28 || tmp === 41) {
                //     this.editedOutgoingsItem.paymentMethod = 5;
                // }
            },

            async openEditOutgoings (event) {
                console.log(event)
                this.balanceGeneralYear = this.formattedDate(event.paidOn, 'year');
                // this.tabMonth = parseInt(this.formattedDate(event.paidOn, 'month')) - 1;
                await this.getDataDay(event.paidOn)
                let tmp = event.outgoing_id;
                let item = this.outgoingsMonth.filter(function(select_item) {
                    return select_item.id === tmp;
                })[0]
                this.editOutgoing(item);
                this.dialogTransactionOutgoings = false;
                this.$store.dispatch('saveDataForReport', ); 
            },

            selectAvailableAgencies () {
                return this.$store.state.available_agencies.filter(function(select_item) {
                    return select_item.obsolete === null;
                })
            },
            selectContactsIncome () {
                return this.$store.state.contacts.filter(function(select_item) {
                    return select_item.obsolete === null;
                })
            },
            selectContactsOutgoings () {
                if (this.editedOutgoingsItem.receiver === 'Contact Accommodation') {
                    return this.$store.state.contacts.filter(function(select_item) {
                        return select_item.obsolete === null && select_item.accommodation > 0;
                    })
                } else if (this.editedOutgoingsItem.receiver === 'Contact Tour Agency') {
                    return this.selectContactsOrganisations;
                    // return this.$store.state.contacts.filter(function(select_item) {
                    //     return select_item.obsolete === null && select_item.tours > 0;
                    // })
                } else if (this.editedOutgoingsItem.receiver === 'Contact Teacher') {
                    return this.$store.state.contacts.filter(function(select_item) {
                        return select_item.obsolete === null && select_item.teaching > 0;
                    })
                } else if (this.editedOutgoingsItem.receiver === 'Contact Staff') {
                    return this.$store.state.contacts.filter(function(select_item) {
                        return select_item.obsolete === null && select_item.staff > 0;
                    })
                } else if (this.editedOutgoingsItem.receiver === 'Contact Project') {
                    return this.selectContactsOrganisations;
                } else {
                    return this.$store.state.contacts.filter(function(select_item) {
                        return select_item.obsolete === null;
                    })
                }
            },

            selectPaymentMethods () {
                if (this.tabGeneral === 0) {
                    this.editedIncomeItem.paymentMethod = 1;
                    this.editedOutgoingsItem.paymentMethod = 1;
                } else {
                    let result = this.$store.state.paymentMethods.filter(function(select_item) {
                        return select_item.obsolete === null;
                    })
                    if (this.tabGeneral === 3) {
                        result.push({id: null, method: 'Variable payment method'});
                    }
                    return result;
                }
            },

            async changeDate (event) {
                this.$store.dispatch('setLoadingApp', 1)
                this.cajaChicaDay = event;
                await this.getDataDay(event);
                this.$store.dispatch('setLoadingApp', 0);
            },

            addDays(startDate, days) {
                let new_date = dayjs(startDate).add(days, 'd');
                let day = new_date.format('DD');
                let month = new_date.format('MM');
                let year = new_date.format('YYYY');
                return year + '-' + month + '-' + day
            },

            async squareCaja() {
                this.loadingButton = true;

                let square = {};
                square.exchangeRate = this.$store.state.exchangeRate;
                square.dateTime = this.formattedDate(new Date(), 'dateTime');
                square.comments = 'S/.' + this.currentBalances[1].toFixed(2) + ' $' + this.currentBalances[0].toFixed(2) + ' | S/.' + this.currentBalances[3].toFixed(2) + ' $' + this.currentBalances[2].toFixed(2);
                await this.$store.dispatch('insertSquare', square);
                this.reloadLastSquare();
                this.cajaChicaDay = this.formattedDate(this.nowDate, 'date');
                await this.getDataDay(this.cajaChicaDay);

                this.editedIncomeItem = Object.assign({}, this.defaultIncomeItem);
                this.editedOutgoingsItem = Object.assign({}, this.defaultOutgoingItem);

                this.loadingButton = false;
            },

            async goApplication(id) {
                await this.$store.dispatch('saveDataForReport', id);
                this.$router.push({ name: 'Application' })
            },

            checkShow() {
                this.distributionsTotalIncome = Number(this.distributionsTotalIncome);
                if (this.distributionsTotalIncome === 0) {
                    return true;
                }
                if (this.transferFeeIncluded === 0) {
                    if (this.editedIncomeItem.currency === '$') {
                        if (this.distributionsTotalIncome != this.editedIncomeItem.amount) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        if (this.distributionsTotalIncome.toFixed(2) != (this.editedIncomeItem.amount / this.$store.state.exchangeRate).toFixed(2)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    
                } else {
                    if (this.editedIncomeItem.currency === '$') {
                        if (this.distributionsTotalIncome != this.editedIncomeItem.amount + this.editedIncomeItem.bankTransferFee) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        if (this.distributionsTotalIncome != (this.editedIncomeItem.amount + this.editedIncomeItem.bankTransferFee) * this.$store.state.exchangeRate) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            },
            getTransferFeeIncluded(agency_id) {
                return this.$store.state.available_agencies.filter(function(select_item) {
                    return select_item.id === agency_id;
                })[0].transferFeeIncluded;
            },

            async selectClassesFinishedNotPaid(teacherId) {
                this.classesPayment = await this.$store.dispatch('getClassesTeacherIdFinishedNotPaid', teacherId);
                if (this.classesPayment.length === 0) {
                    this.$store.dispatch('warningMessage', 'No classes to pay');
                } else {
                    this.prepareClassesNotPaid(null);
                }
            },

            async prepareClassesNotPaid (outgoingId) {
                this.$store.dispatch('setLoadingApp', 1);
                let distribution = {};
                this.distributionsOutgoingsOld = JSON.parse(JSON.stringify(this.distributionsOutgoingsNew));
                this.distributionsOutgoingsNew = [];
                let sqlTxt = 'SELECT * FROM view_resources_classes LEFT JOIN view_resources_course ON view_resources_course.resource_id = view_resources_classes.resource_id WHERE ';
                for (let index = 0; index < this.classesPayment.length; index++) {
                    if (index === 0) {
                        sqlTxt = sqlTxt.concat('class_id = ', this.classesPayment[index].class_id)
                    } else {
                        sqlTxt = sqlTxt.concat(' OR class_id = ', this.classesPayment[index].class_id)
                    }
                }
                let resourcesInClasses = JSON.parse(JSON.stringify(await this.$store.dispatch('openQuery', sqlTxt)));
                for (let index = 0; index < this.classesPayment.length; index++) {
                    let classesPaymentResourceId = this.classesPayment[index].class_id;
                    let resourcesInClass = resourcesInClasses.filter(function(select_item) {
                        return select_item.class_id === classesPaymentResourceId;
                    });
                    for (let index2 = 0; index2 < resourcesInClass.length; index2++) {
                        let pos = this.distributionsOutgoingsNew.map(function(e) { return e.resource_id; }).indexOf(resourcesInClass[index2].resource_id);
                        if (pos > -1) {
                            this.distributionsOutgoingsNew[pos].amount = this.distributionsOutgoingsNew[pos].amount + (this.classesPayment[index].paymentClass / this.classesPayment[index].amount_students);
                        } else {
                            distribution.outgoing_id = outgoingId;
                            distribution.resource_id = resourcesInClass[index2].resource_id;
                            distribution.resourceName = resourcesInClass[index2].resourceName;
                            distribution.full_name = resourcesInClass[index2].full_name;
                            distribution.payment = resourcesInClass[index2].payment;
                            distribution.amount =  (this.classesPayment[index].paymentClass / this.classesPayment[index].amount_students);
                            distribution.currency = 'S/.';
                            distribution.exchangeRate = resourcesInClass[index2].exchangeRate;
                            this.distributionsOutgoingsNew.push(distribution);
                        }
                        distribution = {};
                    }
                }
                this.$store.dispatch('setLoadingApp', 0);
            },

            async deleteClassesPayment (event) {
                this.$store.dispatch('setLoadingApp', 1);
                let pos = this.classesPayment.map(function(e) { return e.id; }).indexOf(event.id);
                if (pos > -1) {
                    this.classesPayment.splice(pos, 1);
                }
                await this.prepareClassesNotPaid(this.editedOutgoingsItem.id);
                console.log(this.distributionsOutgoingsOld,this.distributionsOutgoingsNew)
                this.$store.dispatch('setLoadingApp', 0);
            },

            async goPaymentTeacherForm(outgoing) {
                let payload = [];
                payload.classes = await this.$store.dispatch('getClassesOutgoingId', outgoing.id);
                payload.teacher = outgoing.contactName;
                payload.totalPayment = outgoing.amount;
                payload.discountPayment = outgoing.discount_payment_teacher,
                (outgoing.correction) ? payload.correction = outgoing.correction : payload.correction = 0;
                payload.correction = parseFloat(payload.correction);
                for (let i = 0; i < payload.classes.length; i++) {
                    payload.classes[i].students = this.createStudentsStringfromObject(JSON.parse(payload.classes[i].studentsObject));
                }
                (outgoing.comments_correction) ? payload.comments_correction = outgoing.comments_correction : payload.comments_correction = '';
                await this.$store.dispatch('saveDataForReport', payload);
                this.$router.push({ name: 'PaymentTeacherForm'});
            },

            async openAnnualReport() {
                let payload =[];
                payload.year = this.balanceGeneralYear;
                await this.$store.dispatch('saveDataForReport', payload);
                this.$router.push({ name: 'AnnualReport'});
            },

            async initFinanciallocked() {
                await this.$store.dispatch('getGeneralSettings');
                this.financialLocked = dayjs(this.$store.state.financialLocked).format("YYYY-MM-DD");
                this.financialFirstOpenDay = dayjs(this.financialLocked).add(1, 'd').format("YYYY-MM-DD");
                this.lockedFinancialMonth = dayjs(this.financialLocked).format("YYYY-MM");
            },

            async saveFinancialLocked() {
                this.financialLocked = dayjs(this.lockedFinancialMonth).endOf('month').format('YYYY-MM-DD')
                await this.$store.dispatch('saveFinancialLocked', this.financialLocked);
                await this.initFinanciallocked();
                await this.getDataMonth(this.tabMonth);
            },

            getLastDateOfMonth(month) {
                let yearmonth = dayjs(this.balanceGeneralYear + '-' + month).format('YYYY-MM');
                return dayjs(yearmonth).endOf('month').format('YYYY-MM-DD');
            },

            async calcDistributed() {
                if (this.editedIncomeItem.currency === '$') {
                    this.amountDistributedIncome = Math.min(this.editedIncomeItem.amount, this.amountDistributedIncome);
                }
            },
            switchMonthDialogValutaYear(upOrDown) {
                if(upOrDown === 'down' && this.tabMonth > 0) {
                    this.tabMonth -= 1;
                } else if (upOrDown === 'up' && this.tabMonth < 11) {
                    this.tabMonth += 1;
                } else {
                    return;
                }
                this.getDataMonth(this.tabMonth);
                if (this.dialogValutaYearTitle === 'Balance Soles Bank') {
                    this.openReportValutaYear('solesBank');
                } else if (this.dialogValutaYearTitle === 'Balance Dollars Bank') {
                    this.openReportValutaYear('dollarsBank');
                } else if (this.dialogValutaYearTitle === 'Balance Dollars Wise') {
                    this.openReportValutaYear('dollarsWise');
                } else if (this.dialogValutaYearTitle === 'Balance Euros Wise') {
                    this.openReportValutaYear('eurosWise');
                } else if (this.dialogValutaYearTitle === 'Balance GBP Wise') {
                    this.openReportValutaYear('gbpWise');
                }
            },
            async openReportValutaYear(account) {
                if (account === 'solesBank') {
                    this.valutaYear = await this.$store.dispatch('getSolesBankTable', this.formattedDate(dayjs(this.getLastDateOfMonth(this.tabMonth + 1)).add(1, 'day'), 'date'));
                    this.dialogValutaYearTitle = 'Balance Soles Bank';
                } else if (account === 'dollarsBank') {
                    this.valutaYear = await this.$store.dispatch('getDollarsBankTable', this.formattedDate(dayjs(this.getLastDateOfMonth(this.tabMonth + 1)).add(1, 'day'), 'date'));
                    this.dialogValutaYearTitle = 'Balance Dollars Bank';
                } else if (account === 'dollarsWise') {
                    this.valutaYear = await this.$store.dispatch('getDollarsWiseTable', this.formattedDate(dayjs(this.getLastDateOfMonth(this.tabMonth + 1)).add(1, 'day'), 'date'));
                    this.dialogValutaYearTitle = 'Balance Dollars Wise';
                } else if (account === 'eurosWise') {
                    this.valutaYear = await this.$store.dispatch('getEurosWiseTable', this.formattedDate(dayjs(this.getLastDateOfMonth(this.tabMonth + 1)).add(1, 'day'), 'date'));
                    this.dialogValutaYearTitle = 'Balance Euros Wise';
                } else if (account === 'gbpWise') {
                    this.valutaYear = await this.$store.dispatch('getGBPWiseTable', this.formattedDate(dayjs(this.getLastDateOfMonth(this.tabMonth + 1)).add(1, 'day'), 'date'));
                    this.dialogValutaYearTitle = 'Balance GBP Wise';
                } else {
                    return;
                }
                this.valutaYear[0].balance = this.valutaYear[0].amount;
                this.valutaYear[0].dateTime = dayjs(this.valutaYear[0].dateTime).format('YYYY-MM-DD');
                this.valutaYear = this.valutaYear.sort(this.dynamicSort('dateTime'));
                let beginOfMonth = this.getLastDateOfMonth(this.tabMonth + 1).slice(0,8);
                beginOfMonth += '01';
                this.valutaMonth = [];
                for (let index = 1; index < this.valutaYear.length; index++) {
                    this.valutaYear[index].dateTime = dayjs(this.valutaYear[index].dateTime).format('YYYY-MM-DD');
                    if (this.valutaYear[index].amount > 0) {
                        this.valutaYear[index].balance = this.valutaYear[index-1].balance + this.valutaYear[index].amount;
                    } else {
                        this.valutaYear[index].balance = this.valutaYear[index-1].balance + this.valutaYear[index].amount + this.valutaYear[index].bankTransferFee;
                    }
                    if (this.valutaYear[index].dateTime >= beginOfMonth) {
                        this.valutaMonth.push(this.valutaYear[index]);
                    }  
                }
                this.dialogValutaYear = true;
            },
            async checkAppNr() {
                if (this.editedOutgoingsItem.application_id) {
                    let application = await this.$store.dispatch('getApplicationId', this.editedOutgoingsItem.application_id);
                    if (!application) {
                        this.$store.dispatch('errorMessage', 'Application number does not exist');
                        this.editedOutgoingsItem.application_appTitle = '';
                    } else {
                        this.editedOutgoingsItem.application_appTitle = application.appTitle;
                    }
                }
                
                // let tmp = this.editedOutgoingsItem.application_id;
                // let item = this.applications.filter(function(select_item) {
                //     return select_item.id == tmp;
                // })
                // if (item.length !== 1) {
                //     this.$store.dispatch('errorMessage', 'Application number does not exist');
                //     this.editedOutgoingsItem.application_appTitle = '';
                // } else {
                //     this.editedOutgoingsItem.application_appTitle = item[0].appTitle;
                // }
            },
            goClient(id) {
                this.$store.dispatch('setLoadingApp', 1);
                this.$router.push({ name: 'Client', params: { id: id } })
            },
            distributionsTotalIncome () {
                var distributionsTotalIncome = 0;
                if (this.distributionsIncomeNew.length > 0) {
                    for (let index = 0; index < this.distributionsIncomeNew.length; index++) {
                        distributionsTotalIncome = distributionsTotalIncome + this.distributionsIncomeNew[index].amount;
                    }
                }
                return distributionsTotalIncome;
            },

            timeToTimeStamp(day, time) {
                // console.log("timeToTimeStamp", day, time)
                let minStart = time.substring(time.length - 2, time.length);
                let hourStart = time.substring(0, time.length - 3);
                // console.log('timeToTimeStamp', this.toTimestamp(this.formattedDate(dayjs(day).hour(hourStart).minute(minStart), "dateTime")))
                return this.toTimestamp(this.formattedDate(dayjs(day).hour(hourStart).minute(minStart), "dateTime"));
            },

            toTimestamp(strDate){
                var datum = Date.parse(strDate);
                return datum;
            },

            createStudentsStringfromObject(studentsObject) {
                console.log('createStudentsStringfromObject', studentsObject)
                if (studentsObject !== null) {
                    let students = studentsObject;
                    let result = '';
                    for (let i = 0; i < students.length; i++) {
                        (i > 0) ? result = result.concat(', ') : result = result.concat('')
                        if (students[i].initial_spanish_level && students[i].initial_spanish_level !== '?') {
                            result = result.concat(students[i].full_name, ' (' + students[i].initial_spanish_level, ')')
                        } else {
                            result = result.concat(students[i].full_name)
                        }
                    }
                    return result;
                } else {
                    return 'ERROR';
                }
            },

            changePaymentMethodOutgoing(event) {
                console.log('changePaymentMethodOutgoing', event);
                this.editedOutgoingsItem.paymentMethod = event;
                // this.renderDialog = Math.random();
            },
        }
    }
</script>

<style>
    .green-background {
        background-color: turquoise;
    }
    .orange-background {
        background-color: lightsalmon;
    }
    .white-background {
        background-color: white;
    }
    .blue-background {
        background-color: lightsteelblue;
    }
    .smallText {
        font-size: 10px;
    }
</style>