<template>
    <v-container v-if="$store.getters.loadingApp !== 1">
        <h2 style="width: 100%" class="ppc_darkgreen--text my-5">Images for Services</h2>
        <v-card flat class="d-flex flex-row flex-wrap my-5">
            <v-file-input 
                :rules="fileRules"
                small-chips 
                show-size 
                label="Add jpg/jpeg 300px by 200px" 
                @change="selectFile"
                accept="image/jpeg"
            >
            </v-file-input>
            <v-btn
                color="success" 
                dark 
                small 
                @click="uploadImage()"
                class="ma-6"
            >
                Upload image
                <v-icon right dark>mdi-cloud-upload</v-icon>
            </v-btn>
        </v-card>
        <div class="d-flex flex-row flex-wrap mb-7">
            <v-card flat v-for="(image, imageIndex) in $store.state.available_images" :key="imageIndex">
                <v-img
                    :src="getImage(image.id)"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    max-height="200px"
                    max-width="300px"
                >
                    <v-card-title class="white--text">
                        {{ image.name }} 
                        <v-btn 
                            fab 
                            color="ppc_pink" 
                            x-small 
                            absolute
                            left
                            top
                            class="mt-7"
                            @click="downloadImage(image.id)"
                        >
                            <v-icon color="white">mdi-download</v-icon>
                        </v-btn>
                        <v-btn 
                            fab 
                            color="ppc_pink" 
                            x-small 
                            absolute
                            left
                            top
                            class="mt-7 ml-9"
                            @click="deleteImage(image.id)"
                        >
                            <v-icon color="white">$vuetify.icons.icon_mdi_delete</v-icon>
                        </v-btn>
                    </v-card-title>
                </v-img>
            </v-card>
        </div>
        <v-divider></v-divider>
        <div class="d-flex flex-column mb-7 align-end">
            <v-btn color="ppc_darkgreen darken-1" text @click="close">Close</v-btn>
        </div>

        <v-snackbar v-model="alertUploading" :timeout="timeout" top color="success">
            <v-progress-linear
                v-model="progressAdvance"
                color="ppc_grey"
                height="15"
                reactive
            >
            </v-progress-linear>
        </v-snackbar>
        
    </v-container>
</template>

<script>

import zlib from 'zlib';

export default {
    data () {
        return {
            serviceNr: null,
            currentFile: null,
            currentFileName: null,
            alertUploading: false,
            timeout: 3000,
            fileRules: [
                file => !file || file.size < 2e6 || 'File size should be less than 2 MB!'
            ],
        }
    },
    computed: {
        progressAdvance () {
            return this.$store.getters.progressAdvance
        },
    },
    beforeCreate () {
        this.$store.dispatch('setLoadingApp', 1)
    },
    created () {
      this.initialize()
    },

    methods: {
        async initialize() {
            await this.$store.dispatch('getAvailableImages');
            await this.$store.dispatch('getAvailableServices');
            this.$store.dispatch('setLoadingApp', 0);
        },
        selectFile(file) {
            if (file) {
                console.log('selectFile', file.name)
                this.currentFile = file;
                this.$store.dispatch('setFileName', file.name)
            }
        },
        uploadImage() {
            if (this.currentFile) {
                this.alertUploading = true;
                console.log('uploadImage', this.currentFile.name)

                // const blob = new Blob([JSON.stringify(reader.result, null, 2)], {type : 'application/json'});

                const reader = new FileReader();
                reader.onload = () => {
                    const zip = zlib.gzipSync(JSON.stringify(reader.result)).toString('base64');
                    const payload = {
                        file: zip,
                    }
                    if (payload) {
                        this.$store.dispatch('uploadImage', payload)
                    }
                }
                if(this.currentFile) {
                    reader.readAsDataURL(this.currentFile);
                }

                this.currentFile = undefined;
                this.currentFileName = undefined;
            } else {
                this.$store.dispatch('successMessage', 'Please select a file');
            }
        },
        deleteImage(payload) {
            const tmp = this.$store.state.available_services.filter(function(select_item) {
                return select_item.image === payload;
                }
            )
            if (tmp.length > 0) {
                this.$store.dispatch('errorMessage', 'Cannot delete image. Being used by ' + tmp[0].name);
            } else {
                this.$store.dispatch('deleteImage', payload);
            }
        },
        downloadImage(payload) {
            console.log(payload)
            this.$store.dispatch('downloadImage', payload);
        },
        getImage(id) {
            const tmp = this.$store.state.available_images.filter(function(select_item) {
                return select_item.id === id;
                }
            )
            let returnSrc = JSON.parse(zlib.unzipSync(Buffer.from(tmp[0].image, 'base64')));
            return returnSrc;
        },
        close() {
            this.$router.push({name: 'AvailableServices'})
        }
    },
}
</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
</style>