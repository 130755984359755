import compare from '@/mixins/compare';
import formattedDate from '@/mixins/formattedDate';

export default {

    mixins: [compare, formattedDate],

    methods: {
        createClassScheduleTeacher(classes, contactFullName) {
            this.classesPlanned = classes;
            this.classesPlanned.sort(this.dynamicSort('start'));
            let totalHours = 0;
            for (let index = 0; index < this.classesPlanned.length; index++) {
                totalHours = totalHours + this.classesPlanned[index].duration;
                // let studentsObject = this.classesPlanned[index].studentsObject
                // if (studentsObject !== null) {
                //     this.classesPlanned[index].studentsWithLevel = '';
                //     for (let j = 0; j < studentsObject.length; j++) {
                //         if (j > 0) {this.classesPlanned[index].studentsWithLevel += ', '}
                //         this.classesPlanned[index].studentsWithLevel += studentsObject[j].full_name + ' (' + studentsObject[j].initial_spanish_level + ')'
                //     }
                // }
            }
            totalHours = Math.floor(totalHours / 60 * 2) / 2;
            this.teacher = contactFullName;
            // HTML version
            let messageHeader = "<div style='margin-top: 0cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif;'>" +
            "<div style='display: flex; flex-direction: row; justify-content: space-between; margin: 0 0 2cm 0;'>" +
                "<div style='font-family: Roboto, sans-serif; font-size: 32px; color: #35b729;'><span>PROYECTO</span><strong>PERU</strong></div>" +
                "<div style='font-size: 32px; color: grey; display: flex; flex-direction: column; align-items: flex-end;'>" + 
                    "<div>PLANIFICACION</div>" + 
                    "<div style='font-size: 24px;'>" + this.teacher + "</div>" +
                    "<div style='font-size: 18px;'>" + 
                        this.formattedDate(Date.now()) + 
                    "</div>" + 
                "</div>" + 
            "</div>" + 
            "</div>"
            let messageBody = "<div><table style='width: 100%;'><tr><th style='text-align: left; width:100px;'>Fecha</th><th style='text-align: left; width: 100px'>Hora</th><th style='text-align: left; width: 50px'>Dur</th><th style='text-align: left; width: 100px'>Lugar</th><th style='text-align: left; width: 50px'>Tipo *</th><th style='text-align: left;'>Estudiantes</th></tr>";
            for (let index = 0; index < this.classesPlanned.length; index++) {
                messageBody += "<tr style='font-size: 14px'><td>" + this.formattedDate(this.classesPlanned[index].start, 'dayAndDate') + "</td><td>" + this.formattedDate(this.classesPlanned[index].start, 'time') + " - " + this.formattedDate(this.classesPlanned[index].end, 'time') + "</td><td>" + this.classesPlanned[index].duration/60 + "h</td>" + "</td><td>" + this.classesPlanned[index].class_room_name + "</td>" 
                messageBody += "<td>" + (this.classesPlanned[index].class_group ? this.classesPlanned[index].class_group.substring(0,1): '?') + "-" + (this.classesPlanned[index].class_type ? this.classesPlanned[index].class_type.substring(0,1) : '?') + "</td><td>" + '(' + this.classesPlanned[index].amount_students + ') ' + this.classesPlanned[index].students + "</td><td>"  + "</td></tr>";
            }
            messageBody += "</table><br/> Total: " + totalHours + " horas</div><br>" +
            "<div style='font-size: 12px'>* " +
            "G-N: Grupo-Normal, " +
            "G-S: Grupo-Special, " +
            "I-N: Individual-Normal, " +
            "I-S: Individual-Special</div>\n\n\n" +
            "Revisar el nivel del estudiante en Spanish test" 
            let messageFooter = "<div style='margin-top: 2cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif; background-color: #c7d3d1; height: 2px;'></div>"
            this.messageHTML = messageHeader + messageBody + messageFooter;
            return this.messageHTML;
        },
    }
}