<template>
  <v-container style="max-width: 100%">
    <div class="availableServices" v-if="$store.getters.loadingApp !== 1">
      <div class="d-flex flex-row flex-wrap">
          <v-spacer></v-spacer>
          <div class='d-flex flex-row elevation-1 px-2 pt-4 mb-6'>
            <v-radio-group
                row
                :value="scopeServices"
                @change="$store.dispatch('changeScopeServices', $event), createTable()"
                class="mt-0"
            >
                <v-radio
                    label="All"
                    value="all"
                    class="my-0 py-0"
                ></v-radio>
                <v-radio
                    label="Active"
                    value="active"
                    class="my-0 py-0"
                ></v-radio>
                <v-radio
                    label="PackageMaker"
                    value="packagemaker"
                    class="my-0 py-0"
                ></v-radio>
                <v-radio
                    label="Website"
                    value="website"
                    class="my-0 py-0"
                ></v-radio>
                <v-radio
                    label="Inactive"
                    value="inactive"
                    class="my-0 py-0"
                ></v-radio>
            </v-radio-group>
          </div>
        <v-spacer></v-spacer>
        <!-- <v-btn color="ppc_darkgreen darken-1" class="white--text my-7 ml-2" @click="fillRadioOptions">Fill radioOptions Table</v-btn>
        <v-btn color="ppc_darkgreen darken-1" class="white--text my-7 ml-2" @click="fillRadioOptionsPM">Fill radioOptions Table PM</v-btn> -->
        <v-btn color="ppc_darkgreen darken-1" class="white--text my-7 ml-2" @click="syncPM">Sync with Package Maker</v-btn>
        <v-btn color="ppc_darkgreen darken-1" class="white--text my-7 ml-2" @click="syncWebsite">Sync with Website</v-btn>
        <v-btn color="ppc_darkgreen darken-1" class="white--text my-7 ml-2" @click="$router.push({ name: 'ManageImages'})">Images</v-btn>
      </div>
      <v-data-table
        :headers="headers"
        :items="available_services_edit"
        :items-per-page="-1"
        :sort-by="sortServices"
        :sort-desc="sortDirection"
        @update:sort-by="changeSort($event)"
        @update:sort-desc="changeSortDirection($event)"
        class="elevation-1"
        disable-pagination
        hide-default-footer
        :search="searchServices"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Available Services</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-card flat>
                <v-text-field
                    v-model="searchServices"
                    append-icon="$vuetify.icons.icon_mdi_magnify"
                    :label="'Search ' + `${ available_services_edit.length }` + ' service(s)'"
                    single-line
                    hide-details
                    style="width: 300px"
                    clearable
                ></v-text-field>
            </v-card>
            <v-spacer></v-spacer>
            <v-btn
              color="ppc_pink"
              dark
              small
              absolute
              right
              fab
              @click="addAvailableService"
            >
              <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <v-icon small v-if="`${item.active}` === '1'" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
        </template>

        <template v-slot:[`item.packageMaker`]="{ item }">
          <v-icon small v-if="`${item.packageMaker}` === '1'" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
        </template>

        <template v-slot:[`item.website`]="{ item }">
          <v-icon small v-if="`${item.website}` === '1'" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
        </template>

        <template v-slot:[`item.icon`]="{ item }">
          <v-icon v-text="item.icon"></v-icon>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="openService(item.service_number)"
            >
                $vuetify.icons.icon_mdi_pencil
            </v-icon>
            <v-icon
                small
                @click="updatedAvailableService.service_number = item.service_number, confirmDeleteDialog = true"
            >
                $vuetify.icons.icon_mdi_delete
            </v-icon>
        </template>
        

      </v-data-table>
    </div>
    <v-dialog 
      v-model="dialogService"
      overlay-color="white"
      overlay-opacity="1"
      scrollable
      max-width="80vw"
    >
      <v-form class="service d-flex flex-column pa-8" ref="form">
        <v-card-title class="headline">
          Edit Service
        </v-card-title>
        <div class="d-flex flex-row flex-wrap">
          <v-text-field
            v-model.trim='updatedAvailableService.name'
            label="Service Name"
            validate-on-blur
            full-width
            :rules="inputRulesName"
            style="max-width: 300px"
            color="ppc_darkgreen"
            background-color="white"
            class="rounded-xl"
            outlined
            @change="saveService()"
            :disabled="$store.getters.loadingApp === 1"
          >
          </v-text-field>
          <v-switch
            v-model="updatedAvailableService.active"
            label="Active"
            class="mx-5"
            @change="saveService()"
            :disabled="$store.getters.loadingApp === 1"
          ></v-switch>
          <v-switch
            v-model="updatedAvailableService.packageMaker"
            label="PackageMaker"
            class="mx-5"
            :disabled="updatedAvailableService.agency > 0 || $store.getters.loadingApp === 1"
            @change="saveService()"
          ></v-switch>
          <v-switch
            v-model="updatedAvailableService.website"
            label="Website"
            class="mx-5"
            :disabled="updatedAvailableService.agency > 0 || $store.getters.loadingApp === 1"
            @change="saveService()"
          ></v-switch>
          <div style="width: 200px">
            <v-select
                v-model="updatedAvailableService.agency"
                :items="$store.state.available_agencies"
                item-text="name"
                item-value="id"
                :menu-props="{ auto: true }"
                label="No agency"
                single-line
                prepend-icon="fab fa-sourcetree ppc_darkgreen--text"
                validate-on-blur
                clearable
                @change="changeAgency($event)"
                :disabled="$store.getters.loadingApp === 1"
              >
            </v-select> 
          </div>
        </div>
    
        <v-card flat class="d-flex flex-row flex-wrap">
          <v-card flat>
            <v-img
              :src="getImage(updatedAvailableService.image)"
              class="white--text align-end ma-2 rounded-xl"
              height="200px"
              width="300px"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              @click="selectImageDialog = true"
              :key="renderImage"
            >
              <v-btn 
                v-if="updatedAvailableService.info_link" 
                fab 
                color="ppc_pink" 
                x-small 
                :href="updatedAvailableService.info_link"
                target="_blank"
                top
                right
                absolute
                class="mt-7"
                v-on:click.stop
              >
                <v-icon color="white">$vuetify.icons.icon_mdi_information_variant</v-icon>
              </v-btn>
              <v-btn 
                fab 
                color="ppc_pink" 
                x-small 
                target="_blank"
                top
                left
                absolute
                class="mt-7"
                v-on:click.stop
                @click="removeImage"
              >
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
              <v-card-title v-text="updatedAvailableService.name" class="white--text"></v-card-title>
            </v-img>
          </v-card>
          <v-card class="rounded-xl pa-4 mx-2 my-2" width="300px">
            <v-select
              v-model="updatedAvailableService.type_id"
              :items="typeSelect"
              :prepend-icon="updatedAvailableService.icon"
              label="Service type"
              @change="changeTypeIcon"
              :disabled="$store.getters.loadingApp === 1"
            ></v-select>
          </v-card>
        </v-card>
        
        <v-card class="rounded-xl my-5 mx-2" max-width="1150px">
          <v-text-field
            v-model.trim='updatedAvailableService.info_link'
            label="Info URL"
            validate-on-blur
            full-width
            color="ppc_darkgreen"
            class="px-5"
            prepend-icon="mdi-web"
            clearable
            @change="saveService()"
            :disabled="$store.getters.loadingApp === 1"
          >
          </v-text-field>
        </v-card>

        <v-card class="rounded-xl ma-2 pa-5" max-width="1150px">
          <v-card flat>
            <v-card-title>
              <v-icon color="ppc_darkgreen" class="mr-2">$vuetify.icons.icon_mdi_calendar</v-icon>
              Allowed Days
            </v-card-title>
            <v-card-subtitle>
              Starting
            </v-card-subtitle>
            <v-card-text class="d-flex flex-row flex-wrap">
              <v-card flat v-for="(startDay, i) in week" :key="i" width="150px">
                <v-checkbox 
                  :label="`${startDay}`"
                  :input-value="getStartDay(startDay)"
                  @change="changeStartDays($event, startDay)"
                  :disabled="$store.getters.loadingApp === 1"
                ></v-checkbox>
              </v-card>
            </v-card-text>
            <v-divider></v-divider>
          </v-card>
          <v-card flat>
            <v-card-subtitle>
              Ending
            </v-card-subtitle>
            <v-card-text class="d-flex flex-row flex-wrap">
              <v-card flat v-for="(endDay, i) in week" :key="i" width="150px">
                <v-checkbox 
                  :label="`${endDay}`"
                  :input-value="getEndDay(endDay) === true"
                  @change="changeEndDays($event, endDay)"
                  :disabled="$store.getters.loadingApp === 1"
                ></v-checkbox>
              </v-card>
            </v-card-text>
          </v-card>
        </v-card>

        <div class="d-flex flex-row flex-wrap">
          <v-card class="rounded-xl ma-2 pa-8 d-flex flex-column" max-width="600px">
            <v-card-title class="mt-5">
              <v-icon color="ppc_darkgreen" class="mr-2">mdi-clock-outline</v-icon>
              Duration/Period
            </v-card-title>
            <div class="d-flex flex-row flex-wrap mb-5">
              <v-card flat class="ma-2 px-5" width="250px">
                <v-select
                  v-model="updatedAvailableService.duration_type"
                  :items="durationSelect"
                  label="Period"
                  @change="saveService()"
                  :disabled="priceTable.length > 1 || $store.getters.loadingApp === 1"
                ></v-select>
              </v-card>
              <v-card flat class="ma-2 px-5" width="250px">
                <v-select
                  v-model="updatedAvailableService.duration_text"
                  :items="unitDurationSelect"
                  label="Duration in"
                  :disabled="priceTable.length > 1 || $store.getters.loadingApp === 1"
                  @change="saveService()"
                ></v-select>
              </v-card>
            </div>
            <div class="d-flex flex-column mb-5">
              <v-card flat width="100%">
                <v-slider
                  v-model="updatedAvailableService.min_duration"
                  step="1"
                  ticks
                  label="Min duration"
                  tick-size="4"
                  thumb-label="always"
                  :max="24"
                  :hint="updatedAvailableService.duration_text"
                  persistent-hint
                  thumb-color="ppc_pink"
                  track-color="ppc_grey"
                  :disabled="updatedAvailableService.duration_type === 'fixed' || $store.getters.loadingApp === 1"
                  @change="updatedAvailableService.duration = updatedAvailableService.min_duration, saveService()"
                ></v-slider>
              </v-card>
              <v-card flat width="100%">
                <v-slider
                  v-model="updatedAvailableService.duration"
                  step="1"
                  ticks
                  label="Duration"
                  tick-size="4"
                  thumb-label="always"
                  :hint="updatedAvailableService.duration_text"
                  :disabled="updatedAvailableService.duration_type !== 'fixed' || $store.getters.loadingApp === 1"
                  persistent-hint
                  thumb-color="ppc_pink"
                  track-color="ppc_grey"
                  @change="saveService()"
                ></v-slider>
              </v-card>
            </div>
          </v-card>

          <v-card class="rounded-xl ma-2 pa-8" max-width="600px">
            <v-card-title class="mt-5">
              <v-icon color="ppc_darkgreen" class="mr-2">mdi-currency-usd</v-icon>
              Pricing
            </v-card-title>
            <div class="d-flex flex-row flex-wrap mb-5">
              <v-card flat class="ma-2 px-5" width="250px">
                <v-select
                  v-model="updatedAvailableService.price_type"
                  :items="priceTypeSelect"
                  label="Type"
                  @change="saveService()"
                  :disabled="priceTable.length > 1 || $store.getters.loadingApp === 1"
                ></v-select>
              </v-card>
              <v-card flat class="ma-2 px-5" width="250px">
                <v-select
                  v-model="updatedAvailableService.price_desc"
                  :items="priceDescSelect"
                  label="Description"
                  :disabled="priceTable.length > 1 || $store.getters.loadingApp === 1"
                  @change="saveService()"
                ></v-select>
              </v-card>
            </div>
            <div class="d-flex flex-row flex-wrap mb-5">
              <v-radio-group 
                v-model="updatedAvailableService.currency"
                column
                :disabled="priceTable.length > 1 || $store.getters.loadingApp === 1"
                @change="saveService()"
              >
                <v-radio
                  label="USD"
                  class="my-0 py-0"
                  value="$"
                ></v-radio>
                <v-radio
                  label="PEN"
                  class="my-0 py-0"
                  value="S/."
                ></v-radio>
              </v-radio-group>
              <v-text-field
                  v-model.number="updatedAvailableService.price"
                  label="Fixed price"
                  class="ma-2 px-5"
                  style="width: 100px"
                  :disabled="priceTable.length > 1 || $store.getters.loadingApp === 1"
                  @change="saveService()"
              ></v-text-field>
              <v-text-field
                  v-model.number="updatedAvailableService.varPrice"
                  label="Variable price"
                  class="ma-2 px-5"
                  style="width: 100px"
                  :disabled="updatedAvailableService.price_type === 'fixed' || priceTable.length > 1 || $store.getters.loadingApp === 1"
                  @change="saveService()"
              ></v-text-field>
              <v-text-field
                  v-model.number="updatedAvailableService.priceFrom"
                  label="From (overrides)"
                  class="ma-2 px-5"
                  style="width: 100px"
                  :disabled="updatedAvailableService.price_type === 'fixed' || priceTable.length > 1 || $store.getters.loadingApp === 1"
                  @change="saveService()"
              ></v-text-field>
            </div>
            <v-card flat class="d-flex flex-row flex-wrap" width="100%">
              <v-text-field
                  v-model.trim="updatedAvailableService.promo_text"
                  label="Promotion text"
                  class="ma-2 px-5"
                  :rules="inputRules100max"
                  clearable
                  counter
                  @change="saveService()"
                  :disabled="$store.getters.loadingApp === 1"
              ></v-text-field>
            </v-card>
            <v-card flat class="d-flex flex-row flex-wrap" width="100%">
              <v-text-field
                  v-model.trim="updatedAvailableService.websiteText"
                  label="Website text"
                  class="ma-2 px-5"
                  :rules="inputRules100max"
                  clearable
                  counter
                  @change="saveService()"
                  :disabled="$store.getters.loadingApp === 1"
              ></v-text-field>
            </v-card>
          </v-card>

          <v-card class="rounded-xl ma-2 pa-8">
            <v-data-table
              :headers="headersPriceTable"
              :items="priceTable"
              dense
              hide-default-footer
              style="min-width: 300px"
              sort-by="weeks"
              disable-pagination
            >
              <template v-slot:top>
                <div class="d-flex flex-row flex-wrap">
                  <v-card-title>
                    Price Table
                  </v-card-title>
                </div>
              </template>
              <template v-slot:[`item.price`]="{item}">
                <v-edit-dialog ref="editDialogPriceTable"
                  @save="savePriceTable(item)"
                  @cancel="cancelPriceTable(item)"
                  @open="openPriceTable(item)"
                  large
                  persistent
                >
                  <div>{{ item.price }}</div>
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.price"
                      label="Edit price"
                      single-line
                      autofocus
                      clearable
                      :disabled="$store.getters.loadingApp === 1"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
            </v-data-table>
            <div class="d-flex flex-row">
              <v-btn
                @click="removeLastWeek"
                class="mt-2"
                x-small
              >
                remove last week
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                @click="resetPriceTable"
                class="mt-2"
                x-small
              >
                clear table
              </v-btn>
            </div>
          </v-card>

          <v-card class="rounded-xl ma-2 pa-8" v-if="updatedAvailableService.agency === null || !updatedAvailableService.agency">
            <v-card-title>
              Deposit
            </v-card-title>
            <v-text-field
                v-model="updatedAvailableService.fixed_deposit"
                label="Fixed"
                class="ma-2 px-5"
                style="width: 150px"
                :rules="numberRule"
                @change="saveService()"
                :disabled="$store.getters.loadingApp === 1"
            ></v-text-field>
            <v-text-field
                v-model="updatedAvailableService.variable_deposit"
                label="Variable %"
                class="ma-2 px-5"
                style="width: 150px"
                :rules="numberRule"
                @change="saveService()"
                :disabled="$store.getters.loadingApp === 1"
            ></v-text-field>
          </v-card>

          <v-card class="rounded-xl ma-2 pa-8">
            <v-data-table
                :headers="headersAvailableOptions"
                :items="updatedAvailableOptions"
                :items-per-page="-1"
                dense
                disable-pagination
                hide-default-footer
            >
                <template v-slot:top>
                  <div class="d-flex flex-row flex-wrap">
                    <v-card-title>
                      Available Options
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="ppc_pink"
                            dark
                            x-small
                            absolute
                            right
                            fab
                            @click="dialogOption=true"
                        >
                          <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                    </v-btn>
                  </div>
                  <v-dialog
                      v-model="dialogOption"
                      max-width="500px"
                      persistent
                  >
                      <v-form ref="dialogForm">
                          <v-card>
                              <v-card-title class="ppc_darkgreen darken-1">
                                  <span class="headline white--text">{{ formTitleOptions }}</span>
                              </v-card-title>
                              <v-card-text>
                                  <v-row>
                                      <v-col
                                          cols="12"
                                          md="8"
                                      >
                                          <v-text-field
                                              v-model.trim="editedItemOption.option_name"
                                              label="Name (required)"
                                              counter
                                              :rules="inputRulesName"
                                          ></v-text-field>
                                      </v-col>
                                      <v-col
                                          cols="12"
                                      >
                                          <v-text-field
                                              v-model.trim="editedItemOption.option_question"
                                              label="Question (required)"
                                              counter
                                              :rules="inputRules100maxReq"
                                          ></v-text-field>
                                      </v-col>
                                      <v-col
                                          cols="12"
                                          sm="6"
                                          md="4"
                                      >
                                          <v-text-field
                                              v-model.trim="editedItemOption.price"
                                              label="Price"
                                          ></v-text-field>
                                      </v-col>
                                      <v-col
                                          cols="12"
                                          sm="6"
                                          md="4"
                                      >
                                          <v-text-field
                                              v-model.trim="editedItemOption.varPrice"
                                              label="Variable price"
                                          ></v-text-field>
                                      </v-col>
                                  </v-row>
                              </v-card-text>

                              <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                  color="ppc_darkgreen darken-1"
                                  text
                                  @click="closeDialogOption"
                              >
                                  Cancel
                              </v-btn>
                              <v-btn
                                  color="ppc_darkgreen darken-1"
                                  text
                                  @click="saveOptions"
                              >
                                  Save
                              </v-btn>
                              </v-card-actions>
                          </v-card>
                      </v-form>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                          <v-card-title>Are you sure you want to delete the option: {{ editedItemOption.option_name }}?</v-card-title>
                          <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="ppc_darkgreen darken-1" text @click="closeDeleteOption">Cancel</v-btn>
                          <v-btn color="ppc_darkgreen darken-1" text @click="deleteItemConfirmOption">OK</v-btn>
                          <v-spacer></v-spacer>
                          </v-card-actions>
                      </v-card>
                  </v-dialog>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItemOption(item)"
                    >
                        $vuetify.icons.icon_mdi_pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItemOption(item)"
                    >
                        $vuetify.icons.icon_mdi_delete
                    </v-icon>
                </template>
            </v-data-table>
          </v-card>

          <v-card class="rounded-xl ma-2 pa-5" max-width="100%">
            <v-data-table
                :headers="headersRadioGroups"
                :items="updatedAvailableRadioGroups"
                sort-by="calories"
                dense
                :items-per-page="-1"
                disable-pagination
                hide-default-footer
            >
                <template v-slot:top>
                  <div class="d-flex flex-row flex-wrap">
                    <v-card-title>
                      Available Radio Groups
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="ppc_pink"
                        dark
                        x-small
                        absolute
                        right
                        fab
                        @click="editRadioItem()"
                    >
                        <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editRadioItem(item)"
                    >
                        $vuetify.icons.icon_mdi_pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteRadioItem(item)"
                    >
                        $vuetify.icons.icon_mdi_delete
                    </v-icon>
                </template>
            </v-data-table>
          </v-card>
        
          <v-card class="rounded-xl ma-2 pa-5" max-width="400px">
            <v-card-title>
              Requirements
            </v-card-title>
            <v-card flat v-for="(requirement, i) in requirements" :key="i">
              <v-checkbox 
                v-model="requirement.selected"
                :label="`${requirement.Requirement}`"
                :value="`${requirement.Value}`"
                @change="saveService()"
                :disabled="$store.getters.loadingApp === 1"
              >
              </v-checkbox>
            </v-card>
            <v-card flat class="pa-4 mt-2" max-width="300px">
              <v-select
                v-model="updatedAvailableService.statusAfterReq"
                :items="statusSelect"
                prepend-icon="$vuetify.icons.icon_mdi_exclamation_thick"
                label="Status after fullfilling all requirements"
                @change="saveService()"
                :disabled="$store.getters.loadingApp === 1"
              ></v-select>
            </v-card>
            <v-card flat class="pa-4 mb-2" max-width="300px">
              <v-select
                v-model="updatedAvailableService.min_level_spanish"
                :items="spanishLevelSelect"
                prepend-icon="mdi-school"
                label="Minimal Spanish level at start"
                clearable
                @change="saveService()"
                :disabled="$store.getters.loadingApp === 1"
              ></v-select>
            </v-card>
          </v-card>
          
          <v-card class="rounded-xl ma-2 pa-5" min-with="300px" max-width="500px">
            <v-card-title>
              Description
            </v-card-title>
            <v-card 
              flat
              v-for="(description, indexDescriptions) in descriptions"
              :key="indexDescriptions"
            >
              <v-card-subtitle class="ppc_darkgreen--text">
                {{ description.Title }}
              </v-card-subtitle>
              <v-card-text>
                <v-textarea
                  v-model.trim='updatedAvailableService[description.Value]'
                  counter
                  auto-grow
                  validate-on-blur
                  clearable
                  :rules="[v => (v || '' ).length <= 255 || 'Description must be 255 characters or less']"
                  @change="saveService()"
                  :disabled="$store.getters.loadingApp === 1"
                >
                </v-textarea>
              </v-card-text>
            </v-card>
          </v-card>

          <v-card class="rounded-xl ma-2 pa-5" max-width="400px">
            <v-card-title>
              Handbook
            </v-card-title>
              <v-checkbox 
                v-model="updatedAvailableService.handbookIncluded"
                label="Include"
                @change="saveService()"
                :disabled="$store.getters.loadingApp === 1"
              >
              </v-checkbox>
          </v-card>
          
        </div>

        <div class="d-flex flex-row pb-8">
          <v-spacer></v-spacer>
          <v-btn color="ppc_darkgreen darken-1" text @click="closeServiceDialog()">Ok</v-btn>
        </div>

      </v-form>

    </v-dialog>

  <v-dialog
      v-model="confirmDeleteDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to delete this service?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDeleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="deleteAvailableService()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="selectImageDialog">
      <v-card flat class="d-flex flex-row flex-wrap">
        <v-card flat v-for="(image, imageIndex) in $store.state.available_images" :key="imageIndex">
          <v-img
              :src="getImage(image.id)"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              max-height="100px"
              max-width="150px"
              @click="updatedAvailableService.image = image.id, selectImageDialog = false, saveService()"
          >
              <v-card-title class="white--text">
                  {{ image.name }} 
              </v-card-title>
          </v-img>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogRadioGroup"
        max-width="1000px"
        persistent
    >
        <v-form ref="formRadioGroup">
            <v-card>
                <v-card-title class="ppc_darkgreen darken-1">
                    <span class="headline white--text">{{ formTitleGroups }}</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col
                            cols="12"
                            md="8"
                        >
                            <v-text-field
                                v-model.trim="editedRadioItem.radio_group_name"
                                label="Name (required)"
                                counter
                                :rules="inputRulesName"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                v-model.trim="editedRadioItem.radio_group_question"
                                label="Question (required)"
                                counter
                                :rules="inputRules100maxReq"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                v-model.trim="editedRadioItem.info_link"
                                label="Info link"
                                counter
                                :rules="inputRules100max"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card flat>
                    <v-card-text>
                        <v-data-table
                            :headers="headersRadioOptions"
                            :items="editedRadioItem.options"
                            hide-default-footer
                            single-select
                            v-model="selectedDefaultOption"
                            @item-selected="changeDefault"
                            show-select
                            item-key="label"
                            disable-sort
                            :key="rendererKeyDialog"
                        >
                            <template v-slot:top>
                              <v-toolbar
                                  flat
                              >
                                <v-toolbar-title>Options</v-toolbar-title>
                                <v-divider
                                    class="mx-4"
                                    inset
                                    vertical
                                ></v-divider>
                                (minimal 2 options and select a default value)
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="ppc_pink"
                                    dark
                                    x-small
                                    absolute
                                    right
                                    fab
                                    @click="editRadioOptionItem()"
                                >
                                    <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                                </v-btn>
                              </v-toolbar>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    @click="editRadioOptionItem(item)"
                                >
                                    $vuetify.icons.icon_mdi_pencil
                                </v-icon>
                                <v-icon
                                    small
                                    @click="deleteRadioOptionItem(item)"
                                >
                                    $vuetify.icons.icon_mdi_delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <v-card-actions>
                <v-spacer></v-spacer>
                <!-- edit/new radiogroup buttons -->
                <v-btn
                    color="ppc_darkgreen darken-1"
                    text
                    @click="closeRadioGroup"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="ppc_darkgreen darken-1"
                    text
                    @click="saveRadioGroup"
                >
                    Save
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>

    <v-dialog
        v-model="dialogRadioOption"
        max-width="500px"
        persistent
    >
        <v-form ref="formRadioOptions">
            <v-card>
                <v-card-title class="ppc_darkgreen darken-1">
                    <span class="headline white--text">{{ formTitleRadioOptions }}</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                v-model.trim="editedRadioOptionItem.label"
                                label="Label"
                                counter
                                :rules="inputRules100max"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                            <v-text-field
                                v-model.trim="editedRadioOptionItem.price"
                                label="Price"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                            <v-text-field
                                v-model.trim="editedRadioOptionItem.varPrice"
                                label="Variable price"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                            <v-text-field
                                v-model.trim="editedRadioOptionItem.duration"
                                label="Duration"
                                clearable
                                :disabled="editedRadioOptionItem.min_duration>0"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                            <v-text-field
                                v-model.trim="editedRadioOptionItem.min_duration"
                                label="Minimal duration"
                                clearable
                                :disabled="editedRadioOptionItem.duration>0"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                            <v-text-field
                                v-model.trim="editedRadioOptionItem.discount_after"
                                label="Discount after"
                                clearable
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                            <v-text-field
                                v-model.trim="editedRadioOptionItem.discount_percentage"
                                label="Discount %"
                                clearable
                                :disabled="!editedRadioOptionItem.discount_after"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                            <v-text-field
                                v-model.trim="editedRadioOptionItem.websiteText"
                                label="Website text"
                                clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="ppc_darkgreen darken-1"
                    text
                    @click="closeRadioOption"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="ppc_darkgreen darken-1"
                    text
                    @click="addRadioOption(editedRadioOptionItem)"
                >
                    Ok
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>

    <v-dialog v-model="dialogDeleteRadio" max-width="500px">
        <v-card>
            <v-card-title>Are you sure you want to delete radio group: {{ editedRadioItem.radio_group_name }}?</v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="ppc_darkgreen darken-1" text @click="closeDeleteRadio">Cancel</v-btn>
            <v-btn color="ppc_darkgreen darken-1" text @click="deleteRadioItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

  </v-container>
  
</template>

<script>
import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import radioGroups_filtered from '../mixins/radioGroups_filtered';
import options_filtered from '../mixins/options_filtered';
import zlib from 'zlib';
import dayjs from 'dayjs';

export default {

  mixins: [inputRules, formattedDate, radioGroups_filtered, options_filtered],

  data() {
    return {
      loading: false,
      dialogService: false,
      serviceNr: null,
      headers: [
        { text: null, value: 'icon', sortable: false},
        { text: 'Nr', align: 'start', value: 'service_number' },
        { text: 'Name', align: 'start', value: 'name' },
        { text: 'Agency', value: 'agency_name'},
        { text: 'Type', value: 'type' },
        { text: 'Active', value: 'active'},
        { text: 'PM', value: 'packageMaker'},
        { text: 'Website', value: 'website'},
        { text: 'Applied', value: 'amountUsed'},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersAvailableOptions: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'option_name',
        },
        { text: 'Question', value: 'option_question' },
        { text: 'Fixed price $', value: 'price' },
        { text: 'Variable price $', value: 'varPrice' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersRadioGroups: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'radio_group_name',
        },
        { text: 'Id', value: 'radio_group_id' },
        { text: 'Question', value: 'radio_group_question' },
        { text: 'Options (* = default)', value: 'optionsTxt' },
        { text: 'Info link', value: 'info_link' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersRadioOptions: [
        {
          text: 'Label',
          align: 'start',
          sortable: false,
          value: 'label',
        },
        { text: 'radioOption_id', value: 'radioOption_id' },
        { text: 'Fixed price', value: 'price' },
        { text: 'Variable price', value: 'varPrice' },
        { text: 'Duration', value: 'duration' },
        { text: 'Minimal Duration', value: 'min_duration' },
        { text: 'Discount after', value: 'discount_after' },
        { text: 'Discount %', value: 'discount_percentage' },
        { text: 'Website %', value: 'websiteText' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      addServiceValid: true,
      arrayUpload: [],
      updatedAvailableService: [],
      descriptions:[
        {Title: 'Accommodation', Value: 'desc_acc'},
        {Title: 'Study', Value: 'desc_study'},
        {Title: 'Project', Value: 'desc_project'},
        {Title: 'Start', Value: 'desc_start'},
        {Title: 'Includes (appears in invoice)', Value:'desc_incl'},
      ],
      requirements: [
        {Requirement: 'Food preference', Value: 'foodPreference'},
        {Requirement: 'Pets preference', Value: 'petsPreference'},
        {Requirement: 'Curriculum Vitae', Value: 'cv'},
        {Requirement: 'Passport', Value: 'passport'},
        {Requirement: 'Spanish test', Value: 'spanishTest'},
      ],
      headersPriceTable: [
          {
            text: 'Weeks',
            align: 'start',
            sortable: false,
            value: 'weeks',
          },
          { 
            text: 'Price $', 
            value: 'price', 
            sortable: false
          },
        ],
      confirmDeleteDialog: false,
      selectImageDialog: false,
      updatedAvailableServiceIndex: -1,
      default_available_service: [],
      typeSelect: [],
      statusSelect: [],
      durationSelect: [
        {text: 'business weeks', value: 'businessWeeks'},
        {text: 'weeks', value: 'weeks'},
        {text: 'flexible period', value: 'flexible'},
        {text: 'business days', value: 'businessDays'},
        {text: 'nights', value: 'nights'},
        {text: 'hours', value: 'hours'},
        {text: 'fixed', value: 'fixed'},
      ],
      unitDurationSelect: [
        {text: 'week(s)', value: 'week(s)'},
        {text: 'hour(s)', value: 'hour(s)'},
        {text: 'day(s)', value: 'day(s)'},
        {text: 'nights', value: 'nights'},
        {text: 'afternoon', value: 'afternoon'},
        {text: 'morning', value: 'morning'},
      ],
      priceTypeSelect: [
        {text: 'per business weeks', value: 'per_businessWeek'},
        {text: 'per hour', value: 'per_hour'},
        {text: 'per business day', value: 'per_businessDay'},
        {text: 'per night', value: 'per_night'},
        {text: 'fixed', value: 'fixed'},
        {text: 'per week', value: 'per week'},
      ],
      priceDescSelect: [
        {text: 'one time donation', value: 'one time donation'},
        {text: 'one time payment', value: 'one time payment'},
        {text: 'donation per week', value: 'donation per week'},
        {text: 'per week', value: 'per week'},
        {text: 'per night', value: 'per night'},
        {text: 'per hour', value: 'per hour'},
      ],
      spanishLevelSelect: [
        {text: 'any level', value: 'beginner, intermediate or advanced'},
        {text: 'beginner', value: 'beginner'},
        {text: 'intermediate', value: 'intermediate'},
        {text: 'advanced', value: 'advanced'},
      ],
      renderImage: Math.random(),
      week: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
      priceTable: [],
      available_services_edit: [],
      sortServices: 'agency_name',
      sortDirection: true,
      searchServices: '',
      updatedAvailableOptions: [],
      dialogOption: false,
      dialogDelete: false,
      editedIndexOptions: -1,
      editedItemOption: {
        option_name: '',
        option_question: '',
        price: 0,
        varPrice: 0,
        priceFrom: 0,
      },
      defaultItemOption: {
        option_name: '',
        option_question: '',
        price: 0,
        varPrice: 0,
        priceFrom: 0,
      },
      updatedAvailableRadioGroups: [],
      dialogRadioGroup: false,
      dialogRadioOption: false,
      dialogDeleteRadio: false,
      editedRadioIndex: -1,
      editedRadioOptionIndex: -1,
      editedRadioItem: {
        radio_group_name: '',
        radio_group_question: '',
        radio_group_value: '',
        info_link: '',
      },
      defaultRadioItem: {
        radio_group_name: '',
        radio_group_question: '',
        radio_group_value: '',
        info_link: '',
        options: [],
      },
      editedRadioOptionItem: {
        label: '',
        price: 0,
        varPrice: 0,
        priceFrom: 0,
        duration: null,
      },
      defaultRadioOptionItem: {
        label: '',
        price: 0,
        varPrice: 0,
        priceFrom: 0,
        duration: null,
      },
      selectedDefaultOption: [],
      rendererKeyDialog: Math.random(),
    }
  },
  
  // watch: {},

  beforeCreate () {
    this.$store.dispatch('setLoadingApp', 1)
  },
  computed: {
    formTitleOptions () {
        return this.editedIndexOptions === -1 ? 'New Option' : 'Edit Option'
    },
    formTitleGroups () {
        return this.editedRadioIndex === -1 ? 'New Radiogroup' : 'Edit Radiogroup'
    },
    formTitleRadioOptions () {
            return this.editedRadioOptionIndex === -1 ? 'New Option' : 'Edit Option'
        },
    scopeServices () {
        return this.$store.getters.scopeServices
    },
  },
  created () {
    dayjs.locale("en");
    this.initialize();
  },
  methods: {
    async initialize() {
      this.$store.dispatch('breadcrumbs', ['AvailableServices']);
      this.createTable();
      this.$store.dispatch('setLoadingApp', 0)
    },

    async createTable() {
      this.available_services_edit = await this.$store.dispatch('getAvailableServicesEdit');
      if (this.scopeServices === 'packagemaker') {
        this.available_services_edit = this.available_services_edit.filter(function(select_item) {
          return select_item.packageMaker === 1;
        })
      }
      if (this.scopeServices === 'website') {
        this.available_services_edit = this.available_services_edit.filter(function(select_item) {
          return select_item.website === 1;
        })
      }
      if (this.scopeServices === 'active') {
        this.available_services_edit = this.available_services_edit.filter(function(select_item) {
          return select_item.active === 1;
        })
      }
      if (this.scopeServices === 'inactive') {
        this.available_services_edit = this.available_services_edit.filter(function(select_item) {
          return select_item.active !== 1;
        })
      }
    },

    async openService(service_number) {
      this.serviceNr = service_number;
      this.$store.dispatch('setLoadingApp', 1)
      if (service_number !== null) {
        let serviceNr = service_number;
        let tmpService = this.available_services_edit.filter(function(select_item) {
            return select_item.service_number === serviceNr;
          }
        )
        this.updatedAvailableOptions = await this.$store.dispatch('getAvailableOptions', serviceNr);
        await this.loadRadioGroups();
        this.updatedAvailableService = JSON.parse(JSON.stringify(tmpService[0]));
        this.updatedAvailableServiceIndex = this.available_services_edit.map(function(e) { return e.service_number; }).indexOf(this.updatedAvailableService.service_number)
      }
      for (let i = 0; i < this.requirements.length; i++) {
        if (this.updatedAvailableService.requirements.includes(this.requirements[i].Value)) {
          this.requirements[i].selected = this.requirements[i].Value;
        } else {
          this.requirements[i].selected = false;
        }
      }
      for (let i = 0; i < this.$store.state.statusServices.length; i++) {
        this.statusSelect.push({text: this.$store.state.statusServices[i].name, value: this.$store.state.statusServices[i].id})
      }
      for (let i = 0; i < this.$store.state.types.length; i++) {
        if (this.$store.state.types[i].text !== 'Promotions') {
          this.typeSelect.push({text: this.$store.state.types[i].text, value: this.$store.state.types[i].id, icon: this.$store.state.types[i].icon})
        }
      }
      let serviceNumber = this.updatedAvailableService.service_number;
      let result = await this.$store.dispatch('getAvailablePriceTable', serviceNumber);
      this.priceTable = [];
      if (result.length > 0) {
        this.priceTable = JSON.parse(JSON.stringify(result));
      } 
      this.priceTable.push({weeks: result.length + 1, price: "+"});
      this.$store.dispatch('setLoadingApp', 0)
      this.dialogService = true;
      this.renderImage = Math.random();
    },
    async loadRadioGroups () {
      let availableRadioGroups = await this.$store.dispatch('getAvailableRadioGroups', this.serviceNr);
      this.updatedAvailableRadioGroups = JSON.parse(JSON.stringify(availableRadioGroups));
      let i;
      for (i = 0; i < availableRadioGroups.length; i++) {
          let radioOptions = await this.$store.dispatch('getAvailableRadioOptions_id', availableRadioGroups[i].radio_group_id);
          console.log(radioOptions)
          let j;
          let options = [];
          this.updatedAvailableRadioGroups[i].optionsTxt = [];
          for (j = 0; j < radioOptions.length; j++) {
              options.push(radioOptions[j]);
              if (this.updatedAvailableRadioGroups[i].radio_group_value === radioOptions[j].label) {
                  this.updatedAvailableRadioGroups[i].optionsTxt += "* " + radioOptions[j].label + " ";
              } else {
                  this.updatedAvailableRadioGroups[i].optionsTxt += String.fromCharCode(9900) + " " + radioOptions[j].label + " ";
              }
          }
          this.updatedAvailableRadioGroups[i].options = options;
      }
    },

    addAvailableService () {
      console.log('add Available Service')
      this.updatedAvailableRadioGroups = [];
      this.updatedAvailableOptions = [];
      this.updatedAvailableService = Object.assign({}, this.default_available_service);
      console.log(this.updatedAvailableService)
      this.updatedAvailableServiceIndex = -1;
      this.updatedAvailableService.service_number = null;
      this.updatedAvailableService.allowedStartDays = 'fridaythursdaywednesdaytuesdaymonday';
      this.updatedAvailableService.allowedEndDays = 'fridaythursdaywednesdaytuesdaymonday';
      this.updatedAvailableService.requirements = " ";
      this.updatedAvailableService.active = 0;
      this.updatedAvailableService.currency = '$';
      this.updatedAvailableService.fixed_deposit = 0;
      this.updatedAvailableService.variable_deposit = 40;
      this.updatedAvailableService.handbookIncluded = 0;
      this.updatedAvailableService.website = 0;
      console.log(this.updatedAvailableService)
      this.openService(null);
    },

    async syncPM () {
      this.$store.dispatch('progressAdvance', 0);
      this.$store.dispatch('showProgressBar', true);
      let progress = 1;

      let imagesVPS = await this.$store.dispatch('getAvailableImages_vps');
      let availablePriceTables = await this.$store.dispatch('getAvailablePriceTables');
      await this.$store.dispatch('getAvailableImages');

      let availableServices = this.available_services_edit.filter(function(select_item) {
          return select_item.packageMaker === 1 && select_item.active === 1;
      })

      let step = (100 - progress)/(
        availableServices.length
      );
      let i;
      // images
      for (i = 0; i < imagesVPS.length; i++) {
          let tmp = imagesVPS[i].id;
          let existId = this.$store.state.available_images.filter(function(select_item) {
              return select_item.id === tmp;
          })
          if (existId.length === 0) {
              await this.$store.dispatch('deleteImage_vps', tmp)
          }
          this.$store.dispatch('progressAdvance', progress);
      }
      for (i = 0; i < this.$store.state.available_images.length; i++) {
          let tmp = this.$store.state.available_images[i].id;
          let existId = imagesVPS.filter(function(select_item) {
              return select_item.id === tmp;
          })
          if (existId.length === 0) {
              await this.$store.dispatch('uploadImage_vps', this.$store.state.available_images[i])
          }
          this.$store.dispatch('progressAdvance', progress);
      }
      // available services
      await this.$store.dispatch('deleteAllAvailableServices_vps');
      await this.$store.dispatch('deleteAllAvailablePriceTables_vps');
      await this.$store.dispatch('deleteAllAvailableOptions_vps');
      await this.$store.dispatch('deleteAllAvailableRadioGroups_vps');
      for (i = 0; i < availableServices.length; i++) {
          await this.$store.dispatch('insertAvailableService_vps', availableServices[i]);
          // available price tables
          let serviceNumber = availableServices[i].service_number;
          let availablePriceTableService = availablePriceTables.filter(function(select_item) {
              return select_item.service_number === serviceNumber;
          })
          for (let j = 0; j < availablePriceTableService.length; j++) {
              this.$store.dispatch('insertAvailablePriceTable_vps', availablePriceTableService[j]);
          }
          // available options
          let availableOptionsService = await this.$store.dispatch('getAvailableOptions', serviceNumber);
          for (let j = 0; j < availableOptionsService.length; j++) {
              await this.$store.dispatch('insertAvailableOption_vps', availableOptionsService[j]);
          }
          // available radioGroups
          let availableRadioGroupsService = await this.$store.dispatch('getAvailableRadioGroups', serviceNumber);
          for (let j = 0; j < availableRadioGroupsService.length; j++) {
              await this.$store.dispatch('insertAvailableRadioGroup_vps', availableRadioGroupsService[j]);
              let radioGroupId = availableRadioGroupsService[j].radio_group_id;
              // available radioGroups Options
              let availableRadioOptionsService = await this.$store.dispatch('getAvailableRadioOptions_id', radioGroupId);
              for (let k = 0; k < availableRadioOptionsService.length; k++) {
                  await this.$store.dispatch('insertAvailableRadioOption_vps', availableRadioOptionsService[k]);
              }
          }
          progress = progress + step;
          this.$store.dispatch('progressAdvance', progress);
      }
      this.$store.dispatch('showProgressBar', false);
      this.$store.dispatch('progressAdvance', 0);
      this.$store.dispatch('successMessage', 'Services, Options, Radio Groups and Images successfully synchronised');
    },

    async syncWebsite () {
      console.log('syncWebsite')
      this.$store.dispatch('progressAdvance', 0);
      this.$store.dispatch('showProgressBar', true);
      let progress = 1;

      let availableServices = this.available_services_edit.filter(function(select_item) {
          return select_item.website === 1 && select_item.active === 1;
      })

      let step = (100 - progress)/(
        availableServices.length
      );

      // delete from website
      await this.$store.dispatch('deleteAllAvailableServices_website');

      for (let i = 0; i < availableServices.length; i++) {
        progress = progress + step;
        this.$store.dispatch('progressAdvance', progress);
        if (availableServices[i].websiteText != null && availableServices[i].websiteText !== undefined) {
          let payload = {
            service_number_option: availableServices[i].service_number,
            websiteText: availableServices[i].websiteText,
            comment: availableServices[i].name,
          }
          console.log('syncWebsite test1', payload)
          await this.$store.dispatch('insertAvailableService_website', payload);
        }
        let availableRadioGroups = await this.$store.dispatch('getAvailableRadioGroups', availableServices[i].service_number);
        for (let j = 0; j < availableRadioGroups.length; j++) {
          let radioOptions = await this.$store.dispatch('getAvailableRadioOptions_id', availableRadioGroups[j].radio_group_id);
          for (let k = 0; k < radioOptions.length; k++) {
            if (radioOptions[k].websiteText != null && radioOptions[k].websiteText !== undefined) {
              let payload = {
                service_number_option: availableServices[i].service_number + '-' + radioOptions[k].radioOption_id,
                websiteText: radioOptions[k].websiteText,
                comment: radioOptions[k].label,
              }
              console.log('syncWebsite test2', payload, radioOptions[k])
              await this.$store.dispatch('insertAvailableService_website', payload);
            }
          }
        }
      }
      this.$store.dispatch('showProgressBar', false);
      this.$store.dispatch('progressAdvance', 0);
      this.$store.dispatch('successMessage', 'Services successfully synchronised with website');
    },

    changeTypeIcon (event) {
      let type = this.typeSelect.filter(function(select_item) {
        return select_item.value === event;
      })
      this.updatedAvailableService.icon = type[0].icon;
      this.saveService();
    },
    removeImage () {
      this.updatedAvailableService.image = null;
      this.renderImage = Math.random();
      this.saveService();
    },
    getStartDay (day) {
      if (this.updatedAvailableService.allowedStartDays) {
        return this.updatedAvailableService.allowedStartDays.includes(day)
      }
    },
    changeStartDays (event, day) {
      console.log(event, day, this.updatedAvailableService.allowedStartDays);
      if (!event && this.updatedAvailableService.allowedStartDays.includes(day)) {
        this.updatedAvailableService.allowedStartDays = this.updatedAvailableService.allowedStartDays.replace(day,'')
      } else if (event && !this.updatedAvailableService.allowedStartDays.includes(day)) {
        this.updatedAvailableService.allowedStartDays = this.updatedAvailableService.allowedStartDays.concat(day);
      }
      this.saveService();
    },
    getEndDay (day) {
      if (this.updatedAvailableService.allowedEndDays) {
        return this.updatedAvailableService.allowedEndDays.includes(day)
      }
    },
    changeEndDays (event, day) {
      if (!event && this.updatedAvailableService.allowedEndDays.includes(day)) {
        this.updatedAvailableService.allowedEndDays = this.updatedAvailableService.allowedEndDays.replace(day,'')
      } else if (event && !this.updatedAvailableService.allowedEndDays.includes(day)) {
        this.updatedAvailableService.allowedEndDays = this.updatedAvailableService.allowedEndDays.concat(day);
      }
      this.saveService();
    },
    async saveService() {
      if(this.$refs.form.validate()) {
        this.$store.dispatch('setLoadingApp', 1)
        if (this.updatedAvailableService.fixed_deposit === '') {this.updatedAvailableService.fixed_deposit = 0}
        if (this.updatedAvailableService.variable_deposit === '') {this.updatedAvailableService.variable_deposit = 0}
        let i;
        this.updatedAvailableService.requirements = "";
        for (i = 0; i < this.requirements.length; i++) {
          if (this.requirements[i].selected === this.requirements[i].Value) {
            this.updatedAvailableService.requirements = this.updatedAvailableService.requirements.concat(this.requirements[i].Value);
          }
        }
        if (this.updatedAvailableService.duration_type === 'businessWeeks' || this.updatedAvailableService.duration_type === 'weeks') {
          this.updatedAvailableService.week_slider = 24
        } else if (this.updatedAvailableService.duration_type === 'hours') {
          this.updatedAvailableService.week_slider = 150
        } else {
          this.updatedAvailableService.week_slider = null
        }
        if (this.updatedAvailableService.fixed_deposit === null && this.updatedAvailableService.variable_deposit > 0) {
          this.updatedAvailableService.fixed_deposit = 0;
        }
        if (this.updatedAvailableService.price_type === null || !this.updatedAvailableService.price_type) {
          this.updatedAvailableService.price_type = 'fixed';
        }
        if (!this.updatedAvailableService.price > 0) {
          this.updatedAvailableService.price = 0;
        }
        if (!this.updatedAvailableService.varPrice > 0) {
          this.updatedAvailableService.varPrice = 0;
        }
        if (!this.updatedAvailableService.priceFrom > 0) {
          this.updatedAvailableService.priceFrom = 0;
        }
        if (!this.updatedAvailableService.statusAfterReq > 0) {
          this.updatedAvailableService.statusAfterReq = 8;
        }
        if (this.updatedAvailableService.agency > 0) {
          this.updatedAvailableService.fixed_deposit = 0;
          this.updatedAvailableService.variable_deposit = 0;
        }
        let serviceNr = await this.$store.dispatch('insertAvailableService', this.updatedAvailableService)
        if (!this.updatedAvailableService.service_number) {
          this.updatedAvailableService.service_number = serviceNr;
        }
        // price table
        await this.$store.dispatch('deleteAvailablePriceTable', this.updatedAvailableService.service_number)
        if (this.priceTable.length > 1) {
          for (let i = 0; i < this.priceTable.length-1; i++) {
            this.priceTable[i].service_number = this.updatedAvailableService.service_number;
            await this.$store.dispatch('insertAvailablePriceTable', this.priceTable[i]);
          }
        }
        this.createTable();
        this.$store.dispatch('setLoadingApp', 0)
      }
    },
    async closeServiceDialog () {
      this.$store.dispatch('setLoadingApp', 1)
      this.available_services_edit = await this.$store.dispatch('getAvailableServicesEdit');
      this.$store.dispatch('getAvailableImages');
      this.priceTable = [];
      // document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0;
      this.$store.dispatch('setLoadingApp', 0)
      this.serviceNr = null;
      this.dialogService = false;
    },
    async deleteAvailableService() {
      this.confirmDeleteDialog = false;
      await this.$store.dispatch('deleteAvaliableService', this.updatedAvailableService.service_number).then(() => {
        this.$store.dispatch('successMessage', 'Available Service successfully removed!')
      })
      this.available_services_edit = await this.$store.dispatch('getAvailableServicesEdit');
    },
    getImage(id) {
      if (this.$store.state.available_images && id && id !== null) {
        const tmp = this.$store.state.available_images.filter(function(select_item) {
          return select_item.id === id;
          }
        )
        if (tmp[0]) {
          let returnSrc = JSON.parse(zlib.unzipSync(Buffer.from(tmp[0].image, 'base64')));
          return returnSrc;
        }
        return null;
      } else {
        return null;
      } 
    },
    savePriceTable (row) {
      let price = Number(row.price)
      console.log(price, typeof(price))
      let pos = this.priceTable.map(function(e) { return e.weeks; }).indexOf(row.weeks);
      if (price === null || price === '' || isNaN(price)) {
        this.priceTable[pos].price = 0;
      } else {
        this.priceTable[pos].price = price;
      }
      if (pos + 1 === this.priceTable.length) {
        this.priceTable.push({weeks: pos+2, price: "+"})
      }
      if (this.priceTable.length > 1) {
        this.updatedAvailableService.duration_type = 'weeks';
        this.updatedAvailableService.duration_text = 'week(s)';
        this.updatedAvailableService.price_type = 'per week';
        this.updatedAvailableService.price_desc = 'per week';
        this.updatedAvailableService.packageMaker = false;
      }
      console.log('Dialog savePrice', this.priceTable);
      this.saveService();
    },
    cancelPriceTable (row) {
      this.$refs.editDialogPriceTable.isActive = false;
      let pos = this.priceTable.map(function(e) { return e.weeks; }).indexOf(row.weeks);
      if (pos + 1 === this.priceTable.length) {
        this.priceTable[pos].price = "+";
      } else {
        this.priceTable[pos].price = this.priceTable[pos].priceOld;
      }
      console.log('Dialog cancelPrice');
    },
    openPriceTable (row) {
      console.log('Dialog openPrice');
      let pos = this.priceTable.map(function(e) { return e.weeks; }).indexOf(row.weeks);
      if (row.price === '+') {
        this.priceTable[pos].price = null;
      }
      this.priceTable[pos].priceOld = row.price;
    },
    resetPriceTable () {
      this.priceTable = [
        {weeks: 1, price: "+"}
      ]
      this.saveService();
    },
    removeLastWeek () {
      this.priceTable.pop();
      let pos = this.priceTable.length - 1;
      this.priceTable[pos].price = '+';
      this.saveService();
    },
    changeAgency (event) {
      if (event) {
        this.updatedAvailableService.packageMaker = false;
        this.saveService();
      }
    },
    changeSort (event) {
      this.sortServices = event;
    },
    changeSortDirection (event) {
      this.sortDirection = event;
    },
    closeDialogOption () {
      this.dialogOption = false;
      this.editedItemOption = Object.assign({}, this.defaultItemOption);
      this.editedIndexOptions = -1
    },
    async saveOptions () {
      if(this.$refs.dialogForm.validate()){
        let tmp = this.editedItemOption.option_name;
        let doubleName = this.updatedAvailableOptions.filter(function(select_item) {
                return select_item.option_name === tmp;
            }
        )
        if (doubleName.length > 0 && this.editedIndexOptions !== this.updatedAvailableOptions.indexOf(doubleName[0])) {
            this.$store.dispatch('errorMessage', 'The name "' + this.editedItemOption.option_name + '" is already in use!');
            return;
        } else {
            this.editedItemOption.available_service_number = this.serviceNr;
            await this.$store.dispatch('insertAvailableOption', this.editedItemOption);
            this.updatedAvailableOptions = await this.$store.dispatch('getAvailableOptions', this.serviceNr);
        }
        this.closeDialogOption();
      }
    },
    deleteItemOption (item) {
        this.editedIndexOptions = this.updatedAvailableOptions.indexOf(item);
        this.editedItemOption = Object.assign({}, item);
        this.dialogDelete = true;
      },
    closeDeleteOption () {
      this.dialogDelete = false;
      this.editedItemOption = Object.assign({}, this.defaultItemOption);
      this.editedIndexOptions = -1;
    },
    async deleteItemConfirmOption () {
      console.log(this.editedItemOption.option_id)
      let response = await this.$store.dispatch('deleteAvailableOption', this.editedItemOption.option_id);
      if (response.data.affectedRows === 1) {
          this.$store.dispatch('successMessage', 'Option deleted');
      } else {
          this.$store.dispatch('errorMessage', 'Something went wrong');
      }
      this.updatedAvailableOptions = await this.$store.dispatch('getAvailableOptions', this.serviceNr);
      this.closeDeleteOption()
    },
    editItemOption (item) {
      this.editedIndexOptions = this.updatedAvailableOptions.indexOf(item);
      this.editedItemOption = Object.assign({}, item);
      this.dialogOption = true;
    },
    closeRadioGroup () {
      this.dialogRadioGroup = false;
      this.editedRadioItem = JSON.parse(JSON.stringify(Object.assign({}, this.defaultRadioItem)));
      this.editedRadioIndex = -1;
      this.selectedDefaultOption = [];
    },
    async saveRadioGroup () {
        if(this.$refs.formRadioGroup.validate()) {
          if (this.editedRadioItem.options.length < 2) {
            this.$store.dispatch('errorMessage', 'add more options, minimal 2');
          } else if(this.editedRadioItem.radio_group_value === '') {
            this.$store.dispatch('errorMessage', 'select the default option');
          }
          else  {
            this.editedRadioItem.available_service_number = this.serviceNr;
            let idRadioGroup = await this.$store.dispatch('insertAvailableRadioGroup', this.editedRadioItem);
            if (idRadioGroup > 0) {
              this.editedRadioItem.radio_group_id = idRadioGroup;
            } else {
              idRadioGroup = this.editedRadioItem.radio_group_id;
              let radioOptions = await this.$store.dispatch('getAvailableRadioOptions_id', idRadioGroup);
              for (let index = 0; index < radioOptions.length; index++) {
                let radioOptionId = radioOptions[index].radioOption_id;
                let radioOptionToDelete = this.editedRadioItem.options.filter(function(select_item) {
                  return select_item.radioOption_id === radioOptionId;
                })
                if (radioOptionToDelete.length === 0) {
                  await this.$store.dispatch('deleteAvailableRadioOption', radioOptionId);
                }
              }
            }
            for (let index = 0; index < this.editedRadioItem.options.length; index++) {
              this.editedRadioItem.options[index].radio_group_id = idRadioGroup;
              await this.$store.dispatch('insertAvailableRadioOption', this.editedRadioItem.options[index]);
            }
            await this.loadRadioGroups();
            this.$store.dispatch('successMessage', 'Radiogroup successfully saved');
            this.dialogRadioGroup = false;
          }
        }
    },
    closeDeleteRadio () {
      this.dialogDeleteRadio = false;
      this.editedRadioItem = JSON.parse(JSON.stringify(Object.assign({}, this.defaultRadioItem)));
      this.editedRadioIndex = -1;
    },
    async deleteRadioItemConfirm () {
      let response = await this.$store.dispatch('deleteAvailableRadioGroup', this.editedRadioItem.radio_group_id)
      if (response.data.affectedRows === 1) {
          this.$store.dispatch('successMessage', 'Radiogroup deleted');
      } else {
          this.$store.dispatch('errorMessage', 'Something went wrong');
      }
      await this.loadRadioGroups();
      this.closeDeleteRadio();
    },
    deleteRadioItem (item) {
      this.editedRadioIndex = this.updatedAvailableRadioGroups.indexOf(item)
      this.editedRadioItem = Object.assign({}, item);
      this.dialogDeleteRadio = true;
    },
    editRadioItem (item) {
      if (item) {
        this.editedRadioIndex = this.updatedAvailableRadioGroups.indexOf(item);
        this.editedRadioItem = JSON.parse(JSON.stringify(Object.assign({}, item)));
        this.selectedDefaultOption = [{label: this.editedRadioItem.radio_group_value}];
      } else {
        this.editedRadioItem = JSON.parse(JSON.stringify(Object.assign({}, this.defaultRadioItem)));
        console.log(this.editedRadioItem, this.defaultRadioItem);
      }
      this.dialogRadioGroup = true;
    },
    closeRadioOption () {
      this.dialogRadioOption = false;
      this.editedRadioOptionItem = Object.assign({}, this.editedRadioOptionItem);
      this.editedRadioOptionIndex = -1;
    },
    editRadioOptionItem (item) {
      if (item) {
        this.editedRadioOptionIndex = this.editedRadioItem.options.indexOf(item);
        this.editedRadioOptionItem = JSON.parse(JSON.stringify(Object.assign({}, item)));
        console.log(this.editedRadioOptionItem,this.editedRadioOptionIndex,this.editedRadioItem)
      } else {
        this.editedRadioOptionItem = JSON.parse(JSON.stringify(Object.assign({}, this.defaultRadioItem)));
        this.editedRadioOptionIndex = -1;
      }
      this.dialogRadioOption = true;
    },
    addRadioOption (item) {
      if (this.editedRadioOptionIndex === -1) {
        console.log('new item');
        let itemName = item.label;
        let doubleName = this.editedRadioItem.options.filter(function(select_item) {
          return select_item.label === itemName;
        })
        if (doubleName.length > 0) {
          this.$store.dispatch('errorMessage', 'The label "' + item.label + '" already exists!');
        } else {
          item.min_duration ? item.min_duration : null;
          item.discount_after ? item.discount_after : null;
          item.discount_percentage ? item.discount_percentage : null;
          item.radioOption_id = null;
          item.radio_group_id = null;
          this.editedRadioItem.options.push(item);
          this.rendererKeyDialog = Math.random();
          this.dialogRadioOption = false;
        }
      } else {
        console.log('item exists');
        this.editedRadioItem.options[this.editedRadioOptionIndex] = item;
        console.log(item,this.editedRadioItem.options[this.editedRadioOptionIndex], this.editedRadioItem);
        this.rendererKeyDialog = Math.random();
        this.dialogRadioOption = false;
      }
    },
    changeDefault(event) {
        if (event.value) {
            this.editedRadioItem.radio_group_value = event.item.label;
        }
    },
    deleteRadioOptionItem (item) {
      let pos = this.editedRadioItem.options.map(function(e) { return e.label; }).indexOf(item.label);
      this.editedRadioItem.options.splice(pos, 1);
    },
    // async fillRadioOptions () {
    //   // getAllRadioGroups
    //   let radiogroups = await this.$store.dispatch('getAllRadioGroups');
    //   var teller = 0;
    //   for (let index = 0; index < radiogroups.length; index++) {
    //     let updatedAvailableRadioOptions = await this.$store.dispatch('getAvailableRadioOptions_id', radiogroups[index].radio_group_id);
    //     for (let index2 = 0; index2 < updatedAvailableRadioOptions.length; index2++) {
    //       updatedAvailableRadioOptions[index2].radio_group_id = radiogroups[index].radio_group_id;
    //       updatedAvailableRadioOptions[index2].services_id = radiogroups[index].services_id;
    //       await this.$store.dispatch('addRadioOption', updatedAvailableRadioOptions[index2]);
    //       teller = teller + 1;
    //     }
    //   }
    //   console.log(teller, ' = teller');
    // },
    // async fillRadioOptionsPM () {
    //   //fill PM radiooptions
    //   let radiogroups = await this.$store.dispatch('getAllRadioGroupsPM');
    //   var teller = 0;
    //   for (let index = 0; index < radiogroups.length; index++) {
    //     let updatedAvailableRadioOptions = await this.$store.dispatch('getAvailableRadioOptions_id', radiogroups[index].radio_group_id);
    //     for (let index2 = 0; index2 < updatedAvailableRadioOptions.length; index2++) {
    //       updatedAvailableRadioOptions[index2].radio_group_id = radiogroups[index].radio_group_id;
    //       updatedAvailableRadioOptions[index2].services_id = radiogroups[index].services_id;
    //       await this.$store.dispatch('addRadioOptionPM', updatedAvailableRadioOptions[index2]);
    //       teller = teller + 1;
    //     }
    //   }
    //   console.log(teller, ' = teller');
    // }
  },
}
</script>