<template>
  <div>
    <v-data-table
      :sort-by="['timestamp']"
      :sort-desc="[true]"
      :headers="headersInvoices"
      :items="invoices"
      class="elevation-1"
      :items-per-page="-1"
      :search="searchInvoices"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-flex flex-row flex-wrap justify-center">
              <!-- <v-toolbar-title class="mr-4">Resources</v-toolbar-title> -->
              <v-card flat>
                  <v-text-field
                      v-model="searchInvoices"
                      append-icon="$vuetify.icons.icon_mdi_magnify"
                      :label="'Search ' + `${ invoices.length }` + ' invoice(s)'"
                      single-line
                      hide-details
                      style="width: 300px"
                      clearable
                  ></v-text-field>
              </v-card>
          </div>
        </v-toolbar>
      </template>

      <template v-slot:[`item.timestamp`]="{ item }">
        {{ formattedDate(item.timestamp, 'dateTimeShort') }}
      </template>

      <template v-slot:[`item.application_id`]="{ item }">
          <v-btn small rounded @click="goView(item.application_id)">{{ item.application_id }}</v-btn>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <span v-if="item.price">${{ item.price }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="showInvoice(item)"
          >
            $vuetify.icons.icon_mdi_magnify
          </v-icon>
          <v-icon
              small
              @click="invoiceToBeDeleted = item.id, confirmDeleteDialog = true"
          >
              $vuetify.icons.icon_mdi_delete
          </v-icon>
      </template>

    </v-data-table>

    <v-dialog
      v-model="confirmDeleteDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to delete this invoice?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="invoiceToBeDeleted = null, confirmDeleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="removeInvoice(invoiceToBeDeleted)"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
  
</template>

<script>
import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import dayjs from 'dayjs';
export default {
  data() {
    return {
      dialogAddLead: false,
      loading: false,
      invoices: [],
      scopeInvoices: 'all',
      headersInvoices: [
        { text: 'Saved On', align: 'start', value: 'timestamp', width: '120px'},
        { text: 'Application', value: 'appTitle' },
        { text: 'Payer', value: 'payerName' },
        { text: 'App.nr.', value: 'application_id' },
        { text: 'Application Status', value: 'status' },
        { text: 'Price USD', value: 'price'},
        { text: 'Comments', value: 'comments'},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      searchInvoices: '',
      invoiceToBeDeleted: null,
      confirmDeleteDialog: false,
    }
  },

  mixins: [inputRules, formattedDate],
  
  watch: {
    successMessage: function () {
      if (this.successMessage !== "") {
        this.alertSuccessMessage = true;
      }
    },
  },
  computed: {
    successMessage () {
      return this.$store.getters.successMessage
    },
  },
  beforeCreate () {
    this.$store.dispatch('setLoadingApp', 1)
  },
  created () {
    dayjs.locale("en");
    this.initialize();
  },
  methods: {
    async initialize () {
      this.$store.dispatch('breadcrumbs', ['Invoices']);
      this.searchInvoices = JSON.stringify(this.$store.state.dataForReport);
      this.$store.dispatch('saveDataForReport', );
      await this.getInvoices('all')
      for (let index = 0; index < this.invoices.length; index++) {
        if(this.invoices[index].payerClient === null) {
          this.invoices[index].source = this.invoices[index].payerAgency;
        } else {
          this.invoices[index].source = this.invoices[index].payerClient;
        }
      }
      this.$store.dispatch('setLoadingApp', 0)
    },
    async getInvoices(scopeInvoices) {
      if (scopeInvoices === 'all') {
        this.invoices = await this.$store.dispatch('getAllInvoices');
      } 
    },
    async showInvoice(item) {
      item.archive = true;
      await this.$store.dispatch('saveDataForReport', item);
      this.$store.dispatch('showInvoice', true);
    },
    async removeInvoice(invoiceId) {
      this.confirmDeleteDialog = false;
      let response = await this.$store.dispatch('removeInvoice', invoiceId);
      if (response.data.affectedRows === 1) {
        this.$store.dispatch('successMessage', 'Invoice succesfully removed');
        let pos = this.invoices.map(function(e) { return e.id; }).indexOf(invoiceId);
        this.invoices.splice(pos, 1);
      } else {
        this.$store.dispatch('errorMessage', 'Something went wrong');
      }
    },
    async goView(id) {
      await this.$store.dispatch('saveDataForReport', id);
      this.$router.push({ name: 'Application' });
    },
  },
}
</script>