import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you are using css-loader
import icon_volunteer from '@/components/icon_volunteer.vue';
import icon_internship from '@/components/icon_internship.vue';
import icon_languages from '@/components/icon_languages.vue';
import icon_accommodation from '@/components/icon_accommodation.vue';
import icon_tours from '@/components/icon_tours.vue';
import icon_packages from '@/components/icon_packages.vue';
import icon_sale from '@/components/icon_sale.vue';
import icon_spanish from '@/components/icon_spanish.vue';
import icon_english from '@/components/icon_english.vue';
import icon_quechua from '@/components/icon_quechua.vue';
import icon_mdi_plus from '@/components/icon_mdi_plus.vue';
import icon_mdi_magnify from '@/components/icon_mdi_magnify.vue';
import icon_mdi_account from '@/components/icon_mdi_account.vue';
import icon_mdi_account_group from '@/components/icon_mdi_account_group.vue';
import icon_mdi_account_multiple from '@/components/icon_mdi_account_multiple.vue';
import icon_mdi_account_remove from '@/components/icon_mdi_account_remove.vue';
import icon_mdi_account_multiple_remove from '@/components/icon_mdi_account_multiple_remove.vue';
import icon_mdi_account_plus from '@/components/icon_mdi_account_plus.vue';
import icon_mdi_account_cancel from '@/components/icon_mdi_account_cancel.vue';
import icon_mdi_account_box_multiple_outline from '@/components/icon_mdi_account_box_multiple_outline.vue';
import icon_mdi_archive_outline from '@/components/icon_mdi_archive_outline.vue';
import icon_mdi_pencil from '@/components/icon_mdi_pencil.vue';
import icon_mdi_gear from '@/components/icon_mdi_gear.vue';
import icon_mdi_calendar from '@/components/icon_mdi_calendar.vue';
import icon_mdi_smart_card from '@/components/icon_mdi_smart_card.vue';
import icon_mdi_logout_variant from '@/components/icon_mdi_logout_variant.vue';
import icon_mdi_exclamation_thick from '@/components/icon_mdi_exclamation_thick.vue';
import icon_mdi_eye from '@/components/icon_mdi_eye.vue';
import icon_mdi_eye_off from '@/components/icon_mdi_eye_off.vue';
import icon_mdi_cached from '@/components/icon_mdi_cached.vue';
import icon_mdi_package_variant from '@/components/icon_mdi_package_variant.vue';
import icon_mdi_file_document_multiple from '@/components/icon_mdi_file_document_multiple.vue';
import icon_mdi_cash_multiple from '@/components/icon_mdi_cash_multiple.vue';
import icon_mdi_check from '@/components/icon_mdi_check.vue';
import icon_mdi_cancel from '@/components/icon_mdi_cancel.vue';
import icon_mdi_credit_card_outline from '@/components/icon_mdi_credit_card_outline.vue';
import icon_mdi_domain from '@/components/icon_mdi_domain.vue';
import icon_mdi_cogs from '@/components/icon_mdi_cogs.vue';
import icon_mdi_credit_card from '@/components/icon_mdi_credit_card.vue';
import icon_mdi_information_variant from '@/components/icon_mdi_information_variant.vue';
import icon_mdi_delete from '@/components/icon_mdi_delete.vue';
import icon_mdi_shower_head from '@/components/icon_mdi_shower_head.vue';
import icon_mdi_headphones from '@/components/icon_mdi_headphones.vue';
import icon_mdi_school from '@/components/icon_mdi_school.vue';

Vue.use(
    Vuetify, {
        iconfont: 'fa'
  },
)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#35b729',
                info: '#35b729',
                success: '#35b729',
                error: '#ff6285',
                ppc_darkgreen:'#35b729',
                ppc_lightgreen: '#99e265',
                ppc_grey: '#c7d3d1',
                ppc_pink: '#ff6285',
                background: '#ff6285'
            }
        }
    },
    icons: {
        values: {
          icon_volunteer: { 
            component: icon_volunteer, 
          },
          icon_internship: { 
            component: icon_internship, 
          },
          icon_languages: { 
            component: icon_languages, 
          },
          icon_accommodation: { 
            component: icon_accommodation, 
          },
          icon_tours: { 
            component: icon_tours, 
          },
          icon_packages: { 
            component: icon_packages, 
          },
          icon_sale: { 
            component: icon_sale, 
          },
          icon_spanish: { 
            component: icon_spanish, 
          },
          icon_english: { 
            component: icon_english, 
          },
          icon_quechua: { 
            component: icon_quechua, 
          },
          icon_mdi_plus: { 
            component: icon_mdi_plus, 
          },
          icon_mdi_magnify: { 
            component: icon_mdi_magnify, 
          },
          icon_mdi_account: { 
            component: icon_mdi_account, 
          },
          icon_mdi_account_group: { 
            component: icon_mdi_account_group, 
          },
          icon_mdi_account_multiple: { 
            component: icon_mdi_account_multiple, 
          },
          icon_mdi_account_remove: { 
            component: icon_mdi_account_remove, 
          },
          icon_mdi_account_multiple_remove: { 
            component: icon_mdi_account_multiple_remove, 
          },
          icon_mdi_account_plus: { 
            component: icon_mdi_account_plus, 
          },
          icon_mdi_account_cancel: { 
            component: icon_mdi_account_cancel, 
          },
          icon_mdi_account_box_multiple_outline: { 
            component: icon_mdi_account_box_multiple_outline, 
          },
          icon_mdi_pencil: { 
            component: icon_mdi_pencil, 
          },
          icon_mdi_gear: { 
            component: icon_mdi_gear, 
          },
          icon_mdi_calendar: { 
            component: icon_mdi_calendar, 
          },
          icon_mdi_smart_card: { 
            component: icon_mdi_smart_card, 
          },
          icon_mdi_logout_variant: { 
            component: icon_mdi_logout_variant, 
          },
          icon_mdi_exclamation_thick: { 
            component: icon_mdi_exclamation_thick, 
          },
          icon_mdi_eye: { 
            component: icon_mdi_eye, 
          },
          icon_mdi_eye_off: { 
            component: icon_mdi_eye_off, 
          },
          icon_mdi_cached: { 
            component: icon_mdi_cached, 
          },
          icon_mdi_package_variant: { 
            component: icon_mdi_package_variant, 
          },
          icon_mdi_file_document_multiple: { 
            component: icon_mdi_file_document_multiple, 
          },
          icon_mdi_cash_multiple: { 
            component: icon_mdi_cash_multiple, 
          },
          icon_mdi_check: { 
            component: icon_mdi_check,
          },
          icon_mdi_cancel: { 
            component: icon_mdi_cancel,
          },
          icon_mdi_credit_card_outline: { 
            component: icon_mdi_credit_card_outline,
          },
          icon_mdi_domain: { 
            component: icon_mdi_domain,
          },
          icon_mdi_credit_card: { 
            component: icon_mdi_credit_card,
          },
          icon_mdi_cogs: { 
            component: icon_mdi_cogs,
          },
          icon_mdi_information_variant: { 
            component: icon_mdi_information_variant,
          },
          icon_mdi_delete: { 
            component: icon_mdi_delete,
          },
          icon_mdi_shower_head: { 
            component: icon_mdi_shower_head,
          },
          icon_mdi_archive_outline: { 
            component: icon_mdi_archive_outline,
          },
          icon_mdi_headphones: { 
            component: icon_mdi_headphones,
          },
          icon_mdi_school: { 
            component: icon_mdi_school,
          },
        },
    },
})
