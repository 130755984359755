<template>
    <div class="d-flex justify-space-around" v-if="$store.getters.loadingApp !== 1">
        <v-card 
            flat
            class="pa-12 mt-12"
            width="100%"
            max-width="210mm"
            min-height="297mm"
        >
            <span v-html="messageHTML" class="ma-2"></span>
        </v-card>
    </div>
</template>

<script>

export default {
    data () {
        return {
            dialog_application: false,
            year: null,
            messageHTML: "",
        }
    },
    beforeCreate () {
        this.$store.dispatch('setLoadingApp', 1)
    },
    async created() {
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[breadcrumb.length - 1] !== 'AnnualReport') {
            breadcrumb.push('AnnualReport')
            this.$store.dispatch('breadcrumbs', breadcrumb);
        }
        this.year = JSON.parse(JSON.stringify(this.$store.state.dataForReport.year));
        this.$store.dispatch('saveDataForReport', );
        await this.createReport();
        this.$vuetify.goTo(0);
        this.$store.dispatch('setLoadingApp', 0);
    },
    mounted() {
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[0] === 'Invoice') {
            this.$router.push({ name: 'Leads' })
        }
    },

    watch: {
    },
    computed: {
    },
    methods: {
        async createReport() {
            let payload = {
                year: this.year,
            }
            let incomeYear = await this.$store.dispatch('getIncomeYear', payload);
            let outgoingsYear = await this.$store.dispatch('getOutgoingsYear', payload);
            let typesIncome = await this.$store.dispatch('getTypesIncome');
            let typesOutgoings = await this.$store.dispatch('getTypesOutgoings');

            let totalIngresosSoles = 0;
            let totalIngresosDollars = 0;
            let ingresosSolesPositive = [];
            let ingresosDollarsPositive = [];
            let ingresosSolesNegative = [];
            let ingresosDollarsNegative = [];
            for (let i = 0; i < incomeYear.length; i++) {
                if (incomeYear[i].currency === 'S/.') {
                    totalIngresosSoles = totalIngresosSoles + incomeYear[i].amount;
                } else {
                    totalIngresosDollars = totalIngresosDollars + (incomeYear[i].amount * incomeYear[i].exchangeRate);
                }
                if (!ingresosSolesPositive[incomeYear[i].type_id]) {
                    ingresosSolesPositive[incomeYear[i].type_id] = 0;
                }
                if (!ingresosDollarsPositive[incomeYear[i].type_id]) {
                    ingresosDollarsPositive[incomeYear[i].type_id] = 0;
                }
                if (!ingresosSolesNegative[incomeYear[i].type_id]) {
                    ingresosSolesNegative[incomeYear[i].type_id] = 0;
                }
                if (!ingresosDollarsNegative[incomeYear[i].type_id]) {
                    ingresosDollarsNegative[incomeYear[i].type_id] = 0;
                }
                if (incomeYear[i].amount >= 0) {
                    if (incomeYear[i].currency === 'S/.') {
                        ingresosSolesPositive[incomeYear[i].type_id] = ingresosSolesPositive[incomeYear[i].type_id] + incomeYear[i].amount;
                    } else {
                        ingresosDollarsPositive[incomeYear[i].type_id] = ingresosDollarsPositive[incomeYear[i].type_id] + (incomeYear[i].amount * incomeYear[i].exchangeRate);
                    }
                } else {
                    if (incomeYear[i].currency === 'S/.') {
                        ingresosSolesNegative[incomeYear[i].type_id] = ingresosSolesNegative[incomeYear[i].type_id] + incomeYear[i].amount;
                    } else {
                        ingresosDollarsNegative[incomeYear[i].type_id] = ingresosDollarsNegative[incomeYear[i].type_id] + (incomeYear[i].amount * incomeYear[i].exchangeRate);
                    }
                }
            }

            let totalEgresosSoles = 0;
            let totalEgresosDollars = 0;
            let egresosSolesPositive = [];
            let egresosDollarsPositive = [];
            let egresosSolesNegative = [];
            let egresosDollarsNegative = [];

            for (let i = 0; i < outgoingsYear.length; i++) {
                if (outgoingsYear[i].currency === 'S/.') {
                    totalEgresosSoles = totalEgresosSoles + outgoingsYear[i].amount;
                } else {
                    totalEgresosDollars = totalEgresosDollars + (outgoingsYear[i].amount * outgoingsYear[i].exchangeRate);
                }
                if (!egresosSolesPositive[outgoingsYear[i].type_id]) {
                    egresosSolesPositive[outgoingsYear[i].type_id] = 0;
                }
                if (!egresosDollarsPositive[outgoingsYear[i].type_id]) {
                    egresosDollarsPositive[outgoingsYear[i].type_id] = 0;
                }
                if (!egresosSolesNegative[outgoingsYear[i].type_id]) {
                    egresosSolesNegative[outgoingsYear[i].type_id] = 0;
                }
                if (!egresosDollarsNegative[outgoingsYear[i].type_id]) {
                    egresosDollarsNegative[outgoingsYear[i].type_id] = 0;
                }
                if (outgoingsYear[i].amount >= 0) {
                    if (outgoingsYear[i].currency === 'S/.') {
                        egresosSolesPositive[outgoingsYear[i].type_id] = egresosSolesPositive[outgoingsYear[i].type_id] + outgoingsYear[i].amount;
                    } else {
                        egresosDollarsPositive[outgoingsYear[i].type_id] = egresosDollarsPositive[outgoingsYear[i].type_id] + (outgoingsYear[i].amount * outgoingsYear[i].exchangeRate);
                    }
                } else {
                    if (outgoingsYear[i].currency === 'S/.') {
                        egresosSolesNegative[outgoingsYear[i].type_id] = egresosSolesNegative[outgoingsYear[i].type_id] + outgoingsYear[i].amount;
                    } else {
                        egresosDollarsNegative[outgoingsYear[i].type_id] = egresosDollarsNegative[outgoingsYear[i].type_id] + (outgoingsYear[i].amount * outgoingsYear[i].exchangeRate);
                    }
                }
            }

            // HTML version
            let messageHeader = "<div>Annual Report " + this.year + "</div>"
            var messageBody = null;
            messageBody = "<div style='margin-bottom: 1cm; font-size: 12px;'><table style='width: 100%;'>" +
            "<tr style='height:20px'></tr>" +
            "<colgroup>" +
            "<col span='1' style='width: 35%;'>" +
            "<col span='1' style='width: 35%;'>" +
            "<col span='1' style='width: 15%;'>" +
            "<col span='1' style='width: 15%;'>" +
            "</colgroup>" +
            "<tr><th style='text-align: left; text-indent: 10px'>Total Ingresos Registrados</th><th style='text-align: left;'></th><th style='text-align: left;'></th><th style='text-align: left;'></th></tr>";
            
            messageBody += "<tr><td style='text-indent: 10px'>Soles </td><td>" + totalIngresosSoles.toFixed(2) + "</td><td> PEN</td><td></td></tr>";
            messageBody += "<tr><td style='text-indent: 10px'>Dollars * tdc </td><td>" + totalIngresosDollars.toFixed(2) + "</td><td> PEN</td><td></td></tr>";
            messageBody += "<tr style='height:20px'></tr>";
            for (let index = 0; index < typesIncome.length; index++) {
                if(!ingresosSolesPositive[typesIncome[index].id] && !ingresosDollarsPositive[typesIncome[index].id] && !ingresosSolesNegative[typesIncome[index].id] && !ingresosDollarsNegative[typesIncome[index].id] !== 0) {
                    messageBody += "<tr><td style='text-indent: 0px'>" + typesIncome[index].name + "</td><td> - NOT USED - </td><td></td><td></td></tr>";
                } else {
                    if (typesIncome[index].obsolete !== null) {
                        if (ingresosSolesPositive[typesIncome[index].id] !== 0 || ingresosDollarsPositive[typesIncome[index].id] !== 0 || ingresosSolesNegative[typesIncome[index].id] !== 0 || ingresosDollarsNegative[typesIncome[index].id] !== 0) {
                            messageBody += "<tr><td style='text-indent: 0px'>" + typesIncome[index].name + "</td><td> - OBSOLETE - </td><td></td><td></td></tr>";
                        }
                    } else {
                        messageBody += "<tr><td style='text-indent: 0px'>" + typesIncome[index].name + " (" + typesIncome[index].categoryName + ")" + "</td><td></td><td></td><td></td></tr>";
                    }
                    if (ingresosSolesPositive[typesIncome[index].id] !== 0) {
                        messageBody += "<tr><td></td><td style='text-indent: 10px'>Soles positive</td><td style='text-align: right'>" + (Math.round(ingresosSolesPositive[typesIncome[index].id]*100)/100).toFixed(2) + "</td><td> PEN</td></tr>";
                    }
                    if (ingresosDollarsPositive[typesIncome[index].id] !== 0) {
                        messageBody += "<tr><td></td><td style='text-indent: 10px'>Dollars positive * tdc</td><td style='text-align: right'>" + (Math.round(ingresosDollarsPositive[typesIncome[index].id]*100)/100).toFixed(2) + "</td><td> PEN</td></tr>";
                    }
                    if (ingresosSolesNegative[typesIncome[index].id] !== 0) {
                        messageBody += "<tr><td></td><td style='text-indent: 10px'>Soles negative</td><td style='text-align: right'>" + (Math.round(ingresosSolesNegative[typesIncome[index].id]*100)/100).toFixed(2) + "</td><td> PEN</td></tr>";
                    }
                    if (ingresosDollarsNegative[typesIncome[index].id] !== 0) {
                        messageBody += "<tr><td></td><td style='text-indent: 10px'>Dollars negative * tdc</td><td style='text-align: right'>" + (Math.round(ingresosDollarsNegative[typesIncome[index].id]*100)/100).toFixed(2) + "</td><td> PEN</td></tr>";
                    }
                }
                messageBody += "<tr style='height:20px'></tr>";
            }

            messageBody += "<tr style='height:20px'></tr>";
            messageBody += "<tr style='height:20px'></tr>";
            messageBody += "<tr><th style='text-align: left; text-indent: 10px'>Total Egresos Registrados</th><th style='text-align: left;'></th><th style='text-align: left;'></th><th style='text-align: left;'></th></tr>";
            messageBody += "<tr><td style='text-indent: 10px'>Soles </td><td>" + totalEgresosSoles.toFixed(2) + "</td><td> PEN</td><td></td></tr>";
            messageBody += "<tr><td style='text-indent: 10px'>Dollars * tdc </td><td>" + totalEgresosDollars.toFixed(2) + "</td><td> PEN</td><td></td></tr>";
            messageBody += "<tr style='height:20px'></tr>";
            for (let index = 0; index < typesOutgoings.length; index++) {
                if(!egresosSolesPositive[typesOutgoings[index].id]) {
                    messageBody += "<tr><td style='text-indent: 0px'>" + typesOutgoings[index].name + "</td><td> - NOT USED - </td><td></td><td></td></tr>";
                } else {
                    if (typesOutgoings[index].obsolete !== null) {
                        if (egresosSolesPositive[typesOutgoings[index].id] !== 0 || egresosDollarsPositive[typesOutgoings[index].id] !== 0 || egresosSolesNegative[typesOutgoings[index].id] !== 0 || egresosDollarsNegative[typesOutgoings[index].id] !== 0) {
                            messageBody += "<tr><td style='text-indent: 0px'>" + typesOutgoings[index].name + "</td><td> - OBSOLETE - </td><td></td><td></td></tr>";
                        }
                    } else {
                        messageBody += "<tr><td style='text-indent: 0px'>" + typesOutgoings[index].name + " (" + typesOutgoings[index].categoryName + ")" + "</td><td></td><td></td><td></td></tr>";
                    }
                    if (egresosSolesPositive[typesOutgoings[index].id] !== 0) {
                        messageBody += "<tr><td></td><td style='text-indent: 10px'>Soles positive</td><td style='text-align: right'>" + (Math.round(egresosSolesPositive[typesOutgoings[index].id]*100)/100).toFixed(2) + "</td><td> PEN</td></tr>";
                    }
                    if (egresosDollarsPositive[typesOutgoings[index].id] !== 0) {
                        messageBody += "<tr><td></td><td style='text-indent: 10px'>Dollars positive * tdc</td><td style='text-align: right'>" + (Math.round(egresosDollarsPositive[typesOutgoings[index].id]*100)/100).toFixed(2) + "</td><td> PEN</td></tr>";
                    }
                    if (egresosSolesNegative[typesOutgoings[index].id] !== 0) {
                        messageBody += "<tr><td></td><td style='text-indent: 10px'>Soles negative</td><td style='text-align: right'>" + (Math.round(egresosSolesNegative[typesOutgoings[index].id]*100)/100).toFixed(2) + "</td><td> PEN</td></tr>";
                    }
                    if (egresosDollarsNegative[typesOutgoings[index].id] !== 0) {
                        messageBody += "<tr><td></td><td style='text-indent: 10px'>Dollars negative * tdc</td><td style='text-align: right'>" + (Math.round(egresosDollarsNegative[typesOutgoings[index].id]*100)/100).toFixed(2) + "</td><td> PEN</td></tr>";
                    }
                }
                messageBody += "<tr style='height:20px'></tr>";
            }

            messageBody += "</table></div>";
            
            let messageFooter = "<div>-- end of report --</div>"
            this.messageHTML = messageHeader + messageBody + messageFooter;
            return;
        },
    },
}
</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
    /* @media all {
        .page-break	{ display: none; }
    }
    @media print {
        .page-break	{ display: block; page-break-before: always; }
    } */
</style>