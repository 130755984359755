export default {
    data() {
        return {
      inputRulesName: [
        v => !!v || 'Is required', 
        v => !v || v.length >= 2 || 'Minimum length is 2 characters',
        v => !v || v.length <= 45 || 'Maximun length is 45 characters'
      ],
      inputRules100max: [
        v => !v || v.length <= 100 || 'Maximun length is 100 characters'
      ],
      inputRules100maxReq: [
        v => !!v || 'Is required', 
        v => !v || v.length >= 2 || 'Minimum length is 2 characters',
        v => !v || v.length <= 100 || 'Maximun length is 100 characters'
      ],
      inputRulesPhone: [
        v => !v || v.length <= 45 || 'Maximun length is 45 characters'
      ],
      inputRulesEmail: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
        v => !v || v.length <= 245 || 'Maximun length is 245 characters'
      ],
      inputRulesSourceReq: [
        v => !!v || 'Source is required'
      ],
      inputRulesContactReq: [
        v => !!v || 'Contact is required'
      ],
      inputRulesLevelReq: [
        v => !!v || 'Level is required'
      ],
      inputRulesReq: [
        v => !!v || 'Is required'
      ],
      passwordRules: [ 
        v => !!v || 'Password is required', 
        v => (v && v.length >= 5) || 'Password must have 5+ characters',
        v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character', 
        v => /(?=.*\d)/.test(v) || 'Must have one number', 
        v => /([!@$%])/.test(v) || 'Must have one special character [!@#$%]' 
      ],
      numberRule: [
        v => v >= 0 || 'The value must be zero or positive'
      ],
    }
  }
}