<template>
    <v-container style="max-width: 100%">
        <v-tabs
            v-model="activeTabPlacements"
            background-color="transparent"
            color="basil"
            grow
            @change="selectedReservation=[]"
        >
            <v-tab
                v-for="itemTab in itemsTab"
                :key="itemTab"
            >
                <span class="ppc_darkgreen--text">
                    {{ itemTab }}
                </span>
            </v-tab>
        </v-tabs>
    
        <v-tabs-items v-model="activeTabPlacements">
            <v-tab-item>
                <span class="d-flex flex-column">
                    <v-row justify="center">
                        <div class='d-flex flex-row flex-wrap px-2 pt-4 mb-2'>
                            <v-radio-group
                                row
                                v-model="scopeResourcesPlanned"
                                class="mt-4 d-flex flex-column"
                                @change="getResources(), selectedReservation=[]"
                            >
                                <div class="d-flex flex-row flex-wrap">
                                    <div class="d-flex flex-column">
                                        <div class="d-flex justify-center">
                                            <v-radio
                                                label="Ongoing"
                                                value="ongoing"
                                                class="my-0 py-0"
                                            ></v-radio>
                                        </div>
                                        <v-divider class="my-2"></v-divider>
                                        <div class="d-flex flex-row flex-wrap">
                                            <div class="d-flex flex-row flex-wrap">
                                                <v-radio
                                                    label="To be planned"
                                                    value="notPlaced"
                                                    class="my-0 py-0"
                                                ></v-radio>
                                                <v-radio
                                                    label="Pending"
                                                    value="pending"
                                                    class="my-0 py-0"
                                                ></v-radio>
                                                <v-radio
                                                    label="Set"
                                                    value="set"
                                                    class="my-0 py-0"
                                                ></v-radio>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between mx-2">
                                        <v-radio
                                            label="Completed"
                                            value="completed"
                                            class="my-0 py-0"
                                        ></v-radio>
                                    </div>
                                </div>
                            </v-radio-group>
                            <v-card flat outlined class='d-flex flex-row flex-wrap my-4'>
                                <v-card-title>Starting</v-card-title>
                                <span class="mx-2 my-0">
                                    <v-menu
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="100%"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                label="between"
                                                v-bind="attrs"
                                                prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                                :value="formattedDate(scopeDates.start)"
                                                v-on="on"
                                                @click:clear="scopeDates.start = null, resourcesScope = filterResources(resources)"
                                                clearable
                                                readonly
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="scopeDates.start"
                                            no-title
                                            scrollable
                                            @change="resourcesScope = filterResources(resources)"
                                        ></v-date-picker>
                                    </v-menu>
                                </span>
                                <span class="mx-2 my-0">
                                    <v-menu
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="100%"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                label="and"
                                                v-bind="attrs"
                                                prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                                :value="formattedDate(scopeDates.end)"
                                                v-on="on"
                                                @click:clear="scopeDates.end = null, resourcesScope = filterResources(resources)"
                                                clearable
                                                readonly
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="scopeDates.end"
                                            no-title
                                            scrollable
                                            @change="resourcesScope = filterResources(resources)"
                                        ></v-date-picker>
                                    </v-menu>
                                </span>
                            </v-card>
                        </div>
                    </v-row>
                    <v-data-table
                        :headers="headersResourcesScope"
                        :items="resourcesScope"
                        sort-by="start"
                        class="elevation-1"
                        v-if="resourcesScope.length > 0"
                        :items-per-page="-1"
                        disable-pagination
                        hide-default-footer
                        :search="searchResourcesScope"
                        :show-select="scopeResourcesPlanned === 'notPlaced'"
                        :single-select=selectedReservationSingleSelect
                        v-model='selectedReservation'
                        @input="selectResourceReservation"
                    >
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <div class="d-flex flex-row flex-wrap justify-center">
                                    <v-card flat>
                                        <v-text-field
                                            v-model="searchResourcesScope"
                                            append-icon="$vuetify.icons.icon_mdi_magnify"
                                            :label="'Search ' + `${ resourcesScope.length }` + ' resource(s)'"
                                            single-line
                                            hide-details
                                            style="width: 300px"
                                            clearable
                                        ></v-text-field>
                                    </v-card>
                                </div>
                                <v-spacer></v-spacer>
                                <v-checkbox
                                    v-model="sendPassports"
                                    class="mt-4 mx-4"
                                    label="include passports"
                                    v-if="scopeResourcesPlanned === 'notPlaced'"
                                ></v-checkbox>
                                <v-btn
                                    :disabled="selectedReservation.length === 0"
                                    @click="makeReservation"
                                    v-if="scopeResourcesPlanned === 'notPlaced'"
                                >
                                    Make Reservation
                                </v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.full_name`]="{ item }">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn 
                                        small 
                                        rounded 
                                        v-bind="attrs"
                                        v-on="on" 
                                        @click="goClient(item.client_id, item.id)"
                                    >
                                        {{ item.full_name }}
                                    </v-btn>
                                </template>
                                <span>Go resource</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`item.passportAvailable`]="{ item }">
                            <v-icon small v-if="`${item.passportAvailable}` === '1'" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                        </template>
                        <template v-slot:[`item.start`]="{ item }">
                            <span v-html="formattedDate(item.start, 'date')"></span>
                        </template>
                        <template v-slot:[`item.end`]="{ item }">
                            <span v-html="formattedDate(item.end, 'date')"></span>
                        </template>
                        <template v-slot:[`item.statusResourceId`]="{ item }">
                            <v-chip :color="getStatusResource(item.statusResourceId).color" text-color="white" v-html="getStatusResource(item.statusResourceId).name"></v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="openDialogEditResource(item.id)"
                            >
                                $vuetify.icons.icon_mdi_pencil
                            </v-icon>
                        </template>
                    </v-data-table>
                </span>

                <v-dialog
                    v-model="dialogEditResource"
                    max-width="640px"
                    persistent
                >
                    <v-card class="d-flex flex-row flex-wrap" color="blue lighten-2">
                        <v-card flat class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                            <div>
                                <v-card-title>
                                    <span class=" white--text">Tour Placement #{{ resourceEdit.number_type }} - {{ resourceEdit.full_name }}</span> 
                                </v-card-title>
                            </div>
                        </v-card>
                        <div class="d-flex flex-column" color="blue lighten-2" style="max-width: 320px">
                            <v-card-text>
                                <v-select
                                    v-model="resourceEdit.tour_id"
                                    :items="filterPlacements(resourceEdit.tour_id, resourceEdit.tour_placement_id)"
                                    item-text="tourName"
                                    item-value="tour_id"
                                    :menu-props="{ auto: true }"
                                    label="Tour"
                                    validate-on-blur
                                    clearable
                                    @click:clear="resourceEdit.tour_placement_id = null"
                                    @change="resourceEdit.tour_placement_id = null, resourceEdit.payment = null"
                                    color="white"
                                    item-color="blue"
                                    :disabled="resourceEdit.transaction_id !== null"
                                >
                                    <template #selection="{ item }">
                                        <span class="white--text">{{item.tourName}}</span>
                                    </template>
                                </v-select>
                                <v-select
                                    v-model="resourceEdit.tour_placement_id"
                                    :items="filterPlacements(resourceEdit.tour_id, resourceEdit.tour_placement_id)"
                                    item-text="organisation"
                                    item-value="id"
                                    :menu-props="{ auto: true }"
                                    label="Organisation"
                                    validate-on-blur
                                    clearable
                                    @change="changePlacement($event)"
                                    color="white"
                                    item-color="blue"
                                    :disabled = "resourceEdit.tour_id === null || resourceEdit.transaction_id !== null"
                                >
                                    <template #selection="{ item }">
                                        <span class="white--text">{{item.organisation}}</span>
                                    </template>
                                </v-select>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        label="from"
                                        v-bind="attrs"
                                        prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                        :value="formattedDate(resourceEdit.start)"
                                        v-on="on"
                                        @click:clear="resourceEdit.start = null"
                                        clearable
                                        readonly
                                        class="text-input-white"
                                        color="white"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="resourceEdit.start"
                                        no-title
                                        scrollable
                                        color="blue"
                                    ></v-date-picker>
                                </v-menu>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        label="until"
                                        v-bind="attrs"
                                        prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                        :value="formattedDate(resourceEdit.end)"
                                        v-on="on"
                                        @click:clear="resourceEdit.end = null"
                                        clearable
                                        readonly
                                        class="text-input-white"
                                        color="white"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="resourceEdit.end"
                                        no-title
                                        scrollable
                                        color="blue"
                                    ></v-date-picker>
                                </v-menu>
                            </v-card-text>
                            <v-textarea
                                class="rounded-xl ml-2"
                                v-model="resourceEdit.comments"
                                auto-grow
                                clearable
                                counter
                                outlined
                                color="white"
                                label="Comments (255 max)"
                                :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                            ></v-textarea>
                        </div>
                        <div class="d-flex flex-column" color="blue lighten-2" style="max-width: 320px">
                            <v-card-text class="d-flex flex-column">
                                <div class="d-flex flex-row flex-wrap">
                                    <span>Cost: {{ resourceEdit.currency }}</span>
                                    <span>
                                        <v-text-field
                                            v-model="resourceEdit.payment"
                                            class="text-input-white"
                                            color="white"
                                        ></v-text-field>
                                    </span>
                                    <div class="white--text mb-4" v-if="resourceEdit.tour_placement_id">
                                        {{ getPlacementComments }}
                                    </div>
                                </div>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        label="Info to agency"
                                        v-bind="attrs"
                                        prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                        :value="formattedDate(resourceEdit.info_to_provider)"
                                        v-on="on"
                                        @click:clear="resourceEdit.info_to_provider = null"
                                        clearable
                                        readonly
                                        class="text-input-white"
                                        color="white"
                                        :disabled="resourceEdit.tour_placement_id === null"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="resourceEdit.info_to_provider"
                                        no-title
                                        scrollable
                                        color="blue"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        label="Confirmed"
                                        v-bind="attrs"
                                        prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                        :value="formattedDate(resourceEdit.reserved)"
                                        v-on="on"
                                        @click:clear="resourceEdit.reserved = null"
                                        clearable
                                        readonly
                                        class="text-input-white"
                                        color="white"
                                        :disabled="resourceEdit.tour_placement_id === null"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="resourceEdit.reserved"
                                        no-title
                                        scrollable
                                        color="blue"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        label="Info to client"
                                        v-bind="attrs"
                                        prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                        :value="formattedDate(resourceEdit.info_to_client)"
                                        v-on="on"
                                        @click:clear="resourceEdit.info_to_client = null"
                                        clearable
                                        readonly
                                        class="text-input-white"
                                        color="white"
                                        :disabled="resourceEdit.tour_placement_id === null"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="resourceEdit.info_to_client"
                                        no-title
                                        scrollable
                                        @change="checkResourceDates()"
                                        color="blue"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                <v-btn color="blue lighten-3" @click="openTransactionOutgoings(resourceEdit.transaction_id)" class="ma-4" v-if="resourceEdit.transaction_id !== null">
                                    <v-icon color="white">$vuetify.icons.icon_mdi_credit_card</v-icon>
                                    <span class="white--text">Open Transaction</span>
                                </v-btn>
                            </v-card-text>
                        </div>
                        <v-card-actions class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                            <span class="caption black--text">
                                service - resource nr: {{ resourceEdit.service_id }} - {{ resourceEdit.id }}
                            </span>
                            <span>
                                <v-btn color="white" text @click="dialogEditResource = false">Cancel</v-btn>
                                <v-btn color="white" text @click="insertUpdateResource(resourceEdit), dialogEditResource = false">Save</v-btn>
                            </span>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-tab-item>
            <v-tab-item>
                <v-data-table
                    :headers="headersResourcesNotPaid"
                    :items="resourcesToBePaidScope"
                    sort-by="start"
                    class="elevation-1"
                    group-by="contact_organisation"
                    disable-sort
                    :items-per-page="-1"
                    disable-pagination
                    hide-default-footer
                    v-if="resourcesToBePaidScope.length > 0"
                    show-select
                    :single-select=selectResourceNotPaidSingleSelect
                    v-model='selectedNotPaid'
                    @input="selectResourceNotPaid"
                    @toggle-select-all="selectResourceNotPaid"
                >
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>Agencies to be Paid</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <div>Total: {{ sumPaymentsAgency().toFixed(2) }} USD - {{ (sumPaymentsAgency() * $store.state.exchangeRate).toFixed(2) }} PEN</div>
                            <!-- <v-checkbox
                                v-model="payInDollars"
                                class="mt-4 mx-4"
                                label="pay in dollars"
                            ></v-checkbox> -->
                            <v-btn
                                small
                                @click="payInDollars = false, payAgency()"
                                :disabled="selectedNotPaid.length === 0"
                                class="mx-2"
                            >
                                Pay Agency in Soles
                            </v-btn>
                            <v-btn
                                small
                                @click="payInDollars = true, payAgency()"
                                :disabled="selectedNotPaid.length === 0"
                                class="mx-2"
                            >
                                Pay Agency in Dollars
                            </v-btn>
                            <v-btn
                                small
                                @click="createAgencyReport()"
                                :disabled="selectedNotPaid.length === 0"
                                class="mx-4"
                            >
                                send report  
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`group.header`]="{items}">
                        <th colspan="9">
                            <div class="d-flex flex-row ma-4">
                                <h3>
                                    {{ items[0].contact_organisation }}
                                </h3>
                            </div>
                        </th>
                    </template>
                    <template v-slot:[`item.payment`]="{ item }">
                        {{ item.currency }} {{ item.payment.toFixed(2) }}
                    </template>
                    <template v-slot:[`item.paymentOpen`]="{ item }">
                        {{ item.currency }} {{ item.paymentOpen.toFixed(2) }}
                    </template>
                    <template v-slot:[`item.full_name`]="{ item }">
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn 
                                    small 
                                    rounded 
                                    v-bind="attrs"
                                    v-on="on" 
                                    @click="goClient(item.client_id, item.id)"
                                >
                                    {{ item.full_name_short }}
                                </v-btn>
                            </template>
                            <span>{{ item.full_name }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.start`]="{ item }">
                        {{ formattedDate(item.start) }}
                    </template>
                    <template v-slot:[`item.end`]="{ item }">
                        {{ formattedDate(item.end) }}
                    </template>
                    <template v-slot:[`item.statusResourceId`]="{ item }">
                        <v-chip :color="getStatusResource(item.statusResourceId).color" text-color="white" v-html="getStatusResource(item.statusResourceId).name"></v-chip>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="openDialogEditResourcePayments(item.id)"
                        >
                            $vuetify.icons.icon_mdi_pencil
                        </v-icon>
                    </template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex flex-row flex-wrap">
                    <v-spacer></v-spacer>
                    <v-btn color="ppc_darkgreen darken-1" class="white--text my-7 mx-2" @click="$router.push({ name: 'Tours'})">Tours</v-btn>
                </div>
                <v-data-table
                    :headers="headersTourAgencies"
                    :items="updatedTourPlacements"
                    class="elevation-1"
                    :items-per-page="-1"
                    disable-pagination
                    hide-default-footer
                    :search="searchTourPlacements"
                >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <div class="d-flex flex-row flex-wrap justify-center">
                                <v-card flat>
                                    <v-text-field
                                        v-model="searchTourPlacements"
                                        append-icon="$vuetify.icons.icon_mdi_magnify"
                                        :label="'Search ' + `${ updatedTourPlacements.length }` + ' Tour Placement(s)'"
                                        single-line
                                        hide-details
                                        style="width: 300px"
                                        clearable
                                    ></v-text-field>
                                </v-card>
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="ppc_pink"
                                dark
                                small
                                absolute
                                right
                                fab
                                @click="dialog=true"
                            >
                                <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                            </v-btn>
                            <v-dialog
                                v-model="dialog"
                                max-width="500px"
                                persistent
                            >
                                <v-form ref="dialogForm">
                                    <v-card>
                                        <v-card-title class="ppc_darkgreen darken-1">
                                            <span class="headline white--text"> {{ formTitle }} {{ editedItem.organisation }} </span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-select
                                                v-model="editedItem.tour_id"
                                                :items="selectTours"
                                                item-text="name"
                                                item-value="id"
                                                :menu-props="{ auto: true }"
                                                label="Select tour"
                                                prepend-icon="$vuetify.icons.icon_tours"
                                                single-line
                                                validate-on-blur
                                                clearable
                                            ></v-select>
                                            <v-select
                                                v-model="editedItem.organisation_id"
                                                :items="selectOrganisations"
                                                item-text="organisation"
                                                item-value="id"
                                                :menu-props="{ auto: true }"
                                                label="Select Organisation"
                                                single-line
                                                prepend-icon="$vuetify.icons.icon_mdi_account_group ppc_darkgreen--text"
                                                validate-on-blur
                                                clearable
                                            ></v-select>
                                            <div class="d-flex flex-row flex-wrap justify-space-between">
                                                <v-radio-group
                                                    v-model="editedItem.currency"
                                                    row
                                                    label="Currency"
                                                    :rules="inputRulesReq"
                                                >
                                                    <v-radio
                                                        label = "$"
                                                        value="$"
                                                    ></v-radio>
                                                    <v-radio
                                                        label = "PEN"
                                                        value="S/."
                                                    ></v-radio>
                                                </v-radio-group>
                                                <v-text-field
                                                    v-model="editedItem.payment"
                                                    label="Payment"
                                                    class="mr-2"
                                                    style="max-width: 200px"
                                                ></v-text-field>
                                            </div>
                                            <div class="d-flex flex-row justify-space-around">
                                                <v-select
                                                    v-model="editedItem.payment_type"
                                                    :items="payment_types"
                                                    item-text="text"
                                                    item-value="value"
                                                    :menu-props="{ auto: true }"
                                                    label="Payment Type"
                                                    single-line
                                                    validate-on-blur
                                                    clearable
                                                    style="max-width: 200px"
                                                ></v-select>
                                            </div>
                                            <v-textarea
                                                class="rounded-xl ma-3"
                                                v-model="editedItem.comments"
                                                auto-grow
                                                clearable
                                                counter
                                                outlined
                                                label="Comments (255 max)"
                                                :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                                            >
                                            </v-textarea>
                                            <v-checkbox
                                                v-model="editedItem.obsolete"
                                                :input-value="editedItem.obsolete !== null"
                                                label="Not active"
                                                @change="setObsolete($event)"
                                            ></v-checkbox>
                                            <v-text-field
                                                :value="formattedDate(editedItem.obsolete)"
                                                disabled
                                                label="From"
                                                v-if="editedItem.obsolete !== null"
                                                class="ml-2"
                                            ></v-text-field>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="ppc_darkgreen darken-1"
                                                text
                                                @click="close"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                color="ppc_darkgreen darken-1"
                                                text
                                                @click="savePlacement"
                                            >
                                                save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-form>
                            </v-dialog>

                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title>Are you sure you want to delete {{ editedItem.name }}?</v-card-title>
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="ppc_darkgreen darken-1" text @click="closeDelete">Cancel</v-btn>
                                    <v-btn color="ppc_darkgreen darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                    <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                        {{ item.tourName }} - {{ item.organisation }}
                    </template>
                    <template v-slot:[`item.payment`]="{ item }">
                        {{ item.currency }} {{ item.payment }}
                    </template>
                    <template v-slot:[`item.contact`]="{ item }">
                        {{ item.contact }}
                        <v-btn
                            v-if="item.contact" 
                            fab 
                            color="ppc_pink"
                            height="20px"
                            width="20px"
                            @click="openContactInfo(item)"
                            right
                            top
                            class="ml-3"
                        >
                            <v-icon color="white">$vuetify.icons.icon_mdi_information_variant</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:[`item.obsolete`]="{ item }">
                        <v-icon small v-if="item.obsolete !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="editItem(item)"
                        >
                            $vuetify.icons.icon_mdi_pencil
                        </v-icon>
                        <v-icon
                            small
                            @click="deleteItem(item)"
                        >
                            $vuetify.icons.icon_mdi_delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>


        <v-dialog
            v-model="dialogContactInfo"
            max-width="500px"
        >
            <v-card>
                <v-card-title class="ppc_darkgreen darken-1">
                    <span class="headline white--text"> {{ contact.name }} </span>
                </v-card-title>
                <v-card-subtitle class="mt-5" v-if="contact.organisation">
                    <v-icon
                        class="mr-2"
                    >
                        $vuetify.icons.icon_mdi_domain ppc_darkgreen--text
                    </v-icon>
                    <span class="font-weight-bold">{{ contact.organisation }}</span>
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col
                            cols="12"
                            v-if="contact.address || contact.country"
                        >
                            <v-icon
                                class="mr-2"
                            >
                                mdi-map-marker ppc_darkgreen--text
                            </v-icon>
                            {{contact.address}}
                            <span v-if="contact.address && contact.country">,</span>
                            {{contact.country}}
                        </v-col>
                        
                        <v-col
                            cols="12"
                            sm="6"
                            v-if="contact.phone"
                        >
                            <v-icon
                                class="mr-2"
                            >
                                mdi-cellphone ppc_darkgreen--text
                            </v-icon>
                            {{contact.phone}}
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            v-if="contact.phone_alt"
                        >
                            <v-icon
                                class="mr-2"
                            >
                                mdi-phone ppc_darkgreen--text
                            </v-icon>
                            {{contact.phone_alt}}
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            v-if="contact.email"
                        >   
                            <v-icon
                                class="mr-2"
                            >
                                mdi-email ppc_darkgreen--text
                            </v-icon>
                            {{contact.email}}
                        </v-col>
                        <v-col
                            cols="12"
                            v-if="contact.comments"
                        >   
                            <v-icon
                                class="mr-2"
                            >
                                mdi-message-text ppc_darkgreen--text
                            </v-icon>
                            {{ contact.comments }}
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn
                        color="ppc_darkgreen darken-1"
                        text
                        @click="dialogContactInfo = false"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
    </v-container>
</template>

<script>

import dayjs from 'dayjs';
import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import compare from '../mixins/compare';

export default {
    data: () => ({
        itemsTab: [
            'Planning', 'Payments', 'Tours & Agencies'
        ],
        activeTabPlacements: 0,
      dialog: false,
      dialogDelete: false,
      dialogContactInfo: false,
      dialogEditResource: false,
      headersTourAgencies: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Payment', value: 'payment' },
        { text: 'Payment Type', value: 'payment_type' },
        { text: 'Comments', value: 'comments' },
        { text: 'Active', value: 'obsolete' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      payment_types: [
        { text: '100% before' , value: '100% before'},
        { text: '100% after' , value: '100% after'},
        { text: '50% - 50%' , value: '50% - 50%'},
        { text: 'other' , value: 'other'},
      ],
        headersResourcesScope: [
            { text: 'Client', value: 'full_name' },
            { text: 'Passport', value: 'passportAvailable' },
            { text: 'Food', value: 'food_preference' },
            { text: 'Agency', value: 'agency' },
            { text: 'Tour', value: 'tourName' },
            { text: 'Organisation', value: 'organisation' },
            { text: 'Service', value: 'servicesName' },
            { text: 'Start', value: 'start', width: '120px' },
            { text: 'End', value: 'end', width: '120px' },
            { text: 'Status', value: 'statusResourceId' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],

      headersResourcesNotPaid: [
          { text: 'Tour Agency', value: '' },
          { text: 'Tour', value: 'tourName' },
            { text: 'Payment', value: 'payment' },
            { text: 'When', value: 'paymentTypeTour' },
            { text: 'Open', value: 'paymentOpen' },
          { text: 'Client', value: 'full_name' },
          { text: 'Start', value: 'start' },
          { text: 'End', value: 'end' },
          { text: 'Actions', value: 'actions', sortable: false },
      ],
      updatedTourPlacements: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        option_question: '',
        price: 0,
        varPrice: 0,
      },
      defaultItem: {
        name: '',
        option_question: '',
        price: 0,
        varPrice: 0,
      },
      contact: [],
      resources: [],
    //   resourcesPlaced: [],
    //   resourcesNotPlaced: [],
      resource: [],
      resourceEdit: [],
      resourcesToBePaid: [],
      resourcesToBePaidScope: [],
      loadingButtonCompletedTours: false,
      resourcesCompleted: [],
      selectedNotPaid: [],
      selectResourceNotPaidSingleSelect: true,
      selectedReservation: [],
      selectedReservationSingleSelect: true,
      payInDollars: false,
      selectOrganisations: [],
      scopeResourcesPlanned: 'ongoing',
      resourcesScope: [],
      searchResourcesScope: '',
      searchTourPlacements: '',
      sendPassports: false,
      scopeDates: [],
      selectTours: [],
    }),

    mixins: [inputRules, formattedDate, compare],

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Placement ' : ''
        },
        selectContacts () {
            return this.$store.state.contacts.filter(function(select_item) {
                return select_item.obsolete === null && select_item.organisation !== null;
            })
        },
        getPlacementComments () {
            if (this.resource.tour_placement_id) {
                let placementId = this.resource.tour_placement_id;
                var placement = this.updatedTourPlacements.filter(function(select_item) {
                    return select_item.id === placementId;
                })[0];
                return placement.comments;
            } else {
                return '';
            }
        },
        progressAdvance () {
            return this.$store.getters.progressAdvance
        },
        reloadResourcesTourAgencies () {
            return this.$store.getters.reloadResourcesTourAgencies
        },
    },

    watch: {
        reloadResourcesTourAgencies(newValue, oldValue) {
            console.log(newValue, oldValue)
            if (this.$store.state.reloadResourcesTourAgencies) {
                this.$store.dispatch('reloadResourcesTourAgencies', false);
                this.getResources();
            }
        },
    },

    beforeCreate () {
        if (this.$store.state.accessTours === 0) {
            this.$router.push({ name: 'Illegal' })
        }
        // this.$store.dispatch('setLoadingApp', 1)
    },

    created () {
      this.initialize();
    },

    methods: {
      async initialize () {
        let previousView =  this.$store.state.breadcrumbs[this.$store.state.breadcrumbs.length - 1];
        let breadcrumbs =  JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (previousView === 'Tours') {
          this.activeTabPlacements = 2;
          breadcrumbs.slice(-1);
        }
        if (previousView === 'TourAgenciesReport') {
            this.activeTabPlacements = 1;
            breadcrumbs.slice(-1);
        }
        breadcrumbs = ['TourAgencies'];
        await this.$store.dispatch('breadcrumbs', breadcrumbs);
        let organisations = await this.$store.dispatch('getOrganisationsAll');
        for (let index = 0; index < organisations.length; index++) {
            organisations[index].organisation = organisations[index].name + ' - ' + organisations[index].contactName;
        }
        this.selectOrganisations =  organisations.sort(this.dynamicSort('name'));
        this.updatedTourPlacements = JSON.parse(JSON.stringify(await this.$store.dispatch('getTourPlacements')));
        for (let index = 0; index < this.updatedTourPlacements.length; index++) {
            (this.updatedTourPlacements[index].obsolete) ? this.updatedTourPlacements[index].obsolete = this.formattedDate(this.updatedTourPlacements[index].obsolete, 'date') : this.updatedTourPlacements[index].obsolete = null;
        }
        this.updatedAvailableTours = JSON.parse(JSON.stringify(await this.$store.dispatch('getTours')));
        for (let index = 0; index < this.updatedAvailableTours.length; index++) {
            (this.updatedAvailableTours[index].obsolete) ? this.updatedAvailableTours[index].obsolete = this.formattedDate(this.updatedAvailableTours[index].obsolete, 'date') : this.updatedAvailableTours[index].obsolete = null;
        }
        let toursNotObsolete = this.updatedAvailableTours.filter(function(select_item) {
            return select_item.obsolete === null;
        })
        this.selectTours = toursNotObsolete.sort(this.dynamicSort('name'));
        await this.getResources();
        if (this.$route.params.resourceId) {
            this.openDialogEditResource(this.$route.params.resourceId);
            this.$route.params.resourceId = null;
        }
        this.$store.dispatch('setLoadingApp', 0)
      },
      async getResources() {
        this.$store.dispatch('setLoadingApp', 1)
        if (this.scopeResourcesPlanned === 'all') {
            this.resources = await this.$store.dispatch('getResourcesAllTours');
        } else if (this.scopeResourcesPlanned === 'completed') {
            this.resources = await this.$store.dispatch('getResourcesCompletedTours');
        } else if (this.scopeResourcesPlanned === 'ongoing') {
            this.resources = await this.$store.dispatch('getResourcesToursOngoing');
        } else if (this.scopeResourcesPlanned === 'notPlaced') {
            this.resources = await this.$store.dispatch('getResourcesToursNotPlaced');
        } else if (this.scopeResourcesPlanned === 'pending') {
            this.resources = await this.$store.dispatch('getResourcesToursNotConfirmed');
        } else {
            this.resources = await this.$store.dispatch('getResourcesToursSet');
        }
        this.resources = await this.prepareScopeResources(this.resources);
        this.resourcesScope = this.resources;
        this.resourcesToBePaid = await this.$store.dispatch('getResourcesToursToBePaid');
        this.resourcesToBePaid = await this.prepareScopeResources(this.resourcesToBePaid);
        this.resourcesToBePaid.sort(this.dynamicSort('contact_organisation'));
        this.resourcesToBePaidScope = this.resourcesToBePaid;
        this.$store.dispatch('setLoadingApp', 0);
      },
      prepareScopeResources(resources) {
        for (let i = 0; i < resources.length; i++) {
            (resources[i].start) ? resources[i].start = this.formattedDate(resources[i].start, 'date') : resources[i].start = null;
            (resources[i].end) ? resources[i].end = this.formattedDate(resources[i].end, 'date') : resources[i].end = null;
            (resources[i].info_to_client) ? resources[i].info_to_client = this.formattedDate(resources[i].info_to_client, 'date') : resources[i].info_to_client = null;
            (resources[i].info_to_provider) ? resources[i].info_to_provider = this.formattedDate(resources[i].info_to_provider, 'date') : resources[i].info_to_provider = null;
            (resources[i].reserved) ? resources[i].reserved = this.formattedDate(resources[i].reserved, 'date') : resources[i].reserved = null;
            if (resources[i].statusResourceId === 9 || resources[i].organisation === null) {
                resources[i].isSelectable = false;
            }
        }
        return resources;
      },
      filterResources(resources) {
        if (this.scopeDates.start || this.scopeDates.end) {
            let start = this.scopeDates.start;
            let end = this.scopeDates.end;
            if (this.scopeDates.start && this.scopeDates.end && this.scopeDates.start > this.scopeDates.end) {
                this.$store.dispatch('errorMessage', 'between earlier please');
                return resources;
            }
            if (this.scopeDates.start) {
                resources = resources.filter(function(select_item) {
                    return select_item.start >= start;
                });
            }
            if (this.scopeDates.end) {
                resources = resources.filter(function(select_item) {
                    return select_item.start <= end;
                });
            }
        } 
        return resources;
      },

      selectResourceNotPaid(event){
        let countIsSelectable = this.resourcesToBePaidScope.filter(function(select_item) {
            return select_item.isSelectable;
        });
        // console.log(event.value, this.selectedNotPaid.length, countIsSelectable.length, this.resourcesToBePaidScope)
        if (this.selectedNotPaid.length === 0 || (event.value && this.selectedNotPaid.length === countIsSelectable.length)) {
            this.selectResourceNotPaidSingleSelect = true;
            this.selectedNotPaid = [];
            this.resourcesToBePaidScope.map( (x) => x.isSelectable = true );
            return;
        } else {
            this.selectResourceNotPaidSingleSelect = false;
            for (let i = 0; i < this.resourcesToBePaidScope.length; i++) {
                if (this.selectedNotPaid[0].contact_organisation === this.resourcesToBePaidScope[i].contact_organisation) {
                    this.resourcesToBePaidScope[i].isSelectable = true;
                } else {
                    this.resourcesToBePaidScope[i].isSelectable = false;
                }
            }
        }
        
      },

      selectResourceReservation() {
        if (this.selectedReservation.length === 0) {
            this.resourcesScope = this.resources;
            this.selectedReservationSingleSelect = true;
            return;
        } else {
                let tour_placement_id = this.selectedReservation[0].tour_placement_id;
                let start = this.selectedReservation[0].start;
                this.selectedReservationSingleSelect = false;
                this.resourcesScope = this.resources.filter(function(select_item) {
                    return select_item.tour_placement_id === tour_placement_id && select_item.start === start;
                });
            }
        },

        async makeReservation() {
            let attachments = [];
            let passportIncluded = false;
            let preferenciaAlimentaria = false;
            let emailIntro = "Estimado " + this.selectedReservation[0].contact_organisation + ",\n\n" + 
            "Presentamos la(s) nueva(s) reserva(s). Por favor, confirmar lo mas rápido posible.\n\n"
            emailIntro += "Tour: " + this.selectedReservation[0].tourName + "\n";
            emailIntro += "Fecha de salida: " + this.selectedReservation[0].start + "\n";
            emailIntro += "Fecha de retorno: " + this.selectedReservation[0].end + "\n";
            emailIntro += "Pax: " + this.selectedReservation.length + "\n";
            emailIntro += "Nombres: ";
            for (let i = 0; i < this.selectedReservation.length; i++) {
                (i > 0) ? emailIntro += ", " : emailIntro += ""
                emailIntro += this.selectedReservation[i].full_name;
                if (this.selectedReservation[i].food_preference === 'not known' || this.selectedReservation[i].food_preference === null) {
                    emailIntro += ' (*)';
                    preferenciaAlimentaria = true;
                } else if (this.selectedReservation[i].food_preference === 'I am vegan') {
                    emailIntro += ' (vegano)'
                } else if (this.selectedReservation[i].food_preference === 'I am vegetarian') {
                    emailIntro += ' (vegetariano)'
                }
                if (this.sendPassports) {
                    await this.$store.dispatch('getUploads', this.selectedReservation[i].client_id);
                    let passport = this.$store.state.uploadInfos.filter(function(select_item) {
                        return select_item.type === 'passport';
                    });
                    if (passport.length === 1) {
                        passportIncluded = true;
                        let content = await this.$store.dispatch('createAttachments', passport[0].id);
                        attachments.push(
                            {   
                                filename: passport[0].name,
                                path: content,
                            },
                        );
                    }
                }
            }
            emailIntro += "\n\n";
            if (preferenciaAlimentaria) {
                emailIntro += "(* = preferencia alimentaria no conocido)";
                emailIntro += "\n\n\n";
            }
            // emailIntro += "Idiomas: Ingles/Español" + "\n";
            // emailIntro += "Almuerzo: incluido" + "\n";
            // emailIntro += "Ingreso: incluido" + "\n\n";
            if (passportIncluded) {
                emailIntro += "Pasaportes estan aumentado." + "\n\n";
            }
            emailIntro += "Saludos cordiales, \n\n" + this.$store.state.user + "\n\n" +
            "office@proyectoperucentre.org\n" +
            "Proyecto Peru Centre\n" +
            "Avenida Arcopata 466-A\n" +
            "Cusco, Cusco, 45\n" +
            "+ 51 84 240 278"
            let payload = {
                submittedFrom: "TourAgencies",
                emailList: this.selectedReservation[0].emailContact,
                // emailList: 'mail4coen@gmail.com',
                emailSubject: "Reservación",
                emailIntro: emailIntro,
                messageHTML: '',
                messagePlain: '',
                openEmailDialog: true,
                saveInvoice: false,
                teacherId: null,
                resourceId: null,
                attachments: attachments,
                resources: this.selectedReservation,
            };
            this.$store.dispatch('emailMessage', payload);
            this.selectedReservation = [];
        },
      openContactInfo(editedItem) {
        let tmp = editedItem.contact_id;
        this.contact = this.$store.state.contacts.filter(function(select_item) {
            return select_item.id === tmp;
        })[0]
        this.dialogContactInfo = true;
      },

      editItem (item) {
        this.editedIndex = this.updatedTourPlacements.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      deleteItem (item) {
        this.editedIndex = this.updatedTourPlacements.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        let response = await this.$store.dispatch('deleteTourPlacement', this.editedItem.id)
        if (response.data.affectedRows === 1) {
            this.$store.dispatch('successMessage', 'Tour placement deleted');
        } else {
            this.$store.dispatch('errorMessage', 'Something went wrong');
        }
        this.updatedTourPlacements = JSON.parse(JSON.stringify(await this.$store.dispatch('getTourPlacements')));
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async openDialogEditResource(id) {
        let result = this.resourcesScope.filter(function(select_item) {
            return select_item.id === id;
        });
        if (result.length === 0) {
            result = await this.$store.dispatch('getResourcesTourId', id);
            // console.log('openDialogEditResource test3', result)
        }
        this.resourceEdit = Object.assign({}, result[0]);
        this.dialogEditResource = true;
      },

      openDialogEditResourcePayments(id) {
        let result = this.resourcesToBePaid.filter(function(select_item) {
            return select_item.id === id;
        })[0];
        this.resourceEdit = Object.assign({}, result);
        this.dialogEditResource = true;
      },

      async insertUpdateResource (resource) {
            await this.$store.dispatch('insertResource', resource);
            this.getResources();
        },

      getStatusResource(id) {
            if (id) {
                return this.$store.state.statusResources.filter((select_item) => {
                    return select_item.id === id;
                })[0];
            } else {
                return [];
            }
        },
        filterPlacements (tourId, placementId) {
            if (tourId) {
                    return this.updatedTourPlacements.filter(function(select_item) {
                        return select_item.tour_id === tourId && select_item.obsolete === null;
                    })
                } else {
                    if (placementId) {
                        return this.updatedTourPlacements.filter(function(select_item) {
                            return select_item.obsolete === null;
                        })
                    } else {
                        return this.updatedTourPlacements;
                }
            
                
            }
        },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async savePlacement () {
          if(this.$refs.dialogForm.validate()){
            let tmpOrgId = this.editedItem.organisation_id;
            let tmpTourId = this.editedItem.tour_id;
            console.log(this.updatedTourPlacements)
            let doubleName = this.updatedTourPlacements.filter(function(select_item) {
                return select_item.organisation_id === tmpOrgId && select_item.tour_id === tmpTourId;
            })
            console.log(doubleName)
            if (doubleName.length > 0 && this.editedIndex !== this.updatedTourPlacements.indexOf(doubleName[0])) {
                this.$store.dispatch('errorMessage', 'The placement "' + doubleName[0].name + '" already exists!');
                return;
            } else {
                await this.$store.dispatch('insertTourPlacement', this.editedItem)
                this.updatedTourPlacements = JSON.parse(JSON.stringify(await this.$store.dispatch('getTourPlacements')));
            }
            this.close()
          }
      },
      goClient(id, resourceId) {
            this.$store.dispatch('setLoadingApp', 1);
            this.$router.push({ name: 'Client', params: { id: id, resourceId: resourceId } })
        },
        setObsolete(event) {
            if (event) {
                this.editedItem.obsolete = dayjs().format("YYYY-MM-DD")
            } else {
                this.editedItem.obsolete = null
            }
            
        },
        changePlacement(event) {
            this.resourceEdit.payment = null;
            this.resourceEdit.info_to_provider = null;
            this.resourceEdit.reserved = null;
            this.resourceEdit.info_to_client = null;
            if (event) {
                let tourAgency = this.updatedTourPlacements.filter(function(select_item) {
                    return select_item.id === event;
                })[0];
                this.resourceEdit.currency = tourAgency.currency;
                this.resourceEdit.payment = tourAgency.payment;
            }
        },
        sumPaymentsAgency () {
            var paymentDollar = 0;
            for (let index = 0; index < this.selectedNotPaid.length; index++) {
                if (this.selectedNotPaid[index].currency === '$') {
                    paymentDollar = paymentDollar + this.selectedNotPaid[index].paymentOpen;
                } else {
                    paymentDollar = paymentDollar + this.selectedNotPaid[index].paymentOpen / this.selectedNotPaid[index].exchangeRate;
                }
            }
            return paymentDollar;
        },
        payAgency() {
            this.$store.dispatch('setLoadingApp', 1)
            let payment = 0;
             if (this.payInDollars) {
                payment = this.sumPaymentsAgency().toFixed(2)
             } else {
                 payment = (this.sumPaymentsAgency() * this.$store.state.exchangeRate).toFixed(2)
             }
            this.$router.push({ name: 'Financial', params: { paymentAgency: payment, payInDollars: this.payInDollars, resources: this.selectedNotPaid } })
        },
        openTransactionOutgoings(transIdOutgoings) {
            this.$store.dispatch('setLoadingApp', 1)
            this.$router.push({ name: 'Financial', params: { transIdOutgoings: transIdOutgoings } })
        },
        checkResourceDates() {
            if(this.resourceEdit.info_to_provider === null && this.resourceEdit.reserved === null) {
                this.resourceEdit.info_to_provider = this.resourceEdit.info_to_client; 
                this.resourceEdit.reserved = this.resourceEdit.info_to_client;
            }
            if(this.resourceEdit.reserved === null) {
                this.resourceEdit.reserved = this.resourceEdit.info_to_client;
            }
        },

        async createAgencyReport() {
            let payload = {
                report: this.selectedNotPaid,
            }
            await this.$store.dispatch('saveDataForReport', payload);
            this.$router.push({ name: 'TourAgenciesReport'});
        },
        
    },
}


</script>
