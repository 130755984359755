<template>
  <div>

    <v-snackbar v-model="progressBar" :timeout="-1" centered color="#ECEFF1" elevation="0">
      <div class="d-flex justify-center">
        <v-progress-linear
          v-model="progressAdvance"
          buffer-value="0"
          stream
          color="ppc_pink"
          height="5"
          reactive
        ></v-progress-linear>
      </div>
    </v-snackbar>

    <v-snackbar
      :value="loadingApp>0"
      centered
      elevation="0"
      color="transparent"
      timeout=-1
    >
      <div class="d-flex justify-center">
        <v-progress-circular
          v-if= "loadingAppProgress === 0"
          indeterminate
          color="ppc_pink"
          :value="loadingAppProgress"
          :width="5"
        >
        </v-progress-circular>
      </div>
    </v-snackbar>
    
    <v-snackbar v-model="alertSuccessMessage" :timeout="timeout" bottom color="success">
      {{ successMessage }}
    </v-snackbar>

    <v-snackbar v-model="alertErrorMessage" :timeout="$store.state.timeout" bottom color="error">
      {{ errorMessage }}
    </v-snackbar>

    <v-snackbar v-model="alertWarningMessage" :timeout="$store.state.timeout" bottom color="orange">
      {{ warningMessage }}
    </v-snackbar>

    <v-snackbar :value="alertVPS" :timeout="$store.state.timeout" top color="error">
      Package Maker is not connected!
    </v-snackbar>
    
    <v-snackbar :value="alertConnection" :timeout="$store.state.timeout" top color="error">
      No connection with the database established!
    </v-snackbar>

    <nav>
      <v-app-bar flat clipped-left app>
        <v-app-bar-nav-icon class="ppc_darkgreen--text text-darker-5" @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="d-flex flex-row">
          <v-btn class="text-uppercase ppc_darkgreen--text" href="./" text>
            <span class="font-weight-light d-none d-md-inline display-1">Proyecto</span>
            <span class="d-none d-md-inline display-1">Peru</span>
            <span class="font-weight-bold d-flex d-md-none larger--text display-2">P</span>
          </v-btn>
          <v-card-subtitle class="mt-3">v{{ $store.state.version }}</v-card-subtitle>
          <!-- {{ progressBar }} -->
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="mt-16 pt-4">
          <div class="d-flex flex-row">
            <v-btn 
              v-if="this.$route.name === 'TourAgenciesReport' || this.$route.name === 'FichaDeAlojamiento' || this.$route.name === 'CartaDePresentacion' || this.$route.name === 'PaymentAccommodationForm' || this.$route.name === 'PaymentTeacherForm' || this.$route.name === 'ClassScheduleStudent' || this.$route.name === 'ClassScheduleTeacher' || this.$route.name === 'AnnualReport' || this.$route.name === 'ClientApplicationForms'"
              color="ppc_darkgreen"
              class="white--text d-none d-sm-flex mb-1 mr-2"
              @click="goBack"
              :disabled = "loadingApp>0"
              small
            >
              Back
            </v-btn>
            <v-btn
              v-if="this.$route.name === 'TourAgenciesReport' || this.$route.name === 'PaymentAccommodationForm' || this.$route.name === 'ClassScheduleStudent' || this.$route.name === 'ClassScheduleTeacher'"
              color="ppc_darkgreen"
              class="white--text d-none d-sm-flex mb-1 mr-2"
              @click="openEmail"
              :disabled = "loadingApp>0"
              small
            >Email</v-btn>
            <v-btn 
              v-if="this.$route.name === 'TourAgenciesReport' || this.$route.name === 'FichaDeAlojamiento' || this.$route.name === 'CartaDePresentacion' || this.$route.name === 'PaymentAccommodationForm' || this.$route.name === 'PaymentTeacherForm' || this.$route.name === 'ClassScheduleStudent' || this.$route.name === 'ClassScheduleTeacher' || this.$route.name === 'ClientApplicationForms'"
              color="ppc_darkgreen"
              class="white--text d-none d-sm-flex mb-1 mr-2"
              @click="print()"
              :disabled = "loadingApp>0"
              small
            >
              Print/Download
            </v-btn>
          </div>
        </div>
        <v-snackbar v-model="alertLoggedOut" :timeout="$store.state.timeout" top color="success">
          You are signed out, goodbye {{ $store.state.user }}!
        </v-snackbar>
        <v-snackbar v-model="alertLoggedIn" :timeout="$store.state.timeout" top color="success">
          Welcome {{ $store.state.user }}!
        </v-snackbar>
        <v-spacer></v-spacer>
        <v-text-field
            placeholder="name, email or client nr"
            v-model="searchString"
            filled
            rounded
            dense
            prepend-inner-icon="$vuetify.icons.icon_mdi_magnify"
            class="mt-7"
            style="width: 100px"
            clearable
            @click:prepend-inner="searchClient()"
            @keydown="checkSearchReturn($event)"
            v-if="this.$route.name !== 'ClassScheduleStudent' && this.$route.name !== 'ClassScheduleTeacher'"
          ></v-text-field>
        <div v-if="!statusLoggedOut">
          <span class="mx-5">
            <v-btn @click="dialog_preferences = !dialog_preferences" text>
              <v-icon>$vuetify.icons.icon_mdi_account</v-icon>
              <span>
                {{ $store.state.user }}
              </span>
            </v-btn>
          </span>
          <span class="mx-5">
            <span class="font-weight-light d-none d-sm-inline">Sign Out</span>
            <v-btn icon color="font-weight-light" @click="onSignOut">
              <v-icon>$vuetify.icons.icon_mdi_logout_variant</v-icon>
            </v-btn>
          </span>
        </div>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" mobile-breakpoint ="600" expand-on-hover mini-variant app class="grey">
        <div class="d-flex flex-column">
          <v-list>
            <v-list-item v-for="link in upperLinks" :key="link.text" @click="goView(link.route)">
              <v-list-item-icon>
                  <v-icon v-if="link.text==='Package Maker' && `${alertMessagesPM}` > 0" small class="white--text">{{alertMessagesPM}}</v-icon>
                  <v-icon v-else small class="white--text">{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item v-for="link in belowLinks" :key="link.text" @click="goView(link.route)">
              <v-list-item-icon>
                  <v-icon small class="white--text">{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </div>
      </v-navigation-drawer>
    </nav>

    <v-dialog 
      v-model="dialog_preferences"
      :retain-focus="false"
      max-width="320px"
    >
      <v-flex>
        <v-card>
          <v-card-title>
            Preferences
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form @submit.prevent="onChangePreferences">
                <v-flex>
                  <v-text-field
                    name="newPassword"
                    label="Change Password"
                    id="newPassword"
                    v-model="newPassword"
                    filled
                    rounded
                    dense
                    :rules="passwordRules"
                    :error-messages="errorMessagePassword"
                    :type="passwordField ? 'password' : 'text'"
                    :append-icon="passwordField ? '$vuetify.icons.icon_mdi_eye' : '$vuetify.icons.icon_mdi_eye-off'"
                    @click:append="() => (passwordField = !passwordField)"
                    @blur="matchPassword"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex>
                  <v-text-field
                    name="confirmPassword"
                    label="Confirm Password"
                    id="confirmPassword"
                    v-model="confirmPassword"
                    filled
                    rounded
                    dense
                    :error-messages="errorMessageConfirmPassword"
                    :type="confirmedPasswordField ? 'password' : 'text'"
                    :append-icon="confirmedPasswordField ? '$vuetify.icons.icon_mdi_eye' : '$vuetify.icons.icon_mdi_eye-off'"
                    @click:append="() => (confirmedPasswordField = !confirmedPasswordField)"
                    @blur="matchPassword"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex d-flex justify-center>
                  <v-btn @click="dialog_preferences = !dialog_preferences" color="ppc_darkgreen  white--text" text>
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn type="submit" :disabled="loadingButton" :loading="loadingButton" color="ppc_darkgreen  white--text" text>
                    Save
                    <span slot="loader" class="custom-loader">
                      <v-icon light>$vuetify.icons.icon_mdi_cached</v-icon>
                    </span>
                  </v-btn>
                </v-flex>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-dialog>

    <v-dialog 
      v-model="dialog_email"
      :retain-focus="false"
      max-width="21cm"
    >
      <v-flex>
        <v-card>
          <v-card-title>
            Prepare your email
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form>
                <v-flex>
                  <v-text-field
                    v-model="emailList"
                    filled
                    rounded
                    label="to (seperated by commas)"
                  ></v-text-field>
                  <v-text-field
                    v-model="emailSubject"
                    filled
                    rounded
                    label="subject"
                  ></v-text-field>
                  <v-textarea
                    v-if="$store.state.emailMessage !== null"
                    v-model="emailIntro"
                    filled
                    rounded
                    dense
                    height="50vh"
                    no-resize
                    label="intro"
                  ></v-textarea>
                </v-flex>
                
                <v-flex d-flex justify-center>
                  <v-btn @click="dialog_email = !dialog_email" color="ppc_darkgreen  white--text" text>
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="sendEmail" color="ppc_darkgreen  white--text" text>
                    Send
                  </v-btn>
                </v-flex>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-dialog>

    <v-dialog 
      v-model="statusLoggedOut"
      :retain-focus="false"
      max-width="320px"
      persistent
    >
      <v-flex>
        <v-card>
          <v-card-title>
            Sign In
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form @submit.prevent="onSignin">
                <v-flex mt-3>
                  <v-text-field
                    name="user"
                    label="User"
                    id="user"
                    v-model="user"
                    type="user"
                    filled
                    rounded
                    dense
                    required>
                  </v-text-field>
                </v-flex>
                <v-flex>
                  <v-text-field
                    name="password"
                    label="Password"
                    id="password"
                    v-model="password"
                    filled
                    rounded
                    dense
                    :error-messages="errorMessagePasswordLogin"
                    :type="passwordFieldSignIn ? 'password' : 'text'"
                    :append-icon="passwordFieldSignIn ? '$vuetify.icons.icon_mdi_eye' : '$vuetify.icons.icon_mdi_eye-off'"
                    @click:append="() => (passwordFieldSignIn = !passwordFieldSignIn)"
                    required>
                  </v-text-field>
                </v-flex>
                <v-flex d-flex justify-center>
                  <v-btn type="submit" :disabled="loadingButton || loadingApp>0" :loading="loadingButton" color="ppc_darkgreen white--text">
                    Sign in
                    <span slot="loader" class="custom-loader">
                      <v-icon light>$vuetify.icons.icon_mdi_cached</v-icon>
                    </span>
                  </v-btn>
                </v-flex>
                <v-flex d-flex nowrap justify-end> 
                  v{{ $store.state.version }}
                </v-flex>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-dialog>
    <v-dialog
      v-model="dialog_search"
      :retain-focus="false"
    >
      <v-data-table
        :headers="headersSearch"
        :items="searchResults"
        sort-by="Fullname"
        :items-per-page="-1"
        disable-pagination
        hide-default-footer
        class="pa-8"
      >
        <template v-slot:[`item.full_name`]="{ item }">
            <v-btn small rounded @click="goClient(item.id)">{{ item.full_name }}</v-btn>
        </template>
        <template v-slot:[`item.gender`]="{ item }">
          <v-icon small v-if="`${item.gender}` === 'Male'" color="ppc_darkgreen">fa-male</v-icon>
          <v-icon small v-else-if="`${item.gender}` === 'Female'" color="ppc_darkgreen">fa-female</v-icon>
          <v-icon small v-else color="ppc_darkgreen">fa-genderless</v-icon>
        </template>
        <template v-slot:[`item.start_first`]="{ item }">
          {{ formattedDate(item.start_first) }}
        </template>
        <template v-slot:[`item.statusClientId`]="{ item }">
          <v-chip small :color="`${getStatus(item.statusClientId).color}`" text-color="white" v-html="`${getStatus(item.statusClientId).name}`"></v-chip>
        </template>

      </v-data-table>
    </v-dialog>

    <v-dialog
      v-model="dialogInvoice"
      :retain-focus="false"
      width="fit-content"
    >
      <div class="d-flex justify-space-around">
        <v-card 
            flat
            class="d-flex flex-column pa-12"
            width="210mm"
            min-height="297mm"
        >
            <span id="printSection" v-html="messageHTML"></span>
            <div class="d-flex flex-column invoiceButtons">
                
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="ppc_pink"
                    fab
                    class="white--text my-2"
                    @click="dialogInvoice = false"
                    small
                    v-on="on"
                  >
                    <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>close</span>
              </v-tooltip>

              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="orange"
                    fab
                    class="white--text my-2"
                    @click="openEmail"
                    small
                    v-on="on"
                  >
                    <v-icon color="white">mdi-email</v-icon>
                  </v-btn>
                </template>
                <span>email</span>
              </v-tooltip>
              
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="blue"
                    fab
                    class="white--text my-2"
                    @click="print('invoice')"
                    small
                    v-on="on"
                  >
                    <v-icon color="white">mdi-printer</v-icon>
                  </v-btn>
                </template>
                <span>print/download</span>
              </v-tooltip>

              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="brown"
                    fab
                    class="white--text my-2"
                    @click="insertInvoice"
                    small
                    :disabled="archiveInvoice"
                    v-on="on"
                  >
                    <v-icon color="white">mdi-content-save</v-icon>
                  </v-btn>
                </template>
                <span>save</span>
              </v-tooltip>
            </div>
            <div class="d-flex flex-column invoiceControlPanel">  
              <v-card color="grey lighten-3" class="d-flex flex-column mt-3 pa-4 elevation-5" v-if = showInvoiceControlPanel()>
                <v-checkbox 
                    v-model="showTitleOnInvoice"
                    label="Show app title"
                    @change="createInvoice"
                    class="mt-0 pt-0"
                ></v-checkbox>
                <v-radio-group v-model="language" @change="createInvoice" label="Language" column class="pa-0 ma-0">
                  <v-radio
                      label = "English"
                      value="english"
                  ></v-radio>
                  <v-radio
                      label = "Spanish"
                      value="spanish"
                  ></v-radio>
                </v-radio-group>
                <v-radio-group v-model="groupBy" @change="createInvoice" label="Group by" column class="pa-0 ma-0">
                  <v-radio
                      label = "Client"
                      value="full_name"
                  ></v-radio>
                  <v-radio
                      label = "Service"
                      value="name"
                  ></v-radio>
                  <v-radio
                      label = "Type"
                      value="type"
                  ></v-radio>
                </v-radio-group>
                <v-radio-group v-model="showCurrency" @change="createInvoice" label="Total payment" column class="pa-0 ma-0">
                    <v-radio
                        label = "Dollars only"
                        value="dollars"
                    ></v-radio>
                    <v-radio
                        label = "Soles only"
                        value="soles"
                    ></v-radio>
                    <v-radio
                        label = "Both"
                        value="both"
                    ></v-radio>
                </v-radio-group>
                <v-checkbox 
                    v-model="addDeposit"
                    label="Add Deposit"
                    @change="createInvoice"
                    class="mt-0 pt-0"
                ></v-checkbox>
                <v-checkbox 
                    v-model="showWisePayment"
                    label="Show Wise (eng)"
                    @change="createInvoice"
                    class="mt-0 pt-0"
                ></v-checkbox>
                <v-checkbox 
                    v-model="showNubizPayment"
                    label="Show Nubiz Link"
                    @change="createInvoice"
                    class="mt-0 pt-0"
                ></v-checkbox>
                <v-checkbox 
                    v-model="showSolesPayment"
                    label="Show Soles Acc"
                    @change="createInvoice"
                    class="mt-0 pt-0"
                    :disabled="showCurrency==='dollars'"
                ></v-checkbox>
                <v-checkbox 
                    v-model="showCurrentBalance"
                    label="Show Current Balance"
                    @change="createInvoice"
                    class="mt-0 pt-0"
                    :disabled="showCurrency==='dollars'"
                ></v-checkbox>
              </v-card>
            </div>
        </v-card>
      </div>
    </v-dialog>
    
  </div>
</template>


<script>
import CryptoJS from 'crypto-js';
import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import getDateFromTimestamp from '../mixins/getDateFromTimestamp';
import getServicesApplied from '../mixins/getServicesApplied';
import radioGroups_filtered from '../mixins/radioGroups_filtered';
import options_filtered_selected from '../mixins/options_filtered_selected';
import compare from '../mixins/compare';
import getStatusApp from '../mixins/getStatusApp';
import zlib from 'zlib';
import { print } from 'html-to-printer';
import dayjs from 'dayjs';
import "dayjs/locale/es";

export default {
    data() {
    return {
      drawer: true,
      upperLinks: [
          { icon: '$vuetify.icons.icon_mdi_account_plus', text: 'Leads', route: '/Leads' },
          { icon: '$vuetify.icons.icon_mdi_account_multiple', text: 'Clients', route: '/Clients' },
          { icon: '$vuetify.icons.icon_mdi_package_variant', text: 'Package Maker', route: '/PackageMaker' },
          { icon: '$vuetify.icons.icon_mdi_file_document_multiple', text: 'Applications', route: '/Applications' },
          { icon: '$vuetify.icons.icon_mdi_archive_outline', text: 'Invoice Archive', route: '/Invoices' },
      ],
      belowLinks: [],
      alertLoggedIn: false,
      alertLoggedOut: false,
      passwordField: String,
      confirmedPasswordField: String,
      passwordFieldSignIn: String,
      errorMessagePasswordLogin: '',
      errorMessagePassword: '',
      errorMessageConfirmPassword: '',
      user: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      dialog_preferences: false,
      alertSuccessMessage: false,
      alertErrorMessage: false,
      alertWarningMessage: false,
      timeout: 3000,
      path: null,
      emailIntro: null,
      emailSubject: null,
      emailList: null,
      dialog_email: false,
      searchResults: [],
      headersSearch: [
        { text: 'Full Name', align: 'start', value: 'full_name' },
        { text: 'Email', value: 'email' },
        { text: 'Gender', value: 'gender', width: "100px", sortable: false},
        { text: 'Nationality', value: 'nationality' },
        { text: 'Source/Agency', value: 'source' },
        { text: 'Services', value: 'amountServices', sortable: false},
        { text: 'Start', value: 'start_first' },
        { text: 'Status', value: 'statusClientId' },
      ],
      dialog_search: false,
      searchString: null,
      dialogInvoice: false,
      messageHTML: '',
      groupBy: 'full_name',
      language: 'english',
      showCurrency: 'both',
      showWisePayment: true,
      addDeposit: false,
      showNubizPayment: true,
      showSolesPayment: false,
      showCurrentBalance: true,
      applicationId: null,
      dataSaveInvoice: {},
      archiveInvoice: false,
      showTitleOnInvoice: false,
      messageHTMLOriginal: null,
    }
  },

  mixins: [inputRules, formattedDate, getDateFromTimestamp, getServicesApplied, radioGroups_filtered, options_filtered_selected, compare, getStatusApp],

  mounted() {
    setInterval(() => {
      this.$store.dispatch('checkWithPM');
    }, 600000);
  },

  watch: {
    errorLogIn: function() {
      if (this.errorLogIn === null) {
          this.errorMessagePasswordLogin = ''
        } else {
          this.errorMessagePasswordLogin = this.errorLogIn
        }
    },
    statusLoggedOut(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.dialog_sign_in = false;
        this.alertLoggedIn = true;
        if (this.password === '123456') {
          this.dialog_preferences = true;
        }
      }
      if (newValue) {
        this.alertLoggedOut = true;
      }
    },
    alertVPS: function() {
      this.alertVPS = true;
    },
    alertConnection: function() {
      if (this.alertConnection) {
        this.alertConnection = true;
      }
    },
    emailMessage(newValue, oldValue) {
      console.log(newValue, oldValue)
      if (newValue.openEmailDialog) {
        this.openEmail();
      }
    },
    displaySuccessMessage: function () {
      if (this.successMessage !== "") {
        this.alertSuccessMessage = true;
      }
    },
    displayErrorMessage: function () {
      if (this.errorMessage !== "") {
        this.alertErrorMessage = true;
      }
    },
    displayWarningMessage: function () {
      if (this.warningMessage !== "") {
        this.alertWarningMessage = true;
      }
    },
    accessMenu() {
      this.belowLinks = [];
      if (this.$store.state.accessTeaching === 1) {
        this.belowLinks.push({ icon: '$vuetify.icons.icon_languages', text: 'Teaching', route: '/Teaching' });
      }
      if (this.$store.state.accessAccommodation === 1) {
        this.belowLinks.push({ icon: '$vuetify.icons.icon_accommodation', text: 'Accommodation', route: '/Accommodation' });
      }
      if (this.$store.state.accessProjects === 1) {
        this.belowLinks.push({ icon: '$vuetify.icons.icon_volunteer', text: 'Projects', route: '/ProjectPlacements' });
      }
      if (this.$store.state.accessProjects === 1) {
        this.belowLinks.push({ icon: '$vuetify.icons.icon_tours', text: 'Tours', route: '/TourAgencies' });
      }
      if (this.$store.state.accessFinancial === 1) {
        this.belowLinks.push({ icon: '$vuetify.icons.icon_mdi_cash_multiple', text: 'Financial', route: '/Financial' });
      }
      if (this.$store.state.accessClientAgencies === 1) {
        this.belowLinks.push({ icon: '$vuetify.icons.icon_mdi_domain', text: 'Agencies', route: '/ClientAgencies' });
      }
      this.belowLinks.push(
        { icon: '$vuetify.icons.icon_mdi_cogs', text: 'Services', route: '/AvailableServices' },
        { icon: '$vuetify.icons.icon_mdi_account_group', text: 'Contacts', route: '/Contacts' },
      );
    },
    showInvoice() {
      if (this.$store.getters.showInvoice) {
        this.displayInvoice();
      }
    },
  },
  computed: {
      statusLoggedOut () {
        return this.$store.getters.statusLoggedOut
      },
      loadingApp () {
        return this.$store.getters.loadingApp
      },
      loadingAppProgress () {
        return this.$store.getters.loadingAppProgress
      },
      progressBar () {
        return this.$store.getters.progressBar
      },
      progressAdvance () {
        return this.$store.getters.progressAdvance
      },
      loadingButton () {
        return this.$store.getters.loadingButton
      },
      errorLogIn () {
        return this.$store.getters.errorLogIn
      },
      alertVPS () {
        return this.$store.getters.alertVPS
      },
      alertConnection () {
        return this.$store.getters.alertConnection
      },
      emailMessage () {
        return this.$store.getters.emailMessage
      },
      successMessage () {
        return this.$store.getters.successMessage
      },
      errorMessage () {
        return this.$store.getters.errorMessage
      },
      warningMessage () {
        return this.$store.getters.warningMessage
      },
      displaySuccessMessage () {
        return this.$store.getters.displaySuccessMessage
      },
      displayErrorMessage () {
        return this.$store.getters.displayErrorMessage
      },
      displayWarningMessage () {
        return this.$store.getters.displayWarningMessage
      },
      alertMessagesPM () {
        return this.$store.getters.messagesPM.length
      },
      accessMenu () {
        return this.$store.getters.accessMenu
      },
      showInvoice () {
          return this.$store.getters.showInvoice
      },
    },
    methods: {
      async goView(path) {
        if (this.$route.path !== path) {
          this.$router.push(path);
        }
      },
      matchPassword () {
        if (this.newPassword !== this.confirmPassword) {
          this.errorMessageConfirmPassword = 'Password does not match';
        } else {
          this.errorMessageConfirmPassword = '';
        }
      },
      onSignin () {
        this.$store.dispatch('setLoadingButton', true)
        this.$store.dispatch('clearError')
        let passhash = CryptoJS.SHA256(this.password).toString();
        const payload = {
          user: this.user,
          password: passhash,
        }
        this.$store.dispatch('signUserIn', payload);
      },
      onSignOut () {
        this.$store.dispatch('signUserOut', true)
      },
      async print (form) {
        if (form === 'invoice') {
          print(this.messageHTML);
        } else {
          window.print();
          if (this.$route.name === 'ClassScheduleTeacher') {
            this.saveCurrentScheduleTeacher();
          }
          if (this.$route.name === 'ClassScheduleStudent') {
            this.$store.dispatch('updateStudentSchedule', this.$store.state.emailMessage.resourceId);
          }
        }
        
      },
      openEmail () {
        this.submittedFrom = this.$store.state.emailMessage.submittedFrom;
        this.emailIntro = this.$store.state.emailMessage.emailIntro;
        this.emailSubject = this.$store.state.emailMessage.emailSubject;
        this.emailList = this.$store.state.emailMessage.emailList;
        this.dialog_email = true;
      },
      async sendEmail () {
        this.$store.dispatch('setLoadingApp', 1);
        this.messageHTMLOriginal = this.$store.state.emailMessage.messageHTML;
        let messageHTML = "<html><body style='max-width: 21cm; margin: 20px; font-family: Raleway, Arial, Helvetica, sans-serif;'><span>" + 
        this.emailIntro + "</span><br><br>" + this.$store.state.emailMessage.messageHTML + "</body></html>";
        messageHTML = messageHTML.replace(/\n/g, "<br />");
        messageHTML = messageHTML.replace(/<google>/g, "<a href='https://www.google.com/search?q=proyecto%20peru&sxsrf=ALeKk01w0t_6jBUmS4wAhC6ShgbQb1O-XQ:1627373829160&source=hp&ei=_cD_YM-CAcGIafGcsfgO&iflsig=AINFCbYAAAAAYP_PDTqt8YGv_dAxkU83fnt5PQY_l9-S&oq=proye&gs_lcp=Cgdnd3Mtd2l6EAMYADIECCMQJzICCC4yAggAMgIIADICCAAyAggAMgUIABDLATICCAAyBQgAEMsBMgUIABDLAToECAAQQzoICC4QxwEQowI6BQgAEJECOgQIABAKUPYrWNYxYOM7aABwAHgAgAFjiAGFA5IBATWYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&tbs=lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!1m4!1u16!2m2!16m1!1e1!1m4!1u16!2m2!16m1!1e2!2m1!1e2!2m1!1e16!2m1!1e3!3sIAE,lf:1,lf_ui:2&tbm=lcl&rflfq=1&num=10&rldimm=4342304706273858016&lqi=Cg1wcm95ZWN0byBwZXJ1SOjP9YbdgYCACFoVEAAYABgBIg1wcm95ZWN0byBwZXJ1kgEPbGFuZ3VhZ2Vfc2Nob29sqgEQEAEqDCIIcHJveWVjdG8oDg&ved=2ahUKEwiEuIP454LyAhUpsKQKHWmlC-AQvS4wDHoECBIQMg&rlst=f#rlfi=hd:;si:4342304706273858016,l,Cg1wcm95ZWN0byBwZXJ1SOjP9YbdgYCACFoVEAAYABgBIg1wcm95ZWN0byBwZXJ1kgEPbGFuZ3VhZ2Vfc2Nob29sqgEQEAEqDCIIcHJveWVjdG8oDg;mv:[[-6.1240304,-71.3310992],[-17.629842,-79.4959938]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!1m4!1u16!2m2!16m1!1e1!1m4!1u16!2m2!16m1!1e2!2m1!1e2!2m1!1e16!2m1!1e3!3sIAE,lf:1,lf_ui:2'>Google</a>")
        messageHTML = messageHTML.replace(/<facebook>/g, "<a href='https://www.facebook.com/ProyectoPeru'>Facebook</a>")
        messageHTML = messageHTML.replace(/<online classes>/g, "<a href='https://proyectoperucentre.org/online-spanish-classes.php'>Online Spanish Classes</a>")
        messageHTML = messageHTML.replace(/<Spanish Placement Test>/g, "<a href='https://docs.google.com/forms/d/1u2WVaaYdNo0n22Y8MeHeb3gtwXPhsJg34VAcN1TvL6Q'>Spanish Placement Test</a>")
        messageHTML = messageHTML.replace(/<information on tours>/g, "<a href='https://proyectoperucentre.org/tours.php'>here</a>")
        messageHTML = messageHTML.replace(/<office email>/g, "<a href='office@proyectoperucentre.org'>office@proyectoperucentre.org</a>")
        messageHTML = messageHTML.replace(/<Application Form>/g, "<a href='https://proyectoperucentre.org/form'>Application Form</a>")
        let payload = {
          subject: this.emailSubject,
          emailList: this.emailList,
          messageHTML: messageHTML,
          messagePlain: this.$store.state.emailMessage.messagePlain,
          saveInvoice: this.$store.state.emailMessage.saveInvoice,
          attachments: this.$store.state.emailMessage.attachments,
          submittedFrom: this.submittedFrom,
        }
        this.dialog_email = false;
        let result = await this.$store.dispatch('email', payload);
        if (result === 'OK' && this.archiveInvoice === false && this.$store.state.emailMessage.saveInvoice === true) {
          this.insertInvoice();
          this.archiveInvoice = true;
        }
        if (result === 'OK' && this.$route.name === 'ClassScheduleTeacher') {
          this.saveCurrentScheduleTeacher();
        }
        if (result === 'OK' && this.$route.name === 'ClassScheduleStudent') {
          this.$store.dispatch('updateStudentSchedule', this.$store.state.emailMessage.resourceId);
        }
        if (result === 'OK' && this.$route.name === 'TourAgencies') {
          let resources = this.$store.state.emailMessage.resources;
          const date = new Date();
          let today = date.getTime();
          for (let i = 0; i < resources.length; i++) {
            resources[i].info_to_provider = this.formattedDate(today, 'date');
            await this.$store.dispatch('insertResource', resources[i]);
            await this.$store.dispatch('reloadResourcesTourAgencies', true);
          }
        }
        this.$store.dispatch('setLoadingApp', 0);
      },
      saveCurrentScheduleTeacher() {
        let payload = {
          id: this.$store.state.emailMessage.teacherId,
          schedule: new Date().toISOString().slice(0, 19).replace('T', ' '),
        } 
        this.$store.dispatch('updateTeacherSchedule', payload);
      },
      onChangePreferences () {
        if (this.newPassword.length > 0) {
          this.matchPassword();
          if (this.errorMessagePassword === '' && this.errorMessageConfirmPassword === '') {
            this.$store.dispatch('setLoadingButton', true)
            let passhash = CryptoJS.SHA256(this.newPassword).toString();
            const payload = {
              id: this.$store.state.uid,
              password: passhash,
            }
            console.log(payload)
            this.$store.dispatch('changePsw', payload)
            this.dialog_preferences = false;
          }
        } else {
          this.dialog_preferences = false;
        }
      },
      goBack () {
        console.log('navbar ',this.$store.state.breadcrumbs, this.$store.state.breadcrumbs.length)
        if (this.$store.state.breadcrumbs.length > 1) {
          console.log(this.$store.state.breadcrumbs[this.$store.state.breadcrumbs.length - 2])
          this.$router.push({ name: this.$store.state.breadcrumbs[this.$store.state.breadcrumbs.length - 2], params: { id: this.$store.state.client.id } })
        } else {
          this.$router.push({ name: this.$store.state.breadcrumbs[0] })
        }
      },
      async searchClient() {
        this.$store.dispatch('setLoadingApp', 1);
        this.searchResults = await this.$store.dispatch('searchClient', this.searchString)
        if (this.searchResults.length === 0) {
          this.$store.dispatch('warningMessage', 'No results found');
          this.$store.dispatch('setLoadingApp', 0);
        } else if (this.searchResults.length === 1) {
          this.goClient(this.searchResults[0].id)
        } else {
          for (let index = 0; index < this.searchResults.length; index++) {
            if(this.searchResults[index].source === null) {
                this.searchResults[index].source = this.searchResults[index].agency;
            }
          }
          this.$store.dispatch('setLoadingApp', 0);
          this.dialog_search = true;
        }
      },
      checkSearchReturn(event) {
        if (event.code === 'Enter') {
          this.searchClient()
        }
      },
      goClient(id) {
        this.dialog_search = false;
        if (this.$router.history.current.name === 'Client') {
          this.$store.dispatch('setLoadingApp', 1);
          this.$store.dispatch('changeCurrentClient', id);
        } else {
          this.$store.dispatch('setLoadingApp', 1);
          this.$router.push({ name: 'Client', params: { id: id } })
        }
      },
      getStatus(id) {
          return this.$store.state.statusClients.filter((select_item) => {
                return select_item.id === id;
              })[0];
        },

      async createInvoice() {
        this.$store.dispatch('setLoadingApp', 1);
        await this.$store.dispatch('getAvailableAgencies');
        let application = await this.$store.dispatch('getApplicationId', this.applicationId);
        let services = await this.$store.dispatch('getServicesAppId', this.applicationId);
        let radioGroups = await this.$store.dispatch('getRadioGroupsAppId', this.applicationId);
        let options = await this.$store.dispatch('getOptionsAppId', this.applicationId);
        let applicationIncome = await this.$store.dispatch('getDistributionsApplicationId', this.applicationId);
        let currencyExtra = '$';
        let servicePriceExtra = application.extraChargeUSD;
        if (this.showCurrency === 'dollars') {
          this.showSolesPayment = false;
        }
        // if (this.showCurrency === 'soles') {
        //   this.showWisePayment = false;
        // }
        if (application.extraChargeUSD) {
            if (this.showCurrency === 'soles') {
                currencyExtra = 'S/.';
                servicePriceExtra = parseFloat(application.extraChargeSoles).toFixed(2);
            }
            services[services.length] = {
                name: application.extraCharge,
                full_name: '',
                type: 'Extra',
                duration: '',
                duration_text: '',
                start: '',
                currency: currencyExtra,
                servicePrice: Number(servicePriceExtra),
            }
        }
        for (let i = 0; i < services.length; i++) {
            if (services[i].desc_incl) {
              services[i].name += ', ' + services[i].desc_incl
            }
            if (radioGroups) {
                for (let j = 0; j < radioGroups.length; j++) {
                    if (radioGroups[j].services_id === services[i].id) {
                        services[i].name += ', ' + radioGroups[j].radio_group_value
                    }
                }
            }
            if (options) {
                for (let j = 0; j < options.length; j++) {
                    if (options[j].services_id === services[i].id && options[j].selected) {
                        services[i].name += ', ' + options[j].option_name
                    }
                }
            }
            console.log('createInvoice', services[i].currency, services[i].servicePrice)
            if (!services[i].servicePrice) {
              services[i].servicePrice = 0;
            }
            if (this.showCurrency === 'soles' && services[i].currency === '$') {
              services[i].servicePrice = services[i].servicePrice * this.$store.state.exchangeRate;
              services[i].currency = 'S/.'
            }
            if (this.showCurrency === 'dollars' && services[i].currency === 'S/.') {
              services[i].servicePrice = services[i].servicePrice / this.$store.state.exchangeRate;
              services[i].currency = '$'
            }
        }
        let result = services.map((item) => {
            return {
                name: item.name,
                full_name: item.full_name,
                type: item.type,
                duration: item.duration + " " + item.duration_text,
                start: this.formattedDate(item.start, "Noday"),
                price: item.currency + parseFloat(item.servicePrice).toFixed(2),
            };
        });
        this.dataSaveInvoice = {
            content: '',
            application_id: this.applicationId,
            payerClient: null,
            payerAgency: null,
            payerName: '',
            status: this.getStatusApp(application.statusAppId).name,
            appTitle: application.appTitle,
            price: parseFloat(application.totalPrice).toFixed(2),
            comments: application.comments,
        };
        await this.getUser(application);
        let emailIntro = await this.getIntro(this.payer.name);
        // Plain text version 
        this.messagePlain = "Invoice from Proyecto Peru for " + this.payer.name + ".\r\n" +
        "Date: " + this.getDateFromTimestamp(application.invoice_date) + "\r\n"
        // HTML version
        let messageHeader = "<table style='width: 100%; margin-top: 0cm; font-family: Raleway, Arial, Helvetica, sans-serif;'>" +
            "<tr style='height: 4cm'>" +
                "<td style='font-family: Roboto, sans-serif; font-size: 32px; color: #35b729; text-align: left; vertical-align: top; margin-bottom: 1cm;'><span>PROYECTO</span><strong>PERU</strong></td>" +
                "<td style='font-size: 32px; color: grey; text-align: right; vertical-align: top; margin-top: 5cm;'>"
                  if (this.language === 'english') {
                    dayjs.locale("en");
                    messageHeader += "<div>INVOICE</div>"
                    messageHeader += "<div style='font-size: 18px;'>" + 
                        this.getDateFromTimestamp(application.invoice_date) + 
                    "</div>"
                  } else {
                    dayjs.locale("es");
                    messageHeader += "<div>FACTURA</div>"
                    let fecha = dayjs(this.getDateFromTimestamp(application.invoice_date)).format("ddd MMM DD YYYY");
                    messageHeader += "<div style='font-size: 18px;'>" + 
                        fecha + 
                    "</div>"
                  }
                    messageHeader += "<div style='font-size: 12px;'>refnr: " + 
                        this.getRefnr(application.id) +
                    "</div>" + 
                "</td>" + 
            "</tr>" + 
            "<tr style='height: 5cm'>" +
                "<td style='font-size: 14px; text-align: left; vertical-align: top;'>"
                    if (this.payer.nameFormal) { 
                            messageHeader += "<h3 style='color: grey;'>" + this.payer.nameFormal + "</h3>" 
                        }
                        else {
                            messageHeader += "<h3 style='color: grey;'>" + this.payer.name + "</h3>" 
                        }
                    if (this.payer.idFormal) { messageHeader += "<div>Company number: " + this.payer.idFormal + "</div>" }
                    if (this.payer.address) { messageHeader += "<div>" + this.payer.address + "</div>" }
                    if (this.payer.country) { messageHeader += "<div>" + this.payer.country + "</div>" }
                    if (this.payer.phone) { messageHeader += "<div>" + this.payer.phone + "</div>" }
                    if (this.payer.nationality) { messageHeader += "<div>" + this.payer.nationality + "</div>" }
                    if (this.payer.email) { messageHeader += "<div>" + this.payer.email + "</div>" }
                messageHeader += "</td>" +
                "<td style='font-size: 14px; text-align: right; vertical-align: top;'>" + 
                    "<h3 style='color: grey;'>Proyecto Peru Centre S.A.C.</h3>" +
                    "<div>Avenida Arcopata 466-A</div>" +
                    "<div>Cusco, Cusco, 45</div>" +
                    "<div>+ 51 950 301 314</div>" +
                    "<div>info@proyectoperucentre.org</div>" +
                "</td>" +
                "</tr>" + 
        "</table>"
        var messageBody = '';
        if (this.showTitleOnInvoice) {
          messageBody += '<h3>' + application.appTitle + '</h3><br>'
        }
        var group = null;
        if (this.groupBy === "full_name") {
            result.sort(this.dynamicSort('full_name'));
            messageBody += "<div style='margin-bottom: 1cm; font-size: 12px;'><table style='width: 100%;'>" +
            "<colgroup>" +
            "<col span='1' style='width: 55%;'>" +
            "<col span='1' style='width: 15%;'>" +
            "<col span='1' style='width: 15%;'>" +
            "<col span='1' style='width: 15%;'>" +
            "</colgroup>"
            if (this.language === 'english') {
              messageBody += "<tr><th style='text-align: left; text-indent: 10px'>Service</th><th style='text-align: left;'>Duration</th><th style='text-align: left;'>From</th><th style='text-align: left;'>Price</th></tr>";
            } else {
              messageBody += "<tr><th style='text-align: left; text-indent: 10px'>Servicio</th><th style='text-align: left;'>Duración</th><th style='text-align: left;'>Desde</th><th style='text-align: left;'>Precio</th></tr>";
            }
            for (let index = 0; index < result.length; index++) {
                if (group !== result[index].full_name) {
                    messageBody += "<tr><td colspan='4' style='background-color:#EAECEE'><strong>" + result[index].full_name + "</strong></td></tr>";
                    group = result[index].full_name;
                }
                messageBody += "<tr><td style='text-indent: 10px'>" + result[index].name + "</td><td>" + result[index].duration + "</td><td>" + result[index].start + "</td><td>" + result[index].price + "</td></tr>";
                this.messagePlain += "Service: " + result[index].name + " Duration: " + result[index].duration + " From: " + result[index].start + " Price: " + result[index].price + "\r\n ";
            }
        }
        if (this.groupBy === "name") {
            result.sort(this.dynamicSort('name'));
            messageBody += "<div style='margin-bottom: 1cm; font-size: 12px;'><table style='width: 100%;'>" + 
            "<colgroup>" +
            "<col span='1' style='width: 55%;'>" +
            "<col span='1' style='width: 15%;'>" +
            "<col span='1' style='width: 15%;'>" +
            "<col span='1' style='width: 15%;'>" +
            "</colgroup>" +
            "<tr><th style='text-align: left; text-indent: 10px'>Client</th><th style='text-align: left;'>Duration</th><th style='text-align: left;'>From</th><th style='text-align: left;'>Price</th></tr>";
            let amountOfClients = 0;
            for (let index = 0; index < result.length; index++) {
                amountOfClients = amountOfClients + 1;
                if (group !== result[index].name) {
                    if (index > 0) {
                      messageBody += "<tr><td colspan='4'>Total: " + amountOfClients + "x</td></tr>";
                    }
                    amountOfClients = 0;
                    messageBody += "<tr><td colspan='4' style='background-color:#EAECEE'><strong>" + result[index].name + "</strong></td></tr>";
                    group = result[index].name;
                }
                messageBody += "<tr><td style='text-indent: 10px'>" + result[index].full_name + "</td><td>" + result[index].duration + "</td><td>" + result[index].start + "</td><td>" + result[index].price + "</td></tr>";
                this.messagePlain += "Service: " + result[index].full_name + " Duration: " + result[index].duration + " From: " + result[index].start + " Price: " + result[index].price + "\r\n ";
            }
            amountOfClients = amountOfClients + 1;
            messageBody += "<tr><td colspan='4'>Total: " + amountOfClients + "x</td></tr>";
        }
        if (this.groupBy === "type") {
            result.sort(this.dynamicSort('type'));
            messageBody += "<div style='margin-bottom: 1cm; font-size: 12px;'><table style='width: 100%;'>" + 
            "<colgroup>" +
            "<col span='1' style='width: 35%;'>" +
            "<col span='1' style='width: 35%;'>" +
            "<col span='1' style='width: 15%;'>" +
            "<col span='1' style='width: 15%;'>" +
            "</colgroup>" +
            "<tr><th style='text-align: left; text-indent: 10px'>Client</th><th style='text-align: left;'>Service</th><th style='text-align: left;'>Duration</th><th style='text-align: left;'>From</th><th style='text-align: left;'>Price</th></tr>";
            for (let index = 0; index < result.length; index++) {
                if (group !== result[index].type) {
                    messageBody += "<tr><td colspan='5' style='background-color:#EAECEE'><strong>" + result[index].type + "</strong></td></tr>";
                    group = result[index].type;
                }
                messageBody += "<tr><td style='text-indent: 10px'>" + result[index].full_name + "</td><td>" + result[index].name + "</td><td>" + result[index].duration + "</td><td>" + result[index].start + "</td><td>" + result[index].price + "</td></tr>";
                this.messagePlain += "Service: " + result[index].full_name + " Duration: " + result[index].duration + " From: " + result[index].start + " Price: " + result[index].price + "\r\n ";
            }
        }
        messageBody += "</table></div>";
        messageBody += "<table style='width: 100%;'>";
        if (application.fixedPrice) {
            messageBody +=  "<tr>" +
                                "<td style='text-align: right;'>Corrected Price:</td><td style='text-align: right;'>$" + parseFloat(application.fixedPrice).toFixed(2) + "</td>" +
                            "</tr>"
        }
        if (application.discountPercentage) {
            messageBody +=  "<tr>" +
                                "<td style='text-align: right;'>" + application.discountPercentage + "% discount:</td><td style='text-align: right;'> -$" + parseFloat(application.discount).toFixed(2) + "</td>" +
                            "</tr>"
        } else if (application.discount) {
            messageBody +=  "<tr>" +
                                "<td style='text-align: right;'>Discount:</td><td style='text-align: right;'>-$" + parseFloat(application.discount).toFixed(2) + "</td>" +
                            "</tr>"
        }
        if (application.refund) {
            messageBody +=  "<tr>" +
                                "<td style='text-align: right;'>Refund:</td><td style='text-align: right;'>-$" + parseFloat(application.refund).toFixed(2) + "</td>" +
                            "</tr>"
        }
        if (this.showCurrency === 'both') {
            messageBody += "<tr>" +
                "<td></td><td style='text-align: right;'>" +
                "<div>" +
                "<span style='margin-right: 1cm;'>Total:</span><span style='padding-left: 1cm; font-size: 24px; color: #35b729; border-top: 1px solid black;'>" + parseFloat(application.totalPrice).toFixed(2) + " USD</span>" +
                "<div style='color: grey;'>(" + 
                  parseFloat(application.totalPriceSoles).toFixed(0) +
                " PEN)</div>" +
                "</div></td>" +
            "</tr>"
        }
        if (this.showCurrency === 'dollars') {
            messageBody += "<tr>" +
                "<td></td><td style='text-align: right;'>" +
                "<div>" +
                "<span style='margin-right: 1cm'>Total:</span><span style='padding-left: 1cm; font-size: 24px; color: #35b729; border-top: 1px solid black;'>" + parseFloat(application.totalPrice).toFixed(2) + " USD</span>" +
                "</div></td>" +
            "</tr>"
        }
        if (this.showCurrency === 'soles') {
            messageBody += "<tr>" +
                "<td></td><td style='text-align: right;'>" +
                "<div>" +
                "<span style='margin-right: 1cm'>Total:</span><span style='padding-left: 1cm; font-size: 24px; color: #35b729; border-top: 1px solid black;'>" + parseFloat(application.totalPriceSoles).toFixed(2) + " PEN</span>" +
                "</div></td>" +
            "</tr>"
        }
        messageBody += "</table>"
        let totalPaid = 0;
        if (applicationIncome.length > 0 && this.showCurrentBalance) {
            messageBody +=  "<div style='margin-top: 2cm;'>" 
            messageBody += "<table style='width: 100%;'>";
            for (let index = 0; index < applicationIncome.length; index++) {
              // console.log(applicationIncome[index])
              if (applicationIncome[index].currencyIncome === '$') {
                  messageBody += "<tr>" +
                    "<td></td><td style='text-align: right;'>" +
                    "<span>Payment " + this.formattedDate(applicationIncome[index].paidOn, "Noday") + ": <span style='font-size: 16px; padding-left: 1cm;'>-" + parseFloat(applicationIncome[index].amount).toFixed(2) + " USD</span>" +
                    "</div></td>" +
                    "</tr>"
              } else {
                  messageBody += "<tr>" +
                    "<td></td><td style='text-align: right;'>" +
                    "<span>Payment " + this.formattedDate(applicationIncome[index].paidOn, "Noday") + ": <span style='font-size: 16px; padding-left: 1cm;'>-" + parseFloat(applicationIncome[index].amount * application.exchangeRate).toFixed(0) + " PEN</span>" +
                    "</div></td>" +
                    "</tr>"
              }
              totalPaid = totalPaid + parseFloat(applicationIncome[index].amount);
            }
            messageBody += "</table><br>"
            let currentBalance = parseFloat(application.totalPrice) - totalPaid;
            messageBody += "<div style='text-align: right;'>"
            if (this.showCurrency === 'both' || this.showCurrency === 'dollars') {
                messageBody += "<span style='margin-right: 1cm'>Current Balance: </span><span style='color: #35b729; border-top: 1px solid black; padding-left: 1cm;'>" + parseFloat(currentBalance).toFixed(2) + " USD</span>"
            } else {
                messageBody += "<span style='margin-right: 1cm'>Current Balance: </span><span style='color: #35b729; border-top: 1px solid black; padding-left: 1cm;'>" + parseFloat(currentBalance * application.exchangeRate).toFixed(0) + " PEN</span>"
            }
            if (this.showCurrency === 'both') {
                messageBody += "<div style='color: grey;'>(" + 
                            (currentBalance * application.exchangeRate).toFixed(0) +
                        " PEN)</div>"
            }
            messageBody += "</div>"
        }
        if (this.addDeposit && application.ignore_deposit === 0 && application.depositTotal > 0 && (parseFloat(application.totalPrice).toFixed(2) - application.depositTotal > 25)) {
            messageBody +=  "<div style='margin-top: 1cm;'>" +
                                "<div>We will need a deposit of at least $" + parseFloat(application.depositTotal).toFixed(0) + " (" + parseFloat(application.depositTotal * application.exchangeRate).toFixed(0) + " PEN) in order to start all the necessary preparations. " +
                                "The deposit is your formal confirmation and reservation. " +
                                "The rest you can pay in the first few days, once your services start.</div>" +
                            "</div>"
        } 
        if (application.comments) {
            messageBody +=  "<div style='margin-top: 2cm;'>" +
                                "<div>" + application.comments + "</div>" +
                            "</div></br>"
        }
        let exchangeRates = this.$store.state.exchangeRates;
        var messageFooter = ''
        if (this.language === 'english') {
          messageFooter = "<div style='text-align:center; margin-top:30px; font-size: 14px;'>- this invoice is valid for 15 days -</div><br><br>"
        } else {
          messageFooter = "<div style='text-align:center; margin-top:30px; font-size: 14px;'>- este factura es valida por 15 días -</div><br><br>"
        }
        if (exchangeRates.length > 0) {
            let object = JSON.parse(exchangeRates);
            let rateEUR = object.data.EUR;  
            let rateGBP = object.data.GBP;
            if (this.language === 'english') {
              messageFooter += "<h2>Payment Methods:</strong></h2><br>"
            } else {
              messageFooter += "<h2>Métodos de pago:</strong></h2><br>"
            }
            if (this.showWisePayment) {
              messageFooter += "<h3>Pay with Wise paying minimal bank transfer fees (from zero)</h3><br><a target=_blank href='https://wise.com/pay/business/proyectoperucentresac' style='display: inline'>Pay with wise.com</a><br><br>" +
                "<div style='font-size:12px'>" +
                "<div style='display: inline'><strong>Bank Information for sending USD from an account inside the USA:</strong><br></div>" +
                "<div style='display: inline'>Account holder: Proyecto Peru Centre S.A.C.</div>" +
                "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                "<div style='display: inline'>Routing number: 026073150</div>" +
                "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                "<div style='display: inline'>Account number: 8312474700</div>" +
                "<div style='margin: 0 10px 0 10px; display: inline'>-</div></br>" +
                "<div style='display: inline'>Account type: Checking</div>" +
                "<div style='margin: 0 10px 0 10px; display: inline'>-</div></br>" +
                "<div style='display: inline'>Bank name: Community Federal Savings Bank</div>" +
                "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                "<div style='display: inline'>Address: 89-16 Jamaica Ave, Woodhaven NY 11421, United States</div>" +
                "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                "<div style='display: inline'>Total amount: <strong>" + parseFloat(application.totalPrice - totalPaid).toFixed(2) + " USD</strong></div>"
              if (this.addDeposit && application.ignore_deposit === 0 && application.depositTotal > 0 && (parseFloat(application.totalPrice).toFixed(2) - application.depositTotal > 25)) {
                messageFooter += "<br><div style='display: inline'>Deposit: <strong>" + parseFloat(application.depositTotal).toFixed(2) + " USD</strong></div>"
              }
              messageFooter += "<br><br><div style='display: inline'><strong>Bank Information for sending USD from an account outside the USA:</strong><br></div>" +
                  "<div style='display: inline'>Account holder: Proyecto Peru Centre S.A.C.</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                  "<div style='display: inline'>Routing number: 026073150</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                  "<div style='display: inline'>SWIFT/BIC: CMFGUS33</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                  "<div style='display: inline'>Account number: 8312474700</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div></br>" +
                  "<div style='display: inline'>Bank name: Community Federal Savings Bank</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                  "<div style='display: inline'>Address: 89-16 Jamaica Ave, Woodhaven NY 11421, United States</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                  "<div style='display: inline'>Amount: <strong>" + parseFloat(application.totalPrice - totalPaid).toFixed(2) + " USD</strong></div><br><br>" +
                  "<div style='display: inline'><strong>Bank Information for sending Euros:</strong></br></div>" +
                  "<div style='display: inline'>Account holder: Proyecto Peru Centre S.A.C.</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                  "<div style='display: inline'>SWIFT/BIC: TRWIBEB1XXX</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                  "<div style='display: inline'>IBAN: BE41 9674 5027 8410</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div></br>" +
                  "<div style='display: inline'>Bank name: Wise</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                  "<div style='display: inline'>Address: Rue du Trône 100, 3rd floor, Brussels, 1050, Belgium</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                  "<div style='display: inline'>Amount: <strong>" + ((parseFloat(application.totalPrice) - totalPaid).toFixed(2) * rateEUR).toFixed(2) + " Euro</strong><br><br></div>" +
                  "<div style='display: inline'><strong>Bank Information for sending British Pounds from an account inside the UK:</strong></br></div>" +
                  "<div style='display: inline'>Account holder: Proyecto Peru Centre S.A.C.</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                  "<div style='display: inline'>Sort code: 23-14-70</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                  "<div style='display: inline'>Account number: 64598776</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div></br>" +
                  "<div style='display: inline'>IBAN: GB34 TRWI 2314 7064 5987 76</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div></br>" +
                  "<div style='display: inline'>Bank name: Wise Payments Limited</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                  "<div style='display: inline'>Address: 56 Shoreditch High Street, London, E1 6JJ, United Kingdom</div>" +
                  "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                  "<div style='display: inline'>Amount: <strong>" + ((parseFloat(application.totalPrice) - totalPaid).toFixed(2) * rateGBP).toFixed(2) + " GBP</strong><br><br></div>" +
                  "<div style='display: inline'>If you have a different currency, ask us if we have it's account info to avoid exchange rate costs.</div>" +
                  "</div><br><br>"
            }
            if (this.showNubizPayment) {
              if (this.language === 'english') {
                messageFooter += "<h3>Pay with Credit Card through Nubiz (5% bank transfer fees)</h3><br><a target=_blank href='https://pagolink.niubiz.com.pe/pagoseguro/PROYECTOPERUCENTREDOL/1505549' style='display: inline'>Go to Nubiz</a><br><br><br>"
              } else {
                messageFooter += "<h3>Paga con Nubiz (5% del costos bancarios)</h3><br><a target=_blank href='https://pagolink.niubiz.com.pe/pagoseguro/PROYECTOPERUCENTREDOL/1505549' style='display: inline'>Enlace a Nubiz</a><br><br><br>"
              }
              
            }
            if (this.showSolesPayment) {
              if (this.language === 'english') {
                messageFooter += "<h3>Deposit to Proyecto Peru Centre S.A.C. in Soles</h3><br>" +
                "<div style='display: inline'>Account BCP (soles): 2852239009038</div></br>" +
                "<div style='display: inline'>Account interbanks 00228500223900903853</div><br>"
              } else {
                messageFooter += "<h3>Depósito a nombre de Proyecto Peru Centre S.A.C. en Soles</h3><br>" +
                "<div style='display: inline'>Cuenta BCP (soles): 2852239009038</div></br>" +
                "<div style='display: inline'>Cuenta interbancaria: 00228500223900903853</div><br>"
              }
            }
            messageFooter += "<div style='margin-top: 0cm; margin-bottom: 2cm; font-family: Raleway, Arial, Helvetica, sans-serif; background-color: #c7d3d1; height: 2px;'>" + 
                  "<div style='margin-top: 10px;'>" +
                "</div>" +
            "</div>"
        }
        messageFooter += "<a target=_blank href='https://proyectoperucentre.org/payment-methods/' style='display: inline'>More on Payment Methods</a>" + 
                "<div style='margin: 0 10px 0 10px; display: inline'>-</div>" +
                "<a target=_blank href='https://proyectoperucentre.org/terms-conditions/' style='display: inline'>Terms & Conditions</a></br></br>"
        this.messagePlain += "Total Payment: " + application.totalPrice + " USD.\r\n " +
        "You can contact us on info@proyectoperucentre.org and find our payment methods on https://proyectoperucentre.org/payment-methods/."
        this.messageHTML = messageHeader + messageBody + messageFooter;
        this.$store.dispatch('setLoadingApp', 0);
        this.dialogInvoice = true;
        let payload = {
            submittedFrom: "Invoice",
            emailList: this.payer.email,
            emailSubject: "Invoice Proyecto Peru",
            emailIntro: emailIntro,
            messageHTML: "<div style='margin: 1cm 0 1cm 0; background-color: #c7d3d1; text-align: left; width: 100%; height: 2px;'></div>" + this.messageHTML,
            messagePlain: this.messagePlain,
            openEmailDialog: false,
            saveInvoice: true,
        };
        this.$store.dispatch('emailMessage', payload);
        // check if invoice exists
        if (this.$route.name === 'Client' || this.$route.name === 'Application') {
          const zip = zlib.gzipSync(JSON.stringify(this.messageHTML)).toString('base64');
          let check = await this.$store.dispatch('checkInvoiceExist', zip);
          if (check > 0) {
            this.archiveInvoice = true;
          }
        }
      },
      async getUser(application) {
        if (application.payerAgency) {
          this.dataSaveInvoice.payerName = application.payerAgencyName;
          this.dataSaveInvoice.payerAgency = application.payerAgency;
          this.payer = this.$store.state.available_agencies.filter(function(item) {
              return item.id === application.payerAgency;
          })[0];
        }
        if (application.payerClient) {
          this.dataSaveInvoice.payerName = application.payerClientName;
          this.dataSaveInvoice.payerClient = application.payerClient;
          await this.$store.dispatch('getClient', application.payerClient);
          this.payer = this.$store.state.client;
          this.payer.name = this.payer.first_name + " " + this.payer.last_name;
        }
      },
      getIntro(name) {
        return "Dear " + name + ",\n\n\n" + 
        "Thank you for having interest in our services!\n\n" +
        "Below you'll find your invoice. You can find our payment methods on https://proyectoperucentre.org/payment-methods/\n" + 
        "Please let us know if you want to change your program or if you need any help.\n\n\n" +
        "Kind regards, \n\n" + this.$store.state.user + "\n\n" +
        "info@proyectoperucentre.org\n" +
        "Proyecto Peru Centre\n" +
        "Avenida Arcopata 466-A\n" +
        "Cusco, Cusco, 45\n" +
        "+ 51 950 301 314"
      },
      getRefnr() {
          let refnr = this.applicationId.toString()
          return refnr.substr(1,refnr.length-1)
      },
      async insertInvoice () {
        const zip = zlib.gzipSync(JSON.stringify(this.messageHTML)).toString('base64');
        this.dataSaveInvoice.contentZip = zip;
        let response = await this.$store.dispatch('insertInvoice', this.dataSaveInvoice);
        if (response === 200) {
          this.$store.dispatch('successMessage', 'Invoice saved succesfully');
          this.archiveInvoice = true;
        } else {
          this.$store.dispatch('errorMessage', 'Invoice not saved correctly');
        }
      },
      async displayInvoice() {
        let application = JSON.parse(JSON.stringify(this.$store.state.dataForReport));
        this.$store.dispatch('saveDataForReport', );
        console.log(application);
        this.archiveInvoice = application.archive;
        if (this.archiveInvoice) {
          this.messageHTML = JSON.parse(zlib.unzipSync(Buffer.from(application.contentZip, 'base64')));
          await this.getUser(application);
          let emailIntro = await this.getIntro(this.payer.name);
          let payload = {
              submittedFrom: "AccommodationPaymentForm",
              emailList: this.payer.email,
              emailSubject: "Pago Accommodacíon Proyecto Peru",
              emailIntro: emailIntro,
              messageHTML: this.messageHTML,
          };
          await this.$store.dispatch('emailMessage', payload);
          this.dialogInvoice = true;
        } else {
          this.applicationId = application.id;
          console.log(this.applicationId);
          this.createInvoice();
        }
        this.$store.dispatch('showInvoice', false);
      },
      showInvoiceControlPanel() {
        if (this.$route.name === 'Invoices') {
          return false;
        } else {
          // console.log('showInvoiceControlPanel', this.$route.name);
          return true;
        }
      },
    },
}
</script>

<style>
    th {
      vertical-align: top;
    }
    /* .invoiceButtons {
      position: fixed;
      top: calc(((100% - 297mm)/2) + 120px);
      right: calc(((100% - 210mm)/2) - 70px);
    } */
    .invoiceButtons {
      position: fixed;
      top: calc(((100% - 297mm)/2) + 50px);
      left: calc(((100% - 210mm)/2) - 70px);
    }
    .invoiceControlPanel {
      position: fixed;
      top: calc(((100% - 297mm)/2) + 50px);
      right: calc(((100% - 210mm)/2) - 250px);
    }
    @media only screen and (max-width: 1000px) {
      .invoiceButtons {
        right: 10px;
      }
    }
    @media only screen and (max-width: 1200px) {
      .invoiceControlPanel {
        right: 10px;
      }
    }

</style>