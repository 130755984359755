export default {
    methods: {
        getStatusApp(id) {
            if (id) {
                return this.$store.state.statusServices.filter((select_item) => {
                    return select_item.id === id;
                })[0];
            } else {
                return [];
            }
        },
    }
}