<template>
    <v-container style="max-width: 100%">
        <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            grow
        >
            <v-tab
                v-for="itemTab in itemsTab"
                :key="itemTab"
                >
                <span class="ppc_darkgreen--text">{{ itemTab }}</span>
            </v-tab>
        </v-tabs>
    
        <v-tabs-items v-model="tab">
            <!-- Placements -->
            <v-tab-item>
                <span class="d-flex flex-column">
                    <v-row justify="center">
                        <div class='d-flex flex-row elevation-1 px-2 pt-4 mb-2'>
                            <v-radio-group
                                row
                                v-model="scopeResourcesPlanned"
                                class="mt-4 d-flex flex-column"
                                @change="$store.dispatch('setLoadingApp', 1), getResources(), selectedReservation=[]"
                            >
                                <div class="d-flex flex-row flex-wrap">
                                    <div class="d-flex flex-column">
                                        <div class="d-flex justify-center">
                                            <v-radio
                                                label="Ongoing"
                                                value="ongoing"
                                                class="my-0 py-0"
                                            ></v-radio>
                                        </div>
                                        <v-divider class="my-2"></v-divider>
                                        <div class="d-flex flex-row flex-wrap">
                                            <div class="d-flex flex-row flex-wrap">
                                                <v-radio
                                                    label="To be placed"
                                                    value="notPlaced"
                                                    class="my-0 py-0"
                                                ></v-radio>
                                                <v-radio
                                                    label="Placed"
                                                    value="placed"
                                                    class="my-0 py-0"
                                                ></v-radio>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between mx-2">
                                        <v-radio
                                            label="Completed"
                                            value="completed"
                                            class="my-0 py-0"
                                        ></v-radio>
                                    </div>
                                </div>
                            </v-radio-group>
                            <!-- <v-radio-group
                                row
                                v-model="scopeResourcesPlanned"
                                class="mt-0"
                                @change="getResources()"
                            >
                                <v-radio
                                    label="Ongoing"
                                    value="ongoing"
                                    class="my-0 py-0"
                                ></v-radio>
                                <v-radio
                                    label="Not placed"
                                    value="notPlaced"
                                    class="my-0 py-0"
                                ></v-radio>
                                <v-radio
                                    label="Placed"
                                    value="placed"
                                    class="my-0 py-0"
                                ></v-radio>
                                <v-radio
                                    label="Completed"
                                    value="completed"
                                    class="my-0 py-0"
                                ></v-radio>
                                <v-radio
                                    label="All"
                                    value="all"
                                    class="my-0 py-0"
                                ></v-radio>
                            </v-radio-group> -->
                        </div>
                    </v-row>
                    <v-data-table
                        :headers="headersResourcesScope"
                        :items="resourcesScope"
                        sort-by="start"
                        class="elevation-1"
                        :items-per-page="-1"
                        disable-pagination
                        hide-default-footer
                        :search="searchResourcesScope"
                        :show-select="scopeResourcesPlanned === 'notPlaced'"
                        :single-select=selectedReservationSingleSelect
                        v-model='selectedReservation'
                        @input="selectResourceReservation"
                    >
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <div class="d-flex flex-row flex-wrap justify-center">
                                    <!-- <v-toolbar-title class="mr-4">Resources</v-toolbar-title> -->
                                    <v-card flat>
                                        <v-text-field
                                            v-model="searchResourcesScope"
                                            append-icon="$vuetify.icons.icon_mdi_magnify"
                                            :label="'Search ' + `${ resourcesScope.length }` + ' accommodation(s)'"
                                            single-line
                                            hide-details
                                            style="width: 300px"
                                            clearable
                                        ></v-text-field>
                                    </v-card>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    :disabled="selectedReservation.length === 0"
                                    @click="goFichaDeAlojamiento(selectedReservation)"
                                    v-if="scopeResourcesPlanned === 'notPlaced'"
                                    class = "mx-2"
                                >
                                    Reservation Request
                                </v-btn>
                                <v-btn
                                    :disabled="selectedReservation.length === 0"
                                    @click="confirmReservations(selectedReservation)"
                                    v-if="scopeResourcesPlanned === 'notPlaced'"
                                    class = "mx-2"
                                >
                                    Confirm Reservations
                                </v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.full_name`]="{ item }">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn 
                                        small 
                                        rounded 
                                        v-bind="attrs"
                                        v-on="on" 
                                        @click="goClient(item.client_id, item.id)"
                                    >
                                        {{ item.full_name }}
                                    </v-btn>
                                </template>
                                <span>go to client</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`item.start`]="{ item }">
                            <span v-html="formattedDate(item.start, 'date')"></span>
                        </template>
                        <template v-slot:[`item.end`]="{ item }">
                            <span v-html="formattedDate(item.end, 'date')"></span>
                        </template>
                        <template v-slot:[`item.totalSecurityDeposit`]="{ item }">
                            <span v-if="item.totalSecurityDeposit > 0">$ {{ parseFloat(item.totalSecurityDeposit).toFixed(2) }}</span>
                        </template>
                        <template v-slot:[`item.family`]="{ item }">
                            <span v-if="item.family == 0">Apartment</span>
                            <span v-else>Family</span>
                        </template>
                        <template v-slot:[`item.statusResourceId`]="{ item }">
                            <v-chip :color="getStatusResource(item.statusResourceId).color" text-color="white" v-html="getStatusResource(item.statusResourceId).name"></v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="openDialogEditResource(item.id)"
                                :disabled="item.updating === true"
                            >
                                $vuetify.icons.icon_mdi_pencil
                            </v-icon>
                        </template>
                    </v-data-table>
                </span>
                <v-dialog
                    v-model="dialogEditResource"
                    max-width="640px"
                    :retain-focus="false"
                >
                    <v-card class="d-flex flex-row flex-wrap" color="blue lighten-2">
                        <v-card flat class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                            <div>
                                <v-card-title>
                                    <span class="white--text mr-2">Accommodation #{{ resource.number_type }}</span> 
                                    <span v-if="resource.family == 0" class="white--text">Apartment</span>
                                    <span v-if="resource.family == 1" class="white--text">Family {{ resource.contact_full_name }}</span>
                                </v-card-title>
                                <v-card-subtitle>
                                    <div class="white--text">{{ resource.full_name }}</div>
                                    <div class="white--text">{{ resource.accommodation_class }}</div>
                                </v-card-subtitle>
                            </div>
                            <v-chip class="ma-4" :color="getStatusResource(resource.statusResourceId).color" text-color="white" v-html="getStatusResource(resource.statusResourceId).name"></v-chip>
                        </v-card>
                        <div class="d-flex flex-column" color="blue lighten-2" style="max-width: 320px">
                            <v-card-text>
                                <v-select
                                    v-model="resource.room_id"
                                    :items="this.availableRooms"
                                    item-text="listEntry"
                                    item-value="id"
                                    :menu-props="{ auto: true }"
                                    label="Room"
                                    validate-on-blur
                                    clearable
                                    @click:clear="resource.room_id = null"
                                    @change="calcPaymentResource()"
                                    color="white"
                                    item-color="black"
                                >
                                    <template #selection="{ item }">
                                        <span class="white--text">{{item.roomSelectName}}</span>
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        <v-icon v-if="data.item.success" small color="ppc_darkgreen" class="mr-2">$vuetify.icons.icon_mdi_check</v-icon>  {{data.item.listEntry}}
                                    </template>
                                </v-select>
                                <v-btn small rounded :disabled="!resource.room_id" @click="openAvailability(resource.accommodation_id, resource.start)" class="mb-2">availability</v-btn>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        label="from"
                                        v-bind="attrs"
                                        prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                        :value="formattedDate(resource.start)"
                                        v-on="on"
                                        clearable
                                        @click:clear="resource.start = null"
                                        readonly
                                        class="text-input-white"
                                        color="white"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="resource.start"
                                        no-title
                                        scrollable
                                        color="blue"
                                        @change="calcPaymentResource()"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        label="until"
                                        v-bind="attrs"
                                        prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                        :value="formattedDate(resource.end)"
                                        v-on="on"
                                        clearable
                                        @click:clear="resource.end = null"
                                        readonly
                                        class="text-input-white"
                                        color="white"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="resource.end"
                                        no-title
                                        scrollable
                                        :min="resource.start"
                                        color="blue"
                                        @change="calcPaymentResource()"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                <div class="mb-4">Duration: {{ resource.duration }} night(s)</div>
                                <v-textarea
                                    class="rounded-xl"
                                    v-model="resource.comments"
                                    auto-grow
                                    clearable
                                    counter
                                    outlined
                                    color="white"
                                    label="Comments (255 max)"
                                    :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                                ></v-textarea>
                            </v-card-text>
                        </div>
                        <div class="d-flex flex-column" color="blue lighten-2" style="max-width: 320px">
                            <v-card-text>
                                <v-text-field
                                    label="Pickup, Fixed & Extras (PEN)"
                                    v-model="resource.extras"
                                    class="text-input-white"
                                    color="white"
                                    @change="calcPaymentResource()"
                                ></v-text-field>
                                <div class="mb-5" v-if="resource.payment > 0 && resource.room_id > 0">{{ resource.paymentDescription }} = </div>
                                <!-- <div class="mb-5" v-if="resource.payment > 0">Total: ({{ resource.rate }} x {{ resource.duration }}) + {{ resource.extras }} = </div> -->
                                <v-text-field
                                    label="Total Payment (PEN)"
                                    v-model="resource.payment"
                                    class="text-input-white"
                                    color="white"
                                    disabled
                                    v-if="resource.room_id > 0"
                                ></v-text-field>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <span class="d-flex flex-row">
                                            <!-- <v-btn 
                                                fab @click="goFichaDeAlojamiento()" 
                                                color="blue" elevation="2" 
                                                x-small 
                                                class="mt-4 mr-2"
                                            >
                                                <v-icon size="20" color="white">mdi-email</v-icon>
                                            </v-btn> -->
                                            <v-text-field
                                            label="Info to placement"
                                            v-bind="attrs"
                                            prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                            :value="formattedDate(resource.info_to_provider)"
                                            v-on="on"
                                            @click:clear="resource.info_to_provider = null"
                                            clearable
                                            readonly
                                            class="text-input-white"
                                            color="white"
                                            ></v-text-field>
                                        </span>
                                    </template>
                                    <v-date-picker
                                        v-model="resource.info_to_provider"
                                        no-title
                                        scrollable
                                        color="blue"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            label="Reserved"
                                            v-bind="attrs"
                                            prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                            :value="formattedDate(resource.reserved)"
                                            v-on="on"
                                            @click:clear="resource.reserved = null"
                                            clearable
                                            readonly
                                            class="text-input-white"
                                            color="white"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="resource.reserved"
                                        no-title
                                        scrollable
                                        color="blue"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        label="Info to client"
                                        v-bind="attrs"
                                        prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                        :value="formattedDate(resource.info_to_client)"
                                        v-on="on"
                                        @click:clear="resource.info_to_client = null"
                                        clearable
                                        readonly
                                        class="text-input-white"
                                        color="white"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="resource.info_to_client"
                                        no-title
                                        scrollable
                                        @change="checkResourceDates()"
                                        color="blue"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-card-text>
                        </div>
                        <v-card-actions class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                            nr: {{resource.id}}
                            <v-btn color="blue lighten-3" @click="openTransactionOutgoings(resource.transaction_id)" class="ma-4" v-if="resource.transaction_id">
                                <v-icon color="white">$vuetify.icons.icon_mdi_credit_card</v-icon>
                                <span class="white--text">Open Transaction</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="white" text @click="dialogEditResource = false">Cancel</v-btn>
                            <v-btn color="white" text @click="dialogEditResource = false, insertUpdateResource()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-tab-item>
            <!-- payments -->
            <v-tab-item>
                <v-row class="my-4 d-flex flex-column justify-space-around">
                    <div class="d-flex flex-row justify-space-around">
                        <v-row justify="center">
                            <v-card-title>Pay until {{ startOfNextMonth }}</v-card-title>
                            <v-date-picker
                                :value="monthCalcPaymentsAccommodation.month"
                                type="month"
                                no-title
                                @input="saveCalcPaymentsAccommodation(monthCalcPaymentsAccommodation.type, $event)"
                                :allowed-dates="allowedMonths"
                                :disabled="$store.getters.loadingApp === 1"
                            ></v-date-picker>
                        </v-row>
                        <!-- <div class="d-flex flex-column">
                            <v-radio-group
                                column
                                :value="monthCalcPaymentsAccommodation.type"
                                @change="saveCalcPaymentsAccommodation($event, monthCalcPaymentsAccommodation.month)"
                                row
                            >
                                <v-radio
                                    label="Family"
                                    value="family"
                                    class="my-0 py-0"
                                ></v-radio>
                                <v-radio
                                    label="Apartment"
                                    value="apartment"
                                    class="my-0 py-0"
                                ></v-radio>
                            </v-radio-group>
                        </div> -->
                    </div>
                    <v-card flat v-for="(family, index) in paymentsContactsRender" :key="index">
                        <div class="d-flex flex-row">
                            <div>
                                <v-card-title>{{ family.family }}</v-card-title>
                                <v-card-subtitle>Total to pay: {{ family.totalPayment.toFixed(2) }} PEN</v-card-subtitle>
                            </div>
                            <v-btn
                                color="grey"
                                dark
                                class="ma-2"
                                @click="goPaymentAccommodationForm(family)"
                            >
                                Form
                            </v-btn>
                            <v-btn
                                color="grey"
                                dark
                                @click="addPayment(family)"
                                class="ma-2"
                            >
                                Pay
                            </v-btn>
                        </div>
                        <v-data-table
                            :headers="headersPaymentsFamilies"
                            :items="family.resources"
                            class="elevation-1 my-4"
                            :items-per-page="-1"
                            disable-pagination
                            hide-default-footer
                            sort-by="full_name"
                            :disabled="$store.getters.loadingApp === 1"
                        >
                            <template v-slot:[`item.full_name`]="{ item }">
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                            small 
                                            rounded 
                                            v-bind="attrs"
                                            v-on="on" 
                                            @click="goClient(item.client_id, item.id)"
                                        >
                                            {{ item.full_name }}
                                        </v-btn>
                                    </template>
                                    <span>go to client</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.stay`]="{ item }">
                                <span>{{ formattedDate(item.start, 'day-month-year') }} - {{ formattedDate(item.end, 'day-month-year') }}</span>
                            </template>
                            <template v-slot:[`item.toPayNow`]="{ item }">
                                <span v-html="item.toPayNow.toFixed(2)"></span>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    @click="openDialogEditResource(item.id)"
                                >
                                    $vuetify.icons.icon_mdi_pencil
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-row>
            </v-tab-item>
            <!-- Families -->
            <v-tab-item>
                <v-data-table
                    :headers="headersFamilies"
                    :items="updatedFamilies"
                    class="elevation-1"
                    :items-per-page="-1"
                    disable-pagination
                    hide-default-footer
                    sort-by="contact"
                >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                color="ppc_pink"
                                dark
                                small
                                absolute
                                right
                                fab
                                @click="editedAcc.family = 1, editedAcc.security_deposit = 0, openDialogAcc()"
                            >
                                <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                            </v-btn>

                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.contact`]="{ item }">
                        {{ item.contact }}
                        <v-btn
                            v-if="item.contact" 
                            fab 
                            color="ppc_pink"
                            height="20px"
                            width="20px"
                            @click="openContactInfo(item)"
                            right
                            top
                            class="ml-3"
                        >
                            <v-icon color="white">$vuetify.icons.icon_mdi_information_variant</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:[`item.amountRooms`]="{ item }">
                        <span class="mr-2">{{ item.amountRooms }}</span>
                        <v-btn
                            height="20px"
                            width="20px"
                            fab
                            color="ppc_pink"
                        >
                            <v-icon
                                small
                                @click="editRooms(item)"
                                color="white"
                            >
                                $vuetify.icons.icon_mdi_pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:[`item.checkAv`]="{ item }">
                        <v-btn small rounded @click="openAvailability(item.id, null)">availability</v-btn>
                    </template>
                    <template v-slot:[`item.pets`]="{ item }">
                        <v-icon small v-if="`${item.pets}` === 'have pets'" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                    </template>
                    <template v-slot:[`item.vegetarian`]="{ item }">
                        <v-icon small v-if="`${item.vegetarian}` === 'cooks vegetarian'" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                    </template>
                    <template v-slot:[`item.vegan`]="{ item }">
                        <v-icon small v-if="`${item.vegan}` === 'cooks vegan'" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                    </template>
                    <template v-slot:[`item.obsoleteAcc`]="{ item }">
                        <v-icon small v-if="item.obsoleteAcc !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="editAcc(item)"
                        >
                            $vuetify.icons.icon_mdi_pencil
                        </v-icon>
                        <v-icon
                            small
                            @click="deleteAcc(item)"
                        >
                            $vuetify.icons.icon_mdi_delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-tab-item>
            <!-- Apartments -->
            <v-tab-item>
                <v-data-table
                    :headers="headersApartments"
                    :items="updatedApartments"
                    class="elevation-1"
                >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                color="ppc_pink"
                                dark
                                small
                                absolute
                                right
                                fab
                                @click="editedAcc.family = 0, openDialogAcc()"
                            >
                                <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                            </v-btn>

                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.amountRooms`]="{ item }">
                        <span class="mr-2">{{ item.amountRooms }}</span>
                        <v-btn
                            height="20px"
                            width="20px"
                            fab
                            color="ppc_pink"
                        >
                            <v-icon
                                small
                                @click="editRooms(item)"
                                color="white"
                            >
                                $vuetify.icons.icon_mdi_pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:[`item.checkAv`]="{ item }">
                        <v-btn small rounded @click="openAvailability(item.id, null)">availability</v-btn>
                    </template>
                    <template v-slot:[`item.contact`]="{ item }">
                        {{ item.contact }}
                        <v-btn
                            v-if="item.contact" 
                            fab 
                            color="ppc_pink"
                            height="20px"
                            width="20px"
                            @click="openContactInfo(item)"
                            right
                            top
                            class="ml-3"
                        >
                            <v-icon color="white">$vuetify.icons.icon_mdi_information_variant</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:[`item.obsoleteAcc`]="{ item }">
                        <v-icon small v-if="item.obsoleteAcc !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="editAcc(item)"
                        >
                            $vuetify.icons.icon_mdi_pencil
                        </v-icon>
                        <v-icon
                            small
                            @click="deleteAcc(item)"
                        >
                            $vuetify.icons.icon_mdi_delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog
            v-model="dialogAcc"
            max-width="800px"
            persistent
        >
            <v-form ref="dialogFormAcc">
                <v-card>
                    <v-card-title class="ppc_darkgreen darken-1">
                        <span class="headline white--text"> {{ formTitle }} {{ editedAcc.contact }} </span>
                    </v-card-title>
                    <v-card-text>
                        <v-select
                            v-model="editedAcc.contact_id"
                            :items="$store.state.contacts"
                            item-text="full_name"
                            item-value="id"
                            :menu-props="{ auto: true }"
                            label="Select contact"
                            single-line
                            prepend-icon="$vuetify.icons.icon_mdi_account_group ppc_darkgreen--text"
                            validate-on-blur
                            clearable
                            :rules="inputRulesContactReq"
                        >
                        </v-select>
                        <v-card flat class="ma-2" v-if="editedAcc.family === 1">
                            <v-card-title>Costs per day (PEN)</v-card-title>
                            <v-btn
                                color="blue"
                                dark
                                small
                                absolute
                                right
                                fab
                                @click="openDialogAccPrices"
                            >
                                <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                            </v-btn>
                            <v-card-actions>
                                <v-data-table
                                    :headers="headersAccPrices"
                                    :items="editedAcc.prices"
                                    class="elevation-1"
                                    disable-pagination
                                    hide-default-footer
                                    sort-by="date_price"
                                    v-if="editedAcc.prices"
                                    :key="rendererDialogAccTable"
                                >
                                    <template v-slot:[`item.date_price`]="{ item }">
                                        <span v-html="formattedDate(item.date_price, 'date')"></span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-icon
                                            small
                                            class="mr-2"
                                            @click="editAccPrice(item)"
                                        >
                                            $vuetify.icons.icon_mdi_pencil
                                        </v-icon>
                                        <v-icon
                                            small
                                            @click="deleteAccPrice(item)"
                                        >
                                            $vuetify.icons.icon_mdi_delete
                                        </v-icon>
                                    </template>
                                </v-data-table>
                                <!-- <v-card 
                                    class="d-flex flex-column align-start my-1 ma-sm-3 ma-md-5 pa-5 md12 rounded-xl" 
                                    min-width="320px"
                                    @drop.prevent="onDrop($event, 'cv')"
                                    @dragover.prevent="dragover = true"
                                    @dragenter.prevent="dragover = true"
                                    @dragleave.prevent="dragover = false"
                                    >
                                    <v-card flat>
                                        <h2 class="grey--text"><v-icon size="40" class="mr-2">mdi-text-box</v-icon>Curriculum Vitae</h2>
                                    </v-card>
                                    <v-card 
                                        v-if="getUploadInfoType('cv').length > 0"
                                        flat 
                                        class="mx-auto my-5"
                                    >
                                        <v-list>
                                        <v-list-item-group color="primary">
                                            <v-list-item v-for="(file, index) in getUploadInfoType('cv')" :key="index" class="d-flex flex-row flex-wrap">
                                            <span class="d-inline-block text-truncate" style="max-width: 180px;">{{ file.name }}</span>
                                            <div>
                                                <v-btn
                                                color="ppc_pink"
                                                dark
                                                x-small
                                                fab
                                                class="mx-2 my-0"
                                                @click="downloadFile(file.id)"
                                                >
                                                <v-icon>
                                                    mdi-download
                                                </v-icon>
                                                </v-btn>
                                                <v-btn
                                                color="ppc_pink"
                                                dark
                                                x-small
                                                fab
                                                class="mx-2 my-0"
                                                @click="deleteFile(file.id)"
                                                >
                                                <v-icon>
                                                    $vuetify.icons.icon_mdi_delete
                                                </v-icon>
                                                </v-btn>
                                            </div>
                                            </v-list-item>
                                        </v-list-item-group>
                                        </v-list>
                                        <div v-if="getUploadInfoType('cv')[0].approved" class="align-self-center">
                                        <v-icon color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                                        <strong>cv approved!</strong>
                                        </div>
                                    </v-card>
                                    <v-card flat class="d-flex flex-column align-self-center" v-else>
                                        <v-card flat width="250px">
                                        <v-file-input 
                                            :rules="fileRules"
                                            small-chips 
                                            show-size 
                                            label="Select or Drop pdf" 
                                            @change="selectFile($event, 'cv')"
                                            accept=".pdf"
                                            :disabled="selectFileCV"
                                        >
                                        </v-file-input>
                                        </v-card>
                                        <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
                                        mdi-cloud-upload
                                        </v-icon>
                                    </v-card>
                                    <v-menu 
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                        v-if="getUploadInfoType('cv').length > 0"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field 
                                            :value="formattedDate(getUploadInfoType('cv')[0].approved)" 
                                            label="Date of approval" 
                                            v-on='on' 
                                            v-bind="attrs"
                                            prepend-icon="$vuetify.icons.icon_mdi_calendar ppc_darkgreen--text"
                                            clearable
                                            readonly
                                            @click:clear="approveUpload(getUploadInfoType('cv')[0].id, null)"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker 
                                        v-model="approvalDateCv"
                                        color='ppc_darkgreen'
                                        scrollable
                                        @change="approveUpload(getUploadInfoType('cv')[0].id, approvalDateCv)">
                                        </v-date-picker>
                                    </v-menu>
                                </v-card> -->
                            </v-card-actions>
                        </v-card>
                        <v-card flat class="ma-2" v-if="editedAcc.family === 0">
                            <v-card-title>Payment per client</v-card-title>
                            <v-card-actions>
                                <div class="d-flex flex-row flex-wrap">
                                    <v-text-field
                                        v-model.number="editedAcc.payment_apartment"
                                        label="PEN"
                                        class="mr-2"
                                        :disabled="editedAcc.payment_type==='not client dependent' || editedAcc.payment_type==='fixed per stay'"
                                    ></v-text-field>
                                    <v-select
                                        v-model="editedAcc.payment_type"
                                        :items="payment_types"
                                        item-text="text"
                                        item-value="value"
                                        :menu-props="{ auto: true }"
                                        label="per"
                                        single-line
                                        validate-on-blur
                                        clearable
                                    ></v-select>
                                </div>
                            </v-card-actions>
                            <v-card-title>Security Deposit</v-card-title>
                            <v-card-actions>
                                <div class="d-flex flex-row flex-wrap">
                                    <v-text-field
                                        v-model.number="editedAcc.security_deposit"
                                        label="USD (0 is no deposit)"
                                        class="mr-2"
                                    ></v-text-field>
                                </div>
                            </v-card-actions>
                        </v-card>
                        <v-card flat class="d-flex flex-row flex-wrap ma-2" v-if="editedAcc.family === 1">
                            <div>
                                <v-card-text class="py-0">
                                    <v-radio-group
                                        column
                                        v-model="editedAcc.pets"
                                        prepend-icon="mdi-dog-side"
                                    >
                                        <v-radio
                                            label="Does not have pets"
                                            value="does not have pets"
                                            class="my-0 py-0"
                                        ></v-radio>
                                        <v-radio
                                            label="Have pets"
                                            value="have pets"
                                            class="my-0 py-0"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                            </div>
                            <div>
                                <v-card-text class="py-0">
                                    <v-radio-group
                                        column
                                        v-model="editedAcc.vegetarian"
                                        prepend-icon="mdi-pot-steam"
                                    >
                                        <v-radio
                                            label="Cooks vegetarian"
                                            value="cooks vegetarian"
                                            class="my-0 py-0"
                                        ></v-radio>
                                        <v-radio
                                            label="No vegetarian"
                                            value="no vegetarian"
                                            class="my-0 py-0"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                            </div>
                            <div>
                                <v-card-text class="py-0">
                                    <v-radio-group
                                        column
                                        v-model="editedAcc.vegan"
                                        prepend-icon="mdi-flower"
                                    >
                                        <v-radio
                                            label="Cooks vegan"
                                            value="cooks vegan"
                                            class="my-0 py-0"
                                        ></v-radio>
                                        <v-radio
                                            label="No vegan"
                                            value="no vegan"
                                            class="my-0 py-0"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                            </div>
                        </v-card>
                        <v-textarea
                            class="rounded-xl mx-3"
                            v-model="editedAcc.commentsAcc"
                            auto-grow
                            clearable
                            counter
                            outlined
                            label="Comments (255 max)"
                            :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                        >
                        </v-textarea>
                        <div class="d-flex flex-row flex-wrap">
                            <v-checkbox
                                :input-value="editedAcc.obsoleteAcc !== null"
                                label="Obsolete"
                                @change="setObsoleteAcc($event)"
                                 class="mr-4 mt-0"
                            ></v-checkbox>
                            <div v-if="editedAcc.obsoleteAcc !== null" class="mt-1">
                                from {{ formattedDate(editedAcc.obsoleteAcc) }}
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="ppc_darkgreen darken-1"
                            text
                            @click="dialogAcc = false, closeAcc()"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="ppc_darkgreen darken-1"
                            text
                            @click="saveAccTotal"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog
            v-model="dialogAccPrices"
            max-width="500px"
            persistent
        >
            <v-card flat class="pa-4">
                <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    class="my-5"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            label="prices start on"
                            v-bind="attrs"
                            prepend-icon="$vuetify.icons.icon_mdi_calendar"
                            :value="formattedDate(editedAccPrices.date_price)"
                            v-on="on"
                            readonly
                            clearable
                            style="max-width: 300px"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="editedAccPrices.date_price"
                        no-title
                        scrollable
                        color="blue"
                    ></v-date-picker>
                </v-menu>
                <v-card flat class="d-flex flex-row flex-wrap">
                    <v-text-field
                        v-model="editedAccPrices.price_private"
                        label="Private"
                        prepend-icon="$vuetify.icons.icon_mdi_account ppc_darkgreen--text"
                        style="width: 20px"
                    ></v-text-field>
                    <v-text-field
                        v-model="editedAccPrices.price_shared"
                        label="Shared"
                        prepend-icon="$vuetify.icons.icon_mdi_account_group ppc_darkgreen--text"
                        style="width: 20px"
                    ></v-text-field>
                    <v-text-field
                        v-model="editedAccPrices.price_bunk"
                        label="Bunk"
                        prepend-icon="$vuetify.icons.icon_mdi_bunk_bed ppc_darkgreen--text"
                        style="width: 20px"
                    ></v-text-field>
                </v-card>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn
                        color="ppc_darkgreen darken-1"
                        text
                        @click="cancelAccPrices()"
                    >
                        Cancel
                    </v-btn> -->
                    <v-btn
                        color="ppc_darkgreen darken-1"
                        text
                        @click="saveAccPrice(editedAccPrices)"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogRooms"
            max-width="800px"
            persistent
        >
            <v-card flat>
                <v-data-table
                    :headers="headersRooms"
                    :items="editRoomsTable"
                    class="elevation-1"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                                <v-toolbar-title>Rooms {{ editedAcc.contact }}</v-toolbar-title>
                                <v-divider
                                    class="mx-4"
                                    inset
                                    vertical
                                ></v-divider>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="ppc_pink"
                                dark
                                small
                                absolute
                                right
                                fab
                                @click="editedRoom.name = '#' + (editRoomsTable.length + 1),dialogRoom = true"
                            >
                                <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                            </v-btn>

                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.private_bathroom`]="{ item }">
                        <span v-if="item.private_bathroom === 0">shared</span>
                        <span small v-if="item.private_bathroom === 1">private</span>
                    </template>
                    <template v-slot:[`item.obsoleteRoom`]="{ item }">
                        <v-icon small v-if="item.obsoleteRoom !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="editRoom(item)"
                        >
                            $vuetify.icons.icon_mdi_pencil
                        </v-icon>
                        <v-icon
                            small
                            @click="deleteRoom(item)"
                        >
                            $vuetify.icons.icon_mdi_delete
                        </v-icon>
                    </template>
                </v-data-table>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="ppc_darkgreen darken-1"
                        text
                        @click="dialogRooms = false"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogRoom"
            max-width="800px"
            persistent
        >
            <v-form ref="dialogFormRoom">
                <v-card>
                    <v-card-title class="ppc_darkgreen darken-1">
                        <span class="headline white--text"> Room {{ editedRoom.name }} </span>
                    </v-card-title>
                    <v-card-text>
                        <v-card flat class="ma-2">
                            <v-text-field
                                v-model.number="editedRoom.name"
                                label="Name (max 45)"
                                counter
                                :rules="inputRulesName"
                                @change="nameChange = true"
                            ></v-text-field>
                            <v-card-title>Capacity</v-card-title>
                            <v-card-actions>
                                <v-text-field
                                    v-model.number="editedRoom.single_beds"
                                    label="Single"
                                    prepend-icon="mdi-bed ppc_darkgreen--text"
                                    type="number"
                                    :rules="numberRule"
                                    clearable
                                    :disabled="editedRoom.bunk_places > 0"
                                ></v-text-field>
                                <v-text-field
                                    v-model.number="editedRoom.double_beds"
                                    label="Double"
                                    prepend-icon="mdi-bed-king ppc_darkgreen--text"
                                    type="number"
                                    :rules="numberRule"
                                    clearable
                                    :disabled="editedRoom.bunk_places > 0"
                                ></v-text-field>
                                <v-text-field
                                    v-model.number="editedRoom.bunk_places"
                                    label="Bunk Places"
                                    prepend-icon="$vuetify.icons.icon_mdi_bunk_bed ppc_darkgreen--text"
                                    type="number"
                                    :rules="numberRule"
                                    clearable
                                    :disabled="editedRoom.single_beds > 0 || editedRoom.double_beds > 0"
                                ></v-text-field>
                                <v-checkbox
                                    v-model="editedRoom.private_bathroom"
                                    label="Has private bathroom"
                                    class="mr-4 mt-0"
                                    :disabled="editedRoom.bunk_places > 0"
                                ></v-checkbox>
                            </v-card-actions>
                        </v-card>
                        <v-textarea
                            class="rounded-xl mx-3"
                            v-model="editedRoom.commentsRoom"
                            auto-grow
                            clearable
                            counter
                            outlined
                            label="Comments (255 max)"
                            :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                        >
                        </v-textarea>
                        <div class="d-flex flex-row flex-wrap">
                            <v-checkbox
                                :input-value="editedRoom.obsoleteRoom !== null"
                                label="Obsolete"
                                @change="setObsoleteRoom($event)"
                                 class="mr-4 mt-0"
                            ></v-checkbox>
                            <div v-if="editedRoom.obsoleteRoom !== null" class="mt-1">
                                from {{ formattedDate(editedRoom.obsoleteRoom) }}
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="ppc_darkgreen darken-1"
                            text
                            @click="dialogRoom = false, closeRoom()"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="ppc_darkgreen darken-1"
                            text
                            @click="saveRoom"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog v-model="dialogDeleteAcc" max-width="500px">
            <v-card>
                <v-card-title>Are you sure you want to delete accommodation {{ editedAcc.contact }}?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="ppc_darkgreen darken-1" text @click="closeDeleteAcc()">Cancel</v-btn>
                <v-btn color="ppc_darkgreen darken-1" text @click="deleteAccConfirm()">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDeleteRoom" max-width="500px">
            <v-card>
                <v-card-title>Are you sure you want to delete room nr. {{ editedRoom.name }}?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="ppc_darkgreen darken-1" text @click="dialogDeleteRoom = !dialogDeleteRoom">Cancel</v-btn>
                <v-btn color="ppc_darkgreen darken-1" text @click="deleteRoomConfirm()">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row justify="center">
            <v-dialog
                v-model="dialogContactInfo"
                max-width="500px"
            >
                <v-card>
                    <v-card-title class="ppc_darkgreen darken-1">
                        <span class="headline white--text"> {{ contact.name }} </span>
                    </v-card-title>
                    <v-card-subtitle class="mt-5" v-if="contact.organisation">
                        <v-icon
                            class="mr-2"
                        >
                            $vuetify.icons.icon_mdi_domain ppc_darkgreen--text
                        </v-icon>
                        <span class="font-weight-bold">{{ contact.organisation }}</span>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="12"
                                v-if="contact.address || contact.country"
                            >
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-map-marker ppc_darkgreen--text
                                </v-icon>
                                {{contact.address}}
                                <span v-if="contact.address && contact.country">,</span>
                                {{contact.country}}
                            </v-col>
                            
                            <v-col
                                cols="12"
                                sm="6"
                                v-if="contact.phone"
                            >
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-cellphone ppc_darkgreen--text
                                </v-icon>
                                {{contact.phone}}
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                v-if="contact.phone_alt"
                            >
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-phone ppc_darkgreen--text
                                </v-icon>
                                {{contact.phone_alt}}
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                v-if="contact.email"
                            >   
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-email ppc_darkgreen--text
                                </v-icon>
                                {{contact.email}}
                            </v-col>
                            <v-col
                                cols="12"
                                v-if="contact.comments"
                            >   
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-message-text ppc_darkgreen--text
                                </v-icon>
                                {{ contact.comments }}
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn
                            color="ppc_darkgreen darken-1"
                            text
                            @click="dialogContactInfo = false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-dialog
            v-model="dialogAvailability"
        >
            <v-card>
                <v-card-title>
                    <div class="d-flex flex-row flex-wrap">
                        <div class="d-flex flex-row flex-wrap">
                            <div>
                                <v-btn
                                    @click="startCalendar = addDays(startCalendar, -7)"
                                    class="mx-5 mt-4"
                                >
                                    prev week
                                </v-btn>
                            </div>
                            <div>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            colls="6"
                                            label="from week"
                                            v-bind="attrs"
                                            prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                            v-on="on"
                                            clearable
                                            readonly
                                            @click:clear="startCalendar = nowDate"
                                            :value="formattedDate(startCalendar)"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startCalendar"
                                        no-title
                                        scrollable
                                        color="blue"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                            <div>
                                <v-btn
                                    @click="startCalendar = addDays(startCalendar, 7)"
                                    class="mx-5 mt-4"
                                >
                                    next week
                                </v-btn>
                            </div>
                        </div>
                        <div class="ml-9 align-self-center">{{ contact }}</div>
                    </div>
                </v-card-title>
                <v-card-text>
                    <!-- <v-btn @click="changeWidthWeek('add')" class="ma-4" :disabled="amountOfWeeks===6">add week</v-btn>
                    <v-btn @click="changeWidthWeek('remove')" class="ma-4" :disabled="amountOfWeeks===1">remove week</v-btn> -->
                    <div v-for="(stay, indexAcc) in stays" :key="indexAcc"  class="all_stays vuecal__flex">
                        <strong class="d-flex align-end"><div style="width: 100px; font-size: 14px" class="pa-2 mb-4">{{ stay.name }}</div></strong>
                        <div v-for="week in amountOfWeeks" :key="week" class="all_weeks">
                            <vue-cal 
                                xsmall
                                hide-view-selector
                                :events="stay.nightEvents"
                                :selected-date="dateCalendar(week)"
                                :time="false"
                                :time-from="0"
                                :time-step="24 * 60"
                                :time-to="24 * 60"
                                :snapToTime="(24 * 60)"
                                :disable-views="['years', 'year', 'month', 'day']"
                                :editable-events="{ title: false, drag: true, resize: false, delete: false, create: false }"
                                :active-view="'week'"
                                :style="widthWeek"
                            >
                                <template v-slot:event="{ event }">
                                    <strong>
                                        <span class="vuecal__event-title mr-1" v-html="event.title" />
                                        <!-- <v-icon class="mr-1" small>
                                            {{ event.icon }}
                                        </v-icon> -->
                                        <div class="vuecal__event-content" v-html="event.content" />
                                    </strong>
                                </template>
                            </vue-cal>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="ppc_darkgreen darken-1"
                        text
                        @click="dialogAvailability = false"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>

import inputRules from '../mixins/inputRules';
import dayjs from 'dayjs';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import formattedDate from '../mixins/formattedDate';
import compare from '../mixins/compare';

export default {
    components: { 
        VueCal,
    },
    data: () => ({
        startCalendar:  null,
        amountOfWeeks: null,
        widthWeek: 'width: 400px',
        stays: [],
        tab: 0,
        itemsTab: [
            'Placements', 'Payments', 'Families', 'Apartments'
        ],
      dialogAcc: false,
      dialogAccPrices: false,
      dialogRooms: false,
      dialogRoom: false,
      dialogDeleteAcc: false,
      dialogDeleteRoom: false,
      dialogContactInfo: false,
      dialogEditResource: false,
      dialogAvailability: false,
      headersFamilies: [
        { text: 'Contact', value: 'contact' },
        { text: 'Rooms', value: 'amountRooms' },
        { text: '', value: 'checkAv', sortable: false},
        { text: 'Pets', value: 'pets' },
        { text: 'Vegetarian', value: 'vegetarian' },
        { text: 'Vegan', value: 'vegan' },
        { text: 'Comments', value: 'commentsAcc' },
        { text: 'Obsolete', value: 'obsoleteAcc' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersApartments: [
        { text: 'Contact', value: 'contact' },
        { text: 'Rooms', value: 'amountRooms' },
        { text: '', value: 'checkAv', sortable: false},
        { text: 'Comments', value: 'commentsAcc' },
        { text: 'Obsolete', value: 'obsoleteAcc' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersRooms: [
        { text: 'Name', value: 'name' },
        { text: 'Single', value: 'single_beds' },
        { text: 'Double', value: 'double_beds' },
        { text: 'Bunks', value: 'bunk_places' },
        { text: 'Bathroom', value: 'private_bathroom' },
        { text: 'Comments', value: 'commentsRoom' },
        { text: 'Obsolete', value: 'obsoleteRoom' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersResourcesScope: [
          { text: 'Client', value: 'full_name' },
          { text: 'Agency', value: 'agency' },
          { text: 'Class', value: 'accommodation_class'},
          { text: 'Room', value: 'accName' },
          { text: 'Service Name', value: 'servicesName' },
          { text: 'Type', value: 'family' },
          { text: 'Start', value: 'start', width: '120px'},
          { text: 'End', value: 'end', width: '120px'},
          { text: 'Paid Sec.Dep.', value: 'totalSecurityDeposit'},
          { text: 'Comments', value: 'comments', groupable: false },
          { text: 'Status', value: 'statusResourceId' },
          { text: 'Open', value: 'actions', sortable: false },
      ],
      headersAccPrices: [
        { text: 'Date', value: 'date_price' },
        { text: 'Private', value: 'price_private' },
        { text: 'Shared', value: 'price_shared' },
        { text: 'Bunk', value: 'price_bunk' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersPaymentsFamilies: [
        { text: 'client' , value: 'full_name'},
        { text: 'total stay' , value: 'stay'},
        { text: 'class' , value: 'accommodation_class'},
        { text: 'total payment' , value: 'payment'},
        { text: 'total nights' , value: 'duration'},
        { text: 'service nights' , value: 'servicesDuration'},
        { text: 'nights to be paid now', value: 'nightsShouldBePaidNow'},
        { text: 'extras PEN' , value: 'extras'},
        { text: 'already paid PEN' , value: 'amountPaidReceiver'},
        { text: 'calculation' , value: 'paymentDescription'},
        { text: 'to pay now PEN' , value: 'toPayNow'},
        { text: 'open resource', value: 'actions', sortable: false },
      ],
      payment_types: [
        { text: 'per night' , value: 'per night'},
        { text: 'fixed per stay' , value: 'fixed per stay'},
        { text: 'not client dependent' , value: 'not client dependent'},
      ],
      updatedAccommodations: [],
      updatedAccPrices: [],
      updatedRooms: [],
      editRoomsTable: [],
      updatedFamilies: [],
      updatedApartments: [],
      editedIndexAcc: -1,
      editedIndexRoom: -1,
      editedAcc: {
        contact: '',
        contact_id: null,
        family: null,
        price_private: 0,
        price_shared: 0,
        price_bunk: 0,
        date_price: null,
        addon_private_bathroom: 0.00,
        payment_appartment: 0,
        payment_type: 'per night',
        pets: 'does not have pets', 
        vegetarian: 'cooks vegetarian', 
        vegan: 'no vegan', 
        commentsAcc: '',
        obsoleteAcc: null,
      },
      defaultAcc: {
        contact: '',
        contact_id: null,
        family: null,
        price_private: 0,
        price_shared: 0,
        price_bunk: 0,
        date_price: null,
        addon_private_bathroom: 0.00,
        payment_appartment: 0,
        payment_type: 'per night',
        pets: 'does not have pets', 
        vegetarian: 'cooks vegetarian', 
        vegan: 'no vegan', 
        commentsAcc: '',
        obsoleteAcc: null,
      },
      editedRoom: {
        accommodation_id: null,
        single_beds: 0,
        double_beds: 0,
        bunk_places: 0,
        private_bathroom: 0,
        commentsRoom: '',
        obsoleteRoom: null,
      },
      defaultRoom: {
        accommodation_id: null,
        single_beds: 0,
        double_beds: 0,
        bunk_places: 0,
        private_bathroom: 0,
        commentsRoom: '',
        obsoleteRoom: null,
      },
      contact: [],
      resourcesPaymentMonth: [],
      resourcesScope: [],
      resourcesLoaded: [],
      resourcesNotPlaced: [],
      resource: [],
      nowDate: null,
      nameChange: false,
      paymentsContacts: [],
      paymentsContactsRender: [],
      loadingbuttonAllPlacements: false,
      searchResourcesNotPlaced: '',
      searchResourcesScope: '',
      scopeResourcesPlanned: 'notPlaced',
      availableRooms: [],
      selectedReservation: [],
      selectedReservationSingleSelect: true,
      rendererDialogAccTable: Math.random(),
      editedAccPrices: {},
    }),

    mixins: [inputRules, formattedDate, compare],

    computed: {
        formTitle () {
            return this.editedIndexAcc === -1 ? (this.editedAcc.family === 0 ? 'New Apartment ' : 'New Family ') : (this.editedAcc.family === 0 ? 'Apartment ' : 'Family ')
        },
        endOfMonth () {
            return dayjs(this.monthCalcPaymentsAccommodation.month).endOf('month').format('YYYY-MM-DD')
        },
        startOfMonth () {
            return dayjs(this.monthCalcPaymentsAccommodation.month).startOf('month').format('YYYY-MM-DD')
        },
        startOfNextMonth () {
            return dayjs(dayjs(this.monthCalcPaymentsAccommodation.month).add(1, 'M')).startOf('month').format('YYYY-MM-DD')
        },
        monthCalcPaymentsAccommodation () {
            return this.$store.state.monthCalcPaymentsAccommodation;
        }
    },

    beforeCreate () {
        if (this.$store.state.accessAccommodation === 0) {
            this.$router.push({ name: 'Illegal' })
        }
        this.$store.dispatch('setLoadingApp', 1)
    },

    created () {
        dayjs.locale("en");
        this.initialize()
    },

    methods: {
        async initialize () {
            // console.log('initialize', this.$route.params.resourceId)
            let n = new Date();
            this.nowDate = this.formattedDate(n, 'date');
            this.$store.dispatch('getContacts');
            let previousView =  this.$store.state.breadcrumbs[this.$store.state.breadcrumbs.length - 1];
            if (previousView === 'AccommodationPaymentForm') {
                this.tab = 1;
            }
            await this.getData();
            if (this.$route.params.resourceId) {
                this.scopeResourcesPlanned = 'notPlaced';
            }
            await this.getResources();
            this.calcPaymentsMonth();
            await this.$store.dispatch('breadcrumbs', ['Accommodation']);
            if (this.$route.params.resourceId) {
                this.openDialogEditResource(this.$route.params.resourceId);
                this.$store.dispatch('saveDataForReport', );
            } else {
                this.$store.dispatch('setLoadingApp', 0);
            }
        },

      async getData() {
        await Promise.all([this.$store.dispatch('getRooms'), this.$store.dispatch('getAccommodations'), this.$store.dispatch('getAccommodationPrices')]).then((values) => {
            this.updatedRooms = JSON.parse(JSON.stringify(values[0]));
            this.updatedAccommodations = JSON.parse(JSON.stringify(values[1]));
            this.updatedAccPrices = JSON.parse(JSON.stringify(values[2]));
        });
        this.updatedApartments = [];
        this.updatedFamilies = [];
        for (let index = 0; index < this.updatedAccommodations.length; index++) {
            if (this.updatedAccommodations[index].family === 1) {
                this.updatedFamilies.push(this.updatedAccommodations[index])
            } else {
                this.updatedApartments.push(this.updatedAccommodations[index])
            }
        }
      },

      async getResources() {
        // this.$store.dispatch('setLoadingApp', 1);
        // this.resourcesScope = [];
        this.resourcesLoaded = [];
        this.$store.dispatch('progressAdvance', 10);
        if (this.scopeResourcesPlanned === 'completed') {
            this.resourcesLoaded = await this.$store.dispatch('getResourcesAllAccommodations');
        } else {
            this.resourcesLoaded = await this.$store.dispatch('getResourcesAccommodations');
        }
        for (let i = 0; i < this.resourcesLoaded.length; i++) {
            (this.resourcesLoaded[i].start) ? this.resourcesLoaded[i].start = this.formattedDate(this.resourcesLoaded[i].start, "date") : this.resourcesLoaded[i].start = null;
            (this.resourcesLoaded[i].end) ? this.resourcesLoaded[i].end = this.formattedDate(this.resourcesLoaded[i].end, "date") : this.resourcesLoaded[i].end = null;
            (this.resourcesLoaded[i].date_price) ? this.resourcesLoaded[i].date_price = this.formattedDate(this.resourcesLoaded[i].date_price, "date") : this.resourcesLoaded[i].date_price = null;
            (this.resourcesLoaded[i].info_to_client) ? this.resourcesLoaded[i].info_to_client = this.formattedDate(this.resourcesLoaded[i].info_to_client, "date") : this.resourcesLoaded[i].info_to_client = null;
            (this.resourcesLoaded[i].info_to_provider) ? this.resourcesLoaded[i].info_to_provider = this.formattedDate(this.resourcesLoaded[i].info_to_provider, "date") : this.resourcesLoaded[i].info_to_provider = null;
            (this.resourcesLoaded[i].reserved) ? this.resourcesLoaded[i].reserved = this.formattedDate(this.resourcesLoaded[i].reserved, "date") : this.resourcesLoaded[i].reserved = null;
            (this.resourcesLoaded[i].family === 0) ? this.resourcesLoaded[i].family = '0' : this.resourcesLoaded[i].family = '1';
            (this.resourcesLoaded[i].security_deposit === 0) ? this.resourcesLoaded[i].security_deposit = '' : this.resourcesLoaded[i].security_deposit;
            if (this.resourcesLoaded[i].statusResourceId === 9 || this.resourcesLoaded[i].room_id === null) {
                this.resourcesLoaded[i].isSelectable = false;
            }
        }
        this.filterResources();
        this.$store.dispatch('setLoadingApp', 0);
      },

      filterResources() {
        if (this.scopeResourcesPlanned === 'completed') {
            this.resourcesScope = this.resourcesLoaded.filter(function(select_item) {
                return select_item.statusResourceId === 1;
            });
            this.resourcesPaymentMonth = this.resourcesLoaded.filter(function(select_item) {
                return select_item.statusResourceId > 1;
            });
        } else if (this.scopeResourcesPlanned === 'notPlaced') {
            this.resourcesPaymentMonth = this.resourcesLoaded;
            this.resourcesScope = this.resourcesLoaded.filter(function(select_item) {
                return select_item.room_id === null || select_item.statusResourceId === 6 || select_item.statusResourceId === 5 || select_item.statusResourceId === 7 || select_item.statusResourceId === 9;
            });
        } else if (this.scopeResourcesPlanned === 'placed') {
            this.resourcesPaymentMonth = this.resourcesLoaded;
            this.resourcesScope = this.resourcesLoaded.filter(function(select_item) {
                return select_item.room_id !== null && select_item.statusResourceId !== 6  && select_item.statusResourceId !== 5 && select_item.statusResourceId !== 7 && select_item.statusResourceId !== 9 ;
            });
        } else if (this.scopeResourcesPlanned === 'ongoing') {
            this.resourcesPaymentMonth = this.resourcesLoaded;
            this.resourcesScope = this.resourcesLoaded.filter(function(select_item) {
                return select_item.statusResourceId !== 15 && select_item.statusResourceId !== 1;
            });
        } 
      },

      dateCalendar(week) {
                return this.addDays(this.startCalendar, 7 * (week - 1))
        },

        addDays(startDate, days) {
            let new_date = dayjs(startDate).add(days, 'd');
            let day = new_date.format('DD');
            let month = new_date.format('MM');
            let year = new_date.format('YYYY');
            return year + '-' + month + '-' + day
        },

        async openAvailability (accId, viewFrom) {
            console.log(accId)
            if (accId) {
                this.$store.dispatch('setLoadingApp', 1);
                await this.makeCalendar(accId, viewFrom);
                this.$store.dispatch('setLoadingApp', 0);
                this.dialogAvailability = true;
            }
        },

        async makeCalendar(accommodation_id, viewFrom) {
            if (viewFrom) {
                this.startCalendar = viewFrom;
            } else {
                this.startCalendar = this.formattedDate(new Date(), 'date');
            }
            if (window.innerWidth < 1000) {
                this.amountOfWeeks = 1;
            } else if (window.innerWidth < 1440) {
                this.amountOfWeeks = 2;
            } else if (window.innerWidth < 1920) {
                this.amountOfWeeks = 3;
            } else {
                this.amountOfWeeks = 4;
            }
            let roomsAcc = this.updatedRooms.filter(function(select_item) {
                return select_item.accommodation_id === accommodation_id;
            })
            this.stays = [];
            var name;
            let results = await this.$store.dispatch('getResourcesAllAccommodations');
            for (let index = 0; index < roomsAcc.length; index++) {
                this.contact = roomsAcc[index].contact;
                name = roomsAcc[index].name + " (";
                if (roomsAcc[index].single_beds > 0) {
                    name = name + roomsAcc[index].single_beds + "s, "
                }
                if (roomsAcc[index].double_beds > 0) {
                    name = name + roomsAcc[index].double_beds + "d, "
                }
                if (roomsAcc[index].bunk_places > 0) {
                    name = name + roomsAcc[index].bunk_places + "b, "
                }
                name = name.slice(0, -2) + ")";
                let resourcesRoom = results.filter(function(select_item) {
                    return select_item.room_id === roomsAcc[index].id;
                })
                var arrayClients = [];
                for (let index2 = 0; index2 < resourcesRoom.length; index2++) {
                    if (arrayClients.indexOf(resourcesRoom[index2].client_id) === -1) {
                        var nightsRange = [];
                        arrayClients.push(resourcesRoom[index2].client_id);
                        console.log(arrayClients)
                        var staysClient = resourcesRoom.filter(function(select_item) {
                            return select_item.client_id === resourcesRoom[index2].client_id;
                        })
                        for (let index3 = 0; index3 < staysClient.length; index3++) {
                            nightsRange.push({ 
                                start: this.formattedDate(staysClient[index3].start, "date"), 
                                end: this.formattedDate(staysClient[index3].end, "date"),
                                title: staysClient[index3].full_name,
                                content: staysClient[index3].accommodation_class,
                                class: staysClient[index3].accommodation_class.replace(" ", "_"),
                            })
                        }
                        this.stays.push({name: name, nightsRange: nightsRange});
                        name = '';
                    }
                }
                if (resourcesRoom.length === 0) {
                    this.stays.push({name: name, nightsRange: []});
                }
            }
            for (let index1 = 0; index1 < this.stays.length; index1++) {
                this.stays[index1].nightEvents = new Array();
                for (let index2 = 0; index2 < this.stays[index1].nightsRange.length; index2++) {
                    let days = dayjs(this.stays[index1].nightsRange[index2].end).diff(dayjs(this.stays[index1].nightsRange[index2].start), 'days');
                    for (let index3 = 0; index3 < days + 1; index3++) {
                        if (index3 === 0 && days === 2) {
                            var title = this.stays[index1].nightsRange[index2].title;
                            var content = this.stays[index1].nightsRange[index2].content;
                        } else if (index3 === 0 && days > 2) {
                            title = this.stays[index1].nightsRange[index2].title;
                            content = '';
                        } else if (index3 === 1 && days > 2) {
                            title = this.stays[index1].nightsRange[index2].content;
                            content = '';
                        } else if (index3 === 2 && days > 3) {
                            title = days + ' nights';
                            content = '';
                        } else if (index3 === days) {
                            title = 'check';
                            content = 'out';
                        } else {
                            title = '';
                            content = '';
                        }
                        this.stays[index1].nightEvents.push({
                            start: this.addDays(this.stays[index1].nightsRange[index2].start, index3) + " 00:00:00",
                            end: this.addDays(this.stays[index1].nightsRange[index2].start, index3) + " 23:59:59",
                            title: title,
                            content: content,
                            class: this.stays[index1].nightsRange[index2].class,
                        })
                    }
                }
            }
        },

        getStatusResource(id) {
            if (id) {
                return this.$store.state.statusResources.filter((select_item) => {
                    return select_item.id === id;
                })[0];
            } else {
                return [];
            }
        },

        async openDialogEditResource(id) {
            // console.log('openDialogEditResource', '%%%%%%%%%1', id)
            this.$store.dispatch('setLoadingApp', 1);
            let result = this.resourcesLoaded.filter(function(select_item) {
                return select_item.id === id;
            });
            console.log('openDialogEditResource', '%%%%%%%%%2', this.resourcesLoaded.length, result)
            if (result.length === 0) {
                result = await this.$store.dispatch('getResourceIdAcc', id);
            } else {
                result = result[0];
            }
            console.log('openDialogEditResource', '%%%%%%%%%2b', result)
            this.resource = Object.assign({}, result);
            if (this.resource.family == 1) {
                console.log('openDialogEditResource test1', this.resource.family);
                await this.calcPaymentResource();
            }
            console.log('openDialogEditResource', '%%%%%%%%%3')
            this.availableRooms = await this.filterRooms(this.resource);
            this.$store.dispatch('setLoadingApp', 0);
            this.dialogEditResource = true;
            console.log('openDialogEditResource', '%%%%%%%%%4')
        },

        async calcPaymentResource() {
            console.log('calcPaymentResource');
            let roomId = this.resource.room_id;
            if (roomId && roomId !== null) {
                this.resource.accommodation_id = this.updatedRooms.filter(function(select_item) {
                    return select_item.id === roomId;
                })[0].accommodation_id;
                this.resource.duration = Math.ceil(this.diffDates(this.resource.start, this.resource.end) );
                this.resource.currency = 'S/.';
                if (this.resource.extras === null || !this.resource.extras) {
                    this.resource.extras = 0;
                }
                let [payment, paymentDescription] = this.calcPaymentAccDates(this.resource.accommodation_id, this.resource.start, this.resource.end, this.resource.accommodation_class);
                if (this.resource.extras !== 0) {
                    payment = payment + Number(this.resource.extras);
                    console.log('calcPaymentResource 1', payment);
                    paymentDescription = paymentDescription + ' + ' + this.resource.extras + ' PEN extras '
                }
                // let nightsShouldBePaidTotal = Number(this.calcNights(this.resource.start, this.resource.end));
                // console.log('calcPaymentResource 2', payment, paymentDescription);
                this.resource.payment = payment;
                this.resource.paymentDescription = paymentDescription;
            }
        },

        async calcPaymentsMonth() {
            // console.log('calcPaymentsMonth');
            var date = new Date();
            let start = date.getTime();
            this.paymentsContacts = [];
            this.paymentsContactsRender = [];
            for (let index = 0; index < this.resourcesPaymentMonth.length; index++) {
                if (this.resourcesPaymentMonth[index].accommodation_id) {
                    if (this.monthCalcPaymentsAccommodation.type === 'family') { 
                        if (this.resourcesPaymentMonth[index].family != 1) {
                            continue;
                        }
                    } else {
                        if (this.resourcesPaymentMonth[index].family == 1) {
                            continue;
                        }
                    }
                    if (this.resourcesPaymentMonth[index].amountPaidReceiver === null) {
                        this.resourcesPaymentMonth[index].amountPaidReceiver = 0;
                    }
                    let lastPayDay;
                    if (this.startOfNextMonth < this.resourcesPaymentMonth[index].end) {
                        lastPayDay = this.startOfNextMonth;
                    } else {
                        lastPayDay = this.resourcesPaymentMonth[index].end;
                    }
                    let firstPayDay;
                    if (this.startOfMonth < this.resourcesPaymentMonth[index].start) {
                        firstPayDay = this.resourcesPaymentMonth[index].start;
                    } else {
                        firstPayDay = this.startOfMonth;
                    }
                    let [toPayNow, paymentDescription] = await this.calcPaymentAccDates(this.resourcesPaymentMonth[index].accommodation_id, this.resourcesPaymentMonth[index].start, lastPayDay, this.resourcesPaymentMonth[index].accommodation_class);
                    if (this.resourcesPaymentMonth[index].extras !== 0) {
                        toPayNow = toPayNow + this.resourcesPaymentMonth[index].extras;
                        paymentDescription = paymentDescription + ' + ' + this.resourcesPaymentMonth[index].extras + ' PEN extras '
                    }
                    if (this.resourcesPaymentMonth[index].amountPaidReceiver !== 0) {
                        toPayNow = toPayNow - this.resourcesPaymentMonth[index].amountPaidReceiver;
                        paymentDescription = paymentDescription + ' - ' + this.resourcesPaymentMonth[index].amountPaidReceiver + ' PEN paid ';
                    }
                    let nightsShouldBePaidTotal = Number(this.calcNights(this.resourcesPaymentMonth[index].start, lastPayDay));
                    let nightsShouldBePaidNow = Number(this.calcNights(firstPayDay, lastPayDay));
                    // console.log('calcPaymentsMonth 2', this.resourcesPaymentMonth[index], nightsShouldBePaidTotal);
                    this.resourcesPaymentMonth[index].nightsShouldBePaidTotal = nightsShouldBePaidTotal;
                    // this.resourcesPaymentMonth[index].firstPayDay = firstPayDay;
                    this.resourcesPaymentMonth[index].nightsShouldBePaidNow = nightsShouldBePaidNow;
                    this.resourcesPaymentMonth[index].lastPayDay = lastPayDay;
                    this.resourcesPaymentMonth[index].toPayNow = toPayNow;
                    this.resourcesPaymentMonth[index].paymentDescription = paymentDescription;
                    let paymentDescriptionReport = paymentDescription;
                    paymentDescriptionReport = paymentDescriptionReport.replaceAll("night(s)", "noche(s)");
                    paymentDescriptionReport = paymentDescriptionReport.replaceAll("extras", "recojo");
                    paymentDescriptionReport = paymentDescriptionReport.replaceAll("paid", "pagado");
                    this.resourcesPaymentMonth[index].paymentDescriptionReport = paymentDescriptionReport;
                    let totalPayment = toPayNow;
                    // for report
                    // let [toPayReport, paymentDescriptionReport] = await this.calcPaymentAccDates(this.resourcesPaymentMonth[index].accommodation_id, firstPayDay, lastPayDay, this.resourcesPaymentMonth[index].accommodation_class);
                    // this.resourcesPaymentMonth[index].toPayReport = toPayReport;
                    // paymentDescriptionReport = paymentDescriptionReport.replaceAll("night(s)", "noche(s)");
                    // this.resourcesPaymentMonth[index].paymentDescriptionReport = paymentDescriptionReport;
                    // if (firstPayDay > this.resourcesPaymentMonth[index].start) {
                    //     // calc payment nights before
                    //     let [paymentNightsBefore] = await this.calcPaymentAccDates(this.resourcesPaymentMonth[index].accommodation_id, this.resourcesPaymentMonth[index].start, firstPayDay, this.resourcesPaymentMonth[index].accommodation_class);
                    //     this.resourcesPaymentMonth[index].extrasReport = paymentNightsBefore + this.resourcesPaymentMonth[index].extras - this.resourcesPaymentMonth[index].amountPaidReceiver;
                    // } else {
                    //     this.resourcesPaymentMonth[index].extrasReport = this.resourcesPaymentMonth[index].extras;
                    // }
                    if (toPayNow !== 0 && this.resourcesPaymentMonth[index].nightsShouldBePaidTotal > 0) {
                        // console.log('calcPaymentsMonth 3', toPayNow, nightsShouldBePaidTotal, paymentDescription, this.resourcesPaymentMonth[index]);
                        let pos = this.paymentsContacts.map(function(e) { return e.family; }).indexOf(this.resourcesPaymentMonth[index].contact_full_name);
                        if (pos === -1) {
                            this.paymentsContacts.push({
                                family: this.resourcesPaymentMonth[index].contact_full_name,
                                contact_id: this.resourcesPaymentMonth[index].contact_id,
                                totalPayment: totalPayment,
                                emailContact: this.resourcesPaymentMonth[index].emailContact,
                                resources: [this.resourcesPaymentMonth[index]],
                            });
                        } else {
                                this.paymentsContacts[pos].resources.push(this.resourcesPaymentMonth[index]);
                                this.paymentsContacts[pos].totalPayment = this.paymentsContacts[pos].totalPayment + toPayNow;
                        }
                        this.paymentsContacts.sort(this.dynamicSort('family'));
                    }
                }
            }
            this.paymentsContactsRender = JSON.parse(JSON.stringify(this.paymentsContacts));
            date = new Date();
            let end = date.getTime();
            const totalTime = ((end-start)/1000);
            console.log('Time building payments: ', totalTime);
        },
        
        calcPaymentAccDates(accommodation_id, start, end, accommodation_class) {
            // console.log('calcPaymentAccDates', accommodation_id, start, end, extras, paid, accommodation_class);
            let paymentDescription = '';
            let toPay = 0;
            let nightsToBePaid = 0;
            let rate = 0;
            // get price changes for resource
            let priceChanges = this.updatedAccPrices.filter(function(select_item) {
                return select_item.accommodation_id === accommodation_id && select_item.date_price < end;
            })
            priceChanges.sort(this.dynamicSort('date_price'));
            // console.log('calcPaymentAccDates 2', accommodation_id, priceChanges.length, this.formattedDate(priceChanges[0].date_price, 'date'), start);
            if (priceChanges.length > 0 && this.formattedDate(priceChanges[0].date_price, 'date') <= start) {
                // get the last priceChange before or on start
                let beforePriceChange = {};
                if (start >= priceChanges[priceChanges.length-1].date_price) {
                    // last priceChange before start, all same rate
                    nightsToBePaid = this.calcNights(start, end);
                    rate = this.getRateAcc(priceChanges[priceChanges.length-1], accommodation_class);
                    toPay = (nightsToBePaid * rate);
                    // console.log('last priceChange before start, all same rate', start, toPay, nightsToBePaid);
                    paymentDescription = Number(nightsToBePaid) + ' night(s) x ' + rate + ' PEN ';
                } else {
                    // priceChanges after start
                    for (let i = 0; i < priceChanges.length; i++) {
                        // console.log('priceChanges after start', priceChanges[i], beforePriceChange, start, end);
                        if (priceChanges[i].date_price > start) {
                            // date_price > start
                            // console.log('date_price > start');
                            if (beforePriceChange.date_price < start) {
                                // before priceChange before start
                                nightsToBePaid = Number(this.calcNights(start, priceChanges[i].date_price));
                                // console.log('before priceChange before start', start, priceChanges[i].date_price, this.calcNights(start, priceChanges[i].date_price));
                            } else {
                                // before priceChange after start
                                nightsToBePaid = Number(this.calcNights(beforePriceChange.date_price, priceChanges[i].date_price));
                                // console.log('before priceChange after start', beforePriceChange.date_price, priceChanges[i].date_price, this.calcNights(beforePriceChange.date_price, priceChanges[i].date_price));
                            }
                            rate = this.getRateAcc(beforePriceChange, accommodation_class);
                            paymentDescription = paymentDescription + ' + ' + nightsToBePaid + ' night(s) x ' + rate + ' PEN ';
                            toPay = toPay + (nightsToBePaid * rate);
                            if (i === priceChanges.length - 1) {
                                // last priceChange before end
                                nightsToBePaid = Number(this.calcNights(priceChanges[i].date_price, end));
                                // console.log('last priceChange before end', priceChanges[i], beforePriceChange.date_price, priceChanges[i].date_price);
                                rate = this.getRateAcc(priceChanges[i], accommodation_class);
                                paymentDescription = paymentDescription + ' + ' + nightsToBePaid + ' night(s) x ' + rate + ' PEN ';
                                toPay = toPay + (nightsToBePaid * rate);
                            }
                        }
                        beforePriceChange = Object.assign({}, priceChanges[i]);
                    }
                }
                // paymentDescription = paymentDescription + toPay + ' PEN'
                if (paymentDescription.substring(0,3) === ' + ') {
                    paymentDescription = paymentDescription.substring(3, paymentDescription.length);
                }
                return [toPay, paymentDescription];
            }
            // console.log('calcPaymentAccDates end', accommodation_id, priceChanges.length, this.formattedDate(priceChanges[0].date_price, 'date'), start);
            this.$store.dispatch('errorMessage', 'Accommodation nr ' + accommodation_id + ' does not have initial prices defined!');
            return [0,'null'];
        },

        getRateAcc (priceChange, accommodation_class) {
            let rate = 0;
            if (accommodation_class === 'Private Room') {
                rate = priceChange.price_private;
            }
            if (accommodation_class === 'Shared Room' || accommodation_class === 'Private Double' || accommodation_class === 'Private Twin') {
                rate = priceChange.price_shared;
            }
            if (accommodation_class === 'Shared Bunk') {
                rate = priceChange.price_bunk;
            }
            return rate;
        },

        async filterRooms (resource) {
            console.log('filterRooms', '%%%%%%%%%1');
            if (resource.family !== null && resource.family) {
                let listRooms = this.updatedRooms.filter(function(select_item) {
                    return select_item.family == resource.family && select_item.obsoleteAcc === null && select_item.obsoleteRoom === null;
                });
                await listRooms.sort(this.dynamicSort('contact'));
                let payload = {
                    start: resource.start,
                    end: resource.end,
                }
                console.log('filterRooms', '%%%%%%%%%2');
                let resourcesPlaced = await this.$store.dispatch('getResourcesAccommodationsPlaced', payload);
                console.log('filterRooms', '%%%%%%%%%3');
                for (let index = 0; index < listRooms.length; index++) {
                    listRooms[index].success = false;
                    listRooms[index].listEntry = listRooms[index].roomSelectName + " (";
                    if (listRooms[index].single_beds > 0) {
                        listRooms[index].listEntry = listRooms[index].listEntry + listRooms[index].single_beds + "s, "
                    }
                    if (listRooms[index].double_beds > 0) {
                        listRooms[index].listEntry = listRooms[index].listEntry + listRooms[index].double_beds + "d, "
                    }
                    if (listRooms[index].bunk_places > 0) {
                        listRooms[index].listEntry = listRooms[index].listEntry + listRooms[index].bunk_places + "b, "
                    }
                    listRooms[index].listEntry = listRooms[index].listEntry.slice(0, -2) + ")";
                    let lengthOriginal = listRooms[index].listEntry.length;
                    if ((resource.accommodation_class === 'Private Room' || resource.accommodation_class === 'Shared Room') && listRooms[index].single_beds === 0 && listRooms[index].double_beds === 0) {
                        listRooms[index].listEntry = listRooms[index].listEntry + ' - no beds';
                    }
                    if (resource.accommodation_class === 'Shared Bunk' && listRooms[index].bunk_places === 0) {
                        listRooms[index].listEntry = listRooms[index].listEntry + ' - no bunks';
                    }
                    if (resource.accommodation_class !== 'Shared Bunk') {
                        if (resource.private_bathroom == 1 && listRooms[index].private_bathroom == 0) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - no private bathroom';
                        }
                    }
                    if (resource.family == 1) {
                        if (resource.pets_preference === 'No pets please' && listRooms[index].pets === 'have pets') {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - have pets';
                        }
                        if (resource.food_preference === 'I am vegetarian' && listRooms[index].vegetarian === 'no vegetarian') {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - no vegetarian';
                        }
                        if (resource.food_preference === 'I am vegan' && listRooms[index].vegan === 'no vegan') {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - no vegan';
                        }
                    }
                    let roomMates = resourcesPlaced.filter(function(select_item) {
                        return (select_item.room_id === listRooms[index].id);
                    });
                    if (resource.accommodation_class === 'Private Room') {
                        if (roomMates.length > 0) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - not available'
                        } else if (listRooms[index].single_beds + listRooms[index].double_beds > 1) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - more beds'
                        } else if (listRooms[index].double_beds > 0 && listRooms[index].single_beds === 0) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - double bed'
                        }
                    }
                    if (resource.accommodation_class === 'Private Double') {
                        console.log(roomMates, listRooms[index].roomSelectName)
                        if (roomMates.length > 1) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - not available'
                        } else if (listRooms[index].double_beds === 0) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - no double bed'
                        } else if (listRooms[index].double_beds + listRooms[index].single_beds > 1) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - more beds'
                        }
                    }
                    if (resource.accommodation_class === 'Private Twin') {
                        if (roomMates.length > 1) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - not available'
                        } else if (listRooms[index].double_beds + listRooms[index].single_beds < 2) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - not enough beds'
                        } else if (listRooms[index].double_beds + listRooms[index].single_beds > 2) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - more beds'
                        }
                    }
                    if (resource.accommodation_class === 'Shared Room') {
                        if (listRooms[index].single_beds + listRooms[index].double_beds === 1) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - private room'
                        } else if (roomMates.length === listRooms[index].single_beds + listRooms[index].double_beds) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - not available'
                        } else if (roomMates.length === 1 && (roomMates[0].accommodation_class === 'Private Room' || roomMates[0].accommodation_class === 'Private Double' || roomMates[0].accommodation_class === 'Private Twin')) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - private occupied'
                        }
                    }
                    if (resource.accommodation_class === 'Shared Bunk') {
                        if (roomMates.length === listRooms[index].bunk_places) {
                            listRooms[index].listEntry = listRooms[index].listEntry + ' - not available'
                        }
                    }
                    if (listRooms[index].listEntry.length === lengthOriginal) {
                        listRooms[index].success = true;
                    }
                }
                return listRooms;
            }
        },

        async insertUpdateResource() {
            // console.log('insertUpdateResource', '&&&&&&&&1');
            // this.$store.dispatch('setLoadingApp', 1);
            this.resource.updating = true;
            await this.calcPaymentResource();
            // (!this.resource.start || this.resource.start === null) ? (null) : (this.formattedDate(this.resource.start, 'date'));
            // (!this.resource.end || this.resource.end === null) ? (null) : (this.formattedDate(this.resource.end, 'date'));
            if (this.resource.date_price !== null) {
                this.resource.date_price = this.formattedDate(this.resource.date_price, 'date');
            }
            this.resource.extras = Number(this.resource.extras);
            this.$store.dispatch('insertResource', this.resource);
            let objIndex = this.resourcesScope.findIndex(obj => obj.id == this.resource.id);
            // console.log('insertUpdateResource', this.resource, objIndex, this.resourcesScope[objIndex], '&&&&&&&&2');
            await this.resourcesScope.splice(objIndex, 1, this.resource);
            // await this.getData();
            console.log('######### 8');
            await this.getResources();
            this.resource.updating = false;
            console.log('######### 9');
            this.calcPaymentsMonth();

            this.$store.dispatch('setLoadingApp', 0);

        },

        openContactInfo(editedAcc) {
            let tmp = editedAcc.contact_id;
            this.contact = this.$store.state.contacts.filter(function(select_item) {
                return select_item.id === tmp;
            })[0]
            this.dialogContactInfo = true;
        },

        goClient(id, resourceId) {
            this.$store.dispatch('setLoadingApp', 1);
            this.$router.push({ name: 'Client', params: { id: id, resourceId: resourceId } })
        },

        setObsoleteAcc(event) {
            if (event) {
                this.editedAcc.obsoleteAcc = dayjs().format("YYYY-MM-DD")
            } else {
                this.editedAcc.obsoleteAcc = null
            }
        },

        setObsoleteRoom(event) {
            if (event) {
                this.editedRoom.obsoleteRoom = dayjs().format("YYYY-MM-DD");
            } else {
                this.editedRoom.obsoleteRoom = null;
            }
            
        },

        editAcc (item) {
            this.editedIndexAcc = this.updatedAccommodations.indexOf(item);
            this.editedAcc = Object.assign({}, item);
            this.openDialogAcc();
        },

        openDialogAcc () {
            // console.log('openDialogAcc start', this.editedAcc, this.updatedAccPrices)
            let tmp = this.editedAcc.id;
            let objects = this.updatedAccPrices.filter(function(select_item) {
                return select_item.accommodation_id === tmp;
            })
            this.editedAcc.prices = JSON.parse(JSON.stringify(objects));
            console.log('openDialogAcc', this.editedAcc)
            this.dialogAcc = true;
        },

        editRooms (item) {
            this.editedIndexRoom = this.updatedAccommodations.indexOf(item);
            this.editedAcc = Object.assign({}, item);
            this.getRoomsAcc(this.editedAcc.id)
            this.dialogRooms = true;
        },

        getRoomsAcc(accId) {
            this.editRoomsTable = this.updatedRooms.filter(function(select_item) {
                return select_item.accommodation_id === accId;
            })
        },

        editRoom (item) {
            this.editedIndexRoom = this.updatedRooms.indexOf(item);
            this.editedRoom = Object.assign({}, item);
            this.dialogRoom = true;
        },

        deleteAcc (item) {
            this.editedIndexAcc = this.updatedAccommodations.indexOf(item);
            this.editedAcc = Object.assign({}, item);
            let tmp = this.editedAcc.id;
            if (this.updatedRooms.filter(function(select_item) {
                return select_item.accommodation_id === tmp;
            }).length > 0) {
                this.$store.dispatch('errorMessage', 'Remove first the rooms in the accommodation');
            } else {
                this.dialogDeleteAcc = true;
            }
        },

        async deleteAccConfirm () {
            await this.$store.dispatch('deleteAccommodation', this.editedAcc.id)
            await this.getData();
            this.closeDeleteAcc();
        },

        async deleteRoom (item) {
            this.editedIndexRoom = this.updatedRooms.indexOf(item);
            this.editedRoom = Object.assign({}, item);
            let roomUsed = await this.$store.dispatch('getResourcesRoomId', item.id);
            console.log(item.id, roomUsed.length)
            if (roomUsed.length > 0) {
                this.$store.dispatch('errorMessage', 'Room is being used. You can put it in obsolete instead');
            } else {
                this.dialogDeleteRoom = true;
            }
        },

        async deleteRoomConfirm () {
            await this.$store.dispatch('deleteRoom', this.editedRoom.id);
            await this.getData();
            this.getRoomsAcc(this.editedAcc.id);
            this.dialogDeleteRoom = false;
            // this.closeDeleteRoom();
        },

        closeAcc () {
            this.editedAcc = Object.assign({}, this.defaultAcc);
            this.editedIndexAcc = -1;
            this.getData();
        },

        closeRoom () {
            this.editedRoom = Object.assign({}, this.defaultRoom)
            this.editedIndexRoom = -1
        },

        closeDeleteAcc () {
            this.dialogDeleteAcc = false;
            this.editedAcc = Object.assign({}, this.defaultAcc)
            this.editedIndexAcc = -1
        },

        async saveAccTotal () {
            if(this.$refs.dialogFormAcc.validate()) {
                (!this.editedAcc.security_deposit) ? (this.editedAcc.security_deposit = 0) : (this.editedAcc.security_deposit);
                this.editedAcc.comments = this.editedAcc.commentsAcc;
                this.editedAcc.obsolete = this.editedAcc.obsoleteAcc;
                (!this.editedAcc.obsolete) ? (this.editedAcc.obsolete = null) : (this.editedAcc.obsolete = this.formattedDate(this.editedAcc.obsolete,'date'));
                if (this.editedAcc.contact_id) {
                    let tmp = this.editedAcc.contact_id;
                    let contactName = this.$store.state.contacts.filter(function(select_item) {
                        return select_item.id === tmp;
                    })
                    this.editedAcc.contact = contactName[0].name
                } else {
                    this.editedAcc.contact = null
                }
                let results = await this.$store.dispatch('insertAccommodation', this.editedAcc);
                this.editedAcc.prices.sort(this.dynamicSort('date_price'));
                for (let i = 0; i < this.editedAcc.prices.length; i++) {
                    (!this.editedAcc.prices[i].date_price) ? (this.editedAcc.prices[i].date_price = null) : (this.editedAcc.prices[i].date_price = this.formattedDate(this.editedAcc.prices[i].date_price,'date'));
                    if (results.insertId !== 0) {
                        this.editedAcc.prices[i].accommodation_id = results.insertId;
                    }
                    let thisAcc = [];
                    if (this.editedAcc.prices[i].id) {
                        let tmp = this.editedAcc.prices[i].id;
                        thisAcc = this.updatedAccPrices.filter(function(select_item) {
                            return select_item.id === tmp;
                        })[0];
                        thisAcc.date_price = this.formattedDate(thisAcc.date_price, 'date');
                    }
                    console.log('saveAccTotal 2');
                    if (!this.editedAcc.prices[i].id || !this.sameArrays(thisAcc, this.editedAcc.prices[i])) {
                        console.log('saveAccTotal 3');
                        await this.$store.dispatch('insertAccommodationPrices', this.editedAcc.prices[i]);
                    }
                }
                // console.log('saveAccTotal 5', this.updatedAccPrices, this.editedAcc.prices);
                let tmp = this.editedAcc.id;
                let thisAcc = this.updatedAccPrices.filter(function(select_item) {
                    return select_item.accommodation_id === tmp;
                })
                for (let i = 0; i < thisAcc.length; i++) {
                    let tmp = thisAcc[i].id;
                    let result = this.editedAcc.prices.filter(function(select_item) {
                        return select_item.id === tmp;
                    })
                    // console.log('saveAccTotal 6', tmp, result);
                    if (result.length === 0) {
                        await this.$store.dispatch('deleteAccommodationPrices', tmp);
                    }
                }
                await this.initialize();
                this.dialogAcc = false;
                this.closeAcc()
            }
        },

        async saveRoom () {
            if(this.$refs.dialogFormRoom.validate()){
            let tmp = this.editedRoom.name;
            let double = this.editRoomsTable.filter(function(select_item) {
                return select_item.name === tmp;
            })
            if (double.length > 0 && this.nameChange) {
                this.$store.dispatch('errorMessage', 'Name ' + tmp + ' already in use');
                this.nameChange = false;
            } else {
                this.nameChange = false;
                this.editedRoom.accommodation_id = this.editedAcc.id;
                this.editedRoom.comments = this.editedRoom.commentsRoom;
                this.editedRoom.obsolete = this.editedRoom.obsoleteRoom;
                if (this.editedRoom.single_beds === '' || this.editedRoom.single_beds === null) {
                    this.editedRoom.single_beds = 0;
                }
                if (this.editedRoom.double_beds === '' || this.editedRoom.double_beds === null) {
                    this.editedRoom.double_beds = 0;
                }
                if (this.editedRoom.bunk_places === '' || this.editedRoom.bunk_places === null) {
                    this.editedRoom.bunk_places = 0;
                }
                await this.$store.dispatch('insertRoom', this.editedRoom);
                await this.getData();
                this.getRoomsAcc(this.editedAcc.id);
                console.log('updated')
                this.dialogRoom = false;
                this.closeRoom()
            }
            }
        },

        async saveCalcPaymentsAccommodation (acc, month) {
            this.$store.dispatch('setLoadingApp', 1);
            // console.log('saveCalcPaymentsAccommodation')
            let payload = {
                type: acc,
                month: month,
            };
            await this.$store.dispatch('monthCalcPaymentsAccommodation', payload);
            // console.log('saveCalcPaymentsAccommodation 2')
            await this.calcPaymentsMonth();
            // console.log('saveCalcPaymentsAccommodation 3')
            this.$store.dispatch('setLoadingApp', 0);
        },

        calcNights(startDate, endDate) {
            let nights = dayjs(endDate).diff(dayjs(startDate), 'd');
            return nights.toFixed(0);
        },

        addPayment(family) {
            this.$store.dispatch('setLoadingApp', 1)
            this.$router.push({ name: 'Financial', params: { paymentFamily: family, paymentProfe: null, appId: null, transId: null } })
        },

        async goPaymentAccommodationForm (family) {
            family.startOfNextMonth = this.startOfNextMonth;
            family.startOfMonth = this.startOfMonth;
            family.emailFamily = family.emailContact;
            await this.$store.dispatch('saveDataForReport', family);
            this.$router.push({ name: 'PaymentAccommodationForm'});
        },

        diffDates(startDate, endDate) {
            let days = dayjs(endDate).diff(dayjs(startDate), 'd');
            return days.toFixed(0);
        },

        openTransactionOutgoings(transIdOutgoings) {
            this.$store.dispatch('setLoadingApp', 1)
            this.$router.push({ name: 'Financial', params: { transIdOutgoings: transIdOutgoings } })
        },
            
        checkResourceDates() {
            if(this.resource.info_to_provider === null) {
                this.resource.info_to_provider = this.resource.info_to_client;
            }
            if(this.resource.reserved === null) {
                this.resource.reserved = this.resource.info_to_client;
            }
        },

        selectResourceReservation() {
            if (this.selectedReservation.length === 0) {
                this.filterResources();
                this.selectedReservationSingleSelect = true;
                return;
            } else {
                let contact_full_name = this.selectedReservation[0].contact_full_name;
                this.selectedReservationSingleSelect = false;
                this.resourcesScope = this.resourcesScope.filter(function(select_item) {
                    return select_item.contact_full_name === contact_full_name;
                });
            }
        },

        async goFichaDeAlojamiento (resources) {
            let load = {};
            let payload = [];
            this.$store.dispatch('progressAdvance', 0);
            this.$store.dispatch('showProgressBar', true);
            let progress = 1;
            let step = (100 - progress)/(
                resources.length
            );
            for (let i = 0; i < resources.length; i++) {
                await this.$store.dispatch('getClient', resources[i].client_id);
                if (!resources[i].info_to_provider) {
                    let n = new Date();
                    resources[i].info_to_provider = this.formattedDate(n,'date');
                    resources[i].reserved = null;
                    resources[i].info_to_client = null;
                    this.resource = resources[i];
                    this.insertUpdateResource();
                }
                let client = JSON.parse(JSON.stringify(this.$store.getters.client));
                load = {
                    client: client,
                    resource: resources[i],
                }
                payload.push(load);
                progress = progress + step;
                this.$store.dispatch('progressAdvance', progress);
            }
            await this.$store.dispatch('saveDataForReport', payload);
            this.$store.dispatch('showProgressBar', false);
            this.$store.dispatch('progressAdvance', 100);
            this.$router.push({ name: 'FichaDeAlojamiento'});
        },

        async confirmReservations (resources) {
            this.$store.dispatch('progressAdvance', 0);
            this.$store.dispatch('showProgressBar', true);
            let progress = 1;
            let step = (100 - progress)/(
                resources.length
            );
            for (let i = 0; i < resources.length; i++) {
                await this.$store.dispatch('getClient', resources[i].client_id);
                if (!resources[i].reserved) {
                    let n = new Date();
                    if (!resources[i].info_to_provider) {
                        resources[i].info_to_provider = this.formattedDate(n,'date');
                    }
                    resources[i].reserved = this.formattedDate(n,'date');
                    this.resource = resources[i];
                    this.insertUpdateResource();
                }
                progress = progress + step;
                this.$store.dispatch('progressAdvance', progress);
            }
            this.$store.dispatch('showProgressBar', false);
            this.$store.dispatch('progressAdvance', 100);
        },

        async deleteAccPrice (item) {
            // console.log('deleteAccPrice', item, this.editedAcc.prices);
            let pos = 0;
            pos = this.editedAcc.prices.map(function(e) { return e.id; }).indexOf(item.id);
            if (pos > -1) {
                this.editedAcc.prices.splice(pos, 1);
            }
            // console.log('deleteAccPrice 2', this.editedAcc.prices);
            this.rendererDialogAccTable = Math.random();
        },

        openDialogAccPrices () {
            this.editedAccPrices.date_price = this.nowDate;
            this.editedAccPrices.price_private = 0;
            this.editedAccPrices.price_shared = 0;
            this.editedAccPrices.price_bunk = 0;
            this.dialogAccPrices = true;
        },

        editAccPrice (item) {
            this.editedAccPrices = item;
            this.dialogAccPrices = true;
        },

        saveAccPrice (item) {
            // console.log('saveAccPrice', item, this.editedAccPrices, this.editedAcc.prices);
            let pos = 0;
            pos = this.editedAcc.prices.map(function(e) { return e.id; }).indexOf(item.id);
            if (pos === -1) {
                item.accommodation_id = this.editedAcc.id;
                this.editedAcc.prices.push(item);
            }
            this.dialogAccPrices = false;
            this.rendererDialogAccTable = Math.random();
            // console.log('saveAccPrice 2', this.editedAcc.prices);
        },

        allowedMonths(val) {
            // console.log('allowedMonths', val > this.addDays(this.nowDate, -15))
            return val > this.addDays(this.nowDate, -45);
        },

        sameArrays(arrayOne, arrayTwo) {
            let arrayOneJSON = JSON.stringify(arrayOne);
            let arrayTwoJSON = JSON.stringify(arrayTwo);
            let result = (arrayOneJSON === arrayTwoJSON);
            return result;
        },

        // getUploadInfoType (type) {
        //     if (this.uploadInfos) {
        //         return this.uploadInfos.filter(function(select_item) {
        //             return select_item.type === type;
        //         })
        //     }
        // },

    },
}

</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
    /* .vuecal__cell-split .split-label {color: rgba(0, 0, 0, 0.1);font-size: 26px;} */
    .vuecal__event.Private_Room {background-color: rgba(253, 156, 66, 0.9);color: #fff;}
    .vuecal__event.Private_Double {background-color: rgba(214, 88, 98, 0.9);color: #fff;}
    .vuecal__event.Private_Twin {background-color: rgba(164, 230, 210, 0.9);}
    .vuecal__event.Shared_Room {background-color: rgba(225, 255, 178, 0.5);}
    .vuecal__event.Shared_Bunk {background-color: rgba(164, 230, 210, 0.9);}

    button.vuecal__arrow {display: none;}
    .all_stays:not(:first-of-type) .vuecal__header {display: none;}
    .vuecal__event {height: 60px; border: 0px !important;}
</style>