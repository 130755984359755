<template>
  <v-card 
    flat 
    class="packageMaker" 
    :disabled="$store.getters.loadingApp > 0 || $store.getters.progressBar"
  >
    <v-card-title>
      Package Maker
    </v-card-title>
    <v-data-table
      :headers="headersPM"
      :items="messagesPM"
      class="elevation-1"
      disable-pagination
      hide-default-footer
      item-key="appId"
      sort-by="id"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>New leads PM</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            @click="checkNow()"
          >Check Now</v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.id`]="{ item }">
          {{ formattedDate(item.id, 'dateTimeShort') }}
      </template>

      <template v-slot:[`item.gender`]="{ item }">
        <v-icon small v-if="`${item.gender}` === 'Male'" color="ppc_darkgreen">fa-male</v-icon>
        <v-icon small v-else-if="`${item.gender}` === 'Female'" color="ppc_darkgreen">fa-female</v-icon>
        <v-icon small v-else color="ppc_darkgreen">fa-genderless</v-icon>
      </template>

      <template v-slot:[`item.full_name`]="{ item }">
          <v-btn :disabled="item.status === 'new lead'" small rounded @click="goClient(item.goClientId)">{{ item.full_name }}</v-btn>
      </template>

      <template v-slot:[`item.startDate`]="{ item }">
        {{ getStartDate(item.email) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="resolvePM(item)"
          >
              mdi-sync
          </v-icon>
      </template>

    </v-data-table>

    <v-row justify="center">
      <v-dialog
        v-model="confirmDeleteItemDialog"
        max-width="300px"
      >
        <v-card>
          <v-card-title>
            Are you sure you want to remove this {{ showItemToBeDeleted }}?
          </v-card-title>
          <v-card-text>It will be removed from the Package Maker!</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="confirmDeleteItemDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="deleteItemPM"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="confirmNewLeadDialog"
        max-width="550px"
      >
        <v-card>
          <v-card-title>
            New Lead
          </v-card-title>
          <v-card-text>This will import the lead from the PM into the Administration, remove the lead from the PM or merge the lead with an existing client in the Administration.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="confirmNewLeadDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="confirmNewLeadDialog = false, newData.idPM = newData.id, confirmDeleteItemDialog = true"
            >
              Remove
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="confirmNewLeadDialog = false, importNewLead(newData)"
            >
              Import
            </v-btn>
            <v-select
              style="width: 300px"
              v-model="newData.clientToMerge"
              :items="selectClients"
              item-text="full_name"
              item-value="id"
              :menu-props="{ auto: true }"
              label="merge with..."
              single-line
              prepend-icon="$vuetify.icons.icon_mdi_account ppc_darkgreen--text"
              validate-on-blur
              @change="changedInputSearchMerge($event)"
              class="mr-4"
              dense
              v-if="newData"
            ></v-select>
            <v-btn
              color="green darken-1"
              text
              @click="confirmNewLeadDialog = false, openProfileDifferencesBeforeMerge(newData)"
              :disabled="mergeButtonDisabled"
            >
              Merge
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="confirmNewAppDialog"
        max-width="400px"
      >
        <v-card>
          <div v-if="newData">
            <v-card-title v-if="newData.amountApps">
              {{ newData.amountApps }} New Application(s)
            </v-card-title>
          </div>
          <v-card-text>This will import all newly added applications for this lead/client from the PM into the Administration or remove them from the PM</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="confirmNewAppDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="confirmNewAppDialog = false, deleteItemPM()"
            >
              Remove
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="confirmNewAppDialog = false, importNewApp(newData)"
            >
              Import
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="confirmUploadFileDialog"
        max-width="300px"
      >
        <v-card>
          <div v-if="newData">
            <v-card-title>
              New {{ newData.file }}
            </v-card-title>
            <div v-if="newData.newFile">
              <v-card-text>This will import the {{ newData.file }} from the PM into the Administration or remove the CV from the PM</v-card-text>
            </div>
            <div v-else>
              <v-card-text>This will replace the {{ newData.file }} from the PM into the Administration or remove the CV from the PM</v-card-text>
            </div>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="confirmUploadFileDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="confirmUploadFileDialog = false, deleteItemPM()"
            >
              Remove
            </v-btn>
            <div v-if="newData">
              <v-btn
                v-if="newData.newFile"
                color="green darken-1"
                text
                @click="confirmUploadFileDialog = false, importFileToCusco(newData)"
              >
                Import
              </v-btn>
              <v-btn
                v-if="!newData.newFile"
                color="green darken-1"
                text
                @click="confirmUploadFileDialog = false, importFileToCusco(newData)"
              >
                Replace
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="showNewProfileDialog"
        min-width="400px"
        max-width="700px"
      >
        <v-card>
          <v-card-title v-if="newData">
             {{ newData.status }}
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headersDifferences"
              :items="compareTable"
              class="elevation-1"
              disable-pagination
              hide-default-footer
            ></v-data-table>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="showNewProfileDialog = false, compareTable = [], newData = null"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="showNewProfileDialog = false, confirmDeleteItemDialog = true"
              v-if="newData && newData.status !== 'merging profile'"
            >
              Decline & Remove
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              v-if="newData && newData.status !== 'changed profile' && newData && newData.status !== 'merging profile'"
              @click="confirmNewLeadDialog = false, newData.last_name = newData.last_name.substring(0,43) + ' *',newData.id = newData.idPM, newData.email_cc = null, importNewLead(newData)"
            >
              Import as New Lead
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="newData.idPM = newData.id, changeNewProfile(newData)"
              v-if="newData"
            >
              <span v-if="newData.status === 'changed profile'">Apply Changes</span>
              <span v-else>Merge Clients</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </v-card>
</template>

<script>
import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import createAppTitle from '../mixins/createAppTitle';
import getNationalityFromId from '../mixins/getNationalityFromId';
import getSourceFromId from '../mixins/getSourceFromId';
import compare from '../mixins/compare';
import dayjs from 'dayjs';
export default {
  data() {
    return {
      loading: false,
      headersPM: [
        { text: 'Date', value: 'id' },
        { text: 'Full Name', align: 'start', value: 'full_name' },
        { text: 'Email', value: 'email' },
        { text: 'Gender', value: 'gender', width: "100px", sortable: false},
        { text: 'Nationality', value: 'nationality' },
        { text: 'Source', value: 'source' },
        { text: 'isLead', value: 'isLead' },
        { text: 'Status', value: 'status' },
        { text: 'Resolve', value: 'actions', sortable: false },
      ],
      headersDifferences: [
        { text: 'Field', align: 'start', value: 'field' },
        { text: 'Current Value Admin', value: 'old' },
        { text: 'New Value Admin', value: 'new' },
      ],
      newData: null,
      confirmDeleteItemDialog: false,
      showNewProfileDialog: false,
      confirmNewAppDialog: false,
      confirmNewLeadDialog: false,
      confirmUploadFileDialog: false,
      updatedServices: [],
      compareTable: [],
      selectClients: [],
      mergeButtonDisabled: true,
    }
  },

  mixins: [inputRules, formattedDate, compare, createAppTitle, getNationalityFromId, getSourceFromId],
  
  computed: {
    messagesPM () {
      return this.$store.getters.messagesPM
    },
    showItemToBeDeleted () {
      if (this.newData) {
        return this.newData.status
      } else {
        return ''
      }
    },
  },

  async created () {
    dayjs.locale("en");
    // this.$store.dispatch('showProgressBar', true);
    this.initialize();
  },

  methods: {
    async initialize() {
      // await this.$store.dispatch('checkWithPM');
      this.$store.dispatch('breadcrumbs', ['PackageMaker']);
      this.selectClients = await this.$store.dispatch('getClientsIdName');
      this.selectClients.sort(this.dynamicSort('full_name'));
    },
    getStartDate(email) {
      if (this.$store.state.viewServices) {
        let tmp = this.$store.state.viewServices.filter((select_item) => {
            return select_item.email === email && select_item.start !== null;
          }
        );
        if (tmp.length > 0) {
          let minDate =new Date(Math.max.apply(null, tmp.map(function(e) {
            return new Date(e.start);
          })));
          return this.formattedDate(minDate);
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    async resolvePM(item) {
      this.$store.dispatch('setLoadingApp', 1);
      console.log('resolvePM #1', item.status);
      // new lead
      // new cv
      // new passport
      // changed profile
      // new app
      // no email
      // same email
      // name exist:
      
      let status = item.status.substring(0, 7);
      console.log('resolvePM #2', status);
      let currentData = null;
      switch(status) {
        case 'new lea': {
          this.newData = item;
          this.newData.clientToMerge = null;
          console.log('resolvePM new lead #3', this.newData);
          this.confirmNewLeadDialog = true;
          break;
        }
        case 'new cv:': {
          this.newData = item;
          this.newData.file = 'CV';
          console.log('resolvePM CV #4', this.newData);
          // check if cv exists
          await this.$store.dispatch('getClient', this.newData.id);
          let client = this.$store.state.client;
          if (client.statusCV === 'notUploaded') {
            this.newData.newFile = true;
          } else {
            this.newData.newFile = false;
          }
          this.confirmUploadFileDialog = true;
          break;
        }
        case 'new pas': {
          this.newData = item;
          this.newData.file = 'passport';
          console.log('resolvePM passport #5a', this.newData);
          // check if pasport exists
          await this.$store.dispatch('getClient', this.newData.id);
          let client = this.$store.state.client;
          if (client.statusPassport === 'notUploaded') {
            this.newData.newFile = true;
          } else {
            this.newData.newFile = false;
          }
          this.confirmUploadFileDialog = true;
          break;
        }
        case 'merging':
        case 'same em':
        case 'name ex':
        case 'changed': {
          (status === 'name ex' || status === 'merging') ? currentData = await this.$store.dispatch('searchClient', item.clientToMerge) : currentData = await this.$store.dispatch('searchClient', item.email);
          console.log('resolvePM changed profile #6', this.newData);
          // show differences
          // get client with item.id
          if (!item.email) {
            console.log('importPM changed profile #7', 'no email', item);
            this.$store.dispatch('setLoadingApp', 0);
            return;
          }
          if (currentData.length === 0) {
            console.log('importPM changed profile #8', 'no old data', item.email);
            this.$store.dispatch('setLoadingApp', 0);
            return;
          } else {
            // compare currentData with item
            currentData = currentData[0];
            this.newData = JSON.parse(JSON.stringify(currentData));
            this.compareTable = [];
            let columns = Object.keys(item);
            let columnsDisplay = [];
            this.newData.status = item.status;
            (currentData.pickup_date) ? this.newData.pickup_date = this.formattedDate(currentData.pickup_date, 'date') : null;
            (currentData.handbookSent) ? this.newData.handbookSent = this.formattedDate(currentData.handbookSent, 'date') : null;
            (currentData.first_schedule_sent) ? this.newData.first_schedule_sent = this.formattedDate(currentData.first_schedule_sent, 'date') : null;
            (currentData.spanishTestDone) ? this.newData.spanishTestDone = this.formattedDate(currentData.spanishTestDone, 'date') : null;
            (currentData.thank_you_email) ? this.newData.thank_you_email = this.formattedDate(currentData.thank_you_email, 'date') : null;
            (currentData.welcome_email) ? this.newData.welcome_email = this.formattedDate(currentData.welcome_email, 'date') : null;
            console.log('importPM changed profile #9', item, currentData);
            for (let i = 0; i < columns.length; i++) {
              switch(columns[i]) {
                case 'id':
                  columnsDisplay[i] = 'ID';
                  this.newData.idPM = item.id;
                  console.log('resolvePM changed profile #9aa', this.newData.idPM, item.id)
                  this.newData.id = item.clientToMerge;
                  console.log('resolvePM changed profile #9ab', this.newData.idPM, item.id)
                  break;
                case 'source_id':
                  columnsDisplay[i] = 'Source';
                  // search Source
                  currentData['source'] = this.getSourceFromId(currentData[columns[i]]);
                  this.newData['source'] = this.getSourceFromId(item[columns[i]]);
                  this.newData['source_id'] = item[columns[i]];
                  columns[i] = 'source';
                  break;
                case 'first_name':
                  columnsDisplay[i] = 'First Name';
                  this.newData.first_name = item.first_name;
                  break;
                case 'last_name':
                  columnsDisplay[i] = 'Last Name';
                  this.newData.last_name = item.last_name;
                  break;
                case 'email':
                  columnsDisplay[i] = 'Email';
                  this.newData.email = item.email;
                  break;
                case 'phone':
                  columnsDisplay[i] = 'Phone';
                  this.newData.phone = item.phone;
                  break;
                case 'gender':
                  columnsDisplay[i] = 'Gender';
                  this.newData.gender = item.gender;
                  break;
                case 'nationality_id':
                  columnsDisplay[i] = 'Nationality';
                  // search Nationality
                  currentData['nationality'] = this.getNationalityFromId(currentData[columns[i]]);
                  this.newData['nationality'] = this.getNationalityFromId(item[columns[i]]);
                  this.newData['nationality_id'] = item[columns[i]];
                  columns[i] = 'nationality';
                  break;
                case 'food_preference':
                  columnsDisplay[i] = 'Food Preference';
                  this.newData.food_preference = item.food_preference;
                  break;
                case 'pets_preference':
                  columnsDisplay[i] = 'Pets Preference';
                  this.newData.pets_preference = item.pets_preference;
                  break;
                case 'birthdate':
                  columnsDisplay[i] = 'Birthdate';
                  item[columns[i]] = this.formattedDate(item[columns[i]], 'date');
                  currentData[columns[i]] = this.formattedDate(currentData[columns[i]], 'date');
                  this.newData.birthdate = item.birthdate;
                  break;
                case 'ice_full_name':
                  columnsDisplay[i] = 'Emergency Full Name';
                  this.newData.ice_full_name = item.ice_full_name;
                  break;
                case 'ice_phone':
                  columnsDisplay[i] = 'Emergency Phone';
                  this.newData.ice_phone = item.ice_phone;
                  break;
                case 'ice_email':
                  columnsDisplay[i] = 'Emergency Email';
                  this.newData.ice_email = item.ice_email;
                  break;
                case 'ice_phone_alt':
                  columnsDisplay[i] = 'Emergency Phone Alt';
                  this.newData.ice_phone_alt = item.ice_phone_alt;
                  break;
                case 'comments':
                  columnsDisplay[i] = 'Comments';
                  this.newData.comments = item.comments;
                  break;
                case 'expectations':
                  columnsDisplay[i] = 'Expectations';
                  this.newData.expectations = item.expectations;
                  break;
                case 'experience':
                  columnsDisplay[i] = 'Experience';
                  this.newData.experience = item.experience;
                  break;
                default:
                  continue;
              }
              if (this.newData[columns[i]] !== currentData[columns[i]] || !this.newData[columns[i]] || this.newData[columns[i]] === null && columns[i] !== 'comments' ) {
                console.log('importPM changed profile #9b', 'field:', columnsDisplay[i], 'old:', currentData[columns[i]], 'new:', this.newData[columns[i]]);
                if (currentData[columns[i]] && !this.newData[columns[i]]) {
                  this.newData[columns[i]] = currentData[columns[i]];
                } 
                  this.compareTable.push({field: columnsDisplay[i], old: currentData[columns[i]], new: this.newData[columns[i]]});
                  if (columnsDisplay[i] === 'Email') {
                    if (currentData['email']) {
                      this.newData.email_cc = currentData['email'];
                      this.compareTable.push({field: 'Email alt', old: currentData['Email_cc'], new: this.newData['email']});
                    }
                  }
              }
            }
            this.showNewProfileDialog = true;
            console.log('importPM #11', this.compareTable, this.newData);
            break;
          }
        }
        case 'new app': {
          this.newData = item;
          console.log('resolvePM new app! #11', item);
          let resultApp = await this.$store.dispatch('getAppsNewLeadPM', item.email);
          if (resultApp.length === 0) {
            console.log('resolvePM new app! #12', 'no apps', item.email);
            this.$store.dispatch('setLoadingApp', 0);
            return;
          }
          this.newData.amountApps = resultApp.length;
          console.log('resolvePM new app! #13', resultApp, this.newData.amountApps);
          this.confirmNewAppDialog = true;
          break;
        }
        // case 'same em': {
        //   this.newData = item;
        //   console.log('resolvePM same email #13', item);
        //   break;
        // }
      }
      this.$store.dispatch('setLoadingApp', 0);
    },

    async deleteItemPM() {
      console.log('deleteItemPM', this.newData.idPM);
      this.confirmDeleteItemDialog = false;
      let status = this.newData.status.substring(0, 7);
      switch(status) {
        case 'name ex':
        case 'new lea':
        case 'no emai':
        case 'same em':
          await this.$store.dispatch('deleteLeadPM', this.newData.idPM)
          break;
        case 'new app': {
          this.$store.dispatch('setLoadingApp', 1);
          let resultApp = await this.$store.dispatch('getAppsNewLeadPM', this.newData.email);
          if (resultApp.length > 0) {
            for (let i = 0; i < resultApp.length; i++) {
              await this.$store.dispatch('deleteApplicationPM', resultApp[i].id);
            }
          }
          break;
        }
        case 'changed': {
          this.$store.dispatch('setLoadingApp', 1);
          let correctData = await this.$store.dispatch('searchClient', this.newData.email);
          if (correctData.length === 0) {
            console.log('deleteItemPM', 'no correct data', this.newData.email);
            return;
          }
          correctData = correctData[0];
          console.log('deleteItemPM', status, this.newData.email, correctData);
          correctData.leadChanged = 0;
          let response = await this.$store.dispatch('insertLeadPM', correctData);
          console.log('deleteItemPM #2', response);
          if (response === 200) {
            this.$store.dispatch('successMessage', 'Profile succesfully changes declined');
          } else {
            this.$store.dispatch('errorMessage', 'Something went wrong');
          }
          break;
        }
        default:
          await this.$store.dispatch('deleteUploadPM', this.newData.uploadId)
      }
      this.$store.dispatch('setLoadingApp', 0);
      this.$store.dispatch('showProgressBar', true);
      await this.$store.dispatch('checkWithPM');
    },

    async changeNewProfile(item) {
      let status = this.newData.status.substring(0, 7);
      console.log('changeNewProfile', status, item);
      // change new profile
      this.$store.dispatch('setLoadingApp', 1);
      let payload = [];
      if (status === 'name ex') {
        console.log('changeNewProfile #1');
        item.email_cc = this.newData.email_cc;
      }
      if (status === 'same em' || status === 'name ex') {
        console.log('changeNewProfile #2a', this.newData.idPM);
        await this.$store.dispatch('deleteLeadPM', this.newData.idPM);
        this.newData.idPM = this.newData.id;
        payload = {
          id: this.newData.idPM,
          email: item.email,
        }
        console.log('changeNewProfile #2b', payload);
        await this.$store.dispatch('updateIdLeadPM_vps', payload);
      }
      if (item.birthdate) {
        item.birthdate = dayjs(item.birthdate).format("YYYY-MM-DD");
      } else {
        item.birthdate = null;
      }
      console.log('changeNewProfile #3', item);
      await this.$store.dispatch('clientUpdate', item);
      await this.syncAppsPM(item);
      item.leadChanged = 0;
      console.log('changeNewProfile #4', item);
      let response = await this.$store.dispatch('insertLeadPM', item);
      if (response === 200) {
        this.$store.dispatch('successMessage', 'Profile succesfully changed');
      } else {
        this.$store.dispatch('errorMessage', 'Something went wrong');
      }
      console.log('changeNewProfile #5', item);
      this.showNewProfileDialog = false
      this.$router.push({ name: 'Client', params: { id: item.id } });
      await this.$store.dispatch('checkWithPM');
      console.log('changeNewProfile #6', item);
    },

    async syncAppsPM (item)  {
      console.log('syncAppsPM', item);
      // import applications
      let resultApp = await this.$store.dispatch('getAppsNewLeadPM', item.email);
      for (let i = 0; i < resultApp.length; i++) {
        resultApp[i].autoAppTitle = 1;
        resultApp[i].appNew = 0;
        resultApp[i].frozen = 0;
        resultApp[i].syncPM = 1;
        resultApp[i].exchangeRate = this.$store.state.exchangeRate;
        this.$store.dispatch('insertApplicationPM', resultApp[i]);
        // import services
        let resultServices = await this.$store.dispatch('getServicesAppIdPM', resultApp[i].id);
        let servicesApp = JSON.parse(JSON.stringify(resultServices));
        for (let j = 0; j < servicesApp.length; j++) {
          servicesApp[j].full_name = item.full_name;
        }
        resultApp[i].appTitle = await this.createAppTitle(resultApp[i], servicesApp);
        resultApp[i].payerClient = item.id;
        if(resultApp[i].fixedPrice === null) {
          resultApp[i].fixedPrice = 0;
        }
        if(resultApp[i].discountPercentage === null) {
          resultApp[i].discountPercentage = 0;
        }
        if(resultApp[i].discount === null) {
          resultApp[i].discount = 0;
        }
        if(resultApp[i].refund === null) {
          resultApp[i].refund = 0;
        }
        if(resultApp[i].transferFeeIncluded === null) {
          resultApp[i].transferFeeIncluded = 0;
        }
        console.log(resultApp[i]);
        await this.$store.dispatch('insertApplication', resultApp[i]);
        for (let j = 0; j < servicesApp.length; j++) {
          servicesApp[j].client_id = item.id;
          (servicesApp[j].start) ? servicesApp[j].start = this.formattedDate(servicesApp[j].start, 'date') : servicesApp[j].start = null;
          (servicesApp[j].end) ? servicesApp[j].end = this.formattedDate(servicesApp[j].end, 'date') : servicesApp[j].end = null;
          await this.$store.dispatch('addService', servicesApp[j]);
          // import radio groups and radio options
          let radioGroups = await this.$store.dispatch('getRadioGroupsPM', servicesApp[j].id)
          let k;
          for (k = 0; k < radioGroups.length; k++) {
            await this.$store.dispatch('addRadioGroup', radioGroups[k])
          }
          let radioGroupOptions = await this.$store.dispatch('getRadioOptionsPM', servicesApp[j].id)
          for (let m = 0; m < radioGroupOptions.length; m++) {
            await this.$store.dispatch('addRadioOption', radioGroupOptions[m]);
          }
          //import options
          let options = await this.$store.dispatch('getOptionsPM', servicesApp[j].id)
          console.log(options)
          let l;
          for (l = 0; l < options.length; l++) {
            await this.$store.dispatch('addOption', options[l])
          }
          // if preset add resources
          let resourcesPresets = await this.$store.dispatch('getResourcesPresets', servicesApp[j].service_number);
          for (let index = 0; index < resourcesPresets.length; index++) {
            resourcesPresets[index].id = null;
            resourcesPresets[index].service_id = servicesApp[j].id;
            await this.$store.dispatch('insertResource', resourcesPresets[index]);
          }
        }
      }
    },

    async checkNow() {
      this.$store.dispatch('showProgressBar', true);
      await this.$store.dispatch('checkWithPM');
    },

    goClient(id) {
      console.log('goClient', id);
      this.$store.dispatch('setLoadingApp', 1);
      this.$router.push({ name: 'Client', params: { id: id } })
    },

    async importFileToCusco(newData) {
      this.$store.dispatch('setLoadingApp', 1);
      console.log('importFileToCusco', newData);
      await this.$store.dispatch('importFileToCusco', newData.uploadId);
      this.$store.dispatch('setLoadingApp', 0);
      this.$router.push({ name: 'Client', params: { id: newData.id } });
      this.$store.dispatch('checkWithPM');
    },

    async importNewLead(newData) {
      console.log('importNewLead', newData);
      this.$store.dispatch('setLoadingApp', 1);
      newData = JSON.parse(JSON.stringify(newData));
      if (newData.gender === null) {
        newData.gender = 'X';
      }
      if (newData.food_preference === null) {
        newData.food_preference = 'Not known'
      }
      if (newData.pets_preference === null) {
        newData.pets_preference = 'Not known'
      }
      let uid = this.$store.state.uid;
      newData.salesContact = this.$store.state.users.filter((select_item) => {
        return select_item.id === uid;
      })[0].contact_id;
      console.log('importNewLead #3', this.$store.state.itemsSources);
      if (newData.source_id) {
        let stupidthing1 = newData.source_id;
        let sourceId = this.$store.state.itemsSources.filter((select_item) => {
          return select_item.id === stupidthing1;
        });
        console.log('importNewLead #4', stupidthing1, sourceId);
        newData.source_id = sourceId[0].id;
      } else {
        newData.source_id = 1;
      }
      newData.statusFixed = 0;
      newData.leadChanged = 0;
      newData.ignore_security_deposit = 0;
      newData.ignore_welcome_email = 0;
      newData.ignore_thank_you_email = 0;
      await this.$store.dispatch('addLead', newData);
      await this.$store.dispatch('transferAllUploadsToCusco', newData);
      await this.syncAppsPM(newData);
      newData.leadChanged = 0;
      await this.$store.dispatch('insertLeadPM', newData);
      this.showNewProfileDialog = false;
      this.$router.push({ name: 'Client', params: { id: newData.id } });
      await this.$store.dispatch('checkWithPM');
    },

    async importNewApp(item) {
      this.$store.dispatch('setLoadingApp', 1);
      await this.syncAppsPM(item);
      this.$router.push({ name: 'Client', params: { id: item.id } });
      await this.$store.dispatch('checkWithPM');
    },

    changedInputSearchMerge(event) {
      console.log('changedInputSearchMerge', event, this.newData);
      if (this.newData) {
        this.mergeButtonDisabled = false;
      }
    },

    openProfileDifferencesBeforeMerge() {
      console.log('openProfileDifferencesBeforeMerge', this.newData);
      this.confirmNewLeadDialog = false, this.newData.status = 'merging profile', this.resolvePM(this.newData)
    },
  },
}
</script>