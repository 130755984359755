export default {
    methods: {
        radioGroups_filtered(serviceId) {
            return this.$store.state.radioGroups.filter(function(item) {
                return item.services_id === serviceId;
              }
            )
        },
    }
    
}