<template>
    <v-container style="max-width: 100%">
        <v-tabs
            v-model="activeTabPlacements"
            background-color="transparent"
            color="basil"
            grow
        >
            <v-tab
                v-for="itemTab in itemsTab"
                :key="itemTab"
            >
                <span class="ppc_darkgreen--text">
                    {{ itemTab }}
                </span>
            </v-tab>
        </v-tabs>
    
        <v-tabs-items v-model="activeTabPlacements">
            <v-tab-item>
                <span class="d-flex flex-column">
                    <v-row justify="center">
                        <div class='d-flex flex-row elevation-1 px-2 pt-4 mb-2'>
                            <v-radio-group
                                row
                                v-model="scopeResourcesPlanned"
                                class="mt-4 d-flex flex-column"
                                @change="getResources(), selectedReservation=[]"
                            >
                                <div class="d-flex flex-row flex-wrap">
                                    <div class="d-flex flex-column">
                                        <div class="d-flex justify-center">
                                            <v-radio
                                                label="Ongoing"
                                                value="ongoing"
                                                class="my-0 py-0"
                                            ></v-radio>
                                        </div>
                                        <v-divider class="my-2"></v-divider>
                                        <div class="d-flex flex-row flex-wrap">
                                            <div class="d-flex flex-row flex-wrap">
                                                <v-radio
                                                    label="To be placed"
                                                    value="notPlaced"
                                                    class="my-0 py-0"
                                                ></v-radio>
                                                <v-radio
                                                    label="Placed"
                                                    value="placed"
                                                    class="my-0 py-0"
                                                ></v-radio>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-space-between mx-2">
                                        <v-radio
                                            label="Completed"
                                            value="completed"
                                            class="my-0 py-0"
                                        ></v-radio>
                                    </div>
                                </div>
                            </v-radio-group>
                            <!-- <v-radio-group
                                row
                                v-model="scopeResourcesPlanned"
                                class="mt-0"
                                @change="getResources()"
                            >
                                <v-radio
                                    label="Not placed"
                                    value="notPlaced"
                                    class="my-0 py-0"
                                ></v-radio>
                                <v-radio
                                    label="Placed"
                                    value="placed"
                                    class="my-0 py-0"
                                ></v-radio>
                                <v-radio
                                    label="Completed"
                                    value="completed"
                                    class="my-0 py-0"
                                ></v-radio>
                                <v-radio
                                    label="All"
                                    value="all"
                                    class="my-0 py-0"
                                ></v-radio>
                            </v-radio-group> -->
                        </div>
                    </v-row>
                    <v-data-table
                        :headers="headersResourcesScope"
                        :items="resourcesScope"
                        sort-by="start"
                        class="elevation-1"
                        :items-per-page="-1"
                        disable-pagination
                        hide-default-footer
                        :search="searchResourcesScope"
                        :show-select="scopeResourcesPlanned === 'notPlaced'"
                        :single-select=selectedReservationSingleSelect
                        v-model='selectedReservation'
                        @input="selectResourceReservation"
                    >
                        <!-- <template v-slot:top>
                            <v-toolbar flat color="white">
                                <div class="d-flex flex-row flex-wrap justify-center">
                                    <v-card flat>
                                        <v-text-field
                                            v-model="searchResourcesScope"
                                            append-icon="$vuetify.icons.icon_mdi_magnify"
                                            :label="'Search ' + `${ resourcesScope.length }` + ' project placement(s)'"
                                            single-line
                                            hide-details
                                            style="width: 300px"
                                            clearable
                                        ></v-text-field>
                                    </v-card>
                                </div>
                            </v-toolbar>
                        </template> -->
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <div class="d-flex flex-row flex-wrap justify-center">
                                    <v-card flat>
                                        <v-text-field
                                            v-model="searchResourcesScope"
                                            append-icon="$vuetify.icons.icon_mdi_magnify"
                                            :label="'Search ' + `${ resourcesScope.length }` + ' project placement(s)'"
                                            single-line
                                            hide-details
                                            style="width: 300px"
                                            clearable
                                        ></v-text-field>
                                    </v-card>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    :disabled="selectedReservation.length === 0"
                                    @click="goCartaDePresentacion(selectedReservation)"
                                    v-if="scopeResourcesPlanned === 'notPlaced'"
                                    class = "mx-2"
                                >
                                    Reservation Request
                                </v-btn>
                                <v-btn
                                    :disabled="selectedReservation.length === 0"
                                    @click="confirmReservations(selectedReservation)"
                                    v-if="scopeResourcesPlanned === 'notPlaced'"
                                    class = "mx-2"
                                >
                                    Confirm Reservations
                                </v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.full_name`]="{ item }">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn 
                                        small 
                                        rounded 
                                        v-bind="attrs"
                                        v-on="on" 
                                        @click="goClient(item.client_id, item.id)"
                                    >
                                        {{ item.full_name }}
                                    </v-btn>
                                </template>
                                <span>go to client</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`item.start`]="{ item }">
                            <span v-html="formattedDate(item.start, 'date')"></span>
                        </template>
                        <template v-slot:[`item.end`]="{ item }">
                            <span v-html="formattedDate(item.end, 'date')"></span>
                        </template>
                        <template v-slot:[`item.type_id`]="{ item }">
                            <span v-if="item.type_id === 1">Volunteering</span>
                            <span v-else>Internship</span>
                        </template>
                        <template v-slot:[`item.statusResourceId`]="{ item }">
                            <v-chip :color="getStatusResource(item.statusResourceId).color" text-color="white" v-html="getStatusResource(item.statusResourceId).name"></v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="openDialogEditResource(item.id)"
                                :disabled="item.updating === true"
                            >
                                $vuetify.icons.icon_mdi_pencil
                            </v-icon>
                        </template>
                    </v-data-table>
                </span>

                <v-dialog
                    v-model="dialogEditResource"
                    max-width="800px"
                >
                    <v-card class="d-flex flex-row flex-wrap" color="blue lighten-2" v-if="resource">
                        <v-card flat class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                            <div>
                                <v-card-title>
                                    <span v-if="resource.type_id === 1" class=" white--text">Volunteer Placement #{{ resource.number_type }} - {{ resource.full_name }}</span> 
                                    <span v-if="resource.type_id === 2" class=" white--text">Internship Placement #{{ resource.number_type }} - {{ resource.full_name }}</span>
                                </v-card-title>
                            </div>
                        </v-card>
                        <div class="d-flex flex-column" color="blue lighten-2" style="max-width: 320px">
                            <v-card-text>
                                <v-select
                                    v-model="resource.category_id"
                                    :items="categoriesProjects(resource.type_id)"
                                    item-text="category"
                                    item-value="category_id"
                                    :menu-props="{ auto: true }"
                                    label="Category"
                                    validate-on-blur
                                    clearable
                                    @click:clear="resource.project_id = null, resource.placement_id = null"
                                    @change="resource.project_id = null, resource.placement_id = null, calcDonationProject()"
                                    color="white"
                                    item-color="blue"
                                    :disabled="resource.statusResourceId === 1"
                                >
                                    <template #selection="{ item }">
                                        <span class="white--text">{{item.category}}</span>
                                    </template>
                                </v-select>
                                <v-select
                                    v-model="resource.project_id"
                                    :items="filterProjects(resource.type_id, resource.category_id, resource.project_id)"
                                    item-text="name"
                                    item-value="id"
                                    :menu-props="{ auto: true }"
                                    label="Project"
                                    validate-on-blur
                                    clearable
                                    @click:clear="resource.project_id = null, resource.placement_id = null"
                                    @change="resource.placement_id = null, resource.category_id = filterProjects(resource.type_id, 
                                        resource.category_id, resource.project_id)[0].category_id, 
                                        calcDonationProject()"
                                    color="white"
                                    item-color="blue"
                                    :disabled="resource.statusResourceId === 1"
                                >
                                    <template #selection="{ item }">
                                        <span class="white--text">{{item.name}}</span>
                                    </template>
                                </v-select>
                                <v-select
                                    v-model="resource.placement_id"
                                    :items="filterPlacements(resource.type_id, resource.category_id, resource.project_id)"
                                    item-text="organisation"
                                    item-value="id"
                                    :menu-props="{ auto: true }"
                                    label="Organisation"
                                    validate-on-blur
                                    clearable
                                    @change="resource.category_id = filterPlacements(resource.type_id, resource.category_id, resource.project_id)[0].category_id, 
                                        resource.project_id = filterPlacements(resource.type_id, resource.category_id, resource.project_id)[0].project_id,
                                        calcDonationProject()"
                                    color="white"
                                    item-color="blue"
                                    :disabled = "!resource.project_id || resource.statusResourceId === 1"
                                >
                                    <template #selection="{ item }">
                                        <span class="white--text">{{item.organisation}}</span>
                                    </template>
                                </v-select>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        label="from"
                                        v-bind="attrs"
                                        prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                        :value="formattedDate(resource.start)"
                                        v-on="on"
                                        clearable
                                        readonly
                                        class="text-input-white"
                                        color="white"
                                        :disabled="resource.statusResourceId === 1"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="resource.start"
                                        no-title
                                        scrollable
                                        color="blue"
                                        @change="calcDatesResource('start')"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    label="until"
                                    v-bind="attrs"
                                    prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                    :value="formattedDate(resource.end)"
                                    v-on="on"
                                    clearable
                                    readonly
                                    class="text-input-white"
                                    color="white"
                                    :disabled="resource.statusResourceId === 1"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="resource.end"
                                    no-title
                                    scrollable
                                    @change="calcDatesResource('end')"
                                    color="blue"
                                >
                                </v-date-picker>
                                </v-menu>
                                <v-slider
                                    v-model="resource.duration"
                                    step="1"
                                    ticks
                                    tick-size="4"
                                    thumb-label="always"
                                    :min="1"
                                    :max="52"
                                    hint="Duration in weeks"
                                    persistent-hint
                                    thumb-color="ppc_pink"
                                    track-color="ppc_grey"
                                    color="white"
                                    @change="calcDatesResource('duration')"
                                    class="mt-8"
                                    :disabled="resource.statusResourceId === 1"
                                ></v-slider>
                            </v-card-text>
                            <v-textarea
                                class="rounded-xl ml-2"
                                v-model="resource.comments"
                                auto-grow
                                clearable
                                counter
                                outlined
                                color="white"
                                label="Comments (255 max)"
                                :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                            ></v-textarea>
                        </div>
                        <div class="d-flex flex-column" color="blue lighten-2" style="max-width: 320px">
                            <v-card-text>
                                <span
                                    class="white--text"
                                >
                                    Donation: ${{ resource.donation }}
                                </span>
                                <span
                                    class="white--text"
                                >
                                {{ resource.donation_type }}
                                </span>
                                <v-text-field
                                    label="Donation payment USD"
                                    v-model="resource.payment"
                                    class="text-input-white"
                                    color="white"
                                    @change="changePayment()"
                                    clearable
                                    :disabled="resource.statusResourceId === 1 || resource.amountPaidReceiver === resource.payment"
                                ></v-text-field>
                                <div class="white--text mb-4" v-if="resource.placement_id">
                                    {{ getPlacementComments }}
                                </div>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <span class="d-flex flex-row">
                                            <!-- <v-btn 
                                                fab @click="goCartaDePresentacion(resource)" 
                                                color="blue" elevation="2" 
                                                x-small 
                                                class="mt-4 mr-2"
                                                :disabled="resource.statusResourceId === 1 || !resource.placement_id"
                                            >
                                                <v-icon size="20" color="white">mdi-email</v-icon>
                                            </v-btn> -->
                                            <v-text-field
                                                label="Info to placement"
                                                v-bind="attrs"
                                                prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                                :value="formattedDate(resource.info_to_provider)"
                                                v-on="on"
                                                @click:clear="resource.info_to_provider = null"
                                                clearable
                                                readonly
                                                class="text-input-white"
                                                color="white"
                                                :disabled="resource.statusResourceId === 1"
                                            ></v-text-field>
                                        </span>
                                    </template>
                                    <v-date-picker
                                        v-model="resource.info_to_provider"
                                        no-title
                                        scrollable
                                        color="blue"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            label="Reserved"
                                            v-bind="attrs"
                                            prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                            :value="formattedDate(resource.reserved)"
                                            v-on="on"
                                            @click:clear="resource.reserved = null"
                                            clearable
                                            readonly
                                            class="text-input-white"
                                            color="white"
                                            :disabled="resource.statusResourceId === 1"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="resource.reserved"
                                        no-title
                                        scrollable
                                        color="blue"
                                    >
                                    </v-date-picker>
                                </v-menu>
                                <v-menu
                                    transition="scale-transition"
                                    offset-y
                                    class="my-5"
                                    max-width="100%"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            label="Info to client"
                                            v-bind="attrs"
                                            prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                            :value="formattedDate(resource.info_to_client)"
                                            v-on="on"
                                            @click:clear="resource.info_to_client = null"
                                            clearable
                                            readonly
                                            class="text-input-white"
                                            color="white"
                                            :disabled="resource.statusResourceId === 1"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="resource.info_to_client"
                                        no-title
                                        scrollable
                                        @change="checkResourceDates()"
                                        color="blue"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-card-text>
                        </div>
                        <v-card-actions class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                            <div class="caption blacck--text">
                                service - resource nr: {{ resource.service_id }} - {{ resource.id }}
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn color="blue lighten-3" @click="openTransactionOutgoings(resource.transaction_id)" class="ma-4" v-if="resource.transaction_id">
                                <v-icon color="white">$vuetify.icons.icon_mdi_credit_card</v-icon>
                                <span class="white--text">Open Transaction</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <!-- <v-btn 
                                color="white" 
                                text 
                                @click="dialogEditResource = false"
                                :disabled="$store.getters.loadingApp === 1"
                            >
                                Ok
                            </v-btn> -->
                            <v-btn color="white" text @click="dialogEditResource = false">Cancel</v-btn>
                            <v-btn color="white" text @click="dialogEditResource = false, insertUpdateResource()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-tab-item>
            <v-tab-item>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Projects to be Paid <span v-if="donationsToBePaid">(${{ donationsToBePaid.toFixed(2) }})</span></v-toolbar-title>
                </v-toolbar>
                <v-tabs
                    v-model="activeTabDonations"
                    background-color="transparent"
                    color="basil"
                    grow
                >
                    <v-tab
                        v-for="itemTab in itemsDonationsTab"
                        :key="itemTab"
                    >
                        <span class="ppc_darkgreen--text">
                            {{ itemTab }}
                        </span>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="activeTabDonations">
                    <v-tab-item>
                        <v-data-table
                            :headers="headersResourcesDonations"
                            :items="resourcesToBePaid"
                            sort-by="start"
                            class="elevation-1"
                            group-by="placementName"
                            disable-sort
                            :items-per-page="-1"
                            disable-pagination
                            hide-default-footer
                            v-if="resourcesToBePaid.length > 0"
                        >
                            <template v-slot:[`group.header`]="{items}">
                                <th colspan="7">
                                    <div class="d-flex flex-row ma-4">
                                        <h3>{{ items[0].placementName }} ${{ sumPaymentsProjects(items) }}</h3>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            small
                                            @click="payProject(items)"
                                        >
                                            Pay Project
                                        </v-btn>
                                    </div>
                                </th>
                            </template>
                            <template v-slot:[`item.full_name`]="{ item }">
                                <span class="d-flex flex-row">
                                    <v-btn x-small rounded class='align-self-center mr-2' @click="goClient(item.client_id, item.id)">
                                        <v-icon color="green" small>$vuetify.icons.icon_mdi_pencil</v-icon>
                                    </v-btn><span class="text-wrap">{{ item.full_name }}
                                </span>
                            </span>
                            </template>
                            <template v-slot:[`item.start`]="{ item }">
                                {{ formattedDate(item.start) }}
                            </template>
                            <template v-slot:[`item.end`]="{ item }">
                                {{ formattedDate(item.end) }}
                            </template>
                            <template v-slot:[`item.type_id`]="{ item }">
                                <span v-if="`${item.type_id}` == 1"><v-icon>$vuetify.icons.icon_volunteer</v-icon> #{{ item.number_type }}</span>
                                <span v-if="`${item.type_id}` == 2"><v-icon>$vuetify.icons.icon_internship</v-icon> #{{ item.number_type }}</span>
                            </template>
                            <template v-slot:[`item.statusResourceId`]="{ item }">
                                <v-chip :color="getStatusResource(item.statusResourceId).color" text-color="white" v-html="getStatusResource(item.statusResourceId).name"></v-chip>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    @click="openDialogEditResource(item.id)"
                                >
                                    $vuetify.icons.icon_mdi_pencil
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                        <!-- <v-row justify="center" class="mt-16">
                            <v-btn
                                color="grey"
                                dark
                                small
                                @click="getDonationsGifted()"
                                class="ma-2"
                                :loading="loadingButtonDonationsGifted"
                            >
                                Donations Gifted<v-icon color="white" class="ml-2">mdi-history</v-icon>
                            </v-btn>
                        </v-row> -->
                        <v-data-table
                            :headers="headersDonationsGifted"
                            :items="donationsGiftedIncome"
                            class="elevation-1"
                            :items-per-page="-1"
                            disable-pagination
                            hide-default-footer
                            sort-by="dateTime"
                            dense
                            v-if="donationsGiftedIncome.length > 0"
                        >
                            <template v-slot:top>
                                <v-toolbar
                                    flat
                                >
                                    <v-toolbar-title>Donations Gifted Income</v-toolbar-title>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.dateTime`]="{ item }">
                                {{ formattedDate(item.dateTime) }}
                            </template>
                            <template v-slot:[`item.amountDollars`]="{ item }">
                                <span v-if="item.amountDollars">${{ item.amountDollars.toFixed(2) }}</span>
                            </template>
                        </v-data-table>
                        <v-data-table
                            :headers="headersDonationsGifted"
                            :items="donationsGiftedOutgoings"
                            class="elevation-1"
                            :items-per-page="-1"
                            disable-pagination
                            hide-default-footer
                            sort-by="dateTime"
                            dense
                            v-if="donationsGiftedOutgoings.length > 0"
                        >
                            <template v-slot:top>
                                <v-toolbar
                                    flat
                                >
                                    <v-toolbar-title>Donations Gifted Outgoing</v-toolbar-title>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.dateTime`]="{ item }">
                                {{ formattedDate(item.dateTime) }}
                            </template>
                            <template v-slot:[`item.amountDollars`]="{ item }">
                                <span v-if="item.amountDollars">${{ item.amountDollars.toFixed(2) }}</span>
                            </template>
                        </v-data-table>
                        <v-row justify="center">
                            <h3 v-if="this.totalDonationsGiftedAvailable">Available for Donation: ${{ this.totalDonationsGiftedAvailable.toFixed(2) }}</h3>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex flex-row flex-wrap">
                    <v-spacer></v-spacer>
                    <v-btn color="ppc_darkgreen darken-1" class="white--text my-7 mx-2" @click="$router.push({ name: 'Projects'})">Projects</v-btn>
                </div>
                <v-data-table
                    :headers="headersProjectPlacements"
                    :items="updatedProjectPlacements"
                    class="elevation-1"
                    :items-per-page="-1"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Project Placements</v-toolbar-title>
                            <v-divider
                                class="mx-4"
                                inset
                                vertical
                                ></v-divider>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="ppc_pink"
                                dark
                                small
                                absolute
                                right
                                fab
                                @click="editedItem.obsolete = null, dialogEditProjectPlacement = true"
                            >
                                <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                            </v-btn>
                            <v-dialog
                                v-model="dialogEditProjectPlacement"
                                max-width="500px"
                                persistent
                            >
                                <v-form ref="dialogForm">
                                    <v-card>
                                        <v-card-title class="ppc_darkgreen darken-1">
                                            <span class="headline white--text"> {{ formTitle }} {{ editedItem.project }} - {{ editedItem.organisation }} </span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-select
                                                v-model="editedItem.project_id"
                                                :items="selectProjects"
                                                item-text="name"
                                                item-value="id"
                                                :menu-props="{ auto: true }"
                                                label="Select project"
                                                single-line
                                                prepend-icon="$vuetify.icons.icon_volunteer"
                                                validate-on-blur
                                                clearable
                                                class="mr-2"
                                            >
                                            </v-select>
                                            <v-select
                                                v-model="editedItem.organisation_id"
                                                :items="selectOrganisations"
                                                item-text="organisation"
                                                item-value="id"
                                                :menu-props="{ auto: true }"
                                                label="Select Organisation"
                                                single-line
                                                prepend-icon="$vuetify.icons.icon_mdi_domain"
                                                validate-on-blur
                                                clearable
                                            >
                                            </v-select>
                                            <div class="d-flex flex-row flex-wrap">
                                                <v-text-field
                                                    v-model.trim="editedItem.donation"
                                                    label="donation"
                                                    prepend-icon="mdi-currency-usd ppc_darkgreen--text"
                                                    clearable
                                                    class="mr-4"
                                                    style="width: 50px"
                                                ></v-text-field>
                                                <v-select
                                                    v-model="editedItem.donation_type"
                                                    :items="donation_types"
                                                    item-text="text"
                                                    item-value="value"
                                                    :menu-props="{ auto: true }"
                                                    label="Select type"
                                                    single-line
                                                    validate-on-blur
                                                    clearable
                                                    style="width: 150px"
                                                ></v-select>
                                            </div>
                                            <v-textarea
                                                class="rounded-xl ma-3"
                                                v-model="editedItem.comments"
                                                auto-grow
                                                clearable
                                                counter
                                                outlined
                                                label="Comments (255 max)"
                                                :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                                            ></v-textarea>
                                            <v-checkbox
                                                v-model="editedItem.obsolete"
                                                label="Not active"
                                                @change="setObsolete($event)"
                                            ></v-checkbox>
                                            <v-text-field
                                                :value="formattedDate(editedItem.obsolete)"
                                                disabled
                                                label="From"
                                                v-if="editedItem.obsolete !== null"
                                                class="ml-2"
                                            ></v-text-field>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="ppc_darkgreen darken-1"
                                                text
                                                @click="close"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                color="ppc_darkgreen darken-1"
                                                text
                                                @click="save"
                                            >
                                                Save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-form>
                            </v-dialog>

                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title>Are you sure you want to delete {{ editedItem.name }}?</v-card-title>
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="ppc_darkgreen darken-1" text @click="closeDelete">Cancel</v-btn>
                                    <v-btn color="ppc_darkgreen darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                    <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                        {{ item.project }} - {{ item.organisation }}
                    </template>
                    <template v-slot:[`item.contact`]="{ item }">
                        {{ item.contact }}
                        <v-btn
                            v-if="item.contact" 
                            fab 
                            color="ppc_pink"
                            height="20px"
                            width="20px"
                            @click="openContactInfo(item)"
                            right
                            top
                            class="ml-3"
                        >
                            <v-icon color="white">$vuetify.icons.icon_mdi_information_variant</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:[`item.obsolete`]="{ item }">
                        <v-icon small v-if="item.obsolete !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="editItem(item)"
                        >
                            $vuetify.icons.icon_mdi_pencil
                        </v-icon>
                        <v-icon
                            small
                            @click="deleteItem(item)"
                        >
                            $vuetify.icons.icon_mdi_delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>


        <v-dialog
            v-model="dialogContactInfo"
            max-width="500px"
        >
            <v-card>
                <v-card-title class="ppc_darkgreen darken-1">
                    <span class="headline white--text"> {{ contact.name }} </span>
                </v-card-title>
                <v-card-subtitle class="mt-5" v-if="contact.organisation">
                    <v-icon
                        class="mr-2"
                    >
                        $vuetify.icons.icon_mdi_domain ppc_darkgreen--text
                    </v-icon>
                    <span class="font-weight-bold">{{ contact.organisation }}</span>
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col
                            cols="12"
                            v-if="contact.address || contact.country"
                        >
                            <v-icon
                                class="mr-2"
                            >
                                mdi-map-marker ppc_darkgreen--text
                            </v-icon>
                            {{contact.address}}
                            <span v-if="contact.address && contact.country">,</span>
                            {{contact.country}}
                        </v-col>
                        
                        <v-col
                            cols="12"
                            sm="6"
                            v-if="contact.phone"
                        >
                            <v-icon
                                class="mr-2"
                            >
                                mdi-cellphone ppc_darkgreen--text
                            </v-icon>
                            {{contact.phone}}
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            v-if="contact.phone_alt"
                        >
                            <v-icon
                                class="mr-2"
                            >
                                mdi-phone ppc_darkgreen--text
                            </v-icon>
                            {{contact.phone_alt}}
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            v-if="contact.email"
                        >   
                            <v-icon
                                class="mr-2"
                            >
                                mdi-email ppc_darkgreen--text
                            </v-icon>
                            {{contact.email}}
                        </v-col>
                        <v-col
                            cols="12"
                            v-if="contact.comments"
                        >   
                            <v-icon
                                class="mr-2"
                            >
                                mdi-message-text ppc_darkgreen--text
                            </v-icon>
                            {{ contact.comments }}
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn
                        color="ppc_darkgreen darken-1"
                        text
                        @click="dialogContactInfo = false"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
    </v-container>
</template>

<script>

import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import compare from '../mixins/compare';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';
dayjs.extend(dayjsBusinessDays);

export default {
    data: () => ({
        itemsTab: [
            'Ongoing', 'Donations', 'Projects & Placements'
        ],
        activeTabPlacements: 0,
        itemsDonationsTab: [
            'Debit', 'Gifts'
        ],
        activeTabDonations: 0,
      dialogEditProjectPlacement: false,
      dialogDelete: false,
      dialogContactInfo: false,
      dialogEditResource: false,
      headersProjectPlacements: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Category', value: 'category' },
        { text: 'Type', value: 'type' },
        { text: 'Contact', value: 'contact' },
        { text: 'Donation USD', value: 'donation' },
        { text: 'Type', value: 'donation_type' },
        { text: 'Comments', value: 'comments' },
        { text: 'Obsolete', value: 'obsolete' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      donation_types: [
        { text: 'once' , value: 'once'},
        { text: 'every week' , value: 'every week'},
        { text: 'every month' , value: 'every month'},
      ],
      headersResourcesScope: [
          { text: 'Client', value: 'full_name', groupable: false },
          { text: 'Agency', value: 'agency' },
          { text: 'Project', value: 'projectName' },
          { text: 'Placement', value: 'contact_organisation' },
          { text: 'Service Name', value: 'servicesName', groupable: false },
          { text: 'Type', value: 'type_id', groupable: false },
          { text: 'Start', value: 'start', groupable: false },
          { text: 'End', value: 'end', groupable: false },
          { text: 'Comments', value: 'comments', groupable: false },
          { text: 'Status', value: 'statusResourceId', groupable: false },
          { text: 'Open', value: 'actions', sortable: false, groupable: false },
        ],
      headersResourcesDonations: [
          { text: 'Client', value: 'full_name', groupable: false },
          { text: 'Agency', value: 'agency' },
          { text: 'Service Name', value: 'servicesName', groupable: false },
          { text: 'Type/Nr', value: 'type_id', groupable: false },
          { text: 'Placement', value: 'placementName' },
          { text: 'Start', value: 'start', groupable: false },
          { text: 'End', value: 'end', groupable: false },
          { text: 'Status', value: 'statusResourceId', groupable: false },
          { text: 'Actions', value: 'actions', sortable: false, groupable: false },
      ],
      headersDonationsGifted: [
            {
                text: 'Date',
                align: 'start',
                sortable: false,
                value: 'dateTime',
            },
            { text: 'Donation', value: 'amountDollars' },
            { text: 'Contact', value: 'contactName' },
            { text: 'Methods', value: 'paymentMethodName' },
            { text: 'Comments', value: 'comments' },
        ],
      updatedProjectPlacements: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        contact: '',
        category: '',
        donation: '',
        donation_type: '',
        comments: '',
        obsolete: false,
      },
      defaultItem: {
        name: '',
        contact: '',
        category: '',
        donation: '',
        donation_type: '',
        comments: '',
        obsolete: false,
      },
      contact: [],
      resources: [],
    //   resourcesPlaced: [],
    //   resourcesNotPlaced: [],
      resourcesToBePaid: [],
      resource: [],
      donationsGiftedIncome: [],
      donationsGiftedOutgoings: [],
      totalDonationsGiftedAvailable: 0,
      loadingButtonDonationsGifted: false,
      loadingButtonPaidDonations: false,
      loadingButtonAllProjectPlacements: false,
      selectOrganisations: [],
      scopeResourcesPlanned: 'notPlaced',
      resourcesScope: [],
      searchResourcesScope: '',
      donationsToBePaid: null,
      selectedReservation: [],
      selectedReservationSingleSelect: true,
    }),

    mixins: [inputRules, formattedDate, compare],

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Placement ' : ''
        },
        selectProjects () {
            let projects = JSON.parse(JSON.stringify(this.$store.state.projects.filter(function(select_item) {
                return select_item.obsolete === null;
            })));
            return projects.sort(this.dynamicSort('name'));
        },
        getPlacementComments () {
            if (this.resource.placement_id) {
                let placementId = this.resource.placement_id;
                var placement = this.$store.state.projectPlacements.filter(function(select_item) {
                    return select_item.id === placementId;
                })[0];
                return placement.comments;
            } else {
                return '';
            }
        },
    },

    watch: {
    },

    beforeCreate () {
        if (this.$store.state.accessProjects === 0) {
            this.$router.push({ name: 'Illegal' })
        }
        this.$store.dispatch('setLoadingApp', '1')
    },

    created () {
        dayjs.locale("en");
        this.initialize();
    },

    methods: {
      async initialize () {
        let organisations = [];
        var date = new Date();
        let start = date.getTime();
        console.log('***1');
        await Promise.all([this.getResources(), this.$store.dispatch('getProjectPlacements'), organisations = await this.$store.dispatch('getOrganisationsAll'), this.resourcesToBePaid = await this.$store.dispatch('getResourcesToBePaid'), this.donationsToBePaid = await this.$store.dispatch('getDonationsToBePaid')]);
        if (this.$route.params.resourceId) {
            this.openDialogEditResource(this.$route.params.resourceId);
            this.$store.dispatch('saveDataForReport', );
        } else {
            this.$store.dispatch('setLoadingApp', 'dis');
        }
        this.getDonationsGifted();
        this.resourcesToBePaid.sort(this.dynamicSort('placementName'));
        for (let index = 0; index < organisations.length; index++) {
            organisations[index].organisation = organisations[index].name + ' - ' + organisations[index].contactName;
        }
        this.selectOrganisations = organisations.sort(this.dynamicSort('name'));
        this.updatedProjectPlacements = JSON.parse(JSON.stringify(this.$store.state.projectPlacements));
        let previousView =  this.$store.state.breadcrumbs[this.$store.state.breadcrumbs.length - 1];
        let breadcrumbs =  JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (previousView === 'Projects') {
          this.activeTabPlacements = 2;
          breadcrumbs.slice(-1);
        }
        breadcrumbs = ['ProjectPlacements'];
        this.$store.dispatch('breadcrumbs', breadcrumbs);
        date = new Date();
        let end = date.getTime();
        const totalTime = ((end-start)/1000);
        console.log('***2', totalTime);
      },

      async getResources() {
        this.$store.dispatch('setLoadingApp', 'add');
        if (this.scopeResourcesPlanned === 'completed') {
            this.resources = await this.$store.dispatch('getResourcesCompletedProjects');
        } else {
            this.resources = await this.$store.dispatch('getResourcesAllProjects');
        }
        for (let i = 0; i < this.resources.length; i++) {
            (this.resources[i].start) ? this.resources[i].start = this.formattedDate(this.resources[i].start, 'date') : this.resources[i].start = null;
            (this.resources[i].end) ? this.resources[i].end = this.formattedDate(this.resources[i].end, 'date') : this.resources[i].end = null;
            (this.resources[i].info_to_client) ? this.resources[i].info_to_client = this.formattedDate(this.resources[i].info_to_client, 'date') : this.resources[i].info_to_client = null;
            (this.resources[i].info_to_provider) ? this.resources[i].info_to_provider = this.formattedDate(this.resources[i].info_to_provider, 'date') : this.resources[i].info_to_provider = null;
            (this.resources[i].reserved) ? this.resources[i].reserved = this.formattedDate(this.resources[i].reserved, 'date') : this.resources[i].reserved = null;
            if (this.resources[i].statusResourceId === 9 || this.resources[i].placement_id === null) {
                this.resources[i].isSelectable = false;
            }
        }
        if (this.dialogEditResource) {
            // console.log('initialize test1')
            let itemId = this.resource.id
            let resource = this.resources.filter(function(select_item) {
                return select_item.id === itemId;
            })
            if (resource.length === 0) {
                this.dialogEditResource = false;
                this.resource = [];
            } else {
                this.resource = resource[0]
            }
        }
        this.filterResources();
        this.loadingButtonAllProjectPlacements = false;
        this.$store.dispatch('setLoadingApp', 'dis');
      },

      filterResources() {
        if (this.scopeResourcesPlanned === 'completed') {
            this.resourcesScope = this.resources.filter(function(select_item) {
                return select_item.statusResourceId === 1;
            });
        } else if (this.scopeResourcesPlanned === 'notPlaced') {
            this.resourcesScope = this.resources.filter(function(select_item) {
                return select_item.placement_id === null || select_item.statusResourceId === 6 || select_item.statusResourceId === 5 || select_item.statusResourceId === 7 || select_item.statusResourceId === 9;
            });
        } else if (this.scopeResourcesPlanned === 'placed') {
            this.resourcesScope = this.resources.filter(function(select_item) {
                return select_item.placement_id !== null && select_item.statusResourceId !== 6 && select_item.statusResourceId !== 5 && select_item.statusResourceId !== 7 && select_item.statusResourceId !== 9;
            });
        } else if (this.scopeResourcesPlanned === 'ongoing') {
            this.resourcesScope = this.resources.filter(function(select_item) {
                return select_item.statusResourceId !== 15 && select_item.statusResourceId !== 1;
            });
        } else {
            // scopeResourcesPlanned is 'all'
            this.resourcesScope = this.resources;
        }
      },

      openContactInfo(editedItem) {
        let tmp = editedItem.contact_id;
        this.contact = this.$store.state.contacts.filter(function(select_item) {
            return select_item.id === tmp;
        })[0];
        this.dialogContactInfo = true;
      },

      editItem (item) {
        this.editedIndex = this.updatedProjectPlacements.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogEditProjectPlacement = true;
      },

      deleteItem (item) {
        this.editedIndex = this.updatedProjectPlacements.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },

      async deleteItemConfirm () {
        let response = await await this.$store.dispatch('deleteProjectPlacement', this.editedItem.id)
        if (response.data.affectedRows === 1) {
            this.$store.dispatch('successMessage', 'Project placement deleted');
        } else {
            this.$store.dispatch('errorMessage', 'Something went wrong');
        }
        await this.$store.dispatch('getProjectPlacements');
        this.updatedProjectPlacements = JSON.parse(JSON.stringify(this.$store.state.projectPlacements));
        this.closeDelete()
      },

      close () {
        this.dialogEditProjectPlacement = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async openDialogEditResource(id) {
        this.$store.dispatch('setLoadingApp', 'add');
        console.log('openDialogEditResource 1', id);
        let result = this.resources.filter(function(select_item) {
            return select_item.id === id;
        });
        console.log('openDialogEditResource 2', result);
        if (result.length === 0) {
            result = await this.$store.dispatch('getResourceIdProject', id);
            console.log('openDialogEditResource 3', result);
        } else {
            result = result[0];
        }
        this.resource = Object.assign({}, result);
        console.log('openDialogEditResource 4', this.resource);
        this.dialogEditResource = true;
        this.$store.dispatch('setLoadingApp', 0);
      },

    // mixins?

      getStatusResource(id) {
            if (id) {
                return this.$store.state.statusResources.filter((select_item) => {
                    return select_item.id === id;
                })[0];
            } else {
                return [];
            }
        },
        categoriesProjects (resourceType) {
            let type;
            if (resourceType === 1) {
                type = "Volunteering"
            } else {
                type = "Internship"
            }
            let selection = this.$store.state.projects.filter(function(select_item) {
                return select_item.type === type;
            })
            return selection.sort(this.dynamicSort('category'));
        },
        filterProjects (resourceType, categoryId, projectId) {
            let type;
            var selection;
            if (resourceType === 1) {
                type = "Volunteering"
            } else {
                type = "Internship"
            }
            if (projectId) {
                selection = this.$store.state.projects.filter(function(select_item) {
                    return select_item.id === projectId && select_item.type && select_item.obsolete === null;
                })
            } else {
                if (categoryId) {
                    selection = this.$store.state.projects.filter(function(select_item) {
                        return select_item.category_id === categoryId && select_item.type === type && select_item.obsolete === null;
                    })
                } else {
                    selection = this.$store.state.projects.filter(function(select_item) {
                        return select_item.type === type && select_item.obsolete === null;
                    })
                }
            }
            return selection.sort(this.dynamicSort('name'));
        },
        filterPlacements (resourceType, categoryId, projectId) {
            let type;
            var selection;
            if (resourceType === 1) {
                type = "Volunteering"
            } else {
                type = "Internship"
            }
            if (projectId) {
                selection = this.$store.state.projectPlacements.filter(function(select_item) {
                    return select_item.project_id === projectId && select_item.obsolete === null;
                })
            } else {
                if (categoryId) {
                    selection = this.$store.state.projectPlacements.filter(function(select_item) {
                        return select_item.category_id === categoryId && select_item.type === type && select_item.obsolete === null;
                    })
                } else {
                    selection = this.$store.state.projectPlacements.filter(function(select_item) {
                        return select_item.type === type && select_item.obsolete === null;
                    })
                }
            }
            return selection.sort(this.dynamicSort('name'));
        },
        calcDonationProject () {
            if (this.resource.placement_id) {
                let placementId = this.resource.placement_id;
                let placement = this.$store.state.projectPlacements.filter(function(select_item) {
                    return select_item.id === placementId;
                })[0];
                this.resource.donation_type = placement.donation_type;
                this.resource.donation = placement.donation;
                if (placement.donation_type === 'once') {
                    this.resource.payment = placement.donation;
                }
                if (placement.donation_type === 'every week') {
                    this.resource.payment = placement.donation * this.resource.duration;
                }
                if (placement.donation_type === 'every month') {
                    this.resource.payment = placement.donation * (Math.ceil(this.resource.duration/4));
                }
            } else {
                this.resource.payment = null;
                this.resource.donation = null;
                this.resource.donation_type = null;
            }
            // this.insertUpdateResource();
        },
        changePayment() {
            if (!this.resource.payment) {
                // console.log('changePayment test1',this.resource.payment)
                this.calcDonationProject();
            } else {
                // console.log('changePayment test2',this.resource.payment)
                // this.insertUpdateResource();
            }
        },
        async insertUpdateResource () {
            // if (param === 'info_to_client') {
            //     if (this.resource.reserved === null) {
            //         this.resource.reserved = this.resource.info_to_client;
            //         if (this.resource.info_to_provider === null) {
            //             this.resource.info_to_provider = this.resource.info_to_client
            //         }
            //     }
            // }
            this.resource.updating = true;
            this.resource.currency = '$';
            (this.resource.start) ? this.resource.start = this.formattedDate(this.resource.start, 'date') : this.resource.start = null;
            (this.resource.end) ? this.resource.end = this.formattedDate(this.resource.end, 'date') : this.resource.end = null;
            (this.resource.info_to_provider) ? this.resource.info_to_provider = this.formattedDate(this.resource.info_to_provider, 'date') : this.resource.info_to_provider = null;
            (this.resource.info_to_client) ? this.resource.info_to_client = this.formattedDate(this.resource.info_to_client, 'date') : this.resource.info_to_client = null;
            (this.resource.reserved) ? this.resource.reserved = this.formattedDate(this.resource.reserved, 'date') : this.resource.reserved = null;
            await this.$store.dispatch('insertResource', this.resource);
            let objIndex = this.resourcesScope.findIndex(obj => obj.id == this.resource.id);
            await this.resourcesScope.splice(objIndex, 1, this.resource);
            await this.getResources();
            this.resource.updating = false;
        },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async save () {
          if(this.$refs.dialogForm.validate()){
            let tmpName = this.editedItem.name.trim();
            let tmpId = this.editedItem.placement_id;
            let doubleName = this.updatedProjectPlacements.filter(function(select_item) {
                return select_item.name === tmpName && select_item.placement_id === tmpId;
            })
            if (doubleName.length > 0 && this.editedIndex !== this.updatedProjectPlacements.indexOf(doubleName[0])) {
                this.$store.dispatch('errorMessage', 'The placement "' + this.editedItem.name + '" already exists!');
                return;
            } else {
                await this.$store.dispatch('insertProjectPlacement', this.editedItem)
                await this.$store.dispatch('getProjectPlacements');
                this.updatedProjectPlacements = JSON.parse(JSON.stringify(this.$store.state.projectPlacements));
            }
            this.close();
          }
      },
      goClient(id, resourceId) {
            this.$store.dispatch('setLoadingApp', 1);
            this.$router.push({ name: 'Client', params: { id: id, resourceId: resourceId } })
        },
        setObsolete(event) {
            if (event) {
                this.editedItem.obsolete = dayjs().format("YYYY-MM-DD")
            } else {
                this.editedItem.obsolete = null
            }
            
        },
        sumPaymentsProjects (items) {
            var payment = 0;
            for (let index = 0; index < items.length; index++) {
                payment = payment + items[index].payment;
            }
            return payment;
        },
        payProject(resources) {
            this.$store.dispatch('setLoadingApp', 1)
            this.$router.push({ name: 'Financial', params: { paymentProject: this.sumPaymentsProjects(resources), resources: resources } })
        },
        async getDonationsGifted() {
            this.loadingButtonDonationsGifted = true;
            this.donationsGiftedIncome = await this.$store.dispatch('getDonationsGiftedIncome');
            var totalDonationsGiftedIncome = 0;
            for (let index = 0; index < this.donationsGiftedIncome.length; index++) {
                if (this.donationsGiftedIncome[index].client_id) {
                    this.donationsGiftedIncome[index].contactName = this.donationsGiftedIncome[index].clientName + ' (client)' 
                } else {
                    this.donationsGiftedIncome[index].contactName = this.donationsGiftedIncome[index].contactName + ' (contact)' 
                }
                totalDonationsGiftedIncome = totalDonationsGiftedIncome + this.donationsGiftedIncome[index].amountDolares;
            }
            this.donationsGiftedOutgoings = await this.$store.dispatch('getDonationsGiftedOutgoings'); 
            var totalDonationsGiftedOutgoings = 0;
            for (let index = 0; index < this.donationsGiftedOutgoings.length; index++) {
                totalDonationsGiftedOutgoings = totalDonationsGiftedOutgoings + this.donationsGiftedOutgoings[index].amountDolares;
            }
            this.totalDonationsGiftedAvailable = totalDonationsGiftedIncome - totalDonationsGiftedOutgoings;
            this.loadingButtonDonationsGifted = false;
        },
        async calcDatesResource(action) {
            if ((action === 'duration' && this.resource.start) || ((action === 'start' && this.resource.duration))) {
                this.resource.end = this.addbusinessWeeks(this.resource.start, ((this.resource.duration * 5)-1));
            }
            if (action === 'start' || action === 'end') {
                if (this.resource.start && this.resource.end) {
                    let weeks = ((dayjs(this.resource.end).businessDiff(dayjs(this.resource.start))+1)/5);
                    if (weeks < this.resource.min_duration) {
                    this.resource.end = this.addbusinessWeeks(this.resource.start, ((this.resource.duration * 5)-1));
                    } else {
                    this.resource.duration = Math.ceil(weeks);
                    }
                }
            }
            // this.insertUpdateResource();
        },
        addbusinessWeeks(startDate, days) {
            let new_date = dayjs(startDate).businessDaysAdd(days, 'd');
            let day = new_date.format('DD');
            let month = new_date.format('MM');
            let year = new_date.format('YYYY');
            return year + '-' + month + '-' + day
        },
        openTransactionOutgoings(transIdOutgoings) {
            this.$store.dispatch('setLoadingApp', 1)
            this.$router.push({ name: 'Financial', params: { transIdOutgoings: transIdOutgoings } })
        },
            
        async goCartaDePresentacion (resources) {
            let load = {};
            let payload = [];
            this.$store.dispatch('progressAdvance', 0);
            this.$store.dispatch('showProgressBar', true);
            let progress = 1;
            let step = (100 - progress)/(
                resources.length
            );
            for (let i = 0; i < resources.length; i++) {
                await this.$store.dispatch('getClient', resources[i].client_id);
                if (!resources[i].info_to_provider) {
                    let n = new Date();
                    resources[i].info_to_provider = this.formattedDate(n,'date');
                    resources[i].reserved = null;
                    resources[i].info_to_client = null;
                    this.resource = resources[i];
                    this.insertUpdateResource();
                }
                let client = JSON.parse(JSON.stringify(this.$store.getters.client));
                load = {
                    client: client,
                    resource: resources[i],
                }
                payload.push(load);
                progress = progress + step;
                this.$store.dispatch('progressAdvance', progress);
            }
            await this.$store.dispatch('saveDataForReport', payload);
            this.$store.dispatch('showProgressBar', false);
            this.$store.dispatch('progressAdvance', 100);
            this.$router.push({ name: 'CartaDePresentacion'});
        },

        async confirmReservations (resources) {
            this.$store.dispatch('progressAdvance', 0);
            this.$store.dispatch('showProgressBar', true);
            let progress = 1;
            let step = (100 - progress)/(
                resources.length
            );
            for (let i = 0; i < resources.length; i++) {
                await this.$store.dispatch('getClient', resources[i].client_id);
                if (!resources[i].reserved) {
                    let n = new Date();
                    if (!resources[i].info_to_provider) {
                        resources[i].info_to_provider = this.formattedDate(n,'date');
                    }
                    resources[i].reserved = this.formattedDate(n,'date');
                    this.resource = resources[i];
                    this.insertUpdateResource();
                }
                progress = progress + step;
                this.$store.dispatch('progressAdvance', progress);
            }
            this.$store.dispatch('showProgressBar', false);
            this.$store.dispatch('progressAdvance', 100);
        },

        selectResourceReservation() {
        if (this.selectedReservation.length === 0) {
            this.filterResources();
            this.selectedReservationSingleSelect = true;
            return;
        } else {
                let placement_id = this.selectedReservation[0].placement_id;
                this.selectedReservationSingleSelect = false;
                this.resourcesScope = this.resourcesScope.filter(function(select_item) {
                    return select_item.placement_id === placement_id;
                });
            }
        },
        checkResourceDates() {
            if (this.resource.info_to_provider === null) {
                this.resource.info_to_provider = this.resource.info_to_client;
            }
            if (this.resource.reserved === null) {
                this.resource.reserved = this.resource.info_to_client;
            }
        },
    },
  }

</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
</style>