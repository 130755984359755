<template>
    <v-card flat class="d-flex justify-space-around" v-if="$store.getters.loadingApp !== 1">
        <v-card 
            flat
            class="pa-0"
            width="210mm"
        >
            <span v-html="messageHTML" class="ma-2"></span>
        </v-card>
    </v-card>
</template>

<script>

import dayjs from 'dayjs';
import "dayjs/locale/es";

export default {
    data () {
        return {
            messageHTML: "",
        }
    },
    beforeCreate () {
        this.$store.dispatch('setLoadingApp', 1);
    },
    async created() {
        dayjs.locale("es");
        await this.createFichaDeAlojamiento();
        const date = dayjs().format("DD/MMMM/YYYY");
        console.log(date);
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[breadcrumb.length - 1] !== 'CartaDePresentacion') {
            breadcrumb.push('CartaDePresentacion')
            this.$store.dispatch('breadcrumbs', breadcrumb);
        }
        this.$vuetify.goTo(0);
        this.$store.dispatch('setLoadingApp', 0)
    },
    mounted() {
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[0] === 'CartaDePresentacion') {
            this.$router.push({ name: 'Projects' })
        }
    },
    methods: {
        createFichaDeAlojamiento() {
            let payload = this.$store.state.dataForReport;
            console.log('createFichaDeAlojamiento ', payload);
            // let email = payload[0].resource.emailContact;
            this.$store.dispatch('saveDataForReport', );
            let messageHeader = "<div style='margin-top: 0cm; margin-bottom: 0cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif;'>" +
                "<div style='display: flex; flex-direction: row; justify-content: space-between; margin: 0 0 0cm 0;'>" +
                    "<div style='font-family: Roboto, sans-serif; font-size: 32px; color: #35b729;'><span>PROYECTO</span><strong>PERU</strong></div>" +
                    "<div style='font-size: 32px; color: grey; display: flex; flex-direction: column; align-items: flex-end;'>" + 
                        "<div style='font-size: 24px;'>Ficha de Cliente</div>" +
                        "<div style='font-size: 14px;'><i>¡Brindando nuevas oportunidades para vivir</i></div>" + 
                        "<div style='font-size: 14px;'><i>experiencias culturales inolvidables a</i></div>" + 
                        "<div style='font-size: 14px;'><i>través de voluntariados y hospedaje con familias!</i></div>" + 
                        "<div style='font-size: 18px;'>Cusco, " + 
                            dayjs(Date.now()).format('ddd DD MMM YY') +
                        "</div>" + 
                    "</div>" + 
                "</div>" + 
            "</div>"
            let messageBody = "";
            for (let i = 0; i < payload.length; i++) {
                messageBody += "<div style='margin-top: 1cm;'><strong>Nombre: </strong>" + payload[i].resource.full_name + "</div>"
                if (payload[i].client.nationality) {
                    messageBody += "<div><strong>Nacionalidad: </strong>" + payload[i].client.nationality + "</div>"
                }
                if (payload[i].client.birthdate) {
                    messageBody += "<div><strong>Edad: </strong>" + dayjs().diff(payload[i].client.birthdate, 'year') + "</div>"
                }
                if (payload[i].client.gender === 'Male') {
                    messageBody += "<div><strong>Género: </strong>Masculino</div>"
                } else if (payload[i].client.gender === 'Female') {
                    messageBody += "<div><strong>Género: </strong>Femenino</div>"
                } else {
                    messageBody += "<div><strong>Género: </strong>No conocido</div>"
                }
                if (payload[i].client.occupation) {
                    messageBody += "<div><strong>Ocupación / Profesión: </strong>" + payload[i].client.occupation + "</div>"
                }
                if (payload[i].client.email) {
                    messageBody += "<div><strong>Email: </strong>" + payload[i].client.email + "</div>"
                }
                if (payload[i].client.food_preference === 'Not known') {
                    messageBody += "<div><strong>Dieta: </strong>por confirmar</div>"
                } else if (payload[i].client.food_preference === 'I am not vegetarian neither vegan') {
                    messageBody += "<div><strong>Dieta: </strong>normal</div>"
                } else if (payload[i].client.food_preference === 'I am vegetarian') {
                    messageBody += "<div><strong>Dieta: </strong>vegetariana</div>"
                } else if (payload[i].client.food_preference === 'I am vegan') {
                    messageBody += "<div><strong>Dieta: </strong>vegana</div>"
                }
                if (payload[i].resource.comments) {
                    messageBody += "<div><strong>Comentario: </strong>" + payload[i].resource.comments + "</div>"
                }
                messageBody += "<div style='margin-top: 1cm;'><i>Estadia</i></div>"
                messageBody += "<div style='margin-top: 0cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif; background-color: #c7d3d1; height: 2px;'></div>"
                messageBody += "<div><strong>Tiempo: </strong>" + payload[i].resource.duration + " noches</div>"
                messageBody += "<div><strong>Día de llegada: </strong>" + dayjs(payload[i].resource.start).format('ddd DD MMM YY') + "</div>"
                messageBody += "<div><strong>Día de salida: </strong>" + dayjs(payload[i].resource.end).format('ddd DD MMM YY') + "</div>"
                if (payload[i].client.pickup_type) {
                    messageBody += "<div style='margin-top: 1cm;'><i>Pickup</i></div>"
                    messageBody += "<div style='margin-top: 0cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif; background-color: #c7d3d1; height: 2px;'></div>"
                    if (payload[i].client.pickup_type === 'Flight') {
                        messageBody += "<div><strong>Vuelo: </strong>" + payload[i].client.pickup_nr + "</div>"
                        messageBody += "<div><strong>Compania: </strong>" + payload[i].client.pickup_company + "</div>"
                        messageBody += "<div><strong>Llegando desde: </strong>" + payload[i].client.pickup_from + "</div>"
                        messageBody += "<div><strong>Hora (hh:mm): </strong>" + payload[i].client.pickup_time + "</div>"
                    } else if (payload[i].client.pickup_type === 'Bus') {
                        messageBody += "<div><strong>Bus: </strong>" + payload[i].client.pickup_company + "</div>"
                        messageBody += "<div><strong>Llegando desde: </strong>" + payload[i].client.pickup_from + "</div>"
                        messageBody += "<div><strong>Hora (hh:mm): </strong>" + payload[i].client.pickup_time + "</div>"
                    } else if (payload[i].client.pickup_type === 'Address') {
                        messageBody += "<div><strong>Dirección: </strong>" + payload[i].client.pickup_address + "</div>"
                        messageBody += "<div><strong>Hora (hh:mm): </strong>" + payload[i].client.pickup_time + "</div>"
                    } else if (payload[i].client.pickup_type === 'To be confirmed') {
                        messageBody += "<div><strong>Para Confirmar</strong></div>"
                    }
                }
                messageBody += "<div style='margin-top: 1cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif; background-color: #c7d3d1; height: 4px;'></div>"
            }
            // let emailIntro = "Estimado " + payload[0].resource.contact_organisation + ",\n\n" + 
            // "Adjuntado encuentra la Carta de Presentación de uno de nuestras voluntariados/practicantes.\n\n" +
            // "Saludos cordiales, \n\n" + this.$store.state.user + "\n\n" +
            // "info@proyectoperucentre.org\n" +
            // "Proyecto Peru Centre\n" +
            // "Avenida Arcopata 466-A\n" +
            // "Cusco, Cusco, 45\n" +
            // "+ 51 84 240 278"
            let messageFooter = "<div style='margin-top: 3cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif; background-color: #c7d3d1; height: 2px;'></div>"
            messageFooter += "<div style='display: flex; justify-content: space-around; font-size: 14px;'>Proyecto Peru Centre, Arcopata 466 Cusco, Peru</div>"
            messageFooter += "<div style='display: flex; justify-content: space-around; font-size: 14px;' class='mb-0 pb-0'>Tel. +51 (084) 240 278 / 950 301 317 Email: office@proyectoperucentre.org</div>"
            this.messageHTML = messageHeader + messageBody + messageFooter;
            // payload = {
            //     submittedFrom: "ClassScheduleTeacher",
            //     emailList: email,
            //     emailSubject: "Class Schedule Proyecto Peru",
            //     emailIntro: emailIntro,
            //     messageHTML: "<div style='margin: 1cm 0 1cm 0; background-color: #c7d3d1; text-align: left; width: 100%; height: 2px;'></div>" + this.messageHTML,
            //     messagePlain: '',
            //     openEmailDialog: false,
            //     saveInvoice: false,
            //     attachments: null,
            // };
            // this.$store.dispatch('emailMessage', payload);
        },
    },
}
</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
    /* @media all {
        .page-break	{ display: none; }
    }
    @media print {
        .page-break	{ display: block; page-break-before: always; }
    } */
</style>