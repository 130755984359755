<template>
  <div class="leads" v-if="$store.getters.loadingApp !== 1">
    <v-card flat class="pa-4 d-flex flex-row flex-wrap justify-center">
      <v-radio-group
          row
          v-model="scopeLeads"
          class="mt-0"
          @change="getClients(scopeLeads)"
      >
          <v-radio
              label="Ongoing"
              value="ongoing"
              class="my-0 py-0"
          ></v-radio>
          <v-radio
              label="No Conversion"
              value="noResponse"
              class="my-0 py-0"
          ></v-radio>
      </v-radio-group>
    </v-card>
    <v-data-table
      :headers="headersLeads"
      :items="leads"
      sort-by="autoContact"
      class="elevation-1"
      :items-per-page="-1"
      :search="searchLeads"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-flex flex-row flex-wrap justify-center">
              <v-card flat>
                  <v-text-field
                      v-model="searchLeads"
                      append-icon="$vuetify.icons.icon_mdi_magnify"
                      :label="'Search ' + `${ leads.length }` + ' lead(s)'"
                      single-line
                      hide-details
                      style="width: 300px"
                      clearable
                  ></v-text-field>
              </v-card>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="ppc_pink"
            dark
            small
            absolute
            right
            fab
            @click="dialogAddLead = !dialogAddLead"
          >
            <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
          </v-btn>

          <v-dialog v-model="dialogAddLead" max-width="500px">
            <v-card style='height: 550px'>
              <v-card-title>
                <span class="headline">New lead</span>
              </v-card-title>
              <v-card-text>
                <v-form ref="formAddLead" v-model="addLeadValid">
                  <v-container>
                    <v-radio-group
                      cols="12"
                      column
                      v-model="typeClient"
                      class="mt-0"
                      label="Select type of Client"
                    >
                      <v-radio
                        label="Client (default)"
                        value="Client"
                        class="my-0 py-0"
                      ></v-radio>
                      <v-radio
                        label="Group without Names"
                        value="groupWithoutNames"
                        class="my-0 py-0"
                      ></v-radio>
                    </v-radio-group>
                    <v-row v-if="typeClient === 'Client'">
                      <v-col cols="auto" sm="6" md="6">
                        <v-text-field label="First name" v-model.trim="first_name" prepend-icon="$vuetify.icons.icon_mdi_smart_card ppc_darkgreen--text" 
                          validate-on-blur :error-messages="errorMessageNames" :rules="inputRulesName"></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="auto" sm="6" md="6">
                        <v-text-field label="Last name" v-model.trim="last_name" prepend-icon="$vuetify.icons.icon_mdi_smart_card-outline ppc_darkgreen--text" 
                        validate-on-blur :error-messages="errorMessageNames" :rules="inputRulesName"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="12">
                        Select a source (can be changed later) or an agency. 
                        <v-select
                          v-model="sourceId"
                          :items="$store.state.itemsSources"
                          item-text="name"
                          item-value="id"
                          :menu-props="{ auto: true }"
                          label="Source"
                          single-line
                          prepend-icon="fab fa-sourcetree ppc_darkgreen--text"
                          validate-on-blur
                          @change="agencyId=null"
                        >
                        </v-select>
                        <v-select
                            v-model="agencyId"
                            :items="$store.state.available_agencies"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ auto: true }"
                            label="Agency"
                            single-line
                            prepend-icon="fab fa-sourcetree ppc_darkgreen--text"
                            validate-on-blur
                            @change="sourceId=null"
                          >
                        </v-select>
                      </v-col>
                    </v-row>
                    <div v-if="typeClient !== 'Client'">
                      Only use this when: you are not able to get their names, no need of knowing their personal preferences, no individual pickups, no need of sending personal emails, no extra individual services, no fairwell message, no after marketing, etc.
                      <v-text-field label="Name of the group" v-model.trim="last_name" prepend-icon="$vuetify.icons.icon_mdi_smart_card-outline ppc_darkgreen--text" 
                        validate-on-blur :error-messages="errorMessageNames" :rules="inputRulesName"></v-text-field>
                      <v-text-field
                        label='amount of people'
                        type='number'
                        v-model="numberPeople"
                        validate-on-blur
                        :rules="inputRulesReq"
                      ></v-text-field>
                      <v-select
                          v-model="agencyId"
                          :items="$store.state.available_agencies"
                          item-text="name"
                          item-value="id"
                          :menu-props="{ auto: true }"
                          label="Agency"
                          single-line
                          prepend-icon="fab fa-sourcetree ppc_darkgreen--text"
                          validate-on-blur
                          @change="sourceId=null"
                          :rules="inputRulesReq"
                        >
                      </v-select>
                    </div>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="ppc_darkgreen darken-1" text @click="dialogAddLead = !dialogAddLead">Cancel</v-btn>
                <v-btn color="ppc_darkgreen darken-1" text @click="addLead" :loading="loading" :disabled="!addLeadValid">Add</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-toolbar>
      </template>

      <template v-slot:[`item.full_name`]="{ item }">
          <v-btn small rounded @click="goClient(item.id)">{{ item.full_name }}</v-btn>
      </template>
      <template v-slot:[`item.gender`]="{ item }">
        <v-icon small v-if="`${item.gender}` === 'Male'" color="ppc_darkgreen">fa-male</v-icon>
        <v-icon small v-else-if="`${item.gender}` === 'Female'" color="ppc_darkgreen">fa-female</v-icon>
        <v-icon small v-else color="ppc_darkgreen">fa-genderless</v-icon>
      </template>

      <template v-slot:[`item.start_first`]="{ item }">
        {{ formattedDate(item.start_first) }}
      </template>

      <template v-slot:[`item.statusClientId`]="{ item }">
        <v-chip small :color="`${getStatus(item.statusClientId).color}`" text-color="white" v-html="`${getStatus(item.statusClientId).name}`"></v-chip>
      </template>

      <template v-slot:[`item.autoContact`]="{ item }">
        <v-chip small v-if="`${item.autoContact}` !== 'null' && (formattedDate(`${item.autoContact}`, 'date') <= nowDate)" color="ppc_pink" v-html="formattedDate(`${item.autoContact}`, 'date')"></v-chip>
        <v-chip small v-else-if="`${item.autoContact}` !== 'null'" color="ppc_darkgreen" v-html="formattedDate(`${item.autoContact}`, 'date')"></v-chip>
        <v-chip small v-else color="orange">not set</v-chip>
      </template>

      <!-- <template v-slot:[`item.edit`]="{ item }"> 
        <v-btn 
          @click="goClient(item.id)"
          icon
        >
          <v-icon
            small
            class="mr-2"
          >
            $vuetify.icons.icon_mdi_pencil
          </v-icon>
        </v-btn>
      </template> -->

    </v-data-table>

  </div>
</template>

<script>
import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import dayjs from 'dayjs';
export default {
  data() {
    return {
      nowDate: null,
      arrivals: null,
      dialogAddLead: false,
      loading: false,
      itemsNationality: [],
      itemsSource: [],
      errorMessageNames: '',
      first_name: '',
      last_name: '',
      sourceId: 1,
      agencyId: null,
      leads: [],
      scopeLeads: 'ongoing',
      headersLeads: [],
      addLeadValid: true,
      arrayUpload: [],
      typeClient: 'Client',
      numberPeople: null,
      searchLeads: '',
    }
  },

  mixins: [inputRules, formattedDate],
  
  watch: {
    successMessage: function () {
      if (this.successMessage !== "") {
        this.alertSuccessMessage = true;
      }
    },
  },
  computed: {
    successMessage () {
      return this.$store.getters.successMessage
    },
  },
  beforeCreate () {
    this.$store.dispatch('setLoadingApp', 1)
  },
  created () {
    dayjs.locale("en");
    this.initialize();
  },
  methods: {
    async initialize () {
      this.$store.dispatch('breadcrumbs', ['Leads']);
      this.getClients('ongoing');
      this.leads = this.$store.getters.leads;
      this.nowDate = this.formattedDate(new Date(), 'date');
      this.$store.dispatch('setLoadingApp', 0)
    },
    goClient(id) {
      this.$store.dispatch('setLoadingApp', 1);
      this.$router.push({ name: 'Client', params: { id: id } })
    },
    getStatus(id) {
      return this.$store.state.statusClients.filter((select_item) => {
        return select_item.id === id;
      })[0];
    },
    async addLead () {
      if (this.$refs.formAddLead.validate()) {
        // check existance
        // exactly same name
        this.searchResults = await this.$store.dispatch('searchClient', this.first_name + ' ' + this.last_name)
        if (this.searchResults.length === 1) {
          this.$store.dispatch('warningMessage', 'Client already exsists!');
          this.goClient(this.searchResults[0].id);
        } else {
          const date = new Date();
          var id = date.getTime();
          var salesContact = null;
          if (this.agencyId) {
            let tmp = this.$store.state.available_agencies.filter((select_item) => {
              return select_item.id === this.agencyId
            })
            salesContact = tmp[0].salesContact_id;
            var agencyName = tmp[0].name;
          } else {
            let tmp = this.$store.state.uid;
            let user = this.$store.state.users.filter((select_item) => {
              return select_item.id === tmp;
            })
            salesContact = user[0].contact_id;
          }
          if (this.typeClient === 'Client') {
            var payload = {
              id: id,
              first_name: this.first_name,
              last_name: this.last_name,
              gender: 'X',
              pickup_type: 'To be confirmed',
              source_id: this.sourceId,
              agency_id: this.agencyId,
              numberPeople: null,
              salesContact: salesContact,
              ignore_welcome_email: 0,
              ignore_thank_you_email: 0,
              ignore_security_deposit: 0,
              statusFixed: 0,
            }
          } else {
            payload = {
              id: id,
              first_name: 'Group - ' + agencyName + '(' + this.numberPeople + ') ',
              last_name: this.last_name,
              gender: 'X',
              pickup_type: 'Flight',
              source_id: null,
              agency_id: this.agencyId,
              number_people: this.numberPeople,
              salesContact: salesContact,
              ignore_welcome_email: 0,
              ignore_thank_you_email: 0,
              ignore_security_deposit: 0,
              statusFixed: 0,
            }
          }
          
          this.$refs.formAddLead.reset();
          this.$store.dispatch('setLoadingApp', 1)
          await this.$store.dispatch('addLead', payload);
          this.first_name = '';
          this.last_name = '';
          this.email = null;
          this.sourceId = null;
          this.agencyId = null;
          this.dialogAddLead = false;
          this.goClient(id);
        }
      }
    },
    async getClients(scopeLeads) {
      if (scopeLeads === 'ongoing') {
        this.leads = await this.$store.dispatch('getLeads');
        this.headersLeads = [
          { text: 'Full Name', align: 'start', value: 'full_name' },
          { text: 'Email', value: 'email' },
          { text: 'Gender', value: 'gender', width: "100px", sortable: false},
          { text: 'Nationality', value: 'nationality' },
          { text: 'Source', value: 'source' },
          { text: 'Sales Contact', value: 'salesContactName' },
          { text: 'Services', value: 'amountServices', sortable: false},
          { text: 'Start', value: 'start_first', width: "150px"},
          { text: 'Status', value: 'statusClientId' },
          { text: 'Comments', value: 'comments' },
          { text: 'Next Contact', value: 'autoContact' }
        ];
      } else {
        this.leads = await this.$store.dispatch('getLeadsNoResponse');
        this.headersLeads = [
          { text: 'Full Name', align: 'start', value: 'full_name' },
          { text: 'Email', value: 'email' },
          { text: 'Gender', value: 'gender', width: "100px", sortable: false},
          { text: 'Nationality', value: 'nationality' },
          { text: 'Source', value: 'source' },
          { text: 'Sales Contact', value: 'salesContactName' },
          { text: 'Services', value: 'amountServices', sortable: false},
          { text: 'Start', value: 'start_first', width: "150px"},
          { text: 'Status', value: 'statusClientId' },
          { text: 'Comments', value: 'comments' },
        ];
      }
    },
  },
}
</script>