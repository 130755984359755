<template>
    <v-card flat class="d-flex justify-space-around" v-if="$store.getters.loadingApp !== 1">
        <v-card 
            flat
            class="pa-0"
            width="210mm"
        >
            <span v-html="messageHTML" class="ma-2"></span>
        </v-card>
    </v-card>
</template>

<script>

import dayjs from 'dayjs';
import "dayjs/locale/es";
import compare from '@/mixins/compare';
import formattedDate from '@/mixins/formattedDate';


export default {

    mixins: [compare, formattedDate],

    data () {
        return {
            messageHTML: "",
        }
    },
    beforeCreate () {
        this.$store.dispatch('setLoadingApp', 1);
    },
    async created() {
        dayjs.locale("es");
        await this.createTourAgenciesReport();
        const date = dayjs().format("DD/MMMM/YYYY");
        console.log(date);
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[breadcrumb.length - 1] !== 'TourAgenciesReport') {
            breadcrumb.push('TourAgenciesReport')
            this.$store.dispatch('breadcrumbs', breadcrumb);
        }
        // this.$vuetify.goTo(0);
        this.$store.dispatch('setLoadingApp', 0)
    },
    mounted() {
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[0] === 'TourAgenciesReport') {
            this.$router.push({ name: 'Projects' })
        }
    },
    methods: {
        async createTourAgenciesReport() {
            let report = this.$store.state.dataForReport.report;
            let table = await this.createTableOngoing(report);
            this.$store.dispatch('saveDataForReport', );
            let messageHeader = "<div style='margin-top: 0cm; margin-bottom: 0cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif;'>" +
                "<div style='display: flex; flex-direction: row; justify-content: space-between; margin: 0 0 0cm 0;'>" +
                    "<div style='font-family: Roboto, sans-serif; font-size: 32px; color: #35b729;'><span>PROYECTO</span><strong>PERU</strong></div>" +
                    "<div style='font-size: 32px; color: grey; display: flex; flex-direction: column; align-items: flex-end;'>" + 
                        "<div style='font-size: 24px;'>Liquidación Tours</div>" +
                        "<div style='font-size: 18px;'>Cusco, " + 
                            dayjs(Date.now()).format('ddd DD MMM YY') +
                        "</div>" + 
                        "<div style='font-size: 14px;'><i>¡Brindando nuevas oportunidades para vivir</i></div>" + 
                        "<div style='font-size: 14px;'><i>experiencias culturales inolvidables a</i></div>" + 
                        "<div style='font-size: 14px;'><i>través de voluntariados y pasantías!</i></div>" + 
                    "</div>" + 
                "</div>" + 
            "</div>"
            let messageBody = "<div style='font-family: Roboto;'>Para: " + report[0].contact_organisation + "</div><br>" +
            "<div>Es grato dirigirnos a ustedes para presentarles la liquidación pendiente de pago: </div><br><br>"
            let emailIntro = "Estimado " + report[0].nameContact + ",\n\n" + 
            "Adjuntado encuentra la liquidación pendiente de nuestras clientes.\n\n" +
            "Saludos cordiales, \n\n" + this.$store.state.user + "\n\n" +
            "office@proyectoperucentre.org\n" +
            "Proyecto Peru Centre\n" +
            "Avenida Arcopata 466-A\n" +
            "Cusco, Cusco, 45\n" +
            "+ 51 84 240 278"
            let messageFooter = "<br><div>Agradeciendo nuevamente su gentileza por atender a nuestros clientes.</div>"
            messageFooter += "<br><div>Atentamente, </div>"
            messageFooter += "<br><div>PROYECTOPERU</div>"
            messageFooter += "<div style='margin-top: 1cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif; background-color: #c7d3d1; height: 2px;'></div>"
            messageFooter += "<div style='display: flex; justify-content: space-around; font-size: 14px;'>Proyecto Peru Centre, Arcopata 466 Cusco, Peru</div>"
            messageFooter += "<div style='display: flex; justify-content: space-around; font-size: 14px;' class='mb-0 pb-0'>Tel. +51 (084) 240 278 / 950 301 317 Email: office@proyectoperucentre.org</div>"
            this.messageHTML = messageHeader + messageBody + table + messageFooter;
            let payload = {
                submittedFrom: "office@proyectoperucentre.org",
                emailList: report[0].emailContact,
                emailSubject: "Liquidación Pendiente",
                emailIntro: emailIntro,
                messageHTML: "<div style='margin: 1cm 0 1cm 0; background-color: #c7d3d1; text-align: left; width: 100%; height: 2px;'></div>" + this.messageHTML,
                messagePlain: '',
                openEmailDialog: false,
                saveInvoice: false,
                attachments: null,
            };
            this.$store.dispatch('emailMessage', payload);
        },
        createTableOngoing(report) {
            // HTML version
            report.sort(this.dynamicSort('start'));
            let table = "<div><table style='width: 100%;'><tr><th style='text-align: left; width:80px;'>Fecha</th><th style='text-align: left; width: 100px'>Servicio</th><th style='text-align: left; width: 40px'>Cliente</th><th style='text-align: left; width: 50px'>Preferencia Alimentaria</th><th style='text-align: left; width: 50px'>Precio</th><th style='text-align: left; width: 50px'>Cancelado</th><th style='text-align: left; width: 50px'>Saldo Pendiente</th></tr>";
            let total = 0;
            let cancelado;
            let backgroundColor = '#EAEDED';
            for (let index = 0; index < report.length; index++) {
                report[index].food_preference = report[index].food_preference || '';
                if (report[index].food_preference === 'not known' || report[index].food_preference === null) {
                    report[index].food_preference = '?'
                } else if (report[index].food_preference === 'I am vegan') {
                    report[index].food_preference = 'vegano'
                } else if (report[index].food_preference === 'I am vegetarian') {
                    report[index].food_preference = 'vegetariano'
                } else {
                    report[index].food_preference = ''
                }
                total += report[index].paymentOpen;
                (report[index].amountPaidReceiver === 0) ? (cancelado = '-') : (cancelado = report[index].currency + report[index].amountPaidReceiver.toFixed(2))
                table += "<tr style='font-size: 14px; background-color: " + backgroundColor + "'><td>" + this.formattedDate(report[index].start) + "</td><td>" + report[index].tourName + "</td><td>" + report[index].full_name + "</td>" + "</td><td>" + report[index].food_preference + "</td><td>" + report[index].currency + report[index].payment.toFixed(2) + "</td><td>" + cancelado + "</td><td>" + report[index].currency + report[index].paymentOpen.toFixed(2) + "</td></tr>";
                (backgroundColor === '#EAEDED') ? (backgroundColor = '#FFFFFF') : (backgroundColor = '#EAEDED');
            }
            table += "</table></div><br>"
            table += "<div>Total: " + report[0].currency + total.toFixed(2) + "</div><br>"
            return table;
        },
    },
}
</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
</style>