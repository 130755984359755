export default {
    methods: {
        getServicesApplied (application_id) {
            return this.services.filter(function(select_item) {
                return select_item.application_id === application_id;
              }
            )
        },
    }
    
}