<template>
    <div class="d-flex justify-space-around" v-if="$store.getters.loadingApp !== 1">
        <v-card 
            flat
            class="pa-0"
            width="100%"
            max-width="210mm"
            min-height="297mm"
        >
            <span v-html="messageHTML" class="ma-2"></span>
        </v-card>
    </div>
</template>

<script>

export default {
    data () {
        return {
            messageHTML: '',
        }
    },
    beforeCreate () {
        this.$store.dispatch('setLoadingApp', 1)
    },
    async created() {
        await this.createSheets();
        this.$store.dispatch('setLoadingApp', 0)
    },
    mounted() {
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[0] === 'ClassScheduleTeacher') {
            this.$router.push({ name: 'Teaching' })
        }
    },
    
    methods: {
        async createSheets() {
            this.messageHTML = this.$store.state.dataForReport.messageHTML;
            this.$store.dispatch('saveDataForReport', );
        },
    },
}
</script>

<style>
    .borderAroundText {
        padding: 10px;
        border: 1px solid black;
        display: inline-block;
    }

    @media print {
        .pagebreak { page-break-after: always; }
    }
</style>