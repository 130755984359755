<template>
  <div class="client">   
    <v-card flat class="rounded-xl">
      <v-card-title class="text-center justify-center py-6">
        <h1 class="font-weight-bold display-1 grey--text" v-if="$store.getters.client.full_name"> {{ $store.getters.client.full_name }} </h1>
        <h1 class="font-weight-bold display-1 grey--text" v-else> waiting.. </h1>
      </v-card-title>
      <v-card-actions>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-chip small class="ppc_darkgreen white--text ml-2" v-on="on">
              {{ formattedDate($store.getters.client.start_first) }}
              <v-icon small class="ml-1 white--text">fa-plane-arrival</v-icon>
            </v-chip>
          </template>
          <span>Start date</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-chip small class="ppc_darkgreen white--text ml-2" v-on="on">
              {{ formattedDate($store.getters.client.end_last) }}
              <v-icon small class="ml-1 white--text">fa-plane-departure</v-icon>
            </v-chip>
          </template>
          <span>End date</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <span v-if="$store.getters.client.statusClientId === 8 || $store.getters.client.statusClientId === 9">
          <v-chip 
            @click='resetStatusClient'
            small
          >
           reset status
          </v-chip>
        </span>
        <span>
          <v-chip 
            v-if="getStatusClient($store.getters.client.statusClientId)" 
            class="mx-4" 
            :color="`${getStatusClient($store.getters.client.statusClientId).color}`" 
            text-color="white"
            v-html="`${getStatusClient($store.getters.client.statusClientId).name}`">
          </v-chip>
        </span>
        <v-btn @click="$router.push({ name: 'PackageMaker' })" v-if="calcPMmessages > 0" color="ppc_pink" small rounded class="mr-12">
            <dir class="ml-1 white--text">{{ calcPMmessages }}</dir>
            <v-icon small class="mx-1 white--text">$vuetify.icons.icon_mdi_package_variant</v-icon> 
        </v-btn>
      </v-card-actions>
  
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="basil"
        grow
      >
       <v-tab
          v-for="itemTab in itemsTab"
          :key="itemTab"
        >
          <span class="ppc_darkgreen--text">{{ itemTab }}</span>
        </v-tab>
      </v-tabs>
  
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat :disabled="$store.state.loadingApp !== 0">
            <v-card-text>
              <v-card class="d-flex flex-column my-4 rounded-xl">
                <v-card flat class="d-flex flex-row flex-wrap">
                  <span class="px-6 py-2" style="width: 400px">
                    <v-card-title>
                      Client
                    </v-card-title>
                    <v-card-text class="d-flex flex-column">
                      <span class="py-2" v-if="updatedClient.number_people === null">Name: <span class="font-weight-bold">{{ updatedClient.first_name }} {{ updatedClient.last_name }}</span></span>
                      <span class="py-2" v-if="updatedClient.number_people !== null">Group: <span class="font-weight-bold">{{ updatedClient.last_name }}</span></span>
                      <span class="py-2">Birthdate: <span class="font-weight-bold">{{ updatedClient.birthdate }}</span> ({{ calcAge }} years)</span>
                      <span class="py-2" v-if="updatedClient.number_people === null">Gender: <span class="font-weight-bold">{{ updatedClient.gender }}</span>
                        <span class="px-2" v-if="updatedClient.gender === 'Male'"><v-icon color="ppc_darkgreen" class="mr-4">fa-male</v-icon></span>
                        <span class="px-2" v-else-if="updatedClient.gender === 'Female'"><v-icon color="ppc_darkgreen" class="mr-4">fa-female</v-icon></span>
                        <span class="px-2" v-else><v-icon color="ppc_darkgreen" class="mr-4">fa-genderless</v-icon></span>
                      </span>
                      <span class="py-2">Nationality: <span class="font-weight-bold">{{ updatedClient.nationality }}</span></span>
                      <span class="py-2" v-if="updatedClient.number_people === null">Occupation: <span class="font-weight-bold">{{ updatedClient.occupation }}</span></span>
                      <span class="py-2" v-if="updatedClient.number_people === null">Food preference: <span class="font-weight-bold">{{ updatedClient.food_preference }}</span></span>
                      <span class="py-2" v-if="updatedClient.number_people === null">Pets preference: <span class="font-weight-bold">{{ updatedClient.pets_preference }}</span></span>
                    </v-card-text>
                  </span>
                  <span class="px-6 py-2" style="width: 400px">
                    <v-card-title>
                      Contact info
                    </v-card-title>
                    <v-card-text class="d-flex flex-column">
                      <span class="py-2">Email: <span class="font-weight-bold">{{ updatedClient.email }}</span></span>
                      <span class="py-2">Email cc/agency: <span class="font-weight-bold">{{ updatedClient.email_cc }}</span></span>
                      <span class="py-2">Phone: <span class="font-weight-bold">{{ updatedClient.phone }}</span></span>
                      <span class="py-2">Alternative phone: <span class="font-weight-bold">{{ updatedClient.phone_alt }}</span></span>
                    </v-card-text>
                  </span>
                  <span class="px-6 py-2" style="width: 400px">
                    <v-card-title>
                      Source
                    </v-card-title>
                    <v-card-text class="d-flex flex-column">
                      <span class="py-2" v-if="updatedClient.agency_id !== null">Agency: <span class="font-weight-bold">{{ updatedClient.agency }}</span></span>
                      <span class="py-2" v-else>Source: <span class="font-weight-bold">{{ updatedClient.source }}</span></span>
                      <span class="py-2">Contact PPC: <span class="font-weight-bold">{{ updatedClient.salesContactName }}</span></span>
                    </v-card-text>
                  </span>
                  <span class="px-6 py-2" style="width: 400px">
                    <v-card-title>
                      Emergency Contact
                    </v-card-title>
                    <v-card-text class="d-flex flex-column">
                      <span class="py-2">Name: <span class="font-weight-bold">{{ updatedClient.ice_full_name }}</span></span>
                      <span class="py-2">Email: <span class="font-weight-bold">{{ updatedClient.ice_email }}</span></span>
                      <span class="py-2">Phone: <span class="font-weight-bold">{{ updatedClient.ice_phone }}</span></span>
                      <span class="py-2">Alternative phone: <span class="font-weight-bold">{{ updatedClient.ice_phone_alt }}</span></span>
                    </v-card-text>
                  </span>
                  <span class="px-6 py-2" style="width: 400px">
                    <v-card-title>
                      Pick Up
                    </v-card-title>
                    <v-card-text class="d-flex flex-column">
                      <span class="py-2">
                        <span class="font-weight-bold">{{updatedClient.pickup_type}} 
                          <span v-if="updatedClient.pickup_type !== 'To be confirmed' & updatedClient.pickup_type !== 'Not applicable'">
                            <span v-if="updatedClient.pickup_type === 'Address'"> at {{updatedClient.pickup_address}}</span> <span v-if="updatedClient.pickup_type === 'Flight'">{{updatedClient.pickup_nr}}</span> {{formattedDate(updatedClient.pickup_date)}} <span v-if="updatedClient.pickup_type === 'Flight' || updatedClient.pickup_type === 'Bus'">{{updatedClient.pickup_time}} from {{updatedClient.pickup_from}} with {{updatedClient.pickup_company}}</span>
                          </span>
                        </span>
                      </span>
                    </v-card-text>
                  </span>
                  <v-card class="ma-4 px-6 py-2" style="width: 800px">
                    <v-card-title>
                      Comments
                    </v-card-title>
                    <v-card-text class="d-flex flex-column">
                      <span class="py-2"><span class="font-weight-bold">{{ updatedClient.comments }}</span></span>
                    </v-card-text>
                  </v-card>
                  <v-card class="ma-4 px-6 py-2" style="width: 800px">
                    <v-card-title>
                      Expectations
                    </v-card-title>
                    <v-card-text class="d-flex flex-column">
                      <span class="py-2"><span class="font-weight-bold">{{ updatedClient.expectations }}</span></span>
                    </v-card-text>
                  </v-card>
                  <v-card class="ma-4 px-6 py-2" style="width: 800px">
                    <v-card-title>
                      Experience
                    </v-card-title>
                    <v-card-text class="d-flex flex-column">
                      <span class="py-2"><span class="font-weight-bold">{{ updatedClient.experience }}</span></span>
                    </v-card-text>
                  </v-card>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    @click="openDialogEditClientBasic"
                    class="mx-4 mb-4"
                  >
                    Edit Info
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>

              <!-- files upload section -->
              

              <v-card flat class="d-flex flex-row flex-wrap justify-center justify-md-start">

                <!-- CV -->

                <v-card 
                  class="d-flex flex-column align-start my-1 ma-sm-3 ma-md-5 pa-5 md12 rounded-xl" 
                  min-width="400px"
                  @drop.prevent="onDrop($event, 'cv')"
                  @dragover.prevent="dragover = true"
                  @dragenter.prevent="dragover = true"
                  @dragleave.prevent="dragover = false"
                >
                  <v-card flat>
                    <h2 class="grey--text"><v-icon size="40" class="mr-2">mdi-text-box</v-icon>Curriculum Vitae</h2>
                  </v-card>
                  <v-card 
                    v-if="getUploadInfoType('cv').length > 0"
                    flat 
                    class="mx-auto my-5"
                  >
                    <v-list>
                      <v-list-item-group color="primary">
                        <v-list-item v-for="(file, index) in getUploadInfoType('cv')" :key="index" class="d-flex flex-row flex-wrap">
                          <span class="d-inline-block text-truncate" style="max-width: 180px;">{{ file.name }}</span>
                          <div>
                            <v-btn
                              color="ppc_pink"
                              dark
                              x-small
                              fab
                              class="mx-2 my-0"
                              @click="downloadFile(file.id)"
                            >
                              <v-icon>
                                mdi-download
                              </v-icon>
                            </v-btn>
                            <v-btn
                              color="ppc_pink"
                              dark
                              x-small
                              fab
                              class="mx-2 my-0"
                              @click="deleteFile(file.id)"
                            >
                              <v-icon>
                                $vuetify.icons.icon_mdi_delete
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <div v-if="getUploadInfoType('cv')[0].approved" class="align-self-center">
                      <v-icon color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                      <strong>cv approved!</strong>
                    </div>
                  </v-card>
                  <v-card flat class="d-flex flex-column align-self-center" v-else>
                    <v-card flat width="320px">
                      <v-file-input 
                        :rules="fileRules"
                        small-chips 
                        show-size 
                        label="Select or Drop docx or pdf" 
                        @change="selectFile($event, 'cv')"
                        accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        :disabled="selectFileCV"
                      ></v-file-input>
                    </v-card>
                    <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
                      mdi-cloud-upload
                    </v-icon>
                  </v-card>
                  <v-menu 
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    v-if="getUploadInfoType('cv').length > 0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                        :value="formattedDate(getUploadInfoType('cv')[0].approved)" 
                        label="Date of approval" 
                        v-on='on' 
                        v-bind="attrs"
                        prepend-icon="$vuetify.icons.icon_mdi_calendar ppc_darkgreen--text"
                        clearable
                        readonly
                        @click:clear="approveUpload(getUploadInfoType('cv')[0].id, null)"
                      ></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="approvalDateCv"
                      color='ppc_darkgreen'
                      scrollable
                      @change="approveUpload(getUploadInfoType('cv')[0].id, approvalDateCv)">
                    </v-date-picker>
                  </v-menu>
                </v-card>

                <!-- Passport -->

                <v-card 
                  class="d-flex flex-column align-start my-1 my-1 ma-sm-3 ma-md-5 pa-5 rounded-xl" 
                  min-width="400px"
                  @drop.prevent="onDrop($event, 'passport')"
                  @dragover.prevent="dragover = true"
                  @dragenter.prevent="dragover = true"
                  @dragleave.prevent="dragover = false"
                >
                  <v-card flat>
                    <h2 class="grey--text"><v-icon size="40" class="mr-2">mdi-passport</v-icon>Passport Scan</h2>
                  </v-card>
                  <v-card v-if="getUploadInfoType('passport').length > 0" flat class="mx-auto my-5">
                    <v-list>
                      <v-list-item-group color="primary">
                        <v-list-item v-for="(file, index) in getUploadInfoType('passport')" :key="index" class="d-flex flex-row flex-wrap">
                          <span class="d-inline-block text-truncate" style="max-width: 180px;">{{ file.name }}</span>
                          <div>
                            <v-btn
                              color="ppc_pink"
                              dark
                              x-small
                              fab
                              class="mx-2 my-0"
                              @click="downloadFile(file.id)"
                            >
                              <v-icon>
                                mdi-download
                              </v-icon>
                            </v-btn>
                            <v-btn
                              color="ppc_pink"
                              dark
                              x-small
                              fab
                              class="mx-2 my-0"
                              @click="deleteFile(file.id)"
                            >
                              <v-icon>
                                $vuetify.icons.icon_mdi_delete
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <div v-if="getUploadInfoType('passport')[0].approved" class="align-self-center">
                      <v-icon color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
                      <strong>passport approved!</strong>
                    </div>
                  </v-card>
                  <div class="d-flex flex-column align-self-center" v-else>
                    <v-card flat width="320px">
                      <v-file-input 
                        :rules="fileRules"
                        small-chips 
                        show-size 
                        label="Select or Drop jpeg, png or pdf file" 
                        @change="selectFile($event, 'passport')"
                        accept=".pdf,image/*"
                        :disabled="selectFilePassport"
                      >
                      </v-file-input>
                    </v-card>
                    <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
                      mdi-cloud-upload
                    </v-icon>
                  </div>
                  <v-menu 
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    v-if="getUploadInfoType('passport').length > 0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                        :value="formattedDate(getUploadInfoType('passport')[0].approved)" 
                        label="Date of approval" 
                        v-on='on' 
                        v-bind="attrs"
                        prepend-icon="$vuetify.icons.icon_mdi_calendar ppc_darkgreen--text"
                        clearable
                        readonly
                        @click:clear="approveUpload(getUploadInfoType('passport')[0].id, null)"
                      ></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="approvalDatePassport"
                      color='ppc_darkgreen'
                      scrollable
                      @change="approveUpload(getUploadInfoType('passport')[0].id, approvalDatePassport)">
                    </v-date-picker>
                  </v-menu>
                </v-card>

                <!-- Other uploads -->

                <v-card 
                  class="d-flex flex-column align-start my-1 my-1 ma-sm-3 ma-md-5 pa-5 rounded-xl" 
                  min-width="400px"
                  @drop.prevent="onDrop($event, 'other')"
                  @dragover.prevent="dragover = true"
                  @dragenter.prevent="dragover = true"
                  @dragleave.prevent="dragover = false"
                >
                  <v-card flat>
                    <h2 class="grey--text"><v-icon size="40" class="mr-2">mdi-upload</v-icon>Other Uploads</h2>
                  </v-card>
                  <v-card flat class="mx-auto my-5">
                    <v-list>
                      <v-list-item-group color="primary">
                        <v-list-item v-for="(file, index) in getUploadInfoType('other')" :key="index" class="d-flex flex-row flex-wrap">
                          <span class="d-inline-block text-truncate" style="width: 180px;">{{ file.name }}</span>
                          <div>
                            <v-btn
                              color="ppc_pink"
                              dark
                              x-small
                              fab
                              class="mx-2 my-0"
                              @click="downloadFile(file.id)"
                            >
                              <v-icon>
                                mdi-download
                              </v-icon>
                            </v-btn>
                            <v-btn
                              color="ppc_pink"
                              dark
                              x-small
                              fab
                              class="mx-2 my-0"
                              @click="deleteFile(file.id)"
                            >
                              <v-icon>
                                $vuetify.icons.icon_mdi_delete
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                  <div class="d-flex flex-column align-self-center">
                    <v-card flat width="320px">
                      <v-file-input 
                        :rules="fileRules"
                        small-chips 
                        show-size 
                        label="Select or Drop a file" 
                        @change="selectFile($event, 'other')"
                        :disabled="selectFileOther"
                      >
                      </v-file-input>
                    </v-card>
                    <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
                      mdi-cloud-upload
                    </v-icon>
                  </div>
                </v-card>

                

                <!-- Spanish Test -->

                <v-card class="d-flex flex-column my-1 my-1 ma-sm-3 ma-md-5 pa-5 rounded-xl" width="320px">
                  <v-card flat>
                    <h2 class="grey--text"><v-icon size="40" class="mr-2">mdi-file-document-edit</v-icon>Spanish Test</h2>
                  </v-card>
                  <v-spacer></v-spacer>
                  <v-card flat class="align-self-center">
                    <v-menu 
                      transition="scale-transition"
                      offset-y
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                          :value="formattedDate(updatedClient.spanishTestDone)" 
                          label="Select test date" 
                          v-on='on' 
                          v-bind="attrs"
                          prepend-icon="$vuetify.icons.icon_mdi_calendar ppc_darkgreen--text"
                          clearable
                          readonly
                          @click:clear="updatedClient.spanishTestDone = null"
                          @change="saveClient()"
                        ></v-text-field>
                      </template>
                      <v-date-picker 
                        v-model='updatedClient.spanishTestDone'
                        color='ppc_darkgreen'
                        scrollable
                        @change="saveClient()"
                      >
                      </v-date-picker>
                    </v-menu>
                    <v-select
                      style="width: 250px"
                      v-model="updatedClient.initial_spanish_level"
                      :items="$store.state.spanish_levels"
                      item-text="name"
                      item-value="id"
                      :menu-props="{ auto: true }"
                      label="initial level"
                      single-line
                      prepend-icon="mdi-star ppc_darkgreen--text"
                      validate-on-blur
                      clearable
                      @change="saveClient()"
                    ></v-select>
                  </v-card>
                </v-card>
              </v-card>

              <v-card flat>
                <v-card-text>
                  <v-card class="d-flex flex-column my-4 rounded-xl">
                    <v-card flat class="d-flex flex-row flex-wrap">
                      <span class="pa-6" style="width: 250px">
                        <h2 class="grey--text mt-1">
                          Next Contact
                        </h2>
                        <v-card-subtitle>{{ formattedDate(updatedClient.autoContact, 'date') }}</v-card-subtitle>
                        <v-menu 
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                              <v-text-field 
                                :value="formattedDate(updatedClient.nextContact)"
                                label="Override" 
                                v-on='on' 
                                v-bind="attrs"
                                prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                clearable
                                readonly
                                @change="saveClient()"
                                @click:clear="updatedClient.nextContact=null"
                              ></v-text-field>
                          </template>
                          <v-date-picker 
                            v-model='updatedClient.nextContact'
                            color='ppc_darkgreen'
                            scrollable
                            @change="saveClient()"
                          >
                          </v-date-picker>
                        </v-menu>
                      </span>
                      <span class="pa-6 " style="width: 250px">
                        <div class="d-flex flex-column">
                          <h2 class="grey--text"> 
                            <v-btn 
                              fab @click="sentWelcomeEmail(addPayment)" 
                              color="blue" elevation="2" 
                              x-small 
                              :disabled="checkBoolean(updatedClient.ignore_welcome_email)"
                            >
                              <v-icon size="20" color="white">mdi-email</v-icon>
                            </v-btn>
                              Welcome Email
                          </h2>
                          <span>
                            <v-radio-group
                                row
                                v-model="school_welcome_email"
                                class="mt-2"
                            >
                                <v-radio
                                    label="Spanish School"
                                    value="spanish"
                                    class="my-0 py-0"
                                ></v-radio>
                                <v-radio
                                    label="English School"
                                    value="english"
                                    class="my-0 py-0"
                                ></v-radio>
                            </v-radio-group>
                            <v-checkbox 
                              v-model="updatedClient.ignore_welcome_email"
                              label="Ignore"
                              @change="saveClient()"
                              class="mt-0 pt-0 mr-2"
                              color="blue"
                            ></v-checkbox>
                            <v-checkbox 
                              v-model="addPayment"
                              label="Add Payment"
                              class="mt-0 pt-0 mr-2"
                              color="blue"
                              :disabled="checkBoolean(updatedClient.ignore_welcome_email) || school_welcome_email === 'spanish'"
                            ></v-checkbox>
                            <v-checkbox 
                              v-model="updatedClient.includeHandbook"
                              label="Include Handbook"
                              class="mt-0 pt-0 mr-2"
                              color="blue"
                              :disabled="checkBoolean(updatedClient.ignore_welcome_email) || school_welcome_email === 'spanish'"
                            ></v-checkbox>
                          </span>
                          <v-menu 
                            transition="scale-transition"
                            offset-y
                            :disabled="checkBoolean(updatedClient.ignore_welcome_email)"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field 
                                :value="formattedDate(updatedClient.welcome_email)"
                                label="Sent date" 
                                v-on='on' 
                                v-bind="attrs"
                                prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                clearable
                                @click:clear="updatedClient.welcome_email=null"
                                @change="saveClient()"
                                :disabled="checkBoolean(updatedClient.ignore_welcome_email)"
                              ></v-text-field>
                            </template>
                            <v-date-picker 
                              v-model='updatedClient.welcome_email'
                              color='ppc_darkgreen'
                              scrollable
                              @change="saveClient()"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </span>
                      <span class="pa-6" style="width: 250px">
                        <h2 class="grey--text mt-1">
                          Handbook
                        </h2>
                        <v-card flat class="align-self-center">
                          <v-menu 
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field 
                                :value="formattedDate(updatedClient.handbookSent)" 
                                label="Sent date" 
                                v-on='on' 
                                v-bind="attrs"
                                prepend-icon="$vuetify.icons.icon_mdi_calendar"
                                readonly
                                @change="saveClient()"
                                @click:clear="updatedClient.handbookSent=null"
                                clearable
                              ></v-text-field>
                            </template>
                            <v-date-picker 
                              v-model='updatedClient.handbookSent'
                              color='ppc_darkgreen'
                              scrollable
                              @change="saveClient()"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-card>
                        <span class="mx-2" v-if="updatedClient.sendHandbook === 0">Responsibility Agency</span>
                      </span>
                      <span class="pa-6" style="width: 250px">
                        <h2 class="grey--text mt-1"> 
                          First Schedule
                        </h2>
                        <v-menu 
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                              :value="formattedDate(updatedClient.first_schedule_sent)"
                              label="Sent date"
                              v-on='on' 
                              v-bind="attrs"
                              prepend-icon="$vuetify.icons.icon_mdi_calendar"
                              clearable
                              @change="saveClient()"
                              @click:clear="updatedClient.first_schedule_sent=null"
                            ></v-text-field>
                          </template>
                          <v-date-picker 
                            v-model='updatedClient.first_schedule_sent'
                            color='ppc_darkgreen'
                            scrollable
                            @change="saveClient()"
                          >
                          </v-date-picker>
                        </v-menu>
                      </span>
                      <span class="pa-6" style="width: 250px">
                        <div class="d-flex flex-row flex-wrap">
                          <h2 class="grey--text"> 
                            <v-btn 
                              fab @click="sentThankYouEmail()" 
                              color="blue" elevation="2" 
                              x-small 
                              :disabled="checkBoolean(updatedClient.ignore_thank_you_email)"
                            >
                              <v-icon size="20" color="white">mdi-email</v-icon>
                            </v-btn>
                            Thank You Email
                          </h2>
                          <v-checkbox 
                              v-model="updatedClient.ignore_thank_you_email"
                              label="ignore"
                              class="mt-6 pt-0 mr-2"
                              color="blue"
                              @change="saveClient()"
                          ></v-checkbox>
                        </div>
                        <v-menu 
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                              :value="formattedDate(updatedClient.thank_you_email)" 
                              label="Sent date" 
                              v-on='on' 
                              v-bind="attrs"
                              prepend-icon="$vuetify.icons.icon_mdi_calendar"
                              clearable
                              @change="saveClient()"
                              @click:clear="updatedClient.thank_you_email=null"
                              :disabled="checkBoolean(updatedClient.ignore_thank_you_email)"
                            ></v-text-field>
                          </template>
                          <v-date-picker 
                            v-model='updatedClient.thank_you_email'
                            color='ppc_darkgreen'
                            scrollable
                            @change="saveClient()"
                            :disabled="checkBoolean(updatedClient.ignore_thank_you_email)"
                          >
                          </v-date-picker>
                        </v-menu>
                      </span>
                    </v-card>
                  </v-card>
                </v-card-text>
              </v-card>
            
            <v-card flat elevation="3" class="pa-5 rounded-xl mr-4">
              <v-card-title>Security Deposits USD</v-card-title>
              <v-card-actions>
                <v-btn x-small @click="goFinancial('add')">receive Security Deposit</v-btn>
                <v-btn x-small @click="goFinancial('remove')">return Security Deposit</v-btn>
              </v-card-actions>
              <v-data-table
                :headers="headersSecurityDeposits"
                hide-default-header
                :items="securityDeposits"
                sort-by="dateTime"
                dense
                :items-per-page="-1"
                disable-pagination
                hide-default-footer
                v-if="securityDeposits.length > 0"
              >
                <template v-slot:[`item.dateTime`]="{ item }">
                    <span v-html="formattedDate(item.dateTime, 'date')"></span>
                </template>
              </v-data-table>
              <v-card flat class="d-flex flex-row flex-wrap">
                <div>
                  <v-card-text v-if="!updatedClient.ignore_security_deposit">Total: {{ updatedClient.totalSecurityDeposit }} USD</v-card-text>
                </div>
                <v-card-actions> 
                  <v-checkbox
                    v-model="updatedClient.ignore_security_deposit"
                    label="Ignore"
                    class="mt-2 py-0"
                    @change="saveClient()"
                  ></v-checkbox>
                </v-card-actions>
              </v-card>
            </v-card>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="confirmDeleteClientDialog=true" class="mr-4">
                <v-icon color="ppc_darkgreen darken-1" class="mx-1">$vuetify.icons.icon_mdi_delete</v-icon>
                Remove Client
              </v-btn>
              <v-btn @click="cancelClient" class="mr-4" v-if="updatedClient.isLead === 0">
                <v-icon color="ppc_darkgreen darken-1" class="mx-1">$vuetify.icons.icon_mdi_account_cancel</v-icon>
                Cancel Client
              </v-btn>
              <v-btn @click="copyClient" class="mr-4">
                <v-icon color="ppc_darkgreen darken-1" class="mx-1">$vuetify.icons.icon_mdi_account_box_multiple_outline</v-icon>
                Copy Client
              </v-btn>
              <v-btn @click="contactNoResponse" class="mr-4" v-if="updatedClient.isLead === 1">
                <v-icon color="ppc_darkgreen darken-1" class="mx-1">mdi-phone-cancel</v-icon>
                Contact No Conversion
              </v-btn>
              <!-- <v-spacer></v-spacer>
              <v-btn
                @click="$store.dispatch('setLoadingApp', 1), getClientId()"
                class="mr-4"
              >
                Revert Changes
              </v-btn>
              <v-btn
                @click="saveClient()"
                class="mr-4"
              >
                Save Changes
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <!-- Tab Services -->
        <v-tab-item>
          <v-card
            class="mb-4"
            height="auto"
            flat
          >
            <v-card-title class="card-title">
              <!-- <v-btn color="brown" @click="synchroniseClientPM()" class="white--text" :loading="loadingButtonSynchronise">Synchronise Apps</v-btn> -->
              <v-spacer></v-spacer>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="ppc_pink"
                    v-bind="attrs"
                    v-on="on"
                    dark
                    small
                    fab
                    class="mx-2 my-0"
                    @click="dialog_add_type = true"
                  >
                    <v-icon 
                      v-on="on"
                    >
                      $vuetify.icons.icon_mdi_plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Add a service</span>
              </v-tooltip>

              <v-dialog v-model="dialog_add_type" :retain-focus="false" max-width="320px">
                <v-card>
                  <v-card-title>
                    <span>Add a service</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-list rounded>
                      <v-list-item-group v-model="type" color="primary">
                        <v-list-item
                          v-for="(type, i) in $store.state.types"
                          :key="i"
                          @click="create_select_items(type.id)"
                        >
                          <div v-if="type.id !== 0" class="d-flex flex-row flex-wrap">
                            <v-list-item-icon>
                              <v-icon v-text="type.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title v-text="type.text"></v-list-item-title>
                            </v-list-item-content>
                          </div>
                        </v-list-item>
                        <v-list-item
                          @click="create_select_items('agency')"
                          v-if="updatedClient.agency_id"
                        >
                          <v-list-item-icon>
                            <v-icon>$vuetify.icons.icon_mdi_domain</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="updatedClient.agency"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="ppc_darkgreen darken-1" text @click="dialog_add_type = false">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog 
                v-model="dialog_selectService"
                :retain-focus="false"
              >
                <v-card class="ppc_grey pt-2">
                  <v-card-text>
                    <v-row dense>
                      <v-col
                        v-for="select_item in select_items"
                        :key="select_item.service_number"
                        cols="12"
                        md="4"
                        sm="6"
                        class="d-flex flex-column"
                      >
                        <v-card class="elevation-3 flex d-flex flex-column">
                          <v-card-text class="flex ma-0 pa-0">
                            <v-img
                              :src="getImage(select_item.image)"
                              class="white--text align-end"
                              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                              max-width="300px"
                            >
                              <v-card-title class="white--text">
                                {{ select_item.name }} 
                                <v-spacer></v-spacer>
                                <v-btn
                                  v-if="select_item.info_link" 
                                  fab 
                                  color="ppc_pink" 
                                  x-small 
                                  :href="select_item.info_link" 
                                  target="_blank"
                                  absolute
                                  right
                                  top
                                  class="mt-7"
                                >
                                  <v-icon color="white">$vuetify.icons.icon_mdi_information_variant</v-icon>
                                </v-btn>
                              </v-card-title>
                            </v-img>
                            <v-row no-gutters class="ma-4">
                              <v-col cols="12" class="my-4 py-0">
                                <span v-if="select_item.price"> <span class="black--text display-1"> {{select_item.currency}} {{select_item.price}} </span> {{select_item.price_desc}} </span>
                                <span class="pos_absolute"><v-icon x-large v-if="select_item.promo_text" class="mt-4"> $vuetify.icons.icon_sale </v-icon></span>
                                <span v-if="select_item.promo_text" class="font-weight-black button ml-12"> {{select_item.promo_text}} </span>
                                <v-divider></v-divider>
                              </v-col>
                              <v-col cols="12" class="my-4 py-0" v-if="select_item.min_duration">
                                <v-card-subtitle class="ppc_darkgreen--text mb-0 pb-0 ml-0 pl-0">Minimal duration</v-card-subtitle>
                                <span>{{select_item.min_duration}} {{select_item.duration_text}} </span>
                                <v-divider></v-divider>
                              </v-col>
                              <v-col cols="12" class="my-4 py-0" v-if="select_item.duration_type === 'fixed'">
                                <v-card-subtitle class="ppc_darkgreen--text mb-0 pb-0 ml-0 pl-0">Duration</v-card-subtitle>
                                <span> {{select_item.duration}} {{select_item.duration_text}} </span>
                                <v-divider></v-divider>
                              </v-col>
                              <v-col cols="12" class="my-4 py-0" v-if="select_item.min_level_spanish">
                                <v-card  
                                  flat
                                >
                                  <v-card-subtitle class="ppc_darkgreen--text mb-0 pb-0 ml-0 pl-0">Initial Spanish level</v-card-subtitle>
                                  <v-card-text> {{select_item.min_level_spanish}} </v-card-text>
                                  <v-divider></v-divider>
                                </v-card>
                              </v-col>
                              <v-col  v-if="select_item.incl" cols="12" class="my-1 py-0">
                                <v-card
                                  flat
                                >
                                  <v-card-subtitle class="ppc_darkgreen--text my-0 pa-0 pt-2">Includes</v-card-subtitle>
                                  <v-card-text class="pb-0" v-html="select_item.incl"></v-card-text>
                                  <v-divider></v-divider> 
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="ppc_pink" text ripple @click="selectService(select_item.service_number)">Select</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialog_selectService=false">Cancel</v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-dialog>
              
            </v-card-title>
            <v-card-text>
              <v-card flat class="d-flex flex-column">
                <v-btn 
                  class="my-5 align-self-center" 
                  @click="applyServices()"
                  :disabled="noExistsServices"
                >save & apply services</v-btn>
              </v-card>
              <v-expansion-panels
                hover
                focusable
                v-model="openPanel"
                :key="rendererKeyPanels"
                flat
              >
                <v-expansion-panel
                  v-for="(service, indexServices) in updatedServices"
                  :key="indexServices"
                >
                  <v-card flat class="d-flex flex-column pa-5 mt-3 rounded-xl" v-if="service.application_id !== null && checkNewApp(indexServices)" color="brown lighten-5">
                    <v-card flat class="d-flex flex-row flex-wrap justify-space-between" color="brown lighten-5">
                      <div>
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>
                              <h2>{{ service.appTitle }}</h2>
                            </v-list-item-title>
                            <v-list-item-subtitle>Applied: {{ formattedDate(service.applied) }}</v-list-item-subtitle>
                            <v-list-item-subtitle>Amount of clients: {{ service.amountOfClients }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="service.payerClientName">Payer Client: {{ service.payerClientName }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="service.payerAgencyName">Payer Agency: {{ service.payerAgencyName }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                      <div v-if="service.statusAppId !== 99">
                        <v-chip :color="getStatusApp(service.statusAppId).color" class="ma-4" text-color="white" v-html="getStatusApp(service.statusAppId).name"></v-chip>                      
                      </div>
                    </v-card>
                    <v-card flat class="d-flex flex-row flex-wrap" color="brown lighten-5">
                      <div>
                        <v-card flat class="pa-6 ma-2 rounded-xl">
                          <v-card-title v-if="service.totalPrice">
                            Price: ${{ parseFloat(service.totalPrice).toFixed(2) }}
                          </v-card-title>
                          <v-card-subtitle v-if="service.totalPrice && service.exchangeRate">
                            ({{ parseFloat(service.totalPrice*service.exchangeRate).toFixed(2) }} PEN)
                          </v-card-subtitle>
                          <v-card-subtitle v-if="service.amountPaidApplication">Paid: ${{ parseFloat(service.amountPaidApplication).toFixed(2) }}</v-card-subtitle>
                          <v-card-text class = "mt-3">
                            <div v-if="service.depositTotal && service.depositTotal !== null">Deposit: {{ parseFloat(service.depositTotal).toFixed(2) }} USD</div>
                            <div v-if="service.ignore_deposit || service.depositPaid">Passed to Planning</div>
                          </v-card-text>
                          <hr>
                          <v-card-subtitle v-if="(parseFloat(Math.abs((service.totalPrice - service.amountPaidApplication))).toFixed(2) > 0.00)">Open: <FONT COLOR=red><strong>${{ parseFloat(service.totalPrice - service.amountPaidApplication).toFixed(2) }}</strong></FONT></v-card-subtitle>

                          <v-card-subtitle v-else><FONT COLOR=green><strong>PAID</strong></FONT></v-card-subtitle>
                        </v-card>
                      </div>
                      <div>
                        <v-card flat class="pa-2 ma-2 rounded-xl" v-if="service.fixedPrice || service.discount || service.refund || service.extraChargeUSD">
                          <v-card-text>
                            <div v-if="service.fixedPrice"><span class="grey--text">Fixed at:</span> {{ parseFloat(service.fixedPrice).toFixed(2) }} USD</div>
                            <div v-if="service.discount"><span class="grey--text">Discount:</span> {{ parseFloat(service.discount).toFixed(2) }} USD</div>
                            <div v-if="service.refund"><span class="grey--text">Refund:</span> {{ parseFloat(service.refund).toFixed(2) }} USD</div>
                            <div v-if="service.extraChargeUSD"><span class="grey--text">Extra Charge:</span> {{ parseFloat(service.extraChargeUSD).toFixed(2) }} USD</div>
                          </v-card-text>
                        </v-card>
                      </div>
                      <div>
                        <v-card flat class="pa-6 ma-2 rounded-xl">
                          <v-card-title>
                            PackageMaker
                          </v-card-title>
                          <v-card-text>
                            <!-- <v-chip small :color="getStatusApp(service.maxCurrentStatus).color" class="white--text mt-4">{{getStatusApp(service.maxCurrentStatus).name}}</v-chip> -->
                            <v-switch
                                v-model="service.syncPM"
                                label="synchronise"
                                color="brown"
                                class="mx-4"
                                @change="changeSynchronise($event, service.application_id)"
                                :disabled="service.payerClient === null && service.payerAgency === null"
                            ></v-switch>
                          </v-card-text>
                        </v-card>
                      </div>
                      <div>
                        <v-card flat class="pa-6 ma-2 rounded-xl" v-if="service.frozen===1">
                          <v-card-title>
                            Application Frozen
                          </v-card-title>
                        </v-card>
                      </div>
                      <div>
                        <v-card flat class="pa-2 ma-2 rounded-xl" v-if="service.comments">
                          <v-card-subtitle class="grey--text">
                            Invoice Comments:
                          </v-card-subtitle>
                          <v-card-text>
                            <span>{{ service.comments }}</span>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-card>
                    <v-card flat class="d-flex flex-row flex-wrap" color="brown lighten-5">
                      <div class="caption grey--text mt-10">
                          Nr: {{ service.application_id }}
                      </div>
                      <v-spacer></v-spacer>
                      <div class="d-flex flex-row flex-wrap">
                        <v-btn color="brown lighten-1" :disabled="$store.state.loadingApp === 1" @click="showInvoice(service.application_id)" class="ma-4">
                          <v-icon color="white">$vuetify.icons.icon_mdi_cogs</v-icon>
                          <span class="white--text">Create Invoice</span>
                        </v-btn>
                        <v-btn color="brown lighten-1" :disabled="$store.state.loadingApp === 1" @click="goView('Application', service.application_id)" class="ma-4">
                          <v-icon color="white">$vuetify.icons.icon_mdi_pencil</v-icon>
                          <span class="white--text">Edit Application</span>
                        </v-btn>
                      </div>
                    </v-card>
                  </v-card>

                  <v-expansion-panel-header
                    class="px-0 px-sm-6 rounded-xl" color="grey lighten-5"
                  >
                    <v-card flat :disabled="service.frozen===1" color="grey lighten-5" :id="'scroll-id-' + service.id">
                      <div v-if="service.service_number !== null" class="d-flex flex-row flex-wrap justify-space-between mb-4 mx-4" color="grey lighten-5">
                        <h3 class="ppc_darkgreen--text mt-1"><v-icon class="mr-4"> {{ service.icon }} </v-icon>{{ service.name }}</h3>
                        <v-card color="grey lighten-5" flat v-if="service.autoStatus">
                          <v-chip small :color="getStatusService(service.autoStatus).color" class="white--text mt-2"> {{getStatusService(service.autoStatus).name}} </v-chip>
                        </v-card>
                      </div>
                      <v-card
                        key="1"
                        color="grey lighten-5"
                        class="d-flex flex-row flex-wrap mb-4 mx-4"
                      >
                        <div class="ma-4">
                          <div class="font-italic ma-2">From: {{ formattedDate(service.start) || '(to be planned)' }}</div>
                          <div class="font-italic ma-2">To: {{ formattedDate(service.end) || '(to be planned)' }}</div>
                          <div class="font-italic ma-2">Duration: {{ service.duration || 'Not set' }} {{ service.duration_text }}</div>
                        </div>
                        <div class="ma-4">
                          <div class="ma-2">
                            Price:
                            <span v-if="service.serviceNotChanged">
                              <span v-if="service.servicePrice">&nbsp;{{ service.currency }}{{ parseFloat(service.servicePrice).toFixed(2)  || 'Not set' }} </span>
                              <span v-if="service.currency === 'S/.'">
                                (${{ priceInDollars(service.servicePrice) }})
                              </span>
                            </span>
                            <span v-else>save service to calculate</span>
                          </div>
                          <div v-if="(service.agency !== null || !service.agency) && service.deposit > 0" class="ma-2">
                            <span v-if="service.serviceNotChanged"><span>Deposit:</span> ${{ parseFloat(service.deposit).toFixed(2) }}</span>
                            <span v-else><span>Deposit:</span> save service to calculate</span>
                          </div>
                        </div>
                      </v-card>
                      <div flat class="d-flex flex-row flex-wrap justify-space-between mx-4">
                        <v-card 
                          flat  
                          :disabled = "$store.state.loadingApp === 1"
                          color="grey lighten-5"
                        >
                          <v-tooltip
                            right
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn 
                                icon
                                @click.native.stop="serviceIdToDelete = service.id, confirmDeleteServiceDialog = true"
                                class="mx-1"
                              >
                                <v-icon 
                                  color="ppc_darkgreen darken-1" 
                                  v-bind="attrs"
                                  v-on="on" 
                                  text
                                >
                                    $vuetify.icons.icon_mdi_delete
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Delete the service</span>
                          </v-tooltip>
                          <v-tooltip
                            right
                            v-if="service.service_number > 0"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn 
                                icon
                                @click.native.stop="prepSelectAppMove(service.id)"
                                class="mx-1"
                              >
                                <v-icon 
                                  color="ppc_darkgreen darken-1" 
                                  v-bind="attrs"
                                  v-on="on" 
                                  text 
                                >
                                    mdi-file-replace
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Move the service</span>
                          </v-tooltip>
                          <!-- <v-tooltip
                            right
                            v-if="service.service_number > 0"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn 
                                icon
                                @click.native.stop="prepSelectAppMove(service.id)"
                                class="mx-1"
                              >
                                <v-icon 
                                  color="ppc_darkgreen darken-1" 
                                  v-bind="attrs"
                                  v-on="on" 
                                  text 
                                >
                                    mdi-file-multiple
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Copy the service</span>
                          </v-tooltip> -->
                          <v-tooltip
                            right
                            v-if="service.service_number > 0"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn 
                                icon
                                @click.native.stop="serviceIdToMove = service.id, prepMoveService($event)"
                                class="mx-1"
                                :disabled="service.application_id === null"
                              >
                                <v-icon 
                                  color="ppc_darkgreen darken-1" 
                                  v-bind="attrs"
                                  v-on="on" 
                                  text 
                                >
                                    mdi-file-remove
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Unapply this service</span>
                          </v-tooltip>
                        </v-card>
                        <v-card flat color="grey lighten-5">
                          <v-btn
                            :disabled="service.serviceNotChanged"
                            @click.native.stop="revertService(indexServices)"
                            small
                            class="mr-4"
                          >
                            Revert Changes
                          </v-btn>
                          <v-btn
                            :disabled="service.serviceNotChanged"
                            @click.native.stop="saveService(indexServices)"
                            small
                            class="mr-4"
                          >
                            Save Changes
                          </v-btn>
                        </v-card>
                      </div>
                    </v-card>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>

                    <!-- image & description -->
                    <v-card
                      flat
                      class="mx-0 my-3 mx-sm-3 float-left rounded-xl"
                      max-width="250px"
                      color="#eee"
                      
                    >
                      <v-card-text>
                        <v-img
                          :src="getImage(service.image)"
                          class="white--text align-end mb-5"
                          height="100%"
                          width="100%"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        >
                          <v-btn 
                            v-if="service.info_link" 
                            fab 
                            color="ppc_pink" 
                            x-small 
                            :href="service.info_link"
                            target="_blank"
                            top
                            right
                            absolute
                            class="mt-7"
                          >
                            <v-icon color="white">$vuetify.icons.icon_mdi_information_variant</v-icon>
                          </v-btn>
                          <v-card-title v-text="service.name" class="white--text"></v-card-title>
                        </v-img>
                        <p v-if="service.min_duration">
                          <v-card-subtitle class="ppc_darkgreen--text">Minimal duration</v-card-subtitle>
                          <v-card-text> 
                            {{service.min_duration}} {{service.duration_text}} 
                            <v-divider></v-divider> 
                          </v-card-text>
                        </p>
                        <p v-if="service.min_level_spanish">
                          <v-card-subtitle class="ppc_darkgreen--text">Initial Spanish level</v-card-subtitle>
                          <v-card-text> {{service.min_level_spanish}} <v-divider></v-divider> </v-card-text>
                        </p>
                        <p v-if="service.duration_type === 'fixed'">
                          <v-card-subtitle class="ppc_darkgreen--text">Duration</v-card-subtitle>
                            <v-card-text>{{service.duration}} {{service.duration_text}} </v-card-text>
                            <v-divider></v-divider>
                        </p>
                        <div  v-if="service.desc_acc">
                          <p
                            flat
                          >
                            <v-card-subtitle class="ppc_darkgreen--text">Accommodation</v-card-subtitle>
                            <v-card-text class="pb-0" v-html="service.desc_acc"></v-card-text>
                          </p>
                          <v-divider></v-divider>
                        </div>
                        <div  v-if="service.desc_study">
                          <p
                            flat
                          >
                            <v-card-subtitle class="ppc_darkgreen--text">Study</v-card-subtitle>
                            <v-card-text class="pb-0" v-html="service.desc_study"></v-card-text>
                          </p>
                          <v-divider></v-divider>
                        </div>
                        <div  v-if="service.desc_project">
                          <p
                            flat
                          >
                            <v-card-subtitle class="ppc_darkgreen--text">Project</v-card-subtitle>
                            <v-card-text class="pb-0" v-html="service.desc_project"></v-card-text>
                          </p>
                          <v-divider></v-divider>
                        </div>
                        <div  v-if="service.desc_start">
                          <p
                            flat
                          >
                            <v-card-subtitle class="ppc_darkgreen--text">Start</v-card-subtitle>
                            <v-card-text class="pb-0" v-html="service.desc_start"></v-card-text>
                          </p>
                          <v-divider></v-divider>
                        </div>
                        <div  v-if="service.desc_incl">
                          <p
                            flat
                          >
                            <v-card-subtitle class="ppc_darkgreen--text">Includes</v-card-subtitle>
                            <v-card-text class="pb-0" v-html="service.desc_incl"></v-card-text>
                          </p>
                          <v-divider></v-divider>
                        </div>
                      </v-card-text>
                    </v-card>

                    <!-- Period & dates -->
                    <v-card 
                      elevation="3" 
                      class="mx-0 my-3 mx-sm-3 pa-4 float-left rounded-xl"
                      max-width="250px"
                      v-if="service.allowedStartDays"
                      :disabled="service.frozen===1"
                    >
                      <v-card-title>Period</v-card-title>
                      <v-card-text>
                        <v-menu
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          class="my-5"
                          max-width="100%"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="from"
                              v-bind="attrs"
                              prepend-icon="$vuetify.icons.icon_mdi_calendar"
                              :value="formattedDate(service.start)"
                              v-on="on"
                              :disabled="!service.service_number"
                              @click:clear="clearStartDate(indexServices), service.serviceNotChanged = false"
                              @click="changeAllowedStartDays(service.service_number), service.serviceNotChanged = false"
                              clearable
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="service.start"
                            no-title
                            scrollable
                            :allowed-dates="getStartDays"
                            @change="calcDatesServices('start', indexServices)"
                          >
                          </v-date-picker>
                        </v-menu>
                        <v-menu
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          class="my-5"
                          max-width="100%"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="until"
                              v-bind="attrs"
                              prepend-icon="$vuetify.icons.icon_mdi_calendar"
                              :value="formattedDate(service.end)"
                              v-on="on"
                              :disabled="disabledEndDate(service.id, indexServices)"
                              @click:clear="clearEndDate(indexServices), service.serviceNotChanged = false"
                              @click="changeAllowedEndDays(service.service_number), service.serviceNotChanged = false"
                              clearable
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="service.end"
                            no-title
                            scrollable
                            :allowed-dates="getEndDays"
                            :min="getMinEndDate(indexServices)"
                            :show-current="getMinEndDate(indexServices)"
                            @change="calcDatesServices('end', indexServices)"
                          >
                          </v-date-picker>
                        </v-menu>
                        <v-slider
                          v-model="service.duration"
                          step="1"
                          ticks
                          tick-size="4"
                          thumb-label="always"
                          :min="service.min_duration"
                          :max="service.week_slider"
                          :hint="service.duration_text"
                          persistent-hint
                          thumb-color="ppc_pink"
                          track-color="ppc_grey"
                          @change="calcDatesServices('duration', indexServices), service.serviceNotChanged = false"
                          :disabled="!service.service_number"
                          v-if="service.week_slider"
                          class="mt-8"
                        ></v-slider>
                      </v-card-text>
                    </v-card>

                    <!-- radiogroups in services -->

                    <v-card flat v-if="updatedRadioGroups_filtered(service.id).length > 0" :disabled="service.frozen===1">
                      <v-card
                        flat
                        v-for="(radio_group, j) in updatedRadioGroups_filtered(service.id)"
                        :key="j + radio_group.radio_group_id"
                        class="mx-0 my-3 mx-sm-3 float-left rounded-xl"
                      >
                        <v-card
                          elevation="3"
                          class="pa-4"
                        >
                          <v-card-title>
                            {{radio_group.radio_group_question}}
                            <v-spacer></v-spacer>
                            <v-btn 
                              v-if="radio_group.info_link" 
                              fab 
                              color="ppc_pink" 
                              x-small 
                              :href="radio_group.info_link"
                              target="_blank"
                              class="mx-2"
                            >
                              <v-icon color="white">$vuetify.icons.icon_mdi_information_variant</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-card-text>
                            <v-radio-group
                              v-model="radio_group.radio_group_value"
                              @change="calcDatesServices('duration', indexServices, radio_group), service.serviceNotChanged = false"
                              column
                            >
                              <v-radio
                                v-for="(radio_option, k) in radioOptions_filtered(radio_group)"
                                :key="k"
                                :label="radio_option.label"
                                :value="radio_option.label"
                                class="my-0 py-0"
                              ></v-radio>
                            </v-radio-group>
                          </v-card-text>
                        </v-card>
                      </v-card>
                    </v-card>
                        
                    <!-- options in services -->

                    <v-card 
                      elevation="3"
                      v-if="updatedOptions_filtered(service.id).length > 0"
                      class="mx-0 my-3 mx-sm-3 float-left rounded-xl"
                      :disabled="service.frozen===1"
                    >
                      <v-card-title>
                        Options
                      </v-card-title>
                      <v-card-text>
                        <v-card
                          flat
                          v-for="(option, j) in updatedOptions_filtered(service.id)"
                          :key="j + option.option_id"
                        >
                          <v-checkbox
                            v-model="option.selected"
                            :label="option.option_question"
                            @change="calcDatesServices('duration', indexServices), service.serviceNotChanged = false"
                            class="my-0 py-0"
                          ></v-checkbox>
                        </v-card>
                      </v-card-text>
                    </v-card>

                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                          dark
                          x-small
                          fab
                          class="mx-0 my-0"
                          @click="serviceIdToResource = service.id, dialog_add_resource = true"
                          absolute
                          bottom
                          left
                          :disabled="!service.serviceNotChanged"
                        >
                          <v-icon 
                            v-on="on"
                          >
                            $vuetify.icons.icon_mdi_plus
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Add a resource</span>
                    </v-tooltip>

                    <v-spacer></v-spacer>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                          dark
                          x-small
                          fab
                          class="mx-0 my-0"
                          @click="savePreset(service.service_number, service.id)"
                          absolute
                          bottom
                          right
                          :disabled="!service.serviceNotChanged"
                        >
                          <v-icon 
                            v-on="on"
                          >
                            mdi-content-save
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Save Preset</span>
                    </v-tooltip>

                  
                    <v-card 
                      flat 
                      class="d-flex flex-row float-left" 
                      v-for="(resource, indexResources) in resources" 
                      :key="indexResources"
                    >

                      <!-- resource Projects -->
                      
                      <v-card class="d-flex flex-row flex-wrap float-left mr-2 my-2 rounded-xl" style="max-width: 640px" color="blue lighten-2" v-if="(resource.type_id === 1 || resource.type_id === 2) && resource.service_id === service.id">
                        <v-card flat class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                          <v-card-title>
                            <span v-if="resource.type_id === 1" class=" white--text">Volunteer Placement #{{ resource.number_type }}</span> 
                            <span v-if="resource.type_id === 2" class=" white--text">Internship Placement #{{ resource.number_type }}</span>
                          </v-card-title>
                          <div v-if="service.statusResourceId !== null" class="align-self-end">
                            <v-chip :color="getStatusResource(resource.statusResourceId).color" class="ma-4" text-color="white" v-html="getStatusResource(resource.statusResourceId).name"></v-chip>
                          </div>
                        </v-card>
                        <v-card flat class="d-flex justify-space-around" color="blue lighten-2" style="width: 100%">
                          <v-btn 
                            small
                            :disabled="!resource.project_id || !resource.start || !resource.end || resource.statusResourceId === 8 || resource.statusResourceId === 9 "
                            @click="goProject(resource.id)"
                            class="ma-2"
                          >
                            <span v-if="resource.placementName">Planned: {{ resource.placementName }}</span>
                            <span v-else>Plan Now</span>
                          </v-btn>
                        </v-card>
                        <div class="d-flex flex-column" color="blue lighten-2" style="width: 100%">
                          <v-card-text>
                            <span flat class="d-flex flex-row flex-wrap">
                              <span class="d-flex flex-column mr-12 mb-4">
                                <div class="white--text">
                                  Category: <span v-if="resource.category_id">{{ getProjectCategory(resource.category_id) }}</span><span v-else>to be selected</span>
                                </div>
                                <div class="white--text">
                                  Project: <span v-if="resource.project_id">{{ getProjectName(resource.project_id) }}</span><span v-else>to be selected</span>
                                </div>
                                <div class="white--text">
                                  From: {{ resource.start }}
                                </div>
                                <div class="white--text">
                                  Until: {{ resource.end }}
                                </div>
                                <div class="white--text">
                                  Weeks: {{ resource.businessWeeks }}
                                </div>
                              </span>
                              <span class="d-flex flex-column mr-12 mb-4" v-if="resource.comments">
                                <div class="white--text" style="max-width: 150px">
                                  Comments: {{ resource.comments }}
                                </div>
                              </span>
                            </span>
                          </v-card-text>
                        </div>
                        <v-card-actions class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                          <v-btn 
                            color="white" 
                            text 
                            :disabled="resource.placement_id !== null || !service.serviceNotChanged"
                            @click="edit_resource(resource, service, indexResources), dialog_resource_projects = true"
                          >
                              Edit
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn 
                            @click.native.stop="resourceIdToDelete = resource.id, confirmDeleteResourceDialog = true"
                            class="mx-1"
                            :disabled="resource.placement_id !== null"
                            color="white"
                            text
                          >
                            Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>

                      <!-- resource Tours -->
                        
                      <v-card class="d-flex flex-row flex-wrap float-left mr-2 my-2 rounded-xl" style="max-width: 640px" color="blue lighten-2" v-if="resource.type_id === 5 && resource.service_id === service.id">
                        <v-card flat class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                          <v-card-title>
                            <span class=" white--text">Tour #{{ resource.number_type }}</span> 
                          </v-card-title>
                          <div v-if="service.statusResourceId !== null" class="align-self-end">
                            <v-chip :color="getStatusResource(resource.statusResourceId).color" class="ma-4" text-color="white" v-html="getStatusResource(resource.statusResourceId).name"></v-chip>
                          </div>
                        </v-card>
                        <v-card flat class="d-flex justify-space-around" color="blue lighten-2" style="width: 100%">
                          <v-btn 
                            small
                            :disabled="!resource.tour_id || resource.statusResourceId === 8 "
                            @click="goTour(resource.id)"
                            class="ma-2"
                          >
                            <span v-if="resource.placementTourName">Planned: {{ resource.placementTourName }}</span>
                            <span v-else>Plan Now</span>
                          </v-btn>
                        </v-card>
                        <div class="d-flex flex-column" color="blue lighten-2" style="width: 100%">
                          <v-card-text>
                            <span flat class="d-flex flex-row flex-wrap">
                              <span class="d-flex flex-column mr-12 mb-4">
                                <div class="white--text">
                                  Tour: <span v-if="resource.tour_id">{{ getTour(resource.tour_id).name }}</span><span v-else>to be selected</span>
                                </div>
                                <div class="white--text">
                                  From: {{ resource.start }}
                                </div>
                                <div class="white--text">
                                  Until: {{ resource.end }}
                                </div>
                              </span>
                              <span class="d-flex flex-column mr-12 mb-4" v-if="resource.comments">
                                <div class="white--text" style="max-width: 150px">
                                  Comments: {{ resource.comments }}
                                </div>
                              </span>
                            </span>
                          </v-card-text>
                        </div>
                        <v-card-actions class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                          <v-btn 
                            color="white" 
                            text 
                            :disabled="resource.tour_placement_id !== null || !service.serviceNotChanged"
                            @click="edit_resource(resource, service, indexResources), dialog_resource_tours = true"
                          >
                              Edit
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn 
                            @click.native.stop="resourceIdToDelete = resource.id, confirmDeleteResourceDialog = true"
                            class="mx-1"
                            :disabled="resource.tour_placement_id !== null"
                            color="white"
                            text
                          >
                            Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>

                      <!-- resource Accommodation -->

                      <v-card class="d-flex flex-row flex-wrap float-left mr-2 my-2 rounded-xl" style="max-width: 640px" color="blue lighten-2" v-if="resource.type_id === 4 && resource.service_id === service.id">
                        <v-card flat class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                          <v-card-title>
                            <span class=" white--text">Accommodation #{{ resource.number_type }}</span> 
                          </v-card-title>
                          <div v-if="service.statusResourceId !== null" class="align-self-end">
                            <v-chip :color="getStatusResource(resource.statusResourceId).color" class="ma-4" text-color="white" v-html="getStatusResource(resource.statusResourceId).name"></v-chip>
                          </div>
                        </v-card>
                        <v-card flat class="d-flex justify-space-around" color="blue lighten-2" style="width: 100%">
                          <v-btn 
                            small
                            :disabled="resource.statusResourceId === 8"
                            @click="goAccommodation(resource.id)"
                            class="mb-2"
                          >
                            <span v-if="resource.room_id">Planned</span>
                            <span v-else>Plan Now</span>
                          </v-btn>
                        </v-card>
                        <div class="d-flex flex-column" color="blue lighten-2" style="width: 100%">
                          <v-card-text>
                            <span flat class="d-flex flex-row flex-wrap">
                              <span class="d-flex flex-column mr-12 mb-4">
                                <div class="white--text">
                                  Type:
                                  <span v-if="resource.family == 0">Apartment</span>
                                  <span v-else>Family</span>
                                </div>
                                <div class="white--text">
                                  Class: <span v-if="resource.accommodation_class">{{ resource.accommodation_class }}</span><span v-else>to be selected</span>
                                </div>
                                <div class="white--text">
                                  From: {{ resource.start }}
                                </div>
                                <div class="white--text">
                                  Until: {{ resource.end }}
                                </div>
                                <div class="white--text">
                                  Nights: {{ resource.duration }}
                                </div>
                              </span>
                              <span class="d-flex flex-column mr-12 mb-4" v-if="resource.comments">
                                <div class="white--text" style="max-width: 150px">
                                  Comments: {{ resource.comments }}
                                </div>
                              </span>
                            </span>
                          </v-card-text>
                        </div>
                        <v-card-actions class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                          <v-btn 
                            color="white" 
                            text 
                            :disabled="resource.room_id !== null || !service.serviceNotChanged"
                            @click="edit_resource(resource, service, indexResources), dialog_resource_accommodation = true"
                          >
                              Edit
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn 
                            @click.native.stop="resourceIdToDelete = resource.id, confirmDeleteResourceDialog = true"
                            class="mx-1"
                            :disabled="resource.tour_placement_id !== null"
                            color="white"
                            text
                          >
                            Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>

                      <!-- resource Classes -->

                      <v-card class="d-flex flex-row flex-wrap float-left mr-2 my-2 rounded-xl" style="max-width: 640px" color="blue lighten-2" v-if="resource.type_id === 3 && resource.service_id === service.id">
                        <v-card flat class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                          <v-card-title class="white--text">
                            <span>Language Course  #{{ resource.number_type }}</span>
                          </v-card-title>
                          <!-- {{ resource.statusResourceId }}
                          <div v-if="service.statusResourceId !== null" class="align-self-end">
                            <v-chip :color="getStatusResource(resource.statusResourceId).color" class="ma-4" text-color="white" v-html="getStatusResource(resource.statusResourceId).name"></v-chip>
                          </div> -->
                        </v-card>
                        <v-card flat class="d-flex justify-space-around" color="blue lighten-2" style="width: 100%">
                          <v-btn 
                            small
                            :disabled="resource.hours === 0 || !resource.start || !resource.end || resource.statusResourceId === 8 || resource.statusResourceId === 9 "
                            @click="goTeaching(resource.id)"
                            class="mb-2"
                          >
                            <span v-if="resource.minutes_planned">{{ Math.round(((resource.minutes_planned / 60) * 2) / 2) }} hours planned</span>
                            <span v-else>Plan Now</span>
                          </v-btn>
                        </v-card>
                        <div class="d-flex flex-column" color="blue lighten-2" style="width: 100%">
                          <v-card-text>
                            <span flat class="d-flex flex-row flex-wrap">
                              <span class="d-flex flex-column mr-12 mb-4">
                                <div class="white--text">
                                  Language: {{ resource.class_language }}
                                </div>
                                <div class="white--text">
                                  Location: {{ resource.class_location }}
                                </div>
                                <div class="white--text">
                                  Type: {{ resource.class_group }} - {{ resource.class_type }}
                                </div>
                                <div class="white--text">
                                  Duration: {{ resource.hours }} hours
                                </div>
                                <div class="white--text">
                                  From: {{ resource.start }}
                                </div>
                                <div class="white--text">
                                  Until: {{ resource.end }}
                                </div>
                              </span>
                              <span class="d-flex flex-column mr-12 mb-4" v-if="resource.comments">
                                <div class="white--text" style="max-width: 150px">
                                  Comments: {{ resource.comments }}
                                </div>
                              </span>
                            </span>
                          </v-card-text>
                        </div>
                        <v-card-actions class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
                          <v-btn 
                            color="white" 
                            text 
                            :disabled="!service.serviceNotChanged"
                            @click="edit_resource(resource, service, indexResources), dialog_resource_teaching = true"
                          >
                              Edit
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn 
                            @click.native.stop="resourceIdToDelete = resource.id, confirmDeleteResourceDialog = true"
                            class="mx-1"
                            :disabled="resource.tour_placement_id !== null"
                            color="white"
                            text
                          >
                            Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-card>
                    <v-card-subtitle class="mt-2">Service id:{{ service.id }}</v-card-subtitle>
                  </v-expansion-panel-content>
                  <v-divider></v-divider>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-divider></v-divider>
      <v-card-actions>
        <v-card-subtitle>
          client nr: {{ $store.getters.client.id }}
        </v-card-subtitle>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog_add_resource" :retain-focus="false" max-width="320px">
      <v-form ref="dialogForm">
        <v-card>
            <v-card-title class="ppc_darkgreen darken-1">
                <span class="headline white--text">Add</span>
            </v-card-title>
            <v-card-text>
              <v-list-item-group v-model="type" color="primary">
                <v-list-item
                  v-for="(resource, i) in resource_types"
                  :key="i"
                  @click="addProvider(resource.type_id)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="resource.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="resource.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="ppc_darkgreen darken-1"
                text
                @click="dialog_add_resource = !dialog_add_resource"
            >
                Cancel
            </v-btn>
            </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-row justify="center">
      <v-dialog
        v-model="confirmDeleteResourceDialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title>
            Are you sure you want to delete this resource?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="confirmDeleteResourceDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="deleteResource"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-snackbar v-model="alertUploading" :timeout="timeout" top color="success">
      <v-progress-linear
        v-model="progressAdvance"
        color="ppc_grey"
        height="15"
        reactive
      >
      </v-progress-linear>
    </v-snackbar>

    <v-row justify="center">
      <v-dialog
        v-model="confirmDeleteClientDialog"
        persistent
        max-width="300px"
      >
        <v-card>
          <v-card-title>
            Are you sure you want to delete this client?
          </v-card-title>
          <v-card-text>All information, services and applications of {{ updatedClient.full_name }} will be removed from the system!</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="confirmDeleteClientDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="deleteClient"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    
    <v-row justify="center">
      <v-dialog
        v-model="confirmDeleteAppDialog"
        persistent
        max-width="300px"
      >
        <v-card>
          <v-card-title>
            Are you sure you want to delete the application?
          </v-card-title>
          <v-card-text>
            There is only one service left. Deleting this service results in removing the application.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="confirmDeleteAppDialog = false, getServicesAndApps()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="deleteApplication"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="confirmDeleteServiceDialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title>
            Are you sure you want to delete this service?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="confirmDeleteServiceDialog = false, getServicesAndApps()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="deleteService"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="confirmMoveServiceDialog"
        persistent
        max-width="400"
      >
        <v-card flat class="pa-3">
          <v-card-title>
            Are you sure to move?
          </v-card-title>
          <v-card-subtitle>
            Moving this service will delete the application.
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="confirmMoveServiceDialog = false, getServicesAndApps()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="moveServiceNow"
            >
              Move
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    
    <v-row justify="center">
      <v-dialog
        v-model="selectApplicationDialog"
        max-width="900"
      >
        <v-card>
          <v-card-title>
            Move to
          </v-card-title>
          <v-card-text>
              <v-data-table
                :headers="headersSelectApp"
                hide-default-header
                :items="applicationsSelect"
                sort-by="nr"
                group-by="group"
                group-desc
                dense
                :items-per-page="-1"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:group="props">
                    <span class="font-weight-bold">
                        {{ props.group }}
                    </span>
                    <v-data-table
                        :headers="props.headers"
                        :items="props.items"
                        @click:row="prepMoveService($event)"
                        dense
                        :items-per-page="-1"
                        disable-pagination
                        hide-default-footer
                    >
                      <template v-slot:[`item.applied`]="{ item }">
                          {{ formattedDate(item.applied, 'Noday') }}
                      </template>
                    </v-data-table>
                </template>
              </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="selectApplicationDialog = false, getServicesAndApps()"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog
      v-model="dialogEditClientBasic"
      min-width="300"
      max-width="80%"
    >
      <v-form ref="formClient">
        <v-card flat>
          <v-card flat class="d-flex flex-row flex-wrap pa-4">
            <v-card class="ma-4 pa-4 rounded-xl" style="max-width: 400px">
              <v-card-title>
                Client
              </v-card-title>
              <v-card-text>
                <span v-if="editClient.number_people === null">
                  <v-text-field label="First name" v-model.trim="editClient.first_name" prepend-icon="$vuetify.icons.icon_mdi_smart_card ppc_darkgreen--text"
                    validate-on-blur :error-messages="errorMessageNames" :rules="inputRulesName" required></v-text-field>
                  <v-spacer></v-spacer>
                  <v-text-field label="Last name" v-model.trim="editClient.last_name" prepend-icon="$vuetify.icons.icon_mdi_smart_card-outline ppc_darkgreen--text"
                  validate-on-blur :error-messages="errorMessageNames" :rules="inputRulesName"></v-text-field>
                </span>
                <span v-if="editClient.number_people !== null">
                  <v-text-field label="Name" v-model.trim="editClient.last_name" prepend-icon="$vuetify.icons.icon_mdi_smart_card-outline ppc_darkgreen--text"
                  validate-on-blur :error-messages="errorMessageNames" :rules="inputRulesName"></v-text-field>
                </span>
                <span v-if="editClient.number_people === null">
                  <v-menu 
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                        :value="formattedDate(editClient.birthdate)" 
                        label="Date of birth" 
                        v-on='on' 
                        v-bind="attrs"
                        prepend-icon="mdi-cake-variant ppc_darkgreen--text"
                        clearable
                        @click:clear="editClient.birthdate=null"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model='editClient.birthdate'
                      color='ppc_darkgreen'
                      scrollable
                    >
                    </v-date-picker>
                  </v-menu>
                </span>
                <v-spacer></v-spacer>
                <span v-if="editClient.number_people === null">
                  <v-select
                    v-model="editClient.gender"
                    :items="itemsSex"
                    :menu-props="{ auto: true }"
                    item-text="Text"
                    item-value="Value"
                    label="Gender"
                    single-line
                    validate-on-blur
                  >
                    <template v-slot:item="itemsSex">
                      <span v-if="itemsSex.item.Value === 'Male'"><v-icon color="ppc_darkgreen" class="mr-4">fa-male</v-icon> {{itemsSex.item.Text}} </span>
                      <span v-else-if="itemsSex.item.Value === 'Female'"><v-icon color="ppc_darkgreen" class="mr-4">fa-female</v-icon> {{itemsSex.item.Text}} </span>
                      <span v-else><v-icon small color="ppc_darkgreen" class="mr-4">fa-genderless</v-icon> {{itemsSex.item.Text}} </span>
                    </template>
                    <template v-slot:selection="itemsSex">
                      <span v-if="itemsSex.item.Value === 'Male'"><v-icon color="ppc_darkgreen" class="mr-4">fa-male</v-icon></span>
                      <span v-else-if="itemsSex.item.Value === 'Female'"><v-icon color="ppc_darkgreen" class="mr-4">fa-female</v-icon></span>
                      <span v-else><v-icon color="ppc_darkgreen" class="mr-4">fa-genderless</v-icon></span>
                    </template>
                  </v-select>
                </span>
                <v-spacer></v-spacer>
                <v-select
                  v-model="editClient.nationality_id"
                  :items="$store.state.itemsNationality"
                  item-text="nationality"
                  item-value="id"
                  :menu-props="{ auto: true }"
                  label="Select nationality"
                  single-line
                  prepend-icon="mdi-earth ppc_darkgreen--text"
                  validate-on-blur
                >
                </v-select>
                <span>
                  <v-text-field label="Occupation (in Spanish)" v-model.trim="editClient.occupation" prepend-icon="mdi-account-hard-hat ppc_darkgreen--text" validate-on-blur  clearable></v-text-field>
                </span>
              </v-card-text>
            </v-card>
            <v-card class="ma-4 pa-4 rounded-xl" style="width: 400px">
              <v-card-title>
                Contact info
              </v-card-title>
              <v-card-text>
                <v-text-field label="Email (login PM)" v-model.trim="editClient.email" prepend-icon="mdi-email ppc_darkgreen--text" :error-messages="errorMessageEmail" :rules="inputRulesEmail" validate-on-blur  clearable></v-text-field>
                <v-text-field label="Email cc/agency" v-model.trim="editClient.email_cc" prepend-icon="fas fa-copy ppc_darkgreen--text" :rules="inputRulesEmail" validate-on-blur  clearable></v-text-field>
                <v-text-field label="Phone" v-model.trim="editClient.phone" prepend-icon="mdi-cellphone ppc_darkgreen--text" :rules="inputRulesPhone" validate-on-blur ></v-text-field>
                <v-text-field label="Alternative phone" v-model.trim="editClient.phone_alt" prepend-icon="mdi-phone ppc_darkgreen--text" :rules="inputRulesPhone" validate-on-blur ></v-text-field>
              </v-card-text>
            </v-card>
            <v-card class="ma-4 pa-4 rounded-xl" style="max-width: 400px">
              <v-card-title>Source</v-card-title>
              <v-card-text>
                <v-card flat v-if="editClient.agency_id !== null">Agency: {{ editClient.agency }}</v-card>
                <v-select
                  v-model="editClient.source_id"
                  :items="$store.state.itemsSources.sort(this.dynamicSort('name'))"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ auto: true }"
                  label="Select source"
                  single-line
                  prepend-icon="fab fa-sourcetree ppc_darkgreen--text"
                  v-if="editClient.source_id !== null"
                  validate-on-blur
                >
                </v-select>
                <v-select
                  v-model="editClient.salesContact"
                  :items="selectContactsStaff"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ auto: true }"
                  label="Select sales contact"
                  single-line
                  prepend-icon="fa-comments-dollar ppc_darkgreen--text"
                  validate-on-blur
                  :disabled = "editClient.agency_id !== null"
                >
                </v-select>
              </v-card-text>
            </v-card>
            <v-card class="ma-4 pa-4 rounded-xl" style="max-width: 400px">
              <v-card flat>
                <v-card-title>
                  <v-icon color="ppc_darkgreen" class="mr-2">mdi-food-apple</v-icon>
                  Food preference
                </v-card-title>
                <v-card-text>
                  <v-radio-group
                    cols="12"
                    column
                    v-model="editClient.food_preference"
                    class="mt-0"
                  >
                    <v-radio
                      label="Not known"
                      value="Not known"
                      class="my-0 py-0"
                    ></v-radio>
                    <v-radio
                      label="I am not vegetarian nor vegan"
                      value="I am not vegetarian neither vegan"
                      class="my-0 py-0"
                    ></v-radio>
                    <v-radio
                      label="I am vegetarian"
                      value="I am vegetarian"
                      class="my-0 py-0"
                    ></v-radio>
                    <v-radio
                      label="I am vegan"
                      value="I am vegan"
                      class="my-0 py-0"
                    ></v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>
              <v-card flat>
                <v-card-title>
                  <v-icon color="ppc_darkgreen" class="mr-2">mdi-dog-side</v-icon>
                  Pets preference
                </v-card-title>
                <v-card-text>
                  <v-radio-group
                    cols="12"
                    column
                    v-model="editClient.pets_preference"
                    class="mt-0"
                  >
                    <v-radio
                      label="Not known"
                      value="Not known"
                      class="my-0 py-0"
                    ></v-radio>
                    <v-radio
                      label="I do not mind pets"
                      value="I do not mind pets"
                      class="my-0 py-0"
                    ></v-radio>
                    <v-radio
                      label="No pets please"
                      value="No pets please"
                      class="my-0 py-0"
                    ></v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>
            </v-card>
            <v-card class="rounded-xl ma-4 pa-4" style="max-width: 800px">
              <v-card-title>
                Pick Up Data
              </v-card-title>
              <v-radio-group
                row
                v-model="editClient.pickup_type"
                class="mt-0 mx-4"
              >
                <v-radio
                  label="To be confirmed"
                  value="To be confirmed"
                  class="my-0 py-0"
                ></v-radio>
                <v-radio
                  label="Flight"
                  value="Flight"
                  class="my-0 py-0"
                ></v-radio>
                <v-radio
                  label="Bus"
                  value="Bus"
                  class="my-0 py-0"
                ></v-radio>
                <v-radio
                  label="Address Cusco"
                  value="Address"
                  class="my-0 py-0"
                ></v-radio>
                <v-radio
                  label="Not applicable"
                  value="Not applicable"
                  class="my-0 py-0"
                ></v-radio>
              </v-radio-group>
              <v-card flat class="d-flex flex-row flex-wrap">
                <v-card flat>
                  <v-text-field 
                    v-if="editClient.pickup_type !== 'Address'" 
                    label="Company" 
                    v-model.trim="editClient.pickup_company" 
                    prepend-icon="$vuetify.icons.icon_mdi_domain ppc_darkgreen--text"
                    clearable
                    class="pa-2"
                  ></v-text-field>
                </v-card>
                <v-card flat>
                  <v-text-field 
                    v-if="editClient.pickup_type === 'Flight'" 
                    label="Flight nr" 
                    v-model.trim="editClient.pickup_nr" 
                    prepend-icon="mdi-airplane ppc_darkgreen--text" 
                    clearable
                    class="pa-2"
                  ></v-text-field>
                </v-card>
                <v-card flat>
                  <v-text-field 
                    v-if="editClient.pickup_type !== 'Address'" 
                    label="From" 
                    v-model.trim="editClient.pickup_from" 
                    prepend-icon="mdi-map-marker ppc_darkgreen--text" 
                    clearable
                    class="pa-2"
                  ></v-text-field>
                </v-card>
                <v-textarea
                  v-if="editClient.pickup_type === 'Address'"
                  style="min-width: 200px"
                  class="rounded-xl pa-2"
                  v-model="editClient.pickup_address"
                  auto-grow
                  clearable
                  outlined
                  counter
                  rows="2"
                  label="Address in Cusco (150 max)"
                  :rules="[v => (v || '' ).length <= 150 || 'Comments must be 150 characters or less']"
                  prepend-icon="mdi-map-marker ppc_darkgreen--text"
                ></v-textarea>
                <v-menu
                  transition="scale-transition"
                  offset-y
                  class="my-5"
                  max-width="100%"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="arrival"
                      v-bind="attrs"
                      prepend-icon="$vuetify.icons.icon_mdi_calendar ppc_darkgreen--text"
                      :value="formattedDate(editClient.pickup_date)"
                      v-on="on"
                      @click:clear="editClient.pickup_date=null"
                      clearable
                      readonly
                      class="pa-2"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editClient.pickup_date"
                    no-title
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>
                <v-card flat>
                  <v-text-field 
                    label="Time (hh:mm)" 
                    v-model.trim="editClient.pickup_time" 
                    prepend-icon="mdi-clock-time-nine-outline ppc_darkgreen--text" 
                    clearable
                    class="pa-2"
                  ></v-text-field>
                </v-card>
              </v-card>
            </v-card>
            <v-card class="rounded-xl ma-4 pa-4" style="max-width: 600px">
              <v-card-title>
                Emergency Contact
              </v-card-title>
              <v-text-field label="Full name" v-model.trim="editClient.ice_full_name" prepend-icon="$vuetify.icons.icon_mdi_smart_card ppc_darkgreen--text" ></v-text-field>
              <v-text-field label="Email" v-model.trim="editClient.ice_email" prepend-icon="mdi-email ppc_darkgreen--text" :rules="inputRulesEmail" validate-on-blur ></v-text-field>
              <v-text-field label="Phone" v-model.trim="editClient.ice_phone" prepend-icon="mdi-cellphone ppc_darkgreen--text" :rules="inputRulesPhone" validate-on-blur ></v-text-field>
              <v-text-field label="Alternative phone" v-model.trim="editClient.ice_phone_alt" prepend-icon="mdi-phone ppc_darkgreen--text" :rules="inputRulesPhone" validate-on-blur ></v-text-field>
            </v-card>
            <div class="d-flex flex-column">
              <v-textarea
                class="rounded-xl ma-4"
                v-model="editClient.comments"
                auto-grow
                clearable
                counter
                outlined
                label="Comments (500 max)"
                :rules="[v => (v || '' ).length <= 500 || 'Comments must be 500 characters or less']"
                style="min-width: 400px"
              ></v-textarea>
              <v-textarea
                class="rounded-xl ma-4"
                v-model="editClient.expectations"
                auto-grow
                clearable
                counter
                outlined
                label="Expectations (500 max)"
                :rules="[v => (v || '' ).length <= 500 || 'Expectations must be 500 characters or less']"
                style="min-width: 400px"
              ></v-textarea>
              <v-textarea
                class="rounded-xl ma-4"
                v-model="editClient.experience"
                auto-grow
                clearable
                counter
                outlined
                label="Experience (500 max)"
                :rules="[v => (v || '' ).length <= 500 || 'Experience must be 500 characters or less']"
                style="min-width: 400px"
              ></v-textarea>
            </div>
          </v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="dialogEditClientBasic = false"
              class="mr-4"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="saveClient('info')"
              class="mr-4"
            >
              Save
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialog_resource_projects" 
      max-width="500px"
    >
      <v-card class="d-flex flex-column pa-2" color="blue lighten-2">
        <v-card-title>
          <span v-if="resourceNew.type_id === 1" class=" white--text">Volunteer Placement #{{ resourceNew.number_type }}</span>
          <span v-if="resourceNew.type_id === 2" class=" white--text">Internship Placement #{{ resourceNew.number_type }}</span>
        </v-card-title>
        <v-card-text>
          <v-select
              v-model="resourceNew.category_id"
              :items="categoriesProjects(resourceNew.type_id)"
              item-text="category"
              item-value="category_id"
              :menu-props="{ auto: true }"
              label="Category"
              validate-on-blur
              clearable
              @click:clear="resourceNew.project_id = null, resourceNew.placement_id = null, resourceNew.payment = null, resourceNew.donation = null, resourceNew.donation_type = null"
              @change="resourceNew.project_id = null, resourceNew.placement_id = null, resourceNew.payment = null, resourceNew.donation = null, resourceNew.donation_type = null"
              color="white"
              item-color="blue"
              :disabled="resourceNew.placement_id !== null"
          >
              <template #selection="{ item }">
                  <span class="white--text">{{item.category}}</span>
              </template>
          </v-select>
          <v-select
              v-model="resourceNew.project_id"
              :items="filterProjects(resourceNew.type_id, resourceNew.category_id, resourceNew.project_id)"
              item-text="name"
              item-value="id"
              :menu-props="{ auto: true }"
              label="Project"
              validate-on-blur
              clearable
              @click:clear="resourceNew.project_id = null, resourceNew.placement_id = null, resourceNew.payment = null, resourceNew.donation = null, resourceNew.donation_type = null"
              @change="resourceNew.placement_id = null, resourceNew.payment = null, resourceNew.donation = null, resourceNew.donation_type = null, resourceNew.category_id = filterProjects(resourceNew.type_id, 
                  resourceNew.category_id, resourceNew.project_id)[0].category_id"
              color="white"
              item-color="blue"
              :disabled="resourceNew.placement_id !== null"
          >
              <template #selection="{ item }">
                  <span class="white--text">{{item.name}}</span>
              </template>
          </v-select>
          <v-btn
            x-small
            @click="resourceNew.start = service.start, resourceNew.end = service.end"
          >
            copy dates
          </v-btn>
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            class="my-5"
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="from"
                v-bind="attrs"
                prepend-icon="$vuetify.icons.icon_mdi_calendar"
                :value="formattedDate(resourceNew.start)"
                v-on="on"
                clearable
                readonly
                class="text-input-white"
                color="white"
                @click:clear="resourceNew.start = null, calcDatesResource('start')"
                :disabled="resourceNew.placement_id !== null"
              >
              
              </v-text-field>
            </template>
            <v-date-picker
              v-model="resourceNew.start"
              no-title
              scrollable
              color="blue"
              @change="calcDatesResource('start')"
            >
            </v-date-picker>
          </v-menu>
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            class="my-5"
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="until"
                v-bind="attrs"
                prepend-icon="$vuetify.icons.icon_mdi_calendar"
                :value="formattedDate(resourceNew.end)"
                v-on="on"
                clearable
                readonly
                class="text-input-white"
                color="white"
                @click:clear="resourceNew.end = null, calcDatesResource('end')"
                :disabled="resourceNew.placement_id !== null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="resourceNew.end"
              no-title
              scrollable
              @change="calcDatesResource('end')"
              color="blue"
            >
            </v-date-picker>
          </v-menu>
          <v-slider
            v-model="resourceNew.businessWeeks"
            step="1"
            ticks
            tick-size="4"
            thumb-label="always"
            :min="1"
            :max="52"
            hint="Duration in weeks"
            persistent-hint
            thumb-color="ppc_pink"
            track-color="ppc_grey"
            color="white"
            @change="calcDatesResource('duration')"
            class="my-8"
            :disabled="resourceNew.placement_id !== null"
          ></v-slider>
        </v-card-text>
        <v-textarea
          class="rounded-xl px-6 text-input-white"
          v-model="resourceNew.comments"
          auto-grow
          clearable
          counter
          outlined
          label="Comments (255 max)"
          :disabled="resourceNew.placement_id !== null"
          :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
          color="white"
        ></v-textarea>
        <v-card-actions class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
            #{{ resourceNew.id }}
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="dialog_resource_projects = false">Cancel</v-btn>
            <v-btn color="white" text @click="dialog_resource_projects = false, save_resource(resourceNew)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_resource_tours"
      max-width="500px"
    >
      <v-card class="d-flex flex-column pa-2" color="blue lighten-2">
        <v-card-title>
          <span class=" white--text">Tour #{{ resourceNew.number_type }}</span>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="resourceNew.tour_id"
            :items="tourSelect"
            item-text="name"
            item-value="id"
            :menu-props="{ auto: true }"
            label="Tour"
            validate-on-blur
            clearable
            color="white"
            item-color="blue"
            :disabled="resourceNew.tour_placement_id !== null"
          >
            <template #selection="{ item }">
              <span class="white--text">{{item.name}}</span>
            </template>
          </v-select>
          <v-btn
            x-small
            @click="resourceNew.start = service.start, resourceNew.end = service.end"
          >
            copy dates
          </v-btn>
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            class="my-5"
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="between"
                v-bind="attrs"
                prepend-icon="$vuetify.icons.icon_mdi_calendar"
                :value="formattedDate(resourceNew.start)"
                v-on="on"
                clearable
                readonly
                class="text-input-white"
                color="white"
                @click:clear="resourceNew.start = null, calcDatesResource('start')"
                :disabled="resourceNew.placement_id !== null"
              >
              
              </v-text-field>
            </template>
            <v-date-picker
              v-model="resourceNew.start"
              no-title
              scrollable
              color="blue"
              @change="calcDatesResource('start')"
            >
            </v-date-picker>
          </v-menu>
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            class="my-5"
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="until"
                v-bind="attrs"
                prepend-icon="$vuetify.icons.icon_mdi_calendar"
                :value="formattedDate(resourceNew.end)"
                v-on="on"
                clearable
                readonly
                class="text-input-white"
                color="white"
                @click:clear="resourceNew.end = null, calcDatesResource('end')"
                :disabled="resourceNew.placement_id !== null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="resourceNew.end"
              no-title
              scrollable
              @change="calcDatesResource('end')"
              color="blue"
            >
            </v-date-picker>
          </v-menu>
        </v-card-text>
        <v-textarea
          class="rounded-xl px-6 text-input-white"
          v-model="resourceNew.comments"
          auto-grow
          clearable
          counter
          outlined
          label="Comments (255 max)"
          :disabled="resourceNew.placement_id !== null"
          :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
          color="white"
        ></v-textarea>
        <v-card-actions class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
            #{{ resourceNew.id }}
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="dialog_resource_tours = false">Cancel</v-btn>
            <v-btn color="white" text @click="dialog_resource_tours = false, save_resource(resourceNew)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_resource_accommodation"
      max-width="700px"
    >
      <v-card class="d-flex flex-column pa-2" color="blue lighten-2">
        <v-card-title>
          <span class=" white--text">Accommodation #{{ resourceNew.number_type }}</span> 
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-row flex-wrap justify-space-between">
            <v-radio-group
              cols="12"
              column
              v-model="resourceNew.family"
              class="mt-0"
              @change="resourceNew.private_bathroom = 0"
              :disabled="resourceNew.room_id !== null"
            >
              <v-radio
                label="Family"
                value='1'
                class="my-0 py-0"
                color="white"
              ></v-radio>
              <v-radio
                label="Apartment"
                value='0'
                class="my-0 py-0"
                color="white"
              ></v-radio>
            </v-radio-group>
            <v-radio-group
              cols="12"
              column
              v-model="resourceNew.accommodation_class"
              class="mt-0"
              @change="resourceNew.private_bathroom = 0"
              :disabled="resourceNew.room_id !== null"
            >
              <v-radio
                label="Private Room"
                value="Private Room"
                class="my-0 py-0"
                color="white"
              ></v-radio>
              <v-radio
                label="Private Double"
                value="Private Double"
                class="my-0 py-0"
                color="white"
              ></v-radio>
              <v-radio
                label="Private Twin"
                value="Private Twin"
                class="my-0 py-0"
                color="white"
              ></v-radio>
              <v-radio
                label="Shared Room"
                value="Shared Room"
                class="my-0 py-0"
                color="white"
              ></v-radio>
              <v-radio
                label="Shared Bunk"
                value="Shared Bunk"
                class="my-0 py-0"
                :disabled="resourceNew.family == 1"
                color="white"
              ></v-radio>
            </v-radio-group>
            <v-checkbox
                v-model="resourceNew.private_bathroom"
                label="Private Bathroom"
                class="mr-4 mt-0"
                color="white"
                :disabled="resourceNew.room_id !== null || resourceNew.accommodation_class === 'Shared Room' || resourceNew.accommodation_class === 'Shared Bunk'"
            ></v-checkbox>
          </div>
          <v-btn
            x-small
            @click="resourceNew.start = service.start, calcDatesResource('start'), resourceNew.end = service.end, calcDatesResource('end')"
          >
            copy dates
          </v-btn>
          <div class="d-flex flex-row flex-wrap">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              class="my-5"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="between"
                  v-bind="attrs"
                  prepend-icon="$vuetify.icons.icon_mdi_calendar"
                  :value="formattedDate(resourceNew.start)"
                  v-on="on"
                  clearable
                  readonly
                  class="text-input-white"
                  color="white"
                  @click:clear="resourceNew.start = null, calcDatesResource('start')"
                  :disabled="resourceNew.room_id !== null"
                >
                
                </v-text-field>
              </template>
              <v-date-picker
                v-model="resourceNew.start"
                no-title
                scrollable
                color="blue"
                @change="calcDatesResource('start')"
              >
              </v-date-picker>
            </v-menu>
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              class="my-5"
              max-width="100%"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="until"
                  v-bind="attrs"
                  prepend-icon="$vuetify.icons.icon_mdi_calendar"
                  :value="formattedDate(resourceNew.end)"
                  v-on="on"
                  clearable
                  readonly
                  class="text-input-white"
                  color="white"
                  @click:clear="resourceNew.end = null, calcDatesResource('end')"
                  :disabled="resourceNew.room_id !== null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="resourceNew.end"
                no-title
                scrollable
                :min="resourceNew.start"
                @change="calcDatesResource('end')"
                color="blue"
              >
              </v-date-picker>
            </v-menu>
          </div>
          <v-slider
              v-model="resourceNew.duration"
              step="1"
              ticks
              tick-size="4"
              thumb-label="always"
              :min="1"
              :max="365"
              hint="Duration in nights"
              persistent-hint
              thumb-color="ppc_pink"
              track-color="ppc_grey"
              color="white"
              @change="calcDatesResource('duration')"
              class="my-8"
              :disabled="resourceNew.room_id !== null"
            ></v-slider>
        </v-card-text>
        <v-textarea
          class="rounded-xl px-6 text-input-white"
          v-model="resourceNew.comments"
          auto-grow
          clearable
          counter
          outlined
          label="Comments (255 max)"
          :disabled="resourceNew.placement_id !== null"
          :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
          color="white"
        ></v-textarea>
        <v-card-actions class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
            #{{ resourceNew.id }}
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="dialog_resource_accommodation = false">Cancel</v-btn>
            <v-btn color="white" text @click="dialog_resource_accommodation = false, save_resource(resourceNew)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_resource_teaching"
      max-width="500px"
    >
      <v-card class="d-flex flex-column pa-2" color="blue lighten-2">
        <v-card-title>
          <span class="white--text">Language Course #{{ resourceNew.number_type }}</span>
        </v-card-title>
        <v-card-text>
          <v-btn
            x-small
            @click="resourceNew.start = service.start, calcDatesResource('start'), resourceNew.end = service.end, calcDatesResource('end')"
          >
            copy dates
          </v-btn>
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            class="my-5"
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="between"
                v-bind="attrs"
                prepend-icon="$vuetify.icons.icon_mdi_calendar"
                :value="formattedDate(resourceNew.start)"
                v-on="on"
                clearable
                readonly
                class="text-input-white"
                @click:clear="resourceNew.start = null, calcDatesResource('start')"
                color="white"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="resourceNew.start"
              no-title
              scrollable
              @change="calcDatesResource('start')"
              color="blue"
            >
            </v-date-picker>
          </v-menu>
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            class="my-5"
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="and"
                v-bind="attrs"
                prepend-icon="$vuetify.icons.icon_mdi_calendar"
                :value="formattedDate(resourceNew.end)"
                v-on="on"
                clearable
                readonly
                class="text-input-white"
                @click:clear="resourceNew.end = null, calcDatesResource('end')"
                color="white"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="resourceNew.end"
              no-title
              scrollable
              @change="calcDatesResource('end')"
              color="blue"
              :min="resourceNew.start"
            >
            </v-date-picker>
          </v-menu>
          <div class="d-flex flex-row flex-wrap">
            <div class="d-flex flex-row flex-wrap">
              <v-radio-group
                cols="12"
                column
                v-model="resourceNew.class_language"
                class="mt-0 mr-6"
                :disabled="resourceNew.minutes_planned > 0"
              >
                <v-radio
                  label="Spanish"
                  value="Spanish"
                  class="my-0 py-0"
                  color="white"
                ></v-radio>
                <v-radio
                  label="Quechua"
                  value="Quechua"
                  class="my-0 py-0"
                  color="white"
                ></v-radio>
                <v-radio
                  label="English"
                  value="English"
                  class="my-0 py-0"
                  color="white"
                ></v-radio>
              </v-radio-group>
              <v-radio-group
                cols="12"
                column
                v-model="resourceNew.class_location"
                class="mt-0 mr-6"
                :disabled="resourceNew.minutes_planned > 0"
              >
                <v-radio
                  label="Cusco School"
                  value="School Cusco"
                  class="my-0 py-0"
                  color="white"
                ></v-radio>
                <v-radio
                  label="Urubamba"
                  value="Urubamba"
                  class="my-0 py-0"
                  color="white"
                ></v-radio>
                <v-radio
                  label="Online"
                  value="Online"
                  class="my-0 py-0"
                  color="white"
                ></v-radio>
              </v-radio-group>
            </div>
            <div class="d-flex flex-row flex-wrap">
              <v-radio-group
                cols="12"
                column
                v-model="resourceNew.class_type"
                class="mt-0 mr-6"
                :disabled="resourceNew.minutes_planned > 0"
              >
                <v-radio
                  label="Normal"
                  value="Normal"
                  class="my-0 py-0"
                  color="white"
                ></v-radio>
                <v-radio
                  label="Specialism"
                  value="Special"
                  class="my-0 py-0"
                  color="white"
                ></v-radio>
              </v-radio-group>
              <v-radio-group
                cols="12"
                column
                v-model="resourceNew.class_group"
                class="mt-0 mr-6"
                :disabled="resourceNew.minutes_planned > 0"
              >
                <v-radio
                  label="Group"
                  value="Group"
                  class="my-0 py-0"
                  color="white"
                ></v-radio>
                <v-radio
                  label="Individual"
                  value="Individual"
                  class="my-0 py-0"
                  color="white"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
          
          <v-card-text>
            <v-text-field
              label="Hours sold"
              v-model="resourceNew.hours"
              class="text-input-white"
              color="white"
            ></v-text-field>
            <v-text-field
              label="Hours per class"
              v-model="resourceNew.hours_per_class"
              class="text-input-white"
              color="white"
            ></v-text-field>
          </v-card-text>
        </v-card-text>
        <v-textarea
          class="rounded-xl px-6 text-input-white"
          v-model="resourceNew.comments"
          auto-grow
          clearable
          counter
          outlined
          label="Comments (255 max)"
          :disabled="resourceNew.placement_id !== null"
          :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
          color="white"
        ></v-textarea>
        <v-card-actions class="d-flex flex-row flex-wrap justify-space-between" color="blue lighten-2" style="width: 100%">
            #{{ resourceNew.id }}
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="dialog_resource_teaching = false">Cancel</v-btn>
            <v-btn color="white" text @click="dialog_resource_teaching = false, save_resource(resourceNew)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

  
    
</template>

<script>
import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import getStatusApp from '../mixins/getStatusApp';
import createAppTitle from '../mixins/createAppTitle';
import zlib from 'zlib';
import compare from '../mixins/compare';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';
dayjs.extend(dayjsBusinessDays);

export default {

  mixins: [inputRules, formattedDate, createAppTitle, getStatusApp, compare],
  
  data() {
    return {
      updatedClient: [],
      editClient: [],
      updatedServices: [],
      updatedApplications: [],
      openPanel: [],
      tab: (this.$route.params.resourceId) ? 1 : 0,
      errorMessageEmail: '',
      errorMessageNames: '',
      select_items: [],
      approvalDateCv: null,
      approvalDatePassport: null,
      rendererKeyPanels: Math.random(),
      dialog_add_type: false,
      dialog_add_resource: false,
      type: null,
      dialog_selectService: false,
      itemsTab: [
        'General', 'Applications',
      ],
      itemsSex: [
        {Value: 'Female', Text: 'Female'},
        {Value: 'Male', Text: 'Male'},
        {Value: 'X', Text: 'Genderless'}
      ],
      currentFile: undefined,
      alertUploading: false,
      confirmDeleteClientDialog: false,
      confirmDeleteAppDialog: false,
      confirmDeleteServiceDialog: false,
      confirmDeleteResourceDialog: false,
      confirmMoveServiceDialog: false,
      selectApplicationDialog: false,
      applicationsNotStarted: [],
      headersSelectApp: [
        {text: 'App Title', value: 'appTitle', groupable: false},
        {text: 'payer', value: 'payer', groupable: false},
        {text: 'applied', value: 'applied', groupable: false, width: '120px' },
        {text: 'nr', value: 'id', groupable: false},
        {text: 'group', value: 'group', groupable: false},
      ],
      headersSecurityDeposits: [
        {text: 'date', value: 'dateTime'},
        {text: 'amount', value: 'amountDollars'},
      ],
      appIdToDelete: null,
      serviceIdToDelete: null,
      resourceIdToDelete: null,
      serviceIdToMove: null,
      serviceIdToResource: null,
      timeout: 3000,
      fileRules: [
        file => !file || file.size < 2e6 || 'File size should be less than 2 MB!'
      ],
      selectFileCV: false,
      selectFilePassport: false, 
      selectFileOther: false,
      resources: [],
      resource_types: [
        {icon: "$vuetify.icons.icon_volunteer", text:"Volunteer Placement", type_id: 1},
        {icon: "$vuetify.icons.icon_internship", text:"Internship Placement", type_id: 2},
        {icon: "$vuetify.icons.icon_languages", text:"Language Course", type_id: 3},
        {icon: "$vuetify.icons.icon_accommodation", text:"Accommodation", type_id: 4},
        {icon: "$vuetify.icons.icon_tours", text:"Tour Agency", type_id: 5},
      ],
      applicationsSelect: [],
      tourSelect: [],
      loadingButtonSynchronise: false,
      syncButtonPassport: false,
      syncButtonCV: false,
      addPayment: false,
      nowDate: null,
      securityDeposits: [],
      dialog_resource_projects: false,
      dialog_resource_tours: false,
      dialog_resource_teaching: false,
      dialog_resource_accommodation: false,
      resourceNew:[],
      updatedRadioGroups: [],
      updatedOptions: [],
      dragover: false,
      dialogEditClientBasic: false,
      initializing: false,
      school_welcome_email: 'spanish',
    }
  },

  computed: {
    statusLoggedOut () {
      return this.$store.getters.statusLoggedOut
    },
    client () {
      return this.$store.getters.client
    },
    services () {
      return this.$store.getters.services
    },
    applications () {
      return this.$store.getters.applications
    },
    progressAdvance () {
      return this.$store.getters.progressAdvance
    },
    uploadInfos () {
      return this.$store.getters.uploadInfos
    },
    noExistsServices () {
      let tmp = this.services.filter(function(select_item) {
        return select_item.application_id === null;
      })
      return (tmp.length === 0);
    },
    currentClient () {
      return this.$store.getters.currentClient
    },
    selectContactsStaff () {
      let staff = this.$store.state.contacts.filter(function(select_item) {
          return select_item.obsolete === null && select_item.staff > 0;
      })
      return staff.sort(this.dynamicSort('name'));
    },
    calcAge () {
      return dayjs().diff(this.updatedClient.birthdate, 'year');
    },
    calcPMmessages () {
      let numberMessages = 0;
      if (this.updatedClient.id !== undefined) {
        let clientId = this.updatedClient.id;
        numberMessages = this.$store.state.messagesPM.filter(function(select_item) {
            return select_item.id === clientId;
        });
      }
      return numberMessages.length;
    },
  },

  beforeCreate () {
    this.$store.dispatch('setLoadingApp', 1)
  },

  created () {
    dayjs.locale("en");
    this.initialize(null);
  },

  watch: {
    currentClient(newValue, oldValue) {
      console.log(newValue, oldValue);
      if (newValue) {
        this.$store.dispatch('setLoadingApp', 1)
        this.initialize(newValue);
      }
    },
  },
  methods: {
    async initialize(clientId) {
      this.initializing = true;
      if (clientId === null && this.$route.params.id) {
        clientId = this.$route.params.id;
      }
      let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
      let previousView =  this.$store.state.breadcrumbs[this.$store.state.breadcrumbs.length - 1];
      if (clientId !== null) {
        if (previousView === 'Invoice' || previousView === 'Application') {
          this.tab = 1;
          breadcrumb.pop();
        } else if (previousView !== 'Client') {
          breadcrumb.push('Client');
        }
        await this.$store.dispatch('breadcrumbs', breadcrumb);
      } else {
        this.$router.push({ name: 'Leads' })
      }
      this.updatedClient.id = clientId;
      console.log('##########0');
      await this.getClientId();
      console.log('##########3');
      this.updatedClient.includeHandbook = false;
      this.nowDate = this.formattedDate(new Date(), 'date');
      await this.$store.dispatch('getUploads', this.updatedClient.id);
      console.log('##########4');
      await this.$store.dispatch('getProjectPlacements');
      console.log('##########5');
      this.tourSelect = await this.$store.dispatch('getTours');
      console.log('##########6');
      if (this.updatedClient.takesEnglish === 1 || this.updatedClient.takesEnglish) {
        this.school_welcome_email = 'english';
      } else {
        this.school_welcome_email = 'spanish';
      }
      this.tourSelect.sort(this.dynamicSort('name'));
      this.initializing = false;
      this.$store.dispatch('setLoadingApp', 0);
      this.securityDeposits = await this.$store.dispatch('getSecurityDeposits', this.updatedClient.id);
      console.log('##########7');
      if (this.$route.params.resourceId) {
        // find service
        let resourceId = this.$route.params.resourceId;
        let resource = this.resources.filter(function(select_item) {
          return select_item.id === resourceId;
        })[0];
        // open panel service
        let pos = this.updatedServices.map(function(e) { return e.id; }).indexOf(resource.service_id);
        this.openPanel = pos;
        this.scrollTo('scroll-id-' + resource.service_id);
      }
    },

    async getResources () {
      this.resources = await this.$store.dispatch('getResourcesClientId', this.updatedClient.id);
      for (let i = 0; i < this.resources.length; i++) {
        (this.resources[i].start) ? this.resources[i].start = this.formattedDate(this.resources[i].start, 'date') : this.resources[i].start = null;
        (this.resources[i].end) ? this.resources[i].end = this.formattedDate(this.resources[i].end, 'date') : this.resources[i].end = null;
        (this.resources[i].info_to_client) ? this.resources[i].info_to_client = this.formattedDate(this.resources[i].info_to_client, 'date') : this.resources[i].info_to_client = null;
        (this.resources[i].info_to_provider) ? this.resources[i].info_to_provider = this.formattedDate(this.resources[i].info_to_provider, 'date') : this.resources[i].info_to_provider = null;
        (this.resources[i].reserved) ? this.resources[i].reserved = this.formattedDate(this.resources[i].reserved, 'date') : this.resources[i].reserved = null;
        (this.resources[i].family === 0) ? this.resources[i].family = '0' : this.resources[i].family = '1';
        if (this.resources[i].accommodation_class === null) {this.resources[i].accommodation_class = 'Private Room'}
      }
    },

    async getServicesAndApps (sort)  {
      console.log('getServicesAndApps');
      let payload = {
        clientId: this.updatedClient.id,
        sort: sort,
      }
      await this.$store.dispatch('getServices', payload);
      this.updatedServices = JSON.parse(JSON.stringify(this.services));
      console.log('getServicesAndApps 2', this.updatedServices);
      await this.$store.dispatch('getApplications', this.updatedClient.id);
      this.updatedApplications = JSON.parse(JSON.stringify(this.applications));
      this.itemsTab = [
        'General', 
        'Applications (' + this.updatedApplications.length + ')',
      ];
    },

    async getClientId() {
      console.log('##########1', this.updatedClient);
      await Promise.all([this.$store.dispatch('getClient', this.updatedClient.id), this.$store.dispatch('getRadioGroups', this.updatedClient.id), this.$store.dispatch('getRadioOptionsClient', this.updatedClient.id), this.$store.dispatch('getOptions', this.updatedClient.id), this.getServicesAndApps('byAppDate'), this.getResources()]).then(() => {
        this.updatedClient = JSON.parse(JSON.stringify(this.client));
        console.log('##########2');
        this.updatedRadioGroups = JSON.parse(JSON.stringify(this.$store.state.radioGroups));
        this.updatedOptions = JSON.parse(JSON.stringify(this.$store.state.options));
      });
      this.$store.dispatch('setLoadingApp', 0);
    },

    async saveClient (param) {
      console.log('saveClient', param)
      if (param === 'info') {
        if(this.$refs.formClient.validate()) {
          this.updatedClient = Object.assign({}, this.editClient)
        } else {
          return;
        }
      }
      this.$store.dispatch('setLoadingApp', 1);
      this.errorMessageEmail = '';
      this.errorMessageNames = '';
      if (this.updatedClient.email) {
        if (this.updatedClient.email.trim() === '') {
          this.updatedClient.email = null;
        }
      }
      for (let index = 0; index < this.$store.state.applications.length; index++) {
        this.changeAppTitlePrice(this.$store.state.applications[index].id)
      }
      this.updatedClient.ignore_security_deposit = this.updatedClient.ignore_security_deposit ? 1 : 0;
      if (this.updatedClient.birthdate) {
          this.updatedClient.birthdate = dayjs(this.updatedClient.birthdate).format("YYYY-MM-DD");
      } else {
          this.updatedClient.birthdate = null;
      }
      if (this.updatedClient.spanishTestDone) {
          this.updatedClient.spanishTestDone = dayjs(this.updatedClient.spanishTestDone).format("YYYY-MM-DD");
      } else {
          this.updatedClient.spanishTestDone = null;
      }
      if (this.updatedClient.handbookSent) {
          this.updatedClient.handbookSent = dayjs(this.updatedClient.handbookSent).format("YYYY-MM-DD");
      } else {
          this.updatedClient.handbookSent = null;
      }
      if (this.updatedClient.welcome_email) {
          this.updatedClient.welcome_email = dayjs(this.updatedClient.welcome_email).format("YYYY-MM-DD");
      } else {
          this.updatedClient.welcome_email = null;
      }
      if (this.updatedClient.first_schedule_sent) {
          this.updatedClient.first_schedule_sent = dayjs(this.updatedClient.first_schedule_sent).format("YYYY-MM-DD");
      } else {
          this.updatedClient.first_schedule_sent = null;
      }
      if (this.updatedClient.thank_you_email) {
          this.updatedClient.thank_you_email = dayjs(this.updatedClient.thank_you_email).format("YYYY-MM-DD");
      } else {
          this.updatedClient.thank_you_email = null;
      }
      if (this.updatedClient.nextContact) {
          this.updatedClient.nextContact = dayjs(this.updatedClient.nextContact).format("YYYY-MM-DD");
      } else {
          this.updatedClient.nextContact = null;
      }
      if (!this.updatedClient.statusFixed || this.updatedClient.statusFixed === null) {
          this.updatedClient.statusFixed = 0;
      }
      if (this.updatedClient.ignore_nextContact) {
          this.updatedClient.ignore_nextContact = dayjs(this.updatedClient.ignore_nextContact).format("YYYY-MM-DD");
      } else {
          this.updatedClient.ignore_nextContact = null;
      }
      if (!this.updatedClient.source && this.updatedClient.agency_id === null) {
          this.updatedClient.source_id = 1;
      }
      await this.$store.dispatch('clientUpdate', this.updatedClient);
      await this.getClientId();
      if (this.$store.state.alertVPS === 0 && this.updatedClient.email !== null) {
        console.log('saveClient 2', this.$store.state.alertVPS, this.updatedClient.email);
        this.updatedClient.leadChanged = 0;
        this.$store.dispatch('insertLeadPM', this.updatedClient);
      }
      this.dialogEditClientBasic = false;

    },

    selectFile(file, type) {
      if (file) {
        console.log('selectFile', file.name)
        if (type === 'cv') {
          this.selectFilePassport = true;
          this.selectFileOther = true;
        } else if (type === 'passport') {
          this.selectFileCV = true;
          this.selectFileOther = true;
        } else {
          this.selectFileCV = true;
          this.selectFilePassport = true;
        }
        this.currentFile = file;
        this.$store.dispatch('setFileName', file.name);
        this.uploadFile(type);
      } else {
        this.selectFileCV = false;
        this.selectFilePassport = false;
        this.selectFileOther = false;
      }
    },

    async onDrop(e, type) {
      this.dragover = false;
      if (e.dataTransfer.files.length > 1) {
        this.$store.dispatch('errorMessage', 'Only one file can be uploaded at a time..');
      } else {
        console.log(e.dataTransfer.files[0].type);
        if (type === 'cv') {
          if (e.dataTransfer.files[0].type === 'application/pdf' || e.dataTransfer.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') { 
            this.currentFile = e.dataTransfer.files[0];
            await this.$store.dispatch('setFileName', this.currentFile.name);
            this.uploadFile(type);
          } else {
            this.$store.dispatch('errorMessage', 'Please add a pdf or word document');
          }
        } else if (type === 'passport') {
          if (e.dataTransfer.files[0].type === 'application/pdf' || e.dataTransfer.files[0].type === 'image/jpeg' || e.dataTransfer.files[0].type === 'image/png') { 
            this.currentFile = e.dataTransfer.files[0];
            await this.$store.dispatch('setFileName', this.currentFile.name);
            this.uploadFile(type);
          } else {
            this.$store.dispatch('errorMessage', 'Please add a pdf, jpeg or png file');
          }
        } else {
          if (e.dataTransfer.files[0].type === 'application/pdf' || e.dataTransfer.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || e.dataTransfer.files[0].type === 'image/jpeg' || e.dataTransfer.files[0].type === 'image/png') { 
            this.currentFile = e.dataTransfer.files[0];
            await this.$store.dispatch('setFileName', this.currentFile.name);
            this.uploadFile(type);
          } else {
            this.$store.dispatch('errorMessage', 'Please add a pdf, jpeg, png or word document');
          }
        }
      }
        
    },

    uploadFile(type) {
      console.log('uploadFile', type);
      this.$store.dispatch('showProgressBar', true);
      if (type === 'other') {
        type = null;
      }
      if (this.currentFile) {
        // this.alertUploading = true;

        const reader = new FileReader();
        
        reader.onload = () => {
          const zip = zlib.gzipSync(JSON.stringify(reader.result)).toString('base64');
          const date = new Date();
          const payload = {
            id: date.getTime(),
            client_id: this.updatedClient.id,
            type: type,
            file: zip,
          }
          if (payload) {
            this.$store.dispatch('addUpload', payload).then(() => {
              this.selectFileCV = false;
              this.selectFilePassport = false;
              this.selectFileOther = false;
              this.getServicesAndApps('byAppDate');
            })
          }
        }
        if(this.currentFile) {
          reader.readAsDataURL(this.currentFile);
        }
        this.currentFile = undefined;
        this.file = null;
        this.$store.dispatch('showProgressBar', false);
      } else {
        this.$store.dispatch('successMessage', 'Please select a file');
      }
    },

    downloadFile (payload) {
      this.$store.dispatch('downloadFile', payload);
    },

    async deleteFile (payload) {
      await this.$store.dispatch('deleteFile', payload);
      this.$store.dispatch('deleteUploadPM', payload)
      this.getServicesAndApps('byAppDate');
    },

    async approveUpload (fileId, approved) {
      const payload = {
        id: fileId,
        approved: approved
      }
      await this.$store.dispatch('approveUpload', payload)
      this.getServicesAndApps('byAppDate');
      await this.syncUploadPM(payload.id);
    },

    async syncUploadPM (uploadId) {
      if (this.$store.state.alertVPS === 0) {
        await this.$store.dispatch('transferUploadPM', uploadId);
        this.syncButtonPassport = false;
        this.syncButtonCV = false;
      }
    },

    getUploadInfoType (type) {
      if (this.uploadInfos) {
        if (type === 'other') {
          return this.uploadInfos.filter(function(select_item) {
          return select_item.type === null;
        })
        } else {
          return this.uploadInfos.filter(function(select_item) {
            return select_item.type === type;
          })
        }
      }
    },

    cancel () {
        this.$router.go(-1);
    },

    async synchroniseClientPM () {
      if (this.$store.state.alertVPS === 0) {
        // get applications PM syncPM true
        let appsSyncPM = await this.$store.dispatch('getApplicationsPM', this.updatedClient.email)
        for (let i = 0; i < appsSyncPM.length; i++) {
          let stupidthing = appsSyncPM[i].id;
          let idAppDel = this.updatedApplications.filter(function(select_item) {
            return select_item.id === stupidthing;
          })
          if (idAppDel.length === 1) {
            if (idAppDel[0].syncPM === false || (idAppDel[0].payerClient === null && idAppDel[0].payerAgency === null)) {
              console.log('synchroniseClientPM #1',idAppDel[0].syncPM)
              this.$store.dispatch('deleteApplicationPM', stupidthing)
            }
          } else if (appsSyncPM[i].appNew === 0) {
            console.log('synchroniseClientPM #2')
            this.$store.dispatch('deleteApplicationPM', stupidthing)
          }
        }
        // get services PM syncPM for deletion
        await this.$store.dispatch('getServicesPM', this.updatedClient.id).then((result) => {
          let i;
          for (i = 0; i < result.length; i++) {
            let stupidthing = result[i].id;
            let delServicesSyncPM = this.updatedServices.filter(function(select_item) {
              return select_item.id === stupidthing;
            })
            if (delServicesSyncPM.length === 0) {
              this.$store.dispatch('deleteServicePM', stupidthing)
            } else {
              // look if app is sync and delete if not
              if (delServicesSyncPM[0].application_id !== result[i].application_id) {
                this.$store.dispatch('deleteServicePM', stupidthing)
              }
            }
          }
        })

        // if (this.updatedClient.payerClient === null && this.updatedAgency === null)

        if (this.updatedClient.email !== null) {
          // await this.$store.dispatch('insertLeadPM', this.updatedClient)
          let appsSyncPM = this.updatedApplications.filter(function(select_item) {
            return select_item.syncPM === true && (select_item.payerClient !== null || select_item.payerAgency !== null);
          })
          if (appsSyncPM.length > 0) {
            let stupidthing1 = this.updatedClient.id;
            let uploadsSyncPM = this.uploadInfos.filter(function(select_item) {
              return select_item.client_id === stupidthing1 && (select_item.type === 'cv' || select_item.type === 'passport');
            })
            let i;
            for (i = 0; i < uploadsSyncPM.length; i++) {
              this.syncUploadPM(uploadsSyncPM[i].id)
            }
            for (i = 0; i < appsSyncPM.length; i++) {
              let stupidthing2 = appsSyncPM[i].id;
              appsSyncPM[i].email = this.updatedClient.email;
              console.log('synchroniseClientPM #3', appsSyncPM[i])
              await this.$store.dispatch('insertApplicationPM', appsSyncPM[i])
              let servicesSyncPM = this.updatedServices.filter(function(select_item) {
                  return select_item.application_id === stupidthing2;
              })
              let j;
              for (j = 0; j < servicesSyncPM.length; j++) {
                let stupidthing3 = servicesSyncPM[j].id;
                servicesSyncPM[j].currentStatus = servicesSyncPM[j].autoStatus;
                await this.$store.dispatch('insertServicePM', servicesSyncPM[j]);
                await this.$store.dispatch('serviceUpdate', servicesSyncPM[j]);
                this.getServicesAndApps('byAppDate');
                let radioGroupsSyncPM = this.$store.state.radioGroups.filter(function(select_item) {
                    return select_item.services_id === stupidthing3;
                })
                let k;
                for (k = 0; k < radioGroupsSyncPM.length; k++) {
                  this.$store.dispatch('insertRadioGroupPM', radioGroupsSyncPM[k])
                }
                let radioOptionsSyncPM = this.$store.state.radioOptions.filter(function(select_item) {
                    return select_item.services_id === stupidthing3;
                })
                for (k = 0; k < radioOptionsSyncPM.length; k++) {
                  this.$store.dispatch('addRadioOptionPM', radioOptionsSyncPM[k]);
                }
                let optionsSyncPM = this.$store.state.options.filter(function(select_item) {
                    return select_item.services_id === stupidthing3;
                })
                for (k = 0; k < optionsSyncPM.length; k++) {
                  this.$store.dispatch('insertOptionPM', optionsSyncPM[k])
                }
              }
            }
          }
        }
        // this.loadingButtonSynchronise = false;
        // this.$store.dispatch('successMessage', 'Apps synced with Package Maker');
      }
    },

    async goView(view, id) {
      let nextView = this.$store.state.breadcrumbs[this.$store.state.breadcrumbs.length - 2];
      if (this.$refs.formClient) {
        if (!this.$refs.formClient.validate()) {
          this.tab = 0;
          this.$store.dispatch('errorMessage', 'Please revise input before moving on');
          return;
        } 
      }
      if (view === 'Invoice') {
        await this.$store.dispatch('getApplications', this.updatedClient.id);
        await this.$store.dispatch('saveDataForReport', id);
        this.$router.push({ name: 'Invoice' })
      } else if (view === 'Application') {
        await this.$store.dispatch('getApplications', this.updatedClient.id);
        await this.$store.dispatch('saveDataForReport', id);
        this.$router.push({ name: 'Application' })
      } else if (view === 'close') {
        this.$router.push({ name: nextView })
      }
    },

    async deleteClient () {
      this.confirmDeleteClientDialog = false;
      if (this.updatedClient.amountServices > 0) {
        this.$store.dispatch('errorMessage', 'Delete services first please');
        return;
      } else {
        await this.$store.dispatch('deleteClient');
        await this.$store.dispatch('deleteLeadPM', this.updatedClient.id);
        await this.$store.dispatch('checkWithPM');
        this.$router.push({ name: 'Leads' });
      }
    },

    checkNewApp(index) {
      if (this.updatedServices[index].application_id) {
        if (this.updatedServices[index-1]) {
          if (this.updatedServices[index].application_id === this.updatedServices[index-1].application_id) {
            return false
          } else {
            return true
          }
        } else if (this.updatedServices[index].application_id !== null) {
          return true
        }
      }
    },
    
    async create_select_items (type_id) {
      await this.$store.dispatch('getAvailableServices');
      this.dialog_selectService = true;
      if (type_id !== null) {
        if (type_id === 'agency') {
          let tmp = this.client.agency_id;
          this.select_items = this.$store.state.available_services.filter((select_item) => {
            return select_item.agency === tmp;
          });
        } else {
          if (type_id === 7) {
            this.select_items = this.$store.state.available_services.filter((select_item) => {
                return select_item.promo_text;
              }
            );
            return;
          }
          this.select_items = this.$store.state.available_services.filter((select_item) => {
            return select_item.type_id === type_id && select_item.agency === null;
          });
        }
      }
      return;
    },

    async selectService (newServiceNr) {
      this.dialog_selectService = false;
      this.dialog_add_type = false;
      const date = new Date();
      var idServiceNew = date.getTime();
      let avServ = this.$store.state.available_services.filter(function(select_item) {
        return select_item.service_number === newServiceNr;
      });
      let newPos = this.updatedServices.length;
      this.updatedServices[newPos] = JSON.parse(JSON.stringify(avServ[0]));
      this.updatedServices[newPos].id = idServiceNew;
      this.updatedServices[newPos].currentStatus = 3;
      this.updatedServices[newPos].client_id = this.updatedClient.id;
      this.updatedServices[newPos].service_number = newServiceNr;
      this.$store.dispatch('setLoadingApp', 1);
      await this.$store.dispatch('addService', this.updatedServices[newPos]);
      await this.getServicesAndApps('byAppDate');
      newPos = this.updatedServices.map(function(e) { return e.id; }).indexOf(idServiceNew);
      // add local depending options
      let optionsSelected = await this.$store.dispatch('getAvailableOptions', newServiceNr);
      let i;
      for (i = 0; i < optionsSelected.length; i++) {
        optionsSelected[i].services_id = idServiceNew;
        await this.$store.dispatch('addOption', optionsSelected[i])
      }
      let radioGroupSelected = await this.$store.dispatch('getAvailableRadioGroups', newServiceNr);
      for (i = 0; i < radioGroupSelected.length; i++) {
        radioGroupSelected[i].services_id = idServiceNew;
        await this.$store.dispatch('addRadioGroup', radioGroupSelected[i]);
        let radioOptionsSelected = await this.$store.dispatch('getAvailableRadioOptions_id', radioGroupSelected[i].radio_group_id);
        for (let index = 0; index < radioOptionsSelected.length; index++) {
          radioOptionsSelected[index].services_id = idServiceNew;
          await this.$store.dispatch('addRadioOption', radioOptionsSelected[index]);
        }
      }
      await this.$store.dispatch('serviceUpdate', this.updatedServices[newPos]);
      // if preset add resources
      let resourcesPresets = await this.$store.dispatch('getResourcesPresets', this.updatedServices[newPos].service_number);
      for (let index = 0; index < resourcesPresets.length; index++) {
        resourcesPresets[index].id = null;
        resourcesPresets[index].service_id = this.updatedServices[newPos].id;
        await this.$store.dispatch('insertResource', resourcesPresets[index]);
      }
      await this.getClientId();
      await this.calcPriceService(newPos);
      newPos = this.updatedServices.map(function(e) { return e.id; }).indexOf(idServiceNew);
      this.openPanel = newPos;
      this.rendererKeyPanels = Math.random();
      this.$store.dispatch('setLoadingApp', 0);

    },

    async prepSelectAppMove(servId) {
      this.applicationsNotStarted = await this.$store.dispatch('getApplicationsNotStarted');
      let posService = this.updatedServices.map(function(e) { return e.id; }).indexOf(servId);
      this.applicationsSelect = [];
      for (let index = 0; index < this.applicationsNotStarted.length; index++) {
        if (this.updatedServices[posService].application_id !== this.applicationsNotStarted[index].id && this.applicationsNotStarted[index].frozen === 0 && this.applicationsNotStarted[index].statusAppId !== 16) {
          if (this.updatedServices.map(function(e) { return e.application_id; }).indexOf(this.applicationsNotStarted[index].id) > -1) {
            this.applicationsNotStarted[index].group = 'own';
          } else {
            this.applicationsNotStarted[index].group = 'other';
          }
          if (this.applicationsNotStarted[index].payerClient) {
            this.applicationsNotStarted[index].payer = this.applicationsNotStarted[index].payerClientName;
            if (this.updatedServices[posService].payerClientName) {
              this.applicationsSelect.push(this.applicationsNotStarted[index]);
              continue;
            }
          } else {
            this.applicationsNotStarted[index].payer = this.applicationsNotStarted[index].payerAgencyName;
            if (this.updatedServices[posService].payerAgency === this.applicationsNotStarted[index].payerAgency) {
              this.applicationsSelect.push(this.applicationsNotStarted[index]);
              continue;
            }
          }
          if (!this.updatedServices[posService].application_id) {
            this.applicationsSelect.push(this.applicationsNotStarted[index]);
          }
        }
      }
      this.applicationsSelect.sort(this.dynamicSort('group'));
      this.serviceIdToMove = servId;
      this.selectApplicationDialog = true;
    },

    async prepMoveService (event) {
      this.selectApplicationDialog = false;
      let serviceId = this.serviceIdToMove;
      let service = this.updatedServices.filter(function(select_item) {
        return select_item.id === serviceId;
      })
      service = JSON.parse(JSON.stringify(service[0]));
      let appIdNew = event.id;
      let appIdOld = service.application_id;
      if (appIdNew === appIdOld) {
        this.$store.dispatch('errorMessage', 'The service exists already in that application')
        return;
      }
      if (appIdOld !== null) {
        var servicesInAppOld = this.updatedServices.filter(function(select_item) {
          return select_item.application_id === appIdOld;
        })
        if (servicesInAppOld.length === 1 && servicesInAppOld[0].amountOfClients === 1 && servicesInAppOld[0].amountPaidApplication > 0) {
          this.$store.dispatch('errorMessage', "The application has already payments")
          return;
        }
      } else {
        servicesInAppOld = [];
      }
      let posService;
      if (event.type === "click") {
        // new app
        // if (servicesInAppOld.length === 1 && servicesInAppOld[0].amountOfClients === 1) {
        //   this.$store.dispatch('errorMessage', "The service already has it's own application");
        //   return;
        // }
        posService = this.updatedServices.map(function(e) { return e.id; }).indexOf(serviceId);
        this.updatedServices[posService].application_id = null;
        this.$store.dispatch('serviceUpdate', this.updatedServices[posService])
        // change Title old app
        if (appIdOld !== null) {
          this.changeAppTitlePrice(appIdOld);
        }
        this.synchroniseClientPM();
        return;
      }
      service.application_id = appIdNew;
      posService = this.updatedServices.map(function(e) { return e.id; }).indexOf(serviceId);
      this.updatedServices[posService] = service;

      if (servicesInAppOld.length === 1 && servicesInAppOld[0].amountOfClients === 1) {
        this.appIdToDelete = appIdOld;
        this.confirmMoveServiceDialog = true;
      } else {
        this.appIdToDelete = null;
        this.moveServiceNow ();
      }
    },

    async moveServiceNow () {
      let posService = this.updatedServices.map(function(e) { return e.id; }).indexOf(this.serviceIdToMove);
      this.confirmMoveServiceDialog = false;
      let appIdNew = this.updatedServices[posService].application_id;
      let appIdOld = this.services[posService].application_id;
      this.$store.dispatch('serviceUpdate', this.updatedServices[posService]).then(() => {
        this.openPanel = [];
        this.$store.dispatch('successMessage', 'The service has been moved succesfully');
        if (this.appIdToDelete !== null) {
          this.deleteApplication();
        } else {
          // change app title old
          if (appIdOld !== null) {
            this.changeAppTitlePrice(appIdOld)
          }
        }
        // change app title new
        this.changeAppTitlePrice(appIdNew)
      });
      this.synchroniseClientPM();
      await this.getClientId();
    },

    async changeAppTitlePrice(appId) {
      let application = await this.$store.dispatch('getApplicationId', appId);
      if (application.autoAppTitle === 1) {
        let servicesInApp = await this.$store.dispatch('getServicesAppId', appId);
        application.appTitle = await this.createAppTitle(application, servicesInApp)
      }
      this.updateApplication (application);
    },

    async changeSynchronise(event, appId) {
      let application = await this.$store.dispatch('getApplicationId', appId);
      application.syncPM = event;
      this.updateApplication(application);
    },

    async deleteService () {
      this.$store.dispatch('setLoadingApp', 1);
      this.confirmDeleteServiceDialog = false;
      let serviceId = this.serviceIdToDelete;
      let posService = this.updatedServices.map(function(e) { return e.id; }).indexOf(serviceId);
      let appId = this.updatedServices[posService].application_id;
      // check resources
      let resourcesThisServices = this.resources.filter(function(select_item) {
        return select_item.service_id === serviceId;
      })
      for (let index = 0; index < resourcesThisServices.length; index++) {
        if (await this.checkResourceBeforeDelete(resourcesThisServices[index].id) === false) {
          this.serviceIdToDelete = null;
          this.$store.dispatch('setLoadingApp', 0);
          return;
        }
      }
      if (appId !== null) {
        let tmpServices = this.updatedServices.filter(function(select_item) {
          return select_item.application_id === appId;
        })
        if (tmpServices.length === 1 && tmpServices[0].amountOfClients === 1) {
          if (tmpServices[0].amountPaidApplication > 0 && tmpServices[0].amountPaidApplication !== null) {
            this.$store.dispatch('errorMessage', 'A payment already exist for the application');
            this.$store.dispatch('setLoadingApp', 0);
            return;
          } else {
            this.appIdToDelete = appId;
            this.confirmDeleteAppDialog = true;
            this.$store.dispatch('setLoadingApp', 0);
            return;
          }
          
        }
      }
      await this.$store.dispatch('deleteService', serviceId)
      await this.getServicesAndApps('byAppDate')
      if (appId !== null) {
        this.changeAppTitlePrice(appId)
      }
      await this.getClientId();
      this.$store.dispatch('successMessage', 'Service successfully removed');
      this.synchroniseClientPM();
      this.serviceIdToDelete = null;
      this.$store.dispatch('setLoadingApp', 0);
    },

    async deleteResource () {
      this.confirmDeleteResourceDialog = false;
      if (await this.checkResourceBeforeDelete(this.resourceIdToDelete)) {
        await this.$store.dispatch('deleteResource', this.resourceIdToDelete);
        await this.getClientId();
        this.$store.dispatch('successMessage', 'Resource successfully removed'); 
      }
    },

    async checkResourceBeforeDelete(resourceId) {
      let posRes = this.resources.map(function(e) { return e.id; }).indexOf(resourceId);
      if (this.resources[posRes].type_id === 3) {
        let classesResource = await this.$store.dispatch('getClassesResourceId', resourceId)
        if (classesResource.length > 0) {
          this.$store.dispatch('errorMessage', 'Classes already planned');
          return false;
        }
      }
      if ((this.resources[posRes].type_id === 1 || this.resources[posRes].type_id === 2) && this.resources[posRes].placement_id > 0) {
        this.$store.dispatch('errorMessage', 'Project already planned');
        return false;
      }
      if (this.resources[posRes].type_id === 4 && this.resources[posRes].room_id > 0) {
        this.$store.dispatch('errorMessage', 'Accommodation already planned');
        return false;
      }
      if (this.resources[posRes].type_id === 5 && this.resources[posRes].tour_placement_id > 0) {
        this.$store.dispatch('errorMessage', 'Tour already planned');
        return false;
      }
      return true;
    },
    
    getImage(id) {
      if (this.$store.state.available_images && id) {
        const tmp = this.$store.state.available_images.filter(function(select_item) {
          return select_item.id === id;
          }
        )
        if (tmp[0]) {
          let returnSrc = JSON.parse(zlib.unzipSync(Buffer.from(tmp[0].image, 'base64')));
          return returnSrc;
        }
        return null;
      } else {
        return null
      } 
    },

    getStartDays(value) {
      const date = dayjs(value);
      const day = date.format("dddd").toLowerCase();
      return this.startDays.includes(day);
    },

    getEndDays(value) {
      const date = dayjs(value);
      const day = date.format("dddd").toLowerCase();
      return this.endDays.includes(day);
    },

    changeAllowedStartDays(service_number) {
      if (this.$store.state.available_services.filter(function(select_item) {
          return select_item.service_number === service_number;
        }).length > 0) {
        this.startDays = this.$store.state.available_services.filter(function(select_item) {
          return select_item.service_number === service_number;
        })[0].allowedStartDays
      } else {
        this.startDays = 'sunday, saturday, monday, tuesday, wednesday, thursday, friday'
      }
    },

    changeAllowedEndDays(service_number) {
      if (this.$store.state.available_services.filter(function(select_item) {
          return select_item.service_number === service_number;
        }).length > 0) {
        this.endDays = this.$store.state.available_services.filter(function(select_item) {
          return select_item.service_number === service_number;
        })[0].allowedEndDays
      } else {
        this.endDays = 'sunday, saturday, monday, tuesday, wednesday, thursday, friday'
      }
    },

    clearStartDate(pos) {
      this.updatedServices[pos].start = null;
      this.updatedServices[pos].end = null;
      if (this.updatedServices[pos].duration_type !== "fixed") {
        this.updatedServices[pos].duration = this.updatedServices[pos].min_duration;
      }
    },

    clearEndDate(pos) {
      this.updatedServices[pos].end = null;
      if (this.updatedServices[pos].min_duration !== null) {
        this.updatedServices[pos].duration = this.updatedServices[pos].min_duration;
      } else {
        this.updatedServices[pos].duration = null;
      }
    },

    disabledEndDate (id, pos) {
      if (id) {
        if (!this.updatedServices[pos].service_number) {
          return true;
        }
        if ((this.updatedServices[pos].duration_type === "fixed" && this.updatedServices[pos].duration_text !== "hour(s)" && this.updatedServices[pos].duration_text !== "afternoon" && this.updatedServices[pos].duration_text !== "morning")) {
          return true;
        }
      }
      return false;
    },

    getMinEndDate (pos) {
      if (this.updatedServices[pos].start) {
        if (this.updatedServices[pos].min_duration && this.updatedServices[pos].duration_type === "businessWeeks") {
          return this.addbusinessWeeks(this.updatedServices[pos].start, (this.updatedServices[pos].min_duration * 5) - 1);
        }
        if (this.updatedServices[pos].min_duration && this.updatedServices[pos].duration_type === "businessDays") {
          return this.addbusinessWeeks(this.updatedServices[pos].start, (this.updatedServices[pos].min_duration) - 1);
        }
        if (this.updatedServices[pos].min_duration && this.updatedServices[pos].duration_type === "nights") {
          return this.addDays(this.updatedServices[pos].start, this.updatedServices[pos].min_duration);
        }
        return this.updatedServices[pos].start;
      } else {
        return null;
      }
    },

    async calcDatesServices(action, pos, radioGroup) {
      if (this.initializing) {
        return;
      }
      console.log('calcDatesServices #1', action, pos, radioGroup);
      var avRadioOptions;
      if (radioGroup) {
        avRadioOptions = this.$store.state.radioOptions.filter(function(item) {
          return item.radio_group_id === radioGroup.radio_group_id && item.label === radioGroup.radio_group_value && (item.duration !== null || item.min_duration !== null);
        })
        if (avRadioOptions) {
          if (avRadioOptions.length > 0) {
            if (avRadioOptions[0].duration) {
              this.updatedServices[pos].duration = avRadioOptions[0].duration;
            }
            if (avRadioOptions[0].min_duration && this.updatedServices[pos].duration < avRadioOptions[0].min_duration) {
              this.updatedServices[pos].duration = avRadioOptions[0].min_duration;
            }
          }
        }
      }
      if (this.updatedServices[pos].service_number) {
        let serviceNr = this.updatedServices[pos].service_number;
        let priceTable = await this.$store.dispatch('getAvailablePriceTable', serviceNr);
        if (priceTable.length > 0) {
          if (action === 'start' || action === 'end') {
            if (this.updatedServices[pos].start && this.updatedServices[pos].end) {
              let days = Math.ceil(this.diffDates(this.updatedServices[pos].start, this.updatedServices[pos].end));
              this.updatedServices[pos].duration = Math.ceil(days / 7);
            }
          }
          if (action === 'duration') {
            if (priceTable.length < this.updatedServices[pos].duration) {
              this.updatedServices[pos].duration = priceTable.length;
            }
            if (this.updatedServices[pos].start) {
              this.updatedServices[pos].end = this.addDays(this.updatedServices[pos].start, this.updatedServices[pos].duration * 7);
            }
          }
        }
        if(this.updatedServices[pos].duration_type === "weeks") {
          if ((action === 'duration' && this.updatedServices[pos].start) || ((action === 'start' && this.updatedServices[pos].duration))) {
            this.updatedServices[pos].end = this.addDays(this.updatedServices[pos].start, this.updatedServices[pos].duration * 7);
          }
          if (action === 'start' || action === 'end') {
            if (this.updatedServices[pos].start && this.updatedServices[pos].end) {
              let days = Math.ceil(this.diffDates(this.updatedServices[pos].start, this.updatedServices[pos].end));
              let weeks = Math.ceil(days / 7);
              if (weeks < this.updatedServices[pos].min_duration) {
                this.updatedServices[pos].end = this.addDays(this.updatedServices[pos].start, this.updatedServices[pos].min_duration * 7);
              } else {
                this.updatedServices[pos].duration = weeks;
              }
            }
          }
        }
        if(this.updatedServices[pos].duration_type === "businessWeeks") {
          if ((action === 'duration' && this.updatedServices[pos].start) || ((action === 'start' && this.updatedServices[pos].duration))) {
            this.updatedServices[pos].end = this.addbusinessWeeks(this.updatedServices[pos].start, ((this.updatedServices[pos].duration * 5)-1));
          }
          if (action === 'start' || action === 'end') {
            if (this.updatedServices[pos].start && this.updatedServices[pos].end) {
              let weeks = ((dayjs(this.updatedServices[pos].end).businessDiff(dayjs(this.updatedServices[pos].start))+1)/5);
              if (weeks < this.updatedServices[pos].min_duration) {
                this.updatedServices[pos].end = this.addbusinessWeeks(this.updatedServices[pos].start, ((this.updatedServices[pos].duration * 5)-1));
              } else {
                this.updatedServices[pos].duration = Math.ceil(weeks);
              }
            }
          }
        }
        if(this.updatedServices[pos].duration_type === "businessDays") {
          if (action === 'start' || action === 'end') {
            if (this.updatedServices[pos].start && this.updatedServices[pos].end) {
              let days = ((dayjs(this.updatedServices[pos].end).businessDiff(dayjs(this.updatedServices[pos].start))+1));
              if (days < this.updatedServices[pos].min_duration && action === 'start') {
                this.updatedServices[pos].duration = this.updatedServices[pos].min_duration;
                this.updatedServices[pos].end = null;
              } else {
                this.updatedServices[pos].duration = days;
              }
            }
          }
        }
        if(this.updatedServices[pos].duration_type === "nights") {
          if (action === 'start' || action === 'end') {
            if (this.updatedServices[pos].start && this.updatedServices[pos].end) {
              let nights = Math.ceil(this.diffDates(this.updatedServices[pos].start, this.updatedServices[pos].end));
              if (nights < this.updatedServices[pos].duration && action === 'start') {
                this.updatedServices[pos].duration = this.updatedServices[pos].min_duration;
                this.updatedServices[pos].end = null;
              } else {
                this.updatedServices[pos].duration = nights;
              }
            } else {
                this.updatedServices[pos].duration = this.updatedServices[pos].min_duration;
            }
          }
        }
        if(this.updatedServices[pos].duration_type === "fixed") {
          if (action === 'start' && this.updatedServices[pos].start) {
            if (this.updatedServices[pos].duration_text === 'week(s)') {
              this.updatedServices[pos].end = this.addWeeks(this.updatedServices[pos].start, this.updatedServices[pos].duration);
            }
            else if (this.updatedServices[pos].duration_text === 'morning' || this.updatedServices[pos].duration_text === 'afternoon') {
              this.updatedServices[pos].end = this.updatedServices[pos].start;
            }
            else {
              this.updatedServices[pos].end = this.addDays(this.updatedServices[pos].start, this.updatedServices[pos].duration - 1);
            }
          }
        }
        console.log('calcDatesServices #end');
        await this.calcPriceService(pos);
      }
    },

    async calcDatesResource(action) {
      console.log('calcDatesResource action = ' , action)
      if(this.resourceNew.type_id === 1 || this.resourceNew.type_id === 2) {
        if (this.resourceNew.start && ((action === 'duration' && this.resourceNew.start) || ((action === 'start' && this.resourceNew.duration)))) {
          console.log('calcDatesResource test1')
          this.resourceNew.end = this.addbusinessWeeks(this.resourceNew.start, ((this.resourceNew.businessWeeks * 5)-1));
        }
        if (action === 'start' || action === 'end') {
          if (this.resourceNew.start && this.resourceNew.end) {
            let weeks = ((dayjs(this.resourceNew.end).businessDiff(dayjs(this.resourceNew.start))+1)/5);
            if (weeks < this.resourceNew.min_duration) {
              this.resourceNew.end = this.addbusinessWeeks(this.resourceNew.start, ((this.resourceNew.duration * 5)-1));
            } else {
              this.resourceNew.duration = Math.ceil(weeks);
              this.resourceNew.businessWeeks = Math.ceil(weeks);
            }
          }
        }
      }
      if(this.resourceNew.type_id === 3 || this.resourceNew.type_id === 8) {
        console.log('calcDatesResource #1')
        if ((action === 'duration' && this.resourceNew.start) || ((action === 'start' && this.resourceNew.duration && this.resourceNew.start))) {
          console.log('calcDatesResource #2', this.resourceNew.start , this.resourceNew.duration)
          this.resourceNew.end = this.addbusinessWeeks(this.resourceNew.start, ((this.resourceNew.duration * 5)-1));
        } else if (action === 'start' || action === 'end') {
          if (this.resourceNew.start && this.resourceNew.end) {
            let weeks = ((dayjs(this.resourceNew.end).businessDiff(dayjs(this.resourceNew.start))+1)/5);
            if (weeks < this.resourceNew.min_duration) {
              this.resourceNew.end = this.addbusinessWeeks(this.resourceNew.start, ((this.resourceNew.duration * 5)-1));
            } else {
              this.resourceNew.duration = Math.ceil(weeks);
            }
          }
        }
      }
      if(this.resourceNew.type_id === 4) {
        if ((action === 'duration' && this.resourceNew.start) || ((action === 'start' && this.resourceNew.duration))) {
          this.resourceNew.end = this.addDays(this.resourceNew.start, (this.resourceNew.duration));
        }
        if (action === 'start' || action === 'end') {
          if (this.resourceNew.start && this.resourceNew.end) {
            let nights = Math.ceil(this.diffDates(this.resourceNew.start, this.resourceNew.end));
            if (nights > this.resourceNew.min_duration) {
              if (action === 'start') {
                this.resourceNew.start = this.addDays(this.resourceNew.end, (-this.resourceNew.min_duration));
              } else {
                this.resourceNew.end = this.addDays(this.resourceNew.start, (this.resourceNew.min_duration));
              }
            }
            this.resourceNew.duration = Math.ceil(this.diffDates(this.resourceNew.start, this.resourceNew.end));
          } 
        }
      }
      if(this.resourceNew.type_id === 5) {
        if (action === 'start') {
          if (this.resourceNew.duration_text === 'weeks') {
            this.resourceNew.end = this.addWeeks(this.resourceNew.start, this.resourceNew.duration);
          }
          else if (this.resourceNew.duration_text === 'morning' || this.resourceNew.duration_text === 'afternoon') {
            this.resourceNew.end = this.resourceNew.start;
          }
          else {
            this.resourceNew.end = this.addDays(this.resourceNew.start, this.resourceNew.duration);
          }
        }
      }
    },

    async calcPriceService(pos) {
      console.log('calcPriceService pos = ' , pos)
      let serviceNr = this.updatedServices[pos].service_number;
      if (pos !== null) {
        let servicePrice = 0;
        let varPrice = 0;
        let options = this.updatedOptions;
        if (options.length > 0) {
          let i = 0;
          do {
            if (options[i].selected && options[i].services_id === this.updatedServices[pos].id) {
              servicePrice = servicePrice + options[i].price;
              varPrice = varPrice + options[i].varPrice;
            }
            i++;
          }
          while (options[i]);
        }
        if (isNaN(varPrice)) {varPrice = 0}
        if (isNaN(servicePrice)) {servicePrice = 0}
        let radioGroups = this.updatedRadioGroups;
        if (radioGroups.length > 0) {
          let i = 0;
          do {
            let j = 0;
            console.log('calcPriceService #1a' , radioGroups.length, radioGroups[i].radio_group_id, radioGroups[i].services_id, this.$store.state.radioOptions)
            let avRadioOptions = this.$store.state.radioOptions.filter(function(item) {
              return item.radio_group_id === radioGroups[i].radio_group_id && item.services_id === radioGroups[i].services_id;
            })
            do {
              console.log('calcPriceService #1b' , avRadioOptions[j])
              console.log('calcPriceService #2' , avRadioOptions[j].label)
              if (avRadioOptions[j].label === radioGroups[i].radio_group_value && radioGroups[i].services_id === this.updatedServices[pos].id) {
                servicePrice = servicePrice + avRadioOptions[j].price;
                if (avRadioOptions[j].discount_after < this.updatedServices[pos].duration && avRadioOptions[j].discount_percentage) {
                  varPrice = varPrice + ((avRadioOptions[j].varPrice * avRadioOptions[j].discount_after) + ((avRadioOptions[j].varPrice - (avRadioOptions[j].varPrice * avRadioOptions[j].discount_percentage/100)) * (this.updatedServices[pos].duration - avRadioOptions[j].discount_after)))/this.updatedServices[pos].duration;
                } else {
                  varPrice = varPrice + avRadioOptions[j].varPrice;
                }
              }
              j++;
            }
            while (avRadioOptions[j]);
            i++;
          }
          while (radioGroups[i]);
        }
        if (isNaN(varPrice)) {varPrice = 0}
        if (isNaN(servicePrice)) {servicePrice = 0}
        let result = await this.$store.dispatch('getAvailablePriceTable', serviceNr);
        let available_services_edit = await this.$store.dispatch('getAvailableServicesEdit');
        let avServ = available_services_edit.filter(function(select_item) {
          return select_item.service_number === serviceNr;
        })
        if (result.length > 0) {
          let weeks;
          if (result.length < this.updatedServices[pos].duration) {
            weeks = result.length;
            this.$store.dispatch('warningMessage', 'Price calculated for ' + result.length + ' weeks')
          } else {
            weeks = this.updatedServices[pos].duration;
          }
          let price;
          if (weeks > 0) {
            price = (result.filter(function(item) {
              return item.weeks === weeks;
            }))[0].price;
          } else {
            price = 0;
          }
          if (avServ.length > 0) {
            this.updatedServices[pos].servicePrice = (this.updatedServices[pos].duration * varPrice) + servicePrice + price + avServ[0].price;
          }
          return;
        } else {
          if (avServ.length > 0) {
            if (avServ[0].price_type === 'fixed') {
              this.updatedServices[pos].servicePrice = avServ[0].price + servicePrice;
            } else {
              this.updatedServices[pos].servicePrice = (this.updatedServices[pos].duration * (avServ[0].varPrice + varPrice)) + servicePrice + avServ[0].price;
            }
          }
          return;
        }
      }
    },

    async updateApplication(application) {
      console.log('updateApplication', application);
      let n = new Date();
      let today = this.formattedDate(n, 'date');
      (application.invoice_date) ? application.invoice_date = this.formattedDate(application.invoice_date, 'date') : application.invoice_date = today;
      await this.$store.dispatch('insertApplication', application);
      // below not effictient!!!!
      await this.$store.dispatch('getApplications', this.$store.state.client.id);
      await this.getServicesAndApps('byAppDate');
      this.synchroniseClientPM();
      return;
    },

    getStatusResource(id) {
      if (id) {
        return this.$store.state.statusResources.filter((select_item) => {
            return select_item.id === id;
          })[0];
      } else {
        return [];
      }
    },

    async applyServices () {
      // all new services
      var newAppServices = this.updatedServices.filter(function(item) {
        return item.application_id === null;
      })
      this.openPanel = [];
      const date = new Date();
      let idAppNew = date.getTime();
      let pos;
      for (let i = 0; i < newAppServices.length; i++) {
        pos = this.updatedServices.map(function(e) { return e.id; }).indexOf(newAppServices[i].id);
        this.updatedServices[pos].application_id = idAppNew;
      }
      let newApp = {};
      newApp.id = idAppNew;
      newApp.autoAppTitle = true;
      newApp.amountOfClients = 1;
      newApp.syncPM = true;
      if (this.client.agency_id !== null) {
        newApp.payerAgency = this.client.agency_id;
        newApp.payerClient = null;
        newApp.ignore_deposit = true;
        let agency_id = this.client.agency_id;
        let tmp = this.$store.state.available_agencies.filter(function(select_item) {
            return select_item.id === agency_id;
        });
        newApp.transferFeeIncluded = tmp[0].transferFeeIncluded;
      } else {
        newApp.payerClient = this.client.id;
        newApp.payerAgency = null;
        newApp.ignore_deposit = false;
        newApp.transferFeeIncluded = false;
      }
      newApp.appTitle = await this.createAppTitle(newApp, newAppServices);
      newApp.exchangeRate = this.$store.state.exchangeRate;
      await this.$store.dispatch('insertApplication', newApp)
      for (let i = 0; i < newAppServices.length; i++) {
        await this.$store.dispatch('serviceUpdate', newAppServices[i]);
      }
      await this.getServicesAndApps('byAppDate');
      this.synchroniseClientPM();
    },
    
    async deleteApplication() {
      this.$store.dispatch('setLoadingApp', 1);
      await this.$store.dispatch('deleteApplication', this.appIdToDelete)
      await this.$store.dispatch('deleteApplicationPM', this.appIdToDelete)
      await this.getServicesAndApps('byAppDate');
      this.appIdToDelete = null;
      this.confirmDeleteAppDialog = false;
      if (this.serviceIdToDelete) {
        this.deleteService();
      }
    },

    priceInDollars (price) {
      return parseFloat(price/this.$store.state.exchangeRate).toFixed(2);
    },

    addWeeks(startDate, weeks) {
      let new_date = dayjs(startDate).add((weeks * 7), 'd');
      let day = new_date.format('DD');
      let month = new_date.format('MM');
      let year = new_date.format('YYYY');
      return year + '-' + month + '-' + day
    },

    addWeeksWorkWeek(startDate, weeks) {
      let new_date = dayjs(startDate).add(((weeks * 7) - 3), 'd');
      let day = new_date.format('DD');
      let month = new_date.format('MM');
      let year = new_date.format('YYYY');
      return year + '-' + month + '-' + day
    },

    addDays(startDate, days) {
      let new_date = dayjs(startDate).add(days, 'd');
      let day = new_date.format('DD');
      let month = new_date.format('MM');
      let year = new_date.format('YYYY');
      return year + '-' + month + '-' + day
    },

    diffDates(startDate, endDate) {
      return dayjs(endDate).diff(dayjs(startDate), 'd');
    },
    
    addbusinessWeeks(startDate, days) {
      let new_date = dayjs(startDate).businessDaysAdd(days, 'd');
      let day = new_date.format('DD');
      let month = new_date.format('MM');
      let year = new_date.format('YYYY');
      return year + '-' + month + '-' + day
    },

    async addProvider(resource_type) {
      this.dialog_add_resource = false;
      let currentServiceId = this.serviceIdToResource;
      let currentService = this.updatedServices.filter(function(select_item) {
        return select_item.id === currentServiceId;
      })[0];
      let resourcesSameType = this.resources.filter(function(select_item) {
        return select_item.type_id === resource_type;
      });
      if (resourcesSameType.length === 0) {
        var number_type = 1
      } else {
        number_type = Math.max.apply(Math, resourcesSameType.map(function(o) { return o.number_type; }));
        number_type++;
      }
      let newResource = { 
        id: null, number_type: number_type, 
        service_id: currentServiceId, 
        type_id: resource_type, 
        category_id: null, 
        project_id: null, 
        placement_id: null, 
        course_id: null, 
        class_language: 'Spanish', 
        class_location: 'School Cusco', 
        class_type: 'Normal', 
        class_group: 'Group', 
        hours: null, 
        hours_per_class: null, 
        family: 1, 
        accommodation_class: 'Private Room', 
        private_bathroom: 0, 
        start: currentService.start, 
        end: currentService.end, 
        duration: currentService.duration, 
        donation: null, 
        donation_type: null, 
        payment: null, 
        currency: null 
      };
      this.insertUpdateResource (newResource);
    },

    categoriesProjects (resourceType) {
      let type;
      if (resourceType === 1) {
        type = "Volunteering"
      } else {
        type = "Internship"
      }
      let selection = this.$store.state.projects.filter(function(select_item) {
        return select_item.type === type;
      });
      return selection.sort(this.dynamicSort('category'));
    },

    filterProjects (resourceType, categoryId, projectId) {
      let type;
      var selection;
      if (resourceType === 1) {
        type = "Volunteering"
      } else {
        type = "Internship"
      }
      if (projectId && !categoryId) {
        console.log(resourceType, categoryId, projectId);
        selection = this.$store.state.projects.filter(function(select_item) {
          return select_item.id === projectId && select_item.type && select_item.obsolete === null;
        })
      } else {
        if (categoryId) {
          selection = this.$store.state.projects.filter(function(select_item) {
            return select_item.category_id === categoryId && select_item.type === type && select_item.obsolete === null;
          })
        } else {
          selection = this.$store.state.projects.filter(function(select_item) {
            return select_item.type === type && select_item.obsolete === null;
          })
        }
      }
      return selection.sort(this.dynamicSort('name'));
    },

    async insertUpdateResource (resource) {
      this.$store.dispatch('setLoadingApp', 1);
      if (resource.family === '1' && resource.accommodation_class === 'Shared Bunk') {
        resource.accommodation_class = 'Private Room';
      }
      if (!resource.hours > 0) {
        resource.hours = 0;
      }
      await this.$store.dispatch('insertResource', resource);
      await this.getResources();
      this.$store.dispatch('setLoadingApp', 0);
    },

    async copyClient() {
      this.$store.dispatch('setLoadingApp', 1);
      let payload = JSON.parse(JSON.stringify(this.client));
      var date = new Date();
      const id = date.getTime();
      payload.id = id;
      payload.first_name = 'COPY OF ' + payload.first_name;
      payload.birthdate = null;
      payload.handbookSent = null;
      payload.first_schedule_sent = null;
      payload.food_preference = null;
      payload.pets_preference = null;
      payload.spanishTestDone = null;
      payload.initial_spanish_level = null;
      payload.email = null;
      payload.welcome_email = null;
      payload.ignore_welcome_email = 0;
      payload.ignore_security_deposit = 0;
      payload.thank_you_email = null;
      payload.ignore_thank_you_email = 0;
      payload.statusFixed = 0;
      await this.$store.dispatch('addLead', payload);
      // copy services + options + radiogroups
      let payloadServices = JSON.parse(JSON.stringify(this.updatedServices));
      let payloadOptionsTotal = JSON.parse(JSON.stringify(this.$store.state.options));
      let payloadRadioGroupsTotal = JSON.parse(JSON.stringify(this.$store.state.radioGroups));
      let payloadResourcesTotal = JSON.parse(JSON.stringify(this.resources));
      let payloadOptions;
      let payloadRadioGroups;
      let payloadResources;
      for (let index = 0; index < payloadServices.length; index++) {
        payloadOptions = payloadOptionsTotal.filter(function(select_item) {
          return select_item.services_id === payloadServices[index].id;
        })
        payloadRadioGroups = payloadRadioGroupsTotal.filter(function(select_item){
          return select_item.services_id === payloadServices[index].id;
        })
        payloadResources = payloadResourcesTotal.filter(function(select_item){
          return select_item.service_id === payloadServices[index].id;
        })
        date = new Date();
        payloadServices[index].id = date.getTime();
        payloadServices[index].client_id = id;
        await this.$store.dispatch('addService', payloadServices[index]);
        if (payloadServices[index].application_id !== null) {
          this.changeAppTitlePrice(payloadServices[index].application_id)
        }
        for (let index2 = 0; index2 < payloadOptions.length; index2++) {
          payloadOptions[index2].services_id = payloadServices[index].id;
          await this.$store.dispatch('addOption', payloadOptions[index2]);
        }
        for (let index2 = 0; index2 < payloadRadioGroups.length; index2++) {
          payloadRadioGroups[index2].services_id = payloadServices[index].id;
          await this.$store.dispatch('addRadioGroup', payloadRadioGroups[index2]);
          let radioOptionsSelected = await this.$store.dispatch('getAvailableRadioOptions_id', payloadRadioGroups[index2].radio_group_id);
          for (let index3 = 0; index3 < radioOptionsSelected.length; index3++) {
            radioOptionsSelected[index3].services_id = payloadRadioGroups[index2].services_id;
            await this.$store.dispatch('addRadioOption', radioOptionsSelected[index3]);
          }
        }
        // copy resources without assignments
        for (let index2 = 0; index2 < payloadResources.length; index2++) {
          payloadResources[index2].id = null;
          payloadResources[index2].service_id = payloadServices[index].id;
          payloadResources[index2].placement_id = null;
          payloadResources[index2].course_id = null;
          payloadResources[index2].room_id = null;
          payloadResources[index2].tour_placement_id = null;
          payloadResources[index2].info_to_client = null;
          payloadResources[index2].info_to_provider = null;
          payloadResources[index2].reserved = null;
          payloadResources[index2].payment_info_sent = null;
          await this.$store.dispatch('insertResource', payloadResources[index2]);
        }
      }
      this.$route.params.id = id;
      this.$store.dispatch('successMessage', 'Client successfully copied');
      this.initialize(id);
    },

    async savePreset(service_number, serviceId){
      // delete all presets
      await this.$store.dispatch('deleteResourcePresets', service_number);
      // save again
      let payloadResourcesTotal = JSON.parse(JSON.stringify(this.resources));
      let payloadResources = payloadResourcesTotal.filter(function(select_item){
        return select_item.service_id === serviceId;
      })
      for (let index = 0; index < payloadResources.length; index++) {
        payloadResources[index].service_number = service_number;
        payloadResources[index].placement_id = null;
        payloadResources[index].course_id = null;
        payloadResources[index].room_id = null;
        payloadResources[index].tour_placement_id = null;
        payloadResources[index].info_to_client = null;
        payloadResources[index].info_to_provider = null;
        payloadResources[index].reserved = null;
        payloadResources[index].start = null;
        payloadResources[index].end = null;
        payloadResources[index].payment_info_sent = null;
        await this.$store.dispatch('insertResourcePreset', payloadResources[index]);
      }
      this.$store.dispatch('successMessage', 'Preset successfully saved');
    },

    getStatusService(currentStatus) {
      if (currentStatus > 0) {
        const status = this.$store.state.statusServices.filter(function(select_item) {
          return select_item.id === currentStatus;
        })
        return status[0];
      }
    },

    getStatusClient(id) {
      return this.$store.state.statusClients.filter((select_item) => {
            return select_item.id === id;
          })[0];
    },

    async sentWelcomeEmail(addPayment) {
      console.log('sentWelcomeEmail', this.school_welcome_email);
      let payload = {};
      let emailIntro = '';
      if (this.school_welcome_email === 'english') {
        emailIntro = "<strong>¡Bienvenido a Proyecto Perú Inglés " + this.updatedClient.full_name + "!</strong> \n\n\n"
        emailIntro += "<strong>Normas y Protocolos</strong> \n\n"
        emailIntro += "Estamos muy emocionados de tenerte con nosotros y confiamos en que disfrutarás de esta experiencia de aprendizaje. " + 
        "Nuestro objetivo es apoyarte en tu camino hacia la fluidez en inglés, asegurándonos de que aproveches al máximo cada clase. " + 
        "Si tienes alguna pregunta o inquietud, no dudes en ponerte en contacto con nosotros. ¡Nos vemos en clase! \n\n"
        emailIntro += "<strong>RESPONSABILIDADES Y EXPECTATIVAS</strong> \n"
        emailIntro += "<ol type='1'>"
        emailIntro += "<li>Si el ESTUDIANTE llega tarde, el profesor esperará al ESTUDIANTE solo 20 minutos. " +
        "Si el ESTUDIANTE llega más de 20 minutos tarde sin haber contactado a PPI o simplemente no se presenta a la clase sin aviso previo, el ESTUDIANTE perderá la clase.</li>"
        emailIntro += "<li>Si el ESTUDIANTE necesita cancelar o reprogramar una clase programada, el ESTUDIANTE se compromete a contactar a PPI al menos 24 horas antes para reprogramar su clase. " + 
        "El ESTUDIANTE puede contactar directamente a PPI al (+51 941 111 085). Si la cancelación ocurre con menos de 24 horas de antelación o si el ESTUDIANTE no asiste a la clase, el ESTUDIANTE perderá la lección.</li>"
        emailIntro += "<li>Al elegir el paquete de grupo, debes seleccionar un horario y días fijos. Una vez elegido, debes comprometerte a asistir esos días y horas todas las semanas. " + 
        "Los paquetes de grupo no son flexibles, por lo que no se pueden cambiar los horarios una vez establecidos.</li>"
        emailIntro += "</ol>"
        emailIntro += "<strong>OBJETIVOS Y MÉTODO DE ENSEÑANZA</strong> \n"
        emailIntro += "<ol type='1'>"
        emailIntro += "<li><strong>Comunicación:</strong> " +
        "Fomentamos la comunicación abierta. Si tienes dudas sobre el contenido del curso o necesitas ayuda, habla con tu profesor durante la clase o envía un mensaje a PPI.</li>"
        emailIntro += "<li><strong>Participación:</strong> " +
        "La participación activa en clase es esencial para el aprendizaje. Se alienta a los ESTUDIANTES a hacer preguntas y compartir sus experiencias para enriquecer la experiencia de aprendizaje grupal.</li>"
        emailIntro += "<li><strong>Asistencia:</strong> " +
        "La asistencia regular es crucial para el progreso. Si un ESTUDIANTE tiene dificultades para asistir a las clases, debe contactar al profesor o al equipo de PPI para discutir opciones.</li>"
        emailIntro += "<li><strong>Código de Conducta:</strong> " +
        "Se espera que todos los ESTUDIANTES mantengan una actitud respetuosa hacia el profesor y los compañeros. Esto crea un ambiente de aprendizaje positivo y productivo.</li>"
        emailIntro += "</ol>"
        emailIntro += "<strong>Información de Cuenta:</strong> \n\n"
        emailIntro += "Nombre: Proyecto Perú Centre\n"
        emailIntro += "Cuenta BCP (Soles): 2852239009038\n"
        emailIntro += "Cuenta Interbank: 0022850022390090385\n\n"
        emailIntro += "¡Avísame si necesitas algún ajuste adicional o ayuda! \n\n"
        payload = {
            submittedFrom: "WecomeEmail",
            emailList: this.updatedClient.email,
            emailSubject: "¡Bienvenido a Proyecto Perú Inglés!",
            emailIntro: emailIntro,
            messageHTML: '',
            messagePlain: '',
            openEmailDialog: true,
            saveInvoice: false,
            attachments: '',
        };
      } else {
        let organisation = 'Proyecto Peru';
        if (this.updatedClient.agency_id) {
          organisation = this.updatedClient.agency
        }
        let medical = this.resources.filter(function(select_item) {
          return select_item.category_id === 2;
        })
        let accommodation = this.resources.filter(function(select_item) {
          return select_item.type_id === 4;
        })
        // HTML version 
        if (organisation === 'Proyecto Peru') {
          emailIntro = "Hi " + this.updatedClient.full_name + ",\n\n" + "We are exited to hear that you have decided to join Proyecto Peru! My name is " + this.$store.state.user + " and I will be helping to organize everything for you. " + 
          "We have received your application and are reviewing it carefully to ensure that we select the most appropriate placement and accommodation home-stay for you. \n\n"
          if (addPayment) {
            emailIntro += "We have also received your deposit of $... . The remainder of $... needs to be paid during the first three days of your services preferably in cash at our office in USD or Soles.\n\n"
          }
          emailIntro += "In the meantime, could you please complete our <Spanish Placement Test>, if you haven't done so already. This will help us to find the best placement for you. " + 
          "You can take Spanish classes while you do your program or online before you get to Cusco, just let us know. \n\n" + 
          "You will receive further details from us (via email) one month prior to your arrival with more information about your placement and home-stay. \n\n"
        } else {
          emailIntro = "Hi " + this.updatedClient.full_name + ",\n\n" + "It’s great to hear you’ve made the decision to join " + organisation + " and we're happy that you have chosen to join our program here in Cusco, Peru. My name is " + this.$store.state.user + " and I work with the Proyecto Peru team. \n" + 
          "We will be responsible for you during your stay here in Cusco, Peru and we look forward to hosting and supporting you. \n\n" +
          "We have received your application and are reviewing it carefully to ensure that we can select the most appropriate placement and accommodation home-stay for you. \n\n" + 
          "In the meantime, could you please complete our <Spanish Placement Test>, if you haven't done so already. This will help us to find the best placement for you. " + 
          "You can take Spanish classes while you do your program or online before you get to Cusco, just let us know. \n\n" + 
          "You will receive further details from us (via email) one month prior to your arrival with more information about your placement and home-stay. \n\n"
        }
        let counter = 0;
        if (accommodation.length > 0) {
          accommodation.sort(this.dynamicSort('start'));
          for (let index = 0; index < accommodation.length; index++) {
            if (accommodation[index].start && accommodation[index].end && accommodation[index].statusResourceId < 8) {
              if (counter === 0) {
                emailIntro += "Your accommodation is covered from "
              } else {
                emailIntro += ", "
              }
              emailIntro += this.formattedDate(accommodation[index].start) + " until " + this.formattedDate(accommodation[index].end) 
              counter = counter + 1;
            }
          }
        }
        if (counter === 0) {
          emailIntro += "You have not made reservations for any accommodation with us yet. "
        } else {
          emailIntro += ". "
        }
        emailIntro += "Should you need additional accommodation before or after your stay, please let us know as soon as possible, so that we can check availability and confirm the price of extra nights. \n\n"
        if (medical.length > 0) {
          emailIntro += "You are going to follow one of our medical programs placed with a medical clinic or healthcare institution which require you to use Personal Protective Equipment (PPE). " +
          "This includes a surgery gown and a bouffant cap. You have to wear these over your normal scrubs (any color scrubs). You may choose to bring your own PPE gear (scrubs, surgery gown and bouffant cap) or you can buy them in a shop in Cusco. " + 
          "We additionally provide interns with the opportunity to purchase the PPE from us during the orientation. We have secured an economic cost, which is US$3 per surgery gown and US$0.25 for the bouffant cap. " + 
          "If you don’t have scrubs, they can be purchased in Cusco for approx. US$10. Please advise us in advance if you would like to purchase the PPE gear from the team in Cusco. " + 
          "We can then ensure that the gear is delegated to you at the orientation. You can pay for the PPE then, in cash (in either USD or Peruvian Sol). \n\n"
        }
        emailIntro += "If you would like to arrange any tours during the weekends, we can help you book them with our partner agencies. " + 
        "Machu Picchu tickets sometimes sell out very quickly so we recommend booking this tour before your arrival. Just let us know and we can help you arrange it. We will need a copy of your passport if you book any tours. " +
        "Click <information on tours> for more information. \n\n" 
        if (this.updatedClient.agency_id === 2) {
          emailIntro += "Please continue to direct any questions about your upcoming program to Dallas at Intern Abroad HQ, as she remains your primary point of contact before you arrive in Cusco. \n\n"
        }
        emailIntro += "Please fill our <Application Form> if you haven't done so already.\n\n"
        emailIntro += "Once again, thank you for choosing " + organisation + "’s program. We look forward to meeting you soon! \n\n" +
        "Kind regards, \n" + 
        this.$store.state.user + "\n\n" + 
        "info@proyectoperucentre.org\n" +
        "Proyecto Peru Centre\n" +
        "Avenida Arcopata 466-A\n" +
        "Cusco, Cusco, 45\n" +
        "+ 51 84 240 278"
        let attachments = '';
        if (this.updatedClient.includeHandbook) {
          attachments = 'handbook';
          this.updatedClient.handbookSent = this.formattedDate(new Date(), 'date');
        }
        payload = {
            submittedFrom: "WecomeEmail",
            emailList: this.updatedClient.email,
            emailSubject: "Welcome at Proyecto Peru",
            emailIntro: emailIntro,
            messageHTML: '',
            messagePlain: '',
            openEmailDialog: true,
            saveInvoice: false,
            attachments: attachments,
        };
      }
      this.updatedClient.welcome_email = this.formattedDate(new Date(), 'date');
      this.saveClient();
      await this.$store.dispatch('emailMessage', payload);
    },

    async sentThankYouEmail () {
      // HTML version
      let emailIntro = "Hola " + this.updatedClient.first_name + ",\n\n" + "On behalf of the Proyecto Peru staff, we would like to thank you for being a part of our family! " + 
      "We hope you enjoyed your time here in Cusco and accomplished what you set out to do. \n\n" + 
      "We consider all of our students an integral part of our success and while we are sad for you to leave this beautiful city of Cusco, we are happy for your next adventure and hope you will keep in touch. " + 
      "We are proud to have a very diverse group of students and volunteers and we hope that you made lifelong friends during your time with us. \n\n"
      if (this.updatedClient.agency_id !== 1) {
        emailIntro = emailIntro + "At your earliest convenience, we would appreciate your help in writing a review on <google>. "
      }
      emailIntro = emailIntro + "Any comments would be greatly appreciated. We are continually looking for ways to improve and give our students the best possible experience. \n" + 
      "If you have any other concerns or comments, please feel free to email us directly as well and please do not be a stranger! Stop by the office anytime and say hello whenever you decide to come back to Cusco. \n\n" +
      "Check out our <online classes> to keep your Spanish up to date with your favorite teacher!\n\n" +
      "Hasta la proxima y cuídate, \n\n The Proyecto Peru Team, \n" + 
      "info@proyectoperucentre.org\n" +
      "Proyecto Peru Centre\n" +
      "Avenida Arcopata 466-A\n" +
      "Cusco, Cusco, 45\n" +
      "+ 51 950 301 314"
      let payload = {
          submittedFrom: "ThankYouEmail",
          emailList: this.updatedClient.email,
          emailSubject: "Thank you " + this.updatedClient.first_name,
          emailIntro: emailIntro,
          messageHTML: '',
          messagePlain: '',
          saveInvoice: false,
          openEmailDialog: true,
          attachments: null,
      };
      this.updatedClient.thank_you_email = this.formattedDate(new Date(), 'date');
      this.saveClient();
      await this.$store.dispatch('emailMessage', payload);
    },

    radioOptions_filtered (radioGroup) {
      let radioGroupId = radioGroup.radio_group_id;
      let servicesId = radioGroup.services_id;
      let arrayRadioOptions = this.$store.state.radioOptions.filter(function(item) {
          return item.radio_group_id === radioGroupId && item.services_id === servicesId;
      });
      arrayRadioOptions.sort(this.dynamicSort('id'));
      return arrayRadioOptions;
    },

    goTeaching(resourceId) {
      this.$router.push({ name: 'Teaching', params: { resourceId: resourceId } })
    },

    goTour(resourceId) {
      this.$router.push({ name: 'TourAgencies', params: { resourceId: resourceId } })
    },

    goProject(resourceId) {
      this.$router.push({ name: 'ProjectPlacements', params: { resourceId: resourceId } })
    },

    goAccommodation(resourceId) {
      this.$router.push({ name: 'Accommodation', params: { resourceId: resourceId } })
    },

    async cancelClient() {
      if (Math.min.apply(Math, this.resources.map(function(o) { return o.statusResourceId; })) < 5) {
        this.$store.dispatch('errorMessage', 'Some resources appear to be planned. Remove them first please.');
      } else {
        for (let index = 0; index < this.updatedApplications.length; index++) {
          this.appIdToDelete = this.updatedApplications.id;
          /* remove application */
        }
        this.updatedClient.statusFixed = 8;
        await this.saveClient();
        await this.initialize(this.updatedClient.id);
        this.$store.dispatch('successMessage', 'Client Cancelled');
      }
    },

    async contactNoResponse() {
      if (Math.min.apply(Math, this.resources.map(function(o) { return o.statusResourceId; })) < 5) {
        this.$store.dispatch('errorMessage', 'Some resources appear to be planned. Remove them first please.');
      } else {
        this.updatedClient.statusFixed = 9;
        await this.saveClient();
        await this.initialize(this.updatedClient.id);
        this.$store.dispatch('successMessage', 'Contact moved to status no response');
      }
    },

    async resetStatusClient() {
      this.updatedClient.statusFixed = 0;
      await this.saveClient();
      await this.initialize(this.updatedClient.id);
      this.$store.dispatch('successMessage', 'Reset done');
    },

    async showInvoice(appId) {
      let payload = {
        id: appId,
        archive: false,
      }
      await this.$store.dispatch('saveDataForReport', payload);
      this.$store.dispatch('showInvoice', true);
    },

    async goFinancial(item) {
        console.log(item);
        if (item === 'add') {
            this.$router.push({ name: 'Financial', params: { securityDeposit: 'add', clientId: this.updatedClient.id, incomeType: 26 } })
        } else {
            this.$router.push({ name: 'Financial', params: { securityDeposit: 'remove', clientId: this.updatedClient.id, outgoingType: 79 } })
        }
    },

    getProjectCategory(categoryId) {
      if(this.$store.state.projectCategories.length > 0) {
        return this.$store.state.projectCategories.filter(function(select_item) {
          return select_item.id === categoryId;
        })[0].name;
      } else {
        return [];
      }
    },

    getProjectName(projectId) {
      if(this.$store.state.projects.length > 0) {
        return this.$store.state.projects.filter(function(select_item) {
          return select_item.id === projectId;
        })[0].name;
      } else {
        return [];
      }
    },

    getTour(tourId) {
      if(this.tourSelect.length > 0) {
        return this.tourSelect.filter(function(select_item) {
          return select_item.id === tourId;
        })[0];
      } else {
        return [];
      }
    },

    edit_resource(resource, service, indexResources) {
      // console.log(resource);
      this.resourceNew = JSON.parse(JSON.stringify(resource));
      this.service = service;
      this.indexResources = indexResources;
    },

    async save_resource (resource) {
      console.log(resource);
      await this.insertUpdateResource(resource);
    },

    async saveService(pos) {
      this.$store.dispatch('setLoadingApp', 1);
      await this.calcPriceService(pos);
      await this.$store.dispatch('serviceUpdate', this.updatedServices[pos]);
      await this.getServicesAndApps('byAppDate');
      let payload = {};
      for (let index = 0; index < this.updatedRadioGroups.length; index++) {
        payload = {
          radio_group_id: this.updatedRadioGroups[index].radio_group_id, 
          services_id: this.updatedRadioGroups[index].services_id,
          radio_group_value: this.updatedRadioGroups[index].radio_group_value,
        };
        await this.$store.dispatch('setRadios', payload);
      }
      await this.$store.dispatch('getRadioGroups', this.updatedClient.id);
      this.updatedRadioGroups = JSON.parse(JSON.stringify(this.$store.state.radioGroups));
      for (let index = 0; index < this.updatedOptions.length; index++) {
        let payload = {
          option_id: this.updatedOptions[index].option_id,
          services_id: this.updatedOptions[index].services_id, 
          selected: this.updatedOptions[index].selected,
        };
        await this.$store.dispatch('setOptions', payload);
      }
      await this.$store.dispatch('getOptions', this.updatedClient.id);
      this.updatedOptions = JSON.parse(JSON.stringify(this.$store.state.options));
      this.synchroniseClientPM();
      this.$store.dispatch('setLoadingApp', 0);
    },

    async revertService() {
      this.$store.dispatch('setLoadingApp', 1);
      await this.getClientId();
      this.$store.dispatch('setLoadingApp', 0);
    },

    updatedRadioGroups_filtered(serviceId) {
      return this.updatedRadioGroups.filter(function(item) {
          return item.services_id === serviceId;
        }
      )
    },

    updatedOptions_filtered(serviceId) {
      return this.updatedOptions.filter(function(item) {
          return item.services_id === serviceId;
        }
      )
    },

    openDialogEditClientBasic() {
      this.editClient = Object.assign({}, this.updatedClient);
      this.dialogEditClientBasic = true;
    },   

    checkBoolean (boolean) {
      if (boolean) {
        if (boolean === 1) {
          return true;
        }
      }
      return false;
    },

    async scrollTo(id) {
      console.log(id);
      const element = await this.waitForElement(id);
      console.log(id, element);
      id = '#' + id;
      this.$vuetify.goTo(id);
    },

    async waitForElement(selector) {
      while (!document.getElementById(selector)) {
        await new Promise(resolve => requestAnimationFrame(resolve));
      }
      return document.getElementById(selector);
    },

    
    

  },
}
</script>

<style>
.text-input-green .v-text-field__slot input {
    color: green !important;
    font-size: 1.2em;
}

.text-input-white .v-text-field__slot input {
    color: white !important;
    font-size: 1.2em;
}

.v-chip.Arrived {
  background: #35b729 !important;
}
.v-chip.Lead {
  background: grey !important;
}
.v-chip.Registering {
  background: #ff6285 !important;
}

</style>