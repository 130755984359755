<template>
    <v-card flat class="d-flex justify-space-around" v-if="$store.getters.loadingApp !== 1">
        <v-card 
            flat
            class="pa-0"
            width="210mm"
        >
            <span v-html="messageHTML" class="ma-2"></span>
        </v-card>
    </v-card>
</template>

<script>

import dayjs from 'dayjs';
import "dayjs/locale/es";

export default {
    data () {
        return {
            messageHTML: "",
        }
    },
    beforeCreate () {
        this.$store.dispatch('setLoadingApp', 1);
    },
    async created() {
        dayjs.locale("es");
        await this.createCartaDePresentacion();
        const date = dayjs().format("DD/MMMM/YYYY");
        console.log(date);
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[breadcrumb.length - 1] !== 'CartaDePresentacion') {
            breadcrumb.push('CartaDePresentacion')
            this.$store.dispatch('breadcrumbs', breadcrumb);
        }
        this.$vuetify.goTo(0);
        this.$store.dispatch('setLoadingApp', 0)
    },
    mounted() {
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[0] === 'CartaDePresentacion') {
            this.$router.push({ name: 'Projects' })
        }
    },
    methods: {
        createCartaDePresentacion() {
            let payload = this.$store.state.dataForReport;
            console.log('createCartaDePresentacion ', payload);
            this.$store.dispatch('saveDataForReport', );
            let messageHeader = "<div style='margin-top: 0cm; margin-bottom: 0cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif;'>" +
                "<div style='display: flex; flex-direction: row; justify-content: space-between; margin: 0 0 0cm 0;'>" +
                    "<div style='font-family: Roboto, sans-serif; font-size: 32px; color: #35b729;'><span>PROYECTO</span><strong>PERU</strong></div>" +
                    "<div style='font-size: 32px; color: grey; display: flex; flex-direction: column; align-items: flex-end;'>" + 
                        "<div style='font-size: 24px;'>Carta de Presentación</div>" +
                        "<div style='font-size: 14px;'><i>¡Brindando nuevas oportunidades para vivir</i></div>" + 
                        "<div style='font-size: 14px;'><i>experiencias culturales inolvidables a</i></div>" + 
                        "<div style='font-size: 14px;'><i>través de voluntariados y pasantías!</i></div>" + 
                        "<div style='font-size: 18px;'>Cusco, " + 
                            dayjs(Date.now()).format('ddd DD MMM YY') +
                        "</div>" + 
                    "</div>" + 
                "</div>" + 
            "</div>"
            let messageBody = "<div style='font-family: Roboto;'>Para: " + payload[0].resource.contact_organisation + "</div><br>" +
            "<div>Es grato dirigirnos a Ustedes para informarles de la próxima llegada del voluntario de nuestro proyecto a quien ustedes gentilmente acogerán en su Centro: </div>\n\n"
            for (let i = 0; i < payload.length; i++) {
                messageBody += "<div style='font-size: 24px; display: flex; justify-content: space-around; margin: 0.5cm 0 0.3cm 0;'>" + payload[i].client.full_name + "</div>"
                if (payload[i].client.nationality) {
                    messageBody += "<div><strong>Nacionalidad: </strong>" + payload[i].client.nationality + "</div>"
                }
                if (payload[i].client.birthdate) {
                    messageBody += "<div><strong>Edad: </strong>" + dayjs().diff(payload[i].client.birthdate, 'year') + "</div>"
                }
                if (payload[i].client.gender === 'Male') {
                    messageBody += "<div><strong>Género: </strong>Masculino</div>"
                } else if (payload[i].client.gender === 'Female') {
                    messageBody += "<div><strong>Género: </strong>Femenino</div>"
                } else {
                    messageBody += "<div><strong>Género: </strong>No conocido</div>"
                }
                messageBody += "<div><strong>Período de Pasantía Del: </strong>" + dayjs(payload[i].resource.start).format('ddd DD MMM YY') + " <strong>Hasta: </strong>" + dayjs(payload[i].resource.end).format('ddd DD MMM YY') + "</div>"
                if (payload[i].client.occupation) {
                    messageBody += "<div><strong>Ocupación / Profesión: </strong>" + payload[i].client.occupation + "</div>"
                }
            }
            messageBody += "<br><div>Como es de su conocimiento, PROYECTOPERU es una institución que desarrolla sus actividades en beneficio de los proyectos con los cuales trabaja; a través de voluntarios en actividades educativas y asistencia social.</div>"
            messageBody += "<br><div>Durante la permanencia del voluntario usted podrá coordinar en cualquier momento con Proyecto Peru a los teléfonos 950 301 317 o a nuestra oficina ubicada en Arcopata 466 -  Cusco.</div>"
            messageBody += "<br><div>Finalmente le agradeceremos contactarse inmediatamente en caso de suscitarse algún inconveniente relacionado a la salud o comportamiento del voluntario.</div>"
            messageBody += "<br><div>Agradeciendo nuevamente su gentileza en trabajar con los voluntarios.</div>"
            messageBody += "<br><div>Atentamente, </div>"
            messageBody += "<br><div>PROYECTOPERU</div>"
            let messageFooter = "<div style='margin-top: 1cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif; background-color: #c7d3d1; height: 2px;'></div>"
            messageFooter += "<div style='display: flex; justify-content: space-around; font-size: 14px;'>Proyecto Peru Centre, Arcopata 466 Cusco, Peru</div>"
            messageFooter += "<div style='display: flex; justify-content: space-around; font-size: 14px;' class='mb-0 pb-0'>Tel. +51 (084) 240 278 / 950 301 317 Email: office@proyectoperucentre.org</div>"
            this.messageHTML = messageHeader + messageBody + messageFooter;
        },
    },
}
</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
    /* @media all {
        .page-break	{ display: none; }
    }
    @media print {
        .page-break	{ display: block; page-break-before: always; }
    } */
</style>