<template>
    <v-container style="max-width: 100%">
        <v-data-table
            :headers="headers"
            :items="updatedContacts"
             sort="name"
            :items-per-page="-1"
            disable-pagination
            hide-default-footer
            :key="rendererTable"
            :search="searchContacts"
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-card flat style="width: 100%">
                        <v-card flat class="d-flex flex-row flex-wrap">
                            <v-card flat style="width: 300px">
                                <v-text-field
                                    v-model="searchContacts"
                                    append-icon="$vuetify.icons.icon_mdi_magnify"
                                    :label="'Search ' + `${ updatedContacts.length }` + ' contact(s)'"
                                    single-line
                                    hide-details
                                    clearable
                                ></v-text-field>
                            </v-card>
                            <v-btn
                                color="ppc_pink"
                                dark
                                small
                                absolute
                                right
                                fab
                                @click="editedItem.country_id = 184, editedItem.obsolete = null, dialog=true"
                            >
                                <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                            </v-btn>
                        </v-card>
                        <!-- <v-card flat>
                            <v-text-field
                                v-model="searchContacts"
                                append-icon="$vuetify.icons.icon_mdi_magnify"
                                :label="'Search ' + `${ updatedContacts.length }` + ' contact(s)'"
                                single-line
                                hide-details
                                style="width: 300px"
                                clearable
                            ></v-text-field>
                        </v-card> -->
                    </v-card>
                    <v-dialog
                        v-model="dialog"
                        max-width="500px"
                        persistent
                    >
                        <v-form ref="dialogForm">
                            <v-card>
                                <v-card-title class="ppc_darkgreen darken-1">
                                    <span class="headline white--text"> {{ formTitle }} {{ editedItem.name }} </span>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            md="8"
                                        >
                                            <v-text-field
                                                v-model.trim="editedItem.name"
                                                label="Name"
                                                prepend-icon="$vuetify.icons.icon_mdi_smart_card ppc_darkgreen--text"
                                                counter
                                                clearable
                                                :rules="inputRulesName"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            md="8"
                                        >
                                            <v-text-field
                                                v-model.trim="editedItem.surname"
                                                label="Surname"
                                                prepend-icon="$vuetify.icons.icon_mdi_smart_card ppc_darkgreen--text"
                                                counter
                                                clearable
                                                :rules="inputRulesName"
                                            ></v-text-field>
                                        </v-col>
                                        <!-- <v-col
                                            cols="12"
                                        >
                                            <v-text-field
                                                v-model.trim="editedItem.organisation"
                                                label="Organisation"
                                                prepend-icon="$vuetify.icons.icon_mdi_domain ppc_darkgreen--text"
                                                counter
                                                clearable
                                                :rules="inputRules100max"
                                            ></v-text-field>
                                        </v-col> -->
                                        <v-col
                                            cols="12"
                                        >
                                            <v-text-field
                                                v-model.trim="editedItem.address"
                                                label="address"
                                                prepend-icon="mdi-map-marker ppc_darkgreen--text"
                                                clearable
                                                counter
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="auto" sm="6">
                                            <v-select
                                                v-model="editedItem.country_id"
                                                :items="$store.state.itemsNationality"
                                                item-text="nationality"
                                                item-value="id"
                                                :menu-props="{ auto: true }"
                                                label="Select country"
                                                single-line
                                                prepend-icon="mdi-earth ppc_darkgreen--text"
                                                validate-on-blur
                                                clearable
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-text-field
                                                v-model.trim="editedItem.phone"
                                                label="phone"
                                                prepend-icon="mdi-cellphone ppc_darkgreen--text"
                                                :rules="inputRulesPhone"
                                                clearable
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-text-field
                                                v-model.trim="editedItem.phone_alt"
                                                label="alt phone"
                                                :rules="inputRulesPhone"
                                                clearable
                                                prepend-icon="mdi-phone ppc_darkgreen--text"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-text-field
                                                v-model.trim="editedItem.email"
                                                prepend-icon="mdi-email ppc_darkgreen--text"
                                                label="email"
                                                clearable
                                                :rules="inputRulesEmail"
                                            ></v-text-field>
                                        </v-col>
                                        <v-textarea
                                            class="rounded-xl ma-3"
                                            v-model="editedItem.comments"
                                            auto-grow
                                            clearable
                                            counter
                                            outlined
                                            label="Comments (255 max)"
                                            :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                                        >
                                        </v-textarea>
                                        <v-col
                                            cols="12"
                                            class="d-flex flex-row flex-wrap py-0 my-0"
                                        >
                                            <v-col 
                                                cols="12"
                                                sm="6"
                                                md="4"
                                                class="py-0 my-0"
                                            >
                                                <v-checkbox
                                                    :input-value="editedItem.obsolete !== null"
                                                    label="Obsolete"
                                                    @change="setObsolete($event)"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col 
                                                cols="12"
                                                sm="6"
                                                md="4"
                                                class="py-0 my-0"
                                            >
                                                <v-text-field
                                                    :value="formattedDate(editedItem.obsolete)"
                                                    disabled
                                                    label="From"
                                                    v-if="editedItem.obsolete !== null"
                                                    class="ml-2"
                                                ></v-text-field>
                                            </v-col>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="ppc_darkgreen darken-1"
                                    text
                                    @click="close"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="ppc_darkgreen darken-1"
                                    text
                                    @click="save"
                                >
                                    Save
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title>Are you sure you want to delete {{ editedItem.name }}?</v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="ppc_darkgreen darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-btn color="ppc_darkgreen darken-1" text @click="deleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:[`item.organisations`]="{ item }">
                <v-icon
                    color="ppc_darkgreen darken-1" 
                    class="white--text mr-2" 
                    @click="openOrganisations(item)"
                    small
                >
                    $vuetify.icons.icon_mdi_pencil
                </v-icon>
                {{ item.organisations }}
            </template>
            <template v-slot:[`item.obsolete`]="{ item }">
                <v-icon small v-if="item.obsolete !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    $vuetify.icons.icon_mdi_pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    $vuetify.icons.icon_mdi_delete
                </v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>

import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import dayjs from 'dayjs';

export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
          width: '25ch',
        },
        { text: 'Surname', value: 'surname', width: '35ch' },
        { text: 'Organisation(s)', value: 'organisations', width: '400px' },
        { text: 'Role', value: 'role' },
        // { text: 'Staff', value: 'staff' },
        // { text: 'Agency Int.', value: 'agency' },
        // { text: 'Teaching', value: 'teaching' },
        // { text: 'Acc', value: 'accommodation' },
        // { text: 'Projects', value: 'projects' },
        // { text: 'Tour Ag', value: 'tours' },
        // { text: 'Payer', value: 'payer' },
        // { text: 'Receiver', value: 'receiver' },
        { text: 'Comments', value: 'comments' },
        { text: 'Obs', value: 'obsolete'},
        { text: 'Actions', value: 'actions', sortable: false, width: '200px' },
      ],
      updatedContacts: [],
      editedIndex: -1,
      editedItem: {
        name: '',
      },
      defaultItem: {
        name: '',
      },
      dialogContactInfo: false,
      contact: false,
      rendererTable: Math.random(),
      searchContacts: '',
    }),

    mixins: [inputRules, formattedDate],

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Contact ' : ''
        },
    },

    watch: {
    },

    beforeCreate () {
        this.$store.dispatch('setLoadingApp', 1)
    },

    created () {
        dayjs.locale("en");
        this.initialize()
    },

    methods: {
      async initialize () {
        this.getContacts();
      },
      async getContacts () {
        this.$store.dispatch('setLoadingApp', 1)
        await this.$store.dispatch('getContacts');
        this.updatedContacts = JSON.parse(JSON.stringify(this.$store.state.contacts));
        let organisations;
        let organisationsAll = await this.$store.dispatch('getOrganisationsAll');
        let tmpId;
        for (let index = 0; index < this.updatedContacts.length; index++) {
            tmpId = this.updatedContacts[index].id;
            organisations = organisationsAll.filter(function(select_item) {
                return select_item.contact_id === tmpId;
            })
            this.updatedContacts[index].organisations = '';
            for (let index2 = 0; index2 < organisations.length; index2++) {
                this.updatedContacts[index].organisations = this.updatedContacts[index].organisations.concat(', ', organisations[index2].name)
            }
            if (this.updatedContacts[index].organisations.length > 0) {
                this.updatedContacts[index].organisations = this.updatedContacts[index].organisations.substring(1);
            }
            let role = '';
            if (this.updatedContacts[index].staff) {
                role += 'staff, '
            }
            if (this.updatedContacts[index].agency) {
                role += 'agency, '
            }
            if (this.updatedContacts[index].teaching) {
                role += 'teaching, '
            }
            if (this.updatedContacts[index].accommodation) {
                role += 'accommodation, '
            }
            if (this.updatedContacts[index].projects) {
                role += 'projects, '
            }
            if (this.updatedContacts[index].tours) {
                role += 'tours, '
            }
            if (this.updatedContacts[index].payer) {
                role += 'payer, '
            }
            if (this.updatedContacts[index].receiver) {
                role += 'receiver, '
            }
            role = role.trim();
            if (role.length > 0) {
                role = role.substring(0, role.length - 1);
            }
            this.updatedContacts[index].role = role;
        }
        this.rendererTable = Math.random();  
        this.$store.dispatch('setLoadingApp', 0)
      },

      editItem (item) {
        this.editedIndex = this.updatedContacts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.updatedContacts.indexOf(item)
        this.editedItem = Object.assign({}, item);
        if (this.editedItem.projects > 0 || this.editedItem.teaching > 0 || this.editedItem.tours > 0 || this.editedItem.accommodation > 0 || this.editedItem.agency > 0|| this.editedItem.payer > 0|| this.editedItem.receiver > 0|| this.editedItem.staff > 0) {
            this.$store.dispatch('errorMessage', 'Contact is still in use');
            return;
        }
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        let response = await this.$store.dispatch('deleteContact', this.editedItem.id)
        if (response.data.affectedRows === 1) {
            this.$store.dispatch('successMessage', 'Contact deleted');
        } else {
            this.$store.dispatch('errorMessage', 'Something went wrong');
        }
        await this.$store.dispatch('getContacts');
        this.updatedContacts = JSON.parse(JSON.stringify(this.$store.state.contacts));
        this.closeDelete();
      },

      close () {
        this.dialog = false
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      },

      closeDelete () {
        this.dialogDelete = false
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      },

      setObsolete(event) {
            if (event) {
                this.editedItem.obsolete = dayjs().format("YYYY-MM-DD")
            } else {
                this.editedItem.obsolete = null
            }
        },

      async save () {
          if(this.$refs.dialogForm.validate()){
            let tmpName = this.editedItem.name;
            let tmpSurname = this.editedItem.surname;
            let doubleName = this.updatedContacts.filter(function(select_item) {
                    return select_item.name === tmpName && select_item.surname === tmpSurname;
                }
            )
            if (doubleName.length > 0 && this.editedIndex !== this.updatedContacts.indexOf(doubleName[0])) {
                this.$store.dispatch('errorMessage', 'The name "' + this.editedItem.name + " " + this.editedItem.surname + '" is already in use!');
                return;
            } else {
                await this.$store.dispatch('insertContact', this.editedItem);
                await this.getContacts();
            }
            this.close()
          }
      },
        openOrganisations(item) {
            this.$store.dispatch('setLoadingApp', 1)
            this.$router.push({ name: 'Organisations', params: { contactId: item.id } })
        },
    },
  }

</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
</style>