import compare from '@/mixins/compare';
import formattedDate from '@/mixins/formattedDate';

export default {

    mixins: [compare, formattedDate],

    methods: {
        createClassScheduleStudent(classesPlanned, student) {
            classesPlanned.sort(this.dynamicSort('start'));
            // HTML version
            let messageHeader = "<div style='margin-top: 0cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif;'>" +
            "<div style='display: flex; flex-direction: row; justify-content: space-between; margin: 0 0 2cm 0;'>" +
                "<div style='font-family: Roboto, sans-serif; font-size: 32px; color: #35b729;'><span>PROYECTO</span><strong>PERU</strong></div>" +
                "<div style='font-size: 32px; color: grey; display: flex; flex-direction: column; align-items: flex-end;'>" + 
                    "<div> CLASS SCHEDULE</div>" + 
                    "<div style='font-size: 24px;'> " + student + "</div>" +
                    "<div style='font-size: 18px;'> " + 
                        this.formattedDate(Date.now()) + 
                    "</div>" + 
                "</div>" + 
            "</div>" + 
            "</div>"
            let messageBody = "<div><table style='width: 100%;'><tr><th style='text-align: left;'>Date</th><th style='text-align: left;'>Time</th><th style='text-align: left;'>Duration</th><th style='text-align: left;'>Room</th><th style='text-align: left;'>Teacher</th><th style='text-align: left;'>Status</th></tr>";
            let individual_instead = false;
            let locationSchool = false;
            let breakClass = false;
            let group = false;
            for (let index = 0; index < classesPlanned.length; index++) {
                messageBody += "<tr style='font-size: 14px'><td>" + this.formattedDate(classesPlanned[index].start, 'dayAndDate') + "</td><td>" + this.formattedDate(classesPlanned[index].start, 'time') + " - " + this.formattedDate(classesPlanned[index].end, 'time') + "</td><td>" + classesPlanned[index].duration/60 + "h</td><td>" + classesPlanned[index].class_room_name + "</td><td>" + classesPlanned[index].contact + "</td><td>"
                if (classesPlanned[index].duration_class === classesPlanned[index].minutes_completed) {
                    messageBody += "finished</td></tr>";
                } else {
                    messageBody += "planned</td></tr>";
                }
                if (classesPlanned[index].class_group === 'Group') {
                    group = true;
                }
                if (classesPlanned[index].class_group === 'Group' && classesPlanned[index].amount_students === 1) {
                    individual_instead = true;
                }
                if (classesPlanned[index].class_location === 'School') {
                    locationSchool = true;
                }
                if (classesPlanned[index].duration_class > 180) {
                    breakClass = true;
                }
            }
            messageBody += "</table></div>";
            let messageFooter = "<div style='margin-top: 2cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif; background-color: #c7d3d1; height: 2px;'>" + 
                "<div style='margin-top: 10px; display: flex; flex-direction: row;'>" +
                    "<a target=_blank href='https://proyectoperucentre.org/contact.html'>School Location</a>" +
                    "<div style='margin: 0 10px 0 10px';>&#9900</div>" +
                    "<a target=_blank href='https://proyectoperucentre.org/terms-and-conditions.html'>Terms & Conditions</a>" +
                "</div>" +
            "</div>"
            let messageHTML = messageHeader + messageBody + messageFooter;
            let response = {
                messageHTML: messageHTML,
                group: group,
                individual_instead: individual_instead,
                locationSchool: locationSchool,
                breakClass: breakClass,
            }
            return response;
        },
    }
}