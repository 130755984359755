export default {
    methods: {
        getDateFromTimestamp(timestamp) {
            if (timestamp === null) {
            return "";
            } else {
            const d = new Date(timestamp);
            const date = d.toDateString();
            return date;
            }
        },
    }
    
}