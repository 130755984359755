<template>
    <div class="d-flex justify-space-around" v-if="$store.getters.loadingApp !== 1">
        <v-card 
            flat
            class="pa-12"
            width="100%"
            max-width="210mm"
            min-height="297mm"
        >
            <span v-html="messageHTML" class="ma-2"></span>
        </v-card>
    </div>
</template>

<script>

import createClassScheduleTeacher from '@/mixins/createClassScheduleTeacher';

export default {
    data () {
        return {
            teacher: null,
            classesPlanned: [],
            messagePlain: "",
            messageHTML: "",
        }
    },
    beforeCreate () {
        this.$store.dispatch('setLoadingApp', 1)
    },
    async created() {
        await this.createSchedule();
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[breadcrumb.length - 1] !== 'ClassScheduleTeacher') {
            breadcrumb.push('ClassScheduleTeacher')
            this.$store.dispatch('breadcrumbs', breadcrumb);
        }
        this.$vuetify.goTo(0);
        this.$store.dispatch('setLoadingApp', 0)
    },
    mounted() {
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[0] === 'ClassScheduleTeacher') {
            this.$router.push({ name: 'Teaching' })
        }
    },

    mixins: [createClassScheduleTeacher],
    
    methods: {
        async createSchedule() {
            let messageHTML = await this.createClassScheduleTeacher(this.$store.state.dataForReport.classes, this.$store.state.dataForReport.contactFullName);
            let email = this.$store.state.dataForReport.email;
            let emailIntro = "Estimado " + this.teacher + ",\n\n" + 
            "Presentamos abajo la planificación de sus clases con los detalles correspondientes. Revisa todos los puntos importantes que debe tener en cuenta para dar un excelente servicio al estudiante.\n\n" +
            "1. Revisar el nivel del estudiante en Spanish test:\n" +
            "https://docs.google.com/spreadsheets/d/1fOwOMKTI8_RxKoP72phq5BNeQ1QZWzctJw8V5a4-CYs/edit?resourcekey&usp=forms_web_b#gid=353365034\n" +
            "2. Hacer el plan de clases enviarlo a más tardar en lunes por la noche 22:00 hrs.\n" +
            "3. Confirmar si todo está conforme con el correo (horario) por WhatsUp a Jurgen.\n" +
            "4. Escribir en tu reporte mensual el nivel del estudiante y sus datos completos (nombre completo, horas, faltas, feriados etc.).\n" +
            "5. El día lunes darle un cuaderno y el lapicero al estudiante y desinfectar el aula con alcohol.\n" +
            "6. El primer día debes presentar las reglas del aula al estudiante (cancelación de clases de último minuto, cancelar las clases un día antes, cambios especiales con Jurgen, recuperación de horas etc.).\n" +
            "7. Avisar a Jurgen la falta del estudiante (con anticipación, o cualquier problema que tenga su estudiante).\n" +
            "8. Cualquier cambio especial que el estudiante quiera hacer primero consultar con Jurgen antes de confirmar al estudiante.\n" +
            "9. Respetar la asignación del # de aula que está en Horario (de lo contrario eso causara confusiones o problemas innecesarios en administración).\n" +
            "10. El martes o segundo día de sus clases entregar la encuesta #1 y al final de su paquete la encuesta # 2, el estudiante debe dejar en el escritorio de Jurgen o dárselo directamente a Dora.\n" +
            "11. Planear con anticipación el plan de salida (mercado, museo etc.) no espere el último minuto.\n" +
            "12. No sacar copias o preparar materiales extras durante la clase dejando al estudiante sin profesora (hacerlo antes que comience sus clases o en el recreo de 15 min).\n" +
            "13. Respetar el recreo estandarizado (por la mañana 10:30 a 10:45 am y por la tarde 3:30 a 3:45pm) de 15 min y avisar a sus estudiantes para volver a clase (solo tienen recreo los estudiantes de 3 horas o 4 horas para arriba no aplica para recuperación de horas perdidas o menos a 3 horas).\n\n" +
            "Saludos, \n\n" + this.$store.state.user + "\n\n" +
            "info@proyectoperucentre.org\n" +
            "Proyecto Peru Centre\n" +
            "Avenida Arcopata 466-A\n" +
            "Cusco, Cusco, 45\n" +
            "+ 51 84 240 278"
            let payload = {
                submittedFrom: "ClassScheduleTeacher",
                emailList: email,
                emailSubject: "Class Schedule Proyecto Peru",
                emailIntro: emailIntro,
                messageHTML: messageHTML,
                messagePlain: '',
                openEmailDialog: false,
                saveInvoice: false,
                teacherId: this.$store.state.dataForReport.id,
                resourceId: null,
                attachments: null,
            };
            this.$store.dispatch('saveDataForReport', );
            this.$store.dispatch('emailMessage', payload);
        },
    },
}
</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
    /* @media all {
        .page-break	{ display: none; }
    }
    @media print {
        .page-break	{ display: block; page-break-before: always; }
    } */
</style>