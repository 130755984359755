<template>
    <v-container v-if="$store.getters.loadingApp !== 1" style="max-width: 100%">
        <!-- Teachers -->
        <v-data-table
            :headers="headersTeachers"
            :items="updatedTeachers"
            class="elevation-1"
            :items-per-page="-1"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-toolbar-title>Teachers</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="ppc_pink"
                        dark
                        small
                        absolute
                        right
                        fab
                        @click="dialog=true"
                    >
                        <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                    </v-btn>

                    <v-dialog
                        v-model="dialog"
                        max-width="600px"
                        persistent
                    >
                        <v-form ref="dialogForm">
                            <v-card flat>
                                <v-card-title class="ppc_darkgreen darken-1">
                                    <span class="headline white--text"> Teacher {{ formTitle }} {{ editedItem.contact }} </span>
                                </v-card-title>
                                <v-card-text>
                                        <v-select
                                            v-model="editedItem.contact_id"
                                            :items="selectContacts()"
                                            item-text="full_name"
                                            item-value="id"
                                            :menu-props="{ auto: true }"
                                            label="Select contact"
                                            single-line
                                            prepend-icon="$vuetify.icons.icon_mdi_account_group ppc_darkgreen--text"
                                            clearable
                                            :rules="inputRulesContactReq"
                                            :disabled = "editedItem.obsolete !== null"
                                        >
                                        </v-select>`
                                        <v-card flat class="ma-2">
                                            <v-card-title>Languages</v-card-title>
                                            <v-card-actions>
                                                <v-checkbox
                                                    v-model="editedItem.spanish"
                                                    label="Spanish"
                                                    class="my-0 py-0 mr-4"
                                                    :disabled = "editedItem.obsolete !== null"
                                                ></v-checkbox>
                                                <v-checkbox
                                                    v-model="editedItem.quechua"
                                                    label="Quechua"
                                                    class="my-0 py-0 mr-4"
                                                    :disabled = "editedItem.obsolete !== null"
                                                ></v-checkbox>
                                                <v-checkbox
                                                    v-model="editedItem.english"
                                                    label="English"
                                                    class="my-0 py-0 mr-4"
                                                    :disabled = "editedItem.obsolete !== null"
                                                ></v-checkbox>
                                            </v-card-actions>
                                        </v-card>
                                        <v-card flat class="ma-2">
                                            <v-card-title>Rates (PEN)</v-card-title>
                                            <v-card-actions class="d-flex flex-column">
                                                <v-card flat class="d-flex flex-row flex-wrap justify-space-between">
                                                    <v-text-field
                                                        v-model.number="editedItem.rate_individual"
                                                        label="Individual"
                                                        prepend-icon="$vuetify.icons.icon_mdi_account ppc_darkgreen--text"
                                                        style="width: 100px"
                                                        :disabled = "editedItem.obsolete !== null"
                                                    ></v-text-field>
                                                    <v-text-field
                                                        v-model.number="editedItem.rate_group"
                                                        label="Group"
                                                        prepend-icon="$vuetify.icons.icon_mdi_account_multiple ppc_darkgreen--text"
                                                        style="width: 100px"
                                                        :disabled = "editedItem.obsolete !== null"
                                                    ></v-text-field>
                                                    <v-text-field
                                                        v-model.number="editedItem.rate_special"
                                                        label="Special"
                                                        prepend-icon="$vuetify.icons.icon_mdi_account_remove ppc_darkgreen--text"
                                                        style="width: 100px"
                                                        :disabled = "editedItem.obsolete !== null"
                                                    ></v-text-field>
                                                    <v-text-field
                                                        v-model.number="editedItem.rate_special_group"
                                                        label="Special group"
                                                        prepend-icon="$vuetify.icons.icon_mdi_account_multiple_remove ppc_darkgreen--text"
                                                        style="width: 100px"
                                                        :disabled = "editedItem.obsolete !== null"
                                                    ></v-text-field>
                                                </v-card>
                                                <v-card flat class="d-flex flex-row flex-wrap justify-center">
                                                    <v-text-field
                                                        v-model.number="editedItem.discount_payment"
                                                        label="AFP"
                                                        prepend-icon="mdi-percent ppc_darkgreen--text"
                                                        style="width: 100px"
                                                        :rules="[v => v <= 100 || 'The value must be a smaller than 100']"
                                                        :disabled = "editedItem.obsolete !== null"
                                                    ></v-text-field>
                                                </v-card>
                                            </v-card-actions>
                                        </v-card>
                                        <v-textarea
                                            class="rounded-xl mx-3"
                                            v-model="editedItem.comments"
                                            auto-grow
                                            clearable
                                            counter
                                            outlined
                                            label="Comments (255 max)"
                                            :rules="[v => (v || '' ).length <= 255 || 'Comments must be 255 characters or less']"
                                            :disabled = "editedItem.obsolete !== null"
                                        >
                                        </v-textarea>
                                        <v-checkbox
                                            :input-value="editedItem.obsolete !== null"
                                            label="Not active"
                                            @change="setObsolete($event)"
                                        ></v-checkbox>
                                        <v-text-field
                                            :value="formattedDate(editedItem.obsolete)"
                                            disabled
                                            label="From"
                                            v-if="editedItem.obsolete !== null"
                                            class="ml-2"
                                        ></v-text-field>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="ppc_darkgreen darken-1"
                                        text
                                        @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="ppc_darkgreen darken-1"
                                        text
                                        @click="save"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>

                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title>Are you sure you want to delete {{ editedItem.contact }}?</v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="ppc_darkgreen darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-btn color="ppc_darkgreen darken-1" text @click="deleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-toolbar>
            </template>
            <template v-slot:[`item.contact`]="{ item }">
                {{ item.contact }}
                <v-btn
                    v-if="item.contact" 
                    fab 
                    color="ppc_pink"
                    height="20px"
                    width="20px"
                    @click="openContactInfo(item)"
                    right
                    top
                    class="ml-3"
                >
                    <v-icon color="white">$vuetify.icons.icon_mdi_information_variant</v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.spanish`]="{ item }">
                <v-icon small v-if="item.spanish" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
            </template>
            <template v-slot:[`item.quechua`]="{ item }">
                <v-icon small v-if="item.quechua" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
            </template>
            <template v-slot:[`item.english`]="{ item }">
                <v-icon small v-if="item.english" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
            </template>
            <template v-slot:[`item.obsolete`]="{ item }">
                <v-icon small v-if="item.obsolete !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    $vuetify.icons.icon_mdi_pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    $vuetify.icons.icon_mdi_delete
                </v-icon>
            </template>
        </v-data-table>
        <v-divider></v-divider>
        <div class="d-flex flex-row mb-7">
            <v-spacer></v-spacer>
            <v-btn color="ppc_darkgreen darken-1" text @click="$router.go(-1)">Ok</v-btn>
        </div>

        <v-row justify="center">

            <v-dialog
                v-model="dialogContactInfo"
                max-width="500px"
            >
                <v-card>
                    <v-card-title class="ppc_darkgreen darken-1">
                        <span class="headline white--text"> {{ contact.name }} </span>
                    </v-card-title>
                    <v-card-subtitle class="mt-5" v-if="contact.organisation">
                        <v-icon
                            class="mr-2"
                        >
                            $vuetify.icons.icon_mdi_domain ppc_darkgreen--text
                        </v-icon>
                        <span class="font-weight-bold">{{ contact.organisation }}</span>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="12"
                                v-if="contact.address || contact.country"
                            >
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-map-marker ppc_darkgreen--text
                                </v-icon>
                                {{contact.address}}
                                <span v-if="contact.address && contact.country">,</span>
                                {{contact.country}}
                            </v-col>
                            
                            <v-col
                                cols="12"
                                sm="6"
                                v-if="contact.phone"
                            >
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-cellphone ppc_darkgreen--text
                                </v-icon>
                                {{contact.phone}}
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                v-if="contact.phone_alt"
                            >
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-phone ppc_darkgreen--text
                                </v-icon>
                                {{contact.phone_alt}}
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                v-if="contact.email"
                            >   
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-email ppc_darkgreen--text
                                </v-icon>
                                {{contact.email}}
                            </v-col>
                            <v-col
                                cols="12"
                                v-if="contact.comments"
                            >   
                                <v-icon
                                    class="mr-2"
                                >
                                    mdi-message-text ppc_darkgreen--text
                                </v-icon>
                                {{ contact.comments }}
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn
                            color="ppc_darkgreen darken-1"
                            text
                            @click="dialogContactInfo = false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-row>
        
    </v-container>
</template>

<script>

import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import dayjs from 'dayjs';
import compare from '../mixins/compare';

export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      dialogContactInfo: false,
      headersTeachers: [
        {
          text: 'Contact',
          align: 'start',
          sortable: false,
          value: 'contactFullName',
          width: '20ch'
        },
        { text: 'Spanish', value: 'spanish' },
        { text: 'Quechua', value: 'quechua' },
        { text: 'English', value: 'english' },
        { text: 'Rate individual', value: 'rate_individual' },
        { text: 'Rate group', value: 'rate_group' },
        { text: 'Rate special', value: 'rate_special' },
        { text: 'Rate special group', value: 'rate_special_group' },
        { text: 'AFP %', value: 'discount_payment' },
        { text: 'Comments', value: 'comments' },
        { text: 'Inactive', value: 'obsolete'},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      updatedTeachers: [],
      editedIndex: -1,
      editedItem: {
        contact: '',
        contact_id: '',
        spanish: 0,
        quechua: 0,
        english: 0,
        rate_individual: null,
        rate_group: null,
        rate_special: null,
        rate_special_group: null,
        comments: '',
        obsolete: null,
      },
      defaultItem: {
        contact: '',
        contact_id: '',
        spanish: 0,
        quechua: 0,
        english: 0,
        rate_individual: null,
        rate_group: null,
        rate_special: null,
        rate_special_group: null,
        comments: '',
        obsolete: null,
      },
      contact: [],
    }),

    mixins: [inputRules, formattedDate, compare],

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Teacher ' : ''
        },
    },

    watch: {
    },

    beforeCreate () {
        if (this.$store.state.accessTeaching === 0) {
            this.$router.push({ name: 'Illegal' })
        }
        this.$store.dispatch('setLoadingApp', 1)
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.$store.dispatch('getContacts');
        await this.$store.dispatch('getTeachers');
        this.updatedTeachers = JSON.parse(JSON.stringify(this.$store.state.teachers));
        await this.$store.dispatch('getGeneralSettings')
        this.editedItem.rate_individual = this.$store.state.standard_rate_individual;
        this.editedItem.rate_group = this.$store.state.standard_rate_group;
        this.editedItem.rate_special = this.$store.state.standard_rate_special;
        this.editedItem.rate_special_group = this.$store.state.standard_rate_special_group;
        this.defaultItem.rate_individual = this.$store.state.standard_rate_individual;
        this.defaultItem.rate_group = this.$store.state.standard_rate_group;
        this.defaultItem.rate_special = this.$store.state.standard_rate_special;
        this.defaultItem.rate_special_group = this.$store.state.standard_rate_special_group;
        this.$store.dispatch('setLoadingApp', 0);
      },

      openContactInfo(editedItem) {
        console.log(editedItem)
        let tmp = editedItem.contact_id;
        this.contact = this.$store.state.contacts.filter(function(select_item) {
            return select_item.id === tmp;
        })[0]
        this.dialogContactInfo = true;
      },

      selectContacts() { 
        let array = [];
        for (let index = 0; index < this.updatedTeachers.length; index++) {
            if (this.editedIndex === -1) {
                array.push(this.updatedTeachers[index].contact_id)
            } else {
                if (this.updatedTeachers[index].contact_id !== this.editedItem.contact_id) {
                    array.push(this.updatedTeachers[index].contact_id)
                }
            }
        }
        let teachers = this.$store.state.contacts.filter(function(select_item) {
            return !array.includes(select_item.id) && select_item.obsolete === null;
        })
        return teachers.sort(this.dynamicSort('full_name'));
      },

    

      setObsolete(event) {
            if (event) {
                this.editedItem.obsolete = dayjs().format("YYYY-MM-DD")
            } else {
                this.editedItem.obsolete = null
            }
            
        },

      editItem (item) {
        this.editedIndex = this.updatedTeachers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.updatedTeachers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        let response = await this.$store.dispatch('deleteTeacher', this.editedItem.id);
        if (response.data.affectedRows === 1) {
            this.$store.dispatch('successMessage', 'Teacher deleted');
        } else {
            this.$store.dispatch('errorMessage', 'Something went wrong');
        }
        await this.$store.dispatch('getTeachers');
        this.updatedTeachers = JSON.parse(JSON.stringify(this.$store.state.teachers));
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async save () {
          if(this.$refs.dialogForm.validate()){
            if (this.editedItem.rate_individual === '' || this.editedItem.rate_individual === null) {
                this.editedItem.rate_individual = 0;
            }
            if (this.editedItem.rate_group === '' || this.editedItem.rate_group === null) {
                this.editedItem.rate_group = 0;
            }
            if (!this.editedItem.discount_payment) {
                this.editedItem.discount_payment = 0;
            }
            await this.$store.dispatch('insertTeacher', this.editedItem);
            await this.$store.dispatch('getTeachers');
            this.updatedTeachers = JSON.parse(JSON.stringify(this.$store.state.teachers));
            this.close()
          }
      },
    },
  }

</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
</style>