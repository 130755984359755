<template>
  <div class="leads" v-if="$store.getters.loadingApp !== 1">
    <v-card flat class="pa-4 d-flex flex-row flex-wrap justify-center">
      <v-radio-group
          row
          v-model="scopeClients"
          class="mt-0"
          @change="getClients(scopeClients)"
      >
          <v-radio
              label="Ongoing"
              value="ongoing"
              class="my-0 py-0"
          ></v-radio>
          <v-radio
              label="Completed"
              value="completed"
              class="my-0 py-0"
          ></v-radio>
      </v-radio-group>
    </v-card>
    <v-btn
      small
      @click="createSheets"
      class="mx-4 mb-4"
      :disabled="selectedClients.length === 0"
    >
      Create Information Sheet ({{ selectedClients.length }})
    </v-btn>
    <v-data-table
      :headers="headersClients"
      :items="clients"
      sort-by="start"
      class="elevation-1"
      :items-per-page="-1"
      :search="searchClients"
      :single-select=false
      :show-select=true
      v-model="selectedClients"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="d-flex flex-row flex-wrap justify-center">
              <!-- <v-toolbar-title class="mr-4">Resources</v-toolbar-title> -->
              <v-card flat>
                  <v-text-field
                      v-model="searchClients"
                      append-icon="$vuetify.icons.icon_mdi_magnify"
                      :label="'Search ' + `${ clients.length }` + ' client(s)'"
                      single-line
                      hide-details
                      style="width: 300px"
                      clearable
                  ></v-text-field>
              </v-card>
          </div>
        </v-toolbar>
      </template>

      <template v-slot:[`item.full_name`]="{ item }">
          <v-btn small rounded @click="goClient(item.id)">{{ item.full_name }}</v-btn>
      </template>
      <template v-slot:[`item.gender`]="{ item }">
        <v-icon small v-if="`${item.gender}` === 'Male'" color="ppc_darkgreen">fa-male</v-icon>
        <v-icon small v-else-if="`${item.gender}` === 'Female'" color="ppc_darkgreen">fa-female</v-icon>
        <v-icon small v-else color="ppc_darkgreen">fa-genderless</v-icon>
      </template>

      <template v-slot:[`item.start_first`]="{ item }">
        {{ formattedDate(item.start_first, 'date') }}
      </template>

      <template v-slot:[`item.end_last`]="{ item }">
        {{ formattedDate(item.end_last, 'date') }}
      </template>

      <template v-slot:[`item.statusClientId`]="{ item }">
        <v-chip small :color="`${getStatus(item.statusClientId).color}`" text-color="white" v-html="`${getStatus(item.statusClientId).name}`"></v-chip>
      </template>

    </v-data-table>

  </div>
</template>

<script>
import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import dayjs from 'dayjs';
export default {

  data() {
    return {
      arrivals: null,
      dialogAddLead: false,
      loading: false,
      itemsNationality: [],
      itemsSource: [],
      errorMessageNames: '',
      first_name: '',
      last_name: '',
      sourceId: 1,
      agencyId: null,
      clients: [],
      scopeClients: 'ongoing',
      headersClients: [
        { text: 'Full Name', align: 'start', value: 'full_name' },
        { text: 'Email', value: 'email' },
        { text: 'Gender', value: 'gender', width: "100px", sortable: false},
        { text: 'Nationality', value: 'nationality' },
        { text: 'Source/Agency', value: 'source' },
        { text: 'Services', value: 'amountServices', sortable: false},
        { text: 'Start', value: 'start_first', width: '120px' },
        { text: 'End', value: 'end_last', width: '120px' },
        { text: 'Status', value: 'statusClientId' },
        // { text: 'Edit', value: 'edit', sortable: false },
      ],
      addLeadValid: true,
      arrayUpload: [],
      searchClients: '',
      selectedClients: [],
    }
  },

  mixins: [inputRules, formattedDate],
  
  watch: {
    successMessage: function () {
      if (this.successMessage !== "") {
        this.alertSuccessMessage = true;
      }
    },
  },
  computed: {
    successMessage () {
      return this.$store.getters.successMessage
    },
  },
  beforeCreate () {
    this.$store.dispatch('setLoadingApp', 1)
  },
  created () {
    dayjs.locale("en");
    this.initialize();
  },
  methods: {
    async initialize () {
      this.$store.dispatch('breadcrumbs', ['Clients']);
      await this.getClients('ongoing')
      for (let index = 0; index < this.clients.length; index++) {
        if(this.clients[index].source === null) {
            this.clients[index].source = this.clients[index].agency;
        }
      }
      this.$store.dispatch('setLoadingApp', 0)
    },
    goClient(id) {
      this.$store.dispatch('setLoadingApp', 1);
      this.$router.push({ name: 'Client', params: { id: id } })
    },
    
    getStatus(id) {
      return this.$store.state.statusClients.filter((select_item) => {
        return select_item.id === id;
      })[0];
    },

    async getClients(scopeClients) {
      if (scopeClients === 'ongoing') {
        this.clients = await this.$store.dispatch('getClients');
      } else {
        this.clients = await this.$store.dispatch('getClientsCompleted');
      }
    },

    async createSheets(event) {
      let messageHTML = "";
      console.log('createSheets', event);
      // console.log('createSheets', event.item.payerClient);
      for (let i = 0; i < this.selectedClients.length; i++) {
        messageHTML += "<h2>Information Sheet</h2><br>"
        messageHTML += "<div style='display: flex; flex-direction: row; justify-content: space-between;'>";
        messageHTML += "<div><span>First Name: </span><br><span class='borderAroundText' style='width: 200px; height: 40px'>" + this.selectedClients[i].first_name + "</span></div>";
        messageHTML += "<div><span>Last Name: </span><br><span class='borderAroundText' style='width: 200px; height: 40px'>" + this.selectedClients[i].last_name + "</span></div>";
        messageHTML += "<div><span>Nationality: </span><br><span class='borderAroundText' style='width: 200px; height: 40px'>" + this.selectedClients[i].nationality + "</span></div>";
        messageHTML += "</div><br>";
        // messageHTML += "<div style='display: flex; flex-direction: row; justify-content: space-between;'>";
        // messageHTML += "<div><span>Nationality: </span><br><span class='borderAroundText' style='width: 200px; height: 40px'>" + this.selectedClients[i].nationality + "</span></div>";
        // this.selectedClients[i].occupation === null ? this.selectedClients[i].occupation = '' : this.selectedClients[i].occupation;
        // messageHTML += "<div><span>Occupation: </span><br><span class='borderAroundText' style='width: 200px; height: 40px'>" + this.selectedClients[i].occupation + "</span></div>";
        // messageHTML += "</div><br>";
        messageHTML += "<div style='display: flex; flex-direction: row; justify-content: space-between;'>";
          this.selectedClients[i].occupation === null ? this.selectedClients[i].occupation = '' : this.selectedClients[i].occupation;
        messageHTML += "<div><span>Occupation: </span><br><span class='borderAroundText' style='width: 200px; height: 40px'>" + this.selectedClients[i].occupation + "</span></div>";
        messageHTML += "<div><span>Birth Date (yyyy-mm-dd): </span><br><span class='borderAroundText' style='width: 200px; height: 40px'>" + this.formattedDate(this.selectedClients[i].birthdate, 'date') + "</span></div>";
        this.selectedClients[i].phone === null ? this.selectedClients[i].phone = '' : this.selectedClients[i].phone;
        messageHTML += "<div><span>Phone: </span><br><span class='borderAroundText' style='width: 200px; height: 40px'>" + this.selectedClients[i].phone + "</span></div>";
        messageHTML += "</div><br>";
        this.selectedClients[i].email === null ? this.selectedClients[i].email = '' : this.selectedClients[i].email;
        messageHTML += "<span>Email: </span><br><span class='borderAroundText' style='width: 500px; height: 40px'>" + this.selectedClients[i].email + "</span><br><br>";
        this.selectedClients[i].food_preference === 'Not known' || this.selectedClients[i].food_preference === null ? this.selectedClients[i].food_preference = '' : this.selectedClients[i].food_preference;
        messageHTML += "<span>Food Preference: </span><br>";
        if (this.selectedClients[i].food_preference !== '') {
          messageHTML += "<span class='borderAroundText' style='width: 300px; height: 40px'>" + this.selectedClients[i].food_preference + "</span><br><br>";
        } else {
          messageHTML += "<span style='width: 400px; height: 40px'>I am neither vegetarian nor vegan - I am Vegetarian - I am Vegan </span><br><br>";
        }
        this.selectedClients[i].pets_preference === 'Not known' || this.selectedClients[i].pets_preference === null ? this.selectedClients[i].pets_preference = '' : this.selectedClients[i].pets_preference;
        messageHTML += "<span>Pets Preference: </span><br>";
        if (this.selectedClients[i].pets_preference !== '') {
          messageHTML += "<span class='borderAroundText' style='width: 300px; height: 40px'>" + this.selectedClients[i].pets_preference + "</span><br><br>";
        } else {
          messageHTML += "<span style='width: 400px; height: 40px'>I do not mind pets - No pets please</span><br><br>";
        }
        messageHTML += "<span>Emergency Contact</span><br>";
        messageHTML += "<span class='borderAroundText'>";
        this.selectedClients[i].ice_full_name === null ? this.selectedClients[i].ice_full_name = '' : this.selectedClients[i].ice_full_name;
        messageHTML += "<span>Name: </span><br><span class='borderAroundText' style='width: 400px; height: 40px'>" + this.selectedClients[i].ice_full_name + "</span><br>";
        this.selectedClients[i].ice_email === null ? this.selectedClients[i].ice_email = '' : this.selectedClients[i].ice_email;
        messageHTML += "<span>Email: </span><br><span class='borderAroundText' style='width: 500px; height: 40px'>" + this.selectedClients[i].ice_email + "</span><br>";
        messageHTML += "<div style='display: flex; flex-direction: row; justify-content: space-between;'>";
        this.selectedClients[i].ice_phone === null ? this.selectedClients[i].ice_phone = '' : this.selectedClients[i].ice_phone;
        messageHTML += "<div><span>Phone: </span><br><span class='borderAroundText' style='width: 200px; height: 40px'>" + this.selectedClients[i].ice_phone + "</span></div>";
        this.selectedClients[i].ice_phone_alt === null ? this.selectedClients[i].ice_phone_alt = '' : this.selectedClients[i].ice_phone_alt;
        messageHTML += "<div><span>Alternative Phone: </span><br><span class='borderAroundText' style='width: 200px; height: 40px'>" + this.selectedClients[i].ice_phone_alt + "</span></div>";
        messageHTML += "</div>";
        messageHTML += "</span><br><br>";
        messageHTML += "<span>Where do you know us from?</span><br>";
        messageHTML += "<span class='borderAroundText' style='width: 400px; height: 40px'>" + this.selectedClients[i].source + "</span><br><br>";
        messageHTML += "<div class='pagebreak'></div>";
        if (i < this.selectedClients.length - 1) {
          messageHTML += "<div style='padding-top: 2cm;'></div>";
        }
      }
      let payload = [];
      payload.messageHTML = messageHTML;
      await this.$store.dispatch('saveDataForReport', payload);
      this.$router.push({ name: 'ClientApplicationForms'});
    },

  },
}
</script>