export default {
    methods: {
        getNationalityFromId(id) {
            if (id) {
                const nationality = this.$store.state.itemsNationality.filter(function(select_item) {
                    return select_item.id === id;
                })
                return nationality[0].nationality;
            }
        },
    }
    
}