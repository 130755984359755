<template>
  <v-app>
    <Navbar class="d-print-none"></Navbar>
    <v-main class="px-4 pb-4 grey lighten-4 ml-sm-12">
      <router-view v-if="!statusLoggedOut"></router-view>
    </v-main>
    <Footer class="d-print-none"></Footer>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'

export default {
  name: 'App',

  components: {
    Navbar,
    Footer,
  },

  data: () => ({
    //
  }),
  computed: {
    statusLoggedOut () {
      return this.$store.getters.statusLoggedOut
    },
  }
};
</script>
