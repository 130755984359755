<template>
    <v-container v-if="$store.getters.loadingApp !== 1" style="max-width: 100%">
        <v-data-table
            :headers="headers"
            :items="updatedAvailableTours"
            class="elevation-1"
            :items-per-page="-1"
            disable-pagination
            hide-default-footer
            sort-by="name"
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-toolbar-title>Tours</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="ppc_pink"
                        dark
                        small
                        absolute
                        right
                        fab
                        @click="dialog=true"
                    >
                        <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                    </v-btn>
                    <v-dialog
                        v-model="dialog"
                        max-width="500px"
                        persistent
                    >
                        <v-form ref="dialogForm">
                            <v-card class="d-flex flex-column">
                                <v-card-title class="ppc_darkgreen darken-1">
                                    <span class="headline white--text">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-text-field
                                        v-model.trim="editedItem.name"
                                        label="Name"
                                        counter
                                        :rules="inputRulesName"
                                    ></v-text-field>
                                    <v-textarea
                                        class="rounded-xl ma-3"
                                        v-model="editedItem.description"
                                        auto-grow
                                        clearable
                                        counter
                                        outlined
                                        label="Description (500 max)"
                                        :rules="[v => (v || '' ).length <= 500 || 'Comments must be 500 characters or less']"
                                    ></v-textarea>
                                    <v-checkbox
                                        :input-value="editedItem.obsolete !== null"
                                        label="Obsolete"
                                        @change="setObsolete($event)"
                                    ></v-checkbox>
                                    <v-text-field
                                        :value="formattedDate(editedItem.obsolete)"
                                        disabled
                                        label="From"
                                        v-if="editedItem.obsolete !== null"
                                        class="ml-2"
                                    ></v-text-field>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="ppc_darkgreen darken-1"
                                        text
                                        @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="ppc_darkgreen darken-1"
                                        text
                                        @click="save"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title>Are you sure you want to delete {{ editedItem.name }}?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="ppc_darkgreen darken-1" text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="ppc_darkgreen darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:[`item.obsolete`]="{ item }">
                <v-icon small v-if="item.obsolete !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    $vuetify.icons.icon_mdi_pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    $vuetify.icons.icon_mdi_delete
                </v-icon>
            </template>
        </v-data-table>
        <v-divider></v-divider>
        <div class="d-flex flex-row mb-7">
            <v-spacer></v-spacer>
            <v-btn color="ppc_darkgreen darken-1" text @click="$router.go(-1)">Ok</v-btn>
        </div>
        
    </v-container>
</template>

<script>

import dayjs from 'dayjs';
import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';

export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Description', value: 'description' },
        { text: 'Obsolete', value: 'obsolete' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      updatedAvailableTours: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        category_id: 1,
        type: 'Volunteering',
        description: null,
        obsolete: null,
      },
      defaultItem: {
        name: '',
        category_id: 1,
        type: 'Volunteering',
        description: null,
        obsolete: null,
      },
    }),

    mixins: [inputRules, formattedDate],

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Tour' : 'Edit Tour'
        },
    },

    watch: {
    },

    beforeCreate () {
        if (this.$store.state.accessTours === 0) {
            this.$router.push({ name: 'Illegal' })
        }
        this.$store.dispatch('setLoadingApp', 1)
    },

    created () {
        dayjs.locale("en");
        this.initialize()
    },

    methods: {
        async initialize () {
            await this.getTours();
            let breadcrumb =  JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
            breadcrumb.push('Tours');
            await this.$store.dispatch('breadcrumbs', breadcrumb);
            this.$store.dispatch('setLoadingApp', 0)
        },

        async getTours() {
            this.updatedAvailableTours = JSON.parse(JSON.stringify(await this.$store.dispatch('getTours')));
            for (let index = 0; index < this.updatedAvailableTours.length; index++) {
                (this.updatedAvailableTours[index].obsolete) ? this.updatedAvailableTours[index].obsolete = this.formattedDate(this.updatedAvailableTours[index].obsolete, 'date') : this.updatedAvailableTours[index].obsolete = null;
            }
        },

        editItem (item) {
            this.editedIndex = this.updatedAvailableTours.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        async deleteItem (item) {
            this.editedIndex = this.updatedAvailableTours.indexOf(item)
            this.editedItem = Object.assign({}, item)
            // let tourAgencies = await JSON.parse(JSON.stringify(this.$store.dispatch('getTourPlacements')));
            // let tmp = this.editedItem.id;
            // let usedAgencies = tourAgencies.filter(function(select_item) {
            //         return select_item.project_id === tmp;
            //     }
            // )
            // if (usedAgencies.length > 0) {
            //     this.$store.dispatch('errorMessage', 'The project "' + this.editedItem.name + '" is still in use with ' + usedAgencies[0].name);
            //     return;
            // } else {
            //     this.dialogDelete = true
            // }
            this.dialogDelete = true
        },

        async deleteItemConfirm () {
            let response = await this.$store.dispatch('deleteTour', this.editedItem.id);
            if (response.data.affectedRows === 1) {
                this.$store.dispatch('successMessage', 'Tour deleted');
            } else {
                this.$store.dispatch('errorMessage', 'Something went wrong');
            }
            await this.getTours();
            this.closeDelete()
        },

        setObsolete(event) {
            if (event) {
                this.editedItem.obsolete = dayjs().format("YYYY-MM-DD")
            } else {
                this.editedItem.obsolete = null
            }
            
        },

        close () {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        async save () {
            if(this.$refs.dialogForm.validate()){
                let tmp = this.editedItem.name;
                let doubleName = this.updatedAvailableTours.filter(function(select_item) {
                        return select_item.name === tmp;
                    }
                )
                if (doubleName.length > 0 && this.editedIndex !== this.updatedAvailableTours.indexOf(doubleName[0])) {
                    this.$store.dispatch('errorMessage', 'The name "' + this.editedItem.name + '" is already in use!');
                    return;
                } else {
                    await this.$store.dispatch('insertTour', this.editedItem);
                    await this.getTours();
                }
                this.close()
            }
        },
    },
  }

</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
</style>