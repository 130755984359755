<template>
    <div class="d-flex justify-space-around" v-if="$store.getters.loadingApp !== 1">
        <v-card 
            flat
            class="pa-12"
            width="100%"
            max-width="297mm"
            min-height="210mm"
        >
            <span v-html="messageHTML" class="ma-2"></span>
        </v-card>
    </div>
</template>

<script>

import getDateFromTimestamp from '../mixins/getDateFromTimestamp';
import formattedDate from '../mixins/formattedDate';
import compare from '../mixins/compare';

export default {
    data () {
        return {
            teacher: null,
            classesPlanned: [],
            messagePlain: "",
            messageHTML: "",
            headersClasses: [
                { text: 'Fecha & hora', value: 'start' },
                { text: 'Horas', value: 'duration' },
                { text: 'Nivel', value: 'level' },
                { text: 'Estudiantes', value: 'amount_of_students' },
                { text: 'Commentariós', value: 'comments' },
                { text: 'Pago', value: 'payment' },
            ],
            courseIds: [],
        }
    },
    beforeCreate () {
        this.$store.dispatch('setLoadingApp', 1)
    },
    async created() {
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[breadcrumb.length - 1] !== 'AccommodationPaymentForm') {
            breadcrumb.push('AccommodationPaymentForm')
            this.$store.dispatch('breadcrumbs', breadcrumb);
        }
        this.$vuetify.goTo(0);
        await this.createAccommodationForm();
        this.$store.dispatch('setLoadingApp', 0)
    },
    mounted() {
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[0] === 'AccommodationPaymentForm') {
            this.$router.push({ name: 'PaymentAccommodationForm' })
        }
    },
    mixins: [getDateFromTimestamp, formattedDate, compare],

    watch: {
        resourcePaymentFormSent(newValue, oldValue) {
            console.log(newValue, oldValue)
            if (newValue) {
                this.updateResourcesInfoToResource();
            }
            this.$store.dispatch('resourcePaymentFormSent', false);
        }
    },

    computed: {
        resourcePaymentFormSent () {
            return this.$store.getters.resourcePaymentFormSent
        },
    },
    
    methods: {
        createAccommodationForm() {
            let dataForReport = JSON.parse(JSON.stringify(this.$store.state.dataForReport));
            this.$store.dispatch('saveDataForReport', );
            console.log(dataForReport)
            // this.classesPlanned = dataForReport.classes;
            // this.classesPlanned.sort(this.dynamicSort('start'));
            // this.teacher = dataForReport.teacher;
            let email = dataForReport.emailContact;
            // console.log(this.classesPlanned, email)

            let emailIntro = "Buenos días " + dataForReport.family + ",\n\n\n" + 
            "Presentamos abajo el pago hasta " + dataForReport.startOfNextMonth + ".\n\n"
            
            // HTML version
            let messageHeader = "<div style='margin-top: 0cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif;'>" +
            "<div style='display: flex; flex-direction: row; justify-content: space-between; margin: 0 0 2cm 0;'>" +
                "<div style='font-family: Roboto, sans-serif; font-size: 32px; color: #35b729;'><span>PROYECTO</span><strong>PERU</strong></div>" +
                "<div style='font-size: 32px; color: grey; display: flex; flex-direction: column; align-items: flex-end;'>" + 
                    "<div>Pago Alojamiento</div>" + 
                    "<h6>hasta " + dataForReport.startOfNextMonth + "</h6>" + 
                    "<div style='font-size: 24px;'>" + dataForReport.family + "</div>" +
                    "<div style='font-size: 18px;'>" + 
                        this.formattedDate(Date.now()) + 
                    "</div>" + 
                "</div>" + 
            "</div>" + 
            "</div>"
            let messageBody = "<div><table style='width: 100%;'><tr><th style='text-align: left;'>Cliente</th><th style='text-align: left;'>Desde</th><th style='text-align: left;'>Hasta</th><th style='text-align: left;'>Noches</th><th style='text-align: left;'>Recojo</th><th style='text-align: left;'>Pagado (PEN)</th><th style='text-align: left;'>Cálculo</th><th style='text-align: left;'>Pago (PEN)</th></tr>";
            for (let index = 0; index < dataForReport.resources.length; index++) {
                messageBody += "<tr style='font-size: 14px'><td>" + dataForReport.resources[index].full_name + "</td><td>" + this.formattedDate(dataForReport.resources[index].start, 'monthAndDay') + "</td>"
                messageBody += "<td>" + this.formattedDate(dataForReport.resources[index].lastPayDay, 'monthAndDay') + "</td><td>" + dataForReport.resources[index].nightsShouldBePaidTotal + "</td><td>S/." + dataForReport.resources[index].extras + "</td><td>" + dataForReport.resources[index].amountPaidReceiver + "</td><td>" + dataForReport.resources[index].paymentDescriptionReport + "</td><td>" + dataForReport.resources[index].toPayNow + "</td></tr>";
            }
            emailIntro +=  "En el caso en que los datos no estan conformes como hemos qedado, por favor avísanos lo mas pronto!\n\n\n" +
            "Attentamente, \n\n" + this.$store.state.user + "\n\n" +
            "info@proyectoperucentre.org\n" +
            "Proyecto Peru Centre\n" +
            "Avenida Arcopata 466-A\n" +
            "Cusco, Cusco, 45\n" +
            "+ 51 84 240 278"
            messageBody += "</table></div>";
            let messageFooter = "<div style='margin-top: 1cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif; background-color: #c7d3d1; height: 2px;'></div><div style='width: 100%; display: flex; flex-direction: row; justify-content: flex-end'><h3>Total: " + dataForReport.totalPayment + " PEN</h3></div>"
            this.messageHTML = messageHeader + messageBody + messageFooter;
            let payload = {
                submittedFrom: "AccommodationPaymentForm",
                emailList: email,
                emailSubject: "Pago Accommodacíon Proyecto Peru",
                emailIntro: emailIntro,
                messageHTML: "<div style='margin: 1cm 0 1cm 0; background-color: #c7d3d1; text-align: left; width: 100%; height: 2px;'></div>" + this.messageHTML,
                messagePlain: '',
                openEmailDialog: false,
                saveInvoice: true,
                attachments: null,
            };
            this.$store.dispatch('emailMessage', payload);
        },
        async updateResourcesInfoToResource() {
            var resources = null;
            for (let index = 0; index < this.courseIds.length; index++) {
                resources = await this.$store.dispatch('getResourcesCourseId', this.courseIds[index])
                for (let index2 = 0; index2 < resources.length; index2++) {
                    resources[index2].info_to_provider = new Date();
                    resources[index2].reserved = new Date();
                    (resources[index2].start) ? resources[index2].start = this.formattedDate(resources[index2].start, 'date') : resources[index2].start = null;
                    (resources[index2].end) ? resources[index2].end = this.formattedDate(resources[index2].end, 'date') : resources[index2].end = null;
                    (resources[index2].info_to_client) ? resources[index2].info_to_client = this.formattedDate(resources[index2].info_to_client, 'date') : resources[index2].info_to_client = null;
                    (resources[index2].info_to_provider) ? resources[index2].info_to_provider = this.formattedDate(resources[index2].info_to_provider, 'date') : resources[index2].info_to_provider = null;
                    (resources[index2].reserved) ? resources[index2].reserved = this.formattedDate(resources[index2].reserved, 'date') : resources[index2].reserved = null;
                    await this.$store.dispatch('insertResource', resources[index2]);
                }
            }
        },
    },
}
</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
    /* @media all {
        .page-break	{ display: none; }
    }
    @media print {
        .page-break	{ display: block; page-break-before: always; }
    } */
</style>