import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import zlib from 'zlib';
import download from 'downloadjs';
import router from '../router';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: false, //in production set to false !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    state: {
        // prod config normal ******************************
        address_API: 'http://192.168.100.4:3030/api',
        // prod config alternative
        // address_API: 'http://admin.proyectoperucentre.com/api',
        // // local test config 
        // address_API: 'http://localhost:3030/api',
        version: '12.51',
        // prod config ******************************
        leads: [],
        applications: [],
        paymentMethods: [],
        itemsNationality: [],
        exchangeRate: null,
        standard_rate_individual: null,
        standard_rate_group: null,
        available_services: [],
        available_agencies: [],
        available_descriptions: [],
        available_images: [],
        projectCategories: [],
        projects: [],
        types: [],
        client: [],
        services: [],
        step: 'A',
        radioGroups: [],
        radioOptions: [],
        options: [],
        loadingApp: 0,
        loadingAppProgress: 0,
        progressBar: false,
        progressAdvance: 0,
        loadingButton: false,
        errorLogIn: null,
        statusLoggedOut: false,
        user: null,
        uid: null,
        accessTeaching: 0,
        accessAccommodation: 0,
        accessProjects: 0,
        accessTours: 0,
        accessFinancial: 0,
        accessCajaGrande: 0,
        accessClientAgencies: 0,
        accessMenu: 0,
        users: [],
        displayPets: false,
        pets_preference_set: false,
        displayFood: false,
        food_preference_set: false,
        applicationComments: '',
        successMessage: "",
        errorMessage: "",
        warningMessage: "",
        displaySuccessMessage: Math.random(),
        displayErrorMessage: Math.random(),
        displayWarningMessage: Math.random(),
        uploadInfos: [],
        fileName: undefined,
        messageUpload: "",
        alertVPS: 0,
        alertConnection: 0,
        timeout: 3000,
        breadcrumbs: [],
        messagesPM: [],
        contacts: [],
        projectPlacements: [],
        // accommodations: [],
        courses: [],
        teachers: [],
        resultsNotSaved: false,
        spanish_levels: [],
        classRooms: [],
        scopeLanguage: 'Spanish',
        scopeOnline: 'all',
        scopeResources: 'ongoing',
        scopeClasses: 'ongoing',
        // scopeType: 'all',
        scopeCalendar: 'category',
        scopeServices: 'all',
        activeViewClassRooms: {view: 'day', startDate: new Date},
        activeTabTeaching: 0,
        activeTabTeachers: 0,
        emailMessage: null,
        scopeApplications: 'ongoing',
        directOrAgencies: 'directClients',
        selectedAgency: null,
        exchangeRates: [],
        financialLocked: null,
        reloadResourcesTourAgencies: false,
        statusClients: [
            {id: 1, name: 'completed', color: 'ppc_grey'},
            {id: 2, name: 'send thank you message', color: 'orange'},
            {id: 3, name: 'executing', color: 'ppc_darkgreen'},
            {id: 4, name: 'waiting to start', color: 'ppc_darkgreen'},
            {id: 5, name: 'send handbook', color: 'orange'},
            {id: 6, name: 'waiting for reply', color: 'ppc_darkgreen'},
            {id: 7, name: 'payment issue', color: 'red'},
            {id: 8, name: 'cancelled', color: 'ppc_grey'},
            {id: 9, name: 'not converted', color: 'ppc_grey'},
            {id: 10, name: 'resource incomplete', color: 'brown'},
        ],
        statusServices: [
            {id: 1, name: 'completed', color: 'ppc_grey'},
            {id: 2, name: 'executing', color: 'ppc_darkgreen'},
            {id: 3, name: 'all set', color: 'ppc_darkgreen'},
            {id: 4, name: 'resource incomplete', color: 'brown'},
            {id: 5, name: 'planning services', color: 'ppc_darkgreen'},
            {id: 6, name: 'waiting for dates', color: 'ppc_darkgreen'},
            {id: 7, name: 'waiting for confirmation', color: 'ppc_darkgreen'},
            {id: 8, name: 'checking availability', color: 'ppc_pink'},
            {id: 9, name: 'contact client asap', color: 'ppc_pink'},
            {id: 10, name: 'reviewing upload', color: 'ppc_pink'},
            {id: 11, name: 'waiting for passport', color: 'brown'},
            {id: 12, name: 'waiting for cv', color: 'brown'},
            {id: 13, name: 'payment needed', color: 'red'},
            {id: 14, name: 'no payment, almost starting', color: 'orange'},
            {id: 15, name: 'no payment, started', color: 'red'},
            {id: 16, name: 'cancelled', color: 'ppc_grey'},
        ],
        statusResources: [
            {id: 1, name: 'completed', color: 'ppc_grey'},
            {id: 2, name: 'pay the provider', color: 'orange'},
            {id: 3, name: 'executing', color: 'ppc_darkgreen'},
            {id: 4, name: 'preparations completed', color: 'ppc_darkgreen'},
            {id: 5, name: 'send info to client', color: 'orange'},
            {id: 6, name: 'waiting for confirmation provider', color: 'ppc_darkgreen'},
            {id: 7, name: 'send info to/select provider', color: 'orange'},
            {id: 8, name: 'waiting for deposit/confirmation', color: 'ppc_darkgreen'},
            {id: 9, name: 'resource incomplete', color: 'brown'},
            {id: 10, name: 'no classes planned', color: 'orange'},
            {id: 11, name: 'classes almost starting', color: 'blue'},
            {id: 12, name: 'classes did not start', color: 'red'},
            {id: 13, name: 'no security deposit', color: 'red'},
            {id: 14, name: 'return security deposit', color: 'red'},
            {id: 15, name: 'cancelled', color: 'ppc_grey'},
        ],
        showInvoice: false,
        resourcePaymentFormSent: false,
        currentClient: null,
        monthCalcPaymentsAccommodation: {
            type: 'family',
            month: dayjs().format('YYYY-MM'),
        },
        selectAllTeachers: true,
        // selectAllRooms: true,
    },

    /********************************************************************************/
    /********************************************************************************/
    /********************************** GETTERS *************************************/
    /********************************************************************************/
    /********************************************************************************/

    getters: {
        leads (state){
            return state.leads
        },
        client (state){
            return state.client
        },
        services (state){
            return state.services
        },
        radioGroups (state){
            return state.radioGroups
        },
        servicesLength (state){
            return state.services.length
        },
        applications (state){
            return state.applications
        },
        statusLoggedOut (state){
            return state.statusLoggedOut
        },
        step (state) {
            return state.step
        },
        loadingApp (state) {
            return state.loadingApp
        },
        loadingAppProgress (state) {
            return state.loadingAppProgress
        },
        loadingButton (state) {
            return state.loadingButton
        },
        errorLogIn (state) {
            return state.errorLogIn
        },
        uploadInfos (state) {
            return state.uploadInfos
        },
        progressBar (state) {
            return state.progressBar
        },
        progressAdvance (state) {
            return state.progressAdvance
        },
        messageUpload (state) {
            return state.messageUpload
        },
        alertVPS (state) {
            return state.alertVPS
        },
        alertConnection (state) {
            return state.alertConnection
        },
        successMessage (state) {
            return state.successMessage
        },
        errorMessage (state) {
            return state.errorMessage
        },
        warningMessage (state) {
            return state.warningMessage
        },
        displaySuccessMessage (state) {
            return state.displaySuccessMessage
        },
        displayErrorMessage (state) {
            return state.displayErrorMessage
        },
        displayWarningMessage (state) {
            return state.displayWarningMessage
        },
        messagesPM (state) {
            return state.messagesPM
        },
        resultsNotSaved (state) {
            return state.resultsNotSaved
        },
        classRooms (state) {
            return state.classRooms
        },
        scopeLanguage (state) {
            return state.scopeLanguage
        },
        scopeOnline (state) {
            return state.scopeOnline
        },
        scopeResources (state) {
            return state.scopeResources
        },
        scopeClasses (state) {
            return state.scopeClasses
        },
        // scopeType (state) {
        //     return state.scopeType
        // },
        scopeCalendar (state) {
            return state.scopeCalendar
        },
        scopeServices (state) {
            return state.scopeServices
        },
        teachers (state) {
            return state.teachers
        },
        activeTabTeaching (state) {
            return state.activeTabTeaching
        },
        activeTabTeachers (state) {
            return state.activeTabTeachers
        },
        // groupBy (state) {
        //     return state.groupBy
        // },
        // showCurrency (state) {
        //     return state.showCurrency
        // },
        // showCurrentBalance (state) {
        //     return state.showCurrentBalance
        // },
        // saveInvoice (state) {
        //     return state.saveInvoice
        // },
        showInvoice (state) {
            return state.showInvoice
        },
        resourcePaymentFormSent (state) {
            return state.resourcePaymentFormSent
        },
        accessMenu (state) {
            return state.accessMenu
        },
        currentClient (state) {
            return state.currentClient
        },
        monthCalcPaymentsAccommodation (state) {
            return state.monthCalcPaymentsAccommodation
        },
        emailMessage (state) {
            return state.emailMessage
        },
        selectAllTeachers (state) {
            return state.selectAllTeachers
        },
        // selectAllRooms (state) {
        //     return state.selectAllRooms
        // },
        scopeApplications (state) {
            return state.scopeApplications
        },
        directOrAgencies (state) {
            return state.directOrAgencies
        },
        selectedAgency (state) {
            return state.selectedAgency
        },
        reloadResourcesTourAgencies (state) {
            return state.reloadResourcesTourAgencies
        },
    },

    /********************************************************************************/
    /********************************************************************************/
    /********************************** MUTATIONS ***********************************/
    /********************************************************************************/
    /********************************************************************************/

    mutations: {
        setAddress_API (state, payload) {
            state.address_API = payload;
        },
        breadcrumbs (state, payload) {
            console.log('Breadcrumbs: ', payload)
            state.breadcrumbs = payload;
        },
        showInvoice (state, payload) {
            state.showInvoice = payload;
        },
        setUser (state, payload) {
            state.user = payload.name;
            state.uid = payload.id;
            state.accessTeaching = payload.accessTeaching;
            state.accessAccommodation = payload.accessAccommodation;
            state.accessProjects = payload.accessProjects;
            state.accessTours = payload.accessTours;
            state.accessFinancial = payload.accessFinancial;
            state.accessCajaGrande = payload.accessCajaGrande;
            state.accessClientAgencies = payload.accessClientAgencies;
            state.showBank = payload.showBank;
            state.showWise = payload.showWise;
            state.unfreezeApplication = payload.unfreezeApplication;
            state.accessMenu = Math.random();
        },
        initUsers (state, payload) {
            state.users = payload
        },
        initContacts (state, payload) {
            state.contacts = payload
        },
        initProjectPlacements (state, payload) {
            state.projectPlacements = payload
        },
        initCourses (state, payload) {
            state.courses = payload
        },
        initTeachers (state, payload) {
            state.teachers = payload;
            for (let index = 0; index < state.teachers.length; index ++) {
                state.teachers[index].visible = true;
            }
        },
        initClassRooms (state, payload) {
            state.classRooms = payload;
            for (let index = 0; index < state.classRooms.length; index ++) {
                state.classRooms[index].hide = false;
            }
        },
        statusLoggedOut (state, payload) {
            state.statusLoggedOut = payload
        },
        messagesPM (state, payload) {
            state.messagesPM = payload
        },
        resultsNotSaved (state, payload) {
            state.resultsNotSaved = payload
        },
        initLeads (state, payload) {
            state.leads = payload;
        },
        initLanguageLevels (state, payload) {
            state.spanish_levels = payload;
        },
        initClient (state, payload) {
            (payload.start_first) ? payload.start_first = dayjs(payload.start_first).format("YYYY-MM-DD") : payload.start_first = null;
            (payload.end_last) ? payload.end_last = dayjs(payload.end_last).format("YYYY-MM-DD") : payload.end_last = null;
            (payload.spanishTestDone) ? payload.spanishTestDone = dayjs(payload.spanishTestDone).format("YYYY-MM-DD") : payload.spanishTestDone = null;
            (payload.birthdate) ? payload.birthdate = dayjs(payload.birthdate).format("YYYY-MM-DD") : payload.birthdate = null;
            (payload.pickup_date) ? payload.pickup_date = dayjs(payload.pickup_date).format("YYYY-MM-DD") : payload.pickup_date = null;
            (payload.birthdate) ? payload.birthdate = dayjs(payload.birthdate).format("YYYY-MM-DD") : payload.birthdate = null;
            (payload.welcome_email) ? payload.welcome_email = dayjs(payload.welcome_email).format("YYYY-MM-DD") : payload.welcome_email = null;
            (payload.thank_you_email) ? payload.thank_you_email = dayjs(payload.thank_you_email).format("YYYY-MM-DD") : payload.thank_you_email = null;
            (payload.first_schedule_sent) ? payload.first_schedule_sent = dayjs(payload.first_schedule_sent).format("YYYY-MM-DD") : payload.first_schedule_sent = null;
            (payload.handbookSent) ? payload.handbookSent = dayjs(payload.handbookSent).format("YYYY-MM-DD") : payload.handbookSent = null;
            (payload.nextContact) ? payload.nextContact = dayjs(payload.nextContact).format("YYYY-MM-DD") : payload.nextContact = null;
            payload.uid = state.uid;
            state.client = payload;
        },
        clientUpdate (state, payload) {
            state.client = payload
        },
        initApplications (state, payload) {
            for (let i = 0; i < payload.length; i++) {
                (payload[i].applied) ? payload[i].applied = dayjs(payload[i].applied).format("YYYY-MM-DD") : payload[i].applied = null;
                (payload[i].syncPM == 1) ? payload[i].syncPM = true : payload[i].syncPM = false;    
            }
            state.applications = payload;
        },
        initPaymentMethods (state, payload) {
            state.paymentMethods = payload;
        },
        changeCurrentClient (state, id) {
            state.currentClient = id;
        },
        initServices (state, payload) {
            let i;
            for (i = 0; i < payload.length; i++) {
                (payload[i].start) ? payload[i].start = dayjs(payload[i].start).format("YYYY-MM-DD") : payload[i].start = null;
                (payload[i].end) ? payload[i].end = dayjs(payload[i].end).format("YYYY-MM-DD") : payload[i].end = null;
                (payload[i].syncPM == 1) ? payload[i].syncPM = true : payload[i].syncPM = false;
                payload[i].serviceNotChanged = true;
            }
            state.services = payload;
        },
        initAvailableImages (state, payload) {
            state.available_images = payload
        },
        changeStep (state, payload) {
            state.step = payload;
        },
        alertVPS (state) {
            state.alertVPS = Math.random();
        },
        alertConnection (state) {
            state.alertConnection = Math.random();
        },
        initOptions (state, payload) {
            state.options = payload;
        },
        initRadioGroups (state, payload) {
            state.radioGroups = payload
        },
        initRadioOptions (state, payload) {
            state.radioOptions = payload
        },
        setLoadingApp (state, payload) {
            state.loadingApp = payload;
        },
        showProgressBar (state, payload) {
            state.progressBar = payload
        },
        setLoadingButton (state, payload) {
            state.loadingButton = payload
        },
        errorLogIn (state, payload) {
            state.errorLogIn = payload
        },
        clearError (state) {
            state.errorLogIn = null
        },
        initNationalities (state, payload) {
            state.itemsNationality = payload
        },
        initSources (state, payload) {
            state.itemsSources = payload
        },
        initGeneralSettings (state, payload) {
            state.exchangeRate = payload[0].currentExchangeRate;
            state.standard_rate_individual = payload[0].standard_rate_individual;
            state.standard_rate_group = payload[0].standard_rate_group;
            state.standard_rate_special = payload[0].standard_rate_special;
            state.standard_rate_special_group = payload[0].standard_rate_special_group;
            state.financialLocked = payload[0].financial_locked;
        },
        initTypes (state, payload) {
            state.types = payload
        },
        // initStatus (state, payload) {
        //     state.status = payload
        // },
        initProjectCategories (state, payload) {
            state.projectCategories = payload
        },
        initProjects (state, payload) {
            state.projects = payload
        },
        initDescriptions (state, payload) {
            state.available_descriptions = payload
        },
        initAvailableAgencies (state, payload) {
            state.available_agencies = payload
        },
        initAvailableServices (state, payload) {
            state.available_services = payload
        },
        checkCurrentStatus (state, application_nr) {
            console.log(application_nr)
            let array = state.services.filter(function(select_item) {
                return select_item.application_nr === application_nr;
            })
            let pos = this.state.applications.map(function(e) { return e.application_nr; }).indexOf(application_nr);
            if (array.length>0) {
                state.applications[pos].currentStatus = Math.max.apply(Math, array.map(function(o) { return o.currentStatus; }));
            } else {
                state.applications[pos].currentStatus = 7;
            }
            console.log(state.applications[pos].currentStatus)
        },
        uploadInfos (state, payload) {
            state.uploadInfos = payload;
        },
        progressAdvance (state, payload) {
            state.progressAdvance = payload;
        },
        messageUpload (state, payload) {
            state.messageUpload = payload;
        },
        setFileName (state, payload) {
            state.fileName = payload
        },
        activeTabTeaching (state, payload) {
            state.activeTabTeaching = payload;
        },
        activeTabTeachers (state, payload) {
            state.activeTabTeachers = payload;
        },
        emailMessage (state, payload) {
            state.emailMessage = payload;
        },
        changeActiveViewClassRooms (state, payload) {
            state.activeViewClassRooms = payload;
        },
        saveDataForReport (state, payload) {
            state.dataForReport = payload;
        },
        // changeClassRooms (state, payload) {
        //     state.classRooms = payload;
        // },
        // changeSelectAllRooms (state, payload) {
        //     state.selectAllRooms = payload;
        // },
        changescopeLanguage (state, payload) {
            state.scopeLanguage = payload;
        },
        changeScopeOnline (state, payload) {
            state.scopeOnline = payload;
        },
        changeScopeResources (state, payload) {
            state.scopeResources = payload;
        },
        changeScopeClasses (state, payload) {
            state.scopeClasses = payload;
        },
        // changeScopeType (state, payload) {
        //     state.scopeType = payload;
        // },
        changeScopeCalendar (state, payload) {
            state.scopeCalendar = payload;
        },
        changeScopeServices (state, payload) {
            state.scopeServices = payload;
        },
        changeTeacherVisibility (state, payload) {
            state.teachers[payload.index].visible = payload.visibility;
        },
        changeSelectAllTeachers (state, payload) {
            state.selectAllTeachers = payload;
        },
        successMessage (state, payload) {
            state.successMessage = payload;
            state.displaySuccessMessage = Math.random();
        },
        errorMessage (state, payload) {
            state.errorMessage = payload;
            state.displayErrorMessage = Math.random();
        },
        warningMessage (state, payload) {
            state.warningMessage = payload;
            state.displayWarningMessage = Math.random();
        },
        resourcePaymentFormSent (state, payload) {
            state.resourcePaymentFormSent = payload;
        },
        monthCalcPaymentsAccommodation (state, payload) {
            state.monthCalcPaymentsAccommodation = payload;
        },
        changeScopeApplications (state, payload) {
            state.scopeApplications = payload;
        },
        changeDirectOrAgencies (state, payload) {
            state.directOrAgencies = payload;
        },
        changeSelectedAgency (state, payload) {
            state.selectedAgency = payload;
        },
        getExchangeRates (state, payload) {
            state.exchangeRates = payload;
        },
        reloadResourcesTourAgencies (state, payload) {
            state.reloadResourcesTourAgencies = payload;
        },
    },

    /********************************************************************************/
    /********************************************************************************/
    /********************************** ACTIONS *************************************/
    /********************************************************************************/
    /********************************************************************************/

    actions: {
        testConnection (context) {
            return axios
                .get(this.state.address_API + '/testConnection/', { timeout: 5000 })
                .then(result => {
                    context.commit('successMessage', 'Connected to database: ' + result.data[0].databaseName + ' Build: ' + this.state.version)
                })
                .catch(
                    error => {
                        console.log('switching to external network')
                        context.commit('setAddress_API', 'https://admin.proyectoperucentre.com/api')
                        console.log(error)
                        return axios
                            .get('https://admin.proyectoperucentre.com/api/testConnection/')
                            .then(result => {
                                console.log('connection ', result);
                                context.commit('successMessage', 'Connected to database external: ' + result.data[0].databaseName + ' Build: ' + this.state.version)
                            })
                            .catch(
                                error => {
                                    console.log('no network')
                                    context.commit('alertConnection')
                                    console.log(error)
                                }
                            )
                    }
                )
        },
        setAddress_API (context, payload) {
            context.commit('address_API', payload);
        },
        signUserOut (context) {
            context.commit('statusLoggedOut', true)
        },
        resultsNotSaved (context, payload) {
            context.commit('resultsNotSaved', payload)
        },
        signUserIn (context, payload) {
            return axios
                .get(this.state.address_API + '/signUserIn/', {
                    params: {
                        name: payload.user,
                        password: payload.password
                    }
                })
                .then(result => {
                    router.push({ name: 'Illegal' });
                    context.commit('setUser', result.data[0]);
                    context.commit('statusLoggedOut', false);
                    context.commit('setLoadingButton', false);
                })
                .catch(
                    error => {
                        context.commit('setLoadingButton', false);
                        console.log(error)
                        if (JSON.stringify(error).includes(501)) {
                            context.commit('errorLogIn', 'user and/or password incorrect')
                        } else {
                            context.commit('errorLogIn', error)
                        }
                    }
                )
        },
        // addUser (context, payload) {
        //     axios.post(this.state.address_API + '/add_user/', {
        //         body: payload
        //     })
        //     .then(response => {
        //         console.log(response);
        //         context.commit('setLoadingButton', false);
        //     })
        //     .catch(error => {
        //         console.log(error.response);
        //         context.commit('setLoadingButton', false);
        //     });
        // },
        changePsw (context, payload) {
            console.log(payload)
            axios.put(this.state.address_API + '/changePsw/', {
                body: payload
            })
            .then(response => {
                console.log(response);
                context.commit('setLoadingButton', false);
            })
            .catch(error => {
                console.log(error.response);
                context.commit('setLoadingButton', false);
            });
        },
        setLoadingApp (context, payload) {
            if (payload === 'add') {
                payload = this.state.loadingApp + 1;
            } else if (payload === 'dis') {
                payload = this.state.loadingApp - 1;
            }
            context.commit('setLoadingApp', payload);
        },
        showProgressBar (context, payload) {
            context.commit('showProgressBar', payload);
        },
        progressAdvance (context, payload) {
            context.commit('progressAdvance', payload);
        },
        setLoadingButton (context, payload) {
            context.commit('setLoadingButton', payload);
        },
        // addAvailableService () {
        //     return axios.post(this.state.address_API + '/availableService_new/')
        //     .then(response => {
        //         console.log(response.data.insertId);
        //         return response.data.insertId;
        //         // let serviceNr = response.data.insertId;
        //         // return axios.post(this.state.address_API + '/availableService_new_vps/' + serviceNr)
        //         // .then(response => {
        //         //     console.log(response);
        //         //     return serviceNr;
        //         // })
        //         // .catch(error => {
        //         //     console.log(error.response);
        //         // });
        //     })
        //     .catch(error => {
        //         console.log(error.response);
        //     });
        // },
        insertAvailableService (context, payload) {
            payload.uid = this.state.uid;
            return axios.post(this.state.address_API + '/insertAvailableService/', {
                body: payload
            })
            .then(response => {
                console.log(response);
                return response.data.insertId;
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteAvaliableService(context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteAvailableService/' + payload)
            .then(result => {
                console.log(result)
                this.dispatch('getAvailableServicesEdit');
                this.dispatch('getAvailableServices');
                // return axios
                // .delete(this.state.address_API + '/deleteAvailableService_vps/' + payload)
                // .then(result => {
                //     console.log(result)
                //     this.dispatch('getAvailableServicesEdit');
                // })
                // .catch(error => {
                //     console.log(error);
                // });
            })
            .catch(error => {
                console.log(error);
            });
        },
        // available services VPS
        deleteAllAvailableServices_vps() {
            return axios
            .delete(this.state.address_API + '/deleteAllAvailableServices_vps/')
            .then(result => {
                console.log(result)
            })
            .catch(error => {
                console.log(error);
            });
        },
        insertAvailableService_vps(context, payload) {
            return axios.post(this.state.address_API + '/insertAvaliableService_vps/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },

        // available services website
        deleteAllAvailableServices_website() {
            return axios
            .delete(this.state.address_API + '/deleteAllAvailableServices_website/')
            .then(result => {
                console.log(result)
            })
            .catch(error => {
                console.log(error);
            });
        },

        insertAvailableService_website(context, payload) {
            return axios.post(this.state.address_API + '/insertAvailableService_website/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },

        addLead (context, payload) {
            payload.uid = this.state.uid;
            console.log('addLead', payload)
            return axios.post(this.state.address_API + '/lead_new/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        getAvailableImages (context) {
            return axios.get(this.state.address_API + '/available_images/')
                .then(result => {
                    context.commit('initAvailableImages', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        uploadImage (context, payload) {
            payload.name = this.state.fileName;
            context.commit('progressAdvance', 0);
            
            var config = {
                onUploadProgress: function(progressEvent) {
                    var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    context.commit('progressAdvance', percentCompleted);
                }
            };
            axios.post(this.state.address_API + '/upload_image/', payload, config)
            .then(response => {
                console.log(response);
                this.dispatch('getAvailableImages')
                context.commit('successMessage', 'Image successfully uploaded!')
                context.commit('progressAdvance', 0);
            })
            .catch(error => {
                console.log(error);
            });
        },
        downloadImage (context, payload) {
            console.log(payload)
            return axios
                .get(this.state.address_API + '/downloadImage_id/' + payload)
                .then(result => {
                    if (result.data) {
                        console.log(result.data.name)
                        const image = result.data.image
                        const originalObj = JSON.parse(zlib.unzipSync(Buffer.from(image, 'base64')));
                        download(originalObj, result.data.name)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        deleteImage (context, payload) {
            console.log
            return axios
                .delete(this.state.address_API + '/deleteImage_id/' + payload)
                .then(result => {
                    console.log(result)
                    this.dispatch('getAvailableImages', payload)
                    context.commit('successMessage', 'Image successfully removed!')
                })
                .catch(error => {
                    context.commit('errorMessage', 'Image being used!')
                    console.log(error);
                });
        },
        // **************** available images VPS *******************
        getAvailableImages_vps (context) {
            return axios.get(this.state.address_API + '/available_images_vps/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                        context.commit('errorMessage', 'something went wrong on the Package Maker, please check (available_images_vps)')
                    }
                )
        },
        uploadImage_vps (context, payload) {
            axios.post(this.state.address_API + '/upload_image_vps/', payload)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
                context.commit('errorMessage', 'something went wrong on the Package Maker, please check (upload_image_vps)')
            });
        },
        deleteImage_vps (context, payload) {
            return axios
                .delete(this.state.address_API + '/deleteImage_id_vps/' + payload)
                .then(result => {
                    console.log(result)
                })
                .catch(error => {
                    console.log(error);
                    context.commit('errorMessage', 'something went wrong on the Package Maker, please check (deleteImage_id_vps)')
                });
        },
        // ***********************************************************************
        getSources (context) {
            return axios
                .get(this.state.address_API + '/sources/')
                .then(result => {
                    // console.log('getSources', result.data)
                    context.commit('initSources', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getNationalities (context) {
            return axios
                .get(this.state.address_API + '/nationalities/')
                .then(result => {
                    context.commit('initNationalities', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getGeneralSettings (context) {
            return axios
                .get(this.state.address_API + '/general_settings/')
                .then(result => {
                    context.commit('initGeneralSettings', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error);
                        return error;
                    }
                )
        },
        getUsers (context) {
            return axios
                .get(this.state.address_API + '/get_users/')
                .then(result => {
                    context.commit('initUsers', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        // Contacts

        getContacts (context) {
            return axios
                .get(this.state.address_API + '/getContacts/')
                .then(result => {
                    context.commit('initContacts', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertContact (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertContact/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteContact (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteContact/' + payload)
            .then(response => {
                console.log(response);
                return response;
            })
            .catch(error => {
                console.log(error);
            });
        },

        //Organisations

        getOrganisations (context, payload) {
            return axios
                .get(this.state.address_API + '/getOrganisations/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getOrganisationsAll () {
            return axios
                .get(this.state.address_API + '/getOrganisationsAll/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertOrganisation (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertOrganisation/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteOrganisation (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteOrganisation/' + payload)
            .then(response => {
                console.log(response);
                return response;
            })
            .catch(error => {
                console.log(error);
            });
        },

        // Project Placements

        getProjectPlacements (context) {
            return axios
                .get(this.state.address_API + '/getProjectPlacements/')
                .then(result => {
                    context.commit('initProjectPlacements', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertProjectPlacement (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertProjectPlacement/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteProjectPlacement (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteProjectPlacement/' + payload)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.log(error);
            });
        },
        // Accommodations
        getAccommodations () {
            return axios
                .get(this.state.address_API + '/getAccommodations/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getAccommodationPrices () {
            return axios
                .get(this.state.address_API + '/getAccommodationPrices/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertAccommodation (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertAccommodation/', {
                body: payload
            })
            .then(result => {
                console.log(result);
                return result.data;
            })
            .catch(error => {
                console.log(error.result);
            });
        },
        deleteAccommodation (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteAccommodation/' + payload)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },
        insertAccommodationPrices (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertAccommodationPrices/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteAccommodationPrices (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteAccommodationPrices/' + payload)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },

        // Rooms
        getRooms () {
            return axios
                .get(this.state.address_API + '/getRooms/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertRoom (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertRoom/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteRoom (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteRoom/' + payload)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },

        // Courses
        getCoursesTeacherId (context, teacherId) {
            return axios
                .get(this.state.address_API + '/getCoursesTeacherId/' + teacherId)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getCoursesTeacherIdAll (context, teacherId) {
            return axios
                .get(this.state.address_API + '/getCoursesTeacherIdAll/' + teacherId)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getCourses () {
            return axios
                .get(this.state.address_API + '/getCourses/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getCoursesSafe (context, sqlTxt) {
            return axios
                .get(this.state.address_API + '/getCoursesSafe/' + sqlTxt)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getCoursesAll () {
            return axios
                .get(this.state.address_API + '/getCoursesAll/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getCourseId (context, courseId) {
            console.log(courseId);
            return axios
                .get(this.state.address_API + '/getCourseId/' + courseId)
                .then(result => {
                    return result.data[0];
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertCourse (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertCourse/', {
                body: payload
            })
            .then(response => {
                return response;
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteCourse (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteCourse/' + payload)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },
        // Teachers
        getTeachers (context) {
            return axios
                .get(this.state.address_API + '/getTeachers/')
                .then(result => {
                    context.commit('initTeachers', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertTeacher (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertTeacher/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        updateTeacherSchedule (context, payload) {
            console.log(payload);
            return axios
                .put(this.state.address_API + '/updateTeacherSchedule/', {
                    body: payload
                })
                .then(response => {
                    console.log(response);
                })
                .catch(
                    error => {
                        console.log(error);
            })
        },
        deleteTeacher (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteTeacher/' + payload)
            .then(response => {
                console.log(response);
                return response;
            })
            .catch(error => {
                console.log(error);
            });
        },
        // class rooms
        getClassRooms (context) {
            return axios
                .get(this.state.address_API + '/getClassRooms/')
                .then(result => {
                    context.commit('initClassRooms', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        // classes
        getResourcesClassId (context, classId) {
            return axios
                .get(this.state.address_API + '/getResourcesClassId/' + classId)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClassesResourceId (context, resourceId) {
            return axios
                .get(this.state.address_API + '/getClassesResourceId/' + resourceId)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClassesClientId (context, clientId) {
            return axios
                .get(this.state.address_API + '/getClassesClientId/' + clientId)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClassesSafe (context, teachersVisible) {
            return axios
                .get(this.state.address_API + '/getClassesSafe/' + teachersVisible)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClassesOutgoingId (context, outgoingId) {
            return axios
                .get(this.state.address_API + '/getClassesOutgoingId/' + outgoingId)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClassesTeacherIdOngoing (context, teacherId) {
            return axios
                .get(this.state.address_API + '/getClassesTeacherIdOngoing/' + teacherId)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClassesTeacherIdFinishedPaid (context, teacherId) {
            return axios
                .get(this.state.address_API + '/getClassesTeacherIdFinishedPaid/' + teacherId)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClassesTeacherIdNotPaid (context, teacherId) {
            return axios
                .get(this.state.address_API + '/getClassesTeacherIdNotPaid/' + teacherId)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClassesTeacherIdFinishedNotPaid (context, teacherId) {
            return axios
                .get(this.state.address_API + '/getClassesTeacherIdFinishedNotPaid/' + teacherId)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClassesTeacherIdAll (context, teacherId) {
            return axios
                .get(this.state.address_API + '/getClassesTeacherIdAll/' + teacherId)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClassesDay (context, day) {
            return axios
                .get(this.state.address_API + '/getClassesDay/' + day)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClassesNotFinished () {
            return axios
                .get(this.state.address_API + '/getClassesNotFinished')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        // getClassesNotPaid () {
        //     return axios
        //         .get(this.state.address_API + '/getClassesNotPaid')
        //         .then(result => {
        //             return result.data;
        //         })
        //         .catch(
        //             error => {
        //                 console.log(error)
        //             }
        //         )
        // },
        async insertClass (context, payload) {
            // console.log('insertClass start from $store', payload)
            if (payload.end < payload.start) {
                let t = payload.start;
                payload.start = payload.end;
                payload.end = t;
            }
            if (!payload.autoPayment) {
                payload.autoPayment = 1;
            }
            let result = await axios.post(this.state.address_API + '/insertClass/', {
                body: payload
            })
            .catch(error => {
                console.log(error.response);
                context.commit('errorMessage', 'something went wrong when saving class (' + payload.class_id + '), please check')
            });
            // console.log('ok from $store', result.data.insertId)
            return result.data.insertId;
        },
        
        insertResourcesClasses (context, payload) {
            console.log('insertResourcesClasses ', payload)
            return axios.post(this.state.address_API + '/insertResourcesClasses/', {
                body: payload
            })
            .then(response => {
                return response;
            })
            .catch(error => {
                console.log(error.response);
                context.commit('errorMessage', 'something went wrong when connecting class to course (' + payload.class_id + ',' + payload.resource_id + '), please check')
            });
        },

        // deleteStudentFromClass (context, payload) {
        //     return axios
        //     .delete(this.state.address_API + '/deleteStudentFromClass/' + payload)
        //     .then(response => {
        //         console.log(response)
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
        // },

        deleteStudentFromClassIds (context, payload) {
            return axios.delete(this.state.address_API + '/deleteStudentFromClassIds/', {
                params: { 
                    resourceId: payload.resourceId,
                    classId: payload.classId,
                }
            })
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        deleteClass (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteClass/' + payload)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },

        // paidClassToTeacher (context, class_id) {
        //     return axios
        //         .get(this.state.address_API + '/paidClassToTeacher/' + class_id)
        //         .then(result => {
        //             return result.data;
        //         })
        //         .catch(
        //             error => {
        //                 console.log(error)
        //             }
        //         )
        // },

        // Resources
        getResourceIdCourse (context, payload) {
            return axios
                .get(this.state.address_API + '/getResourceIdCourse/' + payload)
                .then(result => {
                    return result.data[0];
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourceIdAcc (context, payload) {
            return axios
                .get(this.state.address_API + '/getResourceIdAcc/' + payload)
                .then(result => {
                    return result.data[0];
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getCourseResourceId (context, payload) {
            return axios
                .get(this.state.address_API + '/getCourseResourceId/' + payload)
                .then(result => {
                    return result.data[0];
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesClientId (context, payload) {
            return axios
                .get(this.state.address_API + '/getResourcesClientId/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesCourseId (context, payload) {
            return axios
                .get(this.state.address_API + '/getResourcesCourseId/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        openQuery (context, name) {
            return axios
                .get(this.state.address_API + '/openQuery/' , { params: {name} } )
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesClassesIncompleted () {
            return axios
                .get(this.state.address_API + '/getResourcesClassesIncompleted/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesClassesCompleted () {
            return axios
                .get(this.state.address_API + '/getResourcesClassesCompleted/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesClassesNotPlanned () {
            return axios
                .get(this.state.address_API + '/getResourcesClassesNotPlanned/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesClassesAlmostStarting () {
            return axios
                .get(this.state.address_API + '/getResourcesClassesAlmostStarting/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesClassesExecuting () {
            return axios
                .get(this.state.address_API + '/getResourcesClassesExecuting/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesClassesAll () {
            return axios
                .get(this.state.address_API + '/getResourcesClassesAll/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        // getResourcesProjects () {
        //     return axios
        //         .get(this.state.address_API + '/getResourcesProjects/')
        //         .then(result => {
        //             return result.data;
        //         })
        //         .catch(
        //             error => {
        //                 console.log(error)
        //             }
        //         )
        // },
        getResourcesAllProjects () {
            return axios
                .get(this.state.address_API + '/getResourcesAllProjects/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourceIdProject (context, payload) {
            return axios
                .get(this.state.address_API + '/getResourceIdProject/' + payload)
                .then(result => {
                    return result.data[0];
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesCompletedProjects () {
            return axios
                .get(this.state.address_API + '/getResourcesCompletedProjects/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        // getResourcesProjectsNotPlaced () {
        //     return axios
        //         .get(this.state.address_API + '/getResourcesProjectsNotPlaced/')
        //         .then(result => {
        //             return result.data;
        //         })
        //         .catch(
        //             error => {
        //                 console.log(error)
        //             }
        //         )
        // },
        getResourcesToBePaid () {
            return axios
                .get(this.state.address_API + '/getResourcesToBePaid/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesToursOngoing () {
            return axios
                .get(this.state.address_API + '/getResourcesToursOngoing/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesToursSet () {
            return axios
                .get(this.state.address_API + '/getResourcesToursSet/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesToursNotPlaced () {
            return axios
                .get(this.state.address_API + '/getResourcesToursNotPlaced/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesToursNotConfirmed () {
            return axios
                .get(this.state.address_API + '/getResourcesToursNotConfirmed/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesCompletedTours () {
            return axios
                .get(this.state.address_API + '/getResourcesCompletedTours/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesAllTours () {
            return axios
                .get(this.state.address_API + '/getResourcesAllTours/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesTourId (context, payload) {
            return axios
                .get(this.state.address_API + '/getResourcesTourId/' + payload)
                .then(result => {
                    console.log(result.data)
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesToursToBePaid () {
            return axios
                .get(this.state.address_API + '/getResourcesToursToBePaid/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesAccommodations () {
            return axios
                .get(this.state.address_API + '/getResourcesAccommodations/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesAllAccommodations () {
            return axios
                .get(this.state.address_API + '/getResourcesAllAccommodations/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesAccommodationsPlaced (context, payload) {
            return axios
                .get(this.state.address_API + '/getResourcesAccommodationsPlaced/', {
                    params: { 
                        start: payload.start,
                        end: payload.end,
                    }
                })
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        // getResourcesAffected (context, payload) {
        //     return axios
        //         .get(this.state.address_API + '/getResourcesAffected/', {
        //             params: {
        //                 accommodation_id: payload.accommodation_id, 
        //                 start: payload.start,
        //                 end: payload.end,
        //             }
        //         })
        //         .then(result => {
        //             return result.data;
        //         })
        //         .catch(
        //             error => {
        //                 console.log(error)
        //             }
        //         )
        // },

        getPaymentResourceAcc (context, payload) {
            return axios
                .get(this.state.address_API + '/getPaymentResourceAcc/', {
                    params: {
                        accommodation_id: payload.accommodation_id, 
                        start: payload.start,
                        end: payload.end,
                        accommodation_class: payload.accommodation_class, 
                    }
                })
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        // getResourcesCompletedAccommodations () {
        //     return axios
        //         .get(this.state.address_API + '/getResourcesCompletedAccommodations/')
        //         .then(result => {
        //             return result.data;
        //         })
        //         .catch(
        //             error => {
        //                 console.log(error)
        //             }
        //         )
        // },
        // getResourcesAccommodationsNotPlaced () {
        //     return axios
        //         .get(this.state.address_API + '/getResourcesAccommodationsNotPlaced/')
        //         .then(result => {
        //             return result.data;
        //         })
        //         .catch(
        //             error => {
        //                 console.log(error)
        //             }
        //         )
        // },
        getResourcesRoomId (context, roomId) {
            return axios
                .get(this.state.address_API + '/getResourcesRoomId/' + roomId)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getResourcesReceiverContactId (context, payload) {
            return axios
            .get(this.state.address_API + '/getResourcesReceiverContactId/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getPaymentsContactId (context, payload) {
            return axios
            .get(this.state.address_API + '/getPaymentsContactId/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        insertResource (context, payload) {
            return axios.post(this.state.address_API + '/insertResource/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        // updateStudentSchedule (context, payload) {
        //     console.log(payload);
        //     return axios
        //         .put(this.state.address_API + '/updateStudentSchedule/', {
        //             body: payload
        //         })
        //         .then(response => {
        //             console.log(response);
        //         })
        //         .catch(
        //             error => {
        //                 console.log(error);
        //     })
        // },
        updateStudentSchedule (context, payload) {
            console.log(payload);
            return axios
                .put(this.state.address_API + '/updateStudentSchedule/' + payload)
                .then(response => {
                    console.log(response);
                })
                .catch(
                    error => {
                        console.log(error);
            })
        },
        deleteResource (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteResource/' + payload)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },
        getResourcesPresets (context, payload) {
            return axios
                .get(this.state.address_API + '/getResourcesPresets/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertResourcePreset (context, payload) {
            return axios.post(this.state.address_API + '/insertResourcePreset/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteResourcePresets (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteResourcePresets/' + payload)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },
        getTypes (context) {
            return axios
                .get(this.state.address_API + '/types/')
                .then(result => {
                    context.commit('initTypes', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getLanguageLevels (context) {
            return axios
                .get(this.state.address_API + '/getLanguageLevels/')
                .then(result => {
                    context.commit('initLanguageLevels', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        // getStatus (context) {
        //     return axios
        //         .get(this.state.address_API + '/status/')
        //         .then(result => {
        //             context.commit('initStatus', result.data);
        //         })
        //         .catch(
        //             error => {
        //                 console.log(error)
        //             }
        //         )
        // },
        getProjectCategories (context) {
            return axios
                .get(this.state.address_API + '/getProjectCategories')
                .then(result => {
                    context.commit('initProjectCategories', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getProjects (context) {
            return axios
                .get(this.state.address_API + '/getProjects')
                .then(result => {
                    context.commit('initProjects', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getDonationsToBePaid () {
            return axios
                .get(this.state.address_API + '/getDonationsToBePaid')
                .then(result => {
                    console.log( result.data)
                    return result.data[0].donationsToBePaid;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getDonationsGiftedIncome () {
            return axios
                .get(this.state.address_API + '/getDonationsGiftedIncome')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getDonationsGiftedOutgoings () {
            return axios
                .get(this.state.address_API + '/getDonationsGiftedOutgoings')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getTours () {
            return axios
                .get(this.state.address_API + '/getTours')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getTourPlacements () {
            return axios
                .get(this.state.address_API + '/getTourPlacements/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertTourPlacement (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertTourPlacement/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteTourPlacement (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteTourPlacement/' + payload)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.log(error);
            });
        },
        
        // client agencies

        getClientAgencies () {
            return axios
                .get(this.state.address_API + '/getClientAgencies')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertClientAgency (context, payload) {
            return axios.post(this.state.address_API + '/insertClientAgency/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteClientAgency (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteClientAgency/' + payload)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
            });
        },

        getAvailableDescriptions (context) {
            return axios
                .get(this.state.address_API + '/available_descriptions/')
                .then(result => {
                    context.commit('initDescriptions', result.data);
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getAvailableAgencies (context) {
            return axios
                .get(this.state.address_API + '/available_agencies/')
                .then(result => {
                    context.commit('initAvailableAgencies', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getAvailableServices (context) {
            return axios
                .get(this.state.address_API + '/available_services/')
                .then(result => {
                    context.commit('initAvailableServices', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getAvailableServicesEdit () {
            return axios
                .get(this.state.address_API + '/available_services_edit/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getAvailablePriceTable (context, serviceNumber) {
            console.log(serviceNumber)
            return axios
                .get(this.state.address_API + '/getAvailablePriceTable/' + serviceNumber)
                .then(result => {
                    console.log(result)
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getAvailablePriceTables () {
            return axios
            .get(this.state.address_API + '/getAvailablePriceTables')
            .then(result => {
                // context.commit('initAvailablePriceTables', result.data);
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },

        insertAvailablePriceTable (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertAvailablePriceTable/', {
                body: payload
            })
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.log(error.response);
            });
        },

        deleteAvailablePriceTable (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteAvailablePriceTable/' + payload)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },

        deleteAllAvailablePriceTables_vps  (context) {
            return axios
            .delete(this.state.address_API + '/deleteAllAvailablePriceTables_vps/')
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
                context.commit('errorMessage', 'something went wrong on the Package Maker, please check (deleteAllAvailablePriceTables_vps)')
            });
        },

        insertAvailablePriceTable_vps (context, payload) {
            return axios.post(this.state.address_API + '/insertAvailablePriceTable_vps/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
                context.commit('errorMessage', 'something went wrong on the Package Maker, please check (insertAvailablePriceTable_vps)')
            });
        },

        // *******************************************************************
        getAvailableOptions (context, payload) {
            return axios.get(this.state.address_API + '/available_options/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        insertAvailableOption (context, payload) {
            return axios.post(this.state.address_API + '/insertAvailableOption/', {
                body: payload
            })
            .then(response => {
                // this.dispatch('getAvailableOptions')
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        // updateAvailableOption (context, payload) {
        //     return axios
        //     .put(this.state.address_API + '/updateAvailableOption/', {
        //         body: payload
        //     })
        //     .then(response => {
        //         // this.dispatch('getAvailableOptions')
        //         console.log(response)
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
        // },
        deleteAvailableOption (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteAvailableOption/' + payload)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.log(error);
            });
        },
        // ***************** Available Options VPS *******************
        deleteAllAvailableOptions_vps (context) {
            return axios
            .delete(this.state.address_API + '/deleteAllAvailableOptions_vps/')
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
                context.commit('errorMessage', 'something went wrong on the Package Maker, please check (deleteAllAvailableOptions_vps)')
            });
        },
        insertAvailableOption_vps (context, payload) {
            return axios.post(this.state.address_API + '/insertAvailableOption_vps/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
                context.commit('errorMessage', 'something went wrong on the Package Maker, please check (insertAvailableOption_vps)')
            });
        },
        
        // *******************************************************************
        getAvailableRadioGroups (context, payload) {
            return axios.get(this.state.address_API + '/available_radioGroups/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertAvailableRadioGroup (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertAvailableRadioGroup/', {
                body: payload
            })
            .then(response => {
                console.log(response);
                return response.data.insertId;
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        updateAvailableRadioGroup (context, payload) {
            return axios
            .put(this.state.address_API + '/updateAvailableRadioGroup/', {
                body: payload
            })
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },
        deleteAvailableRadioGroup (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteAvailableRadioGroup/' + payload)
            .then(response => {
                console.log(response)
                return response;
            })
            .catch(error => {
                console.log(error);
            });
        },
        // ************************* Available Radio Groups ******************************************
        getAvailableRadioOptions_id (context, payload) {
            console.log(payload)
            return axios.get(this.state.address_API + '/available_radioOptions_id/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertAvailableRadioOption (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertAvailableRadioOption/', {
                body: payload
            })
            .then(response => {
                console.log(response);
                return response;
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        updateAvailableRadioOption (context, payload) {
            return axios
            .put(this.state.address_API + '/updateAvailableRadioOption/', {
                body: payload
            })
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },
        deleteAvailableRadioOption (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteAvailableRadioOption/' + payload)
            .then(response => {
                return response;
            })
            .catch(error => {
                console.log(error);
            });
        },
        // ***************** Available Radio Groups VPS *******************
        deleteAllAvailableRadioGroups_vps (context) {
            return axios
            .delete(this.state.address_API + '/deleteAllAvailableRadioGroups_vps/')
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                context.commit('errorMessage', 'something went wrong on the Package Maker, please check (deleteAllAvailableRadioGroups_vps)')
                console.log(error);
            });
        },
        insertAvailableRadioGroup_vps (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertAvailableRadioGroup_vps/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                context.commit('errorMessage', 'something went wrong on the Package Maker, please check (insertAvailableRadioGroup_vps)')
                console.log(error.response);
            });
        },
        insertAvailableRadioOption_vps (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertAvailableRadioOption_vps/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                context.commit('errorMessage', 'something went wrong on the Package Maker, please check (insertAvailableRadioOption_vps)')
                console.log(error.response);
            });
        },
        getAllInvoices () {
            return axios.get(this.state.address_API + '/getAllInvoices/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertInvoice (context, payload) {
            return axios.post(this.state.address_API + '/insertInvoice/', {
                body: payload
            })
            .then(response => {
                return response.status;
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        checkInvoiceExist (context, payload) {
            return axios.get(this.state.address_API + '/checkInvoiceExist/', {
                params: {
                    content: payload
                }
            })
                .then(result => {
                    return result.data[0][`count(id)`];
                })
                .catch(
                    error => {
                        console.log(error)
                })
        },
        removeInvoice (context, payload) {
            return axios
            .delete(this.state.address_API + '/removeInvoice/' + payload)
            .then(response => {
                console.log(response)
                return response;
            })
            .catch(error => {
                console.log(error);
            });
        },

        // **************************** synchronise client with VPS ***************************************
        getLeadPM (context, payload) {
            return axios
                .get(this.state.address_API + '/getleadPM/' + payload)
                .then(result => {
                    console.log(result)
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertLeadPM (context, payload) {
            console.log('insertLeadPM', payload);
            (payload.spanishTestDone) ? payload.spanishTestDone = dayjs(payload.spanishTestDone).format("YYYY-MM-DD HH:mm:ss") : payload.spanishTestDone = null;
            (payload.birthdate) ? payload.birthdate = dayjs(payload.birthdate).format("YYYY-MM-DD HH:mm:ss") : payload.birthdate = null;
            return axios.post(this.state.address_API + '/insertLeadPM/', {
                body: payload
            })
            .then(response => {
                // this.dispatch('getAvailableOptions')
                console.log(response);
                return response.status;
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteLeadPM (context, payload) {
            console.log('deleteLeadPM', payload)
            return axios
            .delete(this.state.address_API + '/deleteLeadPM/' + payload)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },
        async checkWithPM (context) {
            console.log("checkWithPM checking PM")
            context.commit('progressAdvance', 0);
            let resultLeadsPMChanged = [];
            let resultAppPMChanged = [];
            let resultUploadsPMChanged = [];
            // context.commit('showProgressBar', true);
            let progress = 1;
            await Promise.all([resultLeadsPMChanged = await axios.get(this.state.address_API + '/getLeadsPMchanged/'), resultAppPMChanged = await axios.get(this.state.address_API + '/getNewApplicationsPM/'), resultUploadsPMChanged = await axios.get(this.state.address_API + '/getUploadsViewPM/')])
            .then(() => {
                resultAppPMChanged = resultAppPMChanged.data;
                resultUploadsPMChanged = resultUploadsPMChanged.data;
                resultLeadsPMChanged = resultLeadsPMChanged.data;
            })
            .catch(() => {
                error => {
                    console.log(error)
                    context.commit('alertVPS');
                }
            });

            let step = (100 - progress)/(
                resultLeadsPMChanged.length + resultAppPMChanged.length + resultUploadsPMChanged.length
            );
            var listMessagesPM = [];
            var checkIfMoreNewApp = [];
            var checkIfNewLead = [];
            var checkAlreadyMessage = [];
            let result = await axios.get(this.state.address_API + '/getClientEmails/');
            var clientsEmails = result.data;
            console.log('checkWithPM #1', resultLeadsPMChanged);
            for (let i = 0; i < resultLeadsPMChanged.length; i++) {
                progress = progress + step;
                context.commit('progressAdvance', progress);
                resultLeadsPMChanged[i].status = 'changed profile';
                resultLeadsPMChanged[i].goClientId = resultLeadsPMChanged[i].id;
                if (!resultLeadsPMChanged[i].email) {
                    resultLeadsPMChanged[i].status = 'no email';
                } else {
                    let exist = clientsEmails.filter(function(item) {
                        return item.email === resultLeadsPMChanged[i].email && item.id !== resultLeadsPMChanged[i].id;
                    })
                    if (exist.length > 0) {
                        resultLeadsPMChanged[i].status = 'same email ' + exist[0].fullName;
                        resultLeadsPMChanged[i].goClientId = exist[0].id;
                        resultLeadsPMChanged[i].isLead = exist[0].isLead;
                    } else {
                        exist = clientsEmails.filter(function(item) {
                            return item.email === resultLeadsPMChanged[i].email;
                        })
                        if (exist.length === 0) {
                            console.log('checkWithPM #1a', resultLeadsPMChanged[i].first_name, resultLeadsPMChanged[i].last_name);
                            if (resultLeadsPMChanged[i].first_name && resultLeadsPMChanged[i].first_name.length > 0) {
                                resultLeadsPMChanged[i].first_name = resultLeadsPMChanged[i].first_name.trim()
                            }
                            if (resultLeadsPMChanged[i].last_name && resultLeadsPMChanged[i].last_name.length > 0) {
                                resultLeadsPMChanged[i].last_name = resultLeadsPMChanged[i].last_name.trim()
                            }
                            let fullNameLead = (resultLeadsPMChanged[i].first_name + ' ' + resultLeadsPMChanged[i].last_name).trim();
                            let result = await this.dispatch('searchNameClient', fullNameLead);
                            console.log('checkWithPM #2', fullNameLead, result);
                            if (result.length > 0) {
                                console.log('checkWithPM #3', result[0].id, resultLeadsPMChanged[i].id);
                                resultLeadsPMChanged[i].goClientId = result[0].id;
                                resultLeadsPMChanged[i].clientToMerge = result[0].id;
                                resultLeadsPMChanged[i].status = 'name exists: ' + fullNameLead;
                                resultLeadsPMChanged[i].isLead = result[0].isLead;
                            } else {
                                resultLeadsPMChanged[i].status = 'new lead';
                            }
                        }
                    }
                }
                listMessagesPM.push(resultLeadsPMChanged[i]);
            }
            // check new apps!
            let j;
            for (j = 0; j < resultAppPMChanged.length; j++) {
                progress = progress + step;
                context.commit('progressAdvance', progress);
                let stupidthing1 = resultAppPMChanged[j].email;
                console.log('checkWithPM #4a', resultAppPMChanged, stupidthing1);
                let leadNewApp = await axios.get(this.state.address_API + '/getleadPM/' + stupidthing1);
                if (leadNewApp) {
                    leadNewApp = leadNewApp.data;
                    let addApp = JSON.parse(JSON.stringify(leadNewApp));
                    console.log('checkWithPM #4b', leadNewApp.data, leadNewApp.first_name, leadNewApp.last_name);
                    addApp.full_name = leadNewApp.first_name + ' ' + leadNewApp.last_name;
                    checkIfNewLead = listMessagesPM.filter(function(select_item) {
                        return select_item.email === stupidthing1 && select_item.status === 'new lead';
                    });
                    if (checkIfNewLead.length === 0) {
                        checkIfMoreNewApp = listMessagesPM.filter(function(select_item) {
                            return select_item.email === stupidthing1 && (select_item.status === 'new app(s)' || select_item.status.substring(0, 7) === 'name ex');
                        });
                        if (checkIfMoreNewApp.length === 0) {
                            addApp.status = 'new app(s)';
                            addApp.goClientId = leadNewApp.id;
                            listMessagesPM.push(addApp);
                        }
                    }
                }
            }
            // check new uploads
            let addUploadMessage = async (uploadClientIdPM, uploadTypePM, uploadNamePM, uploadIdPM) => {
                let resultClient = await axios.get(this.state.address_API + '/get_client/' + uploadClientIdPM);
                if (resultClient && resultClient.data[0]) {
                    let clientInfo = JSON.parse(JSON.stringify(resultClient.data[0]));
                    clientInfo.status = 'new ' + uploadTypePM + ': ' + uploadNamePM;
                    clientInfo.uploadId = uploadIdPM;
                    clientInfo.goClientId = uploadClientIdPM;
                    listMessagesPM.push(clientInfo);
                }
            };
            let k;
            for (k = 0; k < resultUploadsPMChanged.length; k++) {
                progress = progress + step;
                context.commit('progressAdvance', progress);
                checkAlreadyMessage = [];
                let uploadClientIdPM = resultUploadsPMChanged[k].lead_id;
                if (listMessagesPM.length > 0) {
                    checkAlreadyMessage = listMessagesPM.filter(function(select_item) {
                        return select_item.id === uploadClientIdPM && select_item.status === 'new lead';
                    })
                }
                if (checkAlreadyMessage.length === 0) {
                    await addUploadMessage(uploadClientIdPM, resultUploadsPMChanged[k].type, resultUploadsPMChanged[k].name, resultUploadsPMChanged[k].id);
                }
            }
            listMessagesPM.forEach((item, i) => {
                item.index = i + 1;
            })
            context.commit('messagesPM', listMessagesPM);
            this.dispatch('showProgressBar', false);
            context.commit('progressAdvance', 0);
        },
        async transferAllUploadsToCusco (context, newLead) {
            let result = null;
            let leadId = newLead.id;
            if (!newLead.uploadId) {
                result = await axios.get(this.state.address_API + '/getUploadsViewPM/');
                var uploads = result.data.filter(function(select_item) {
                    return select_item.lead_id === leadId;
                });
            } else {
                uploads = [];
                uploads.push({ "id": newLead.uploadId });
            }
            console.log('transferUploadToCusco #1', uploads);
            for (let i = 0; i < uploads.length; i++) {
                let uploadPM = (await axios.get(this.state.address_API + '/getUploadPM/' + uploads[i].id)).data[0];
                (uploadPM.approved) ? uploadPM.approved = dayjs(uploadPM.approved).format("YYYY-MM-DD") : uploadPM.approved = null;
                uploadPM.updated = 0;
                uploadPM.client_id = uploadPM.lead_id;
                await axios.post(this.state.address_API + '/insertUpload/', uploadPM).then(response => {
                    console.log('transferUploadToCusco #2', response);
                    uploadPM.updated = 0;
                    axios.post(this.state.address_API + '/insertUploadPM/', {
                        body: uploadPM
                    })
                })
                .catch(error => {
                    console.log(error.response);
                });
            }
            await this.dispatch('getUploads', leadId);
        },
        async importFileToCusco (context, payload) {
            console.log('importFileToCusco', payload);
            let uploadPM = (await axios.get(this.state.address_API + '/getUploadPM/' + payload)).data[0];
            uploadPM.updated = 0;
            uploadPM.client_id = uploadPM.lead_id;
            await axios.post(this.state.address_API + '/insertUpload/', uploadPM).then(response => {
                console.log('transferUploadToCusco #2', response);
                uploadPM.updated = 0;
                axios.post(this.state.address_API + '/insertUploadPM/', {
                    body: uploadPM
                })
                context.commit('successMessage', 'File imported successfully')
            })
            .catch(error => {
                context.commit('errorMessage', 'File imported unsuccessfully')
                console.log(error.response);
            });
        },
        async transferUploadPM (context, uploadId) {
            let result = await axios.get(this.state.address_API + '/getUpload_id/' + uploadId);
            (result.data.approved) ? result.data.approved = dayjs(result.data.approved).format("YYYY-MM-DD") : result.data.approved = null;
            // (result.data.updated) ? result.data.updated = dayjs(result.data.updated).format("YYYY-MM-DD HH:mm:ss") : result.data.updated = null;
            result.data.updated = 0;
            return axios.post(this.state.address_API + '/insertUploadPM/', {
                body: result.data
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },

        insertProject (context, payload) {
            return axios.post(this.state.address_API + '/insertProject/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteProject (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteProject/' + payload)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
            });
        },

        insertTour (context, payload) {
            return axios.post(this.state.address_API + '/insertTour/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteTour (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteTour/' + payload)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
            });
        },
        // **************************** synchronise apps with VPS ***************************************

        updateIdLeadPM_vps (context, payload) {
            return axios
            .put(this.state.address_API + '/updateIdLeadPM_vps/', {
                body: payload
            })
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },
        
        insertApplicationPM (context, payload) {
            console.log('insertApplicationPM', payload)
            return axios.post(this.state.address_API + '/insertApplicationPM/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                context.commit('errorMessage', 'something went wrong on the Package Maker, please check (insertApplicationPM)')
                console.log(error.response);
            });
        },

        getApplicationsPM (context, email) {
            return axios.get(this.state.address_API + '/getApplicationsPM/' + email)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },

        getAppsNewLeadPM (context, leadId) {
            return axios.get(this.state.address_API + '/getAppsNewLeadPM/' + leadId)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },

        getAllApplicationsPM () {
            return axios
            .get(this.state.address_API + '/getAllApplicationsPM/')
            .then(result => {
                console.log(result.data)
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        
        deleteApplicationPM (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteApplicationPM/' + payload)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },

        insertServicePM (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertServicePM/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                context.commit('errorMessage', 'something went wrong on the Package Maker, please check (insertServicePM)')
                console.log(error.response);
            });
        },

        getServicesPM (context, clientId) {
            console.log(clientId)
            return axios.get(this.state.address_API + '/getServicesPM/' + clientId)
            .then(result => {
                console.log(result.data);
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },

        getServicesAppIdPM (context, appId) {
            console.log(appId)
            return axios.get(this.state.address_API + '/getServicesAppIdPM/' + appId)
            .then(result => {
                console.log(result.data);
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },

        deleteServicePM (context, payload) {
            console.log(payload)
            return axios
            .delete(this.state.address_API + '/deleteServicePM/' + payload)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        },

        insertRadioGroupPM (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertRadioGroupPM/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                context.commit('errorMessage', 'something went wrong on the Package Maker, please check (insertRadioGroupPM)')
                console.log(error.response);
            });
        },

        getRadioGroupsPM (context, payload) {
            console.log(payload)
            return axios.get(this.state.address_API + '/getRadioGroupsPM/' + payload)
            .then(result => {
                console.log(result.data);
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },

        insertOptionPM (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertOptionPM/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                context.commit('errorMessage', 'something went wrong on the Package Maker, please check (insertOptionPM)')
                console.log(error.response);
            });
        },

        getOptionsPM (context, payload) {
            console.log(payload)
            return axios.get(this.state.address_API + '/getOptionsPM/' + payload)
            .then(result => {
                console.log(result.data);
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },

        getUploadsViewPM () {
            return axios.get(this.state.address_API + '/getUploadsViewPM/')
            .then(result => {
                console.log(result.data);
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },


        // **************************************************************************************************
        getLeads (context) {
            return axios.get(this.state.address_API + '/get_leads/')
            .then(result => {
                context.commit('initLeads', result.data);
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getClients () {
            return axios.get(this.state.address_API + '/getClients/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClientsCompleted () {
            return axios.get(this.state.address_API + '/getClientsCompleted/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getLeadsNoResponse () {
            return axios.get(this.state.address_API + '/getLeadsNoResponse/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClientsIdName () {
            return axios.get(this.state.address_API + '/getClientsIdName/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getClient (context, clientId) {
            console.log(clientId)
            return axios
                .get(this.state.address_API + '/get_client/' + clientId)
                .then(result => {
                    context.commit('initClient', result.data[0]);
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        clientUpdate (context, payload) {
            let client = JSON.parse(JSON.stringify(payload));
            console.log('clientUpdate', client)
            return axios
            .put(this.state.address_API + '/client_update/', {
                body: client
            })
            .then(response => {
                console.log(response);
                // context.commit('successMessage', 'updated successfully');
            })
            .catch(
                error => {
                    console.log(error);
                    if (error.response.status === 501) {
                        context.commit('errorMessage', 'Something went wrong.' + payload.first_name + ' ' + payload.last_name + ' already in use!?');
                    }
                    if (error.response.status === 502) {
                        context.commit('errorMessage', payload.email + ' already in use! Inserting client without email');
                        client.email = null;
                        return axios
                        .put(this.state.address_API + '/client_update/', {
                            body: client
                        })
                        .then(response => {
                            console.log(response);
                            this.dispatch('getClient', client.id);
                        })
                    }
                }
            )
        },
        deleteClient (context) {
            console.log(this.state.client.id)
            return axios
            .delete(this.state.address_API + '/deleteClient/' + this.state.client.id)
            .then(result => {
                console.log(result)
                this.dispatch('getLeads');
                context.commit('successMessage', 'Client successfully removed!')
            })
            .catch(error => {
                console.log(error);
            });
        },
        serviceUpdate (context, payload) {
            payload.uid = this.state.uid;
            return axios
            .put(this.state.address_API + '/service_update/', {
                body: payload
            })
            .then(response => {
                console.log(response);
                this.dispatch('getClient', payload.client_id);
            })
            .catch(
                error => {
                    console.log(error);
                }
            )
        },
        deleteService (context, payload) {
            let filterService = this.state.services.filter(function(select_item) {
                return select_item.id === payload;
              }
            )
            let service = JSON.parse(JSON.stringify(filterService[0]));
            service.uid = this.state.uid;
            return axios
            .put(this.state.address_API + '/service_update/', {
                body: service
            })
            .then(response => {
                console.log(response);
                return axios
                .delete(this.state.address_API + '/deleteService/' + payload)
                .then(response => {
                    console.log(response)
                    this.dispatch('getServices', service.client_id);
                    context.commit('successMessage', 'Service successfully removed!')
                })
                .catch(error => {
                    console.log(error);
                });
            })
            .catch(
                error => {
                    console.log(error);
                }
            )
        },
        getPaymentMethods (context) {
            return axios.get(this.state.address_API + '/getPaymentMethods/')
                .then(result => {
                    context.commit('initPaymentMethods', result.data);
                    return 'ok';
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        changeCurrentClient (context, id) {
            context.commit('changeCurrentClient', id);
        },

        // Categories Income

        getCategoriesIncome () {
            return axios.get(this.state.address_API + '/getCategoriesIncome/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertCategoryIncome (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertCategoryIncome/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteCategoryIncome (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteCategoryIncome/' + payload)
            .then(response => {
                context.commit('successMessage', 'Category deleted');
                return response
            })
            .catch(error => {
                context.commit('errorMessage', 'Something went wrong');
                console.log(error);
            });
        },

        // Categories Outgoings

        getCategoriesOutgoings () {
            return axios.get(this.state.address_API + '/getCategoriesOutgoings/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertCategoryOutgoings (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertCategoryOutgoings/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteCategoryOutgoings (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteCategoryOutgoings/' + payload)
            .then(response => {
                context.commit('successMessage', 'Category deleted');
                return response
            })
            .catch(error => {
                context.commit('errorMessage', 'Something went wrong');
                console.log(error);
            });
        },

        // Types Income

        getTypesIncome () {
            return axios.get(this.state.address_API + '/getTypesIncome/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertTypeIncome (context, payload) {
            return axios.post(this.state.address_API + '/insertTypeIncome/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteTypeIncome (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteTypeIncome/' + payload)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
            });
        },

        // Types Outgoings

        getTypesOutgoings () {
            return axios.get(this.state.address_API + '/getTypesOutgoings/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertTypeOutgoings (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertTypeOutgoings/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteTypeOutgoings (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteTypeOutgoings/' + payload)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
            });
        },

        // Income

        // getIncome (context) {
        //     return axios.get(this.state.address_API + '/getIncome/')
        //         .then(result => {
        //             context.commit('initIncome', result.data);
        //         })
        //         .catch(
        //             error => {
        //                 console.log(error)
        //             }
        //         )
        // },

        getIncomeDay (context, payload) {
            return axios.get(this.state.address_API + '/getIncomeDay/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getIncomeMonth (context, payload) {
            return axios.get(this.state.address_API + '/getIncomeMonth/', {
                params: { 
                    month: payload.month,
                    year: payload.year,
                }
            })
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getIncomeYear (context, payload) {
            return axios.get(this.state.address_API + '/getIncomeYear/', {
                params: { 
                    year: payload.year,
                }
            })
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertIncome (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertIncome/', {
                body: payload
            })
            .then(response => {
                return response.data.insertId;
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteIncome (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteIncome/' + payload)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
            });
        },

        // Outgoing

        getOutgoingsDay (context, payload) {
            return axios.get(this.state.address_API + '/getOutgoingsDay/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getOutgoingsMonth (context, payload) {
            return axios.get(this.state.address_API + '/getOutgoingsMonth/', {
                params: { 
                    month: payload.month,
                    year: payload.year,
                }
            })
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getOutgoingsYear (context, payload) {
            return axios.get(this.state.address_API + '/getOutgoingsYear/', {
                params: { 
                    month: payload.month,
                    year: payload.year,
                }
            })
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getOutgoingsApp (context, payload) {
            return axios.get(this.state.address_API + '/getOutgoingsApp/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        insertOutgoing (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertOutgoing/', {
                body: payload
            })
            .then(response => {
                return response.data.insertId;
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteOutgoing (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteOutgoing/' + payload)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
            });
        },

        // cajas

        getTotalBalancesDay (context, payload) {
            return axios.get(this.state.address_API + '/getTotalBalancesDay/' + payload)
                .then(result => {
                    console.log(result.data);
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getTotalBalancesMonth (context, payload) {
            return axios.get(this.state.address_API + '/getTotalBalancesMonth/' + payload)
                .then(result => {
                    console.log(result.data);
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getTotalDollarsBank (context, payload) {
            return axios.get(this.state.address_API + '/getTotalDollarsBank/' + payload)
                .then(result => {
                    console.log(result.data);
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getTotalSolesBank (context, payload) {
            return axios.get(this.state.address_API + '/getTotalSolesBank/' + payload)
                .then(result => {
                    console.log(result.data);
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getSolesBankTable (context, payload) {
            return axios.get(this.state.address_API + '/getSolesBankTable/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getDollarsBankTable (context, payload) {
            return axios.get(this.state.address_API + '/getDollarsBankTable/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getTotalDollarsWise (context, payload) {
            return axios.get(this.state.address_API + '/getTotalDollarsWise/' + payload)
                .then(result => {
                    console.log(result.data);
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getDollarsWiseTable (context, payload) {
            return axios.get(this.state.address_API + '/getDollarsWiseTable/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getTotalEurosWise (context, payload) {
            return axios.get(this.state.address_API + '/getTotalEurosWise/' + payload)
                .then(result => {
                    console.log(result.data);
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getEurosWiseTable (context, payload) {
            return axios.get(this.state.address_API + '/getEurosWiseTable/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getTotalGBPWise (context, payload) {
            return axios.get(this.state.address_API + '/getTotalGBPWise/' + payload)
                .then(result => {
                    console.log(result.data);
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getGBPWiseTable (context, payload) {
            return axios.get(this.state.address_API + '/getGBPWiseTable/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getTotalDollarsCajaChica (context, payload) {
            return axios.get(this.state.address_API + '/getTotalDollarsCajaChica/' + payload)
                .then(result => {
                    console.log(result.data);
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getTotalSolesCajaChica (context, payload) {
            return axios.get(this.state.address_API + '/getTotalSolesCajaChica/' + payload)
                .then(result => {
                    console.log(result.data);
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getTotalDollarsCajaFuerte (context, payload) {
            return axios.get(this.state.address_API + '/getTotalDollarsCajaFuerte/' + payload)
                .then(result => {
                    console.log(result.data);
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getTotalSolesCajaFuerte (context, payload) {
            return axios.get(this.state.address_API + '/getTotalSolesCajaFuerte/' + payload)
                .then(result => {
                    console.log(result.data);
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        

        // getDayTotalSoles (context, payload) {
        //     return axios.get(this.state.address_API + '/getDayTotalSoles/' + payload)
        //         .then(result => {
        //             return result.data;
        //         })
        //         .catch(
        //             error => {
        //                 console.log(error)
        //             }
        //         )
        // },
        // getDayTotalDollars (context, payload) {
        //     return axios.get(this.state.address_API + '/getDayTotalDollars/' + payload)
        //         .then(result => {
        //             return result.data;
        //         })
        //         .catch(
        //             error => {
        //                 console.log(error)
        //             }
        //         )
        // },

        // Squares

        getSquaresMonth (context, payload) {
            return axios.get(this.state.address_API + '/getSquaresMonth/', {
                params: { 
                    month: payload.month,
                    year: payload.year,
                }
            })
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        getLastSquare () {
            return axios.get(this.state.address_API + '/getLastSquare/')
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },

        insertSquare (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertSquare/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },

        deleteSquare (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteSquare/' + payload)
            .then(response => {
                return response
            })
            .catch(error => {
                console.log(error);
            });
        },

        // Services

        getServices (context, payload) {
            return axios
            .get(this.state.address_API + '/get_services/' + payload.clientId)
            .then(result => {
                context.commit('initServices', result.data);
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getServicesAppId (context, payload) {
            return axios
            .get(this.state.address_API + '/getServicesAppId/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getRadioGroups (context, payload) {
            return axios
            .get(this.state.address_API + '/getRadioGroups_id/' + payload)
            .then(result => {
                console.log(result.data)
                if (result.data.length > 0) {
                    console.log('radioGroups found', result.data);
                } else {
                    console.log('No radioGroups found', result.data);
                }
                context.commit('initRadioGroups', result.data);
                return result;
            })
            .catch(
                error => {
                    console.log('Radiogroup error', error)
                }
            )
        },
        getAllRadioGroups () {
            return axios
            .get(this.state.address_API + '/getAllRadioGroups/')
            .then(result => {
                console.log(result.data);
                return result.data;
            })
            .catch(
                error => {
                    console.log('Radiogroup error', error)
                }
            )
        },
        getAllRadioGroupsPM () {
            return axios
            .get(this.state.address_API + '/getAllRadioGroupsPM/')
            .then(result => {
                console.log(result.data);
                return result.data;
            })
            .catch(
                error => {
                    console.log('Radiogroup error', error)
                }
            )
        },
        getRadioGroupsAppId (context, payload) {
            return axios
            .get(this.state.address_API + '/getRadioGroups_appId/' + payload)
            .then(result => {
                console.log(result.data)
                if (result.data.length > 0) {
                    return result.data;
                } else {
                    console.log('No radioGroups found', result.data);
                }
                return result;
            })
            .catch(
                error => {
                    console.log('Radiogroup error', error)
                }
            )
        },
        getOptions (context, payload) {
            return axios
            .get(this.state.address_API + '/getOptions_id/' + payload)
            .then(result => {
                if (result.data.length > 0) {
                    console.log('Options found', result.data);
                } else {
                    console.log('No Options found', result.data);
                }
                context.commit('initOptions', result.data);
            })
            .catch(
                error => {
                    console.log('Options error', error)
                }
            )
        },
        getOptionsAppId (context, payload) {
            return axios
            .get(this.state.address_API + '/getOptions_appId/' + payload)
            .then(result => {
                if (result.data.length > 0) {
                    return result.data;
                } else {
                    console.log('No Options found', result.data);
                }
            })
            .catch(
                error => {
                    console.log('Options error', error)
                }
            )
        },
        getApplications (context, payload) {
            console.log(payload)
            return axios
            .get(this.state.address_API + '/get_applications/' + payload)
            .then(result => {
                console.log(result.data)
                context.commit('initApplications', result.data);
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getApplicationId (context, payload) {
            console.log(payload)
            return axios
            .get(this.state.address_API + '/getApplicationId/' + payload)
            .then(result => {
                console.log(result.data)
                return result.data[0];
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getApplicationsNotStarted () {
            return axios
            .get(this.state.address_API + '/getApplicationsNotStarted/')
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getAllApplications () {
            return axios
            .get(this.state.address_API + '/getAllApplications/')
            .then(result => {
                console.log(result.data)
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getApplicationsNotPaidDirectServices () {
            return axios
            .get(this.state.address_API + '/getApplicationsNotPaidDirectServices/')
            .then(result => {
                console.log(result.data)
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getApplicationsOngoingDirectServices () {
            return axios
            .get(this.state.address_API + '/getApplicationsOngoingDirectServices/')
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getApplicationsCompletedDirectServices () {
            return axios
            .get(this.state.address_API + '/getApplicationsCompletedDirectServices/')
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getApplicationsNotPaidAgencies (context, payload) {
            return axios
            .get(this.state.address_API + '/getApplicationsNotPaidAgencies/' + payload)
            .then(result => {
                console.log(result.data)
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getApplicationsOngoingAgencies (context, payload) {
            return axios
            .get(this.state.address_API + '/getApplicationsOngoingAgencies/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getApplicationsCompletedAgencies (context, payload) {
            return axios
            .get(this.state.address_API + '/getApplicationsCompletedAgencies/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getApplicationsStatusId (context, payload) {
            console.log(payload)
            return axios
            .get(this.state.address_API + '/getApplicationsStatusId/' + payload)
            .then(result => {
                console.log(result.data)
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getApplicationsPayerAgencyId (context, payload) {
            console.log(payload)
            return axios
            .get(this.state.address_API + '/getApplicationsPayerAgencyId/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getApplicationsPayerClientId (context, payload) {
            console.log(payload)
            return axios
            .get(this.state.address_API + '/getApplicationsPayerClientId/' + payload)
            .then(result => {
                console.log(result.data)
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        insertApplication (context, payload) {
            payload.uid = this.state.uid;
            if (!payload.discount) {
                payload.discount = 0;
            }
            if (!payload.ignore_deposit) {
                payload.ignore_deposit = 0;
            }
            if (!payload.transferFeeIncluded) {
                payload.transferFeeIncluded = 0;
            }
            if (!payload.syncPM) {
                payload.syncPM = 0;
            }
            console.log(payload)
            return axios.post(this.state.address_API + '/insertApplication/', {
                body: payload
            })
            .then(response => {
                console.log(response);
                return response;
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        deleteApplication (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteApplication/' + payload)
            .then(response => {
                // context.commit('successMessage', 'Application successfully removed!')
                console.log(response);
            })
            .catch(
                error => {
                    console.log(error);
                }
            )
        },

        // Income transactions

        getOpenTransactionsIncome () {
            return axios
            .get(this.state.address_API + '/getOpenTransactionsIncome/')
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getAllTransactionsIncome () {
            return axios
            .get(this.state.address_API + '/getAllTransactionsIncome/')
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getTransactionIncomeId (context, payload) {
            return axios
            .get(this.state.address_API + '/getTransactionIncomeId/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getDistributionsIncomeId (context, payload) {
            return axios
            .get(this.state.address_API + '/getDistributionsIncomeId/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getDistributionsApplicationId (context, payload) {
            return axios
            .get(this.state.address_API + '/getDistributionsApplicationId/' + payload)
            .then(result => {
                console.log(result.data)
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getDistributionsIncomeTransactionId (context, payload) {
            return axios
            .get(this.state.address_API + '/getDistributionsIncomeTransactionId/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        insertDistributionIncome (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertDistributionIncome/', {
                body: payload
            })
            .then(response => {
                console.log(response);
                return response;
            })
            .catch(error => {
                console.log(error.response);
            });
        },

        deleteDistributionIncomeId (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteDistributionIncomeId/' + payload)
            .then(response => {
                console.log(response);
            })
            .catch(
                error => {
                    console.log(error);
                }
            )
        },

        // Outgoings transactions

        getOpenTransactionsOutgoings () {
            return axios
            .get(this.state.address_API + '/getOpenTransactionsOutgoings/')
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getAllTransactionsOutgoings () {
            return axios
            .get(this.state.address_API + '/getAllTransactionsOutgoings/')
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getTransactionOutgoingsId (context, payload) {
            return axios
            .get(this.state.address_API + '/getTransactionOutgoingsId/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getDistributionsOutgoingId (context, payload) {
            return axios
            .get(this.state.address_API + '/getDistributionsOutgoingId/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        // getDistributionsResourceId (context, payload) {
        //     return axios
        //     .get(this.state.address_API + '/getDistributionsResourceId/' + payload)
        //     .then(result => {
        //         console.log(result.data)
        //         return result.data;
        //     })
        //     .catch(
        //         error => {
        //             console.log(error)
        //         }
        //     )
        // },
        getDistributionsOutgoingsTransactionId (context, payload) {
            return axios
            .get(this.state.address_API + '/getDistributionsOutgoingsTransactionId/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getDistributionsOutgoingsApplicationId (context, payload) {
            return axios
            .get(this.state.address_API + '/getDistributionsOutgoingsApplicationId/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        insertDistributionOutgoings (context, payload) {
            console.log(payload)
            return axios.post(this.state.address_API + '/insertDistributionOutgoings/', {
                body: payload
            })
            .then(response => {
                console.log(response);
                return response;
            })
            .catch(error => {
                console.log(error.response);
            });
        },

        deleteDistributionOutgoingId (context, payload) {
            return axios
            .delete(this.state.address_API + '/deleteDistributionOutgoingId/' + payload)
            .then(response => {
                console.log(response);
            })
            .catch(
                error => {
                    console.log(error);
                }
            )
        },

        // services
        addService (context, payload) {
            payload.uid = this.state.uid;
            console.log(payload)
            return axios.post(this.state.address_API + '/service_add/', {
                body: payload
            })
            .then(response => {
                // context.commit('checkCurrentStatusService', ServiceId);
                return response.data.insertId;
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        async addOption (context, payload) {
            await axios.post(this.state.address_API + '/options_new/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(
                error => {
                    console.log(error);
                }
            )
        },
        async addRadioGroup (context, payload) {
            await axios.post(this.state.address_API + '/radioGroups_new/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(
                error => {
                    console.log(error);
                }
            )
        },
        async addRadioOption (context, payload) {
            await axios.post(this.state.address_API + '/radioOption_new/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(
                error => {
                    console.log(error);
                }
            )
        },
        async addRadioOptionPM (context, payload) {
            await axios.post(this.state.address_API + '/radioOption_newPM/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(
                error => {
                    console.log(error);
                }
            )
        },
        getRadioOptionsClient (context, payload) {
            return axios.get(this.state.address_API + '/getRadioOptionsClient/' + payload)
                .then(result => {
                    context.commit('initRadioOptions', result.data);
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        getRadioOptionsPM (context, payload) {
            console.log(payload)
            return axios.get(this.state.address_API + '/getRadioOptionsPM/' + payload)
                .then(result => {
                    return result.data;
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        setOptions (context, payload) {
            console.log(payload)
            return axios
            .put(this.state.address_API + '/option_update/', {
                body: payload
            })
        },
        setRadios (context, payload) {
            console.log(payload)
            return axios
            .put(this.state.address_API + '/radios_update/', {
                body: payload
            })
            .then(response => {
                console.log(response);
                return response;
            })
            .catch(
                error => {
                    console.log(error);
                }
            )
        },
        clearError (context) {
            context.commit('clearError')
        },
        setFileName (context, payload) {
            context.commit('setFileName', payload);
        },
        getUploads (context, payload) {
            console.log(payload)
            return axios
                .get(this.state.address_API + '/getUploads/' + payload)
                .then(result => {
                    context.commit('uploadInfos', result.data);
                    return result.data;
                    // let i;
                    // for (i = 0; i < this.state.services.length; i++) {
                    //     context.commit('checkCurrentStatusService', this.state.services[i].id);
                    // }
                })
                .catch(
                    error => {
                        console.log(error)
                    }
                )
        },
        deleteUploadPM (context, payload) {
            return axios
                .delete(this.state.address_API + '/deleteUploadPM/' + payload)
                .then(result => {
                    console.log(result)
                    context.commit('successMessage', 'Upload successfully removed from PM!')
                })
                .catch(error => {
                    console.log(error);
                });
        },
        searchClient (context, payload) {
            return axios
            .get(this.state.address_API + '/searchClient/' + payload)
            .then(result => {
                console.log(result.data)
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        searchNameClient (context, payload) {
            return axios
            .get(this.state.address_API + '/searchNameClient/' + payload)
            .then(result => {
                console.log(result.data)
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        async addUpload (context, payload) {
            // console.log('addUpload', payload);
            payload.name = this.state.fileName;
            payload.approved = null;
            context.commit('progressAdvance', 0);
            var config = {
                onUploadProgress: function(progressEvent) {
                    var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                    context.commit('progressAdvance', percentCompleted);
                }
            };
            axios.post(this.state.address_API + '/insertUpload/', payload, config)
            .then(response => {
                console.log('addUpload #2', response);
                this.dispatch('getUploads', this.state.client.id);
                let newId = JSON.parse(response.config.data).id;
                this.dispatch('transferUploadPM', newId);
                context.commit('successMessage', 'Document successfully uploaded!');
                context.commit('progressAdvance', 0);
            })
            .catch(error => {
                console.log(error);
            });
        },
        downloadFile (context, payload) {
            console.log(payload)
            return axios
                .get(this.state.address_API + '/getUpload_id/' + payload)
                .then(result => {
                    if (result.data) {
                        console.log(result.data.name)
                        const zip = result.data.file
                        const originalObj = JSON.parse(zlib.unzipSync(Buffer.from(zip, 'base64')));
                        download(originalObj, result.data.name)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        createAttachments (context, payload) {
            console.log(payload)
            return axios
                .get(this.state.address_API + '/getUpload_id/' + payload)
                .then(result => {
                    if (result.data) {
                        console.log(result.data.name)
                        const zip = result.data.file;
                        const originalObj = JSON.parse(zlib.unzipSync(Buffer.from(zip, 'base64')));
                        return originalObj;
                        // download(originalObj, result.data.name)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        deleteFile (context, uploadId) {
            return axios
                .delete(this.state.address_API + '/deleteUpload/' + uploadId)
                .then(result => {
                    console.log(result)
                    this.dispatch('getUploads', this.state.client.id)
                    context.commit('successMessage', 'Document successfully removed!')
                })
                .catch(error => {
                    console.log(error);
                });
        },
        successMessage (context, payload) {
            context.commit('successMessage', payload)
        },
        errorMessage (context, payload) {
            context.commit('errorMessage', payload)
        },
        warningMessage (context, payload) {
            context.commit('warningMessage', payload)
        },
        approveUpload (context, payload) {
            return axios
            .put(this.state.address_API + '/uploads_update/', {
                body: payload
            })
            .then(response => {
                console.log(response);
                this.dispatch('getUploads', this.state.client.id)
                context.commit('successMessage', 'Document status successfully changed!')
            })
            .catch(
                error => {
                    console.log(error);
            })
        },
        activeTabTeaching (context, payload) {
            context.commit('activeTabTeaching', payload)
        },
        activeTabTeachers (context, payload) {
            context.commit('activeTabTeachers', payload)
        },
        emailMessage (context, payload) {
            context.commit('emailMessage', payload)
        },
        changeActiveViewClassRooms (context, payload) {
            context.commit('changeActiveViewClassRooms', payload)
        },
        saveDataForReport (context, payload) {
            context.commit('saveDataForReport', payload)
        },
        // changeClassRooms (context, payload) {
        //     context.commit('changeClassRooms', payload)
        // },
        // changeSelectAllRooms (context, payload) {
        //     context.commit('changeSelectAllRooms', payload)
        // },
        changescopeLanguage (context, payload) {
            context.commit('changescopeLanguage', payload)
        },
        changeScopeOnline (context, payload) {
            context.commit('changeScopeOnline', payload)
        },
        changeScopeResources (context, payload) {
            context.commit('changeScopeResources', payload)
        },
        changeScopeClasses (context, payload) {
            context.commit('changeScopeClasses', payload)
        },
        // changeScopeType (context, payload) {
        //     context.commit('changeScopeType', payload)
        // },
        changeScopeCalendar (context, payload) {
            context.commit('changeScopeCalendar', payload)
        },
        changeScopeServices (context, payload) {
            context.commit('changeScopeServices', payload)
        },
        changeTeacherVisibility (context, payload) {
            context.commit('changeTeacherVisibility', payload)
        },
        changeSelectAllTeachers (context, payload) {
            context.commit('changeSelectAllTeachers', payload)
        },
        breadcrumbs (context, payload) {
            context.commit('breadcrumbs', payload)
        },
        email (context, payload) {
            return axios
                .post(this.state.address_API + '/email', {
                    body: payload
                })
                .then(result => {
                    if (result.data) {
                        if (result.data === "OK") {
                            context.commit('successMessage', 'Email succesfully sent!');
                            return result.data;
                        } else {
                            context.commit('errorMessage', 'Something went wrong with sending the email!');
                            return result.data;
                        }
                    }
                })
                .catch(error => {
                    if (error.message == 'Request failed with status code 413') {
                        context.commit('errorMessage', 'Attachments are too large!');
                    } else {
                        context.commit('errorMessage', 'Something went wrong with sending the email!');
                    }
                    console.log(error);
                });
        },
        showInvoice (context, payload) {
            context.commit('showInvoice', payload)
        },
        resourcePaymentFormSent (context, payload) {
            context.commit('resourcePaymentFormSent', payload)
        },
        monthCalcPaymentsAccommodation (context, payload) {
            context.commit('monthCalcPaymentsAccommodation', payload)
        },
        changeDirectOrAgencies (context, payload) {
            context.commit('changeDirectOrAgencies', payload)
        },
        changeScopeApplications (context, payload) {
            context.commit('changeScopeApplications', payload)
        },
        changeSelectedAgency (context, payload) {
            context.commit('changeSelectedAgency', payload)
        },
        // getExchangeRates (context, payload) {
        //     var requestURL = 'https://api.exchangerate.host/latest?base=USD';
        //         var request = new XMLHttpRequest();
        //         request.open('GET', requestURL);
        //         request.responseType = 'json';
        //         request.send();

        //         request.onload = function() {
        //             payload = request.response;
        //             console.log('exchangeRates:', payload);
        //             context.commit('getExchangeRates', payload)
        //         }
        // },
        // getExchangeRates (context) {
        //     var myHeaders = new Headers();
        //     myHeaders.append("apikey", "Kt06NwzuGyv2IV8ucoYWPKVURQbXNREi");

        //     var requestOptions = {
        //     method: 'GET',
        //     redirect: 'follow',
        //     headers: myHeaders
        //     };

        //     fetch("https://api.apilayer.com/fixer/latest?base=USD&symbols=EUR,GBP", requestOptions)
        //     .then(response => response.text())
        //     .then(result => context.commit('getExchangeRates', result))
        //     .catch(error => console.log('error', error));
        // },
        getExchangeRates (context, payload) {
            var oReq = new XMLHttpRequest();
            oReq.addEventListener("load", function () { console.log(this.responseText); });
            oReq.open("GET", "https://api.freecurrencyapi.com/v1/latest?apikey=BpEra7Xh1iE0B9FflVgZvHvGsLnPWIOA2X7iylOV");
            oReq.send();

            oReq.onload = function() {
                payload = oReq.response;
                // console.log('exchangeRates:', payload);
                context.commit('getExchangeRates', payload)
            }
        },
        saveFinancialLocked (context, payload) {
            return axios.put(this.state.address_API + '/saveFinancialLocked/', {
                body: payload
            })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        getSecurityDeposits (context, payload) {
            return axios.get(this.state.address_API + '/getSecurityDeposits/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getSecurityDepositsTotals () {
            return axios.get(this.state.address_API + '/getSecurityDepositsTotals/')
            .then(result => {
                return result.data;
            })
            .catch(
                error => {
                    console.log(error)
                }
            )
        },
        getScheduleTeacher (context, payload) {
            return axios.get(this.state.address_API + '/getScheduleTeacher/' + payload)
            .then(result => {
                return result.data;
            })
            .catch(error => {
                console.log(error.response);
            });
        },
        // getScheduleStudent (context, payload) {
        //     return axios.get(this.state.address_API + '/getScheduleStudent/' + payload)
        //     .then(result => {
        //         return result.data;
        //     })
        //     .catch(error => {
        //         console.log(error.response);
        //     });
        // },
        reloadResourcesTourAgencies (context, payload) {
            context.commit('reloadResourcesTourAgencies', payload)
        },
    }
})