export default {
    methods: {
        async createAppTitle (application, servicesInApp) {
        let appTitle = '';
        let clientsInServices = [];
        // let servicesInApp = await this.$store.dispatch('getServicesAppId', application.id);
        for (let i = 0; i < servicesInApp.length; i++) {
            if (clientsInServices.map(function(e) { return e.client_id; }).indexOf(servicesInApp[i].client_id) === -1) {
                clientsInServices.push({ client_id: servicesInApp[i].client_id });
                appTitle = appTitle.concat(servicesInApp[i].full_name);
                appTitle = appTitle.concat(", ");
            }
        }
        appTitle = appTitle.trim();
        if (appTitle.slice(-1) === ',') {
            appTitle = appTitle.substring(0, appTitle.length - 1);
        }
        if (application.amountOfClients > 1) {
            appTitle = 'Group Application: '.concat(appTitle);
        } else {
            appTitle = appTitle.concat(' - ');
            for (let i = 0; i < servicesInApp.length; i++) {
                appTitle = appTitle.concat(servicesInApp[i].name);
                appTitle = appTitle.concat(", ");
            }
        }
        appTitle = appTitle.trim();
        if (appTitle.slice(-1) === ',') {
            appTitle = appTitle.substring(0, appTitle.length - 1);
        }
        if (appTitle.length > 100) {
            appTitle = appTitle.substring(0, 96);
            appTitle = appTitle.concat("...");
        }
        return appTitle;
        },
    }
}