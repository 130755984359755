import dayjs from 'dayjs';
export default {
    methods: {
        formattedDate (date, format) {
            if (format === "Noday") {
                return date ? dayjs(date).format('DD MMM YY') : ''
            } else if (format === "dateTime") {
                return date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : ''
            } else if (format === "dateTimeShort") {
                return date ? dayjs(date).format('YYYY-MM-DD HH:mm') : ''
            } else if (format === "dateAndTime") {
                return date ? dayjs(date).format('DD MMM HH:mm') : ''
            } else if (format === "dayAndDate") {
                return date ? dayjs(date).format('ddd DD MMM') : ''
            } else if (format === "time") {
                return date ? dayjs(date).format('HH:mm') : ''
            } else if (format === "year") {
                return date ? dayjs(date).format('YYYY') : ''
            } else if (format === "month") {
                return date ? dayjs(date).format('MM') : ''
            } else if (format === "date") {
                return date ? dayjs(date).format('YYYY-MM-DD') : ''
            } else if (format === "dateYearShort") {
                return date ? dayjs(date).format('YY-MM-DD') : ''
            } else if (format === "day-month-year") {
                return date ? dayjs(date).format('DD-MM-YY') : ''
            } else if (format === "monthAndDay") {
                return date ? dayjs(date).format('MM-DD') : ''
            } else if (format === "yearAndMonth") {
                return date ? dayjs(date).format('YYYY-MM') : ''
            } else {
                return date ? dayjs(date).format('ddd DD MMM YY') : ''
            }
        },
    }
    
}