<template>
    <div class="d-flex justify-space-around" v-if="$store.getters.loadingApp !== 1">
        <v-card 
            flat
            class="pa-12"
            width="100%"
            max-width="210mm"
            min-height="297mm"
        >
            <span v-html="messageHTML" class="ma-2"></span>
        </v-card>
    </div>
</template>

<script>

import getDateFromTimestamp from '../mixins/getDateFromTimestamp';
import formattedDate from '../mixins/formattedDate';
import compare from '../mixins/compare';

export default {
    data () {
        return {
            teacher: null,
            classesPaid: [],
            messagePlain: "",
            messageHTML: "",
            headersClasses: [
                { text: 'Fecha & hora', value: 'start' },
                { text: 'Horas', value: 'duration' },
                { text: 'Nivel', value: 'level' },
                { text: 'Estudiantes', value: 'amount_of_students' },
                { text: 'Commentariós', value: 'comments' },
                { text: 'Pago', value: 'payment' },
            ],
            // courseIds: [],
            totalPayment: 0,
            totalMinutes: 0,
        }
    },
    beforeCreate () {
        this.$store.dispatch('setLoadingApp', 1)
    },
    async created() {
        await this.createTeacherSchedule();
        this.$vuetify.goTo(0);
        this.$store.dispatch('setLoadingApp', 0)
    },
    mixins: [getDateFromTimestamp, formattedDate, compare],
    
    methods: {
        createTeacherSchedule() {
            this.classesPaid = this.$store.state.dataForReport.classes;
            this.teacher = this.$store.state.dataForReport.teacher;
            this.totalPayment = this.$store.state.dataForReport.totalPayment;
            this.discountPayment = this.$store.state.dataForReport.discountPayment;
            this.correction = this.$store.state.dataForReport.correction;
            this.comments_correction = this.$store.state.dataForReport.comments_correction;
            this.$store.dispatch('saveDataForReport', );
            for (let index = 0; index < this.classesPaid.length; index++) {
                this.totalMinutes = this.totalMinutes + this.classesPaid[index].duration;
                this.classesPaid[index].description = this.classesPaid[index].class_type + '-' + this.classesPaid[index].class_group + ': ' + ' (' + this.classesPaid[index].amount_students + ') ' + this.classesPaid[index].students;
                this.classesPaid[index].description = this.classesPaid[index].description.substring(0, 80);
            }
            this.classesPaid.sort(this.dynamicSort('start'));
            let messageHeader = "<div style='margin-top: 0cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif;'>" +
            "<div style='display: flex; flex-direction: row; justify-content: space-between; margin: 0 0 2cm 0;'>" +
                "<div style='font-family: Roboto, sans-serif; font-size: 32px; color: #35b729;'><span>PROYECTO</span><strong>PERU</strong></div>" +
                "<div style='font-size: 32px; color: grey; display: flex; flex-direction: column; align-items: flex-end;'>" + 
                    "<div>PAGO PROFE</div>" + 
                    "<div style='font-size: 24px;'>" + this.teacher + "</div>" +
                    "<div style='font-size: 18px;'>" + 
                        this.formattedDate(Date.now()) + 
                    "</div>" + 
                "</div>" + 
            "</div>" + 
            "</div>"
            let messageBody = "<div><table style='width: 100%;'><tr><th style='text-align: left; width: 140px;'>Fecha/hora (min)</th><th style='text-align: left;'>Descripcion</th><th style='text-align: left; width: 120px;'>Tarifa (hr)</th><th style='text-align: left;'>Pago</th></tr>";
            // let courseId = null;
            for (let index = 0; index < this.classesPaid.length; index++) {
                messageBody += "<tr style='font-size: 12px'><td>" + this.formattedDate(this.classesPaid[index].start, 'dateAndTime') + " (" + this.classesPaid[index].duration + ")</td><td>" + this.classesPaid[index].description + "</td><td>" + (this.classesPaid[index].paymentClass/(this.classesPaid[index].duration/60)).toFixed(2) + "</td><td>" + this.classesPaid[index].paymentClass.toFixed(2) + "</td></tr>"; 
            }
            messageBody += "</table></div>";
            let messageFooter = "<div style='margin-top: 0.5cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif; background-color: #c7d3d1; height: 2px;'></div>" + 
            "<div style='width: 100%; display: flex; flex-direction: column; '>" +
            "<div style='width: 100%; display: flex; flex-direction: row; justify-content: flex-end'>" +
                "<div><div style='text-align: right'>Subtotal: &nbsp; &nbsp; &nbsp;" + this.totalPayment.toFixed(2) + "</div></br> " + 
                "<div style='text-align: right'>AFP " + this.discountPayment + "%: " + "&nbsp; &nbsp; &nbsp;-" + (this.totalPayment * this.discountPayment/100).toFixed(2) + "</div></br>" +
                "<div style='text-align: right'>Corrección " + this.comments_correction + ": &nbsp; &nbsp; &nbsp;" + this.correction.toFixed(2) + "</div></div></br></br>" +
            "</div>" +
            "<div style='margin-top: 0.5cm; display: flex; flex-direction: column; font-family: Raleway, Arial, Helvetica, sans-serif; background-color: #c7d3d1; height: 2px;'></div>" +
            "<div style='width: 100%; display: flex; flex-direction: row; justify-content: flex-end'>" +
            "<h3>Total: S/." +(this.totalPayment - (this.totalPayment * this.discountPayment/100) + this.correction).toFixed(2) + "<br>" + this.totalMinutes/60 + " horas</h3></div>" +
            "</div>"
            this.messageHTML = messageHeader + messageBody + messageFooter;
        },
    },
}
</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
    /* @media all {
        .page-break	{ display: none; }
    }
    @media print {
        .page-break	{ display: block; page-break-before: always; }
    } */
</style>