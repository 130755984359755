import Vue from 'vue'
import VueRouter from 'vue-router'
import Leads from '../views/Leads.vue'
import PackageMaker from '../views/PackageMaker.vue'
import Client from '../views/Client.vue'
import Clients from '../views/Clients.vue'
import Application from '../views/Application.vue'
import Applications from '../views/Applications.vue'
import CartaDePresentacion from '../views/CartaDePresentacion.vue'
import FichaDeAlojamiento from '../views/FichaDeAlojamiento.vue'
import Invoices from '../views/Invoices.vue'
import ManageImages from '../views/ManageImages.vue'
import AvailableServices from '../views/AvailableServices.vue'
import ProjectPlacements from '../views/ProjectPlacements.vue'
import Accommodation from '../views/Accommodation.vue'
import Teaching from '../views/Teaching.vue'
import Teachers from '../views/Teachers.vue'
import Projects from '../views/Projects.vue'
import Tours from '../views/Tours.vue'
import TourAgencies from '../views/TourAgencies.vue'
import TourAgenciesReport from '../views/TourAgenciesReport.vue'
import Contacts from '../views/Contacts.vue'
import Organisations from '../views/Organisations.vue'
import ClassScheduleStudent from '../views/ClassScheduleStudent.vue'
import ClassScheduleTeacher from '../views/ClassScheduleTeacher.vue'
import PaymentTeacherForm from '../views/PaymentTeacherForm.vue'
import PaymentAccommodationForm from '../views/PaymentAccommodationForm.vue'
import Financial from '../views/Financial.vue'
import ClientAgencies from '../views/ClientAgencies.vue'
import ClientApplicationForms from '../views/ClientApplicationForms.vue'
import AnnualReport from '../views/AnnualReport.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root'
  },
  {
    path: '/Leads',
    name: 'Illegal',
    component: Leads
  },
  {
    path: '/ManageImages',
    name: 'ManageImages',
    component: ManageImages
  },
  {
    path: '/Leads',
    name: 'Leads',
    component: Leads
  },
  {
    path: '/PackageMaker',
    name: 'PackageMaker',
    component: PackageMaker
  },
  {
    path: '/Client',
    name: 'Client',
    component: Client
  },
  {
    path: '/Clients',
    name: 'Clients',
    component: Clients
  },
  {
    path: '/Application',
    name: 'Application',
    component: Application
  },
  {
    path: '/Applications',
    name: 'Applications',
    component: Applications
  },
  {
    path: '/CartaDePresentacion',
    name: 'CartaDePresentacion',
    component: CartaDePresentacion
  },
  {
    path: '/FichaDeAlojamiento',
    name: 'FichaDeAlojamiento',
    component: FichaDeAlojamiento
  },
  {
    path: '/Invoices',
    name: 'Invoices',
    component: Invoices
  },
  {
    path: '/AvailableServices',
    name: 'AvailableServices',
    component: AvailableServices
  },
  {
    path: '/Projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/ProjectPlacements',
    name: 'ProjectPlacements',
    component: ProjectPlacements
  },
  {
    path: '/Tours',
    name: 'Tours',
    component: Tours
  },
  {
    path: '/TourAgencies',
    name: 'TourAgencies',
    component: TourAgencies
  },
  {
    path: '/TourAgenciesReport',
    name: 'TourAgenciesReport',
    component: TourAgenciesReport
  },
  {
    path: '/Teaching',
    name: 'Teaching',
    component: Teaching
  },
  {
    path: '/Teachers',
    name: 'Teachers',
    component: Teachers
  },
  {
    path: '/Accommodation',
    name: 'Accommodation',
    component: Accommodation
  },
  {
    path: '/Contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/Organisations',
    name: 'Organisations',
    component: Organisations
  },
  {
    path: '/ClassScheduleStudent/',
    name: 'ClassScheduleStudent',
    component: ClassScheduleStudent
  },
  {
    path: '/ClassScheduleTeacher',
    name: 'ClassScheduleTeacher',
    component: ClassScheduleTeacher
  },
  {
    path: '/Financial',
    name: 'Financial',
    component: Financial
  },
  {
    path: '/ClientAgencies',
    name: 'ClientAgencies',
    component: ClientAgencies
  },
  {
    path: '/PaymentTeacherForm',
    name: 'PaymentTeacherForm',
    component: PaymentTeacherForm
  },
  {
    path: '/PaymentAccommodationForm',
    name: 'PaymentAccommodationForm',
    component: PaymentAccommodationForm
  },
  {
    path: '/ClientApplicationForms',
    name: 'ClientApplicationForms',
    component: ClientApplicationForms
  },
  {
    path: '/AnnualReport',
    name: 'AnnualReport',
    component: AnnualReport
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
