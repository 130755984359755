<template>
    <div class="d-flex justify-space-around" v-if="$store.getters.loadingApp !== 1">
        <v-card 
            flat
            class="pa-12"
            width="100%"
            max-width="210mm"
            min-height="297mm"
        >
            <span v-html="messageHTML" class="ma-2"></span>
        </v-card>
    </div>
</template>

<script>

import createClassScheduleStudent from '@/mixins/createClassScheduleStudent';
// import getDateFromTimestamp from '../mixins/getDateFromTimestamp';
// import formattedDate from '../mixins/formattedDate';
// import compare from '../mixins/compare';

export default {
    data () {
        return {
            // classesPlanned: [],
            messagePlain: "",
            messageHTML: "",
        }
    },
    beforeCreate () {
        this.$store.dispatch('setLoadingApp', 1)
    },
    async created() {
        await this.createStudentSchedule();
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[breadcrumb.length - 1] !== 'ClassScheduleStudent') {
            breadcrumb.push('ClassScheduleStudent')
            this.$store.dispatch('breadcrumbs', breadcrumb);
        }
        this.$vuetify.goTo(0);
        this.$store.dispatch('setLoadingApp', 0);
    },
    mounted() {
        let breadcrumb = JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
        if (breadcrumb[0] === 'ClassScheduleStudent') {
            this.$router.push({ name: 'Teaching' })
        }
    },

    // mixins: [getDateFromTimestamp, formattedDate, compare, createClassScheduleStudent],
    mixins: [createClassScheduleStudent],

    methods: {
        async createStudentSchedule() {
            // this.classesPlanned = this.$store.state.dataForReport.classes;
            let student = this.$store.state.dataForReport.student;
            let response = await this.createClassScheduleStudent(this.$store.state.dataForReport.classes, student);
            let resourceId = this.$store.state.dataForReport.resourceId;
            let email = this.$store.state.dataForReport.email;
            this.$store.dispatch('saveDataForReport', );

            let emailIntro = "Dear " + student + ",\n\n" + 
            "Thank you for taking classes with Proyecto Peru!\n\n" +
            "Please check your class schedule below.\n\n"
            if (response.individual_instead) {
                emailIntro += "If you requested the 20 hour group class and there is no one available to put in a group with you, you will be given 15 hours of private classes at the same cost.\n\n"
            }
            if (response.breakClass) {
                emailIntro += "There's a break of 15 minutes included when your class is longer than 3 hours.\n\n"
            }
            if (response.locationSchool) {
                emailIntro +=   "Please be 10 minutes early to check the board in school for last minute changes, " + 
                                "to find your classroom and to grab a cup of coffee or tea.\n" + 
                                "On your first day you will also receive the necessary materials such as pen and notebook.\n\n"
            }
            emailIntro += "If you didn't have the opportunity to complete your Spanish exam yet, please do so at this link <Spanish Placement Test> before your first class so your teacher can be prepared and provide you with the best service.\n\n"
            if (response.group) {
                emailIntro +=   "If you will not be able to make it to a class, please notify Jurgen as soon as posible. You can contact Jurgen on WhatsApp +51 950301311. \n\n"
            } else {
                emailIntro +=   "If you will not be able to make it to a class, please notify Jurgen at least one day ahead of time. You can contact Jurgen on WhatsApp +51 950301311. This way you will be able to make up the hours you missed at another time. " + 
                                "If there is an emergency and you are only able to notify us on the day of your class, you will be given half the time of the scheduled class to make up. For make-up classes please arrange these with Jurgen. \n\n"
            }
            emailIntro += "Please contact <office email> to let us know that you received this schedule or if you have any questions or concerns about your classes.\n\n" +
            "Hasta pronto, \n" + this.$store.state.user + "\n\n" +
            "info@proyectoperucentre.org\n" +
            "Proyecto Peru Centre\n" +
            "Avenida Arcopata 466-A\n" +
            "Cusco, Cusco, 45\n" +
            "+ 51 84 240 278"
            let payload = {
                submittedFrom: "ClassScheduleStudent",
                emailList: email,
                emailSubject: "Class Schedule Proyecto Peru",
                emailIntro: emailIntro,
                messageHTML: response.messageHTML,
                messagePlain: '',
                openEmailDialog: false,
                saveInvoice: false,
                teacherId: null,
                resourceId: resourceId,
                attachments: null,
            };
            this.messageHTML = response.messageHTML;
            this.$store.dispatch('emailMessage', payload);
        },
    },
}
</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
    /* @media all {
        .page-break	{ display: none; }
    }
    @media print {
        .page-break	{ display: block; page-break-before: always; }
    } */
</style>