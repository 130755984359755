export default {
    methods: {
        getSourceFromId(id) {
            if (id) {
                const source = this.$store.state.itemsSources.filter(function(select_item) {
                    return select_item.id === id;
                })
                return source[0].name;
            }
        },
    }
    
}