<template>
  <div>
    <nav>
      <v-footer app>
        <v-btn 
          v-if="this.$route.name === 'Application'"
          color="ppc_darkgreen"
          class="white--text d-flex d-sm-none"
          @click="$router.go(-1)"
        >
          Back
        </v-btn>
        <v-spacer class="d-flex d-sm-none"></v-spacer>
        <v-btn 
          v-if="this.$route.name === 'Application'"
          color="ppc_darkgreen"
          class="white--text d-flex d-sm-none"
          @click="printApplication"
        >
          Print/Download
        </v-btn>
      </v-footer>
    </nav>
  </div>
</template>

<script>
export default {
  methods: {
      printApplication () {
        window.print();
      },
    }
  }
</script>

