<template>
    <v-container v-if="$store.getters.loadingApp !== 1" style="max-width: 100%">
        <v-data-table
            :headers="headers"
            :items="updatedClientAgencies"
            class="elevation-1"
            :items-per-page="-1"
            disable-pagination
            hide-default-footer
            sort-by="name"
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-toolbar-title>Client Agencies</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="ppc_pink"
                        dark
                        small
                        absolute
                        right
                        fab
                        @click="dialog=true"
                    >
                        <v-icon>$vuetify.icons.icon_mdi_plus</v-icon>
                    </v-btn>
                    <v-dialog
                        v-model="dialog"
                        max-width="500px"
                        persistent
                    >
                        <v-form ref="dialogForm">
                            <v-card class="d-flex flex-column">
                                <v-card-title class="ppc_darkgreen darken-1">
                                    <span class="headline white--text">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-text-field
                                        v-model.trim="editedItem.name"
                                        label="Name"
                                        counter
                                        :rules="inputRulesName"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model.trim="editedItem.nameFormal"
                                        label="Formal Name"
                                        counter
                                    ></v-text-field>
                                    <v-text-field
                                        v-model.trim="editedItem.idFormal"
                                        label="Formal ID"
                                        counter
                                    ></v-text-field>
                                    <v-select
                                        v-model="editedItem.contact_id"
                                        :items="selectContactsOrganisation"
                                        item-text="organisation"
                                        item-value="contact_id"
                                        :menu-props="{ auto: true }"
                                        label="Select Organisation"
                                        single-line
                                        prepend-icon="$vuetify.icons.icon_mdi_domain ppc_darkgreen--text"
                                        validate-on-blur
                                        clearable
                                    >
                                    </v-select>
                                    <v-select
                                        v-model="editedItem.salesContact_id"
                                        :items="selectContactsStaff"
                                        item-text="name"
                                        item-value="id"
                                        :menu-props="{ auto: true }"
                                        label="Select Staff Member"
                                        single-line
                                        prepend-icon="$vuetify.icons.icon_mdi_account_group ppc_darkgreen--text"
                                        validate-on-blur
                                        clearable
                                    >
                                    </v-select>
                                    <v-checkbox
                                        v-model="editedItem.transferFeeIncluded"
                                        label="Transfer Fee Included"
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="editedItem.sendHandbook"
                                        label="Send PPC Handbook"
                                    ></v-checkbox>
                                    <v-checkbox
                                        :input-value="editedItem.obsolete !== null"
                                        label="Obsolete"
                                    ></v-checkbox>
                                    <v-text-field
                                        :value="formattedDate(editedItem.obsolete)"
                                        disabled
                                        label="From"
                                        v-if="editedItem.obsolete !== null"
                                        class="ml-2"
                                    ></v-text-field>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="ppc_darkgreen darken-1"
                                        text
                                        @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="ppc_darkgreen darken-1"
                                        text
                                        @click="save"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title>Are you sure you want to delete {{ editedItem.name }}?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="ppc_darkgreen darken-1" text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="ppc_darkgreen darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:[`item.transferFeeIncluded`]="{ item }">
                <v-icon small v-if="item.transferFeeIncluded === 1" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
            </template>
            <template v-slot:[`item.sendHandbook`]="{ item }">
                <v-icon small v-if="item.sendHandbook === 1" color="ppc_darkgreen">$vuetify.icons.icon_mdi_check</v-icon>
            </template>
            <template v-slot:[`item.obsolete`]="{ item }">
                <v-icon small v-if="item.obsolete !== null" color="ppc_pink">$vuetify.icons.icon_mdi_cancel</v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="goInvoice(item)"
                >
                    mdi-file-chart
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    $vuetify.icons.icon_mdi_pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    $vuetify.icons.icon_mdi_delete
                </v-icon>
            </template>
        </v-data-table>
        
    </v-container>
</template>

<script>

import dayjs from 'dayjs';
import inputRules from '../mixins/inputRules';
import formattedDate from '../mixins/formattedDate';
import compare from '../mixins/compare';

export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Formal Name', value: 'nameFormal' },
        { text: 'Formal ID', value: 'idFormal' },
        { text: 'Contact', value: 'contactFullName' },
        { text: 'Organisation(s)', value: 'organisations' },
        { text: 'Contact PPC', value: 'salesContactName' },
        { text: 'Transfer Fee Included', value: 'transferFeeIncluded' },
        { text: 'Send PPC Handbook', value: 'sendHandbook' },
        { text: 'Obsolete', value: 'obsolete' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      updatedClientAgencies: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        contact_id: null,
        salesContact_id: null,
        transferFeeIncluded: 0,
        sendHandbook: 0,
        obsolete: null,
      },
      defaultItem: {
        name: '',
        contact_id: null,
        salesContact_id: null,
        transferFeeIncluded: 0,
        sendHandbook: 0,
        obsolete: null,
        selectContactsOrganisation: [],
      },
    }),

    mixins: [inputRules, formattedDate, compare],

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Agency' : 'Edit Agency'
        },
        selectContactsStaff () {
            let staff = this.$store.state.contacts.filter(function(select_item) {
                return select_item.obsolete === null && select_item.staff > 0;
            })
            return staff.sort(this.dynamicSort('name'));
        }
    },

    watch: {
    },

    beforeCreate () {
        if (this.$store.state.accessClientAgencies === 0) {
            this.$router.push({ name: 'Illegal' })
        }
        this.$store.dispatch('setLoadingApp', 1)
    },

    created () {
        dayjs.locale("en");
        this.initialize()
    },

    methods: {
        async initialize () {
            await this.getClientAgencies();
            let organisations = await this.$store.dispatch('getOrganisationsAll');
            for (let index = 0; index < organisations.length; index++) {
                organisations[index].organisation = organisations[index].name + ' - ' + organisations[index].contactName;
            }
            this.selectContactsOrganisation =  organisations.sort(this.dynamicSort('name'));
            let breadcrumb =  JSON.parse(JSON.stringify(this.$store.state.breadcrumbs));
            breadcrumb.push('ClientAgencies');
            await this.$store.dispatch('breadcrumbs', breadcrumb);
            this.$store.dispatch('setLoadingApp', 0)
        },

        async getClientAgencies() {
            this.updatedClientAgencies = JSON.parse(JSON.stringify(await this.$store.dispatch('getClientAgencies')));
            let organisations;
            for (let index = 0; index < this.updatedClientAgencies.length; index++) {
                (this.updatedClientAgencies[index].obsolete) ? this.updatedClientAgencies[index].obsolete = this.formattedDate(this.updatedClientAgencies[index].obsolete, 'date') : this.updatedClientAgencies[index].obsolete = null;
                organisations = await this.$store.dispatch('getOrganisations', this.updatedClientAgencies[index].contact_id);
                this.updatedClientAgencies[index].organisations = '';
                for (let index2 = 0; index2 < organisations.length; index2++) {
                    this.updatedClientAgencies[index].organisations = this.updatedClientAgencies[index].organisations.concat(', ', organisations[index2].name)
                }
                if (this.updatedClientAgencies[index].organisations.length > 0) {
                    this.updatedClientAgencies[index].organisations = this.updatedClientAgencies[index].organisations.substring(1);
                }
            }
        },

        editItem (item) {
            this.editedIndex = this.updatedClientAgencies.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        async deleteItem (item) {
            this.editedIndex = this.updatedClientAgencies.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        async deleteItemConfirm () {
            let response = await this.$store.dispatch('deleteClientAgency', this.editedItem.id);
            if (response.data.affectedRows === 1) {
                this.$store.dispatch('successMessage', 'Agency deleted');
            } else {
                this.$store.dispatch('errorMessage', 'Something went wrong');
            }
            await this.getClientAgencies();
            this.closeDelete()
        },

        setObsolete(event) {
            if (event) {
                this.editedItem.obsolete = dayjs().format("YYYY-MM-DD")
            } else {
                this.editedItem.obsolete = null
            }
            
        },

        close () {
            this.dialog = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        async save () {
            if(this.$refs.dialogForm.validate()){
                let tmp = this.editedItem.name;
                let doubleName = this.updatedClientAgencies.filter(function(select_item) {
                        return select_item.name === tmp;
                    }
                )
                if (doubleName.length > 0 && this.editedIndex !== this.updatedClientAgencies.indexOf(doubleName[0])) {
                    this.$store.dispatch('errorMessage', 'The name "' + this.editedItem.name + '" is already in use!');
                    return;
                } else {
                    console.log(this.editedItem)
                    await this.$store.dispatch('insertClientAgency', this.editedItem);
                    await this.getClientAgencies();
                }
                this.close()
            }
        },
        async goInvoice (item) {
            const date = new Date();
            let idAppNew = date.getTime();
            let newApp = {};
            newApp.id = idAppNew;
            newApp.autoAppTitle = false;
            newApp.amountOfClients = 1;
            // newApp.syncPM = false;
            newApp.payerAgency = item.id;
            newApp.payerClient = null;
            newApp.ignore_deposit = true;
            let agency_id = item.id;
            let tmp = this.$store.state.available_agencies.filter(function(select_item) {
                return select_item.id === agency_id;
            });
            newApp.transferFeeIncluded = tmp[0].transferFeeIncluded;
            newApp.appTitle = 'Agency Invoice';
            newApp.exchangeRate = this.$store.state.exchangeRate;
            await this.$store.dispatch('insertApplication', newApp);
            await this.$store.dispatch('saveDataForReport', idAppNew);
            this.$router.push({ name: 'Application' })
        },
    },
  }

</script>

<style>
    .v-card__title {
        color: #35b729;
        word-break: normal !important;
    }
    .v-card__subtitle { padding-top: 0; padding-bottom: 0;}
    .pos_absolute {
        position: absolute;
    }
</style>